/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { BKStyledButton } from '@gigin-work-space/common-ui';
import { guardedPageNavigate, VARIANT_TYPE } from '@gigin-work-space/utils';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { tenantState } from 'src/app/utils/router/tenantState';
import { AuthenticationPaper } from '../../../components';
import { axiosInstance, endpoints, LoginSchema } from '../../../utils';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';

export function Signin() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stytchTokenType = searchParams.get('stytch_token_type');
  const token = searchParams.get('token');
  const redirectTo = searchParams.get('redirectTo');
  const [togglePassword, setTogglePassword] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isMagicLinkLoading, setIsMagicLinkLoading] = useState(
    !!stytchTokenType
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Handle deep link redirection
  const handleDeepLinkRedirection = useCallback((redirectTo: any) => {
    const redirectToParts = redirectTo.split('data=');
    if (redirectToParts.length === 2) {
      const dataFromRedirect = redirectToParts[1];
      if (dataFromRedirect !== null) {
        const encodedData = encodeURIComponent(dataFromRedirect);
        const redirectUrl = `/${redirectToParts[0]}data=${encodedData}`;
        globalNavigateTo(redirectUrl, navigate);
        return;
      }
    }
  }, []);

  const checkMfaAndRedirect = useCallback((is2faEnabled: boolean) => {
    if (is2faEnabled) {
      globalNavigateTo('/mfa/otp-verification', navigate);
    } else {
      enqueueSnackbar('Logged in successfully', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      globalNavigateTo(guardedPageNavigate(), navigate);
    }
  }, []);

  const handleLoginSuccess = useCallback((response: any) => {
    const userAccountDetail = Object.assign({}, response?.data?.data);
    const localStorageData = {
      auth_token: JSON.stringify(response?.data?.auth_token),
      userAccountDetail: JSON.stringify(userAccountDetail),
      userPermissions: JSON.stringify(
        response?.data?.data?.rolePermissionByName
      ),
      role: JSON.stringify(response?.data?.data?.roleName),
    };

    Object.entries(localStorageData).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    tenantState.tenantName = response?.data?.data?.tenantName;
    // NOTE: this redirect function is used to redirect to a page coming from deep link
    if (redirectTo) {
      handleDeepLinkRedirection(redirectTo);
    }
    checkMfaAndRedirect(response?.data?.data?.is2faEnabled);
    return;
  }, []);

  useEffect(() => {
    const handleStytchLogin = async () => {
      if (
        stytchTokenType &&
        token &&
        stytchTokenType.toLowerCase() === 'login'
      ) {
        setIsMagicLinkLoading(true);
        try {
          const { data: response } = await axiosInstance.post(
            endpoints.POST_AUTH_SIGNIN_MAGICLINK,
            { token },
            { withCredentials: true }
          );

          if (response?.data?.success) {
            const userAccountDetail = Object.assign(
              {},
              response?.data?.data?.account_name
            );
            const localStorageData = {
              auth_token: JSON.stringify(response?.data?.auth_token),
              userAccountDetail: JSON.stringify({
                ...userAccountDetail,
                company_logo: response?.data?.data?.company_logo,
                is2faEnabled: response?.data?.data?.is2faEnabled || false,
                tenantName: response?.data?.data?.tenantName,
              }),
              userPermissions: JSON.stringify(
                response?.data?.data?.rolePermissionByName
              ),
              role: JSON.stringify(response?.data?.data?.role),
            };

            Object.entries(localStorageData).forEach(([key, value]) => {
              localStorage.setItem(key, value);
            });
            tenantState.tenantName = response?.data?.data?.tenantName;
            checkMfaAndRedirect(response?.data?.data?.is2faEnabled);
          } else {
            throw new Error('This link has already been used or has expired');
          }
        } catch (error: any) {
          const errorCode = JSON.parse(
            error?.response?.data?.message
          )?.status_code;
          if (errorCode === 401) {
            enqueueSnackbar('This link has already been used or has expired', {
              variant: VARIANT_TYPE.WARNING,
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar('Failed to login', {
              variant: VARIANT_TYPE.ERROR,
              autoHideDuration: 2000,
            });
            localStorage.clear();
            navigate('/login');
          }
        } finally {
          setIsMagicLinkLoading(false);
        }
      }
    };

    handleStytchLogin();
  }, [stytchTokenType, token, navigate, enqueueSnackbar]);

  const formik = useFormik({
    initialValues: {
      userName: '',
      userPassword: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsApiLoading(true);
      try {
        const response = await axiosInstance.post(
          endpoints.POST_AUTH_SIGNIN,
          {
            email: values.userName,
            password: values.userPassword,
          },
          { withCredentials: true }
        );

        if (response?.data?.success) {
          handleLoginSuccess(response);
        }
        if (!response?.data?.success) {
          if (response?.data?.message?.status === 401) {
            enqueueSnackbar('Invalid email or Password', {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
          if (response?.data?.message?.status === 404) {
            enqueueSnackbar('User Not registered', {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }
      } catch (error: any) {
        console.log('error', error);
        const errorMessage =
          error.response?.data?.message || error.message || 'Failed to login';
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setIsApiLoading(false);
        resetForm();
      }
    },
  });

  // function to show the password on mouse Press
  const handleTogglePassword = () => {
    setTogglePassword(!togglePassword);
  };

  // memoized variable to return the boolean value of btn disabled state
  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled || isApiLoading;
  }, [formik.values, isApiLoading]);

  // Note: This is used to auto login when auth_token is present in local storage
  useEffect(() => {
    // Check if auth_token is present in local storage
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      // If auth_token is present, try to get userAccountDetail
      const userAccountDetailStr = localStorage.getItem('userAccountDetail');
      if (userAccountDetailStr) {
        // If userAccountDetailStr is not null, parse it and proceed
        const userDetail = JSON.parse(userAccountDetailStr);
        tenantState.tenantName = userDetail.tenantName;
        globalNavigateTo(guardedPageNavigate(), navigate);
      }
    }
  }, []);

  if (isMagicLinkLoading) {
    return <LinearProgress sx={{ height: 5, borderRadius: 5 }} />;
  }

  return (
    <AuthenticationHOC>
      <section className="absolute bottom-[-4%] left-[-160px]">
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary">
            Log in
          </Typography>
          <Box
            className="mt-6"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}>
            <TextField
              id="userName"
              name="userName"
              label="Your Email ID"
              variant="outlined"
              fullWidth
              required
              value={formik.values.userName.toLowerCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': { borderRadius: '8px !important' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-testid': 'userName' }}
            />
            <TextField
              id="userPassword"
              name="userPassword"
              label="Password"
              sx={{
                marginBottom: '4px',
                '& .MuiInputBase-root': { borderRadius: '8px !important' },
              }}
              variant="outlined"
              type={togglePassword ? 'text' : 'password'}
              fullWidth
              required
              value={formik.values.userPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.userPassword &&
                Boolean(formik.errors.userPassword)
              }
              helperText={
                formik.touched.userPassword && formik.errors.userPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ p: 0 }} onClick={handleTogglePassword}>
                      {togglePassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-testid': 'userPassword' }}
            />
            {/* Forgot Password */}
            <Typography
              className="bK-body2 text-bk_tag_blue_light cursor-pointer text-right"
              onClick={() =>
                globalNavigateTo('/reset-password/forgot', navigate)
              }>
              Forgot password?
            </Typography>
            <BKStyledButton
              data-testid="submitBtn"
              className="bg-bk_action_primary mt-6 rounded-lg"
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              disabled={isBtnDisabled}>
              Log in
            </BKStyledButton>
          </Box>
          {/* <Typography className="bK-body2 mt-2 text-center">or</Typography>
          <BKStyledButton
            data-testid="signUpBtn"
            className="bg-bk_action_primary mt-2 rounded-lg"
            fullWidth
            size="large"
            variant="contained"
            onClick={() => globalNavigateTo('/self-signup', navigate)}>
            Sign Up
          </BKStyledButton> */}
        </AuthenticationPaper>
      </section>
    </AuthenticationHOC>
  );
}

export default Signin;
