import { Box, LinearProgress, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import loaderJson from '../../../../assets/lottieJson/Generate.json';

export const GenerateReportLoader = ({ title }: { title: string }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
      <Typography className="bk-headline1 text-center mt-16 mb-9">
        {title}
      </Typography>
      <Lottie
        animationData={loaderJson}
        loop={true}
        autoPlay={true}
        height={'50vh'}
        width={'50vh'}
      />
    </Box>
  );
};
