import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
// import { axiosInstance, endpoints } from '../../utils';
import { useSnackbar } from 'notistack';
import { axiosInstance, endpoints } from 'src/app/utils';

interface Account {
  _id: string;
  account_name: string;
  tenantId: string;
}

interface AccountsContextType {
  allAccounts: Account[];
  getAccounts: () => Promise<void>;
}

export const AccountsContext = createContext<AccountsContextType | undefined>(
  undefined
);

interface AccountsProviderProps {
  children: ReactNode;
}

export const AccountsProvider: React.FC<AccountsProviderProps> = ({
  children,
}) => {
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const getAccounts = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(endpoints.GET_ALL_ACCOUNTS);
      if (data.success) {
        setAllAccounts(data.data);
      } else {
        throw new Error('Failed to fetch accounts');
      }
    } catch {
      enqueueSnackbar('Failed to fetch accounts', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [enqueueSnackbar]);

  return (
    <AccountsContext.Provider value={{ allAccounts, getAccounts }}>
      {children}
    </AccountsContext.Provider>
  );
};

// Custom hook for easier access to the context
export const useAccounts = () => {
  const context = useContext(AccountsContext);
  if (!context) {
    throw new Error('useAccounts must be used within an AccountsProvider');
  }
  return context;
};
