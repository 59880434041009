import {
  CheckStatus,
  DocumentFile,
  DocumentList,
  Report,
} from '@gigin-work-space/model';
import {
  ParameterLevelLabel,
  getAccordionColorByCheckStatus,
  getDescriptionBGColorByCheckStatus,
  isBlockedorFailed as isBlockedOrFailed,
} from '@gigin-work-space/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DisplayImage } from 'src/app/components';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import {
  ProgressIndividualCandidate,
  StatusChip,
} from '../bgv-orders-table/components';

const IdentityDetails = ({ document }: { document: DocumentList }) => {
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  // const [zoomLevel, setZoomLevel] = useState(1);

  const handleOpen = (url: string) => {
    setImageUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setZoomLevel(1);
  };

  const displayImage = (url: string) => {
    handleOpen(url);
  };

  // const handleZoomIn = () => {
  //   setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 0.1, 2.5));
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 0.1, 0.1));
  // };
  return (
    <Stack direction={'row'} spacing={1}>
      <Stack>
        <FileDisplayComponent
          fileUrl={document?.details?.image}
          fileType="image"
          fileStyle={{
            width: '38px',
            height: '40px',
          }}
          fileClassName="object-fill"
        />
      </Stack>
      <Stack direction={'column'}>
        <Stack>
          <Typography className="bk-sub-heading2">
            {document?.details?.check_name}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography className="bK-body2">
            {/* todo: how you find it's adhar or what */}
            {document?.details?.check_name?.toUpperCase() === 'AADHAR CARD'
              ? document?.details?.check_number?.match(/.{1,4}/g)?.join(' ') ??
                ''
              : document?.details?.check_number}
          </Typography>
        </Stack>
        <Grid display={'flex'} className="mt-6" width={'auto'}>
          <Grid columnGap={6} display={'flex'}>
            {document.documentFileList.map((checklist: DocumentFile) => {
              return (
                <>
                  {
                    <Grid
                      columnGap={1}
                      className="cursor-pointer bk-on-hover-bg-change"
                      display={'flex'}
                      onClick={() => displayImage(checklist.fileUrl)}>
                      <Grid>
                        <DisplayImage
                          imageName={
                            checklist.fileType === 'application/pdf'
                              ? 'pdf-icon.svg'
                              : 'img-icon.svg'
                          }
                          imageType="icon"
                          width="25px"
                          height="24px"
                        />
                      </Grid>

                      <Grid>
                        <Typography className="bk-sub-heading3">
                          {
                            ParameterLevelLabel[
                              checklist.fileLabel as keyof typeof ParameterLevelLabel
                            ]
                          }
                        </Typography>
                        <Tooltip title={checklist.fileName} placement="top">
                          <Typography className="bk-subheading-of-subheading-3">
                            {checklist.fileName.slice(0, 15)}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                </>
              );
            })}
          </Grid>
        </Grid>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ width: '600px', height: '600px' }}>
          <FileDisplayComponent
            fileUrl={imageUrl}
            fileClassName="w-full h-full object-contain"
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          {/* <Button onClick={handleZoomIn} className="bk-img-zoom-in-out-button">
            +
          </Button>
          <Button onClick={handleZoomOut} className="bk-img-zoom-in-out-button">
            -
          </Button> */}
          <Button onClick={handleClose} className="bk-close-button">
            Close(Esc)
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

const FailureDescription = ({
  document,
  color,
}: {
  document: DocumentList;
  color: string;
}) => {
  return (
    <>
      {document?.details?.info?.map(data => {
        return (
          <Stack
            direction={'row'}
            spacing={0.5}
            className="w-full"
            alignItems={'center'}
            sx={{
              backgroundColor: getDescriptionBGColorByCheckStatus(
                document?.check_status?.status
              ),
              py: '0.7rem',
              px: '1rem',
            }}>
            <FileDisplayComponent
              fileUrl={data?.icon}
              fileType="image"
              fileStyle={{
                width: '12px',
                height: '12px',
              }}
              fileClassName="object-fill"
            />
            {/* TODO: Text color by logic */}
            <Typography className="bK-body2" sx={{ color: color }}>
              {data?.message}
            </Typography>
          </Stack>
        );
      })}
    </>
  );
};
// eslint-disable-next-line no-unused-vars
const OverallReport = ({ status }: { status: CheckStatus }) => {
  return (
    <Stack>
      <StatusChip
        message={status?.message}
        title={status?.title}
        status={status?.status}
      />
    </Stack>
  );
};

export const CommonCheckStatus = ({ data }: { data: Report }) => {
  return (
    <Stack direction={'column'} spacing={2} sx={{ my: 1.5, mx: '1rem' }}>
      {data.documentlist.map(document => {
        const orderColor = getAccordionColorByCheckStatus(
          document?.check_status?.status
        );
        return (
          <Stack
            direction={'column'}
            spacing={0.5}
            sx={{
              borderLeft: `2px solid ${orderColor}`,
              borderBottom: isBlockedOrFailed(document?.check_status?.status)
                ? `1px solid ${orderColor}`
                : 'none',
            }}>
            {/* Process Row */}
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ pr: '2.2rem', pl: '1.5rem', mb: '0.5em' }}>
              {/* check col */}
              <Stack direction={'column'} spacing={1.5}>
                {/* Identity check */}
                <IdentityDetails document={document} />
                {/* progress details */}
                <ProgressIndividualCandidate
                  steps={document?.check_steps_status}
                />
              </Stack>
              {/* Status col */}
              <Stack direction={'row'}>
                {/* Overall Report */}
                <OverallReport status={document?.check_status} />
              </Stack>
            </Stack>

            {/* Failure Description */}
            {isBlockedOrFailed(document?.check_status?.status) ? (
              <Stack direction={'row'} alignItems={'center'} className="mt-2">
                {/* TODO: change info array to object */}
                <FailureDescription document={document} color={orderColor} />
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
