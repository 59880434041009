import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

/* eslint-disable-next-line */
export interface PrivacyPolicyProps {}

export function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="mb-20 overflow-scroll" style={{ height: '100vh' }}>
      <div className="m-12">
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Stack direction={'column'}>
            <Typography
              onClick={() => navigate(-1)}
              variant="h6"
              className="text-bk_action_primary"
              sx={{ cursor: 'pointer' }}>
              &lt;&nbsp;Go back
            </Typography>
            <h1 className="mb-8">Privacy Policy</h1>
          </Stack>
          <Stack>
            <Typography onClick={() => globalNavigateTo('/login', navigate)}>
              <DisplayImage
                imageName="verifyin-dark.svg"
                imageType="image"
                width="200px"
                height="100px"
                className="ml-1"
              />
            </Typography>
          </Stack>
        </Stack>
        <p>
          Gigin understands that you care about how your information is used and
          shared, and we take your privacy seriously. Please read the following
          to learn more about our Privacy Policy, this Privacy Policy ("
          <strong>Policy</strong>") along with terms and conditions describes
          how Gigin Technologies Private Limited (“
          <strong>Gigin or We/Us</strong>”) collects, uses, discloses and
          transfers Personal Information of users through its websites and
          applications, including through www.giginjobs.com (collectively, the "
          <strong>Platform</strong>"). This policy applies to those who visit
          the Platform, or whose information Gigin otherwise receives by use of
          our products, software, services and web sites and applications
          (collectively, the “<strong>Web Site or Platform</strong>”), owned or
          operated by Gigin (hereinafter collectively referred to as "
          <strong>Users</strong>"). For the purposes of the Privacy Policy, "
          <strong>You</strong>" or "<strong>Your</strong>" shall mean the person
          who is accessing the Platform; you acknowledge that you understand and
          accept the practices and policies outlined in this Privacy Policy.
        </p>
        <p className="mt-6">
          <strong>What Does This Privacy Policy Cover?</strong>
        </p>
        <p>
          This Privacy Policy covers Gigin’s treatment of information that
          identifies you or could reasonably be used to identify you that we, or
          our third-party partners, gather when you are accessing our Web Site
          and when you use our services or products (“Personal Identifiable
          Information”), as well as usageinformation that is anonymized and not
          associated with you (“Non-Personal Information”). This policy does not
          apply to the practices of companies that we do not own or control, or
          to individuals that we do not employ or manage.
        </p>
        <p className="mt-6">
          <strong>Purpose of This Privacy Policy</strong>
        </p>
        <p>
          This privacy policy aims to give you information on how Gigin collects
          and processes your personal data through your use of the Website,
          including any data you may provide through this Website when you
          submit an application with Gigin.
        </p>
        <p>
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy policy supplements other notices and privacy
          policies and is not intended to override them.
        </p>
        <p className="mt-6">
          <strong>Third Party Content:</strong>
        </p>
        <p>
          Please be aware that the Website/Platform sometimes contains links to
          other sites that are not governed by this Privacy Policy. Users may be
          directed to third-party sites for more information, such as
          advertisers, blogs, content sponsorships, Vendor services. Social
          networks, etc.
        </p>
        <p>
          Gigin makes no representations or warranties regarding how your
          information is stored or used on third-party servers. We recommend
          that you review the applicable privacy statements and policies of each
          third-party site linked from the Platform to determine their use of
          your Personal Information.
        </p>
        <p className="mt-6">
          <strong>What Information Does Gigin Collect?</strong>
        </p>
        <p>
          "<strong>Personal Identifiable Information</strong>" (PII) - means any
          information relating to an identified or identifiable natural person
          including common identifiers such as a name, username, location data,
          an online identifier or one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity
          of that natural person and any other information that is so
          categorized by applicable laws. We collect information about you
          and/or your usage to provide better services and offerings. The
          Personal Identifiable Information that we collect, and how we collect
          it, depends upon how you interact with us. We collect the following
          categories of Personal Information in the following ways:
        </p>
        <ul className="ml-5">
          <li>
            Name, email address, password, country, city, contact number and
            company/organization that you are associated with.
          </li>
          <li>
            Information that one would usually include in a resume, including
            name, contact details including e-mail address and mobile number,
            work experience, educational qualifications, data relating to your
            current and past remuneration or salary, a copy of your resume,
            title, employer, social media pages like LinkedIn etc. when you
            register on the Platform;
          </li>
          <li>
            Information about the services that you use and how you use them,
            including log information and location information, when you are a
            user of the services through the Platform;
          </li>
          <li>
            We may collect your Personal/categorical Informationsuch as name,
            age, contact details, preferences, work position etc. through
            surveys and forms, when you choose to participate in these surveys
            etc.;
          </li>
          <li>
            We may also collect information relating to your caste and
            information about whether you are eligible for any affirmative
            action programs or policies, if you opt to provide such information;
          </li>
          <li>
            When you communicate with Gigin or use the Platform to communicate
            with other Users (such as partners), we collect information about
            your communication and any information you choose to provide;
          </li>
          <li>
            When you visit the Platform, we use cookies to automatically
            collect, store and use technical information about your system and
            interaction with our Platform;
          </li>
          <li>
            When you use a device to access the Platform, Gigin may collect
            technical or other related information from the device as well as
            the device location;
          </li>
          <li>
            To the extent permitted by law, Gigin may record and monitor your
            communications with us to ensure compliance with our legal and
            regulatory obligations and our internal policies. This may include
            the recording of Audio/Video communication or telephone
            conversations;
          </li>
          <li>
            If you choose to sign in with your social media account to access
            the Platform, or otherwise connect your social media account with
            the services of the Platform, you consent to our collection,
            storage, and use, in accordance with this Privacy Policy, of the
            information that you make available to us through the social media
            interface. Please see your social media provider's privacy policy
            and help centre for more information about how they share
            information when you choose to connect your account.
          </li>
        </ul>
        <p></p>
        <p className="mt-6">
          <strong>How does Gigin Collect Information?</strong>
        </p>
        <p>
          We use passive tracking tools to automatically collect information,
          such as browser cookies.Cookies are alphanumeric identifiers that we
          transfer to your browser to enable our systems to recognize your
          browser over time and tell us how and when pages in our Web Site are
          visited and by how many people. We may receive a confirmation when you
          open an email from us if your computer supports this type of program.
          We may have third parties collect information this way on our behalf.
          To learn more about our use of cookies and your choices, please see
          theChoicessection below.
        </p>
        <p>
          We collect information directly from you and also when you submit a
          testimonial or complete a profile questionnaire.
        </p>
        <p>
          We may also receive information about you from third parties. For
          example, our service providers (such as the vendors who provide
          services related to the Web Site) may provide us with information
          about you.
        </p>
        <p>
          You can choose not to provide us with certain Personal Information,
          but then you may not be able to take full advantage of certain
          features or benefits of the Web Site.
        </p>
        <p className="mt-6">
          <strong>How Does Gigin Use Information?</strong>
        </p>
        <p>
          We use information to respond to your requests or questions. For
          example, we might use your information to respond to your feedback or
          product reviews. We also use your information to notify you of new
          features and updates.
        </p>
        <p>
          We use information to improve the content of the Web Site. We might
          use your information to customize your experience and the content you
          see.
        </p>
        <p>
          We use information for security purposes. We may use information to
          protect Gigin, our affiliates and partners, and our users. We also use
          information to protect our Web Site.
        </p>
        <p>
          We use information for marketing purposes. For example, we might
          provide you with information about specials, services, and new
          features. We might also use your information to customize the
          advertisements we serve you based on your interests. These might be
          third party offers or products we think you would find interesting.
        </p>
        <p>
          We use information to communicate with you. For example, we may
          contact you about feedback you give us. We might also contact you
          about this Privacy Policy or our Terms of Use.
        </p>
        <p>
          We use information as otherwise permitted by law or as we may notify
          you from time to time.
        </p>
        <p className="mt-6">
          <strong>Gigin Shares Information with Third Parties</strong>
        </p>
        <p>
          We share information with the colleges/universities and our associated
          Partners from which you request to receive information about. We work
          with employers, colleges, universities and educational institutions
          you are interested in to provide targeted recruiting and outreach.{' '}
        </p>
        <p>
          We share information with affiliated businesses we do not control and
          are not responsible for their privacy statements, their content,
          products, or messages on the Web Site. When you leave our Website, we
          encourage you to read the privacy policy of every website you visit.
        </p>
        <p>
          We may share aggregated data and impact metrics with other
          organizations.
        </p>
        <p>
          We share information with agents and service providers.For example, we
          share information with vendors that send emails for us or process
          online financial transactions.
        </p>
        <p>
          We may make user submissions available for other users to see on the
          Web Site. User profile information may be displayed and viewable to
          other users to facilitate user interaction within the Web Site.
        </p>
        <p>
          We will share information if we have to in order to comply with the
          law or to protect ourselves. For example, we will share information to
          respond to a court order or a notice. We may share it if a government
          agency or investigatory body requests. We might share information when
          we are investigating potential threat or fraud.
        </p>
        <p>
          We may share information with any successor to all or part of our
          organization. For example, we may give our user database to the
          resulting entity as part of that reorganization.
        </p>
        <p>
          We may share information for other reasons we may describe to you.
        </p>
        <p>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>
        <p className="mt-6">
          <strong>
            You Have Certain Choices about How We Use Your Information
          </strong>
        </p>
        <p>
          You have choices related to email and SMS. If you do not want to
          receive email or other mail from us, follow the “unsubscribe”
          instructions in any email you get from us. Even if you opt out of
          getting marketing messages, we may still send you transactional
          messages (such as responses to your requests and questions or updates
          about this Privacy Policy or the Terms of Use).
        </p>
        <p>
          You have choices related to cookies and tracking tools. Most browsers
          have an option for turning off the cookie feature, which will prevent
          your browser from accepting new cookies, as well as (depending on the
          capability of your browser software) allowing you to decide on
          acceptance of each new cookie in a variety of ways.If you block or
          reject cookies, certain features on the Web Site may not work. Certain
          choices you make may be browser-specific or device-specific or both.
          If you block or reject cookies, not all of the tracking described here
          will stop.
        </p>
        <p className="mt-6">
          <strong>Gigin Uses Standard Security Measures</strong>
        </p>
        <p>
          The internet is not absolutely secure. We endeavor to protect user
          information to ensure that your information is kept private, however,
          we cannot guarantee the security of your information. Unauthorized
          entry or use, hardware or software failure, and other factors, may
          compromise the security of user information. We encourage you to use
          caution when using the internet. This includes selecting and
          protecting your Web Site account password appropriately and limiting
          access to your computer and browser by signing off after you have
          finished accessing your account.
        </p>
        <p className="mt-6">
          <strong>Data Security and Integrity.</strong>
        </p>
        <p>
          We implement physical, technical, and administrative safeguards
          designed to maintain data accuracy, integrity, and security, prevent
          unauthorized access, and facilitate correct use of Personal
          Information. These safeguards include limiting access to data, storing
          Personal Information on secure servers and computers, periodically
          testing our security technology, and training our employees in
          information security.
        </p>
        <p>
          In addition, we seek to maintain contractual data security,
          confidentiality, and privacy obligations with our partners and
          vendors, and our employees that have access to Personal Information
          are subject to access, use, and confidentiality policies. Only
          authorized employees with a reasonable need related to their job
          duties have access to user information. Employees who violate our
          policies are subject to disciplinary action, up to and including
          termination.
        </p>
        <p>
          Although we maintain the controls listed herein, transmission of data
          is not without risk and we complete security of your information
          cannot be guaranteed. Please note, you are responsible for keeping
          your username and password secret at all times.
        </p>
        <p>
          In the event we believe the security of your Personal Information in
          our possession or control may be compromised, we may seek to notify
          you by e-mail and you consent to our use of e-mail as a means of such
          notification.
        </p>
        <p className="mt-6">
          <strong>No Fee Usually Required</strong>
        </p>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee, ifrequired; in near future information in case your request is
          clearly unfounded, repetitive or excessive, however, you will be
          informedin advance over the platform/website. For this purpose when
          required we may collect payment information like your credit card
          information and billing address.
        </p>
        <p className="mt-6">
          <strong>Termination of Account</strong>
        </p>
        <p>
          You may request deletion of your Web Site account by sending an e-mail
          from www.giginjobs.com/contact_us/. Please note that some information
          may remain in our records after deletion of your account.
        </p>
        <p className="mt-6">
          <strong>Changes to this Privacy Policy</strong>
        </p>
        <p>
          We may amend this Privacy Policy from time to time. If we make changes
          in the way we use Personal Information, we will notify you by posting
          an announcement on our Web Site or sending you an email as required by
          law. Users are bound by any changes to the Privacy Policy when they
          access, visit, or use the Web Site after such changes have been first
          posted.
        </p>
        <p className="mt-6">
          <strong>Questions or Concerns</strong>
        </p>
        <p>
          If you have any questions or concerns regarding privacy at our Web
          Site, please contact us with a detailed message, and we will make
          every effort to resolve your concerns.
        </p>
        <p></p>

        <p></p>
      </div>
    </div>
  );
}
