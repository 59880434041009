import { COLORS, DisplayImageType } from '@gigin-work-space/utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import CheckCases from './CheckCases';
import DetailCheckInfo from './DetailCheckInfo';

const SingleCheckDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { check } = useParams<{ check: any }>();
  const item = location?.state;

  // TODO: is there is no item it will redirect to ops-dashboard

  useEffect(() => {
    if (!item) {
      globalNavigateTo('/ops-platform/checks-dashboard', navigate);
    }
  }, [item, navigate]);

  const count = item?.state?.count;
  const id = item?.state?.id;
  const icon = item?.state?.icon;

  const isScreenLarger = useMediaQuery('(min-width: 768px');

  if (!item || !item?.state) {
    // Handle the case where item or item.state is null or undefined
    return <Typography variant="h6">Data not available</Typography>;
  }

  const handleback = () => {
    navigate(-1);
  };

  return (
    <>
      <Grid container direction={'row'}>
        <Grid
          item
          xs={0.5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}>
          <ArrowBackIosIcon
            onClick={handleback}
            className="cursor-pointer text-3xl"
          />
        </Grid>
        <Grid item xs={11}>
          <Box
            key={id}
            className={`bk-single-checks  flex items-center space-between ${
              isScreenLarger ? 'flex-row' : 'flex-col'
            } my-3 mx-0`}
            component={Paper}
            elevation={2}>
            <Box
              className={`flex items-center justify-start ${
                isScreenLarger ? 'w-1/3' : 'w-full'
              }`}
              padding={1}
              border="1px solid #e0e0e0"
              borderRadius={2}>
              <FileDisplayComponent
                fileUrl={icon}
                fileType={DisplayImageType.URL}
                fileClassName={`h-8 w-8 object-contain text-${COLORS.bk_icon_color}`}
              />
              <Box className="ml-4 w-full">
                <Typography variant="h6" className="text-justify">
                  {check?.charAt(0).toUpperCase() + check.slice(1)}
                </Typography>
                <Typography variant="body2"> {count} initiated </Typography>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem className="mx-4" />

            <Grid
              container
              justifyContent={isScreenLarger ? 'space-around ' : 'flex-start'}
              alignItems={'center'}
              marginTop="0">
              <CheckCases />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <DetailCheckInfo />
    </>
  );
};

export default SingleCheckDetails;
