import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper } from 'src/app/components';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { AuthenticationHOCForSignup } from '../../authentication/authentication-hoc/authentication-hoc-without-background';

export function NewUserRequestInfo() {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      globalNavigateTo('/bgv', navigate);
    }
  }, []);
  return (
    <AuthenticationHOCForSignup>
      <AuthenticationPaper>
        <Box className="bk-on-boarded p-4">
          <Grid className="bk-company-verified">
            <Grid className="bk-company-verified-item">
              <Typography className="bk-caption text-bk_text_primary">
                Your request has been successfully sent to the administrator.
                Please wait for the administrator to approve your request.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </AuthenticationPaper>
    </AuthenticationHOCForSignup>
  );
}

export default React.memo(NewUserRequestInfo);
