/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { EmployerCDCStore } from '@gigin-work-space/store';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
export interface IEBDragDropProps {
  type: string;
  description: string;
  acceptedFileTypes: string[];
  fetchDataTable: (order_id: string, package_id: string) => void;
}

interface Field {
  label: string;
  key: string;
  alternateMatches: string[];
  fieldType: {
    type: string;
  };
}
// const acceptedFileTypes = ['.xlsx', '.csv', '.xls'];
const EBDataCompleted = () => {
  // isdrag hover state
  const { toggle } = useSnapshot(EmployerCDCStore);

  const theme = useTheme();

  useEffect(() => {}, [toggle]);
  return (
    <Box className="w-full h-max rounded-lg" sx={{ background: '#F8F8F8' }}>
      <Stack
        className="border rounded-lg py-3 flex items-center cursor-pointer"
        spacing={1}>
        <Stack direction={'column'} className="justify-center h-32 flex">
          <Typography className="bk-sub-heading2">
            <b>Candidate Data collection completed</b>
          </Typography>
          <Typography
            className="bk-body3 ml-1"
            sx={{ color: '#55505A', fontSize: '12px' }}>
            Please connect with our team for any queries
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EBDataCompleted;
