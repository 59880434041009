import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { axiosInstance } from 'src/app/utils/services/axios';

export const OpsPlatformCrcUploadList = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Initialize useNavigate

  const goBack = () => {
    globalNavigateTo('/ops-platform/crc-check-list', navigate); // Navigate to the desired route
  };
  const uploadFile = async () => {
    if (!selectedFile) {
      enqueueSnackbar('No file selected', { variant: 'warning' });
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axiosInstance.post(
        endpoints.OPS_CHECK_LIST_IMPORT,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setIsUploading(false);

      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar(response.data.message || 'File uploaded successfully', {
          variant: 'success',
        });
        // Reset the form by clearing the selected file
        setSelectedFile(null);
      } else {
        throw new Error(
          response.data.message ||
            `Error occurred: Status code ${response.status}`
        );
      }
    } catch (error) {
      setIsUploading(false);
      if (error instanceof Error) {
        enqueueSnackbar(`Upload failed: ${error.message}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Upload failed due to an unexpected error', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <section
      style={{
        padding: '20px',
        width: '500px',
        margin: '0px auto',
        marginTop: '20%',
      }}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={goBack}
        style={{ marginBottom: '20px' }}>
        Go Back
      </Button>
      <div style={{ marginBottom: '20px' }}>
        <TextField
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              setSelectedFile(e.target.files[0]);
            }
          }}
          variant="outlined"
          fullWidth
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={uploadFile}
        disabled={isUploading}>
        {isUploading ? <CircularProgress size={24} /> : 'Upload File'}
      </Button>
    </section>
  );
};
