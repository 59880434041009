import { BKStyledButton, EmptyState } from '@gigin-work-space/common-ui';
import { refreshPage } from '@gigin-work-space/utils';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import CircularIndeterminate from '../bgv/component/skelton/bgv-loader';
import LinkExpired from './link-expired/link-expired';
import SignupViaLink from './signup-via-link/signup-via-link';

export const Authentication = () => {
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const data = searchParams.get('data');
  const [loading, setLoading] = useState(true);
  // Function to get user details
  const getUserDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_AUTH_USERDETAILS, {
        params: { data: data },
      });
      if (response.data.success) {
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
        setIsUserRegistered(response.data.data.isRegistered);
        // if already registered then go to login page
        if (response.data.data.isRegistered) {
          globalNavigateTo('/login', navigate);
        }
        return;
      }
      throw new Error(response.data.message);
    } catch (error) {
      //
      setIsLinkExpired(true);
    } finally {
      setLoading(false);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (data) {
      getUserDetails();
    }
  }, [getUserDetails]);
  if (loading) {
    return <CircularIndeterminate />;
  }

  return (
    <>
      {!data && (
        <EmptyState
          maxHeight={'100vh'}
          imageComponent={
            <DisplayImage
              imageName="404.svg"
              imageType="icon"
              width="370px" //todo: px to rem
              height="370px"
            />
          }
          buttonComponent={
            <BKStyledButton
              variant="contained"
              className="bg-bk_action_primary mt-2 rounded-lg"
              onClick={() => {
                refreshPage();
              }}>
              Refresh
            </BKStyledButton>
          }
          title="Not Found"
          description="Start Your first order The page you were looking for doesn't exist."
        />
      )}
      {data && isLinkExpired ? (
        <LinkExpired />
      ) : (
        !isUserRegistered && !isLinkExpired && <SignupViaLink />
      )}
    </>
  );
};
