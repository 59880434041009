import { BKStyledButton } from '@gigin-work-space/common-ui';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Typography } from 'antd';
import { AxiosError, HttpStatusCode } from 'axios';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper, DisplayImage } from 'src/app/components';
import { SelfSignUpSchema, axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { setTenantName } from 'src/app/utils/router/tenantState';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';

interface userDetails {
  role: string;
  isRegistered: string;
  signUpToken: string;
  email: string;
  tenantId: string;
  accountId: string;
  userId: string;
  tenantName: string;
}

const LinkSentSuccess = ({ emailId }: { emailId: string }) => {
  return (
    <AuthenticationPaper>
      <Typography className="bk-headline1 text-bk_text_primary">
        Link sent successfully
      </Typography>
      <Typography className="bK-body1 text-bk_text_secondary mt-1">
        A link has been sent to {emailId}. Please open your mail to set your
        password or login via link
      </Typography>
      <DisplayImage
        imageName="reset_password_sent_illustration.svg"
        imageType="image"
        className="flex justify-center items-center object-contain ml-9"
      />
    </AuthenticationPaper>
  );
};

export function SelfSignUp() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showCheckEmail, setShowCheckEmail] = useState(false);

  const handleSubmit = async (
    values: { userName: string; userEmail: string },
    formikHelpers: { resetForm: any }
  ) => {
    const { resetForm } = formikHelpers;
    try {
      const response = await axiosInstance.post(endpoints.SELF_SIGN_UP, {
        userName: values.userName,
        email: values.userEmail,
      });
      if (response.status === HttpStatusCode.Created) {
        const data: userDetails = response?.data;
        localStorage.setItem('userDetails', JSON.stringify(data));
        localStorage.setItem(
          'verificationToken',
          response?.data?.verificationToken
        );
        if (data) {
          setTenantName(data.tenantName);
        }

        setShowCheckEmail(true);
        resetForm();
      }
      if (response.request.status === HttpStatusCode.Conflict) {
        resetForm();
        enqueueSnackbar(`User already registered please login`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        globalNavigateTo('/login', navigate);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.message || 'An unexpected error occurred. Please try again.';
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const formik = useFormik({
    initialValues: {
      userName: '',
      userEmail: '',
    },
    validationSchema: SelfSignUpSchema,
    onSubmit: handleSubmit,
  });

  // memoized variable to return the boolean value of btn disabled state
  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled;
  }, [formik.values]);

  return (
    <AuthenticationHOC>
      <section className="absolute  left-[-160px] bottom-[-4%]">
        {showCheckEmail ? (
          <LinkSentSuccess emailId={formik.values.userEmail} />
        ) : (
          <AuthenticationPaper>
            <Typography className="bk-headline1 text-bk_text_primary">
              Sign Up
            </Typography>
            <Box
              className="mt-6"
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}>
              <TextField
                id="userName"
                name="userName"
                label="User Name"
                variant="outlined"
                fullWidth
                required
                value={formik.values.userName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.userName && Boolean(formik.errors.userName)
                }
                helperText={formik.touched.userName && formik.errors.userName}
                className="bk-custom-input-style-user"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ 'data-testid': 'userName' }}
              />
              <TextField
                id="userEmail"
                name="userEmail"
                label="Your Email ID"
                variant="outlined"
                fullWidth
                required
                value={formik.values.userEmail.toLowerCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.userEmail && Boolean(formik.errors.userEmail)
                }
                helperText={formik.touched.userEmail && formik.errors.userEmail}
                className="bk-custom-input-style-user"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ 'data-testid': 'userEmail' }}
              />

              <BKStyledButton
                data-testid="submitBtn"
                className="bg-bk_action_primary mt-6"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                disabled={isBtnDisabled}>
                Sign Up
              </BKStyledButton>
              <Typography className="bK-body2 mt-2 text-center">or</Typography>
              <BKStyledButton
                data-testid="loginBtn"
                className="bg-bk_action_primary mt-2 rounded-lg"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => globalNavigateTo('/login', navigate)}>
                Log in
              </BKStyledButton>
            </Box>
          </AuthenticationPaper>
        )}
      </section>
    </AuthenticationHOC>
  );
}
export default React.memo(SelfSignUp);
