/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { BKStyledFormGroup } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import {
  COLORS,
  CORE_USER_ROLE,
  capitalizeString,
  userRoleMap,
} from '@gigin-work-space/utils';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { withViewPermission } from 'src/app/HOC';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';

/* eslint-disable no-unused-vars */
export interface RolesPermissionProps {}

export const RolesPermission = (props: RolesPermissionProps) => {
  const [allRoles, setAllRoles] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [permissionParameters, setPermissionParameters] = useState<any[]>([]);
  const [permissionUpdated, setPermissionUpdated] = useState(false);
  const [role, setRole] = useState<any>(null);
  const [access, setAccess] = useState('Allow access');
  const { canUpdateRoles, canReadRoles, canCreateRoles } =
    useSnapshot(permissionStore);
  const { enqueueSnackbar } = useSnackbar();

  // Function to get all user roles for role DD - DD will have all except super admin
  const getUserRoles = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.FETCH_USER_ROLE);
      if (response.data) {
        const allowedRoles = response.data.filter(
          (data: any) => data.name !== CORE_USER_ROLE.SUPER_ADMIN
        );
        setAllRoles(allowedRoles);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch roles', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  // function to fetch role-permissions when a role is selected
  const getRolePermissions = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_ROLE_PERMISSION}/${role.name}`
      );
      if (response.data.success) {
        const parameterCategory = new Set<any>();
        response.data.data.forEach((element: any) =>
          parameterCategory.add(element.permission_id.category)
        );

        setPermissionParameters(Array.from(parameterCategory));
        setRolePermissions(response.data.data);
        return;
      }
      throw new Error('Failed to fetch permissions');
    } catch (error) {
      enqueueSnackbar('Failed to fetch permissions', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const isAdminRole = () => role?.name === CORE_USER_ROLE.ADMIN;

  // function to toggle permissions to a role
  const toggleRolePermissions = async (
    permissionIds: any,
    allowPermission: boolean
  ) => {
    try {
      const response = await axiosInstance.patch(
        `${endpoints.PATCH_TOGGLE_PERMISSION}/${role._id}/${allowPermission}`,
        { permission_ids: permissionIds }
      );
      if (response.data.success) {
        setPermissionUpdated(!permissionUpdated);
        return;
      }
      throw new Error('Failed to change the permission');
    } catch (error) {
      enqueueSnackbar('Failed to change the permission', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleChangeAccess = (event: SelectChangeEvent<unknown>) => {
    setAccess(event.target.value as string);
  };
  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  // function to toggle checkbox - on click
  // TODO: API to add and remove permission is giving error
  const handleTogglePermission =
    (permission: any) => (event: ChangeEvent<HTMLInputElement>) => {
      if (role.name === CORE_USER_ROLE.ADMIN) {
        return;
      }
      const { checked } = event.target;
      const permissionIds = [];
      permissionIds.push(permission._id);
      toggleRolePermissions(permissionIds, checked);
    };

  // effect to run on page load to fetch roles and permissions for that role
  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  // memoized effect to re-run when role is changed
  useMemo(() => {
    if (role) {
      getRolePermissions();
    }
  }, [role, permissionUpdated]);

  const children = (
    <Stack
      spacing={4}
      className="w-full p-xl2 h-[90vh] overflow-y-auto pb-[10vh]">
      {/* Title section */}
      <Typography className="bk-headline2">
        Role & permission management
      </Typography>
      {/* Role selection DD */}
      <Box sx={{ maxWidth: 312 }}>
        <FormControl fullWidth>
          <InputLabel id="role-select">Select Role</InputLabel>
          <Select
            value={role ? role : ''}
            label="Select Role"
            onChange={handleChangeRole}>
            {allRoles.length &&
              allRoles.map((role: any) => (
                <MenuItem value={role} key={role._id}>
                  {userRoleMap(role.name)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {/* Permissions Section  */}
      <Stack spacing={3}>
        {!permissionParameters.length && (
          <Stack marginTop={3} alignItems={'center'}>
            {!role ? (
              <Typography className="bk-sub-heading3">
                Please Select a role to set permissions.
              </Typography>
            ) : (
              <Typography className="bk-sub-heading3">
                No Permissions found for {userRoleMap(role.name)}.
              </Typography>
            )}
          </Stack>
        )}
        {permissionParameters.length !== 0 &&
          permissionParameters.map((parameter: any) => (
            <Box key={parameter}>
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                sx={{ borderBottom: `1px solid ${COLORS.bk_stroke_primary}` }}>
                <Typography className="bk-sub-heading3">{parameter}</Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="standard">
                    {/* TODO: need to fix not working */}
                    {/* <BKStyledStandardSelect
                      labelId="parameter-group-select-label"
                      id="parameter-group-select-label"
                      value={access}
                      label={access}
                      disableUnderline
                      onChange={handleChangeAccess}>
                      <MenuItem value={'Allow access'}>Allow access</MenuItem>
                      <MenuItem value={'No access'}>No access</MenuItem>
                      <MenuItem value={'View only'}>View only</MenuItem>
                    </BKStyledStandardSelect> */}
                  </FormControl>
                </Box>
              </Stack>
              <Stack spacing={1} marginLeft={2} marginTop={1}>
                {rolePermissions.length !== 0 &&
                  rolePermissions.map(
                    (permission: any) =>
                      permission.permission_id.category === parameter && (
                        <BKStyledFormGroup key={permission.permission_id._id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission.is_active}
                                disabled={isAdminRole() || !canUpdateRoles}
                                onChange={handleTogglePermission(
                                  permission.permission_id
                                )}
                              />
                            }
                            label={capitalizeString(
                              permission.permission_id.title
                            )}
                          />
                        </BKStyledFormGroup>
                      )
                  )}
              </Stack>
            </Box>
          ))}
      </Stack>
    </Stack>
  );

  return withViewPermission({
    permissions: [canReadRoles, canCreateRoles, canUpdateRoles],
    children: children,
  });
};
