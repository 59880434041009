import { removeSelectedFile } from '@gigin-work-space/store';
import { DataCollectionItemSource } from '@gigin-work-space/utils';
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { IPackage, IParsedExcelDetails } from 'src/app/constant/interfaces';

interface OrderCreationState {
  orderInitiationId: string | null;
  selectedPackage: IPackage | null;
  dataCollectionMode:
    | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
    | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
    | null;
  orderId: string | null;
  parsedExcelDetailsCandidate: IParsedExcelDetails | null;
  parsedExcelDetailsRecruiter: IParsedExcelDetails | null;
}

type OrderCreationAction =
  | { type: 'SET_ORDER_INITIATION_ID'; payload: string }
  | { type: 'SET_SELECTED_PACKAGE'; payload: IPackage }
  | {
      type: 'SET_DATA_COLLECTION_MODE';
      payload:
        | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
        | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD;
    }
  | { type: 'SET_ORDER_ID'; payload: string }
  | {
      type: 'SET_PARSED_EXCEL_DETAILS_CANDIDATE';
      payload: IParsedExcelDetails | null;
    }
  | {
      type: 'SET_PARSED_EXCEL_DETAILS_RECRUITER';
      payload: IParsedExcelDetails | null;
    }
  | {
      type: 'CLEAR_PARSED_EXCEL_DETAILS';
      payload:
        | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
        | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD;
    };

const initialState: OrderCreationState = {
  orderInitiationId: null,
  selectedPackage: null,
  dataCollectionMode: null,
  orderId: null,
  parsedExcelDetailsCandidate: null,
  parsedExcelDetailsRecruiter: null,
};

const OrderCreationContext = createContext<
  | {
      state: OrderCreationState;
      dispatch: React.Dispatch<OrderCreationAction>;
    }
  | undefined
>(undefined);

const orderCreationReducer = (
  state: OrderCreationState,
  action: OrderCreationAction
): OrderCreationState => {
  switch (action.type) {
    case 'SET_ORDER_INITIATION_ID':
      return { ...state, orderInitiationId: action.payload };
    case 'SET_SELECTED_PACKAGE':
      return { ...state, selectedPackage: action.payload };
    case 'SET_DATA_COLLECTION_MODE':
      return { ...state, dataCollectionMode: action.payload };
    case 'SET_ORDER_ID':
      return { ...state, orderId: action.payload };
    case 'SET_PARSED_EXCEL_DETAILS_CANDIDATE':
      return { ...state, parsedExcelDetailsCandidate: action.payload };
    case 'SET_PARSED_EXCEL_DETAILS_RECRUITER':
      return { ...state, parsedExcelDetailsRecruiter: action.payload };
    case 'CLEAR_PARSED_EXCEL_DETAILS':
      if (
        action.payload === DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
      ) {
        return { ...state, parsedExcelDetailsRecruiter: null };
      } else if (
        action.payload === DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
      ) {
        removeSelectedFile(); //removing from valtio store(old code is using this)
        return { ...state, parsedExcelDetailsCandidate: null };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const OrderCreationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(orderCreationReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <OrderCreationContext.Provider value={contextValue}>
      {children}
    </OrderCreationContext.Provider>
  );
};

export const useOrderCreationContext = () => {
  const context = useContext(OrderCreationContext);
  if (!context) {
    throw new Error(
      'useOrderCreationContext must be used within an OrderCreationProvider'
    );
  }
  return context;
};
