import { EmployerCDCStore } from '@gigin-work-space/store';
import { Stack } from '@mui/material';
import { useSnapshot } from 'valtio';
import EBMainContent from './employer-bulk-content';
import EBIndividualPackageComponent from './employer-bulk-individual-package';

const EBCandidateDataManagement = () => {
  const { activeStep } = useSnapshot(EmployerCDCStore);
  return (
    <Stack className="mt-6" sx={{ marginTop: '0' }}>
      {activeStep === 0 ? (
        <Stack direction={'column'} spacing={2}>
          <EBIndividualPackageComponent />
        </Stack>
      ) : (
        <EBMainContent />
      )}

      {/* todo: upload component need to manage correctly */}
      {/* <EBUploadModel /> */}
    </Stack>
  );
};

export default EBCandidateDataManagement;
