import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { reportStatusIconMapper } from './report-status-mapper';
import { ImageComponent } from './report-image';

export const CheckColorLegend = () => {
  return (
    <View style={styles.checkLegendContainer} fixed>
      <View style={styles.iconTextContainer}>
        <ImageComponent
          src={reportStatusIconMapper('CLEAR')}
          style={styles.iconImageSmall}
        />
        <Text style={[styles.textBody2, styles.clear]}>Clear</Text>
      </View>
      <View style={styles.iconTextContainer}>
        <ImageComponent
          src={reportStatusIconMapper('DISCREPANT')}
          style={styles.iconImageSmall}
        />
        <Text style={[styles.textBody2, styles.discrepant]}>Discrepant</Text>
      </View>
      <View style={styles.iconTextContainer}>
        <ImageComponent
          src={reportStatusIconMapper('INSUFFICIENT')}
          style={styles.iconImageSmall}
        />
        <Text style={[styles.textBody2, styles.insufficient]}>
          Attention Required / Insufficient
        </Text>
      </View>
      <View style={styles.iconTextContainer}>
        <ImageComponent
          src={reportStatusIconMapper('NO_RESPONSE')}
          style={styles.iconImageSmall}
        />
        <Text style={[styles.textBody2, styles.noResponse]}>
          No Response Received
        </Text>
      </View>
    </View>
  );
};
