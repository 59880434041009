import { EnumsOrderCreationStepLabel } from '@gigin-work-space/utils';
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { OrderCreationStepConfigInterface } from 'src/app/constant/interfaces';

const AddPackage = React.lazy(
  () => import('src/app/features/bgv/component/add-package/add-package')
);

const CandidateDetailUpload = React.lazy(
  () =>
    import(
      'src/app/features/bgv/component/data-collection-component/data-collection-component'
    )
);

interface OrderCreationStepState {
  stepsConfig: OrderCreationStepConfigInterface[];
}

type OrderCreationStepAction =
  | { type: 'MARK_STEP_VISITED'; payload: number }
  | { type: 'MARK_STEP_COMPLETED'; payload: number }
  | { type: 'ADD_NEW_STEP'; payload: OrderCreationStepConfigInterface }
  | { type: 'REMOVE_STEP'; payload: EnumsOrderCreationStepLabel };

const initialState: OrderCreationStepState = {
  stepsConfig: [
    {
      label: EnumsOrderCreationStepLabel.PACKAGES,
      component: <AddPackage />,
      permission: true,
      isCompleted: false,
      isVisited: false,
    },
    {
      label: EnumsOrderCreationStepLabel.CANDIDATE_DETAIL,
      component: <CandidateDetailUpload />,
      permission: true,
      isCompleted: false,
      isVisited: false,
    },
  ],
};

const OrderCreationStepContext = createContext<
  | {
      state: OrderCreationStepState;
      dispatch: React.Dispatch<OrderCreationStepAction>;
    }
  | undefined
>(undefined);

const orderCreationStepReducer = (
  state: OrderCreationStepState,
  action: OrderCreationStepAction
): OrderCreationStepState => {
  switch (action.type) {
    case 'MARK_STEP_VISITED':
      return {
        ...state,
        stepsConfig: state.stepsConfig.map((step, index) =>
          index === action.payload ? { ...step, isVisited: true } : step
        ),
      };
    case 'MARK_STEP_COMPLETED':
      return {
        ...state,
        stepsConfig: state.stepsConfig.map((step, index) =>
          index === action.payload ? { ...step, isCompleted: true } : step
        ),
      };
    case 'ADD_NEW_STEP':
      return {
        ...state,
        stepsConfig: [...state.stepsConfig, action.payload],
      };
    case 'REMOVE_STEP':
      return {
        ...state,
        stepsConfig: state.stepsConfig.filter(
          step => step.label !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const OrderCreationStepProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(orderCreationStepReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <OrderCreationStepContext.Provider value={contextValue}>
      {children}
    </OrderCreationStepContext.Provider>
  );
};

export const useOrderCreationStepContext = () => {
  const context = useContext(OrderCreationStepContext);
  if (!context) {
    throw new Error(
      'useOrderCreationStepContext must be used within an OrderCreationStepProvider'
    );
  }
  return context;
};
