import {
  BKStyledButton,
  EmptyState,
  UnAuthorizedEmptyState,
} from '@gigin-work-space/common-ui';
import { TENANT_NAME } from '@gigin-work-space/utils';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularIndeterminate from 'src/app/features/bgv/component/skelton/bgv-loader';
import { DisplayImage } from '../../components';
import { globalNavigateTo } from './navigateTo';
interface UserData {
  roleName: string;
  account_name: string;
  name: string;
  company_logo: string;
  tenantName: string;
}
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState<string | null>(null);
  const location = useLocation();
  const [isTrue, setIsTrue] = useState<boolean>(false);

  useEffect(() => {
    // Simulate async token retrieval (replace with actual async logic)
    const fetchUserToken = async () => {
      try {
        const token = await localStorage.getItem('auth_token');
        setUserToken(token);
      } catch (error) {
        setUserToken(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserToken();
    // Retrieve user details string from local storage
    const userDetailsString = localStorage.getItem('userAccountDetail');
    // Declare a variable to store user details
    let userDetails: UserData;

    // Check if the current route includes 'ops-platform'
    const isOpsPlatformRoute = location.pathname.includes('ops-platform');
    // Check if user details string is present
    if (userDetailsString) {
      // Parse user details from the stored JSON string
      userDetails = JSON.parse(userDetailsString);

      // Check if the current route is an ops-platform route
      if (isOpsPlatformRoute) {
        // Check if the tenantName in user details is equal to TENANT_NAME.GIGIN
        // If true, set isTrue to true; otherwise, set it to false
        const t = userDetails.tenantName === TENANT_NAME.GIGIN ? false : true;
        setIsTrue(t);
      }
    }
    // Cleanup effect to avoid memory leaks
    return () => {
      setIsLoading(false);
    };
  }, []);

  if (isLoading) {
    // Render loading indicator while fetching token
    return (
      <Typography className="w-full text-center">
        <CircularIndeterminate />
      </Typography>
    );
  }

  if (isTrue) {
    return <UnAuthorizedEmptyState />;
  }
  if (!userToken) {
    return (
      <EmptyState
        maxHeight="100vh"
        imageComponent={
          <DisplayImage
            imageName="404.svg"
            imageType="icon"
            width="370px" //todo: px to rem
            height="370px"
          />
        }
        buttonComponent={
          <BKStyledButton
            variant="contained"
            className="bg-bk_action_primary mt-2 rounded-lg"
            onClick={() => {
              localStorage.setItem('company_settings_tab', 'companyProfile');
              globalNavigateTo('/login', navigate);
            }}>
            Login again
          </BKStyledButton>
        }
        title="Session expired"
        description="Please log in again to continue."
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
