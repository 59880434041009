import { EmployerCDCStore } from '@gigin-work-space/store';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import EBSearchFilter from './employer-bulk-search-filter';
import EBDataMappingTable from './employer-data-mapping-table';
export const EBDataMappingInstructions = () => {
  return (
    <Stack direction={'column'} rowGap={1}>
      <Typography className="bk-sub-heading2">
        What’s to be done here?
      </Typography>
      <Stack direction={'column'} rowGap={2}>
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          You can map the documents uploaded to the respective candidate by
          opening the document in the table.
        </Typography>

        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          Verifyin team will perform this activity by default
        </Typography>
      </Stack>
    </Stack>
  );
};
const EBDataMapping = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const [sortValue, setSortValue] = useState('recent');

  const updateSearchValue = (e: any) => {
    setSearchValue(e?.target?.value ?? '');
  };
  const updateFilterValue = (value: any) => {
    setFilterValue(value ?? '');
  };
  const updateSortValue = (event: SelectChangeEvent<unknown>) => {
    setSortValue(event?.target?.value as string);
  };

  const [tableData, setTableData] = useState([]);

  const updateTableData = (value: any) => {
    setTableData(value);
  };

  const fetchData = useCallback(
    async (paramSearchValue?: string) => {
      const orderId = EmployerCDCStore.request_id;
      const packageId = EmployerCDCStore.request_role_id;
      const { currentPage, itemsPerPage } = EmployerCDCStore.dataMapMeta;
      const fileName = paramSearchValue ?? searchValue;
      try {
        const response = await axiosInstance.get(
          `${
            endpoints.GET_LIST_OF_MAPPED_DATA
          }/${orderId}/${packageId}?page=${Number(
            currentPage
          )}&pageSize=${Number(
            itemsPerPage
          )}&fileName=${fileName}&mapped=${filterValue}&sort=${sortValue}`
        );
        if (response.data) {
          updateTableData(response.data?.data?.data);
          EmployerCDCStore.dataMapMeta = response.data?.data?.meta;
          EmployerCDCStore.step3CurrentStatus = filterValue;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [searchValue, filterValue, sortValue]
  ); //callback will change of searchValue, filterValue, sortValue

  useEffect(() => {
    fetchData();
  }, [filterValue, sortValue, EmployerCDCStore.toggle]);

  // only handle search value
  let timeoutId: NodeJS.Timeout;
  useEffect(() => {
    function debounce(func: () => void, delay: number) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    }
    if (searchValue && searchValue.length > 2) {
      debounce(fetchData, 1000); // Adjust the delay time as needed
    }
  }, [searchValue, EmployerCDCStore.toggle]);
  return (
    <Grid container direction={'row'} justifyContent={'space-between'}>
      <Grid
        item
        container
        rowGap={1}
        direction={'row'}
        sx={{
          width: '65%',
          marginTop: 0,
          marginLeft: 0,
          alignContent: 'stretch',
        }}>
        <EBSearchFilter
          searchValue={searchValue}
          filterValue={filterValue}
          sortValue={sortValue}
          updateFilterValue={updateFilterValue}
          updateSearchValue={updateSearchValue}
          updateSortValue={updateSortValue}
          fetchData={fetchData}
        />
        <EBDataMappingTable tableData={tableData} fetchData={fetchData} />
      </Grid>
      {/* <Grid item className="h-full">
          <Divider
            orientation="vertical"
            sx={{ flexGrow: 1, ml: 3, color: 'red' }}
          />
        </Grid> */}
      <Grid item sx={{ maxWidth: '30%' }}>
        <EBDataMappingInstructions />
      </Grid>
    </Grid>
  );
};

export default EBDataMapping;
