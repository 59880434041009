import { EmployerCDCStore } from '@gigin-work-space/store';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import {
  axiosInstance,
  Candidate,
  DataGroup,
  endpoints,
  generateExcelSheet,
  processData,
} from 'src/app/utils';

interface FetchDataResponse {
  header: DataGroup[];
  listOfDocument: Candidate[];
}

// API call to fetch data
const fetchData = async (): Promise<FetchDataResponse> => {
  const response = await axiosInstance.get(
    `${endpoints.BULK_UPLOAD_TEMPLATE}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
  );
  return response.data.data;
};

//function to generate template
export const generateTemplate = async () => {
  try {
    const { header, listOfDocument } = await fetchData();
    const { subHeaders, nameToLabelMap } = processData(header);
    generateExcelSheet(header, listOfDocument, subHeaders, nameToLabelMap);
  } catch (error) {
    enqueueSnackbar('Failed to download template', {
      variant: 'error',
      autoHideDuration: 2000,
    });
  }
};

const EBNoFiles: React.FC = () => {
  const downloadTemplate = async () => {
    await generateTemplate();
  };

  return (
    <Stack
      spacing={2}
      direction={'column'}
      className="w-full h-1/2 flex justify-center items-center">
      <Stack>
        <Typography>
          No files uploaded yet. You can download the template for candidate
          details below
        </Typography>
      </Stack>
      <Stack>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={downloadTemplate}
          sx={{ '&.MuiButton-root': { textTransform: 'none' } }}>
          Download template
        </Button>
      </Stack>
    </Stack>
  );
};

export default EBNoFiles;
