import { IUserTeams } from '@gigin-work-space/model';
import { BKBody2, COLORS } from '@gigin-work-space/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

interface IFilterProps {
  searchValue: string;
  // eslint-disable-next-line no-unused-vars
  handleSearchValue(event: any): void;
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange(value: IUserTeams): void;
  selectedTeams: IUserTeams[];
  teamOption: IUserTeams[];
  hanldeClearCheckbox(): void;
}

const FilterTeam = ({
  searchValue,
  handleSearchValue,
  selectedTeams,
  handleCheckboxChange,
  teamOption,
  hanldeClearCheckbox,
}: IFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Stack direction={'row'}>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '8px 12px',
                outline: 'none',
                border: 0,
                fontSize: '12px',
                backgroundColor: searchValue ? COLORS.bk_tag_action_bg : '',
                '&:hover': {
                  background: COLORS.bk_tag_action_bg,
                  // color: COLORS.bk_action_primary,
                },
                '&:focus': {
                  // color: COLORS.bk_action_primary,
                },
              },
              // To reducs space of text field input
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
              width: '200px',
            }}
            placeholder="Search by name or email"
            variant="outlined"
            fullWidth
            name="searchValue"
            id="searchValue"
            size="small"
            onChange={handleSearchValue}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      '&:hover': {
                        color: COLORS.bk_action_primary,
                      },
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleSearchValue('');
                    }}>
                    <ClearIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        </Stack>
        <Stack direction={'row'}>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              '& .MuiSvgIcon-root ': {
                color: COLORS.bk_text_secondary,
              },
            }}
            alignItems={'center'}>
            <Typography
              aria-describedby={id}
              onClick={handleClick}
              sx={{
                background: selectedTeams.length
                  ? COLORS.bk_tag_action_bg
                  : 'transparent',
                p: '12px !important',
                borderRadius: 1,
                '&:hover': {
                  background: COLORS.bk_tag_gray_bg,
                },
                '&:focus': {
                  background: COLORS.bk_tag_action_bg,
                },
              }}>
              {/* check with about this option */}
              {teamOption.length ? (
                selectedTeams.length ? (
                  <Box className="bK-body2 text-bk_text_secondary flex items-center cursor-pointer">
                    <FilterAltOutlinedIcon
                      sx={{ ml: 0.5 }}
                      className={COLORS.bk_text_secondary}
                    />{' '}
                    <span className="bK-body2 text-bk_text_secondary">
                      Filter ({selectedTeams.length})
                    </span>
                    <ArrowDropDownIcon sx={{ ml: 0.5 }} />
                  </Box>
                ) : (
                  <Box className="bK-body2 text-bk_text_secondary flex items-center cursor-pointer">
                    <FilterAltOutlinedIcon
                      sx={{ ml: 0.5 }}
                      className={COLORS.bk_text_secondary}
                    />{' '}
                    <span className="bK-body2 text-bk_text_secondary ">
                      Filter Team{' '}
                    </span>
                    <ArrowDropDownIcon
                      sx={{
                        ml: 0.5,
                        borderLeft: `1px solid ${COLORS.bk_bg_primary}`,
                      }}
                    />
                  </Box>
                )
              ) : (
                <></>
              )}
            </Typography>
            <Popover
              onClose={handleClose}
              id={id}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              <Stack direction={'column'} spacing={0} sx={{ p: 1.5 }}>
                <Typography
                  onClick={() => {
                    hanldeClearCheckbox();
                    handleClose();
                  }}
                  textAlign={'right'}
                  className="text-bk_tag_blue_light bK-body3 cursor-pointer">
                  Clear all
                </Typography>
                {teamOption?.map(option => (
                  <FormControlLabel
                    key={option._id}
                    className="bK-body2"
                    sx={{
                      '& .MuiCheckbox-root': {
                        // padding: '4px',
                      },
                      '& .MuiTypography-root': {
                        ...BKBody2,
                      },
                    }}
                    control={
                      <Checkbox
                        checked={selectedTeams?.some(
                          v => v.name === option.name
                        )}
                        onChange={() => handleCheckboxChange(option)}
                      />
                    }
                    label={option.name}
                  />
                ))}
              </Stack>
            </Popover>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default FilterTeam;
