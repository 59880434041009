import { BKStyledButton } from '@gigin-work-space/common-ui';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { AuthenticationPaper } from '../../../components';
import { ForgotPasswordSchema, axiosInstance, endpoints } from '../../../utils';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';
import LinkSentSuccess from '../link-sent-success/link-sent-success';

export function ForgotPassword() {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailRegistered, setIsEmailRegistered] = useState(true);
  const [emailId, setEmailId] = useState('');

  const formik = useFormik({
    initialValues: {
      userName: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsApiLoading(true);
      try {
        const response = await axiosInstance.post(
          endpoints.POST_AUTH_FORGOT_PASSWORD,
          {
            email: values.userName,
          }
        );
        if (response.data.success) {
          setIsEmailSent(true);
          setEmailId(values.userName);
          return;
        }
      } catch (error) {
        //
        setIsEmailRegistered(false);
      } finally {
        setIsApiLoading(false);
        resetForm();
      }
    },
  });

  // function to reset all error and helper text
  const resetHelperText = () => {
    setIsEmailRegistered(true);
  };

  // memoized variable to return the boolean value of btn disabled state
  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled || isApiLoading;
  }, [formik.values, isApiLoading]);

  return (
    <AuthenticationHOC>
      {isEmailSent && <LinkSentSuccess emailId={emailId} />}
      {!isEmailSent && (
        <section className="absolute bottom-[-1%] left-[-160px]">
          <AuthenticationPaper>
            <Typography className="bk-headline1 text-bk_text_primary">
              Forgot password
            </Typography>
            <Typography className="bK-body1 text-bk_text_secondary mt-1">
              A link to reset your password will be sent to your registered mail
              ID
            </Typography>
            <Box
              className="mt-6"
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}>
              <TextField
                id="userName"
                name="userName"
                label="Your Email ID"
                variant="outlined"
                fullWidth
                required
                value={formik.values.userName.toLowerCase()}
                onChange={formik.handleChange}
                onFocus={resetHelperText}
                error={
                  (formik.touched.userName &&
                    Boolean(formik.errors.userName)) ||
                  !isEmailRegistered
                }
                helperText={
                  isEmailRegistered
                    ? formik.touched.userName && formik.errors.userName
                    : 'This email ID is not registered with us. Please contact your admin'
                }
                sx={{
                  marginBottom: '16px',
                  '& .MuiInputBase-root': { borderRadius: '8px !important' },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ 'data-testid': 'userName' }}
              />
              <BKStyledButton
                data-testid="submitBtn"
                className="bg-bk_action_primary mt-2 mb-8 rounded-lg"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                disabled={isBtnDisabled}
                // endIcon={<EastIcon />}
              >
                Send Reset password link
              </BKStyledButton>
            </Box>
          </AuthenticationPaper>
        </section>
      )}
    </AuthenticationHOC>
  );
}

export default ForgotPassword;
