import { EmployerCDCStore } from '@gigin-work-space/store';
import { returnIconExcelOrZip } from '@gigin-work-space/utils';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DisplayImage } from 'src/app/components';
import { fileMediaIdType } from './employer-bulk-file-details';

const EBTableFileDetails = ({ mediaId }: { mediaId: fileMediaIdType }) => {
  return (
    <Stack direction={'row'} spacing={0.5}>
      <DisplayImage
        imageName={returnIconExcelOrZip(mediaId?.file_type)}
        imageType="icon"
        width="28px"
        height="28px"
      />
      <Stack direction={'column'}>
        <Typography className="bk-sub-heading3">{mediaId.file_name}</Typography>
        <Typography className="bK-hint-text">
          ~ {EmployerCDCStore.totalCount}{' '}
          {EmployerCDCStore.totalCount > 1 ? `candidates` : `candidate`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EBTableFileDetails;
