import {
  AUTO_HIDE_TIMEOUT,
  COLORS,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { DisplayImage } from 'src/app/components';
import { endpoints } from '../../../utils';

export const CandidateInstruction = () => {
  const { enqueueSnackbar } = useSnackbar();

  const getTemplate = () => {
    window.open(`${endpoints.GET_TEMPALATE}`, '_blank', 'noreferrer');
    enqueueSnackbar(`Template download Successfully`, {
      variant: VARIANT_TYPE.SUCCESS,
      autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
    });
  };
  return (
    <Stack
      sx={{
        p: 2,
        background: COLORS.bk_tag_yellow_bg,
        ml: 2,
        borderRadius: 2,
      }}>
      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <DisplayImage
          imageName="info-blub-icon.svg"
          imageType="icon"
          width="10.5px"
          height="10.5px"
        />
        <Typography className="bk-sub-heading3 align-middle">
          Instructions
        </Typography>
      </Stack>
      <ol className="bK-btn-small flex flex-col gap-4">
        <li>
          {' '}
          <span
            className="underline text-bk_action_primary cursor-pointer"
            onClick={getTemplate}>
            Download csv template
          </span>{' '}
          and upload the candidates in bulk.
        </li>
        <li>
          Please ensure that no duplicate mobile numbers are added in the
          excel/table
        </li>
        <li>
          When you create the order, mail will be sent to candidates for data
          collection
        </li>
      </ol>
    </Stack>
  );
};
