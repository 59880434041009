export const baseUrl = process.env['NX_REACT_APP_API_URL'];
export const cloudFrontDomain = process.env['NX_CF_DOMAIN'];

// API ENDPOINTS
export const endpoints = {
  // AUTH
  GET_AUTH_USERDETAILS: 'auth/signupLinkInfo',
  POST_AUTH_SIGNIN: 'auth/signin',
  POST_AUTH_SIGNIN_MAGICLINK: 'auth/login/magic_link',
  GET_AUTH_LOGOUT: 'auth/logout',
  POST_AUTH_SIGNUP: 'auth/signupViaLink',
  POST_AUTH_FORGOT_PASSWORD: 'auth/initiate/password-reset',
  POST_AUTH_CREATE_PASSWORD: 'auth/set/password',
  POST_AUTH_CHECK_PASSWORD_STRENGTH: 'auth/password/strength-check',
  POST_AUTH_INITIATE_TOTP: 'auth/initiate-totp',
  POST_AUTH_REGISTER_TOTP_DEVICE: 'auth/register-totp-device',
  POST_AUTH_VERIFY_TOTP: 'auth/authenticate-totp',
  // POST_AUTH_RESET_PASSWORD: 'password-reset/reset',
  SELF_SIGN_UP: 'auth/signup/self',
  // ACCOUNT/COMPANY PROFILE for Login Person
  GET_ACCOUNT_DETAILS: 'account/my_account',
  PATCH_ACCOUNT_DETAILS: 'account',
  // PACKAGE
  GET_PACKAGE_DETAILS: 'package',
  GET_LIST_OF_PACKAGES_OF_ACCOUNT: 'package/list-of-package',
  //PREFERENCES
  ACCOUNT_PREFERENCES: 'account-preferences',
  PREFERENCE_POLICY: 'account-preferences/fetch/policy',

  //PRODUCT LISTING
  PRODUCT_LISTING: 'product-listing',
  // USER
  FETCH_USER_ROLE: '/user-role',
  FETCH_ALL_USER: '/user/all',
  UPDATE_USER: '/user',
  INVITE_EMPLOYERS: '/team/invite-members',
  INVITE_EMPLOYERS_EMAIL: '/team/invited-members/emails',
  VERIFY_OTP: 'otp-verification',
  // PERMISSION
  GET_ROLE_PERMISSION: 'role-permission/role',
  PATCH_TOGGLE_PERMISSION: 'role-permission/update-permission',
  // TEAM
  CREATE_TEAM: '/team/create-new',
  FETCH_TEAM: '/team',
  // Candidates
  GET_CANDIDATES: 'candidate',
  GET_CANDIDATES_BY_ROLE: 'getCandidatesByRole',
  // ORDER MANAGEMENT
  GET_ORDERS_BY_ROLE: 'checkout/summary',
  CREATE_CHECKOUT_INIT: 'checkout-initiation/checkout',
  GET_PRODUCTS: 'getChecks',
  UPDATE_PACKAGE_BY_ROLE: 'checkout',
  CREATE_NEW_ORDER_REQUEST: 'checkout/confirm_request',
  GET_ORDERLIST: 'order',
  GET_CANDIDATE_BY_ORDER_ID: 'order/candidate/list/?order_id=',
  GET_CASES_LIST: 'order/case-list/?page=',
  GET_LOAD_ALL_CONSENT: 'order/all-case/consent-form-data',
  DOWNLOAD_CASE_LIST: 'order/case-list/download',
  GET_TEMPALATE:
    'https://cdn-assets.ai.gigin.ai/bgv-sample-data/candidate-upload-list.xlsx',
  INITIATE_ITEM: 'initiated-item',
  UPDATE_INITIATE_ITEM: 'initiated-item/item',
  FETCH_ROLE: 'job-role',
  CHECKOUT_INITIATION: 'checkout-initiation/',
  INITIAITE_EMAIL: 'order/initiate_data_collection',
  DOWNLOAD_DUBLICATE: 'initiated-item/dublicateitem',
  DOWNLOAD_INVALID: 'initiated-item/invaliditem',
  FETCH_SPECIFIC_CANDIDATE_DATA: 'order-service-request/report',
  ANALYTICS_FETCH: 'order/account/stats/all',
  FETCH_ALL_DRAFT_ORDER: 'checkout-initiation/draft/list',
  ORDER_CHECK_CANCEL: 'order/check/cancel-initiate',
  CONFIRM_ORDER_CHECKOUT: 'checkout/confirm',
  UPDATE_CHECKOUT_INITIATION: 'checkout-initiation/update',
  BULK_UPLOAD_DOCUMENT_LIST: 'bulk-upload/multiple-file-upload',
  BULK_UPLOAD_REMOVE_DOCUMENTS: 'bulk-upload/documents/remove',
  UPLOAD_CANDIDATE_EXCEL_TO_SEND_LINK: 'initiated-item/import/excel',
  // Report
  GET_REPORT_DATA: 'order-service-request/interim/report',
  VSD_TOPFIVE_FETCH: 'order/account/stats/vsd/topfive',
  RWD_ALL: 'order/account/stats/rwd',
  VSD_ALL: 'order/account/stats/vsd',
  VID_ALL: 'order/account/stats/vid',
  TIME_COMPLIENCE: 'order/account/stats/tat',
  QUALITY_ALL: 'order/account/stats/quality',
  POST_FILE_S3: 'media-library/upload',
  INITIATE_BULK_DOWNLOAD: 'order/all-case/download-trigger',
  BULK_DOWNLOAD_REQUEST_LIST: 'process',
  UPLOAD_AND_PARSE_EXCEL_FILE: 'initiated-item/import',
  DELETE_BULK_CANDIDATE_FILE: 'initiated-item',
  GET_BULK_LIST_OF_DOCUMENTS: 'bulk-upload/document-list',
  GET_UPLOADED_BULK_DOCUMENTS: 'bulk-upload/list-of-uploaded/documents',
  // OPS Platform
  LIST_OF_CLIENT_WITH_PACKAGE: 'account/client-list',
  GET_ALL_ACCOUNTS: 'account/opps/flow',
  GET_CASE_LIST: 'order-service-request/opps',
  GET_CHECK_LIST: 'order-service-request-item/opps',
  GET_CHECK_DETAILS: 'order-service-request/checks/details',
  GET_CHECK_DETAIL: 'ops-flow/check-detail',
  UPDATE_CHECK_DETAIL: 'ops-flow/update-check-detail',
  UPDATE_CHECK_DETAIL_OVERALL: 'ops-flow/update-check-detail-overall',
  GET_CASE_DETAIL: 'ops-flow/case-detail',
  VALIDATE_TENANT: 'validate-tenant',
  GET_EB_HEADER_DETAILS: 'bulk-upload/candidate-details',
  PROFILE_PIC_UPDATE: 'data-collection/ops-flow/update',
  // GET_FILE_S3: 'aws-s3/fetch-doc',
  UPLOAD_EMPLOYER_BULK: 'document-storage/upload',
  UPLOAD_EMPLOYER_BULK_S3: 'bulk-upload',
  GET_LIST_OF_PACKAGES: 'bulk-upload/request/order/list-of-packages',
  GENERATE_SAMPLE_DATA_FOR_ORDER: 'upload-file-manager/sampled',
  GET_LIST_OF_MAPPED_DATA: 'upload-file-manager',
  BULK_UPLOAD_FILE_MAPPING: 'bulk-upload/data-mapping/file',
  GET_LIST_OF_CANDIDATE: 'bulk-upload/fetch/list-of-candidate',
  GET_LIST_OF_DOC: 'bulk-upload/upload/list-of-document/info',
  POST_BULK_UPLOAD_IMPORT: 'bulk-upload/import/documents',
  BULK_UPLOAD_STEP_STATUS: 'bulk-upload/stepper/status',
  BULK_UPLOAD_TEMPLATE: 'bulk-upload/excel/generate/download/template',
  BULK_UPLOAD_GET_UNMASKED_CELL_VALUE: 'bulk-upload/get-parameter-value',
  BULK_LINK_SEND: 'notification/bulk-upload/link-send',
  DATA_COLLECTION_UPDATE: 'Data-collection/email-status',
  STATUS_DATA_COLLECTION: 'data-collection/status/data-collection',
  BULK_DATA_COLLECTION_LINK:
    'order-service-request/data-mapping/link-send-status',
  BULK_TEMPLATE_DATA_GET: 'bulk-upload/data-mapping/fetch',
  BULK_TEMPLATE_DATA_PATCH: 'bulk-upload/data-mapping/update',
  BULK_TEMPLATE_DATA_DELETE: 'bulk-upload/data-mapping',
  BULK_UPLOAD_CONFIRM: 'bulk-upload/data-mapping/confirm',
  //OPS-CHECKLIST
  OPS_CHECK_LIST: 'crc-check-list',
  OPS_CHECK_LIST_IMPORT: 'crc-check-list/import',
  OPS_CHECK_GREEN: 'crc-check-list/green',
  GET_SELF_DECLARATION_DETAILS:
    'candidate-data-collection/fetch-data-collection',
  CASE_INFO: 'order-service-request-item',
  CHECK_ASSIGNMENT: 'order-service-request-item/check-assignment',
  OPS_LIST_USERS: 'ops-flow/ops-user/list',
  OPS_FLOW_CHECK: 'ops-flow/verification/check-assignment-details',
  OPS_DETAIL_CHECK_DATA:
    'order-service-request-item/check-assignment/details-items/checks',
  CHECK_ALLOCATION: '/data-collection/vendor-check-allocation-data',
  INDIVIDUAL_CASE: '/ops-individual-case',
};

// Frontend Routes
export const frontendRoutes = {
  CRC_CHECKLIST: '/crc-check-list',
};
