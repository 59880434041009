import { BKStyledButton } from '@gigin-work-space/common-ui';
import { Typography } from '@mui/material';
import { DisplayImage } from 'src/app/components';

export const ErrorPage = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="w-full bg-bk_bg_primary_smoke flex flex-col items-center justify-center gap-4 p-4">
      <DisplayImage imageName="candidate-dc_404.svg" imageType="image" />
      <Typography className="bk-headline3 text-bk_text_primary mt-2 text-center">
        We couldn't find the page you are looking for
      </Typography>
      <BKStyledButton variant="outlined" onClick={handleRefresh}>
        Refresh page
      </BKStyledButton>
    </div>
  );
};
