import { BKStyledTableCell } from '@gigin-work-space/common-ui';
import { EmployerCDCStore } from '@gigin-work-space/store';
import { COLORS, getDateMonth } from '@gigin-work-space/utils';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import { borderLessSelect } from '../initiate-bgv/editable-table/custom-select-cell';
import { CandidateDetail, DataCollectionItem } from './interface';

interface ViewCandidateListModalProps {
  open: boolean;
  onClose: () => void;
  packageInfo: any;
  //   {
  //     candidateDetails: CandidateDetail[];
  //     dataCollectionSurvey: {
  //       status: string;
  //     };
  //   };
  // handleDataCollectionLink: (dataCollectionId: string) => void;
}

const DataCollectionStatus = {
  Completed: 'Completed',
  Initiated: 'Initiated',
  Pending: 'Pending',
};

const ViewCandidateList: React.FC<ViewCandidateListModalProps> = ({
  open,
  onClose,
  packageInfo,
}) => {
  // const [cdcSource, setCDCSource] = useState<string>('');
  const [cdcStatus, setCDCStatus] = useState<string>('');
  const [cdcLinkStatus, setCDCLinkStatus] = useState<any>([]);

  const [packageDetails, setPackageDetails] = useState<CandidateDetail[]>(
    packageInfo.candidateDetails
  );
  const statusOptions = ['All', 'Pending', 'Initiated', 'Completed'];

  // const sourceOptions = [
  //   'All',
  //   'OPS TEAM',
  //   'DATA COLLECTION SURVEY',
  //   'EMPLOYER BULK UPLOAD',
  // ];
  const sendToAllId: string[] = [];

  const dataCollection = async () => {
    try {
      const ids = await Promise.all(
        packageInfo.candidateDetails.map(async (candidate: CandidateDetail) => {
          return candidate.dataCollectionId;
        })
      );

      const data = await axiosInstance.post(
        `${endpoints.STATUS_DATA_COLLECTION}`,
        { ids }
      );

      if (data) setCDCLinkStatus(data.data);
    } catch (error) {
      console.error('Error in dataCollection:', error);
    }
  };

  const sendToAll = async () => {
    await packageInfo.candidateDetails.map(
      async (candidate: CandidateDetail) => {
        sendToAllId.push(candidate.dataCollectionId);
      }
    );
    const response = await handleDataCollectionLink(
      packageInfo.candidateDetails[0].dataCollectionId
    );
    if (response) {
      const linkStatusUpdated = await axiosInstance.patch(
        `${endpoints.BULK_DATA_COLLECTION_LINK}/${EmployerCDCStore.request_id}/${packageInfo?.packageDetails.id}`
      );
      if (linkStatusUpdated.data) {
        enqueueSnackbar('Link sent successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
      }
    }
  };

  const handleDataCollectionLink = async (id: string) => {
    try {
      const uniqueIdsSet = new Set(sendToAllId);

      // Add the new id to the Set
      uniqueIdsSet.add(id);

      // Convert the Set back to an array
      const dataCollectionId = Array.from(uniqueIdsSet);
      const response = await axiosInstance.post(
        `${endpoints.BULK_LINK_SEND}`,
        dataCollectionId
      );

      //Update Data collection status for indivisual invite sent status

      if (response.data.success) {
        await axiosInstance.patch(`${endpoints.DATA_COLLECTION_UPDATE}/${id}`);
        if (response.data.success) {
          enqueueSnackbar('Link sent successfully', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
        }
      }
      return response.data.success;
    } catch (error) {
      enqueueSnackbar('Error while sending link', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  // const handleChangeCDCSource = (event: SelectChangeEvent<string>) => {
  //   const pk: any[] = [];
  //   const selectedValue: string = event.target.value;
  //   if (selectedValue.toLowerCase() === 'all') {
  //     setPackageDetails(packageInfo.candidateDetails);
  //     setCDCSource(selectedValue);
  //     return;
  //   }
  //   packageInfo.candidateDetails.map((candidate: any) => {
  //     if (
  //       candidate.sourceOfData.toLowerCase() === selectedValue.toLowerCase()
  //     ) {
  //       pk.push(candidate);
  //     }
  //   });
  //   setPackageDetails(pk);
  //   setCDCSource(selectedValue);
  // };

  const handleChangeCDCStatus = (event: SelectChangeEvent<string>): void => {
    const selectedValue: string = event.target.value;

    if (selectedValue.toLowerCase() === 'all') {
      setCDCStatus(selectedValue);
      setPackageDetails(packageInfo.candidateDetails);
      return;
    }

    const filteredCandidates = packageInfo.candidateDetails.filter(
      (candidate: any) =>
        candidate.cdcStatus.toLowerCase() === selectedValue.toLowerCase()
    );

    setCDCStatus(selectedValue);
    setPackageDetails(filteredCandidates);
  };

  useEffect(() => {
    setPackageDetails(packageInfo.candidateDetails);
    setCDCStatus('');
    // setCDCSource('');
    dataCollection();
  }, [onClose]);
  const isSent = (dataCollectionId: string) => {
    return cdcLinkStatus.some(
      (item: DataCollectionItem) =>
        item?._id.toString() === dataCollectionId &&
        item?.dataCollectionLink?.status === 'PENDING'
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        m: 5,
        maxHeight: '650px',
        justifyContent: 'center',
      }}>
      <Card sx={{ borderRadius: 6, minHeight: '650px' }}>
        <CardHeader
          title={
            <Typography className="bk-view-candidate-card-package">
              {packageInfo?.packageDetails?.title}
            </Typography>
          }
          action={
            <CloseOutlined
              onClick={onClose}
              style={{
                color: 'black',
                cursor: 'pointer',
              }}
            />
          }
          sx={{ display: 'flex', alignItems: 'center', mr: 2 }}
        />
        <Divider sx={{ width: '100%' }} />
        <CardContent>
          <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            flexWrap="nowrap"
            columnGap={56}>
            {/*Commenting this now s we do not have source data collection based we have the source candidate data collection based now.*/}
            {/* <Grid item className="bk-view-candidate-list-dropdown w-[190]">
              <Typography className="bK-body1">
                Candidate details uploaded via
              </Typography>
              <FormControl
                variant="standard"
                className="bK-body1"
                sx={{ mr: 1, minWidth: 120 }}>
                {!cdcSource && (
                  <InputLabel className="bK-btn-small" sx={{ mb: 4 }}>
                    <u>Any Source</u>
                  </InputLabel>
                )}
                <Select
                  id="filter.source"
                  name="filter.source"
                  value={cdcSource}
                  onChange={handleChangeCDCSource}
                  className="bK-btn-small"
                  sx={{ ...borderLessSelect, mr: 1, maxWidth: 190 }}>
                  {sourceOptions.map(source => (
                    <MenuItem key={source} className="bK-body1" value={source}>
                      {source === '' ? 'Any source' : source}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid
              item
              className="bk-view-candidate-list-dropdown bk-rounded-item">
              <Typography className="bK-body1">Status: </Typography>
              <FormControl
                variant="standard"
                className="bK-body1"
                sx={{ mr: 1, minWidth: 120 }}>
                <Select
                  id="filter.status"
                  name="filter.status"
                  value={cdcStatus}
                  onChange={handleChangeCDCStatus}
                  className="bK-btn-small"
                  sx={borderLessSelect}>
                  {statusOptions.map((status, index) => (
                    <MenuItem key={index} className="bK-body1" value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer
            sx={{
              overflowX: 'hidden',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              marginTop: 4,
              maxHeight: '350px',
              overflowY: 'scroll',
              borderTop: `1px solid ${COLORS.bk_stroke_primary}`,
            }}>
            <Table>
              <TableHead className="bk-data-mapping-table-border bk-stick-table-header">
                <TableRow>
                  <BKStyledTableCell
                    className={`bk-editable-table-header  bk-sub-heading2`}>
                    {`Candidate(${packageDetails.length})`}
                  </BKStyledTableCell>
                  <BKStyledTableCell
                    className={`bk-editable-table-header bk-sub-heading2`}>
                    Mobile No
                  </BKStyledTableCell>
                  <BKStyledTableCell
                    className={`bk-editable-table-header bk-sub-heading2`}>
                    Email Id
                  </BKStyledTableCell>
                  <BKStyledTableCell
                    colSpan={6}
                    className={`bk-editable-table-header bk-sub-heading2`}>
                    Status
                  </BKStyledTableCell>
                  <BKStyledTableCell
                    className={`bk-editable-table-header bk-sub-heading2`}>
                    Actions
                  </BKStyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packageDetails.map(
                  (candidate: CandidateDetail, index: number) => (
                    <TableRow
                      key={index}
                      className="bk-data-mapping-table-border">
                      <BKStyledTableCell className="bk-border-left-stroke-primary">
                        <Typography className="bk-subheading-2">
                          {candidate.name}
                        </Typography>
                      </BKStyledTableCell>
                      <BKStyledTableCell className="bk-border-left-stroke-primary bK-body2">
                        <Typography className="bK-body2">
                          {candidate.mobile}
                        </Typography>
                      </BKStyledTableCell>
                      <BKStyledTableCell className="bk-border-left-stroke-primary bK-body2">
                        {candidate.email}
                      </BKStyledTableCell>
                      <BKStyledTableCell
                        className="bk-border-left-stroke-primary"
                        colSpan={6}>
                        <Grid columnGap={2} className=" bk-view-candidate-cell">
                          <Typography
                            className={`bk-chip-color-completed ${
                              candidate.cdcStatus.toLowerCase() ===
                              DataCollectionStatus.Completed.toLowerCase()
                                ? 'bk-bg-green'
                                : candidate.cdcStatus.toLowerCase() ===
                                  DataCollectionStatus.Pending.toLowerCase()
                                ? 'bk-bg-yellow'
                                : 'bk-bg-grey'
                            }`}>
                            {candidate.cdcStatus}
                          </Typography>

                          <Grid
                            columnGap={5}
                            className="bk-view-candidate-cell">
                            {candidate.cdcStatus.toLowerCase() !==
                              DataCollectionStatus.Completed.toLowerCase() &&
                              cdcLinkStatus.map(
                                (cdc: DataCollectionItem, index: number) => {
                                  if (
                                    cdc._id.toString() ===
                                    candidate.dataCollectionId.toString()
                                  ) {
                                    return (
                                      <Typography
                                        className="bK-body2"
                                        key={index}>
                                        {!cdc?.dataCollectionLink?.count
                                          ? `No action taken`
                                          : `Data collection link sent on ${getDateMonth(
                                              cdc?.dataCollectionLink?.sentAt
                                            )}`}
                                      </Typography>
                                    );
                                  } else return null;
                                }
                              )}

                            {candidate.cdcStatus.toLowerCase() ===
                              DataCollectionStatus.Completed.toLowerCase() &&
                              cdcLinkStatus.map(
                                (cdc: DataCollectionItem, index: number) => {
                                  if (
                                    cdc._id.toString() ===
                                    candidate.dataCollectionId.toString()
                                  ) {
                                    return (
                                      <Typography
                                        className="bK-body2"
                                        key={index}>
                                        {`Data collected by ${getDateMonth(
                                          cdc.updatedAt
                                        )}`}
                                      </Typography>
                                    );
                                  } else return null;
                                }
                              )}
                          </Grid>
                        </Grid>
                      </BKStyledTableCell>
                      <BKStyledTableCell className="bk-border-left-stroke-primary bK-body2">
                        {candidate.cdcStatus.toLowerCase() !==
                          DataCollectionStatus.Completed.toLowerCase() && (
                          <Button
                            className="bk-btn-text-tranform-none bk-padding-Y"
                            color="info"
                            onClick={() => {
                              handleDataCollectionLink(
                                candidate.dataCollectionId
                              );
                            }}>
                            {isSent(candidate.dataCollectionId)
                              ? 'Send link'
                              : 'Re-send link'}
                          </Button>
                        )}
                      </BKStyledTableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Grid
            className="bK-body1 mt-24 mb-6 mx-4"
            container
            display={'flex'}
            alignItems={'baseline'}
            justifyContent={'space-between'}>
            <Grid item>
              <Button
                className="bk-btn-text-tranform-none"
                color="info"
                variant="text"
                onClick={sendToAll}>
                Send link to all pending
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onClose}
                className="px-12 bk-btn-text-tranform-none bk-bg-grey"
                color="inherit"
                variant="contained">
                Close
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ViewCandidateList;
