import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  IMetaProps,
  IMetaPropsInitial,
  IOrderData,
} from '@gigin-work-space/model';
import { CheckoutStore, OrderCandidateStore } from '@gigin-work-space/store';
import {
  COLORS,
  getAccordionColor,
  getDateMonth,
  maxTwoRole,
} from '@gigin-work-space/utils';
import { enqueueSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import CircularIndeterminate from '../skelton/bgv-loader';
import { OrderTable, SkeltonOrderList, StatusProgress } from './components';

export const BgvNewOrdersTable = () => {
  const [ordersList, setOrdersList] = useState<IOrderData[]>([]);
  const [expanded, setExpanded] = useState<string | false>('0');
  const [, setIsFetchingOrders] = useState(false);
  const [metaData, setMetaDeta] = useState<IMetaProps>(IMetaPropsInitial);
  const [page, setPage] = useState(1);
  const [isTimeOut, setTimeOut] = useState(false);
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      //prevent collpasing an accordian on click, if it is already opened
      if (expanded === panel) setExpanded('');
      else {
        // clear state for avoid latency in UI
        OrderCandidateStore.candidateList = [];
        OrderCandidateStore.metaData = IMetaPropsInitial;
        setExpanded(newExpanded ? panel : false);
      }
    };

  const fetchData = async () => {
    setIsFetchingOrders(true);
    setTimeOut(true);

    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_ORDERLIST}?status=confirmed&limit=10&page=${page}`
      );
      if (response.data) {
        setPage(prevPage => prevPage + 1);
        setOrdersList(prevState => [...prevState, ...response.data.data]);
        setMetaDeta(response.data?.meta);
      }
      setTimeOut(false);
    } catch (err) {
      enqueueSnackbar('Error fetching orders', { variant: 'error' });
    } finally {
      setIsFetchingOrders(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [CheckoutStore]);

  // expend Closed JSX
  const BgvNewOrderAccordionSummaryClosed = ({
    order,
    orderColor,
  }: {
    order: IOrderData;
    orderColor: string;
  }) => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '35% 28% 28%',
          gap: '8px',
        }}>
        {/* orderRow */}
        <Stack direction={'row'} spacing={1.5}>
          <ChevronRightIcon sx={{ color: orderColor }} />
          <Stack direction={'column'} columnGap={'0.13 rem'}>
            <Typography color={orderColor} className="bk-sub-heading1">
              Request {order.orderId.toUpperCase()}
            </Typography>
            {order.job_role.length ? (
              <Stack direction={'row'} alignItems={'center'}>
                <Typography className="bK-body2 text-bk_text_secondary">
                  {maxTwoRole(order.job_role)}.
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>

        {/* STATUS ROW */}
        <Stack direction={'column'} className="flex gap-1">
          <Typography className="bK-body2 text-bk_text_primary">
            Status
          </Typography>
          {/* TODO: MultiProgress Bar Package not finalized! */}
          <StatusProgress orderCompleteStatus={order.orderCompletionStatus} />
        </Stack>
        {/* Initiated Row */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-end'}>
          <Typography className="bK-body2 text-bk_text_secondary">
            Initiated: {getDateMonth(order.createdAt)}
          </Typography>
          <Box ml="0.3rem">
            <DisplayImage
              imageName={order.initiatedBy_image}
              imageType="url"
              width="24px"
              height="24px"
              style={{ borderRadius: '50%' }}
            />
          </Box>
        </Stack>
        {/* Invoice Row */}
      </Stack>
    );
  };
  // expend Opened JSX
  const BgvNewOrderAccordionSummaryOpen = ({
    order,
    orderColor,
  }: {
    order: IOrderData;
    orderColor: string;
  }) => {
    const navigate = useNavigate();
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        sx={{}}>
        {/* orderRow & Role */}
        <Stack direction={'row'} spacing={1}>
          <ExpandMoreIcon sx={{ color: orderColor }} />

          <Typography color={orderColor} className="bk-sub-heading1">
            {/* Returning 5char */}
            Request {order.orderId.toUpperCase()}
          </Typography>
          {order.job_role.length ? (
            <Stack direction={'row'} alignItems={'center'} sx={{ ml: 1 }}>
              <Typography className="bK-body2 text-bk_text_secondary">
                {maxTwoRole(order.job_role)}.
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          <Typography
            className="bK-body2 text-bk_text_secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              localStorage.setItem('order_id', order?._id);
              globalNavigateTo(
                `/bgv/bulk-upload/${order?.orderId.toLowerCase()}/candidate-data-management`,
                navigate
              );
            }}>
            <u>View Details</u>
            <OpenInNewIcon sx={{ ml: '4px', fontSize: '14px' }} />
          </Typography>
        </Stack>
        {/* Initiated Row */}
        <Stack direction={'row'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography className="bK-body2 text-bk_text_secondary">
              Initiated: {getDateMonth(order.createdAt)}
            </Typography>
            <Box ml="0.3rem">
              <DisplayImage
                imageName={order.initiatedBy_image}
                imageType="url"
                width="24px"
                height="24px"
                style={{ borderRadius: '50%' }}
              />
            </Box>
          </Stack>
          {/* TODO: accordion expend color behaviour borderLeft need to check as per design */}
        </Stack>
      </Stack>
    );
  };

  return (
    <Box className="pl-sm">
      {/* TODO for future fixing */}
      {/* <SearchFilterComponent /> */}
      {ordersList.length ? (
        <Box>
          <InfiniteScroll
            dataLength={ordersList.length}
            next={fetchData}
            hasMore={!(metaData.totalItems === ordersList.length)}
            loader={<SkeltonOrderList />}
            // TODO: don't remove this height else it won't call hasMore function
            height={500}
            className="infinite">
            <Box
              className="mt-lg"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                my: '10px',
              }}>
              {ordersList.map((order, index) => {
                const orderColor = getAccordionColor(index);
                return (
                  <Accordion
                    key={index}
                    expanded={expanded === index.toString()}
                    onChange={handleChange(index.toString())}
                    disableGutters={true}
                    sx={{
                      '& .MuiAccordionDetails-root': {
                        padding: 0,
                      },
                      '& .MuiAccordionSummary-root': {
                        padding: 0,
                      },
                      '& .MuiAccordion-root.Mui-expanded ': {
                        margin: 0,
                      },
                      '&.Mui-expanded': {
                        borderBottom: 'none',
                      },
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                        py: 1.5,
                        px: '20px',
                        '&.Mui-expanded': {
                          pl: 0,
                        },
                        '&:hover': {
                          '&:not(.Mui-expanded)': {
                            backgroundColor: COLORS.bk_tag_blue_bg,
                          },
                        },
                      },

                      borderLeft:
                        expanded !== index.toString()
                          ? `4px solid ${orderColor}`
                          : '',
                      boxShadow: 'none',
                      borderBottom: `1px solid ${COLORS.bk_stroke_divider}`,
                      // transition: 'all 3s ease', TODO: transition need to add
                    }}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      {expanded === index.toString() ? (
                        <BgvNewOrderAccordionSummaryOpen
                          order={order}
                          orderColor={orderColor}
                        />
                      ) : (
                        <BgvNewOrderAccordionSummaryClosed
                          order={order}
                          orderColor={orderColor}
                        />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {expanded === index.toString() && (
                        <OrderTable
                          expend={expanded === index.toString()}
                          order_id={order._id}
                          orderColor={orderColor}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </InfiniteScroll>
        </Box>
      ) : isTimeOut ? (
        <CircularIndeterminate />
      ) : (
        <Grid className="flex justify-center m-24">
          <DisplayImage
            imageName="files-icon.svg"
            imageType="icon"
            width="79px"
            height="77px"
          />
          <div>
            <Typography className="bk-subheading-2 ml-6 mt-8">
              No data found with applied filters
            </Typography>
          </div>
        </Grid>
      )}
    </Box>
  );
};
