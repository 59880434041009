import { Accordion, BKStyledButton } from '@gigin-work-space/common-ui';
import { BulkDownloadType } from '@gigin-work-space/utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { Typography } from 'antd';
import React, { useState } from 'react';
import { User } from '../../employer-upload/interface';
import { AllCasesConsentAPIResponse } from './all-cases-search-filter-component';

interface DownloadModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  processType: BulkDownloadType;
  totalCandidate: number;
  consentData: AllCasesConsentAPIResponse;
  user: User;
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  open,
  onClose,
  onConfirm,
  processType,
  totalCandidate,
  consentData,
  user,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const viewList = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleClose = () => {
    onClose();
    setIsAccordionOpen(false);
  };
  // Define the dialog content based on the processType
  const dialogContent =
    processType === BulkDownloadType.REPORT
      ? {
          title: `Downloading BGV Report for ${totalCandidate} Candidates`,
          text: (
            <>
              Your download request will take 5-10 minutes to process. We will
              also send you an e-mail at <strong>{user?.email}</strong> once the
              download is complete. Meanwhile, you can continue using our
              platform.
            </>
          ),
        }
      : consentData.totalCandidates - consentData.pendingDeclarations > 0
      ? {
          title: `Downloading Consent form for ${
            consentData.totalCandidates - consentData.pendingDeclarations
          }/${consentData.totalCandidates} Candidates`,
          text: (
            <>
              Your download request will take 5-10 minutes to process. We will
              also send you an e-mail at <strong>{user?.email}</strong> once the
              download is complete. Meanwhile, you can continue using our
              platform.
            </>
          ),
        }
      : {
          title: `No existing consent form`,
          text: (
            <>
              None of the {consentData?.totalCandidates} candidate(s) have
              signed the consent form yet. Please try downloading after the
              consent has been signed by the candidates.
            </>
          ),
        };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        handleClose();
      }}
      maxWidth="md"
      className="overflow-hidden">
      <DialogTitle
        className="p-8"
        display={'flex'}
        justifyContent={'space-between'}>
        <Typography className="bk-headline2 ">
          {dialogContent?.title}
        </Typography>
        <Typography>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              onClose();
              handleClose();
            }}
          />
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className="custom-dialog-content px-8">
        <DialogContentText className="bK-body2">
          {dialogContent.text}
        </DialogContentText>
        <br />
        {processType === BulkDownloadType.CONSENT &&
          consentData?.pendingDeclarations !== 0 &&
          consentData?.pendingDeclarations !== consentData?.totalCandidates && (
            <DialogContentText
              display={'flex'}
              alignItems={'center'}
              className="bK-body2 bk-orange-highlighter">
              Note: Consent form is yet to be generated for{' '}
              {consentData?.pendingDeclarations}/{consentData?.totalCandidates}{' '}
              selected candidates.
              <span
                className="span-color-text cursor-pointer pl-1"
                onClick={viewList}>
                {isAccordionOpen ? 'Collapse list' : 'View list'}
                <ArrowDropDownIcon />
              </span>
            </DialogContentText>
          )}
        {isAccordionOpen &&
          consentData.pendingDeclarations > 0 &&
          processType === BulkDownloadType.CONSENT && (
            <Accordion
              className="mt-4"
              style={{ maxHeight: 'calc(100vh - 500px)', overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="bK-body2">Candidate name</TableCell>
                    <TableCell className="bK-body2">Mobile number</TableCell>
                    <TableCell className="bK-body2">Mail ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consentData.pendingDeclarationsDetails.map(item => (
                    <TableRow className="bK-body2" key={item?.actorId?.mobile}>
                      <TableCell className="bK-body2">
                        {item?.actorId?.name}
                      </TableCell>
                      <TableCell className="bK-body2">
                        {item?.actorId?.mobile}
                      </TableCell>
                      <TableCell className="bK-body2">
                        {item?.actorId?.email}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Accordion>
          )}
      </DialogContent>
      <DialogActions className="p-8">
        {/* REPORT: Always show download button
        CONSENT: Show download only when atleast 1(one) are consent forms created */}
        {processType === BulkDownloadType.REPORT ||
        (processType === BulkDownloadType.CONSENT &&
          consentData.totalCandidates - consentData.pendingDeclarations > 0) ? (
          <>
            <BKStyledButton
              onClick={() => {
                onClose();
                handleClose();
              }}>
              Cancel
            </BKStyledButton>
            <BKStyledButton
              onClick={onConfirm}
              color="primary"
              variant="contained">
              Start Download
            </BKStyledButton>
          </>
        ) : (
          <BKStyledButton
            onClick={() => {
              onClose();
              handleClose();
            }}
            className="w-[164px]"
            color="primary"
            variant="contained">
            Okay
          </BKStyledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DownloadModal;
