import * as XLSX from 'xlsx';

export interface DataGroup {
  field_product_type_group: string;
  objects: Array<{ name: string; label: string } | string>;
}

export interface Candidate {
  dataCollectionId: string;
  name: string;
  collectedData: Array<{
    parameter_label: string;
    data_collected: { value: string };
  }>;
}

// Process data to extract headers and subheaders
export const processData = (dataGroups: DataGroup[]) => {
  if (!Array.isArray(dataGroups)) {
    throw new Error('Invalid dataGroups parameter');
  }

  const header: string[] = [];
  const nameToLabelMap: { [key: string]: string } = {};
  const flattenData = dataGroups.reduce<string[]>((acc, group) => {
    if (!group || !Array.isArray(group.objects)) {
      return acc;
    }

    const objects = group.objects
      .map(obj => {
        if (typeof obj !== 'string' && obj !== null && obj !== undefined) {
          header.push(obj.label);
          nameToLabelMap[obj.name] = obj.label;
          return obj.label;
        } else if (typeof obj === 'string') {
          header.push(obj);
          return obj;
        }
        return '';
      })
      .filter(Boolean); // Remove empty strings
    return [...acc, ...objects];
  }, []);
  return { header, subHeaders: flattenData, nameToLabelMap };
};

// Generate Excel sheet
export const generateExcelSheet = (
  dataGroups: DataGroup[],
  candidateData: Candidate[],
  subHeaders: string[],
  nameToLabelMap: { [key: string]: string }
) => {
  if (
    !Array.isArray(dataGroups) ||
    !Array.isArray(candidateData) ||
    !Array.isArray(subHeaders) ||
    typeof nameToLabelMap !== 'object'
  ) {
    throw new Error('Invalid parameters');
  }

  const workbook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
  const merges: XLSX.Range[] = [];
  let colIndex = 0;

  dataGroups.forEach(dataGroup => {
    if (!dataGroup || !Array.isArray(dataGroup.objects)) {
      return;
    }

    const header = dataGroup.field_product_type_group;
    const subheaderCount = dataGroup.objects.length;

    worksheet[0] = worksheet[0] || [];
    worksheet[0][colIndex] = header;

    if (subheaderCount > 1) {
      merges.push({
        s: { c: colIndex, r: 0 },
        e: { c: colIndex + subheaderCount - 1, r: 0 },
      });
    }

    colIndex += subheaderCount;
  });

  const mergedWorksheet = XLSX.utils.aoa_to_sheet([worksheet[0], subHeaders]);

  candidateData.forEach((candidate, i) => {
    if (!candidate || !Array.isArray(candidate.collectedData)) {
      return;
    }

    const rowValues = Array(subHeaders.length).fill('');
    rowValues[0] = candidate.dataCollectionId || '';
    rowValues[1] = candidate.name || '';

    candidate.collectedData.forEach(item => {
      if (!item || !item.parameter_label || !item.data_collected) {
        return;
      }

      const label = nameToLabelMap[item.parameter_label];
      const columnIndex = subHeaders.indexOf(label);
      if (columnIndex !== -1) {
        rowValues[columnIndex] = item.data_collected.value || '';
      }
    });

    XLSX.utils.sheet_add_aoa(mergedWorksheet, [rowValues], { origin: 2 + i });
  });

  mergedWorksheet['!merges'] = merges;
  XLSX.utils.book_append_sheet(workbook, mergedWorksheet, 'Sheet1');
  XLSX.writeFile(workbook, 'Template.xlsx', { bookSST: true });
};
