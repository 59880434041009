import { StyledAuthenticationPaper } from './authentication-paper-styled';
export interface AuthenticationPaperProps {
  children: React.ReactNode;
  elevation?: number;
}

export function AuthenticationPaper(props: AuthenticationPaperProps) {
  const { children, ...rest } = props;
  return (
    <StyledAuthenticationPaper {...rest} elevation={2}>
      {children}
    </StyledAuthenticationPaper>
  );
}

export default AuthenticationPaper;
