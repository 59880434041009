/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IValidateCandidate } from '@gigin-work-space/model';
import { CheckoutStore, removeSelectedFile } from '@gigin-work-space/store';
import {
  COLORS,
  DataCollectionItemSource,
  EnumIndexedDbContextKey,
  EnumIndexedDbStore,
  FILE_TYPE,
  getItem,
  MAX_FILE_SIZE,
  ONE_MB,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IParsedExcelDetails } from 'src/app/constant/interfaces';
import { useOrderCreationContext } from 'src/app/context';
import { endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import { DisplayImage } from '../../../components';
import { axiosInstance } from '../../../utils/services/axios';
import { IpersistedData } from './candidate-detail-upload/candidate-detail-upload';

/**
 * Interface for the component props.
 */
export interface CandidateDragDropProps {
  candidates: IValidateCandidate[];
  UpdateCandidates?: (
    candidates: IValidateCandidate[],
    parsedData: IParsedExcelDetails
  ) => void;
  tableHeight?: number;
  onFileUploadSuccess?: (responseData: any, message: string) => void;
  onFileUploadError?: (error: string | Error) => void;
  packageId: string;
}

/**
 * Enum for file validation messages.
 */
enum FileValidationMessages {
  SUCCESS = 'Candidates imported successfully.',
  FORMAT_ERROR = 'Candidate upload failed. Please check the file format.',
  SIZE_ERROR = `File exceeds the size limit of ${MAX_FILE_SIZE / ONE_MB}MB.`,
  REJECTED = 'File was rejected.',
}

/**
 * Media data configuration for file upload.
 */
const MEDIA_DATA = {
  field_id: 'initiated-item-excel',
  isEncrypted: false,
  filePathContext: 'initiated-item',
  isTenantIsolated: true,
  isPrivate: true,
  shouldCompress: false,
};

/**
 * Converts a file size from kilobytes to megabytes.
 * @param size File size in bytes.
 * @returns File size in megabytes.
 */
const convertKiloByteToMegaByte = (size: number): string => {
  return `${(size / ONE_MB).toFixed(2)} MB`;
};

/**
 * Drag and Drop component for uploading candidate files.
 * @param props Component props.
 * @returns JSX Element.
 */
export function DragDropComponent({
  UpdateCandidates,
  packageId,
  onFileUploadSuccess = () => {},
  onFileUploadError = () => {},
}: CandidateDragDropProps) {
  const acceptedFileTypes = useMemo(() => FILE_TYPE.CSV, []);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [files, setFiles] = useState<File[]>([]);
  const [isDragHover, setIsDragHover] = useState(false);
  const [isRemovingFiles, setIsRemovingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { initiate_id, currentPage } = useSnapshot(CheckoutStore);
  const abortControllerRef = useRef<AbortController | null>(null);
  const {
    dispatch: orderDispatch,
    state: { parsedExcelDetailsCandidate },
  } = useOrderCreationContext();

  /**
   * Fetches the Excel candidate list from the server.
   * @returns Entire response data from the server.
   */
  const fetchExcelCandidateList = useCallback(async (): Promise<any> => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.UPDATE_INITIATE_ITEM}/${initiate_id}?page=${currentPage}&limit=10`,
        { signal: abortControllerRef.current?.signal }
      );
      CheckoutStore.totalCandidate = response.data?.meta?.totalItems || 0;
      if (response.data) {
        CheckoutStore.meta = response.data.meta;
        return response.data; // Return the entire response data
      } else {
        return {};
      }
    } catch (error: any) {
      enqueueSnackbar('Failed to fetch candidate list.', {
        variant: VARIANT_TYPE.ERROR,
      });
      return {}; // Return empty object on error
    }
  }, [initiate_id, currentPage, enqueueSnackbar]);

  /**
   * Handles the file upload process.
   * @param file File to upload.
   */
  const handleFileUpload = useCallback(
    async (file: File) => {
      if (!file) return;

      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        removeSelectedFile();
        onFileUploadError(FileValidationMessages.SIZE_ERROR);
        return;
      }

      const formData = new FormData();
      formData.append('files', file);
      formData.append('media', JSON.stringify(MEDIA_DATA));
      formData.append('checkoutInitationId', initiate_id || '');
      formData.append('packageId', packageId || '');
      formData.append(
        'excelImportFor',
        DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
      );

      // Initialize AbortController
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      try {
        CheckoutStore.filedata = formData;
        CheckoutStore.isLoading = true;
        setIsLoading(true);

        if (initiate_id) {
          const response = await axiosInstance.post(
            `${endpoints.UPLOAD_AND_PARSE_EXCEL_FILE}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (response?.data?.statusCode === HttpStatus.OK) {
            const responseData = await fetchExcelCandidateList();
            const parsedExcelDetails: IParsedExcelDetails = response.data?.data;

            const candidateParsedData: IpersistedData = {
              candidateList: responseData?.data ?? [],
              parsedExcelDetails: {
                ...parsedExcelDetails,
                totalItems: responseData?.meta?.totalItems ?? 0,
                validRecords: responseData?.meta?.validItem ?? 0,
              },
            };

            if (response?.data?.data?.totalItems === 0) {
              setFiles([]);
              CheckoutStore.UploadFileName = '';
              CheckoutStore.UploadFileSize = 0;
            }
            onFileUploadSuccess(
              candidateParsedData,
              FileValidationMessages.SUCCESS
            );
          }
        }
      } catch (error: any) {
        removeSelectedFile();
        const errorMessage = error?.response?.data?.message;
        onFileUploadError(errorMessage || FileValidationMessages.FORMAT_ERROR);
      } finally {
        CheckoutStore.isLoading = false;
        setIsLoading(false);
      }
    },
    [
      enqueueSnackbar,
      onFileUploadSuccess,
      onFileUploadError,
      initiate_id,
      fetchExcelCandidateList,
    ]
  );

  /**
   * Handles the drop event when files are dragged and dropped.
   * @param acceptedFiles Array of accepted files.
   */
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        setFiles(prevFiles => [...prevFiles, file]);
        CheckoutStore.UploadFileName = file.name;
        CheckoutStore.UploadFileSize = file.size;
        handleFileUpload(file);
      }
    },
    [handleFileUpload]
  );

  /**
   * Configures the dropzone with validation and event handlers.
   */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver: () => setIsDragHover(true),
    onDragLeave: () => setIsDragHover(false),
    accept: Array.isArray(acceptedFileTypes)
      ? acceptedFileTypes.reduce((acc: { [key: string]: any[] }, type) => {
          acc[type] = [];
          return acc;
        }, {})
      : acceptedFileTypes,
    multiple: false,
  });

  /**
   * Memoized file detail component.
   */
  const fileDetail = useMemo(
    () => (
      <Typography className="bK-body2">
        {CheckoutStore.UploadFileName} -{' '}
        {convertKiloByteToMegaByte(CheckoutStore.UploadFileSize)}
      </Typography>
    ),
    [CheckoutStore.UploadFileName, CheckoutStore.UploadFileSize]
  );

  /**
   * Cleanup function to abort any ongoing requests when the component unmounts.
   */
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  /**
   * Handles the removal of the uploaded file.
   * TODO: Add api call to remove the file from the server
   */
  const handleRemoveFile = useCallback(async () => {
    setIsRemovingFile(true);
    try {
      const deleteCandidateFile = await axiosInstance.delete(
        `${endpoints.DELETE_BULK_CANDIDATE_FILE}/${initiate_id}/${parsedExcelDetailsCandidate?.mediaDetails?.media_id}/${DataCollectionItemSource.CANDIDATE_DATA_COLLECTION}`
      );
      if (deleteCandidateFile?.data?.statusCode === HttpStatus.OK) {
        setFiles([]);
        removeSelectedFile();
        const responseData = await fetchExcelCandidateList();
        const persistedData = (await getItem(
          EnumIndexedDbStore.FILES,
          EnumIndexedDbContextKey.BGV_CANDIDATE_LINK_CSV_UPLOAD
        )) as IpersistedData;

        const candidateParsedData: IpersistedData = {
          candidateList: responseData?.data ?? [],
          parsedExcelDetails: {
            ...persistedData?.parsedExcelDetails,
            mediaDetails: null,
            totalItems: responseData?.meta?.totalItems ?? 0,
            validRecords: responseData?.meta?.validItem ?? 0,
          },
        };
        CheckoutStore.meta = responseData?.meta;
        if (UpdateCandidates) {
          UpdateCandidates(
            candidateParsedData.candidateList,
            candidateParsedData.parsedExcelDetails
          );
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to remove the file', {
        variant: VARIANT_TYPE.ERROR,
      });
    } finally {
      setIsRemovingFile(false);
    }
  }, [UpdateCandidates, initiate_id, parsedExcelDetailsCandidate]);

  return (
    <Box>
      {!CheckoutStore.UploadFileName ? (
        <Stack
          className="bg-bk_bg_primary border-dashed border border-bk_action_primary bk-flex-row-item-content-center rounded-lg py-6 cursor-pointer"
          spacing={1}
          {...getRootProps()}
          role="button"
          tabIndex={0}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              // Trigger file selection
              // For accessibility, you might want to trigger the file dialog here
            }
          }}
          aria-label="File Upload Dropzone">
          <input type="file" {...getInputProps()} style={{ display: 'none' }} />
          <Stack
            sx={{ py: isNotExtraLargeScreen ? 2 : 2 }}
            alignItems={'center'}
            justifyContent={'center'}>
            <DisplayImage
              imageName="upload-icon.svg"
              imageType="icon"
              height="76px"
              width="94px"
            />
          </Stack>
          <Stack direction="column" className="flex items-center">
            <Typography className="bk-sub-heading2">
              Drag & drop or{' '}
              <span style={{ color: COLORS.bk_tag_blue }}>Choose file</span> to
              upload
            </Typography>
            <Typography
              className="bk-body3"
              sx={{ color: '#55505A', fontSize: 11 }}>
              Supported formats: CSV, XLS, XLSX
            </Typography>
          </Stack>
        </Stack>
      ) : isLoading || isRemovingFiles ? (
        <Stack
          className="bg-bk_bg_primary border-dashed border border-bk_action_primary bk-flex-row-item-content-center rounded-lg py-6 cursor-pointer"
          spacing={1}
          {...getRootProps()}>
          <DisplayImage
            imageName="loading-three-dot.gif"
            imageType="image"
            width="52px"
            height="52px"
          />
        </Stack>
      ) : (
        <Stack
          className="border-dashed border border-bk_action_primary rounded-lg p-6 cursor-pointer"
          sx={{ background: '#FBFCFC' }}
          spacing={1}
          role="button"
          tabIndex={0}
          onClick={() => {
            // Optionally handle click if needed
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              // Optionally handle key press if needed
            }
          }}
          aria-label="Uploaded File Details">
          <Stack
            direction="row"
            className="flex items-center justify-between"
            spacing={2}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <DisplayImage
                imageName="excel-icon.svg"
                imageType="icon"
                width="16px"
                height="16px"
              />
              {fileDetail}
              <Typography className="bK-hint-text text-bk_text_secondary ml-2">
                File uploaded
              </Typography>
            </Stack>
            <Typography
              className="bK-body3 text-bk_action_primary underline"
              onClick={handleRemoveFile}
              role="button"
              tabIndex={0}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleRemoveFile();
                }
              }}
              aria-label="Remove Uploaded File">
              Remove
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
