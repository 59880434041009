import { Grid, Stack, Typography } from '@mui/material';

/* eslint-disable-next-line */
export interface EmptyStateProps {
  title?: string;
  imageComponent?: React.ReactNode;
  buttonComponent?: React.ReactNode;
  description?: string;
  maxHeight?: string;
}

export function EmptyState({ title, imageComponent, buttonComponent, description, maxHeight }: EmptyStateProps) {
  return (
    <Grid
      container
      alignItems={'center'}
      direction={'row'}
      justifyContent={'center'}
      sx={{
        display: 'flex',
        height: `${maxHeight}`,
        overflow: 'hidden',
      }}>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        {/* Your Image goes here */}
        {imageComponent}
      </Grid>
      <Grid
        item
        container
        direction="column"
        rowSpacing={5}
        xs={12}
        lg={6}
        sx={{ display: 'flex', overflow: 'hidden' }}>
        {/* Your content goes here */}
        <Grid item>
          <Stack spacing={1.5}>
            <Typography className="bk-error text-bk_text_primary">{title}</Typography>
            <Typography className="bk-headline3 text-bk_text_secondary">{description}</Typography>
          </Stack>
        </Grid>
        <Grid item>{buttonComponent}</Grid>
      </Grid>
    </Grid>
  );
}

export default EmptyState;
