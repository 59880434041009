import { EmployerCDCStore } from '@gigin-work-space/store';
import {
  firstLetterReturner,
  getAccordionColor,
  maxTwoRole,
} from '@gigin-work-space/utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Avatar, Typography } from '@mui/material';
import { Stack } from '@mui/system';
const EBMainHeader = () => {
  const avatarStyle = {
    backgroundColor: getAccordionColor(4),
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  };
  const jobRoles = EmployerCDCStore.currentJobRoles;
  return (
    <Stack
      direction={'row'}
      spacing={2}
      className="items-center mx-4 mt-4 h-max">
      <ArrowBackIosIcon
        className="cursor-pointer"
        onClick={() => {
          EmployerCDCStore.activeStep = 0;
        }}
      />
      <Stack direction={'row'} className="items-center h-max" columnGap={1}>
        <Avatar variant="square" style={avatarStyle}>
          {firstLetterReturner(EmployerCDCStore.currentPackage)}
        </Avatar>
        <Stack direction={'column'} rowGap={0.5}>
          <Typography className="bk-headline2">
            {EmployerCDCStore.currentPackage}
          </Typography>
          <Typography className="bK-body2">
            {jobRoles.length ? (
              <Stack direction={'row'} alignItems={'center'}>
                <Typography className="bK-body2 text-bk_text_secondary">
                  {maxTwoRole(jobRoles)}.
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EBMainHeader;
