import { ThemeOptions } from '@mui/material';

export const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
  },
  customTheme: {
    width: {
      custom_90: '90px',
      custom_94: ' 94px',
      custom_300: '300px',
      custom_40: '40px',
    },
    height: {
      custom_56: '56px',
      custom_4: '4px',
    },
    fontSize: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
      custom_34: '34px',
    },
    fontFamily: {
      roboto: 'Roboto',
      helvetica_neue: 'Helvetica Neue',
      inter: 'Inter',
    },
    fontWeight: {
      light: '300px',
      normal: '400px',
      medium: '500px',
      bold: '600px',
      bolder: '700px',
    },
    color: {
      white: '#ffffff',
      authentication_container: '#fcfaf6;',
      authentication_divider: '#878587',
      black_rgb87: 'rgba(0, 0, 0, 0.87)',
      black_rgb23: 'rgba(0, 0, 0, 0.23)',
      black_rgb6: 'rgba(0, 0, 0, 0.6)',
      company_ribbon: 'rgba(33, 150, 243, 0.04)',
      link_text: '#0085ff',
      normal_text: '#242224',
      error_text: '#d32f2f',
      success_text: '#2e7d32',
      orange_warning: '#ed6c02',
      orange: '#ff9800',
    },
    boxShadow: {
      authentication_paper: '1px solid rgba(0, 0, 0, 0.23)',
    },
    border: {
      normal_black: '1px solid rgba(0, 0, 0, 0.23)',
      normal_error: '1px solid #d32f2f',
      normal_selected: '1px solid #2196f3',
    },
    borderRadius: {
      authentication_paper: '0px 120px',
      custom_4: '4px',
      custom_16: '16px',
      custom_32: '32px',
    },
    margin: {
      header_emoji: '-8px',
      horizontal_otp_input: '4px',
      signup_option_err: '0 0 0.25rem 14px',
    },
    padding: {
      horizontal_otp_input: '12px',
    },
    gap: {
      custom_4: '4px',
      custom_10: '10px',
      custom_6_percentage: '6%',
      custom_15: '15px',
    },
  },
};
