import { BKStyledStandardSelect } from '@gigin-work-space/common-ui';
import { EmployerCDCStore } from '@gigin-work-space/store';
import { COLORS, FILTER } from '@gigin-work-space/utils';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect } from 'react';

/* eslint-disable no-unused-vars */
interface IEBSearchFilterProps {
  searchValue: string;
  filterValue: string;
  sortValue: string;
  updateSearchValue: (value: any) => void;
  updateFilterValue: (value: any) => void;
  updateSortValue: (value: any) => void;
  fetchData: (x: string) => void;
}

const EBSearchFilter = ({
  searchValue,
  updateSearchValue,
  filterValue,
  updateFilterValue,
  updateSortValue,
  sortValue,
  fetchData,
}: IEBSearchFilterProps) => {
  useEffect(() => {
    updateFilterValue(FILTER.All);
  }, []);
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      className="flex items-center w-full">
      <Stack direction={'row'} columnGap={4}>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: '8px 12px',
              outline: 'none',
              border: 0,
              fontSize: '12px',
              backgroundColor: searchValue ? COLORS.bk_tag_action_bg : '',
              '&:hover': {
                background: COLORS.bk_tag_action_bg,
                // color: COLORS.bk_action_primary,
              },
              '&:focus': {
                // color: COLORS.bk_action_primary,
              },
            },
            // To reducs space of text field input
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },
            width: '200px',
          }}
          placeholder="Search"
          variant="outlined"
          fullWidth
          name="searchValue"
          id="searchValue"
          size="small"
          onChange={updateSearchValue}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* ClassName css not working */}
                <SearchIcon
                  sx={{
                    height: '16px',
                    width: '16px',
                    '&:hover': {
                      color: COLORS.bk_action_primary,
                    },
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: searchValue ? (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await updateSearchValue('');
                    fetchData('');
                  }}>
                  <ClearIcon style={{ cursor: 'pointer' }} />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
        <Stack direction={'row'} columnGap={1} className="flex items-center">
          <Box
            className="px-2 py-1 rounded-lg cursor-pointer"
            onClick={() => {
              EmployerCDCStore.dataMapMeta.currentPage = 1;
              updateFilterValue(FILTER.All);
            }}
            sx={{
              border:
                filterValue === FILTER.All
                  ? `1px solid ${COLORS.bk_action_primary}`
                  : `1px solid ${COLORS.bk_transparent}`,
              color:
                filterValue === FILTER.All
                  ? ` ${COLORS.bk_action_primary}`
                  : `${COLORS.bk_text_secondary}`,
              background:
                filterValue === FILTER.All
                  ? `${COLORS.bk_tag_action_bg}`
                  : `#F8F8F8`,
            }}>
            All
          </Box>
          <Box
            className="px-2 py-1 rounded-lg cursor-pointer"
            onClick={() => {
              EmployerCDCStore.dataMapMeta.currentPage = 1;
              updateFilterValue(FILTER.MAPPED);
            }}
            sx={{
              border:
                filterValue === FILTER.MAPPED
                  ? `1px solid ${COLORS.bk_action_primary}`
                  : `1px solid ${COLORS.bk_transparent}`,
              color:
                filterValue === FILTER.MAPPED
                  ? ` ${COLORS.bk_action_primary}`
                  : `${COLORS.bk_text_secondary}`,
              background:
                filterValue === FILTER.MAPPED
                  ? `${COLORS.bk_tag_action_bg}`
                  : `#F8F8F8`,
            }}>
            Mapped
          </Box>
          <Box
            className="px-2 py-1 rounded-lg cursor-pointer"
            onClick={() => {
              EmployerCDCStore.dataMapMeta.currentPage = 1;
              updateFilterValue(FILTER.UNMAPPED);
            }}
            sx={{
              border:
                filterValue === FILTER.UNMAPPED
                  ? `1px solid ${COLORS.bk_action_primary}`
                  : `1px solid ${COLORS.bk_transparent}`,
              color:
                filterValue === FILTER.UNMAPPED
                  ? ` ${COLORS.bk_action_primary}`
                  : `${COLORS.bk_text_secondary}`,
              background:
                filterValue === FILTER.UNMAPPED
                  ? `${COLORS.bk_tag_action_bg}`
                  : `#F8F8F8`,
            }}>
            Un-mapped
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        columnGap={1}
        className="flex items-center whitespace-nowrap w-max">
        <Typography>Sort By:</Typography>
        <FormControl fullWidth variant="standard">
          <BKStyledStandardSelect
            labelId="suggestive-sort-select-label"
            id="suggestive-sort-select-label"
            value={sortValue}
            label={sortValue}
            disableUnderline
            onChange={updateSortValue}>
            <MenuItem value={FILTER.DESC}>Recent</MenuItem>
            <MenuItem value={FILTER.ASC}>Default</MenuItem>
          </BKStyledStandardSelect>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default EBSearchFilter;
