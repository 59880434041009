import { BKStyledButton } from '@gigin-work-space/common-ui';
import { Box, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';
import { DisplayImage } from 'src/app/components';

interface ReportTabAccordianProps {
  request: any;
  handleGenerateReport?: (_event: SyntheticEvent) => void;
  handleGenerateConsolidatedReport: (_event: SyntheticEvent) => void;
}

const ViewReport = ({
  request,
  handleGenerateConsolidatedReport,
}: ReportTabAccordianProps) => {
  return (
    <>
      {request?.view_report && (
        <>
          <Box className="flex items-center justify-between bg-bk_beige rounded-lg pt-3 mt-4 mb-6">
            <Box className="flex items-center gap-3">
              <DisplayImage imageName="consolidate.svg" imageType="icon" />
              <Box className="flex flex-col gap-1 justify-center items-start">
                <Typography className="bk-headline3 font-semibold">
                  Our quality reports now sustainable!
                </Typography>
                <Typography className="max-w-xl text-bk_text_secondary bK-body1">
                  Introducing our sustainable reports that delivers the same
                  quality and accuracy, while reducing paper usage for a
                  greener, more efficient process.
                </Typography>
              </Box>
            </Box>
            <BKStyledButton
              className="py-1 px-4 text-bk_text_primary bk-view-report-border bK-body1 mr-6 mb-5"
              onClick={handleGenerateConsolidatedReport}>
              View Report
            </BKStyledButton>
          </Box>
        </>
      )}
    </>
  );
};

export default ViewReport;
