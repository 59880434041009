import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { ReportDataInterface } from '../constants/interfaces/ReportDataInterface';
import { styles } from '../styles';
import { reportISOImage, verifyInByGiginDark } from './image-library';
import { ImageComponent } from './report-image';
import { COLORS, SPACING } from '@gigin-work-space/utils';

interface ReportHeaderConsolidatedProps {
  reportDataObject: ReportDataInterface;
}

const ReportHeaderConsolidated = ({
  reportDataObject,
}: ReportHeaderConsolidatedProps) => {
  return (
    <View>
      <View style={styles.reportHeaderConsolidated}>
        <ImageComponent
          src={verifyInByGiginDark}
          style={styles.reportHeaderConsolidatedHeroImage}
        />
        <ImageComponent
          src={reportISOImage}
          style={styles.reportHeaderConsolidatedHeroImage}
        />
        <View
          style={[
            styles.candidateDetails,
            { alignItems: 'flex-end', justifyContent: 'flex-end' },
          ]}>
          <Text style={[styles.typov3SubHeader1, { fontSize: 8 }]}>
            {reportDataObject.basic_detail.name}
          </Text>
          <Text style={[styles.typov3HintText, { fontSize: 8 }]}>
            {`Ref Id : ${reportDataObject.basic_detail.case_id}`}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.breakerDivider,
          {
            marginTop: SPACING.sm,
            borderStyle: 'solid',
            borderColor: COLORS.bk_report_stroke_secondary_100,
          },
        ]}
      />
    </View>
  );
};

export default React.memo(ReportHeaderConsolidated);
