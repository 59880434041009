import {
  AUTO_HIDE_TIMEOUT,
  COLORS,
  MAX_FILE_SIZE,
  ONLY_IMAGE_ACCEPTED,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { RemoveRedEye } from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DisplayImage } from 'src/app/components';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { axiosInstance, endpoints } from 'src/app/utils';
import { DocumentListItem } from './check-detail-hoc';
import PreviewDialog from './previewDailog';
interface DocumentListProps {
  documentList: DocumentListItem[];
  setDocumentList: React.Dispatch<React.SetStateAction<DocumentListItem[]>>;
  // eslint-disable-next-line no-unused-vars
  updateCheckDataDocumentList: (newDocumentList: DocumentListItem[]) => void;
}

const SupportDocumentComponent: React.FC<DocumentListProps> = props => {
  const { documentList, setDocumentList, updateCheckDataDocumentList } = props;

  // is drag hover state
  const [isDragHover, setIsDragHover] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      // Filter out files that exceed the maximum file size
      const validFiles = acceptedFiles.filter(
        file => file.size <= MAX_FILE_SIZE
      );
      const invalidFiles = acceptedFiles.filter(
        file => file.size > MAX_FILE_SIZE
      );

      if (invalidFiles.length > 0) {
        // Show notification or alert for invalid file size
        enqueueSnackbar('File size exceeding the limit', {
          variant: VARIANT_TYPE.WARNING,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
      }

      if (validFiles.length > 0) {
        const mediaData = {
          field_id: 'support-documents',
          isEncrypted: true,
          filePathContext: 'support-documents', // Provide the feature/context where the file is being uploaded
          isTenantIsolated: true, // whether file has to be tenant specific
          isPrivate: true, // only authorized user access
          shouldCompress: false,
        };

        const formData = new FormData();
        formData.append('file', validFiles[0]);
        formData.append('media', JSON.stringify(mediaData));

        try {
          // Make the POST request to upload files
          const response = await axiosInstance.post(
            `${endpoints.POST_FILE_S3}`,
            formData
          );

          if (response.data) {
            const cloneDocumentList = documentList;

            cloneDocumentList.push({
              file_url: response.data.data.value,
              type: response.data.data.data_type,
              document_id: 'NEW',
              deleted: false,
            });
            setDocumentList(cloneDocumentList);
            updateCheckDataDocumentList(cloneDocumentList);
          }
        } catch (error) {
          console.error('Failed to upload files:', error);
        }
      }
    },
    [documentList, setDocumentList, updateCheckDataDocumentList]
  );
  // drag drop functionality callback
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver: () => {
      setIsDragHover(true);
    },
    onDragLeave: () => {
      setIsDragHover(false);
    },
    accept: ONLY_IMAGE_ACCEPTED,
    multiple: true,
  });

  const removeFile = (nameToDelete: string) => {
    const indexToDelete = documentList.findIndex(
      item => item.file_url === nameToDelete
    );

    if (indexToDelete !== -1) {
      const cloneDocumentList = [...documentList];
      cloneDocumentList[indexToDelete].deleted = true;

      setDocumentList(cloneDocumentList);
      updateCheckDataDocumentList(cloneDocumentList);
    }
  };

  const undoDelete = (nameToUndo: string) => {
    const indexToUndo = documentList.findIndex(
      item => item.file_url === nameToUndo
    );

    if (indexToUndo !== -1 && documentList[indexToUndo].deleted) {
      const cloneDocumentList = [...documentList];
      cloneDocumentList[indexToUndo].deleted = false;
      setDocumentList(cloneDocumentList);
      updateCheckDataDocumentList(cloneDocumentList);
    }
  };
  // Hook to handle bytes to MB
  //const { fileSize, fileUnit } = useBytesToMB(MAX_FILE_SIZE);

  const handleIconClick = (url: string) => {
    setDialogContent(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent(null);
  };

  return (
    <>
      <Stack
        className={`bg-bk_bg_primary border-dashed border border-bk_action_primary rounded-lg py-3 flex items-center cursor-pointer ${
          isDragHover ? 'bg-red-100' : ''
        }`}
        spacing={1}
        {...getRootProps()}>
        <input type="file" {...getInputProps()} style={{ display: 'none' }} />
        <Stack sx={{ py: 2 }}>
          <DisplayImage
            imageName="report-documents-upload-icon.svg"
            imageType="icon"
          />
        </Stack>
        <Stack direction={'column'} className="flex items-center">
          <Typography className="bk-sub-heading2">
            Drag & drop or{' '}
            <span style={{ color: COLORS.bk_tag_blue }}> Choose file</span> to
            upload
          </Typography>
          <Typography
            className="bk-body3 text-bk_text_secondary"
            sx={{ color: '#55505A', fontSize: 11 }}>
            Supported formats: jpeg, png & max size 5mb only
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={'column'}
        className="p-4  overflow-y-auto"
        style={{ maxHeight: '200px', height: '100%' }}>
        {documentList.length > 0 &&
          documentList.map((file: any, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  className={`items-center ${file.deleted ? 'bg-red-100' : ''}`}
                  style={{ backgroundColor: '#F8F8F8' }}>
                  <Stack direction={'row'} alignItems="center">
                    <FileDisplayComponent
                      fileUrl={file.file_url}
                      fileStyle={{
                        width: '60px',
                        height: '60px',
                        opacity: 0.7,
                      }}
                    />
                    <Typography
                      className={`bk-sub-heading1 ${
                        file.deleted ? 'line-through' : ''
                      }`}
                      style={{
                        fontSize: '14px',
                        opacity: 0.7,
                        marginLeft: '8px', // Adjust the margin as needed
                      }}>
                      {file.file_url.match(/\/([^/?#]+)$/)[1].length > 40
                        ? file.file_url.slice(-30)
                        : file.file_url.match(/\/([^/?#]+)$/)[1]}
                    </Typography>
                  </Stack>
                  <IconButton
                    style={{ marginLeft: '0.3rem' }}
                    onClick={() => {
                      if (file.deleted) {
                        undoDelete(file.file_url);
                      } else {
                        removeFile(file.file_url);
                      }
                    }}
                    className="cursor-pointer">
                    <DisplayImage
                      imageName={'delete-icon.svg'}
                      imageType="icon"
                      width="14px"
                      height="16px"
                    />
                  </IconButton>
                  <IconButton onClick={() => handleIconClick(file.file_url)}>
                    <RemoveRedEye />
                  </IconButton>
                </Stack>
              </Grid>
            );
          })}
      </Stack>
      <PreviewDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        contentUrl={dialogContent}
      />
    </>
  );
};

export default SupportDocumentComponent;
