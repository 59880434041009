export interface DisplayImageProps {
  imageName: string;
  imageType?: string;
  width?: string;
  height?: string;
  className?: string;
  containerClass?: string;
  style?: React.CSSProperties;
}

export function DisplayImage(props: DisplayImageProps) {
  const { imageName, imageType, containerClass = 'items-end', ...rest } = props;
  return (
    <div className={`flex ${containerClass}`}>
      {imageType?.toLowerCase() === 'url' ? (
        <img src={imageName} alt="display" {...rest} />
      ) : (
        <img
          src={`../../../assets/${imageType}/${imageName}`}
          alt="display"
          {...rest}
        />
      )}
    </div>
  );
}

export default DisplayImage;
