import { BKStyledButton, EmptyState } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import {
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  FAIL_TO_GENERATE_PDF_DESCRIPTION,
  FAIL_TO_GENERATE_PDF_TITLE,
  GENERATE_CONSOLIDATED_REPORT_LOADER_TITLE,
  guardedPageNavigate,
  NO_REPORT_DATA_DESCRIPTION,
  NO_REPORT_DATA_TITLE,
} from '@gigin-work-space/utils';
import { Document, Page, usePDF } from '@react-pdf/renderer';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withViewPermission } from 'src/app/HOC';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio/react';
import { DisplayImage } from '../../../components';
import SectionBreak from './components/section-break';
import { ReportDataInterface } from './constants/interfaces/ReportDataInterface';
import { ReportProvider, useReportContext } from './contexts/report-context';
import { GenerateReportLoader } from './generate-report-loader';
import IndividualCheckConsolidated from './Pages/individual-check-consolidated';
import ReportAnnexurePage from './Pages/report-annexure-page';
import ReportSummary from './Pages/report-summary';
import { styles } from './styles';
import {
  DocumentWithIndex,
  FlattenedSubcheck,
  SubcheckMapValue,
} from './utility/restructure-data';

export interface PDFDocumentProps {
  reportDataObject: ReportDataInterface;
  flattenedSubchecks: FlattenedSubcheck[];
  documentList: DocumentWithIndex[];
  subcheckMap: Map<string, SubcheckMapValue>;
}

type PDFGeneratorProps = {
  reportData: ReportDataInterface;
};

export const PDFDocument: React.FC<PDFDocumentProps> = ({
  reportDataObject,
  flattenedSubchecks,
  documentList,
  subcheckMap,
}) => (
  <Document>
    {/* TODO: Uncomment this when the cover page is ready */}
    {/* {reportDataObject.report_details && (
      <CoverPageConsolidated reportDataObject={reportDataObject} />
    )} */}
    <Page size="A4" style={styles.pageConsolidated} wrap>
      <ReportSummary
        flattenedSubchecks={flattenedSubchecks}
        reportDataObject={reportDataObject}
      />
      <SectionBreak data={'Check details'} />
      <IndividualCheckConsolidated
        flattenedSubchecks={flattenedSubchecks}
        documentList={documentList}
      />
    </Page>
    {documentList.length > 0 && (
      <ReportAnnexurePage
        documentList={documentList}
        subcheckMap={subcheckMap}
      />
    )}
  </Document>
);

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ reportData }) => {
  const navigate = useNavigate();
  const { reportDataObject, flattenedSubchecks, documentList, subcheckMap } =
    useReportContext();

  const [instance, updateInstance] = usePDF({
    document: reportDataObject ? (
      <PDFDocument
        reportDataObject={reportDataObject}
        flattenedSubchecks={flattenedSubchecks}
        documentList={documentList}
        subcheckMap={subcheckMap}
      />
    ) : undefined,
  });

  useEffect(() => {
    if (reportDataObject) {
      updateInstance(
        <PDFDocument
          reportDataObject={reportDataObject}
          flattenedSubchecks={flattenedSubchecks}
          documentList={documentList}
          subcheckMap={subcheckMap}
        />
      );
    }
  }, [
    reportData,
    updateInstance,
    reportDataObject,
    flattenedSubchecks,
    documentList,
    subcheckMap,
  ]);

  if (instance.loading) {
    return (
      <GenerateReportLoader title={GENERATE_CONSOLIDATED_REPORT_LOADER_TITLE} />
    );
  }

  if (instance.error) {
    return (
      <EmptyState
        maxHeight="100vh"
        imageComponent={
          <DisplayImage
            imageName={EmployerAppAssetIconsFileNames.ERROR_404_SVG}
            imageType={DisplayImageType.ICON}
            width="370px"
            height="370px"
          />
        }
        buttonComponent={
          <BKStyledButton
            variant="contained"
            className="bg-bk_action_primary mt-2 rounded-lg"
            onClick={() => globalNavigateTo('/bgv', navigate)}>
            Back to dashboard
          </BKStyledButton>
        }
        title={FAIL_TO_GENERATE_PDF_TITLE}
        description={FAIL_TO_GENERATE_PDF_DESCRIPTION}
      />
    );
  }

  return (
    <iframe
      src={instance.url as string}
      title="Consolidated Report"
      style={{ width: '100%', height: '100vh' }}
    />
  );
};

const BgvConsolidatedReport: React.FC = () => {
  const { isDataLoaded, reportDataObject } = useReportContext();
  const navigate = useNavigate();

  if (!isDataLoaded) {
    return (
      <GenerateReportLoader title={GENERATE_CONSOLIDATED_REPORT_LOADER_TITLE} />
    );
  }

  if (!reportDataObject) {
    return (
      <EmptyState
        maxHeight="100vh"
        imageComponent={
          <DisplayImage
            imageName={EmployerAppAssetIconsFileNames.ERROR_404_SVG}
            imageType={DisplayImageType.ICON}
            width="370px"
            height="370px"
          />
        }
        buttonComponent={
          <BKStyledButton
            variant="contained"
            className="bg-bk_action_primary mt-2 rounded-lg"
            onClick={() => globalNavigateTo(guardedPageNavigate(), navigate)}>
            Back to dashboard
          </BKStyledButton>
        }
        title={NO_REPORT_DATA_TITLE}
        description={NO_REPORT_DATA_DESCRIPTION}
      />
    );
  }

  return <PDFGenerator reportData={reportDataObject} />;
};

export const WithPermissionBgvConsolidatedReport: React.FC = () => {
  const { canReadOrder } = useSnapshot(permissionStore);
  return withViewPermission({
    permissions: [canReadOrder],
    children: (
      <ReportProvider>
        <BgvConsolidatedReport />
      </ReportProvider>
    ),
  });
};
