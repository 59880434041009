import {
  AUTO_HIDE_TIMEOUT,
  ConsentType,
  InputLabel,
  PROFILE_PIC_PLACEHOLDER,
  SelfDeclaration,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Box, Button, Grid, Input, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { HttpStatus } from '@nestjs/common';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { axiosInstance, endpoints } from 'src/app/utils';
import { updateDataCollectionStatus } from 'src/app/utils/store';
import ConsentDocument from '../../bgv/component/candidate-individual/consent-download';
import CircularIndeterminate from '../../bgv/component/skelton/bgv-loader';
import { CheckHeader } from './check-header';
interface CheckDetail {
  checkData: any;
}
const DEFAULT_PROFILE_PIC_URL = PROFILE_PIC_PLACEHOLDER;

interface DataCollection {
  _id: string;
  status: string;
  accountId: string;
  actorId: string;
  orderId: string;
  cdcSource: string;
}

export const BasicDetails = (props: CheckDetail) => {
  const { checkData } = props;
  const { order_request_id } = checkData[0];
  const [checkDataState, setCheckDataState] = useState<any>(null);
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [consentDocumentURL, setConsentDocumentURL] = useState<string>('');
  const [consentType, setConsentType] = useState<string>(
    ConsentType.OTP_VERIFICATION
  );
  const [name, setName] = useState(checkDataState?.actor_id?.name);
  const [email, setEmail] = useState(checkDataState?.actor_id?.email);
  const [mobile, setMobile] = useState(checkDataState?.actor_id?.mobile);
  const [candidateId, setCandidateId] = useState(checkDataState?.actor_id?._id);

  const [dataCollection, setDataCollection] = useState<DataCollection>(
    {} as DataCollection
  );

  const [filteredChecksState, setFilteredChecksState] = useState<any>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSaveCandidateData = async () => {
    try {
      const response = await axiosInstance.patch(
        `${endpoints.GET_CANDIDATES}/${candidateId}`,
        {
          name,
          email,
          mobile,
        }
      );

      if (response?.status === HttpStatus.OK) {
        enqueueSnackbar('Candidate details updated successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
        // Optionally, refresh the data or update the state to reflect the changes
      } else {
        enqueueSnackbar('Failed to update candidate details', {
          variant: VARIANT_TYPE.ERROR,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
      }
    } catch (error) {
      enqueueSnackbar('Error updating candidate details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const getCheckDetail = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_CASE_DETAIL}/${order_request_id}`
      );
      await fetchCheckDetails();
      setCheckDataState(response.data.data);
      const actorId = response.data.data.actor_id?._id;
      const orderId = response.data.data.order_id?._id;
      setCandidateId(response?.data?.data?.actor_id?._id);

      const cd_details = await axiosInstance.get(
        `${endpoints.PROFILE_PIC_UPDATE}/${orderId}/${actorId}`
      );
      setProfilePic(
        cd_details?.data?.data?.profilePictureData?.dataCollected?.value
      );

      const selfDeclarationResponse = await axiosInstance.get(
        `${endpoints.GET_SELF_DECLARATION_DETAILS}/${cd_details?.data?.data?.dataCollectionId}`
      );

      setDataCollection(selfDeclarationResponse?.data?.data);
      updateDataCollectionStatus(selfDeclarationResponse?.data?.data?.status);
      if (
        selfDeclarationResponse?.data?.data?.selfDeclaration === false ||
        selfDeclarationResponse?.data?.data?.selfDeclarationDetails?.signatureStatus.toLowerCase() ===
          SelfDeclaration.COMPLETED
      ) {
        setConsentDocumentURL(
          selfDeclarationResponse?.data?.data?.selfDeclarationDetails
            .signedDocument?.value
        );
      }

      if (
        selfDeclarationResponse?.data?.data?.selfDeclarationDetails?.signatureType.toUpperCase() ===
        ConsentType.E_SIGN
      ) {
        setConsentType(ConsentType.E_SIGN);
      } else if (
        selfDeclarationResponse?.data?.data?.selfDeclarationDetails?.signatureType.toUpperCase() ===
        ConsentType.MANUAL
      ) {
        setConsentType(ConsentType.MANUAL);
      } else {
        setConsentType(ConsentType.OTP_VERIFICATION);
      }
    } catch (error) {
      enqueueSnackbar('Error fetching details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  }, [order_request_id]);

  const fetchCheckDetails = async () => {
    try {
      const { data: checkInfo } = await axiosInstance.get(
        `${endpoints.CASE_INFO}/opps/${order_request_id}`
      );

      const filteredChecks = checkInfo.data
        .filter(({ remark }: { remark: string }) => remark.length > 0)
        .map(
          ({
            order_service_verification_status: checkStatus,
            check_id: { product_listing_name: check },
            remark,
          }: {
            order_service_verification_status: string;
            check_id: { product_listing_name: string };
            remark: string;
          }) => ({
            checkStatus,
            check,
            remark,
          })
        );
      setFilteredChecksState(filteredChecks);
      return filteredChecks;
    } catch (error) {
      enqueueSnackbar('Error fetching Check details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
      throw error;
    }
  };

  useEffect(() => {
    getCheckDetail();
  }, [order_request_id]);

  const handleSendDataCollectionLink = async () => {
    try {
      const response = await axiosInstance.post(`${endpoints.BULK_LINK_SEND}`, [
        dataCollection._id,
      ]);

      if (response.data.success) {
        enqueueSnackbar('Link sent successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
      }
      return response.data.success;
    } catch (error) {
      enqueueSnackbar('Error while sending link', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleSubmit = async (reportStatus: string) => {
    try {
      const caseStatus = { status: reportStatus };
      const response = await axiosInstance.post(
        `${endpoints.UPDATE_CHECK_DETAIL_OVERALL}/${order_request_id}`,
        caseStatus
      );
      if (response.status === 201) {
        enqueueSnackbar('Data updated successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong in API', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleInitiatedDateChange = (value: any | null) => {
    if (value) {
      const clonedCheckDataState = { ...checkDataState };

      clonedCheckDataState.createdAt = value;

      setCheckDataState(clonedCheckDataState);
    }
  };

  const handleProfilePicChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    try {
      if (files && files.length > 0) {
        const mediaData = {
          field_id: 'YOUR_FIELD_ID', // Replace with your field ID
          isEncrypted: true,
          filePathContext: 'bgv-check-file-upload',
          isTenantIsolated: true,
          isPrivate: true,
          shouldCompress: false,
        };

        const body = new FormData();
        body.append('file', files[0]);
        body.append('field_id', 'params.field_id');
        body.append('media', JSON.stringify(mediaData));

        const fileUploaded = await axiosInstance.post(
          `${endpoints.POST_FILE_S3}`,
          body
        );

        // TODO: Handle success & error scenarios
        if (fileUploaded.status === 201) {
          const actorId = checkDataState.actor_id?._id;
          const orderId = checkDataState.order_id?._id;
          setName(checkDataState?.actor_id?.name);
          setMobile(checkDataState?.actor_id?.mobile);
          setEmail(checkDataState?.actor_id?.email);
          const response = await axiosInstance.put(
            `${endpoints.PROFILE_PIC_UPDATE}/${orderId}/${actorId}`,
            fileUploaded.data.data
          );
          setProfilePic(response.data.data.data_collected.value);
        }
      }
    } catch (error) {
      enqueueSnackbar('Error uploading file', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleReportStatusChange = () => {};

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const lowerCaseEmail = event.target.value.toLowerCase();
    event.target.value = lowerCaseEmail;
    setEmail(lowerCaseEmail);
  };

  return (
    <>
      {checkDataState ? (
        <Box className="w-full h-[100vh] relative flex-grow">
          <CheckHeader
            onReportStatusChange={handleReportStatusChange}
            onSubmit={handleSubmit}
            checkTitle="Basic Details"
            checkId={checkDataState?.ext_case_id}
            report_status={checkDataState?.report_status}
            dataCollectionStatus={dataCollection?.status}
            checkStatus={filteredChecksState}
          />
          <Grid className="flex relative top-[10%] left-[15%]">
            <Grid sx={{ flex: 2 }}>
              <Box className="bk-basic-detail">
                <TextField
                  label={InputLabel.CANDIDATE_NAME}
                  name="name"
                  size="small"
                  defaultValue={checkDataState.actor_id?.name}
                  onChange={e => setName(e.target.value)}
                  required
                />
                <TextField
                  type="tel"
                  label={InputLabel.PHONE_NUMBER}
                  name="mobile"
                  size="small"
                  defaultValue={checkDataState.actor_id?.mobile}
                  onChange={e => setMobile(e.target.value)}
                  required
                />
                <TextField
                  type="email"
                  label={InputLabel.EMAIL}
                  name="email"
                  size="small"
                  defaultValue={checkDataState.actor_id?.email}
                  onChange={handleEmailChange}
                  required
                />
                <DatePicker
                  label={InputLabel.INITIATED_ON}
                  defaultValue={dayjs()} // Set the default value to today's date
                  format="YYYY-MM-DD"
                  value={dayjs(checkDataState.createdAt)}
                  onChange={newValue => handleInitiatedDateChange(newValue)}
                />
                <Box className="flex flex-col items-center p-[20px]">
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    inputRef={fileInputRef}
                    onChange={handleProfilePicChange}
                    className="hidden"
                  />
                  <Grid className="m-[20px]" onClick={handleButtonClick}>
                    <FileDisplayComponent
                      fileUrl={profilePic || DEFAULT_PROFILE_PIC_URL}
                      fileClassName="w-[150px] h-[150px] object-cover"
                      fileType="image"
                    />
                  </Grid>
                </Box>
              </Box>
              <Grid className="p-[20px]" display={'flex'} gap={2}>
                <Button variant="outlined" onClick={handleSaveCandidateData}>
                  Update Candidate
                </Button>
                <Button variant="text" onClick={handleButtonClick}>
                  Upload Picture
                </Button>
                <Button variant="text" onClick={handleSendDataCollectionLink}>
                  Send Link
                </Button>
              </Grid>
            </Grid>

            <ConsentDocument
              consentDocumentURL={consentDocumentURL}
              consentType={consentType}
              candidateName={checkDataState?.actor_id?.name}
            />
          </Grid>
        </Box>
      ) : (
        <CircularIndeterminate />
      )}
    </>
  );
};
