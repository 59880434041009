export const baseCloudfrontUrl =
  process.env['NX_REACT_REPORT_IMAGE_CLOUDFRONT_URL'];
const baseUrl = `${baseCloudfrontUrl}/bgv-sample-data`;
const staticImageUrl = `${baseCloudfrontUrl}/bgv_static_images`;

export const verifyInWhite = staticImageUrl.concat('/Verifyin_White.png');
export const verifyInDark = staticImageUrl.concat('/Verifyin_Dark.png');
export const giginLogo = staticImageUrl.concat('/gigin_logo.png');
export const verifyInByGiginDark = staticImageUrl.concat(
  '/verifyin_by_gigin_dark.png'
);
export const reportISOImage = staticImageUrl.concat('/report_iso_image.png');

export const addressIcon = baseUrl.concat('/address_icon.png');
export const emailIcon = baseUrl.concat('/email_icon.png');
export const phoneIcon = baseUrl.concat('/phone_icon.png');
export const userDetailGradient = baseUrl.concat(
  '/userdetails_bg-gradient.png'
);
export const checkCompletedWatermark = baseUrl.concat(
  '/check_completed_watermark.png'
);
export const coverPageBg = baseUrl.concat('/coverpage_bg.png');
export const reportCoverTitle = baseUrl.concat('/report_cover_title.png');
export const checkInProgress = baseUrl.concat('/hourglass_check_progress.png');
export const noDocumentsUploaded = baseUrl.concat('/no_documents_uploaded.png');
export const clearIcon = baseUrl.concat('/clear_icon.png');
export const discrepantIcon = baseUrl.concat('/discrepant_icon.png');
export const insufficientIcon = baseUrl.concat('/insufficient_icon.png');
export const noResponseIcon = baseUrl.concat('/noresponse_icon.png');
export const clearLogo = baseUrl.concat('/clear.png');
export const discrepantLogo = baseUrl.concat('/discrepant.png');
export const insufficientLogo = staticImageUrl.concat('/insufficient.png');
export const noResponseLogo = baseUrl.concat('/noresponse.png');
export const unableToVerifyLogo = baseUrl.concat('/unableToVerifyLogo.png');
export const inProgressLogo = baseUrl.concat('/in_progress.png');
export const qcPendingLogo = baseUrl.concat('/qc_pending.png');
export const clearLightIcon = baseUrl.concat('/Clear_light_icon.png');
export const adverseLightIcon = baseUrl.concat('/Adverse_light_icon.png');
export const considerLightIcon = baseUrl.concat('/Consider_light_icon.png');
export const infoLightIcon = baseUrl.concat('/Info_light_icon.png');
export const clearDarkIcon = baseUrl.concat('/Clear_dark_icon.png');
export const adverseDarkIcon = baseUrl.concat('/Adverse_dark_icon.png');
export const considerDarkIcon = baseUrl.concat('/Consider_dark_icon.png');
export const infoDarkIcon = baseUrl.concat('/Info_dark_icon.png');
// Consolidated Report Status Images START
export const greenBtnImage = baseUrl.concat('/green_btn.png');
export const redBtnImage = baseUrl.concat('/red_btn.png');
export const amberBtnImage = baseUrl.concat('/amber_btn.png');
export const inProgressBtnImage = baseUrl.concat('/in_progress_btn.png');
export const greyBtnImage = baseUrl.concat('/grey_btn.png');
export const greenTxtImage = baseUrl.concat('/green_txt.png');
export const redTxtImage = baseUrl.concat('/red_txt.png');
export const amberTxtImage = baseUrl.concat('/amber_txt.png');
export const inProgressTxtImage = baseUrl.concat('/in_progress_txt.png');
export const greyTxtImage = baseUrl.concat('/grey_txt.png');
// Consolidated Report Status Images END
