import { Page, Text, View } from '@react-pdf/renderer';
import { ReportFooter, ReportHeader, UserDetailContainer } from '../components';
import { styles } from '../styles';

export interface InstructionPageProps {
  reportDataObject: any;
}
export const InstructionPage = (props: InstructionPageProps) => {
  const { reportDataObject } = props;

  return (
    <Page size="A4" wrap style={styles.page}>
      {/* header section */}
      <ReportHeader reportDataObject={reportDataObject} />
      {/* Main Section */}
      <View style={styles.mainSectionContainer}>
        {/* User Detail Section */}
        <UserDetailContainer reportDataObject={reportDataObject} />
        {/* check Status section menu */}
        <Text style={(styles.textHeadLine2, { marginTop: 4 })}>
          Definition of Results & Color codes:
        </Text>
        {/* Menu List Container */}
        <View style={styles.stackColumnContainer}>
          {/* CLEAR */}
          <View
            style={[
              styles.instructionContainer,
              { borderLeft: `27px solid #048218` },
            ]}
            wrap={false}>
            <Text style={styles.textSubHeading1}>Clear</Text>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              There is no disparity between stated and verified antecedents or
              the difference is considered non-significant and treated as Clear.
            </Text>
          </View>
          {/* DISCREPANT */}
          <View
            style={[
              styles.instructionContainer,
              { borderLeft: '27px solid #D94415' },
            ]}
            wrap={false}>
            <Text style={styles.textSubHeading1}>Discrepant</Text>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Source of verification is fake or the major mismatch in the
              verification remarks or produced fake documents.
            </Text>
          </View>
          {/* Attention required / Insufficient */}
          <View
            style={[
              styles.instructionContainer,
              { borderLeft: '27px solid #EE6E12' },
            ]}
            wrap={false}>
            <Text style={styles.textSubHeading1}>
              Attention Required / Insufficient
            </Text>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Source of verification have provided partial information, hence
              verification could not be conducted, where there is no records
              available.{'\n'} or{'\n'} Verification could not be completed due
              to absence of mandatory data or document.
            </Text>
          </View>
          {/* No response received */}
          <View
            style={[
              styles.instructionContainer,
              { borderLeft: '27px solid #55505A' },
            ]}
            wrap={false}>
            <Text style={styles.textSubHeading1}>No Response Received</Text>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Verification could not be completed as the response from the
              verification source has not been received till the date of report
              generation.
            </Text>
          </View>
        </View>
      </View>
      {/* footer section */}
      <ReportFooter reportDataObject={reportDataObject} />
    </Page>
  );
};
