import { Page, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import {
  addressIcon,
  coverPageBg,
  emailIcon,
  phoneIcon,
  reportCoverTitle,
  reportStatusMapper,
  verifyInWhite,
} from '../components';
import { ImageComponent } from '../components/report-image';
import { styles } from '../styles';

export interface CoverPageProps {
  reportDataObject: any;
}
export const CoverPage = (props: CoverPageProps) => {
  const { reportDataObject } = props;

  return (
    <Page size="A4">
      <View style={{ width: '100%', height: '100%' }}>
        <ImageComponent
          src={coverPageBg}
          style={{
            width: '100%',
            height: '100vh',
            position: 'absolute',
            objectFit: 'cover',
            zIndex: '-10',
          }}
        />
        {/* Logo and report title section */}
        <View
          style={{
            position: 'absolute',
            top: 110,
            flexDirection: 'column',
            gap: 18,
            padding: '0 48px',
          }}>
          <ImageComponent
            src={verifyInWhite}
            style={{ width: '194px', height: '55px' }}
          />
          <ImageComponent
            src={reportCoverTitle}
            style={{ width: 'auto', height: '120px' }}
          />
        </View>
        {/*  user details Section */}

        <View
          style={{
            position: 'absolute',
            bottom: 201,
            flexDirection: 'column',
            gap: 18,
            padding: '30px 25px',
            backgroundColor: 'rgba(0,0,0,0.36)',
          }}>
          {/* profile and address section */}
          <View style={styles.profileSection}>
            <View style={{ width: '12%' }}>
              <ImageComponent
                src={reportDataObject.basic_detail.profile_image}
                style={styles.profileImageLarge}
              />
            </View>
            <View style={styles.profileInfo}>
              <View style={styles.nameSection}>
                <View style={{ gap: 4 }}>
                  <Text style={[styles.textHeadLine3, styles.textLight]}>
                    {reportDataObject.basic_detail.name}
                  </Text>
                  <View style={styles.contactDetails}>
                    <View style={styles.iconTextContainer}>
                      <ImageComponent
                        src={phoneIcon}
                        style={styles.iconImageSmall}
                      />
                      <Text style={[styles.textBody3, styles.textLight]}>
                        {reportDataObject.basic_detail.mobile}
                      </Text>
                    </View>
                    <View style={styles.iconTextContainer}>
                      <ImageComponent
                        src={emailIcon}
                        style={styles.iconImageSmall}
                      />
                      <Text style={[styles.textBody3, styles.textLight]}>
                        {reportDataObject.basic_detail.email}
                      </Text>
                    </View>
                  </View>
                </View>
                <ImageComponent
                  src={reportStatusMapper(
                    reportDataObject.overall_status.status
                  )}
                  style={[styles.pillImage]}
                />
              </View>
              {reportDataObject.basic_detail.address !== '-' && (
                <View style={[styles.iconTextContainer, { marginTop: 6 }]}>
                  <ImageComponent
                    src={addressIcon}
                    style={styles.iconImageSmall}
                  />
                  <Text style={[styles.textBody3, styles.textLight]}>
                    {reportDataObject.basic_detail.address}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={[styles.orderSection, { marginLeft: '13%' }]}>
            <View style={styles.orderColumn}>
              <Text style={[styles.textBody2, styles.textLight]}>
                Initiated on
              </Text>
              <Text style={[styles.textSubHeading2, styles.textLight]}>
                {dayjs(reportDataObject.request.initiated_on).format(
                  'DD-MM-YYYY'
                )}
              </Text>
            </View>
            <View style={styles.orderColumn}>
              <Text style={[styles.textBody2, styles.textLight]}>
                Initiated by
              </Text>
              <View style={styles.iconTextContainer}>
                <ImageComponent
                  src={reportDataObject.request.initiated_by.profile_image}
                  style={styles.profileImageSmall}
                />
                <Text style={[styles.textBody3, styles.textLight]}>
                  {reportDataObject.request.initiated_by.name}
                </Text>
              </View>
            </View>
            <View style={styles.orderColumn}>
              <Text style={[styles.textBody2, styles.textLight]}>
                Verified by
              </Text>
              <View style={styles.iconTextContainer}>
                <ImageComponent
                  src={reportDataObject.request.verified_by.profile_image}
                  style={styles.profileImageSmall}
                />
                <Text style={[styles.textBody3, styles.textLight]}>
                  {reportDataObject.request.verified_by.name}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};
