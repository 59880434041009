import { enqueueSnackbar } from 'notistack';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance, endpoints } from 'src/app/utils';
import { ReportDataInterface } from '../constants/interfaces/ReportDataInterface';
import {
  DocumentWithIndex,
  FlattenedSubcheck,
  restructureData,
  SubcheckMapValue,
} from '../utility/restructure-data';
import { VARIANT_TYPE } from '@gigin-work-space/utils';

interface ReportContextProps {
  reportDataObject: ReportDataInterface | null;
  documentList: DocumentWithIndex[];
  subcheckMap: Map<string, SubcheckMapValue>;
  flattenedSubchecks: FlattenedSubcheck[];
  isDataLoaded: boolean;
}

const ReportContext = createContext<ReportContextProps | undefined>(undefined);

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReportContext must be used within a ReportProvider');
  }
  return context;
};

export const ReportProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { caseId } = useParams<{ caseId: string }>();
  const [state, setState] = useState<ReportContextProps>({
    reportDataObject: null,
    documentList: [],
    subcheckMap: new Map(),
    flattenedSubchecks: [],
    isDataLoaded: false,
  });

  const getReportData = async () => {
    try {
      setState(prevState => ({ ...prevState, isDataLoaded: false }));
      const response = await axiosInstance.get(
        `${endpoints.GET_REPORT_DATA}/${caseId}`
      );

      if (!response.data.success) {
        throw new Error('Failed to generate report pdf');
      }

      const { subcheckMap, documentList, flattenedSubchecks } = restructureData(
        response.data.data
      );

      setState({
        reportDataObject: response.data.data,
        documentList,
        subcheckMap,
        flattenedSubchecks,
        isDataLoaded: true,
      });
    } catch (error) {
      enqueueSnackbar('Failed to generate report pdf', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: 2000,
      });
      setState(prevState => ({
        ...prevState,
        isDataLoaded: true,
        reportDataObject: null,
      }));
    }
  };

  useEffect(() => {
    getReportData();
  }, [caseId]);

  return (
    <ReportContext.Provider value={state}>{children}</ReportContext.Provider>
  );
};
