/* eslint-disable no-unused-vars */
import { BKStyledField } from '@gigin-work-space/common-ui';
import { ICandidateRole, IMetaPropsInitial } from '@gigin-work-space/model';
import { OrderCandidateStore } from '@gigin-work-space/store';
import {
  COLORS,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  FilterStatus,
  borderLessSelect,
} from '@gigin-work-space/utils';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import DownloadOptions from './downloadOptions';
import { FilterPopupComponent } from './search-filter-popup';
import Processes from './triggeredProcesses/processes';

export interface AllCasesConsentAPIResponse {
  totalCandidates: number;
  pendingDeclarations: number;
  pendingDeclarationsDetails: PendingDeclarationDetail[];
}

interface PendingDeclarationDetail {
  _id: string;
  isactive: boolean;
  dataCollectionLink: {
    status: string;
    count: number;
    sentAt: string;
    _id: string;
  };
  status: string;
  account_id: string;
  actorId: {
    _id: string;
    account_id: string;
    name: string;
    email: string;
    mobile: string;
    job_role_id: string;
    tenantId: string;
    deletedAt: null | string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  order_id: string;
  validity: {
    type: string;
    value: number;
  };
  data_collection_progress_status: DataCollectionProgressStatus[];
  data_collection_check_list: DataCollectionCheckList[];
  started_time: number;
  selfDeclaration: boolean;
  signature_type: string;
  signature_status: string;
  signed_image: {
    value: string;
    createdAt: string;
  };
  signed_document: {
    value: string;
    createdAt: string;
  };
  consent_template: string;
  order_service_request_id: string;
  tenantId: string;
  cdcSource: string;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface DataCollectionProgressStatus {
  collection_type: string;
  collection_group_type: string;
  collection_status: string;
  service_request_id: string;
  collection_meta_data: {
    is_otp_required: boolean;
  };
  otp_verified: boolean;
  _id: string;
}

interface DataCollectionCheckList {
  status: string;
  document_type: string;
  sub_checks: string;
  check_type: string;
  data_collection_parameters: string[];
  service_request_item_id: string;
  _id: string;
  data_collected: any[];
}

interface AllCasesSearchFilterProps {
  dates?: any;
  handleFilterDate?(date: any[]): void;
  selectedRole: string[];
  handleSelectSelectedRole: (
    event: React.SyntheticEvent,
    value: string[]
  ) => void;
  selectedInitiatedStatus: string;
  handleSelectSelectedInitiatedStatus: (value: string) => void;
  selectedReportStatusModal: string;
  handleSelectSelectedReportStatusModal: (
    event: React.MouseEvent<HTMLElement> | null,
    value: string
  ) => void;
  selectedProgressStatus: string[];
  handleSelectSelectedProgressStatus: (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => void;
  selectedPackages: string[];
  handleSelectSelectedPackages: (
    event: React.SyntheticEvent,
    value: string[]
  ) => void;
  selectedReportGeneratedStatus: string;
  handleSelectSelectedReportGeneratedStatus(
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLElement>,
    // eslint-disable-next-line no-unused-vars
    value: string
  ): void;
}

export const AllCasesSearchFilterComponent: React.FC<
  AllCasesSearchFilterProps
> = ({
  dates,
  handleFilterDate,
  selectedRole,
  handleSelectSelectedRole,
  selectedReportStatusModal,
  handleSelectSelectedReportStatusModal,
  selectedReportGeneratedStatus,
  handleSelectSelectedReportGeneratedStatus,
  selectedInitiatedStatus,
  handleSelectSelectedInitiatedStatus,
  selectedProgressStatus,
  handleSelectSelectedProgressStatus,
  selectedPackages,
  handleSelectSelectedPackages,
}) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const [selectedStatus, setSelectedStatus] = useState([] as string[]);
  const [roles, setRoles] = useState([]);
  const [selectedReportStatus, setSelectedReportStatus] = useState<string>(
    FilterStatus.ALL
  );

  const [consentData, setConsentData] = useState<AllCasesConsentAPIResponse>({
    totalCandidates: 0,
    pendingDeclarations: 0,
    pendingDeclarationsDetails: [],
  });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { caseTableSearchValue, caseTablePage, caseTableLimit } =
    useSnapshot(OrderCandidateStore);

  const prevCaseTablePage = useRef(caseTablePage);
  const prevReportStatus = useRef(selectedReportStatus);
  const prevReportStatusModal = useRef(selectedReportStatusModal);

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectstatusChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    setSelectedStatus(value);
  };

  const handleSelectSelectedReportStatus = (
    _event: React.MouseEvent<HTMLElement> | null,
    value: string
  ) => {
    if (value === null) {
      setSelectedReportStatus(FilterStatus.ALL);
    } else {
      setSelectedReportStatus(value);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getEndDateBasedOnFilter = (filterValue: string) => {
    if (
      filterValue === FilterStatus.CUSTOM &&
      OrderCandidateStore.range_date[1]
    ) {
      const endDateString = OrderCandidateStore.range_date[1];
      const endDate = new Date(endDateString);

      return formatDate(endDate);
    } else if (
      filterValue === FilterStatus.INITIATED_THIS_MONTH ||
      filterValue === FilterStatus.INITITATED_LAST_30_DAYS ||
      filterValue === FilterStatus.INITITATED_LAST_90_DAYS
    ) {
      return formatDate(new Date());
    }
    return '';
  };

  const getDateBasedOnFilter = (filterValue: string) => {
    const currentDate = new Date();

    switch (filterValue) {
      case FilterStatus.INITIATED_THIS_MONTH: {
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        return formatDate(firstDayOfMonth);
      }
      case FilterStatus.INITITATED_LAST_30_DAYS: {
        const last30Days = new Date();
        last30Days.setDate(currentDate.getDate() - 30);
        return formatDate(last30Days);
      }
      case FilterStatus.INITITATED_LAST_90_DAYS: {
        const last90Days = new Date();
        last90Days.setDate(currentDate.getDate() - 90);
        return formatDate(last90Days);
      }
      case FilterStatus.CUSTOM: {
        if (OrderCandidateStore.range_date[0]) {
          const startDateString = OrderCandidateStore.range_date[0];
          const startDate = new Date(startDateString);

          return formatDate(startDate);
        } else {
          return '';
        }
      }

      default: {
        return '';
      }
    }
  };

  const loadAllConsentDetails = async () => {
    try {
      const consent_url = `${endpoints.GET_LOAD_ALL_CONSENT}?search=${
        OrderCandidateStore.caseTableSearchValue
      }&packages=${selectedPackages?.length ? selectedPackages : ''}&roles=${
        selectedRole.length ? selectedRole : ''
      }&status=${
        selectedProgressStatus?.length ? selectedProgressStatus : ''
      }&filter_by=${''}&fromDate=${getDateBasedOnFilter(
        selectedInitiatedStatus
      )}&endDate=${getEndDateBasedOnFilter(
        selectedInitiatedStatus
      )}&fromDateReport=${getDateBasedOnFilter(
        selectedReportGeneratedStatus
      )}&endDateReport=${
        selectedReportGeneratedStatus ? formatDate(new Date()) : ''
      }&reportStatus=${
        selectedReportStatus !== FilterStatus.ALL ? selectedReportStatus : ''
      }`;

      const consent = await axiosInstance.get(`${consent_url}`);
      if (consent.data.success) {
        setConsentData(consent.data.data);
      }
    } catch (err) {
      OrderCandidateStore.allCasesFilterApplied = false;
      enqueueSnackbar('Filter Search failed', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const confirmPageSubmit = async () => {
    try {
      const URL = `${endpoints.GET_CASES_LIST}${
        OrderCandidateStore.caseTablePage
      }&limit=${OrderCandidateStore.caseTableLimit}&search=${
        OrderCandidateStore.caseTableSearchValue
      }&packages=${selectedPackages?.length ? selectedPackages : ''}&roles=${
        selectedRole.length ? selectedRole : ''
      }&status=${
        selectedProgressStatus?.length ? selectedProgressStatus : ''
      }&fileter_by=${''}&fromDate=${getDateBasedOnFilter(
        selectedInitiatedStatus
      )}&endDate=${getEndDateBasedOnFilter(
        selectedInitiatedStatus
      )}&fromDateReport=${getDateBasedOnFilter(
        selectedReportGeneratedStatus
      )}&endDateReport=${
        selectedReportGeneratedStatus ? formatDate(new Date()) : ''
      }&reportStatus=${
        selectedReportStatus !== FilterStatus.ALL ? selectedReportStatus : ''
      }`;
      const response = await axiosInstance.get(URL);
      if (response.data.success) {
        OrderCandidateStore.caseList = response.data.data.candidateList;
        OrderCandidateStore.metaData = response.data.meta;
        OrderCandidateStore.allCasesFilterApplied = true;
      }
      OrderCandidateStore.totalCases = response.data?.meta?.totalItems ?? 0;
    } catch (err) {
      OrderCandidateStore.allCasesFilterApplied = false;
      enqueueSnackbar('Filter Search failed', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmit = () => {
    if (prevReportStatus.current !== selectedReportStatus) {
      prevReportStatus.current = selectedReportStatus;
      handleSelectSelectedReportStatusModal(null, selectedReportStatus);
    } else if (prevReportStatusModal.current !== selectedReportStatusModal) {
      prevReportStatusModal.current = selectedReportStatusModal;
      setSelectedReportStatus(selectedReportStatusModal);

      return;
    }

    if (
      prevCaseTablePage.current === caseTablePage &&
      OrderCandidateStore.caseTablePage !== 1
    ) {
      OrderCandidateStore.caseTablePage = 1;
      return;
    }

    prevCaseTablePage.current = caseTablePage;

    confirmPageSubmit();
    loadAllConsentDetails();
  };

  let searchTimeout: string | number | NodeJS.Timeout | undefined;
  useEffect(() => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      handleSubmit();
    }, 500);

    return () => clearTimeout(searchTimeout);
  }, [caseTableSearchValue]);
  // TODO: useCallback not working (means it always not calling api bcz of cache value.useEffect triggering on page load also)
  useEffect(() => {
    handleSubmit();
  }, [selectedReportStatus, caseTablePage, caseTableLimit]);

  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_ROLE}`);
      if (response.data) {
        const returnedRole = response.data.data.map(
          (role: ICandidateRole) => role.role_title
        );
        setRoles(returnedRole);
      } else {
        setRoles([]);
      }
    } catch (e) {
      enqueueSnackbar('Error while fetching roles', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [OrderCandidateStore.updateConsentStatus]);

  const handleClearSearch = () => {
    OrderCandidateStore.caseTableSearchValue = '';
    OrderCandidateStore.hasClearClicked = true;
    OrderCandidateStore.metaData = IMetaPropsInitial;
  };

  return (
    <Stack direction={'row'} className="flex justify-between sticky top-0">
      <Stack direction={'row'} gap={3} alignItems={'center'}>
        <Stack direction={'row'}>
          <BKStyledField
            className="bg-bk_bg_primary rounded-lg"
            placeholder="Search for candidate"
            variant="outlined"
            fullWidth
            name="caseTableSearchValue"
            id="caseTableSearchValue"
            size="small"
            onChange={(e: any) =>
              (OrderCandidateStore.caseTableSearchValue = e.target.value)
            }
            value={caseTableSearchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="text-bk_bg_primary_dark" />
                </InputAdornment>
              ),
              endAdornment: caseTableSearchValue ? (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    style={{ cursor: 'pointer' }}
                    onClick={handleClearSearch}>
                    <ClearIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        </Stack>

        <Stack direction={'row'}>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{
              '& .MuiSvgIcon-root ': {
                color: COLORS.bk_text_secondary,
              },
            }}
            alignItems={'center'}>
            <Typography
              aria-describedby={id}
              onClick={handleClick}
              sx={{
                background:
                  selectedRole.length ||
                  selectedPackages.length ||
                  selectedProgressStatus.length ||
                  selectedReportStatus !== FilterStatus.ALL ||
                  selectedInitiatedStatus?.length ||
                  selectedReportGeneratedStatus?.length
                    ? COLORS.bk_tag_action_bg
                    : 'transparent',
                p: '5px !important',
                borderRadius: 1,
                '&:hover': {
                  background: COLORS.bk_tag_gray_bg,
                },
                '&:focus': {
                  background: COLORS.bk_tag_action_bg,
                },
              }}>
              {selectedRole.length ||
              selectedPackages.length ||
              selectedProgressStatus.length ||
              selectedReportStatus !== FilterStatus.ALL ||
              selectedInitiatedStatus?.length ||
              selectedReportGeneratedStatus?.length ? (
                <Box className="bK-body2 text-bk_text_secondary items-center flex cursor-pointer">
                  <span className="bK-body1 text-bk_text_dark">
                    Filter (
                    {selectedRole.length +
                      selectedPackages.length +
                      selectedProgressStatus.length +
                      (selectedReportStatus !== FilterStatus.ALL ? 1 : 0) +
                      (selectedInitiatedStatus ? 1 : 0) +
                      (selectedReportGeneratedStatus ? 1 : 0)}
                    )
                  </span>
                  <FilterAltIcon
                    sx={{ ml: 0.5 }}
                    className="w-xl h-xl ml-xs2"
                  />
                </Box>
              ) : (
                <Box className=" items-center flex cursor-pointer">
                  <span className="bK-body1 text-bk_text_dark ml-xs2">
                    Filter{' '}
                  </span>
                  <FilterAltIcon sx={{ ml: 0.5 }} className="w-xl h-xl" />
                </Box>
              )}
            </Typography>
            <Popover
              onClose={handleClose}
              id={id}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              <FilterPopupComponent
                handleFilterDate={handleFilterDate}
                dates={dates}
                selectedStatus={selectedStatus}
                handleSelectstatusChange={handleSelectstatusChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                roles={roles}
                selectedRole={selectedRole}
                handleSelectSelectedRole={handleSelectSelectedRole}
                selectedReportStatus={selectedReportStatus}
                handleSelectSelectedReportStatus={
                  handleSelectSelectedReportStatus
                }
                selectedReportStatusModal={selectedReportStatusModal}
                handleSelectSelectedReportStatusModal={
                  handleSelectSelectedReportStatusModal
                }
                selectedReportGeneratedStatus={selectedReportGeneratedStatus}
                handleSelectSelectedReportGeneratedStatus={
                  handleSelectSelectedReportGeneratedStatus
                }
                selectedInitiatedStatus={selectedInitiatedStatus}
                handleSelectSelectedInitiatedStatus={
                  handleSelectSelectedInitiatedStatus
                }
                selectedProgressStatus={selectedProgressStatus}
                handleSelectSelectedProgressStatus={
                  handleSelectSelectedProgressStatus
                }
                selectedPackages={selectedPackages}
                handleSelectSelectedPackages={handleSelectSelectedPackages}
              />
            </Popover>
          </Stack>
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <FormControl
            variant="standard"
            sx={{
              '& .MuiInput-input': {
                padding: '5px',
                borderRadius: '4px',
              },
            }}>
            <Select
              id="orderStatus"
              name="orderStatus"
              value={selectedReportStatus}
              onChange={e =>
                handleSelectSelectedReportStatus(null, e.target.value)
              }
              label="Status"
              placeholder="Status"
              style={{ color: COLORS.bk_text_dark }}
              className="bK-body1 text-bk_text_secondary"
              sx={borderLessSelect}>
              <MenuItem
                value={FilterStatus.ALL}
                className="bK-body2 text-bk_text_secondary">
                All Status
              </MenuItem>
              <MenuItem
                value={FilterStatus.CLEAR}
                className="bK-body2 text-bk_text_secondary items-center">
                <div className="flex">
                  <DisplayImage
                    containerClass="items-center"
                    imageName={EmployerAppAssetIconsFileNames.VERIFYIN_GREEN}
                    imageType={DisplayImageType.IMAGE}
                    className="w-md h-md"
                  />
                  <span className="ml-1">Verifyin Green</span>
                </div>
              </MenuItem>
              <MenuItem
                value={FilterStatus.DISCREPANT}
                className="bK-body2 text-bk_text_secondary">
                <div className="flex">
                  <DisplayImage
                    containerClass="items-center"
                    imageName={EmployerAppAssetIconsFileNames.VERIFYIN_RED}
                    imageType={DisplayImageType.IMAGE}
                    className="w-md h-md"
                  />
                  <span className="ml-1">Verifyin Red</span>
                </div>
              </MenuItem>
              <MenuItem
                value={FilterStatus.NO_RESPONSE}
                className="bK-body2 text-bk_text_secondary">
                <div className="flex">
                  <DisplayImage
                    containerClass="items-center"
                    imageName={EmployerAppAssetIconsFileNames.VERIFYIN_GREY}
                    imageType={DisplayImageType.IMAGE}
                    className="w-md h-md"
                  />
                  <span className="ml-1">Verifyin Grey</span>
                </div>
              </MenuItem>
              <MenuItem
                value={FilterStatus.INSUFFICIENT}
                className="bK-body2 text-bk_text_secondary">
                <div className="flex">
                  <DisplayImage
                    containerClass="items-center"
                    imageName={EmployerAppAssetIconsFileNames.VERIFYIN_AMBER}
                    imageType={DisplayImageType.IMAGE}
                    className="w-md h-md"
                  />
                  <span className="ml-1">Verifyin Amber</span>
                </div>
              </MenuItem>
              <MenuItem
                value={FilterStatus.INPROGRESS}
                className="bK-body2 text-bk_text_secondary">
                Yet to generate
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {OrderCandidateStore.caseList.length ? (
        <Grid display={'flex'} alignItems={'center'}>
          <Processes />
          <DownloadOptions
            totalCandidate={consentData.totalCandidates}
            caseTableSearchValue={OrderCandidateStore.caseTableSearchValue}
            selectedPackages={selectedPackages}
            selectedRole={selectedRole}
            selectedProgressStatus={selectedProgressStatus}
            selectedInitiatedStatus={selectedInitiatedStatus}
            selectedReportGeneratedStatus={selectedReportGeneratedStatus}
            selectedReportStatus={selectedReportStatus}
            consentData={consentData}
          />
        </Grid>
      ) : (
        ''
      )}
    </Stack>
  );
};
