import { integerToRoman } from './integer-to-roman';

export interface DocumentWithIndex {
  checkId: string;
  document: any;
  index: string;
}

export interface SubcheckMapValue {
  reportTitle: string;
  subcheckName: string;
}

export interface FlattenedSubcheck {
  reportTitle: string;
  reportType: string;
  subcheckName: string;
  subcheckStatus: string;
  details: any;
  meta: any;
}

export const restructureData = (reportData: any) => {
  const subcheckMap = new Map<string, SubcheckMapValue>();
  const documentList: DocumentWithIndex[] = [];
  const flattenedSubchecks: FlattenedSubcheck[] = [];
  const documentIndexMap = new Map<string, number>();

  // Populate the map, list, and flattened array
  reportData.reports.forEach((report: any) => {
    report.documentlist.forEach((document: any) => {
      const checkId = document.meta.check_id;
      subcheckMap.set(checkId, {
        reportTitle: report.title,
        subcheckName: document.product_listing_name,
      });

      // Add to flattened array
      flattenedSubchecks.push({
        reportTitle: report.title,
        reportType: report.type,
        subcheckName: document.product_listing_name,
        subcheckStatus: document.check_status.status,
        details: document.details,
        meta: document.meta,
      });

      document.documents_provided.forEach((doc: any) => {
        const currentIndex = documentIndexMap.get(checkId) || 0;
        documentList.push({
          checkId: checkId,
          document: doc,
          index: `Annexure ${integerToRoman(currentIndex + 1)}`,
        });
        documentIndexMap.set(checkId, currentIndex + 1);
      });
    });
  });

  return { subcheckMap, documentList, flattenedSubchecks };
};
