import { BKStyledEBTableCell } from '@gigin-work-space/common-ui';
import { CheckoutStore, EmployerCDCStore } from '@gigin-work-space/store';
import {
  COLORS,
  convertKiloBiteToMegaBite,
  formatDateFromTimestamp,
} from '@gigin-work-space/utils';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { axiosInstance, endpoints } from 'src/app/utils';
import EBDeleteRecord from './employer-bulk-table-delete';
import EBTableFileDetails from './employer-bulk-table-file-details';
import EBTableUserDetails from './employer-bulk-user-details';

export interface fileMediaIdType {
  _id: string;
  file_name: string;
  file_type: string;
  file_size: number;
  file_url: string;
  media_type: {
    data_type: string;
    field_id: string;
  };
  isactive: boolean;
  account_id: string;
  tenantId: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: Date;
  __v: number;
}
interface fileType {
  _id: string;
  orderId: string;
  packageId: string;
  mediaId: fileMediaIdType;
  tenantId: string;
  accountId: string;
  created_by: { name: '' };
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
interface IEBFileDetailsProps {
  files: fileType[];
  // eslint-disable-next-line no-unused-vars
  fetchDataTable: (order_id: string, package_id: string) => void;
}
const EBFileDetails = ({ files, fetchDataTable }: IEBFileDetailsProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key: any) => (
    <Button
      sx={{ textTransform: 'none', color: COLORS.bk_bg_primary }}
      size="small"
      // className="text-bk_bg_primary"
      onClick={() => {
        // Undo logic goes here (e.g., restore the file)
        // You'll need to define the specific undo functionality.
        closeSnackbar(key); // Close the snackbar after undoing the action
      }}>
      {/* Undo */}
    </Button>
  );
  const deleteFileFromList = async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `${endpoints.UPLOAD_EMPLOYER_BULK_S3}/${id}`
      );
      if (response?.data) {
        fetchDataTable(
          EmployerCDCStore.request_id,
          EmployerCDCStore.request_role_id
        );
        enqueueSnackbar('File deleted successfully!', {
          variant: 'success',
          autoHideDuration: 2000,
          action,
        });
        EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
      }
    } catch (e) {
      enqueueSnackbar('Error deleting file!', {
        variant: 'error',
        autoHideDuration: 2000,
        action,
      });
    }
  };
  return (
    <Box className="w-full">
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 700, overflowX: 'scroll' }}
          aria-label="customized table"
          className="w-full">
          <TableHead>
            <TableRow>
              <BKStyledEBTableCell colSpan={2} className="bk-sub-heading2">
                Uploaded files({files.length ?? 0})
              </BKStyledEBTableCell>
              <BKStyledEBTableCell className="bK-body2 text-bk_text_secondary">
                Uploaded by
              </BKStyledEBTableCell>
              <BKStyledEBTableCell className="bK-body2 text-bk_text_secondary">
                Date
              </BKStyledEBTableCell>
              <BKStyledEBTableCell className="bK-body2 text-bk_text_secondary">
                File size
              </BKStyledEBTableCell>
              <BKStyledEBTableCell></BKStyledEBTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((row: fileType, index: number) => (
              <TableRow key={index.toString()}>
                <BKStyledEBTableCell scope="row">
                  <EBTableFileDetails mediaId={row?.mediaId} />
                </BKStyledEBTableCell>
                <BKStyledEBTableCell></BKStyledEBTableCell>
                <BKStyledEBTableCell>
                  <EBTableUserDetails
                    index={index}
                    createdBy={row?.created_by?.name}
                  />
                </BKStyledEBTableCell>
                <BKStyledEBTableCell>
                  {formatDateFromTimestamp(row.createdAt, 'MMM DD, YYYY')}
                </BKStyledEBTableCell>
                <BKStyledEBTableCell>
                  {convertKiloBiteToMegaBite(row.mediaId.file_size)}
                </BKStyledEBTableCell>
                <BKStyledEBTableCell>
                  <IconButton
                    edge="end"
                    style={{ cursor: 'pointer' }}
                    disabled={CheckoutStore.step_3}
                    onClick={() => {
                      deleteFileFromList(row?._id);
                    }}>
                    <EBDeleteRecord />
                  </IconButton>
                </BKStyledEBTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EBFileDetails;
