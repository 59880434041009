import { Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { styles } from '../styles';
import {
  addressIcon,
  emailIcon,
  phoneIcon,
  userDetailGradient,
} from './image-library';
import { ImageComponent } from './report-image';
import { reportStatusMapper } from './report-status-mapper';

export interface UserDetailContainerProps {
  reportDataObject: any;
}

export const UserDetailContainer = (props: UserDetailContainerProps) => {
  const { reportDataObject } = props;

  return (
    <View style={styles.userDetailsContainer}>
      <ImageComponent
        src={userDetailGradient}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
      <View style={{ flexDirection: 'column', gap: 18, padding: '30px 25px' }}>
        {/* profile and address section */}
        <View style={styles.profileSection}>
          <View style={{ width: '12%' }}>
            <ImageComponent
              src={reportDataObject.basic_detail.profile_image}
              style={styles.profileImageLarge}
            />
          </View>

          <View style={styles.profileInfo}>
            <View style={styles.nameSection}>
              <View style={{ gap: 4 }}>
                <Text style={[styles.textHeadLine3, styles.textLight]}>
                  {reportDataObject.basic_detail.name}
                </Text>
                <View style={styles.contactDetails}>
                  <View style={styles.iconTextContainer}>
                    <ImageComponent
                      src={phoneIcon}
                      style={styles.iconImageSmall}
                    />
                    <Text style={[styles.textBody3, styles.textLight]}>
                      {reportDataObject.basic_detail.mobile}
                    </Text>
                  </View>
                  <View style={styles.iconTextContainer}>
                    <ImageComponent
                      src={emailIcon}
                      style={styles.iconImageSmall}
                    />
                    <Text style={[styles.textBody3, styles.textLight]}>
                      {reportDataObject.basic_detail.email}
                    </Text>
                  </View>
                </View>
              </View>
              <ImageComponent
                src={reportStatusMapper(reportDataObject.overall_status.status)}
                style={[styles.pillImage]}
              />
            </View>
            {reportDataObject.basic_detail.address !== '-' && (
              <View style={[styles.iconTextContainer, { marginTop: 6 }]}>
                <ImageComponent
                  src={addressIcon}
                  style={styles.iconImageSmall}
                />
                <Text style={[styles.textBody3, styles.textLight]}>
                  {reportDataObject.basic_detail.address}
                </Text>
              </View>
            )}
          </View>
        </View>
        {/* order detail section */}
        <View style={styles.orderSection}>
          <View style={styles.orderColumn}>
            <Text style={[styles.textBody2, styles.textLight]}>
              Initiated on
            </Text>
            <Text style={[styles.textSubHeading2, styles.textLight]}>
              {dayjs(reportDataObject.request.initiated_on).format(
                'DD-MM-YYYY'
              )}
            </Text>
          </View>
          <View style={styles.orderColumn}>
            <Text style={[styles.textBody2, styles.textLight]}>
              Initiated by
            </Text>
            <View style={styles.iconTextContainer}>
              <ImageComponent
                src={reportDataObject.request.initiated_by.profile_image}
                style={styles.profileImageSmall}
              />
              <Text style={[styles.textBody3, styles.textLight]}>
                {reportDataObject.request.initiated_by.name}
              </Text>
            </View>
          </View>
          <View style={styles.orderColumn}>
            <Text style={[styles.textBody2, styles.textLight]}>
              Verified by
            </Text>
            <View style={styles.iconTextContainer}>
              <ImageComponent
                src={reportDataObject.request.verified_by.profile_image}
                style={styles.profileImageSmall}
              />
              <Text style={[styles.textBody3, styles.textLight]}>
                {reportDataObject.request.verified_by.name}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
