import { View } from '@react-pdf/renderer';
import { useMemo, useState } from 'react';
import { styles } from '../styles';

export interface WatermarkProps {
  checkStatus: string;
}

export const Watermark = (props: WatermarkProps) => {
  const { checkStatus } = props;
  const [isRotated, setIsRotated] = useState(
    checkStatus.toLowerCase() === 'inprogress'
  );

  //   Rerender when prop checkStatus changes
  useMemo(() => {
    setIsRotated(checkStatus.toLowerCase() === 'inprogress');
  }, [checkStatus]);

  return (
    <View
      style={
        isRotated ? styles.watermarkRotatedContainer : styles.watermarkContainer
      }
      fixed>
      {/* TODO: It will be use in future when QC will come */}
      {/* {isRotated && <Text style={styles.watermarkText}>QC PENDING</Text>}
      {!isRotated && (
        <ImageComponent
          src={checkCompletedWatermark}
          style={styles.watermarkImage}
        />
      )} */}
      <></>
    </View>
  );
};
