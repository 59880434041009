import { DisplayImageType } from '@gigin-work-space/utils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { IAccountData } from '../bgv/component/employer-upload/interface';
import CircularIndeterminate from '../bgv/component/skelton/bgv-loader';
import AccountAutocomplete from './components/AccountAutoComplete';
import CheckCases from './components/CheckCases';
import FilterByDays from './components/FilterByDays';
import { useAccounts } from './store/AccountContext';
import { useCheck } from './store/ChecksContext';
const ChecksDashboard = () => {
  const { allAccounts, getAccounts } = useAccounts(); // for getting all client data
  const [accountData, setAccountData] = useState<IAccountData | null>(null); // for capturing data in the input

  const {
    checkItems,
    handleGetChecks,
    filterDays,
    setFilterDays,
    accountDataId,
    setAccountDataId,
    isLoading,
  } = useCheck();

  const navigate = useNavigate();

  const singleCheckHandler = (check: string, item: any) => {
    const formattedCheck = check.replace(/ /g, '-').toLowerCase();
    globalNavigateTo(
      `/ops-platform/checks-dashboard/${encodeURIComponent(formattedCheck)}`,
      navigate,
      { state: item }
    );
  };

  const handleDaysChange = (event: any) => {
    setFilterDays(event.target.value);
  };

  useEffect(() => {
    getAccounts();
  }, [filterDays]);

  useEffect(() => {
    if (accountData && accountData._id === null) {
      getAccounts();
    }
  }, [accountData, getAccounts]);

  const handleChangeAccount = async (event: {
    target: { value: IAccountData | null };
  }) => {
    const selectedAccount = event.target.value;

    if (selectedAccount && selectedAccount._id === '') {
      setAccountData(null);
      setAccountDataId('');
    } else if (selectedAccount) {
      setAccountData(selectedAccount);
      setAccountDataId(selectedAccount._id);
    }
  };

  useEffect(() => {
    handleGetChecks();
  }, [getAccounts]);

  useEffect(() => {
    handleGetChecks();
  }, [filterDays, accountDataId]);

  const isScreenLarger = useMediaQuery('(min-width: 768px');

  return (
    <>
      <Typography variant="h4" className="m-4 p-3 font-[700]">
        Checks Dashboard
      </Typography>

      <Box className="flex justify-between max-md:flex-col max-md:items-center max-md:gap-5 m-4">
        <AccountAutocomplete
          allAccounts={allAccounts}
          accountData={accountData}
          handleChangeAccount={handleChangeAccount}
          inputLabel={'Select Account/ Company'}
          showAllAccountsOption={true}
        />

        <FilterByDays
          filterDays={filterDays}
          handleDaysChange={handleDaysChange}
        />
      </Box>

      <Box className="mt-8 py-1.5" component={Paper} elevation={2}>
        {isLoading ? (
          <CircularIndeterminate />
        ) : checkItems?.length > 0 ? (
          checkItems?.map(([key, checkItem]) => {
            return (
              <Box
                key={key}
                className={`bk-single-checks flex ${
                  isScreenLarger ? 'flex-row' : 'flex-col'
                } my-4 mx-2`}
                onClick={() => singleCheckHandler(key, checkItem)}>
                <Box
                  key={key}
                  className={`flex items-center justify-start ${
                    isScreenLarger ? 'w-1/3' : 'w-full'
                  }`}
                  padding={2}
                  border="1px solid #e0e0e0"
                  borderRadius={2}>
                  <FileDisplayComponent
                    fileUrl={checkItem?.icon}
                    fileType={DisplayImageType.URL}
                    fileClassName="h-8 w-8 object-contain"
                  />
                  <Box className="ml-4 w-full max-md:flex justify-between">
                    <Stack className="flex flex-row items-center justify-start gap-1.5 max-md:flex-row max-md:justify-center">
                      <Typography variant="h6" className="text-justify">
                        {key}
                      </Typography>
                      <OpenInNewIcon className="bk-display-icon" />
                    </Stack>
                    <Typography variant="body2" className="opacity-90">
                      {checkItem?.count} initiated
                    </Typography>
                  </Box>
                </Box>

                <Divider
                  orientation={isScreenLarger ? 'vertical' : 'horizontal'}
                  flexItem
                  className="h-auto mx-4 my-1"
                />
                <Grid
                  container
                  justifyContent={
                    isScreenLarger ? 'space-around' : 'flex-start'
                  }
                  alignItems={'center'}
                  marginTop="0">
                  <CheckCases />
                </Grid>
              </Box>
            );
          })
        ) : (
          <Typography variant="subtitle1" className="text-center">
            No Data Found
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ChecksDashboard;
