import { EmployerCDCStore } from '@gigin-work-space/store';
import { formatDateFromTimestamp } from '@gigin-work-space/utils';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
interface getHeaderType {
  orderId: string;
  orderStatus: string;
  createdBy: string;
  createdAt: string;
  numberOfCandidates: number;
  shared: number;
}
const EBHeader = () => {
  const [header, setHeader] = useState<getHeaderType>({} as getHeaderType);
  const navigate = useNavigate();
  EmployerCDCStore.request_id = localStorage.getItem('order_id') ?? '';
  const getHeaderDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_EB_HEADER_DETAILS}/${EmployerCDCStore.request_id}`
      );
      if (response?.data) {
        setHeader(response?.data?.data);
      }
    } catch (e) {
      console.error('Error fetching header details.');
    }
  };
  useEffect(() => {
    getHeaderDetails();
  }, []);
  return (
    <Grid
      item
      container
      direction={'row'}
      justifyContent={'space-between'}
      className=" bg-bk_bg_primary">
      <Grid item spacing={0.5} sx={{ pl: 2, pt: 0.5 }}>
        <Stack direction={'row'}>
          <Typography
            className="bK-body2 cursor-pointer"
            sx={{ color: '#356AF2 !important' }}
            onClick={() => {
              globalNavigateTo('/bgv', navigate);
            }}>
            Verification Dashboard
          </Typography>
          <Typography className="bK-body2">/ {header?.orderId}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography className="bk-headline1">
            Verification Request {header?.orderId}
          </Typography>
          <Typography className="rounded-xl bg-bk_tag_gray_bg py-1 px-2 bK-body2 flex items-center">
            {header?.numberOfCandidates} Candidates{' '}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                background: '#DCD9DD',
                margin: '0 8px', // Set margin for both left and right sides
              }}
            />
            {formatDateFromTimestamp(header.createdAt, 'DD MMM')}
          </Typography>
          <DisplayImage
            imageName="info_icon.svg"
            imageType="icon"
            width="20px"
            height="20px"
          />
        </Stack>
      </Grid>
      <Grid className="flex items-center" item>
        {/* <Stack>Images</Stack> */}
      </Grid>
    </Grid>
  );
};

export default EBHeader;
