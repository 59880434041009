/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DropzoneFileUploader,
  FileTypeIconComponent,
} from '@gigin-work-space/common-ui';
import {
  AUTO_HIDE_TIMEOUT,
  clearIndexedDBEntries,
  COLORS,
  deleteItem,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  EnumIndexedDbContextKey,
  EnumIndexedDbStore,
  FILE_TYPE,
  FILES_CONTEXT,
  FONTWEIGHT,
  getItem,
  guardedPageNavigate,
  MAX_FILE_RECRUITER_BUL_UPLOAD,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_ZIP,
  MimeTypes,
  SCREEN_SIZE,
  setItem,
  SPACING,
  useScreenSize,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useWizard } from 'react-use-wizard';
import {
  DisplayImage,
  itemInterface,
  MultiColumnList,
} from 'src/app/components';
import {
  useFooter,
  useOrderCreationContext,
  useOrderCreationStepContext,
} from 'src/app/context';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

/* eslint-disable-next-line */
export interface BgvRecruiterDocumentUploadProps {
  contextKey?: EnumIndexedDbContextKey;
}

interface IMedia {
  _id: string;
  file_name: string;
  file_type: string;
  file_size: number;
  file_url: string;
  media_type: {
    field_id: string;
  };
  isactive: boolean;
  account_id: string;
  tenantId: string;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface ICreatedBy {
  _id: string;
  name: string;
  email: string;
}

interface IUploadedFile {
  _id: string;
  orderId: string;
  mediaId: IMedia;
  tenantId: string;
  accountId: string;
  created_by: ICreatedBy;
  count: number;
  deletedAt: null | string;
  __v: number;
  createdAt: string;
  updatedAt: string;
}

export const BgvRecruiterDocumentUpload = React.memo(
  ({
    contextKey = EnumIndexedDbContextKey.BGV_RECRUITER_BULK_DOCUMENT_UPLOAD,
  }: BgvRecruiterDocumentUploadProps) => {
    const { dispatch: stepDispatch } = useOrderCreationStepContext();
    const {
      dispatch: orderDispatch,
      state: { selectedPackage, orderId },
    } = useOrderCreationContext();
    const { state: footerState, dispatch: footerDispatch } = useFooter();
    const { nextStep, activeStep } = useWizard();
    const { enqueueSnackbar } = useSnackbar();
    const screenSize = useScreenSize();
    const navigate = useNavigate();

    const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);
    const [requiredDocuments, setRequiredDocuments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [documentListFetching, setDocumentListFetching] =
      useState<boolean>(false);
    const [isUploadingDocuments, setIsUploadingDocuments] =
      useState<boolean>(false);
    const [isRemovingFiles, setIsRemovingFiles] = useState<boolean>(false);

    const fetchDocumentList = async () => {
      setDocumentListFetching(true);
      footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
      try {
        const response = await axiosInstance.get(
          `${endpoints.GET_BULK_LIST_OF_DOCUMENTS}/${selectedPackage?.id}`
        );
        if (response?.data?.statusCode === HttpStatus.OK) {
          const documentList: itemInterface[] =
            response?.data?.data?.listOfDocuments?.map((document: any) => {
              return {
                title: document?.title,
                isMandatory: document?.isMandatory,
              };
            });
          setRequiredDocuments(documentList);
        }
      } catch (error) {
        console.error('Failed to fetch document list:', error);
        enqueueSnackbar('Failed to fetch document list', {
          variant: VARIANT_TYPE.ERROR,
        });
      } finally {
        setDocumentListFetching(false);
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
      }
    };

    const uploadFilesToServer = async (newFiles: File[]) => {
      const formData = new FormData();
      newFiles.forEach(file => formData.append('files', file));
      formData.append('packageId', selectedPackage?.id || '');
      formData.append('orderId', orderId || '');
      formData.append(
        'media',
        JSON.stringify({
          field_id: 'support-documents',
          isEncrypted: true,
          filePathContext: 'support-documents',
          isTenantIsolated: true,
          isPrivate: true,
          shouldCompress: false,
        })
      );

      try {
        const response = await axiosInstance.post(
          `${endpoints.BULK_UPLOAD_DOCUMENT_LIST}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response?.data?.statusCode === HttpStatus.OK) {
          const responseData = await fetchUploadedFiles();
          return responseData;
        }
        throw new Error('File upload failed');
      } catch (error) {
        console.error('Failed to upload files:', error);
        enqueueSnackbar('Failed to upload files', {
          variant: VARIANT_TYPE.ERROR,
        });
        throw error;
      }
    };

    const removeFilesFromServer = async (fileIds: string[]) => {
      try {
        const response = await axiosInstance.delete(
          `${endpoints.BULK_UPLOAD_REMOVE_DOCUMENTS}/${orderId}`,
          {
            data: {
              ids: fileIds,
            },
          }
        );

        if (response?.data?.statusCode === HttpStatus.OK) {
          const responseData = await fetchUploadedFiles();
          return responseData;
        }
        throw new Error('File removal failed');
      } catch (error) {
        console.error('Failed to remove file:', error);
        enqueueSnackbar('Failed to remove file', {
          variant: VARIANT_TYPE.ERROR,
        });
        throw error;
      }
    };

    const fetchUploadedFiles = useCallback(async () => {
      const response = await axiosInstance.get(
        `${endpoints.GET_UPLOADED_BULK_DOCUMENTS}/${orderId}?context=${FILES_CONTEXT.DOCUMENTS}`
      );

      if (response?.data?.statusCode === HttpStatus.OK) {
        return response?.data;
      }
      throw new Error('Failed to fetch uploaded files');
    }, [contextKey]);

    const handleFilesChange = useCallback(
      async (newFiles: File[]) => {
        if (newFiles && newFiles.length > MAX_FILE_RECRUITER_BUL_UPLOAD) {
          enqueueSnackbar(
            `Maximum ${MAX_FILE_RECRUITER_BUL_UPLOAD} files can be uploaded at a time`,
            {
              variant: VARIANT_TYPE.ERROR,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }
          );
          return;
        }

        setIsUploadingDocuments(true);
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
        try {
          const uploadedFilesResponse = await uploadFilesToServer(newFiles);
          setUploadedFiles(uploadedFilesResponse?.data);
          await setItem(
            EnumIndexedDbStore.FILES,
            contextKey,
            uploadedFilesResponse?.data
          );
          enqueueSnackbar('File uploaded successfully', {
            variant: VARIANT_TYPE.SUCCESS,
            autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
          });
        } catch (error) {
          console.error('Failed to save files to IndexedDB:', error);
        } finally {
          setIsUploadingDocuments(false);
          footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
        }
      },
      [contextKey, footerDispatch, uploadFilesToServer, uploadedFiles]
    );

    const handleRemoveFile = useCallback(
      async (fileToRemove: IUploadedFile) => {
        setIsRemovingFiles(true);
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
        try {
          const updatedFiles = await removeFilesFromServer([fileToRemove._id]);
          setUploadedFiles(updatedFiles?.data);
          await setItem(
            EnumIndexedDbStore.FILES,
            contextKey,
            updatedFiles?.data
          );
          enqueueSnackbar('File removed successfully', {
            variant: VARIANT_TYPE.SUCCESS,
            autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
          });
        } catch (error) {
          console.error('Failed to remove file from IndexedDB:', error);
        } finally {
          setIsRemovingFiles(false);
          footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
        }
      },
      [contextKey, footerDispatch, uploadedFiles]
    );

    const handlePrimaryCTA = useCallback(async () => {
      stepDispatch({
        type: 'MARK_STEP_COMPLETED',
        payload: activeStep,
      });
      enqueueSnackbar('Candidate documents uploaded successfully', {
        variant: VARIANT_TYPE.SUCCESS,
        autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
      });

      try {
        await clearIndexedDBEntries(EnumIndexedDbStore.FILES);
      } catch (error) {
        console.error('Failed to clear IndexedDB entries:', error);
      }

      globalNavigateTo(guardedPageNavigate(), navigate);
    }, [stepDispatch, activeStep, navigate, enqueueSnackbar]);

    const COLUMNS_PER_PAGE_MULTICOLUMN_LIST = useMemo(() => {
      if (screenSize === SCREEN_SIZE.EXTRALARGE) {
        return 8;
      } else if (screenSize === SCREEN_SIZE.LARGE) {
        return 10;
      } else {
        return 12;
      }
    }, [screenSize]);

    // SIDE EFFECTS
    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_ACTION',
        action: handlePrimaryCTA,
        label: 'Done',
      });
      stepDispatch({
        type: 'MARK_STEP_VISITED',
        payload: activeStep,
      });
    }, [footerDispatch, handlePrimaryCTA, stepDispatch, activeStep]);

    useEffect(() => {
      const initializeFiles = async () => {
        try {
          const storedFiles = await getItem<IUploadedFile[]>(
            EnumIndexedDbStore.FILES,
            contextKey
          );
          setUploadedFiles(storedFiles || []);
        } catch (error) {
          console.error('Failed to load files from IndexedDB:', error);
        } finally {
          setLoading(false);
        }
      };
      initializeFiles();
    }, [contextKey]);

    useEffect(() => {
      const handleBeforeUnload = async () => {
        if (uploadedFiles.length === 0) {
          try {
            await deleteItem(EnumIndexedDbStore.FILES, contextKey);
          } catch (error) {
            console.error('Failed to clear files from IndexedDB:', error);
          }
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [uploadedFiles, contextKey]);

    useEffect(() => {
      fetchDocumentList();
    }, []);

    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_DISABLED',
        disabled: uploadedFiles.length === 0,
      });
    }, [uploadedFiles, footerDispatch]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <Box className="w-[90vw] h-full mx-auto overflow-auto">
        <Paper
          className="w-full p-xl3 flex flex-col gap-y-xl2 rounded-lg"
          elevation={0}>
          <Box className="w-full">
            <Typography className="bk-typography-h6">
              Documents required for each candidate
            </Typography>
            <Typography className="bk-typography-body2">
              Our Team will check the documents you upload and reach out in case
              of any Insufficiencies found.
            </Typography>
          </Box>
          {documentListFetching ? (
            <Box className="flex items-center justify-center">
              <CircularProgress />
            </Box>
          ) : (
            <MultiColumnList
              items={requiredDocuments}
              columns={Math.ceil(
                requiredDocuments.length / COLUMNS_PER_PAGE_MULTICOLUMN_LIST
              )}
              itemsPerColumn={COLUMNS_PER_PAGE_MULTICOLUMN_LIST}
              bulletType="number"
              containerStyle={{
                gap: SPACING.xl2,
              }}
              itemStyle={{ fontWeight: FONTWEIGHT.extrabold }}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={uploadedFiles.length > 0 ? 6 : 12}>
              <DropzoneFileUploader
                className="border-[2px] border-dashed border-bk_primary_light p-xl2 min-h-[32vh] flex items-center justify-center relative rounded-lg bg-bk_bg_primary_states_hover"
                label={
                  <Typography className="bk-typography-h6 text-center">
                    Drag & drop your file here or{' '}
                    <span className="text-bk_primary_main cursor-pointer">
                      {activeStep === 1 ? 'Upload the template' : 'Upload here'}
                    </span>
                  </Typography>
                }
                description={
                  <Typography className="bk-typography-body2 text-center">
                    {activeStep === 1 ? (
                      'Download your .csv template from above and fill in the candidate details precisely with each column'
                    ) : (
                      <>
                        You can upload up to 20 files in one go. (Max combined
                        upload limit 200 MB)
                        <br />
                        Supported Files: pdf/ image/.zip
                      </>
                    )}
                  </Typography>
                }
                loaderLabel={
                  <Typography className="bk-typography-h6">
                    Your file is being uploaded, Please wait for few seconds
                  </Typography>
                }
                loaderDescription={
                  <Typography className="bk-typography-body2">
                    Once uploaded, kindly go through the list of documents
                  </Typography>
                }
                icon={
                  <DisplayImage
                    imageName={
                      EmployerAppAssetIconsFileNames.CUSTOM_FILE_UPLOAD_OUTLINE_SVG
                    }
                    imageType={DisplayImageType.ICON}
                  />
                }
                onFilesChange={handleFilesChange}
                allowedFileTypes={FILE_TYPE.IMAGE_PDF_ZIP}
                multiple={true}
                isUploading={isUploadingDocuments}
                fileSizeLimits={{
                  [MimeTypes['.zip']]: MAX_FILE_SIZE_ZIP,
                  [MimeTypes['.pdf']]: MAX_FILE_SIZE,
                  [MimeTypes['.jpeg']]: MAX_FILE_SIZE,
                  [MimeTypes['.jpg']]: MAX_FILE_SIZE,
                  [MimeTypes['.png']]: MAX_FILE_SIZE,
                }}
              />
            </Grid>
            {uploadedFiles.length > 0 && (
              <Grid item xs={12} md={6}>
                <Typography className="bk-typography-h6">
                  Uploaded Documents ({uploadedFiles.length})
                </Typography>
                <Box className="grid grid-cols-1 xl:grid-cols-2 gap-x-lg2 gap-y-sm max-h-[30vh] overflow-y-auto">
                  {uploadedFiles.map((file, index) => (
                    <Box
                      key={index}
                      className="flex items-center justify-between px-lg py-sm border rounded-lg bg-bk_bg_divider">
                      <Box className="w-[80%] flex items-center gap-x-xs2">
                        <FileTypeIconComponent
                          fileType={file?.mediaId?.file_type}
                          iconProps={{
                            sx: {
                              color: COLORS.bk_selection_secondary,
                              fontSize: SPACING.xl2,
                            },
                          }}
                        />
                        <Typography className="w-full bk-typography-subtitle1 truncate">
                          {file?.mediaId?.file_name}
                        </Typography>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveFile(file)}
                        disabled={isRemovingFiles}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    );
  }
);

export default BgvRecruiterDocumentUpload;
