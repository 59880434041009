import { DocumentWithIndex } from './restructure-data';

export const getDocumentsByCheckId = (
  documentList: DocumentWithIndex[],
  checkId: string
) => {
  return documentList.filter(doc => doc.checkId === checkId);
};

export const getAnnexuresByCheckId = (
  documentList: DocumentWithIndex[],
  checkId: string
) => {
  const documents = getDocumentsByCheckId(documentList, checkId);
  if (documents.length <= 2) {
    return documents.map(doc => doc.index).join(', ');
  } else {
    return `${documents[0].index}, ${documents[1].index} +${
      documents.length - 2
    } more`;
  }
};
