import { COLORS, EnumBgvReportVerifyinStatus } from '@gigin-work-space/utils';
import {
  amberBtnImage,
  amberTxtImage,
  clearIcon,
  clearLogo,
  discrepantIcon,
  discrepantLogo,
  greenBtnImage,
  greenTxtImage,
  greyBtnImage,
  greyTxtImage,
  inProgressBtnImage,
  inProgressLogo,
  inProgressTxtImage,
  insufficientIcon,
  insufficientLogo,
  noResponseIcon,
  noResponseLogo,
  qcPendingLogo,
  redBtnImage,
  redTxtImage,
  unableToVerifyLogo,
} from './image-library';

export const reportStatusMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: clearLogo,
    DISCREPANT: discrepantLogo,
    INSUFFICIENT: insufficientLogo,
    NO_RESPONSE: noResponseLogo,
    INPROGRESS: inProgressLogo,
    QC_PENDING: qcPendingLogo,
    UNABLE_TO_VERIFY: unableToVerifyLogo,
  };

  return statusMap[reportStatus] || null;
};

export const reportStatusIconMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: clearIcon,
    DISCREPANT: discrepantIcon,
    INSUFFICIENT: insufficientIcon,
    NO_RESPONSE: noResponseIcon,
    INPROGRESS: noResponseIcon,
    UNABLE_TO_VERIFY: noResponseIcon,
  };

  return statusMap[reportStatus] || null;
};

// Consolidated Report Status Mapper Button Status
export const reportStatusBtnMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: greenBtnImage,
    DISCREPANT: redBtnImage,
    INSUFFICIENT: greyBtnImage,
    NO_RESPONSE: greyBtnImage,
    INPROGRESS: inProgressBtnImage,
    QC_PENDING: greyBtnImage,
    UNABLE_TO_VERIFY: amberBtnImage,
  };

  return statusMap[reportStatus] || null;
};

// Consolidated Report Status Mapper Text Status
export const reportStatusTextMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: greenTxtImage,
    DISCREPANT: redTxtImage,
    INSUFFICIENT: greyTxtImage,
    NO_RESPONSE: greyTxtImage,
    INPROGRESS: inProgressTxtImage,
    QC_PENDING: greyTxtImage,
    UNABLE_TO_VERIFY: amberTxtImage,
  };

  return statusMap[reportStatus] || null;
};

// Consolidated Report Status Mapper Status Color
export const reportStatusColorMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: COLORS.bk_report_success_main,
    DISCREPANT: COLORS.bk_report_error_main,
    INSUFFICIENT: COLORS.bk_report_grey_a400,
    NO_RESPONSE: COLORS.bk_report_grey_a400,
    INPROGRESS: COLORS.bk_report_text_secondary,
    QC_PENDING: COLORS.bk_report_grey_a400,
    UNABLE_TO_VERIFY: COLORS.bk_report_amber_800,
  };

  return statusMap[reportStatus] || null;
};

// Consolidated Report Status Mapper Status Color
export const reportStatusLabelMapper = (reportStatus: string) => {
  const statusMap: Record<string, any> = {
    CLEAR: EnumBgvReportVerifyinStatus.VERIFYIN_GREEN,
    DISCREPANT: EnumBgvReportVerifyinStatus.VERIFYIN_RED,
    INSUFFICIENT: EnumBgvReportVerifyinStatus.VERIFYIN_GREY,
    NO_RESPONSE: EnumBgvReportVerifyinStatus.VERIFYIN_GREY,
    INPROGRESS: EnumBgvReportVerifyinStatus.INPROGRESS,
    QC_PENDING: EnumBgvReportVerifyinStatus.VERIFYIN_GREY,
    UNABLE_TO_VERIFY: EnumBgvReportVerifyinStatus.VERIFYIN_AMBER,
  };

  return statusMap[reportStatus] || null;
};
