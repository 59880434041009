/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  COLORS,
  guardedPageNavigate,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { setTenantName } from 'src/app/utils/router/tenantState';
import { AuthenticationPaper, PasswordStrengthBar } from '../../../components';
import { axiosInstance, endpoints } from '../../../utils';
import { AuthenticationHOCForSignup } from '../authentication-hoc/authentication-hoc-without-background';

interface User {
  email: string;
  accountId: string;
  tenantId: string;
  userId: string;
  role: string;
  isRegistered: boolean;
  signUpToken: string;
}

export function SetPassword() {
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingStrength, setIsCheckingStrength] = useState(false);
  const [passwordState, setPasswordState] = useState({
    strength: null as number | null,
    feedback: [] as string[],
    warning: null as string | null,
    isValid: false as boolean,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const checkPasswordStrength = useCallback(
    debounce(async (password: string) => {
      setIsCheckingStrength(true);
      try {
        const response = await axiosInstance.post(
          `${endpoints.POST_AUTH_CHECK_PASSWORD_STRENGTH}`,
          { password }
        );
        const data = response?.data?.data;
        setPasswordState({
          strength: data.score,
          feedback: data.feedback.suggestions || [],
          warning: data.feedback.warning || null,
          isValid: data.valid_password,
        });
      } catch (error) {
        // console.error('Failed to check password strength', error);
      } finally {
        setIsCheckingStrength(false);
      }
    }, 300),
    []
  );

  const checkMfaAndRedirect = useCallback((is2faEnabled: boolean) => {
    if (is2faEnabled) {
      globalNavigateTo('/mfa/otp-verification', navigate);
    } else {
      enqueueSnackbar('Your password has been set successfully', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      globalNavigateTo(guardedPageNavigate(), navigate);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      userPassword: '',
      userConfirmPassword: '',
    },
    validate: values => {
      const errors: { userConfirmPassword?: string } = {};
      if (values.userPassword !== values.userConfirmPassword) {
        errors.userConfirmPassword = 'Passwords must match';
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = {
          password: values.userPassword,
          confirmPassword: values.userConfirmPassword,
          token: token,
        };

        setIsLoading(true);

        const response = await axiosInstance.post(
          `${endpoints.POST_AUTH_CREATE_PASSWORD}`,
          data
        );

        const serverResponse = response?.data;

        if (serverResponse?.success) {
          const userAccountDetail = Object.assign(
            {},
            serverResponse?.data?.data?.account_name
          );
          const localStorageData = {
            auth_token: JSON.stringify(serverResponse?.data?.auth_token),
            userAccountDetail: JSON.stringify({
              ...userAccountDetail,
              company_logo: serverResponse?.data?.data?.company_logo,
              is2faEnabled: serverResponse?.data?.data?.is2faEnabled || false,
              tenantName: serverResponse?.data?.data?.tenantName,
            }),
            userPermissions: JSON.stringify(
              serverResponse?.data?.data?.rolePermissionByName
            ),
            role: JSON.stringify(serverResponse?.data?.data?.role),
          };

          Object.entries(localStorageData).forEach(([key, value]) => {
            if (value !== undefined) {
              localStorage.setItem(key, value);
            }
          });
          setTenantName(serverResponse?.data?.data?.tenantName);
          checkMfaAndRedirect(serverResponse?.data?.data?.is2faEnabled);
        } else {
          throw new Error('This link has already been used or has expired');
        }
      } catch (error: any) {
        // console.log(error);
        const errorCode = JSON.parse(
          error?.response?.data?.message
        )?.status_code;
        if (errorCode === 401) {
          enqueueSnackbar('This link has already been used or has expired', {
            variant: VARIANT_TYPE.WARNING,
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar('Failed to set password', {
            variant: VARIANT_TYPE.ERROR,
            autoHideDuration: 2000,
          });
        }
      } finally {
        resetForm();
        setPasswordState({
          strength: null,
          feedback: [],
          warning: null,
          isValid: false,
        });
        setIsLoading(false);
      }
    },
  });

  const handleTogglePassword = useCallback(() => {
    setTogglePassword(prev => !prev);
  }, []);

  const handleToggleConfirmPassword = useCallback(() => {
    setToggleConfirmPassword(prev => !prev);
  }, []);

  useEffect(() => {
    if (formik.values.userPassword) {
      checkPasswordStrength(formik.values.userPassword);
    } else {
      setPasswordState({
        strength: null,
        feedback: [],
        warning: null,
        isValid: false,
      });
    }
  }, [formik.values.userPassword, checkPasswordStrength]);

  const isBtnDisabled = useMemo(() => {
    const allFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    const noErrors = Object.values(formik.errors).length === 0;
    const passwordsMatch =
      formik.values.userPassword === formik.values.userConfirmPassword;
    const isPasswordStrongEnough = passwordState.isValid;

    return !(
      allFieldsFilled &&
      noErrors &&
      passwordsMatch &&
      isPasswordStrongEnough &&
      !isCheckingStrength
    );
  }, [formik.values, formik.errors, passwordState.isValid, isCheckingStrength]);

  return (
    <AuthenticationHOCForSignup>
      <AuthenticationPaper>
        <Typography className="bk-headline1 text-bk_text_primary">
          Set your password
        </Typography>
        <Typography className="bK-body1 text-bk_text_secondary mt-1">
          Set up a Password for your account
        </Typography>
        <Box
          className="mt-6"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}>
          <TextField
            id="userPassword"
            name="userPassword"
            label="New Password"
            className="bk-custom-input-style-user"
            variant="outlined"
            type={togglePassword ? 'text' : 'password'}
            fullWidth
            required
            value={formik.values.userPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.userPassword && Boolean(formik.errors.userPassword)
            }
            helperText={
              formik.touched.userPassword && formik.errors.userPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword}>
                    {togglePassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-testid': 'userPassword' }}
          />
          <TextField
            id="userConfirmPassword"
            name="userConfirmPassword"
            label="Confirm Password"
            className="bk-custom-input-style-user"
            variant="outlined"
            type={toggleConfirmPassword ? 'text' : 'password'}
            fullWidth
            required
            value={formik.values.userConfirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.userConfirmPassword &&
              Boolean(formik.errors.userConfirmPassword)
            }
            helperText={
              formik.touched.userConfirmPassword &&
              formik.errors.userConfirmPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleConfirmPassword}>
                    {toggleConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-testid': 'userConfirmPassword' }}
          />
          <PasswordStrengthBar
            strength={passwordState.strength}
            barCount={4}
            colors={{
              weak: COLORS.bk_alert_failure,
              average: COLORS.bk_alert_new,
              strong: COLORS.bk_alert_success,
              default: COLORS.bk_stroke_secondary,
            }}
            barHeight="8px"
            barSpacing="0.5rem"
          />
          <Box className="flex flex-col items-start justify-center gap-y-1 mt-4">
            {passwordState.feedback.map((feedback, index) => (
              <Typography
                key={index}
                className="bk-caption text-bk_alert_failure">
                {feedback}
              </Typography>
            ))}
            {passwordState.warning && (
              <Typography className="bk-caption text-bk_alert_new">
                {passwordState.warning}
              </Typography>
            )}
          </Box>
          <BKStyledButton
            data-testid="submitBtn"
            className="bg-bk_action_primary mt-6 rounded-lg"
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            disabled={isBtnDisabled || isLoading}>
            Reset password{' '}
            <span className="ml-2">
              {isLoading ? (
                <CircularProgress
                  sx={{ fontSize: '16px', color: COLORS.bk_bg_dark }}
                />
              ) : null}
            </span>
          </BKStyledButton>
        </Box>
      </AuthenticationPaper>
    </AuthenticationHOCForSignup>
  );
}

export default React.memo(SetPassword);
