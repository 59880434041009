import { capitalizeString } from '@gigin-work-space/utils';
import { Link, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { styles } from '../styles';

export interface IndividualCheckMetaProps {
  subCheckData: any;
}
export const IndividualCheckMeta = (props: IndividualCheckMetaProps) => {
  const { subCheckData } = props;
  return (
    <View style={styles.verificationMetaContainer} wrap={false}>
      {subCheckData.documents_provided.length > 0 && (
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.stackColumnContainer, { flex: 1, gap: 8 }]}>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Supporting document(s)
            </Text>
            <Link
              src={`#${subCheckData.first_document_id}`}
              style={[styles.textLink, styles.textBody2]}>
              View document(s)
            </Link>
          </View>

          <View style={[styles.stackColumnContainer, { flex: 1, gap: 6 }]}>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Requested on
            </Text>
            <Text style={[styles.textBody1]}>
              {dayjs(subCheckData.meta.timeline.initiated_on).format(
                'DD-MM-YYYY'
              )}
            </Text>
          </View>
          <View style={[styles.stackColumnContainer, { flex: 1, gap: 6 }]}>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Completed on
            </Text>
            <Text style={[styles.textBody1]}>
              {subCheckData.meta.timeline.completed_on
                ? dayjs(subCheckData.meta.timeline.completed_on).format(
                    'DD-MM-YYYY'
                  )
                : 'N/A'}
            </Text>
          </View>
        </View>
      )}
      {subCheckData.documents_provided.length === 0 && (
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.stackColumnContainer, { flex: 1, gap: 6 }]}>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Requested on
            </Text>
            <Text style={[styles.textBody1]}>
              {dayjs(subCheckData.meta.timeline.initiated_on).format(
                'DD-MM-YYYY'
              )}
            </Text>
          </View>
          <View style={[styles.stackColumnContainer, { flex: 2, gap: 6 }]}>
            <Text style={[styles.textBody2, styles.textSecondary]}>
              Completed on
            </Text>
            <Text style={[styles.textBody1]}>
              {subCheckData.meta.timeline.completed_on
                ? dayjs(subCheckData.meta.timeline.completed_on).format(
                    'DD-MM-YYYY'
                  )
                : 'N/A'}
            </Text>
          </View>
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <View style={[styles.stackColumnContainer, { flex: 1, gap: 6 }]}>
          <Text style={[styles.textBody2, styles.textSecondary]}>Source</Text>
          <Text style={[styles.textBody1]}>
            {subCheckData.meta.verification_source
              ? capitalizeString(subCheckData.meta.verification_source)
              : 'N/A'}
          </Text>
        </View>
        <View style={[styles.stackColumnContainer, { flex: 2, gap: 6 }]}>
          <Text style={[styles.textBody2, styles.textSecondary]}>
            Verified by
          </Text>
          <Text style={[styles.textBody1]}>
            {subCheckData.meta.verified_by
              ? subCheckData.meta.verified_by
              : 'N/A'}
          </Text>
        </View>
      </View>
      {/* individual check additional comments - fixed section */}
      <View style={{ flexDirection: 'row' }}>
        <View style={[styles.stackColumnContainer, { gap: 6, marginTop: 8 }]}>
          <Text style={[styles.textBody2, styles.textSecondary]}>
            Additional Comments
          </Text>
          <Text style={styles.textBody1}>
            {subCheckData.meta.additional_comments
              ? subCheckData.meta.additional_comments
              : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};
