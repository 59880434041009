import { Box, Divider, Grid, Typography } from '@mui/material';

const CheckCases = () => {
  return (
    <Grid item>
      <Box className=" flex flex-between items-center gap-2.5 flex-wrap">
        <Typography variant="subtitle1" className="text-justify">
          Open cases
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-auto mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          DC Pending
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-[4rem] mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          Insufficient
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-auto mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          Unassigned
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-auto mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          Inprogress
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-auto mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          Ready for QA
        </Typography>
        <Divider
          orientation={'vertical'}
          flexItem
          className="h-auto mx-4 my-1"
        />
        <Typography variant="subtitle1" className="text-justify">
          QC Completed
        </Typography>
      </Box>
    </Grid>
  );
};

export default CheckCases;
