import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import OpsSidebar from './OpsSidebar';

const Layout = () => {
  return (
    <Box className="flex">
      <OpsSidebar />
      <Box className="flex-grow h-screen overflow-y-scroll">
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
