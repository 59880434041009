import { EmployerCDCStore, permissionStore } from '@gigin-work-space/store';
import {
  BulkUploadLinkSend,
  firstLetterReturner,
  maxTwoRole,
} from '@gigin-work-space/utils';
import { Avatar, Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { enqueueSnackbar } from 'notistack';
import { Key, useCallback, useEffect, useState } from 'react';
import { ErrorPage } from 'src/app/features/ops-platform/components/error-page';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import CircularIndeterminate from '../skelton/bgv-loader';
import EmployerBulkBothLinkAndBulk from './employer-bulk-both-link-and-bulk';
import EmployerBulkNeitherLinkNorBulk from './employer-bulk-neither-link-nor-bulk';
import EmployerBulkOnlyBulk from './employer-bulk-only-bulk';
import EmployerBulkOnlyLink from './employer-bulk-only-link';
import { dataLinkType, packageType } from './interface';
//TODO: Need to Move out of Individual Package and Import here so it can be used any where
const EBIndividualPackageHeader = ({
  title,
  listOfPackage,
}: {
  title: string;
  listOfPackage: any;
}) => {
  const [jobRoles, setJobRoles] = useState<any>([]);
  const getJobRoles = () => {
    const jobRoleCount: { [key: string]: number } = {};

    listOfPackage.candidateDetails.forEach((candidate: { jobRole: any }) => {
      const jobRole = candidate.jobRole;
      jobRoleCount[jobRole] = (jobRoleCount[jobRole] || 0) + 1;
    });

    const result = Object.entries(jobRoleCount).map(([title, total_count]) => ({
      title,
      total_count,
    }));

    EmployerCDCStore.currentJobRoles = result;
    setJobRoles(result);
  };

  useEffect(() => {
    getJobRoles();
  }, []);

  return (
    <Stack direction={'column'} rowGap={0.5}>
      <Typography className="bk-headline2">{title}</Typography>
      {jobRoles.length ? (
        <Stack direction={'row'} alignItems={'center'}>
          <Typography className="bK-body2 text-bk_text_secondary">
            {maxTwoRole(jobRoles)}.
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

const EBIndividualPackageComponent = () => {
  const [loading, setLoading] = useState(true);
  const permissions = useSnapshot(permissionStore);
  // const [dataCollectionIDS, setDataCollectionIDS] = useState<any>([]);
  const [packages, setPackages] = useState<packageType[]>([] as packageType[]);
  // const [dataCollectionLink, setDataCollectionLink] = useState<dataLinkType>();
  const [, setDataCollectionLink] = useState<dataLinkType>();
  const snapshot = useSnapshot(EmployerCDCStore);

  // const relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime);

  const getOrderPackageList = useCallback(async (orderId: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_LIST_OF_PACKAGES}/${orderId}`
      );

      if (response.data.success) {
        EmployerCDCStore.listOfPackage = response?.data?.data?.listOfPackage;

        setPackages(response?.data?.data?.listOfPackage);
        setDataCollectionLink(response?.data?.data?.dataCollectionLink);
      }
    } catch (error) {
      enqueueSnackbar('Failed to get packages', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getOrderPackageList(EmployerCDCStore.request_id);
  }, [getOrderPackageList, snapshot.toggle]);

  return !loading ? (
    <Stack rowGap={3} className="px-2">
      {packages.length ? (
        packages?.map((listOfPackage, index: Key | null | undefined) => (
          <Box className="bk-package-box">
            <Stack
              className="rounded-lg p-4 border-bk_text_secondary border-2"
              key={index}>
              <Stack direction={'row'} columnGap={1}>
                <Avatar className="bk-avatarStyle" variant="square">
                  {firstLetterReturner(listOfPackage?.packageDetails?.title)}
                </Avatar>
                <Stack direction={'column'} rowGap={0.5}>
                  <EBIndividualPackageHeader
                    listOfPackage={listOfPackage}
                    title={listOfPackage?.packageDetails?.title}
                  />
                  {listOfPackage?.dataCollectionSurvey?.status ===
                  BulkUploadLinkSend.SENT ? (
                    listOfPackage?.bulkUpload?.candidateDetailsUploaded ===
                    0 ? (
                      <EmployerBulkOnlyLink
                        packageInfo={listOfPackage}
                        permissions={permissions}
                      />
                    ) : (
                      <EmployerBulkBothLinkAndBulk
                        packageInfo={listOfPackage}
                        permissions={permissions}
                      />
                    )
                  ) : listOfPackage?.bulkUpload?.candidateDetailsUploaded ===
                    0 ? (
                    <EmployerBulkNeitherLinkNorBulk
                      packageInfo={listOfPackage}
                      permissions={permissions}
                    />
                  ) : (
                    <EmployerBulkOnlyBulk
                      packageInfo={listOfPackage}
                      permissions={permissions}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        ))
      ) : (
        <div className="bk-package-center-container">
          <ErrorPage />
        </div>
      )}
    </Stack>
  ) : (
    <Typography className="w-full text-center">
      <CircularIndeterminate />
    </Typography>
  );
};

export default EBIndividualPackageComponent;
