import { enqueueSnackbar } from 'notistack';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';

interface ICheckItem {
  count: number;
  icon: string;
  id: string;
}

interface ICheckContextType {
  filterDays: string;
  setFilterDays: Dispatch<SetStateAction<string>>;
  checkItems: [string, ICheckItem][];
  accountDataId: string;
  setAccountDataId: Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  checkId: string;
  handleGetChecks: () => void;
}

interface ChecksProviderProps {
  children: ReactNode;
}

export const CheckContext = createContext<ICheckContextType | undefined>(
  undefined
);
export const CheckProvider = ({ children }: ChecksProviderProps) => {
  const [filterDays, setFilterDays] = useState('30');
  const [checkItems, setCheckItems] = useState<[string, ICheckItem][]>([]);
  const [accountDataId, setAccountDataId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [checkId, setCheckId] = useState<string>('');

  const handleGetChecks = async () => {
    try {
      setIsLoading(true);
      let url = `${endpoints.CHECK_ASSIGNMENT}?accountId=${accountDataId}`;
      if (filterDays !== 'All') {
        url += `&lastDay=${filterDays}`;
      }

      const response = await axiosInstance.get(url);
      if (response?.data) {
        const entries: [string, ICheckItem][] = Object.entries(response.data);
        setCheckItems(entries);
        setIsLoading(false);
        const extractedData = response?.data;
        if (extractedData !== null) {
          setCheckId(extractedData);
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch Checks', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      setIsLoading(false);
    }
  };

  return (
    <CheckContext.Provider
      value={{
        filterDays,
        setFilterDays,
        checkItems,
        accountDataId,
        setAccountDataId,
        isLoading,
        handleGetChecks,
        checkId,
      }}>
      {children}
    </CheckContext.Provider>
  );
};

export const useCheck = () => {
  const context = useContext(CheckContext);
  if (!context) {
    throw new Error('useCheckContext must be used within a CheckProvider');
  }
  return context;
};
