import { SPACING } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';

interface SectionBreakProps {
  data: string;
}

const SectionBreak = React.memo(({ data }: SectionBreakProps) => {
  return (
    <View style={styles.sectionBreak}>
      <View style={styles.breakerDivider} />
      <Text style={[styles.typov3SubHeader3, { marginHorizontal: SPACING.sm }]}>
        {data}
      </Text>
      <View style={styles.breakerDivider} />
    </View>
  );
});

export default SectionBreak;
