import { useEffect, useState } from 'react';
import { SCREEN_SIZE } from '../enums';

export type ScreenSize = 'lg' | 'md' | 'xl';

export const useScreenSize = (): ScreenSize => {
  const [screenSize, setScreenSize] = useState<ScreenSize>('lg');

  const handleResizeFunction = () => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1200) {
        setScreenSize(SCREEN_SIZE.EXTRALARGE);
      } else if (windowWidth >= 992) {
        setScreenSize(SCREEN_SIZE.LARGE);
      } else if (windowWidth >= 768) {
        setScreenSize(SCREEN_SIZE.MEDIUM);
      } else {
        setScreenSize(SCREEN_SIZE.LARGE); // Fallback to 'lg' for smaller screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for screen size on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  };

  useEffect(() => {
    handleResizeFunction();
  }, []);

  return screenSize;
};
