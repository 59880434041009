import { IPermissionStoreProps } from '@gigin-work-space/store';
import {
  COLORS,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
} from '@gigin-work-space/utils';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import ComingSoonModal from '../skelton/coming-soon-model';
import ViewCandidateList from './employeer-bulk-view-candidate-list';
import { BulkUploadProgress } from './employer-bulk-upload-progress';

const EmployerBulkOnlyLink = (props: {
  packageInfo: any;
  permissions: IPermissionStoreProps;
}) => {
  const packageInfo = props.packageInfo;

  const sentDate = packageInfo.dataCollectionSurvey.sentDate;
  const [timeDifference, setTimeDifference] = useState<any>({ days: 1 });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFeatureInfoModalOpen, setIsFeatureInfoModalOpen] = useState(false);
  const handleViewList = () => {
    setModalOpen(true); //To enable list ofcandidate model change it true
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const successPercentage =
    (packageInfo.dataCollectionSurvey.dataCollected.completed * 100) /
    packageInfo.dataCollectionSurvey.numberOfCandidates;

  //TODO: This is temporally disabled
  // const handleNavigateEBMainContent = (pkg: {
  //   candidateDetails: { jobRole: string }[];
  //   packageDetails: { id: string; title: string };
  //   dataCollectionSurvey: { numberOfCandidates: number };
  // }) => {
  //   const uniqueJobRoles: string[] = [
  //     ...new Set(
  //       pkg.candidateDetails.map(
  //         (item: { jobRole: string }) => item.jobRole as string
  //       )
  //     ),
  //   ];

  //   const jobRoleCount: { [key: string]: number } = {};

  //   packageInfo.candidateDetails.forEach((candidate: { jobRole: any }) => {
  //     const jobRole = candidate.jobRole;
  //     jobRoleCount[jobRole] = (jobRoleCount[jobRole] || 0) + 1;
  //   });

  //   const result = Object.entries(jobRoleCount).map(([title, total_count]) => ({
  //     title,
  //     total_count,
  //   }));

  //   EmployerCDCStore.activeStep = 1;
  //   EmployerCDCStore.request_role_id = pkg.packageDetails.id;
  //   EmployerCDCStore.totalCount = pkg.dataCollectionSurvey.numberOfCandidates;
  //   EmployerCDCStore.currentJobRoles = result;
  //   EmployerCDCStore.currentRole = uniqueJobRoles;
  //   EmployerCDCStore.currentPackage = pkg.packageDetails.title;
  // };

  useEffect(() => {
    const currentDate = moment();
    const sentDateTime = moment(sentDate);

    const duration = moment.duration(currentDate.diff(sentDateTime));

    setTimeDifference({
      days: duration.days(),
    });
  }, [sentDate]);

  const handleOpenModal = () => {
    setIsFeatureInfoModalOpen(true);
  };

  const handleCloseComingSoonModal = () => {
    setIsFeatureInfoModalOpen(false);
  };

  function openModelComingSoon() {
    handleOpenModal();
  }

  return (
    <Stack alignItems={'centre'}>
      {packageInfo.dataCollectionSurvey.dataCollected.completed === 0 ? (
        <>
          <Stack className="mt-4" direction={'column'} rowGap={0.5}>
            <Stack direction={'row'} rowGap={0.5}>
              <DisplayImage
                imageName="send-link-icon.svg"
                imageType={DisplayImageType.ICON}
                width="16px"
                height="16px"
                className="mb-[2px]"
              />
              <Typography className="bk-sub-heading2 ml-1">
                <b>
                  Data collection link sent to all (
                  {packageInfo?.dataCollectionSurvey?.numberOfCandidates})
                  candidates{' '}
                  {timeDifference.days === 0
                    ? 'today'
                    : `${timeDifference.days} day${
                        timeDifference.days === 1 ? '' : 's'
                      } ago`}
                </b>
              </Typography>
            </Stack>
            <Stack
              className="ml-[22px] relative"
              direction={'row'}
              rowGap={0.5}>
              <DisplayImage
                imageName="yellow-info-icon.svg"
                imageType={DisplayImageType.ICON}
                width="16px"
                height="16px"
              />
              <Typography
                className="bk-only-link-typography"
                onClick={handleViewList}>
                No Candidate data received yet.
                <u style={{ cursor: 'pointer' }}>View candidate list</u>
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : packageInfo.dataCollectionSurvey.dataCollected.completed ===
        packageInfo.candidateDetails.length ? (
        <>
          <Stack className="mt-4" direction={'column'} rowGap={0.5}>
            <Stack direction={'row'} rowGap={0.5}>
              <DisplayImage
                imageName="success_tick.svg"
                imageType={DisplayImageType.ICON}
                width="16px"
                height="16px"
                className="mb-[5px]"
              />
              <Typography className="ml-1 bk-headline3">
                All candidate data collected successfully
              </Typography>
            </Stack>
            <Typography className="ml-4 bK-body1">
              Link sent {timeDifference.days} days ago
            </Typography>
          </Stack>
        </>
      ) : (
        <>
          <Stack className="mt-4" direction={'column'} rowGap={0.5}>
            <Stack direction={'row'} rowGap={0.5}>
              <DisplayImage
                imageName="send-link-icon.svg"
                imageType={DisplayImageType.ICON}
                width="16px"
                height="16px"
                className="mb-[2px]"
              />
              <Typography className="ml-1 bk-sub-heading2">
                <b>
                  Data collection link sent to all (
                  {packageInfo?.dataCollectionSurvey?.numberOfCandidates})
                  candidates
                  {timeDifference.days === 0
                    ? 'today'
                    : `${timeDifference.days} day${
                        timeDifference.days === 1 ? '' : 's'
                      } ago`}
                </b>
              </Typography>
            </Stack>
            <BulkUploadProgress
              orderCompleteStatus={{
                fail_count: 0,
                fail_percentage: 0,
                success_count: 0,
                success_percentage: successPercentage,
                total_count:
                  packageInfo.dataCollectionSurvey.numberOfCandidates,
                warn_count: 0,
                warn_percentage: 0,
              }}
            />
          </Stack>
          <Stack className="mt-4" direction={'row'}>
            <Typography className="bK-body2 text-bk_text_secondary">
              <b className="bk-body1">
                {packageInfo.dataCollectionSurvey.dataCollected.completed}
              </b>{' '}
              Completed Data Collection
            </Typography>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="mr-[10px] mt-[5px] ml-[10px] bg-gray-500 w-[2px] h-4"
            />
            <Typography
              className="bK-body2 cursor-pointer text-bk_text_secondary"
              onClick={handleViewList}>
              <b className="bk-body1">
                {packageInfo.dataCollectionSurvey.dataCollected.inProgress}
              </b>
              In progress
              <DisplayImage
                imageName="eye-icon.svg"
                imageType={DisplayImageType.ICON}
                width="20px"
                height="20px"
                className="ml-2"
              />
            </Typography>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="mr-[10px] mt-[5px] ml-[10px] bg-gray-500 w-[2px] h-4"
            />
            <Typography className="bK-body2 text-bk_text_secondary bk-orange-highlighter">
              <b className="bk-body1">
                {packageInfo.dataCollectionSurvey.dataCollected.pending}{' '}
              </b>{' '}
              Yet to start
            </Typography>
          </Stack>
        </>
      )}
      {packageInfo.dataCollectionSurvey.dataCollected.completed ===
      packageInfo.candidateDetails.length ? (
        <Box className="bk-only-link-view-details-box">
          <Stack direction={'row'}>
            <Typography className="p-5 bK-body1">
              View the details and documents uploaded by the candidates here
            </Typography>
            <Button
              className="bk-only-link-view-deatils-btn"
              onClick={() => {
                openModelComingSoon();
                // handleNavigateEBMainContent(packageInfo)
              }}>
              <span className="p-3 bK-body1 bk-black-text">View details</span>
            </Button>
            <ComingSoonModal
              isOpen={isFeatureInfoModalOpen}
              onClose={handleCloseComingSoonModal}
            />
          </Stack>
        </Box>
      ) : (
        <Box className="bk-only-link-upload-data-box">
          <Stack className="bk-only-link-upload-data-stack" direction={'row'}>
            <Stack direction={'column'} className="p-[14px]">
              <Typography className="bK-body1 pb-2 w-3/4 ">
                Upload the details and documents of the candidates in bulk &
                with ease
              </Typography>
              <Stack className="flex items-start" direction={'row'}>
                <DisplayImage
                  imageName={EmployerAppAssetIconsFileNames.RED_ICON_SVG}
                  imageType={DisplayImageType.ICON}
                  width="16px"
                  height="16px"
                />
                <Typography
                  className={`bK-body2 pl-[5px] text-[${COLORS.bk_alert_failure}] w-3/4`}>
                  We are currently improving the experience of this feature.
                  Please contact our team to share candidate details
                </Typography>
              </Stack>
            </Stack>
            <Button
              className="bk-only-link-upload-data-btn"
              onClick={() => {
                openModelComingSoon();
                // handleNavigateEBMainContent(packageInfo);
              }}>
              <DisplayImage
                imageName={EmployerAppAssetIconsFileNames.UPLOAD_BULK_ICON_SVG}
                imageType={DisplayImageType.ICON}
                width="14px"
                height="14px"
              />
              <span className="bk-only-link-upload-data-text">Upload Data</span>
            </Button>
            <ComingSoonModal
              isOpen={isFeatureInfoModalOpen}
              onClose={handleCloseComingSoonModal}
            />
          </Stack>
        </Box>
      )}
      <ViewCandidateList
        open={isModalOpen}
        onClose={handleCloseModal}
        packageInfo={packageInfo}
      />
    </Stack>
  );
};

export default EmployerBulkOnlyLink;
