/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  EmployerCDCStore,
  handleModalClose,
  handleModalOpen,
  isModalOpenStore,
} from '@gigin-work-space/store';
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_ZIP,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import * as XLSX from 'xlsx';
import { DataMapping } from '../common-alert-popup';
import CircularIndeterminate from '../skelton/bgv-loader';
export interface IEBDragDropProps {
  type: string;
  description: string;
  acceptedFileTypes: string[];
  fetchDataTable: (order_id: string, package_id: string) => void;
}

interface Field {
  label: string;
  key: string;
  alternateMatches: string[];
  fieldType: {
    type: string;
  };
}

interface UIProgress {
  DCEmployerBulkUploadProgress: {
    dataStatus: string;
    documentStatus: string;
    dataMappingStatus: string;
  };
}
// const acceptedFileTypes = ['.xlsx', '.csv', '.xls'];
export const EBDragDropExcel = ({
  acceptedFileTypes,
  description,
  fetchDataTable,
}: IEBDragDropProps) => {
  // isdrag hover state

  const [, setIsDragHover] = useState(false);
  const [excelData, setExcelData] = useState<any>([]);
  const [excelImportData, setExcelImportData] = useState<boolean>(false);
  const [headerKeys, setHeaderKeys] = useState<any>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [mediaID, setMediaID] = useState<any>();
  const [headerKeysUpdated, setHeaderKeysUpdated] = useState(false);

  const { isOpen } = useSnapshot(isModalOpenStore);

  const getExcelImportData = async (order_id: string, package_id: string) => {
    try {
      const responseDataWithValidation = await axiosInstance.get(
        `${endpoints.BULK_TEMPLATE_DATA_GET}/${order_id}/${package_id}`
      );

      setExcelImportData(
        responseDataWithValidation.data.data.some(
          (data: { UIProgress: UIProgress }) =>
            data.UIProgress.DCEmployerBulkUploadProgress.dataMappingStatus !==
            'PENDING'
        )
      );
    } catch (error) {
      // Handle errors, e.g., log or set state
      enqueueSnackbar('Error fetching data:', {
        variant: VARIANT_TYPE.ERROR,
      });
    }
  };

  const handleFileUpload = (file: any) => {
    handleModalClose();
    setHeaderKeysUpdated(false);
    setHeaderKeys([]);
    setExcelData([]);
    if (file) {
      const reader = new FileReader();
      reader.onload = async event => {
        if (event.target) {
          try {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            // Assuming there's only one sheet in the Excel file
            const sheetName = workbook.SheetNames[0];

            const worksheet = workbook.Sheets[sheetName];

            // Read with raw: false for correct date formatting
            const formattedContent = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              raw: false,
              blankrows: true,
              defval: null,
            });

            // Read with raw: true for correct large number handling
            const rawContent: any = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              raw: true,
              blankrows: true,
              defval: null,
            });

            // Merge the results
            const excelContent = formattedContent.map((row: any, rowIndex) => {
              return row.map((cell: any, cellIndex: number) => {
                const rawCell = rawContent[rowIndex][cellIndex];
                // If the raw cell is a large number, use it; otherwise, use the formatted cell
                if (
                  typeof rawCell === 'number' &&
                  rawCell > 1e11 &&
                  Number.isInteger(rawCell)
                ) {
                  return rawCell.toString();
                }
                return cell;
              });
            });

            const response = await axiosInstance.get(
              `${endpoints.BULK_UPLOAD_TEMPLATE}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
            );

            const dataGroups = response.data.data.header;
            const header: any[] = [];
            dataGroups.forEach(
              (item: {
                field_product_type_group: any;
                objects: Array<{ name: string; label: string } | string>;
              }) => {
                item.objects.forEach(obj => {
                  if (typeof obj !== 'string') {
                    header.push(obj.label);
                  } else {
                    header.push(obj);
                  }
                });
              }
            );

            setHeaderKeys(header);

            // Create a new object with keys from the first object and values from the second object
            const updatedArray = excelContent.slice(1).map(row => {
              const obj: { [key: string]: any } = {};
              excelContent[1].forEach(
                (key: string | number, index: string | number) => {
                  obj[key] = row[index];
                }
              );
              return obj;
            });
            updatedArray.shift();
            setExcelData(updatedArray);

            const generateFields = header.map(label => ({
              label: label,
              key: label,
              alternateMatches: [label.toLowerCase()],
              fieldType: {
                type: 'input',
              },
            }));

            setFields(generateFields);

            handleModalOpen({
              ...isOpen,
              dataMapping: true,
            });
          } catch (error) {
            console.error('Error parsing Excel file:', error);
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  useEffect(() => {
    if (excelData.length > 0) {
      setHeaderKeysUpdated(true);
    }
  }, [excelData]);

  // useEffect(() => {
  //
  //   fetchDataTable(
  //     EmployerCDCStore.request_id,
  //     EmployerCDCStore.request_role_id
  //   );
  // }, []);

  // todo:upload progress commented code
  // const { excelUploadFiles } = useSnapshot(EmployerCDCStore);
  // const onDrop = async (acceptedFiles: any[]) => {
  //   const updatedFiles: CustomFile[] = acceptedFiles.map(file => ({
  //     file,
  //     uploadStatus: 'uploading',
  //     uploadProgress: 0,
  //   }));
  //   if (acceptedFiles?.length) {
  //     EmployerCDCStore.excelUploadFiles = [
  //       ...excelUploadFiles,
  //       ...updatedFiles,
  //     ];
  //     postFileUpload(acceptedFiles);
  //   }
  // };
  const onDrop = (acceptedFiles: any[]) => {
    if (acceptedFiles?.length) {
      // setFiles(previousFiles => [...previousFiles, ...acceptedFiles]);
      postFileUpload(acceptedFiles);
    }
    //   fileUpload(acceptedFiles[0]);
  };

  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    onDragOver: () => {
      // Add any visual feedback here, such as changing the background color
      setIsDragHover(true);
    },
    onDragLeave: () => {
      setIsDragHover(false);
    },
    // accept file type
    accept: { acceptedFileTypes },
    multiple: false,
  });

  const postFileUpload = async (acceptedFiles: any) => {
    if (acceptedFiles[0]?.size > MAX_FILE_SIZE) {
      enqueueSnackbar(`Maximum file size is 5MB only`, {
        variant: VARIANT_TYPE.WARNING,
      });
      return;
    }
    try {
      const mediaData = {
        field_id: 'company-profile',
        isEncrypted: true,
        filePathContext: 'company-settings',
        isTenantIsolated: true,
        isPrivate: false,
        shouldCompress: false,
      };
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      formData.append('media', JSON.stringify(mediaData));

      const response = await axiosInstance.post(
        `${endpoints.UPLOAD_EMPLOYER_BULK}`,
        formData
      );
      if (response?.data) {
        setMediaID(response?.data?.data?.media_id);
        handleFileUpload(acceptedFiles[0]);
      }
    } catch (e) {
      enqueueSnackbar('Error uploading file', {
        variant: VARIANT_TYPE.ERROR,
      });
    }
  };

  const postDocUploadToOrderID = async (media_id: string) => {
    const body = {
      orderId: EmployerCDCStore.request_id,
      packageId: EmployerCDCStore.request_role_id,
      mediaId: media_id,
    };
    const response = await axiosInstance.post(
      `${endpoints.UPLOAD_EMPLOYER_BULK_S3}`,
      body
    );
    if (response?.data) {
      fetchDataTable(body.orderId, body.packageId);
      EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
    }
  };
  // todo:upload progress commented code
  // const onCancel = (index: number) => {
  //   EmployerCDCStore.excelUploadFiles = EmployerCDCStore.excelUploadFiles.map(
  //     (prevFile, i) =>
  //       i === index
  //         ? {
  //             ...prevFile,
  //             uploadStatus: 'canceled',
  //             uploadProgress: 0,
  //           }
  //         : prevFile
  //   );
  // };

  const { toggle } = useSnapshot(EmployerCDCStore);
  useEffect(() => {
    handleModalClose();
  }, []);
  useEffect(() => {
    getExcelImportData(
      EmployerCDCStore.request_id,
      EmployerCDCStore.request_role_id
    );
  }, [toggle]);
  useEffect(() => {}, [toggle]);

  return (
    <>
      {!isOpen?.dataMapping && !excelImportData ? (
        <Box className="w-full h-max">
          <Stack
            className="bk-bulk-file-drag-drop-input"
            spacing={1}
            {...getRootProps()}>
            <input type="file" {...getInputProps()} className="hidden" />

            <DisplayImage
              imageName="upload-icon.svg"
              imageType="icon"
              style={{
                height: isNotExtraLargeScreen ? '13vh' : '20.153340635268346vh',
                width: '13.541666666666666vw',
              }}
            />
            <Stack direction={'column'} className="flex items-center">
              <Typography className="bk-sub-heading1">
                Drag & drop or{' '}
                <span className="bk-blue-highlighter"> Choose file</span> to
                upload
              </Typography>
              <Typography className="bk-bulk-file-drag-drop-desc">
                {description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      ) : (
        headerKeysUpdated &&
        isOpen.dataMapping && (
          <DataMapping
            postDocUploadToOrderID={postDocUploadToOrderID}
            mediaID={mediaID}
            headerKeys={headerKeys}
            excelData={excelData}
          />
        )
      )}
    </>
  );
};

export const EBDragDropDoc = ({
  type,
  acceptedFileTypes,
  description,
  fetchDataTable,
}: IEBDragDropProps) => {
  // isdrag hover state

  const [, setIsDragHover] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const onDrop = (acceptedFiles: any[]) => {
    if (acceptedFiles?.length) {
      // setFiles(previousFiles => [...previousFiles, ...acceptedFiles]);
      postFileUpload(acceptedFiles);
    }
    //   fileUpload(acceptedFiles[0]);
  };

  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    onDragOver: () => {
      // Add any visual feedback here, such as changing the background color
      setIsDragHover(true);
    },
    onDragLeave: () => {
      setIsDragHover(false);
    },
    // accept file type
    accept: { acceptedFileTypes },
    multiple: false,
  });

  const postFileUpload = async (acceptedFiles: any) => {
    setLoading(true);
    if (acceptedFiles[0]?.size > MAX_FILE_SIZE_ZIP) {
      enqueueSnackbar(`Maximum file size is 50MB only`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      setLoading(false);
      return;
    }
    try {
      const orderId = EmployerCDCStore.request_id;
      const packageId = EmployerCDCStore.request_role_id;
      const mediaData = {
        field_id: 'company-profile',
        isEncrypted: true,
        filePathContext: 'bulkupload-documents',
        isTenantIsolated: true,
        isPrivate: false,
        shouldCompress: false,
      };
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      formData.append('media', JSON.stringify(mediaData));

      try {
        const mappedDataResponse = await axiosInstance.get(
          `${
            endpoints.GET_LIST_OF_MAPPED_DATA
          }/${orderId}/${packageId}?page=${Number()}&pageSize=${Number()}&fileName=${''}&mapped=${''}&sort=${''}`
        );

        let candidatesCount = 0;
        EmployerCDCStore.currentJobRoles.map(data => {
          candidatesCount += data.total_count;
        });

        const totalCount =
          candidatesCount * EmployerCDCStore.docListNumber * 1.5;

        if (mappedDataResponse.data.data.data.length >= totalCount) {
          enqueueSnackbar(
            'You have reached the maximum number of files allowed to be uploaded.',
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
          setLoading(false);
          return;
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar('Error fetching data:', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }

      const response = await axiosInstance.post(
        `${endpoints.UPLOAD_EMPLOYER_BULK}`,
        formData
      );
      if (response.data) {
        await postDocUploadToOrderID(response?.data?.data?.media_id);
        if (type === 'doc') {
          await axiosInstance.post(
            `${endpoints.POST_BULK_UPLOAD_IMPORT}/${orderId}/${packageId}/${response?.data?.data?.media_id}`,
            formData
          );
        }
        EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar('Error uploading file', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    setLoading(false);
  };

  const postDocUploadToOrderID = async (media_id: string) => {
    const body = {
      orderId: EmployerCDCStore.request_id,
      packageId: EmployerCDCStore.request_role_id,
      mediaId: media_id,
    };
    const response = await axiosInstance.post(
      `${endpoints.UPLOAD_EMPLOYER_BULK_S3}`,
      body
    );
    setDocumentId(response.data.data._id);

    if (response?.data) {
      fetchDataTable(body.orderId, body.packageId);
    }
  };
  const { toggle } = useSnapshot(EmployerCDCStore);
  useEffect(() => {}, [toggle]);
  return (
    <Box className="w-full h-max">
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <Stack
          className="bk-bulk-file-drag-drop-input"
          spacing={1}
          {...getRootProps()}>
          <input className="hidden" type="file" {...getInputProps()} />
          <DisplayImage
            imageName="upload-icon.svg"
            imageType="icon"
            style={{
              height: isNotExtraLargeScreen ? '13vh' : '20.153340635268346vh',
              width: '13.541666666666666vw',
            }}
          />
          <Stack direction={'column'} className="flex items-center">
            <Typography className="bk-sub-heading1">
              Drag & drop or{' '}
              <span className="bk-blue-highlighter"> Choose file</span> to
              upload
            </Typography>
            <Typography className="bk-bulk-file-drag-drop-desc">
              {description}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
