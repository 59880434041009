import { IProgressStatusProps } from '@gigin-work-space/model';
import {
  COLORS,
  ORDER_FLOW_STATUS,
  PROGRESS_STATUS,
  getStatusBgColor,
  getStatusColor,
  hasCheckStatusError,
} from '@gigin-work-space/utils';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';

export const ReportGeneratedSVG: React.FC<IProgressStatusProps> = ({ message, status }) => {
  const color = getStatusColor(status);
  const bgColor = getStatusBgColor(status);
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={message ? message : ORDER_FLOW_STATUS.REPORT} placement="top" arrow>
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          {hasCheckStatusError(status) && (
            <path
              d="M15.7929 29C16.2383 29 16.4614 28.4614 16.1464 28.1464L13.3536 25.3536C13.1583 25.1583 12.8417 25.1583 12.6464 25.3536L9.85355 28.1464C9.53857 28.4614 9.76165 29 10.2071 29H15.7929Z"
              fill={color}
            />
          )}
          <circle
            cx="13"
            cy="13"
            r="12.5"
            fill={bgColor}
            stroke={status.toUpperCase() === PROGRESS_STATUS.PENDING ? COLORS.bk_tag_gray_bg : color}
          />
          <path
            d="M16.5294 13.725V11.16C16.5294 10.4039 16.5294 10.0259 16.3755 9.73711C16.2402 9.48309 16.0242 9.27657 15.7586 9.14714C15.4566 9 15.0613 9 14.2706 9H11.2588C10.4682 9 10.0728 9 9.77084 9.14714C9.5052 9.27657 9.28922 9.48309 9.15387 9.73711C9 10.0259 9 10.4039 9 11.16V15.84C9 16.5961 9 16.9741 9.15387 17.2629C9.28922 17.5169 9.5052 17.7234 9.77084 17.8529C10.0728 18 10.4682 18 11.2588 18H12.7647M13.7059 13.05H10.8824M11.8235 14.85H10.8824M14.6471 11.25H10.8824M13.9412 16.65L14.8824 17.55L17 15.525"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Tooltip>
    </Stack>
  );
};
