import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  english: { translation: require('./languages/english.json') },
  bangala: { translation: require('./languages/bangala.json') },
  hindi: { translation: require('./languages/hindi.json') },
  kannada: { translation: require('./languages/kannada.json') },
  malayalam: { translation: require('./languages/malayalam.json') },
  marathi: { translation: require('./languages/marathi.json') },
  tamil: { translation: require('./languages/tamil.json') },
  telugu: { translation: require('./languages/telugu.json') },
};

i18next
  .use(Backend)
  // load from server and increase performence
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'english', // if you're using a language detector, do not define the lng option
    fallbackLng: 'english',
    // debug: true,
    resources: resources,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    // backend: {
    //   loadPath: './languages/english.json',
    // },
    react: { useSuspense: false }, //this line
    detection: {
      // it will enable and order browser to which one need to load first
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie', 'localStorage'],
    },
  });

export default i18next;
