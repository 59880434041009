import {
  OrderCandidateStore,
  updateConsentStatusValue,
} from '@gigin-work-space/store';
import { BulkDownloadType, FilterStatus } from '@gigin-work-space/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { axiosInstance, baseUrl, endpoints } from 'src/app/utils';
import { tenantState } from 'src/app/utils/router/tenantState';
import { User } from '../../employer-upload/interface';
import { AllCasesConsentAPIResponse } from './all-cases-search-filter-component';
import DownloadModal from './downloadModel';

type DownloadOptionsProps = {
  totalCandidate: number;
  caseTableSearchValue: string;
  selectedPackages: string[];
  selectedRole: string[];
  selectedProgressStatus: string[];
  selectedInitiatedStatus: string;
  selectedReportGeneratedStatus: string;
  selectedReportStatus: string;
  consentData: AllCasesConsentAPIResponse;
};
const DownloadOptions: React.FC<DownloadOptionsProps> = ({
  totalCandidate,
  caseTableSearchValue,
  selectedPackages,
  selectedRole,
  selectedProgressStatus,
  selectedInitiatedStatus,
  selectedReportGeneratedStatus,
  selectedReportStatus,
  consentData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState<User>({} as User);
  const [processType, setProcessType] = useState<BulkDownloadType>(
    BulkDownloadType.CONSENT
  );
  const buttonRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getEndDateBasedOnFilter = (filterValue: string) => {
    if (
      filterValue === FilterStatus.CUSTOM &&
      OrderCandidateStore.range_date[1]
    ) {
      const endDateString = OrderCandidateStore.range_date[1];
      const endDate = new Date(endDateString);

      return formatDate(endDate);
    } else if (
      filterValue === FilterStatus.INITIATED_THIS_MONTH ||
      filterValue === FilterStatus.INITITATED_LAST_30_DAYS ||
      filterValue === FilterStatus.INITITATED_LAST_90_DAYS
    ) {
      return formatDate(new Date());
    }
    return '';
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getDateBasedOnFilter = (filterValue: string) => {
    const currentDate = new Date();

    switch (filterValue) {
      case FilterStatus.INITIATED_THIS_MONTH: {
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        return formatDate(firstDayOfMonth);
      }
      case FilterStatus.INITITATED_LAST_30_DAYS: {
        const last30Days = new Date();
        last30Days.setDate(currentDate.getDate() - 30);
        return formatDate(last30Days);
      }
      case FilterStatus.INITITATED_LAST_90_DAYS: {
        const last90Days = new Date();
        last90Days.setDate(currentDate.getDate() - 90);
        return formatDate(last90Days);
      }
      case FilterStatus.CUSTOM: {
        if (OrderCandidateStore.range_date[0]) {
          const startDateString = OrderCandidateStore.range_date[0];
          const startDate = new Date(startDateString);

          return formatDate(startDate);
        } else {
          return '';
        }
      }

      default: {
        return '';
      }
    }
  };

  const filterCount =
    selectedRole.length +
    selectedPackages.length +
    selectedProgressStatus.length +
    (selectedReportStatus !== FilterStatus.ALL ? 1 : 0) +
    (selectedInitiatedStatus ? 1 : 0) +
    (selectedReportGeneratedStatus ? 1 : 0);

  const handleDownload = () => {
    const accessToken = localStorage.getItem('auth_token');
    const trimmedAccessToken = accessToken ? accessToken.replace(/"/g, '') : '';
    const apiUrl = `${baseUrl}${
      endpoints.DOWNLOAD_CASE_LIST
    }?search=${caseTableSearchValue}&packages=${
      selectedPackages.length ? selectedPackages : ''
    }&roles=${selectedRole.length ? selectedRole : ''}&status=${
      selectedProgressStatus.length ? selectedProgressStatus : ''
    }&filter_by=${''}&fromDate=${getDateBasedOnFilter(
      selectedInitiatedStatus
    )}&endDate=${getEndDateBasedOnFilter(
      selectedInitiatedStatus
    )}&fromDateReport=${getDateBasedOnFilter(
      selectedReportGeneratedStatus
    )}&endDateReport=${
      selectedReportGeneratedStatus ? formatDate(new Date()) : ''
    }&reportStatus=${
      selectedReportStatus !== FilterStatus.ALL ? selectedReportStatus : ''
    }`;
    const headers = new Headers();

    headers.append('Authorization', `Bearer ${trimmedAccessToken}`);
    headers.append('x-tenant-name', tenantState.tenantName);
    headers.append(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    //TODO: NEED TO CONVERTED TO AXIS WITH PROPER CONTENT TYPE
    fetch(apiUrl, { headers })
      .then(response => {
        // Handle the response
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(data => {
        // Handle the data (blob)
        // For example, you can create a downloadable link or display the file in the browser
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'caseList.xlsx'; // Set the desired file name and extension
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the object URL
        enqueueSnackbar(`File downloaded successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(error => {
        console.error(
          'There has been a problem with your fetch operation:',
          error
        );
        enqueueSnackbar('Error fetching data:', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  };

  const handleInitiateBulkDownload = async () => {
    try {
      setIsModalOpen(false);
      const apiUrl = `${baseUrl}${
        endpoints.INITIATE_BULK_DOWNLOAD
      }/${processType}?search=${caseTableSearchValue}&packages=${selectedPackages.join(
        ','
      )}&roles=${selectedRole.join(',')}&status=${selectedProgressStatus.join(
        ','
      )}&filter_by=&fromDate=${getDateBasedOnFilter(
        selectedInitiatedStatus
      )}&endDate=${getEndDateBasedOnFilter(
        selectedInitiatedStatus
      )}&fromDateReport=${getDateBasedOnFilter(
        selectedReportGeneratedStatus
      )}&endDateReport=${
        selectedReportGeneratedStatus ? formatDate(new Date()) : ''
      }&reportStatus=${
        selectedReportStatus !== FilterStatus.ALL ? selectedReportStatus : ''
      }`;

      const response = await axiosInstance.get(apiUrl);

      if (response.data && response.data.success) {
        updateConsentStatusValue();
        enqueueSnackbar('Bulk download initiated successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        throw new Error('Failed to initiate bulk download');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to initiate bulk download', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      //setBtnDisable(false);
    }
  };

  const triggerBulkDownload = async (type: BulkDownloadType) => {
    fetchUserDetails();
    setProcessType(type);
    setIsModalOpen(true);
    setAnchorEl(null);
  };

  async function fetchUserDetails() {
    try {
      const response = await axiosInstance.get(
        `${endpoints.UPDATE_USER}/user-profile`
      );
      setUser(response.data);
    } catch (error) {
      enqueueSnackbar('Failed to get details your details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'download-options-popover' : undefined;
  return (
    <Box className="p-lg w-full">
      <DownloadModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleInitiateBulkDownload}
        processType={processType}
        totalCandidate={totalCandidate}
        consentData={consentData}
        user={user}
      />
      <Button
        ref={buttonRef}
        aria-describedby={id}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        className="downloadStatusButton">
        Download
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Paper sx={{ width: '340px' }}>
          <Grid className="downloadHover" onClick={handleDownload}>
            <Typography className="bk-sub-heading2 downloadTitle">
              Download candidate list
            </Typography>
            <Typography variant="body2">
              To download an excel with the list of {totalCandidate} candidates
              along with the status.
            </Typography>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid
            className="downloadHover"
            onClick={() => triggerBulkDownload(BulkDownloadType.REPORT)}>
            <Typography className="bk-sub-heading2 downloadTitle">
              Download BGV Report(s)
            </Typography>
            <Typography variant="body2">
              To download the full BGV report of the {totalCandidate} candidates
            </Typography>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid
            className="downloadHover"
            onClick={() => triggerBulkDownload(BulkDownloadType.CONSENT)}>
            <Typography className="bk-sub-heading2 downloadTitle">
              Download Consent form(s)
            </Typography>
            <Typography variant="body2">
              To download the consent provided by the {totalCandidate}{' '}
              candidates during data collection
            </Typography>
          </Grid>
        </Paper>
        <Typography className="footer-download" color="text.secondary">
          {totalCandidate} Candidates.{' '}
          {filterCount > 0 && (
            <span>
              {filterCount} filter{filterCount > 1 ? 's' : ''} applied
            </span>
          )}
        </Typography>
      </Popover>
    </Box>
  );
};

export default DownloadOptions;
