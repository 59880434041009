import { COLORS } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { ImageComponent } from './report-image';
import {
  reportStatusBtnMapper,
  reportStatusColorMapper,
  reportStatusLabelMapper,
} from './report-status-mapper';

interface StatusBtnProps {
  status: string;
}

const StatusBtn = ({ status }: StatusBtnProps) => {
  return (
    <View
      style={[
        styles.statusBtnContainer,
        { backgroundColor: reportStatusColorMapper(status?.toUpperCase()) },
      ]}>
      <ImageComponent
        src={reportStatusBtnMapper(status?.toUpperCase())}
        style={[styles.statusBtnIconImage]}
      />
      <Text style={[styles.typov3Body3, { color: COLORS.bk_bg_primary }]}>
        {reportStatusLabelMapper(status?.toUpperCase())}
      </Text>
    </View>
  );
};

export default React.memo(StatusBtn);
