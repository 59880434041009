import { BKStyledButton } from '@gigin-work-space/common-ui';
import { AccountDetails, UserDetails } from '@gigin-work-space/model';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { AuthenticationHOCForSignup } from '../../authentication/authentication-hoc/authentication-hoc-without-background';
import CircularIndeterminate from './skelton/bgv-loader';

export function OnBoardedNewUser() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDetails | undefined>();
  const [userCount, setUserCount] = useState<number>(0);
  const [createdDate, setCreatedDate] = useState<moment.Duration>();

  const [isLoading, setIsLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(
    null
  );

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    globalNavigateTo('/bgv', navigate);
  };

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');
    // setUserDetails(getUserDetailsFromStorage());
    if (authToken) {
      fetchAccountDetails();
      fetchUserDetails();
      const currentDate = moment();
      const sentDateTime = moment(accountDetails?.createdAt);

      const duration = moment.duration(currentDate.diff(sentDateTime));
      setCreatedDate(duration);
    } else {
      globalNavigateTo('/bgv', navigate);
    }
  }, []);

  const fetchAccountDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${endpoints.PATCH_ACCOUNT_DETAILS}/my_account`
      );
      setAccountDetails(response.data.data);
    } catch (error) {
      enqueueSnackbar('Failed to fetch company details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchUserDetails() {
    try {
      const response = await axiosInstance.get(`${endpoints.UPDATE_USER}`);
      if (response) {
        setUserCount(response.data.count);
        setUser(response.data);
      }
    } catch (error) {
      enqueueSnackbar('Failed to update your details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }

  const handleRequest = async () => {
    try {
      const users = await axiosInstance.get(
        `${endpoints.UPDATE_USER}/fetch/request`
      );

      if (users.status === HttpStatusCode.Ok) {
        enqueueSnackbar('Request sent to admin', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setOpenDialog(true);
      }
    } catch (error) {
      enqueueSnackbar('Failed to send request', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  //TODO: Accept and Join logic need to implement
  const handleAcceptAndJoin = () => {
    enqueueSnackbar('Accept and joined', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  return (
    <AuthenticationHOCForSignup>
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <AuthenticationPaper>
          <Typography className="bK-body1 text-bk_text_primary pt-2 pb-4">
            We found your company already registered with us
          </Typography>

          <Box className="bk-on-boarded p-4">
            {user?.isInviteSent ? (
              <Typography className="bK-body2 text-bk_text_primary">
                Your mail ID has already been invited by this company
              </Typography>
            ) : (
              ''
            )}
            <Grid className="bk-company-verified">
              <Grid className="bk-company-verified-item">
                <Typography className="bk-subheading-2 text-bk_text_primary">
                  {accountDetails?.account_name}
                </Typography>
                {accountDetails?.isRegistered ? (
                  <Typography className="bk-caption bk-caption-verified">
                    Company verified
                  </Typography>
                ) : (
                  <Typography className="bk-caption  bk-caption-color">
                    Company not verified
                  </Typography>
                )}
              </Grid>
              <Grid className="bk-company-verified-item">
                <Typography className="bk-caption text-bk_text_primary">
                  <strong> {userCount - 1} </strong>Member
                </Typography>
                <Typography className="bk-caption text-bk_text_primary">
                  Created{' '}
                  {createdDate
                    ? `${
                        createdDate.asDays() < 1
                          ? 'today'
                          : createdDate.asDays() + 'day(s) ago'
                      } `
                    : 'Unknown'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid className="bk-company-verified-request-join">
            {user?.isInviteSent ? (
              <BKStyledButton
                data-testid="submitBtn"
                className="btn-primary mt-8"
                size="small"
                variant="contained"
                onClick={handleAcceptAndJoin}>
                Accept & Join
              </BKStyledButton>
            ) : (
              <BKStyledButton
                data-testid="submitBtn"
                className="bg-bk_action_primary mt-8"
                size="small"
                variant="contained"
                onClick={handleRequest}>
                Request to join the team
              </BKStyledButton>
            )}
          </Grid>

          <Dialog open={openDialog} onClose={handleCloseDialog} className="p-4">
            <DialogTitle className="bk-headline2">
              {accountDetails?.account_name}
            </DialogTitle>
            <DialogContent className=" m-4 bk-caption bk-on-boarded">
              <Typography className="p-2 text-left">
                Your request has been successfully sent to the administrator.
                Please wait for the administrator to approve your request.
              </Typography>
            </DialogContent>
            <DialogActions className="mx-4">
              <BKStyledButton
                className="mt-2 rounded-lg"
                size="small"
                onClick={handleCloseDialog}
                color="primary">
                Ok
              </BKStyledButton>
            </DialogActions>
          </Dialog>
        </AuthenticationPaper>
      )}
    </AuthenticationHOCForSignup>
  );
}

export default React.memo(OnBoardedNewUser);
