import { proxy } from 'valtio';

interface TenantState {
  tenantName: string;
}

export const tenantState = proxy<TenantState>({
  tenantName: 'default', // Initialize with your default tenantName
});

export const setTenantName = (newTenantName: string) => {
  tenantState.tenantName = newTenantName;
};
