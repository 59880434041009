import {
  AUTO_HIDE_TIMEOUT,
  CollectionGroupType,
  ExportExcelCTA,
  ExportExcelFileName,
  VARIANT_TYPE,
  capitalizeString,
} from '@gigin-work-space/utils';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { LoadingButton } from 'src/app/components/loading-button/loading-button';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { axiosInstance, endpoints } from '../../utils';
import { ExportOrchestrator, ExportType } from '../../utils/data-manipulation';
import { CaseDetailTable } from './components';
import AccountAutocomplete from './components/AccountAutoComplete';
import { useAccounts } from './store/AccountContext';

export const OpsPlatformDashboard = () => {
  const [accountData, setAccountData] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [disableCaseCTA, setDisableCaseCTA] = useState<boolean>(false);
  const [disableCheckCTA, setDisableCheckCTA] = useState<boolean>(false);
  const [disableVendorCTA, setDisableVendorCTA] = useState<boolean>(false);
  const exportCaseDetailsClass = new ExportOrchestrator(ExportType.CASE);
  const exportCheckDetailsClass = new ExportOrchestrator(ExportType.CHECK);
  const exportCheckAllocationClass = new ExportOrchestrator(
    ExportType.CHECK_ALLOCATION_TO_VENDOR
  );
  const { allAccounts, getAccounts } = useAccounts();
  const exportExcelClass = new ExportOrchestrator(ExportType.CASE);
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    type: string;
    lastDay: number;
  }>();

  const { enqueueSnackbar } = useSnackbar();

  const goToCrcList = () => {
    globalNavigateTo('/ops-platform/crc-check-list', navigate);
  };

  // function to fetch Case details when an account is selected
  const getCaseDetails = async (accountId: string, tenantId: string) => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_CASE_LIST}/${accountId}/${tenantId}`
      );
      if (response.data.success) {
        setCaseDetails(response.data.data);
        return;
      }
      throw new Error('Failed to fetch case details');
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleExportCheckDetails = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get('accountId');
      const response = await axiosInstance.get(
        `${endpoints.GET_CHECK_DETAILS}/${accountId}`
      );
      if (response.status === HttpStatus.OK) {
        exportCheckDetailsClass.exportToExcel(
          response.data.data,
          ExportExcelFileName.CHECK_DETAILS
        );
        return;
      }
    } catch (error) {
      enqueueSnackbar('Failed to export check details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
      throw new Error(`Failed to fetch checks ${error}`);
    }
  };

  const handleChangeAccount = (event: any) => {
    const selectedAccount = event.target.value;

    localStorage.setItem('selectedAccount', JSON.stringify(selectedAccount));

    setAccountData(selectedAccount);
    navigate(
      `?accountId=${selectedAccount._id}&tenantId=${selectedAccount.tenantId}`
    );
  };

  useEffect(() => {
    getAccounts();

    const storedAccount = localStorage.getItem('selectedAccount');
    if (storedAccount) {
      setAccountData(JSON.parse(storedAccount));
    }
  }, [getAccounts]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accountId = queryParams.get('accountId');
    const tenantId = queryParams.get('tenantId');

    if (accountId && tenantId) {
      getCaseDetails(accountId, tenantId);
    } else if (accountData) {
      navigate(
        `?accountId=${accountData._id}&tenantId=${accountData.tenantId}`
      );
    }
  }, [location.search, allAccounts, accountData, refresh]);

  const packageCreation = () => {
    globalNavigateTo('/ops-platform/create-package', navigate);
  };

  const exportAllCasesDetails = async () => {
    try {
      setDisableCaseCTA(true);
      const response = await axiosInstance.get(
        `${endpoints.GET_CASE_LIST}/cases`
      );
      if (response.data.success) {
        exportCaseDetailsClass.exportToExcel(
          response.data.data,
          ExportExcelFileName.ALL_CASE_DETAILS
        );
        setDisableCaseCTA(false);
        return;
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const exportAllCheckDetails = async () => {
    try {
      setDisableCheckCTA(true);
      const response = await axiosInstance.get(
        `${endpoints.GET_CASE_LIST}/checks`
      );
      if (response.data.success) {
        exportCheckDetailsClass.exportToExcel(
          response.data.data.data,
          ExportExcelFileName.ALL_CHECK_DETAILS
        );
        setDisableCheckCTA(false);
        return;
      }
      throw new Error('Failed to fetch case details');
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const options = [
    {
      label: 'Address - Last 2 days',
      type: CollectionGroupType.address_detail,
      lastDay: 2,
    },
    {
      label: 'Address - Last 7 days',
      type: CollectionGroupType.address_detail,
      lastDay: 7,
    },
    {
      label: 'Education - Last 2 days',
      type: CollectionGroupType.education_detail,
      lastDay: 2,
    },
    {
      label: 'Education - Last 7 days',
      type: CollectionGroupType.education_detail,
      lastDay: 7,
    },
  ];

  const handleExport = async (event: SelectChangeEvent<string>) => {
    const params = options.find(option => option.label === event.target.value);

    try {
      setDisableVendorCTA(true);
      const response = await axiosInstance.get(
        `${endpoints.CHECK_ALLOCATION}/?lastDay=${params?.lastDay}&collectionGroupType=${params?.type}`
      );
      if (response.data.success) {
        exportCheckAllocationClass.exportToExcel(
          response.data.data,
          ExportExcelFileName.CHECK_ALLOCATION
        );
        setDisableVendorCTA(false);
      }
    } catch (error) {
      setDisableVendorCTA(false);
      enqueueSnackbar('Failed to fetch case details', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleCallback = () => {
    setRefresh(!refresh);
  };

  return (
    <Stack>
      {/* Top header section START*/}
      <Box className="sticky top-0  z-10 bg-bk_bg_primary">
        <Grid className="flex justify-between items-center p-2">
          <Typography className="bk-headline1">Ops Platform</Typography>
          <Stack direction="row" className="flex justify-between">
            <Button
              variant="outlined"
              color="secondary"
              onClick={packageCreation}
              className="bk-ops-action-btn mr-2 ml-2">
              Create Package
            </Button>
            <LoadingButton
              isLoading={disableCaseCTA}
              variant="outlined"
              color="secondary"
              className="bk-ops-action-btn mr-2 ml-2"
              onClick={exportAllCasesDetails}>
              Export All Case Data
            </LoadingButton>
            <LoadingButton
              isLoading={disableCheckCTA}
              variant="outlined"
              color="secondary"
              className="bk-ops-action-btn mr-2 ml-2"
              onClick={exportAllCheckDetails}>
              Export All Check Data
            </LoadingButton>
            <Select
              className="bk-ops-action-btn mr-2 ml-2"
              disabled={disableVendorCTA}
              displayEmpty
              value={
                options.find(option => option.label === selectedOption?.label)
                  ?.label || ''
              }
              onChange={event => {
                setSelectedOption(
                  options.find(option => option.label === event.target.value)
                );
                handleExport(event);
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'secondary.main',
                },
                '& .MuiSelect-select': {
                  color: 'secondary.main',
                },
              }}>
              <MenuItem disabled value="">
                Select Vendor Allocation
              </MenuItem>
              {options.map(item => (
                <MenuItem value={item.label} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="outlined"
              color="secondary"
              onClick={goToCrcList}
              className="bk-ops-action-btn">
              Crc List
            </Button>
          </Stack>
        </Grid>
        <Divider />
        {/* Top header section END*/}
      </Box>
      {/* Middle Section START */}

      <Box className="flex justify-between items-center p-5">
        <FormControl>
          <AccountAutocomplete
            allAccounts={allAccounts}
            accountData={accountData}
            handleChangeAccount={handleChangeAccount}
            inputLabel={'Select Account/ Company'}
            showAllAccountsOption={false}
          />
        </FormControl>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              exportExcelClass.exportToExcel(
                caseDetails,
                ExportExcelFileName.CASE_DETAILS
              );
            }}
            className="bk-ops-action-btn w-fit">
            {ExportExcelCTA.CASE_DETAILS}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleExportCheckDetails}
            className="bk-ops-action-btn bg-black">
            {ExportExcelCTA.CHECK_DETAILS}
          </Button>
        </Stack>
      </Box>

      <Box className="p-4 w-full">
        {/* Account select DD */}

        {/* Account Specific Case Details List view */}
        <Stack spacing={3}>
          {!caseDetails.length && (
            <Stack marginTop={3} alignItems={'center'}>
              {!accountData ? (
                <Typography className="bk-sub-heading3">
                  Please Select an account/company first.
                </Typography>
              ) : (
                <Typography className="bk-sub-heading3">
                  No Details found for{' '}
                  {capitalizeString(accountData?.account_name)}.
                </Typography>
              )}
            </Stack>
          )}
          {caseDetails.length !== 0 && (
            <CaseDetailTable
              caseDetails={caseDetails}
              onCaseDetailClick={handleCallback}
            />
          )}
        </Stack>
      </Box>
      {/* Middle Section START */}
    </Stack>
  );
};
