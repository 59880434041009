import { COMPANY_SETTINGS_LABELS, Feature, PermissionAction } from './enums';
export const baseCloudfrontUrl = process.env['NX_REACT_REPORT_IMAGE_CLOUDFRONT_URL'];

export const DEFAULT_USER_NAME = 'default';

export const PRODUCTION_HOSTNAME = 'empverifyin.gigin.ai';

export const PROFILE_PIC_PLACEHOLDER =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

export const ReportStatusImageLinks = {
  CLEAR: `https://cdn-assets.ai.gigin.ai/bgv-sample-data/clear.png`,
  DISCREPANT: `https://cdn-assets.ai.gigin.ai/bgv-sample-data/discrepant.png`,
  INSUFFICIENT: `https://cdn-assets.ai.gigin.ai/bgv_static_images/insufficient.png`,
  NO_RESPONSE: `https://cdn-assets.ai.gigin.ai/bgv-sample-data/noresponse.png`,
};

export const BGV_OPS_EMAIl = 'bgv-ops@gigin.ai';
export const SUPPORT_MOBILE_NUMBER = '+91 7259412221';
//TODO: Remove after 2-3 days of monitoring
export const OPS_ID = '65e036a8ca8d21f88de5a291';

export const ROWS_TO_BE_SHOWN = '20';
export const ONE_MB = 1 * 1024 * 1024;
export const MAX_SCREEN_BREAKPOINT = 425;
export const MAX_FILE_SIZE = 5 * 1024 * 1024;
export const MAX_FILE_SIZE_ZIP = 50 * 1024 * 1024;
export const IMAGE_AND_FILE_ACCEPTED = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
};

export const URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;

export const FILE_TYPE = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  OCTATE: 'application/octet-stream',
  IMAGE_STARTSWITH: 'image/',
  VIDEO_STARTSWITH: 'video/',
  IMAGE: 'image',
  AUDIO_STARTSWITH: 'audio/',
  IMAGE_PDF: ['image/jpeg', 'image/png', 'application/pdf'],
  CSV: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  IMAGE_PDF_ZIP: ['image/jpeg', 'image/png', 'application/pdf', 'application/zip', 'application/x-zip-compressed'],
};

export const ONLY_ZIP_FILE_ACCEPTED = {
  'application/zip': [],
};

export const MimeTypes = {
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.gif': 'image/gif',
  '.zip': 'application/zip',
  '.x-zip-compressed': 'application/x-zip-compressed',
  '.pdf': 'application/pdf',
  '.csv': 'text/csv',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // Add more if needed
};
export const ONLY_IMAGE_ACCEPTED = {
  'image/jpeg': [],
  'image/png': [],
};

export const WHITELIST_URLS = [
  'https://cdn-assets.ai.gigin.ai',
  'https://cdn.pixabay.com',
  'https://cdn0.iconfinder.com',
  'https://dev123.gigin.ai',
  'https://dxpu5xhc45xfg.cloudfront.net', // Did this for logo visibility in candidate-data-collection
];
export const DOCUMENT_UPLOAD = 'document_upload';
export const IMAGE_UPLOAD = 'image_upload';
export const PROFILE_PIC = 'profile_pic';
export const DATE_DD_MM_YYYY = ['year', 'month', 'day'];
export const DATE_MM_YYYY = ['year', 'month'];
export const DATE_YYYY = ['year'];
export const ORG_DETAILS = [
  { id: 'companyProfile', label: COMPANY_SETTINGS_LABELS.COMPANY_PROFILE },
  { id: 'teams', label: COMPANY_SETTINGS_LABELS.USER_TEAMS },
  { id: 'roleManage', label: COMPANY_SETTINGS_LABELS.ROLE_MANAGEMENT },
];
export const VERIFICATION_DETAILS = [{ id: 'package', label: COMPANY_SETTINGS_LABELS.PACKAGES }];
export const AADHAAR_NUMBER = 'aadhaar_number';
export const PASSPORT_NUMBER = 'passport_number';
export const PASSPORT_FILE_NUMBER = 'passport_file_no';
export const BANK_IFSC = 'bank_ifsc';
export const DRIVING_LICENSE_NUMBER = 'driving_licence_number';
export const PANCARD_NUMBER = 'pancard_number';
export const CURRENTLY_WORKING_HERE = 'currently_working';
export const SINGLE_SELECT = 'single_select';
export const FILE = 'file';
export enum PARAMETER_DETAIL_FIELD_DATA_TYPE {
  FILE = 'file',
  TEXT = 'text',
  URL = 'url',
}

export const DOCUMENT_COUNT_MULTIPLIER = 1.5;

export const MENU_WITH_HEADERS = 'MENU_WITH_HEADER';
export const OPS_PLATFORM_MENU = 'OPS_PLATFORM_MENU';
export const EMPLOYMENT_END_DATE = [
  'employment_end_date_1',
  'employment_end_date_2',
  'employment_end_date_3',
  'employment_end_date_4',
  'employment_end_date_5',
  'employment_end_date_6',
  'employment_end_date_with_uan',
  'employment_end_date_with_uan_2',
];
// PERMISSION ARRAYS START
/* ORDER MANAGEMENT */
export const CREATE_ORDER_PERMISSIONS = [{ feature: Feature.ORDER, action: [PermissionAction.CREATE] }];

/* TEAM MANAGEMENT */
export const CREATE_TEAM_PERMISSIONS = [{ feature: Feature.TEAM, action: [PermissionAction.CREATE] }];
export const UPDATE_TEAM_PERMISSIONS = [{ feature: Feature.TEAM, action: [PermissionAction.UPDATE] }];
export const MANAGE_TEAM_MEMBERS_PERMISSIONS = [
  {
    feature: Feature.TEAM,
    action: [PermissionAction.CREATE, PermissionAction.READ, PermissionAction.UPDATE, PermissionAction.DELETE],
  },
];
export const DELETE_TEAM_PERMISSIONS = [{ feature: Feature.TEAM, action: [PermissionAction.DELETE] }];
export const VIEW_TEAM_LIST_PERMISSIONS = [{ feature: Feature.TEAM, action: [PermissionAction.READ] }];
export const VIEW_TEAM_DETAILS_PERMISSIONS = [{ feature: Feature.TEAM, action: [PermissionAction.READ] }];

/* PACKAGE MANAGEMENT */
export const VIEW_PACKAGE_LIST_PERMISSIONS = [{ feature: Feature.PACKAGE, action: [PermissionAction.READ] }];

/* COMPANY/ACCOUNT MANAGEMENT */
export const ADD_UPDATE_COMPANY_LOGO_PERMISSIONS = [
  { feature: Feature.ACCOUNT, action: [PermissionAction.CREATE, PermissionAction.UPDATE] },
];
export const VIEW_COMPANY_PROFILE_PERMISSIONS = [{ feature: Feature.ACCOUNT, action: [PermissionAction.READ] }];
export const VIEW_COMPANY_AGGREEMENT_PERMISSIONS = [{ feature: Feature.ACCOUNT, action: [PermissionAction.READ] }];

export const TypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const TypeZip = 'application/zip';

export enum DataType {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
}

export enum DataCollectionItemStatus {
  INITIATED = 'Initiated',
  COMPLETED = 'Completed',
  PENDING = 'Pending',
  INVALID = 'Invalid',
  VALID = 'Valid',
}

// Converting the required date format enum.
export enum DataRules {
  DOB_DATE_MONTH_YEAR = 'dob_date_month_year',
  CALENDAR_MONTH_YEAR = 'calender_month_year',
  CALENDAR_DATE_MONTH_YEAR = 'calendar_date_month_year',
  CALENDAR_YEAR = 'calender_year',
}

// Define date formats for year only
export const YearOnlyFormats = ['YYYY', 'YY'];

// Define date formats for month and year
export const MonthYearFormats = [
  'MM/YYYY',
  'MM/YY',
  'MMM-YY',
  'MMM-YYYY',
  'MMM/YYYY',
  'MMM YY',
  'MMM YYYY',
  'M/YYYY',
  'M/YY',
  'MM/YY',
  'MM/YYYY',
  'M/YYYY',
  'M, YYYY',
  'M, YY',
  'MM, YY',
  'MM, YYYY',
  'M, YYYY',
  'MMM, YY',
  'MMM, YYYY',
  'M,YYYY',
  'M,YY',
  'MM,YY',
  'MM,YYYY',
  'M,YYYY',
  'MMM,YY',
  'MMM,YYYY',
];

// Define date formats for date, month, and year
export const DateMonthYearFormats = [
  'DD/MM/YYYY',
  'DD/MM/YY',
  'DD-MMM-YY',
  'DD-MMM-YYYY',
  'DD/MMM/YYYY',
  'DD MMM YY',
  'DD MMM YYYY',
  'D/M/YYYY',
  'D/M/YY',
  'D/MM/YY',
  'D/MM/YYYY',
  'DD/M/YY',
  'DD/M/YYYY',
  'ddd, DD MMM YYYY HH:mm:ss [GMT]',
];

export const EMAIL_DOMAIN = [
  'mailinator.com',
  'spambooger.com',
  'bobmail.info',
  'guerrillamail.com',
  'sharklasers.com',
  'grr.la',
  'guerrillamail.biz',
  'guerrillamail.net',
  '10minutemail.com',
  '10minutemail.net',
  'throwawaymail.com',
  'temp-mail.org',
  'tempmail.de',
  'tempmail.eu',
  'yopmail.com',
  'yopmail.fr',
  'yopmail.net',
  'fakemailgenerator.com',
  'fakeinbox.com',
  'maildrop.cc',
  'mintemail.com',
  'getnada.com',
  'nada.email',
  'dispostable.com',
  'mailnesia.com',
  'trashmail.com',
  'trashmail.net',
  'moakt.com',
  'mytemp.email',
  'mailcatch.com',
  'tempinbox.com',
  'smailpro.com',
  'owlymail.com',
  'emailondeck.com',
  'gmail.com',
  'google.com',
  'outlook.com',
  'yahoo.com',
  'icloud.com',
  'aol.com',
  'hotmail.com',
  'protonmail.com',
  'zoho.com',
  'mail.com',
  'gmail.in',
  'google.in',
  'outlook.in',
  'yahoo.in',
  'hotmail.in',
  'outlook.ai',
  'rediff.com',
];
export const CollectionGroupType = {
  address_detail: 'address_detail',
  education_detail: 'education_detail',
};

export const ParameterLevelLabel = {
  profile_pic: 'Profile',
  aadhaar_front_image: 'Aadhaar Front Image',
  aadhaar_back_image: 'Aadhaar Back Image',
  pancard_front_image: 'Pancard Front Image',
  voter_card_front_image: 'Voter ID Front Image',
  voter_card_back_image: 'Voter ID Back Image',
  driving_licence_front_image: 'DL Front Image',
  driving_licence_back_image: 'DL Back Image',
  current_document: 'Address Proof',
  permanent_document: 'Supporting Document',
  employment_document_1: 'Offer Letter',
  employe_payslip_1: 'Payslip',
  employment_document_2: 'Offer Letter',
  employe_payslip_2: 'Payslip',
  passport_front_image: 'Passport Front Image',
  passport_back_image: 'Passport Back Image',
  upload_bank_passbook: 'Bank Passbook',
  employment_expirience_2: 'Experience Letter',
  graduation_marksheet: 'Marks sheet',
  primary_education_marksheet: 'Marks sheet',
  secondary_education_marksheet: 'Marks sheet',
  employment_expirence_1: 'Experience Letter',
  master_marksheet: 'Marks sheet',
  master_verifying_authority: 'Verifying Authority',
  employment_experience_4: 'Experience Letter',
  employee_payslip_4: 'Payslip',
  employment_document_4: 'Offer Letter',
  employment_experience_3: 'Experience Letter',
  employee_payslip_3: 'Payslip',
  employment_document_3: 'Offer Letter',
  employment_document_with_uan: 'Offer Letter',
  employe_payslip_with_uan: 'Payslip',
  employment_expirence_with_uan: 'Experience Letter',
  employment_document_with_uan_2: 'Offer Letter',
  employe_payslip_with_uan_2: 'Payslip',
  employment_expirence_with_uan_2: 'Experience Letter',
  employment_proof_1: 'Employment Proof',
  employment_proof_2: 'Employment Proof',
  employment_proof_3: 'Employment Proof',
  employment_proof_4: 'Employment Proof',
  employment_payslip_5: 'Payslip',
  employment_payslip_6: 'Payslip',
  employment_proof_5: 'Employment Proof',
  employment_proof_6: 'Employment Proof',
};

export enum DateTypeLabel {
  DateOfBirth = 'Date of Birth',
  PassingYear = 'Year of Passing',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  IssuseDate = 'Date of Issue',
  ValidityDate = 'Validity Date',
}

export enum dataCollectionParameterNames {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  MIDDLE_NAME = 'middle_name',
}

export enum InputLabel {
  CANDIDATE_NAME = 'Candidate Name',
  PHONE_NUMBER = 'Phone Number',
  EMAIL = 'Email',
  INITIATED_ON = 'Initiated On',
}

export const ROWS_PER_PAGE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

export const ExtendedTimeOutURLs = [
  '/gateway/order-service-request/opps/cases',
  '/gateway/order-service-request/opps/checks',
  '/gateway/document-storage/upload',
  '/gateway/bulk-upload/import/documents',
  '/gateway/data-collection/vendor-check-allocation-data',
  '/gateway/checkout/summary',
  '/gateway/checkout/confirm',
  '/gateway/initiated-item/import',
];

// Function to check if req.url matches any of the extended timeout URLs
export const isExtendedTimeoutURL = (url: string, urls: string[]) => {
  return urls.some((baseURL) => url.includes(baseURL));
};

export const maskMobileNumber = (mobile: string) => {
  const visibleDigits = 4;
  const maskedPart = mobile.substring(0, mobile.length - visibleDigits).replace(/./g, '*');
  const visiblePart = mobile.substring(mobile.length - visibleDigits);
  return maskedPart + visiblePart;
};

export const maskEmail = (email: string) => {
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = localPart.substring(0, 5).replace(/./g, '*') + localPart.substring(5);
  return maskedLocalPart + '@' + domain;
};

export const formatDateByMonth = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day} ${month} ${hours}:${minutes}`;
};

export enum ProductTypeGroup {
  BASIC_DETAILS = 'basic_detail',
}

export enum FieldProductTypeGroupLabel {
  ORDER_DETAILS = 'Order Details',
}

export enum DataCollectionFieldLabel {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  MIDDLE_NAME = 'middle_name',
  CURRENT_ADDRESS_1 = 'current_address_1',
  CURRENT_CITY = 'current_city',
  CURRENT_STATE = 'current_state',
  CURRENT_COUNTRY = 'current_country',
  PROFILE_PIC = 'profile_pic',
}
export const formatDateByYear = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export enum LocalStorageData {
  USER_ACCOUNT_DETAIL = 'userAccountDetail',
}

export interface FieldValidationRule {
  rule: string;
  isOptional: boolean;
}

export const NO_REPORT_DATA_TITLE = 'No Report Data';
export const NO_REPORT_DATA_DESCRIPTION = 'The report data is not available. Please try again from the dashboard.';
export const FAIL_TO_GENERATE_PDF_TITLE = 'Fail to generate PDF';
export const FAIL_TO_GENERATE_PDF_DESCRIPTION = 'Failed to generate the report. Please try again from the dashboard.';
export const GENERATE_REPORT_LOADER_TITLE = 'Generating verification report';
export const GENERATE_CONSOLIDATED_REPORT_LOADER_TITLE = 'Generating consolidated verification report';

export const CHECK_WITH_SUBTITLE = ['education_detail', 'address_detail', 'employment_detail', 'crc_check'];

export const PRODUCT_FRUITS_LANGUAGE = 'en';

export const ROWS_PER_PAGE_ARRAY = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const COUNTDOWN_TIMER_FIVE = 5;

export const MAX_FILE_RECRUITER_BUL_UPLOAD = 20;
