import { BKStyledButton, BKStyledTableCell } from '@gigin-work-space/common-ui';
import {
  AUTO_HIDE_TIMEOUT,
  capitalizeString,
  COLORS,
  DisplayImageType,
  FilterStatus,
  formatDateByMonth,
  REPORT_STATUS,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { enqueueSnackbar } from 'notistack';
import {
  ChangeEvent,
  ChangeEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DisplayImage, TablePaginationActions } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import verifyIcon from '../../../../assets/image/verifyin-green-icon.svg';
import verifyInGrey from '../../../../assets/image/verifyin-grey-icon.svg';
import verifyIconRed from '../../../../assets/image/verifyin-red-icon.svg';
import CircularIndeterminate from '../../bgv/component/skelton/bgv-loader';
import { useCheck } from '../store/ChecksContext';
import ChecksFIlterCTA from './ChecksFIlterCTA';
import DetailCheckHeader from './DetailCheckHeader';

interface Candidate {
  name: string;
  extCheckId: string;
}

interface Contact {
  id: string;
  mobile: string;
  email: string;
}

interface CheckRecord {
  id: string;
  caseId: string;
  candidate: Candidate;
  contact: Contact;
  status: string;
  internalOwner: string;
  externalOwner: string | null;
  checkAssigned: string;
  checkInitiated: string;
}

export interface IUser {
  _id: string;
  name: string;
}

const DetailCheckInfo = () => {
  const { filterDays, accountDataId } = useCheck();
  const location = useLocation();
  const item = location?.state;
  const id = item?.state?.id;
  const navigate = useNavigate();

  const [owners, setOwners] = useState<any[]>([]);
  const [checkInfo, setCheckInfo] = useState<CheckRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterByDays] = useState(filterDays);
  const [accountId] = useState(accountDataId);
  const [checkId] = useState(id);
  const [filteredCheckInfo, setFilteredCheckInfo] = useState<CheckRecord[]>([]);
  const [showUnassigned, setShowUnassigned] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [orderStatus, setOrderStatus] = useState<FilterStatus>(
    FilterStatus.ALL
  );
  const [filteredUser, setFilteredUser] = useState<string>('All Users');
  const [selectedOwner, setSelectedOwner] = useState<string>('');
  const [hasUser, setHasUser] = useState<Record<string, IUser>>({});
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCases, setSelectedCases] = useState<string[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const getAllCheckDetails = async () => {
    try {
      setIsLoading(true);

      const lastDay = filterByDays === 'All' ? '' : filterByDays;

      const response = await axiosInstance.get(
        `${endpoints.OPS_DETAIL_CHECK_DATA}?accountId=${accountId}&checkId=${checkId}&lastDay=${lastDay}&page=${page}&limit=${rowsPerPage}`
      );

      if (response.status === HttpStatus.OK) {
        setCheckInfo(response.data?.data?.results);
        setFilteredCheckInfo(response.data?.data?.results);
        setTotalCount(response.data?.data?.totalCount);
        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar('Failed fetching owner', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  useEffect(() => {
    const getAllOwners = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`${endpoints.OPS_LIST_USERS}`);
        if (response?.status === HttpStatus.OK) {
          setOwners(response?.data?.data);
          const usersMap = response?.data?.data?.reduce(
            (map: Record<string, IUser>, user: IUser) => {
              map[user._id] = user;
              return map;
            },
            {} as Record<string, IUser>
          );
          setHasUser(usersMap);
          setIsLoading(false);
        }
      } catch (error) {
        enqueueSnackbar('Failed fetching owner', {
          variant: VARIANT_TYPE.ERROR,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });

        setIsLoading(false);
      }
    };

    getAllOwners();
  }, []);

  const handlePatchOwner = async (data: any) => {
    try {
      const response = await axiosInstance.patch(
        `${endpoints.OPS_FLOW_CHECK}`,
        data
      );
      if (response.status === HttpStatus.OK) {
        enqueueSnackbar('Owner selected successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
        await getAllCheckDetails();
      } else {
        enqueueSnackbar('Something went wrong', {
          variant: VARIANT_TYPE.WARNING,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to select owner', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleChangeOwner = async (
    event: SelectChangeEvent<string>,
    checkDetailId: string
  ) => {
    const ownerId = event.target.value;

    setSelectedOwner(ownerId);
    setHoveredRow(null);

    if (ownerId) {
      const payload = [
        {
          id: checkDetailId,
          data: {
            checkAssignment: [
              {
                assignedTo: ownerId,
                comment: '',
              },
            ],
          },
        },
      ];
      try {
        await handlePatchOwner(payload);

        setSelectedOwner('');
      } catch (error) {
        enqueueSnackbar('Failed to select owner', {
          variant: VARIANT_TYPE.ERROR,
          autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
        });
      }
    }
  };

  useEffect(() => {
    getAllCheckDetails();
  }, [page, rowsPerPage, filterByDays]);

  const handleViewReport = (event: SyntheticEvent, caseId: string) => {
    globalNavigateTo(`/ops-platform/report/${caseId}`, navigate);
  };

  const handleUnassign = () => {
    const newShowUnassigned = !showUnassigned;

    const filteredChecks = checkInfo?.filter(record => {
      const isMyCheck = hasUser[record?.internalOwner]?.name === userName;
      const isUnassigned =
        !record?.internalOwner || record?.internalOwner === 'Unassigned';
      return (isClicked && isMyCheck) || (newShowUnassigned && isUnassigned);
    });

    // If both are false, show all data

    if (!isClicked && !newShowUnassigned) {
      setFilteredCheckInfo(checkInfo);
    } else {
      setFilteredCheckInfo(filteredChecks);
    }

    // Toggling the view mode
    setShowUnassigned(!showUnassigned);
  };

  useEffect(() => {
    const storedUserName = localStorage.getItem('userAccountDetail');
    if (storedUserName) {
      const parsedData = JSON.parse(storedUserName);
      setUserName(parsedData?.name);
    }
  }, []);

  const handleShowMyChecks = () => {
    const newIsClicked = !isClicked;

    const filteredChecks = checkInfo?.filter(record => {
      const isMyCheck = hasUser[record?.internalOwner]?.name === userName;
      const isUnassigned =
        !record?.internalOwner || record?.internalOwner === 'Unassigned';
      return (newIsClicked && isMyCheck) || (showUnassigned && isUnassigned);
    });

    if (!newIsClicked && !showUnassigned) {
      setFilteredCheckInfo(checkInfo);
    } else {
      setFilteredCheckInfo(filteredChecks);
    }
    setIsClicked(newIsClicked);
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    caseId: string
  ) => {
    setSelectedCases(prevSelectedCases =>
      prevSelectedCases.includes(caseId)
        ? prevSelectedCases.filter(id => id !== caseId)
        : [...prevSelectedCases, caseId]
    );
  };

  const handleAssignBulkOwner = async (event: any) => {
    const ownerId = event.target.value._id;

    const payload = selectedCases.map(caseId => ({
      id: caseId,
      data: {
        checkAssignment: [
          {
            assignedTo: ownerId,
            comment: '',
          },
        ],
      },
    }));

    try {
      setFilteredCheckInfo(prevCheckInfo =>
        prevCheckInfo.map(info =>
          selectedCases.includes(info?.id)
            ? { ...info, internalOwner: ownerId }
            : info
        )
      );

      await handlePatchOwner(payload);
      setSelectedCases([]);

      setFilteredCheckInfo(prevCheckInfo =>
        prevCheckInfo.map(info =>
          selectedCases.includes(info?.id)
            ? { ...info, internalOwner: ownerId }
            : info
        )
      );

      setSelectedCases([]);
    } catch (error) {
      enqueueSnackbar('Failed to assign cases', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleSetOrder = (status: FilterStatus) => {
    setOrderStatus(status);
  };

  useEffect(() => {
    filterOrderData();
  }, [orderStatus, checkInfo]);

  const filterOrderData = () => {
    if (orderStatus === FilterStatus.ALL) {
      setFilteredCheckInfo(checkInfo);
      return;
    }
    const filteredData = checkInfo?.filter(
      record => record?.status === orderStatus
    );
    setFilteredCheckInfo(filteredData);
  };

  const handleFilterByOwner = (ownerName: string) => {
    setFilteredUser(ownerName);
    const filteredUser =
      ownerName === 'All Users'
        ? checkInfo
        : checkInfo.filter(
            record => hasUser[record?.internalOwner]?.name === ownerName
          );
    setFilteredCheckInfo(filteredUser);
  };

  const renderVerifyIcon = (reportStatus: string) => {
    switch (reportStatus) {
      case REPORT_STATUS.CLEAR:
        return (
          <Stack direction="row" className="gap-1.5">
            <Box>
              <DisplayImage
                imageName={verifyIcon}
                imageType={DisplayImageType.URL}
                className="mt-0.5"
                height="12px"
                width="12px"
              />
            </Box>
            <Typography className="bK-body2">Clear</Typography>
          </Stack>
        );
      case REPORT_STATUS.DISCREPANT:
        return (
          <Stack direction="row" className="gap-1.5">
            <Box>
              <DisplayImage
                imageName={verifyIconRed}
                className="mt-0.5"
                imageType={DisplayImageType.URL}
                height="12px"
                width="12px"
              />
            </Box>
            <Typography className="bK-body2">Discrepant</Typography>
          </Stack>
        );
      case REPORT_STATUS.INPROGRESS:
        return (
          <Stack direction="row" className="gap-1.5">
            <Box>
              <DisplayImage
                imageName={verifyInGrey}
                className="mt-0.5"
                imageType={DisplayImageType.URL}
                height="12px"
                width="12px"
              />
            </Box>
            <Typography className="bK-body2">In Progress</Typography>
          </Stack>
        );
      default:
        return (
          <Typography className="bK-body2">
            {capitalizeString(reportStatus)}
          </Typography>
        );
    }
  };

  const handleChangePage: TablePaginationProps['onPageChange'] = (
    event,
    newPage
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (
      filteredCheckInfo.length > 0 &&
      selectedCases.length === filteredCheckInfo.length
    ) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedCases, filteredCheckInfo]);

  const handleHeaderCheckboxChange = (event: any) => {
    const checked = event.target.checked;
    setIsAllChecked(checked);
    if (checked) {
      const allCaseIds = filteredCheckInfo.map(info => info?.id);
      setSelectedCases(allCaseIds);
    } else {
      setSelectedCases([]);
    }
  };

  const handleRedirectCheck = (caseId: string) => {
    globalNavigateTo(`${endpoints.INDIVIDUAL_CASE}/${caseId}`, navigate);
  };

  const isScreenLarger = useMediaQuery('(min-width: 768px');

  const tableCells = [
    { id: 0, cellName: 'Contact', align: 'left' },
    { id: 1, cellName: 'Status', align: 'left' },
    { id: 2, cellName: 'Internal Owner', align: 'center' },
    { id: 3, cellName: 'External Owner', align: 'center' },
    { id: 4, cellName: 'Check Assigned', align: 'center' },
    { id: 5, cellName: 'Check Initiated', align: 'center' },
    { id: 6, cellName: 'Report', align: 'center' },
  ];

  return (
    <>
      <Stack
        direction={isScreenLarger ? 'row' : 'column'}
        justifyContent={'flex-end'}
        spacing={2}
        className="p-2 m-4">
        <ChecksFIlterCTA
          isScreenLarger={isScreenLarger}
          selectedCases={selectedCases}
          filteredUser={filteredUser}
          owners={owners}
          isClicked={isClicked}
          showUnassigned={showUnassigned}
          orderStatus={orderStatus}
          handleAssignBulkOwner={handleAssignBulkOwner}
          handleUnassign={handleUnassign}
          handleShowMyChecks={handleShowMyChecks}
          handleSetOrder={handleSetOrder}
          handleFilterByOwner={handleFilterByOwner}
        />
      </Stack>
      <TableContainer
        component={Paper}
        className="bk-ops-table-container w-[95vw] left-2 max-h-[600px] my-3">
        <Table
          stickyHeader
          aria-label="sticky table"
          padding="none"
          size="small"
          sx={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '& .MuiTableCell-root': {
              paddingX: '8px !important',
            },
          }}>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              boxShadow: '3px -4px 10px 1px gray',
              backgroundColor: COLORS.bk_bg_primary_smoke,
              '& .MuiTableCell-head': {
                padding: '8px !important',
              },
            }}>
            <TableRow>
              <TableCell className="flex items-center p-1 border-none">
                <Checkbox
                  checked={isAllChecked}
                  onChange={handleHeaderCheckboxChange}
                />
                Check ID
              </TableCell>

              {tableCells?.map(cell => {
                return <DetailCheckHeader cell={cell} />;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <CircularIndeterminate />
            ) : filteredCheckInfo?.length > 0 ? (
              filteredCheckInfo?.map(info => (
                <TableRow
                  key={info?.id}
                  className={`${isScreenLarger && 'overflow-x-scroll'} p-3`}>
                  <TableCell
                    component="th"
                    scope="info"
                    className="p-3 flex items-center">
                    <Checkbox
                      checked={selectedCases.includes(info?.id)}
                      onChange={event => handleCheckboxChange(event, info?.id)}
                    />
                    <Box
                      onClick={() => handleRedirectCheck(info?.caseId)}
                      className="bk-check-name">
                      <Typography variant="body2" className="text-[15px]">
                        {info?.candidate?.name}
                      </Typography>
                      <Typography className="bk-sub-heading3">
                        {info?.candidate?.extCheckId}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className="p-3">
                    <Typography className="bk-sub-heading3">
                      {info?.contact?.mobile}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {info?.contact?.email}
                    </Typography>
                  </TableCell>
                  <BKStyledTableCell className="p-3" size="small">
                    {renderVerifyIcon(info?.status)}
                  </BKStyledTableCell>

                  <TableCell
                    align="center"
                    className={` ${
                      info?.internalOwner &&
                      info?.internalOwner !== 'Unassigned'
                        ? 'inherit'
                        : 'text-[red]'
                    } p-3`}
                    onMouseEnter={() => setHoveredRow(info?.id)}
                    onMouseLeave={() => setHoveredRow(null)}>
                    {hoveredRow === info?.id ||
                    selectedOwner === info?.internalOwner ? (
                      <FormControl fullWidth size="small">
                        <InputLabel id="owner-select-label" className="text-sm">
                          User
                        </InputLabel>
                        <Select
                          labelId="owner-select-label"
                          id="owner-select"
                          value={selectedOwner || info?.internalOwner || ''}
                          onChange={(event: SelectChangeEvent<string>) =>
                            handleChangeOwner(event, info?.id)
                          }
                          label="Users">
                          <MenuItem value="">Unassigned</MenuItem>
                          {owners.map((owner: IUser) => (
                            <MenuItem key={owner?._id} value={owner?._id}>
                              {hasUser[owner?._id]?.name || owner?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="subtitle2">
                        {hasUser[info?.internalOwner || '']?.name ||
                          'Unassigned'}
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell align="center" className="p-3">
                    {info?.externalOwner}
                  </TableCell>
                  <TableCell className="text-center">
                    {info?.checkAssigned
                      ? formatDateByMonth(info?.checkAssigned)
                      : 'N/A'}
                  </TableCell>
                  <TableCell className="p-3 text-center">
                    {formatDateByMonth(info?.checkInitiated)}
                  </TableCell>
                  <TableCell align="center" className="p-3">
                    <BKStyledButton
                      variant="text"
                      className={`border border-${COLORS.bk_tag_blue} bk-sub-heading3`}
                      onClick={event => handleViewReport(event, info?.caseId)}>
                      View
                    </BKStyledButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography className="text-center p-4 w-screen">
                No data available
              </Typography>
            )}
            <TableRow>
              <TablePagination
                colSpan={8}
                component="div"
                count={totalCount}
                page={page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className="bk-all-cases-table-pagination-grid fixed"
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DetailCheckInfo;
