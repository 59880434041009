import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { ICandidateRole } from '@gigin-work-space/model';
import { COLORS, borderLessSelect } from '@gigin-work-space/utils';
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { Distribution } from './IAnalytics';
export function VerificationTrend() {
  const [selectRole, setSelectRole] = useState('all');
  const [vidAllData, setVidAllData] = useState<Distribution[]>(
    [] as Distribution[]
  );
  const [roles, setRoles] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectRoleChange = (value: any) => {
    setSelectRole(value);
  };

  // function for array of object data need to feed for chart
  const returnChartData = (name: string[], data: number[]) => {
    let arr = [];
    arr = name.map((item, index) => ({
      name: item,
      data: data[index],
    }));

    return arr;
  };

  const data = returnChartData(
    vidAllData ? vidAllData?.map(data => data.label) : [],
    vidAllData?.map(data => data.value)
  );

  //draw chart
  const chartDraw = (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        {/* gradient color code */}
        <defs>
          <linearGradient id="colorVSD" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <Area
          dataKey="data"
          connectNulls
          // stackId="1"
          stroke="#048218"
          fill="url(#colorVSD)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  const fetchAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.VID_ALL}/role/${
          selectRole ? selectRole : 'all'
        }?duration=6&type=month`
      );

      if (response.data) {
        setVidAllData(response?.data?.data ?? []);
      }
    } catch (e) {
      enqueueSnackbar(`Fetching Verification Intend Trend is Failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  // fetching roles from API
  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_ROLE}`);
      if (response.data) {
        const returnedRole = response.data.data.map(
          (role: ICandidateRole) => role.role_title
        );
        setRoles(returnedRole);
      } else {
        setRoles([]);
      }
    } catch (e) {
      enqueueSnackbar(`Error while fetching roles`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  useEffect(() => {
    fetchAll();
  }, [selectRole]);
  // Fetching role on load page
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <Paper elevation={0} sx={{ background: COLORS.bk_bg_primary }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 1.5,
          borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
        }}>
        <Typography className="bk-heading1">
          Verification initiation trend
        </Typography>
        <FormControl
          variant="standard"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '2px',
          }}>
          <Select
            id="selectRole"
            name="selectRole"
            value={selectRole}
            onChange={(event: any) => {
              handleSelectRoleChange(event.target.value);
            }}
            label="recent"
            className="bK-btn-small"
            sx={borderLessSelect}>
            <MenuItem value={'all'}>All roles</MenuItem>
            {roles.map(role => (
              <MenuItem value={role}>{role}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 2,
        }}>
        {vidAllData.length ? (
          chartDraw
        ) : (
          <ChartEmptyState
            // maxHeight={'100vh'}
            imageComponent={
              <DisplayImage
                imageName="quality-empty-state.svg"
                imageType="icon"
                style={{
                  height: '250px',
                  width: 'auto',
                }}
              />
            }
            description="Identity patterns in verification initiation across roles"
          />
        )}
      </Stack>
    </Paper>
  );
}
