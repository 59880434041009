import { permissionStore } from '@gigin-work-space/store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Feature, PermissionAction, ROLE_PERMISSION_CATEGORY_KEY } from '../enums';
import { hasPermission, verifyToken } from '../utils';

export const useRoutePermission = () => {
  const location = useLocation();
  const updatePermissions = () => {
    const permissions = [
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
        feature: Feature.ORDER,
        action: PermissionAction.READ,
        permissionKey: 'canReadOrder',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
        feature: Feature.ORDER,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreateOrder',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
        feature: Feature.ORDER,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateOrder',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.ORDER_MANAGEMENT,
        feature: Feature.ORDER,
        action: PermissionAction.DELETE,
        permissionKey: 'canDeleteOrder',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.REPORTS_ANALYTICS,
        feature: Feature.ANALYTIC,
        action: PermissionAction.READ,
        permissionKey: 'canReadAnalytics',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
        feature: Feature.ACCOUNT,
        action: PermissionAction.READ,
        permissionKey: 'canReadCompanyProfile',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
        feature: Feature.ACCOUNT,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreateCompanyProfile',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
        feature: Feature.ACCOUNT,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateCompanyProfile',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.COMPANY_PROFILE,
        feature: Feature.ACCOUNT,
        action: PermissionAction.AGREEMENT,
        permissionKey: 'canReadAgreement',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.OPS_MANAGEMENT,
        feature: Feature.OPS,
        action: PermissionAction.READ,
        permissionKey: 'canReadOps',
      },

      {
        key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
        feature: Feature.TEAM,
        action: PermissionAction.READ,
        permissionKey: 'canReadTeams',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
        feature: Feature.TEAM,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateTeams',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.TEAM_MANAGEMENT,
        feature: Feature.TEAM,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreateTeams',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
        feature: Feature.USER,
        action: PermissionAction.READ,
        permissionKey: 'canReadUsers',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
        feature: Feature.USER,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateUsers',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
        feature: Feature.USER_ROLE,
        action: PermissionAction.READ,
        permissionKey: 'canReadRoles',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
        feature: Feature.USER_ROLE,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreateRoles',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.USER_MANAGEMENT,
        feature: Feature.USER_ROLE,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateRoles',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
        feature: Feature.PACKAGE,
        action: PermissionAction.READ,
        permissionKey: 'canReadPackage',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
        feature: Feature.PACKAGE,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreatePackage',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PACKAGE_MANAGEMENT,
        feature: Feature.PACKAGE,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdatePackage',
      },

      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
        feature: Feature.PREFERENCE,
        action: PermissionAction.READ,
        permissionKey: 'canReadPreference',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
        feature: Feature.PREFERENCE,
        action: PermissionAction.CREATE,
        permissionKey: 'canCreatePreference',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.PREFERENCE_MANAGEMENT,
        feature: Feature.PREFERENCE,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdatePreference',
      },

      {
        key: ROLE_PERMISSION_CATEGORY_KEY.REPORTS_ANALYTICS,
        feature: Feature.BGV_REPORT,
        action: PermissionAction.READ,
        permissionKey: 'canReadReport',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DATA_MAPPING,
        action: PermissionAction.READ,
        permissionKey: 'canReadTemplate',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DATA_MAPPING,
        action: PermissionAction.CREATE,
        permissionKey: 'canUploadTemplate',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DATA_MAPPING,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateTemplate',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DATA_MAPPING,
        action: PermissionAction.DELETE,
        permissionKey: 'canDeleteTemplate',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DOCUMENT_MAPPING,
        action: PermissionAction.CREATE,
        permissionKey: 'canUploadDocument',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DOCUMENT_MAPPING,
        action: PermissionAction.UPDATE,
        permissionKey: 'canUpdateDocument',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DOCUMENT_MAPPING,
        action: PermissionAction.READ,
        permissionKey: 'canReadDocument',
      },
      {
        key: ROLE_PERMISSION_CATEGORY_KEY.BULK_UPLOAD,
        feature: Feature.DOCUMENT_MAPPING,
        action: PermissionAction.DELETE,
        permissionKey: 'canDeleteDocument',
      },
    ];

    permissions.forEach(({ key, feature, action, permissionKey }) => {
      permissionStore[permissionKey] = hasPermission(key, feature, action);
    });

    permissionStore['hasToken'] = verifyToken();
  };

  useEffect(() => {
    updatePermissions();
  }, [location]);
};
