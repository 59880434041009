import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export function SkeltonOrderList() {
  return (
    <Stack spacing={2}>
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={'3rem'}
        style={{ borderRadius: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={'3rem'}
        style={{ borderRadius: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={'3rem'}
        style={{ borderRadius: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={'3rem'}
        style={{ borderRadius: '8px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={'3rem'}
        style={{ borderRadius: '8px' }}
      />
    </Stack>
  );
}
