export const COLORS = {
  bk_text_primary: '#201D22',
  bk_text_secondary: '#55505A',
  bk_text_secondary2: '#9A9EA1',
  bk_text_dark: '#161616',
  bk_bg_light: '#F8F8F8',
  bk_bg_primary: '#FFFFFF',
  bk_bg_primary_dark: '#4D19BE',
  bk_bg_primary_smoke: '#FAFBFD',
  bk_bg_secondary: '#FAF9FC',
  bk_bg_dark: '#C8CBCE',
  bk_stroke_primary: '#DCD9DD',
  bk_stroke_secondary: '#EBEBEB',
  bk_stroke_divider: '#C8CBCE',
  bk_action_primary: '#6F35F2',
  bk_action_pressed: '#660CD9',
  bk_action_disabled: '#BEBBC2',
  bk_alert_success: '#048218',
  bk_alert_failure: '#D94415',
  bk_alert_new: '#E6971F',
  bk_alert_warning: '#EE6E12',
  bk_tag_yellow: '#CCAF16',
  bk_tag_yellow_bg: '#FEFCF2',
  bk_tag_orange: '#B98113',
  bk_tag_orange_bg: '#FEFBF5',
  bk_tag_blue: '#0053F3',
  bk_tag_blue_light: '#167FCC',
  bk_tag_blue_bg: '#F3F8FC',
  bk_tag_green_bg: '#E8FCF2',
  bk_tag_action_bg: '#F1ECFD',
  bk_tag_gray_bg: '#F1F1F1',
  bk_table_header_text: 'rgba(0, 0, 0, 0.4);',
  bk_semi_black: 'rgba(0, 0, 0, 0.5);',
  bk_semi_light_black: 'rgba(0,0,0, 0.05);',
  bk_table_bg_header: '#FBFCFC',
  bk_table_bg_input: '#FFEDE8',
  bk_secondary_active: '#0053F3',
  bk_secondary_disabled: '#55505A',
  bk_tag_purple: '#A716CC',
  bk_tag_green: '#7CCC16',
  bk_light_gray: '#FBFBFB',
  bk_charcoal_gray: '#302E32',
  bk_info: '#616063',
  bk_info_main: '#0288D1',
  bk_transparent: 'transparent',
  bk_blue_100: '#DAECFF',
  bk_blue_200: '#80BFFF',
  bk_blue_400: '#3399FF',
  bk_blue_500: '#007FFF',
  bk_blue_600: '#0072E5',
  bk_blue_700: '#0059B2',
  bk_grey_50: '#F3F6F9',
  bk_grey_90: '#e5e5e5',
  bk_grey_100: '#E5EAF2',
  bk_grey_200: '#DAE2ED',
  bk_grey_300: '#C7D0DD',
  bk_grey_400: '#B0B8C4',
  bk_grey_500: '#9DA8B7',
  // bk_grey_600: '#6B7A90',
  bk_grey_700: '#434D5B',
  bk_grey_800: '#303740',
  bk_grey_900: '#1C2025',
  bk_icon_color: '#6200ea',
  bk_bg_tertiary: '#F9F7FF',
  bk_grey_600: '#757575',
  bk_pagination: '#6F2CFF',
  bk_checks_border: '#A083DD',
  bk_checks_bg: '#F9F7FF',
  bk_grey_750: '#757575',
  bku_grey_600: '#757575',
  bku_grey_700: '#616161',
  bk_selection_secondary: '#008DF3',
  bku_stepper_bg: '#6f2cff14',
  bk_red_50: '#FEEBEE',
  bk_deep_purple_50: '#EDE7F6',
  bk_report_text_secondary: '#9A9EA1',
  bk_report_text_primary: '#212121',
  bk_report_success_main: '#2E7D32',
  bk_report_error_main: '#D32F2F',
  bk_report_amber_800: '#FF8F00',
  bk_report_grey_a400: '#616161',
  bk_report_stroke_secondary_100: '#E1E6EF',
  bk_report_grey_100: '#F8F9FC',
  bk_report_selection_secondary_main: '#008DF3',
  bk_primary_main: '#6F2CFF',
  bk_primary_light: '#A083DD',
  bk_bg_primary_states_hover: 'rgba(111, 44, 255, 0.04)',
  bk_error_main: '#D32F2F',
  bk_bg_divider: '#F5F5F5',
  bk_all_cases_pagination: '#6F2CFF',
  bk_warning_main: '#ED6C02',
  bk_error_states_hover: 'rgba(211, 47, 47, 0.04)',
  bk_secondary_main: 'rgba(0, 141, 243, 1)',
  bk_bg_primary_states_selected: 'rgba(111, 44, 255, 0.08)',
  bk_green_400: "#66BB6A",
};

export const SPACING = {
  0: '0px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
  null: '0px', // NULL
  xs3: '2px', // XS3
  xs2: '4px', // XS2
  xs: '6px', // XS
  sm: '8px', // SM
  sm2: '10px', // SM
  md: '12px', // MD
  lg: '16px', // LG
  lg2: '18px', // LG2
  xl: '20px', // XL
  xl2: '24px', // XL2
  xl3: '32px', // XL3
  xl4: '36px', // XL4
  xxl: '40px', // XL5
  xxl2: '48px', // XL6
  xxl3: '64px', // XL7
};

export const RADIUS = {
  null: '0px', // NULL
  xs3: '2px', // XS3
  xs2: '4px', // XS2
  xs: '6px', // XS
  sm: '8px', // SM
  sm2: '10px', // SM
  md: '12px', // MD
  lg: '16px', // LG
  lg2: '18px', // LG2
  xl: '20px', // XL
  xl2: '24px', // XL2
  xl3: '32px', // XL3
  xl4: '36px', // XL4
  xxl: '40px', // XL5
  xxl2: '48px', // XL6
  xxl3: '64px', // XL7
};

// export const FONTSIZE = {
//   xs: ['0.75rem', { lineHeight: '1rem' }],
//   sm: ['0.875rem', { lineHeight: '1.25rem' }],
//   base: ['1rem', { lineHeight: '1.5rem' }],
//   lg: ['1.125rem', { lineHeight: '1.75rem' }],
//   xl: ['1.25rem', { lineHeight: '1.75rem' }],
//   '2xl': ['1.5rem', { lineHeight: '2rem' }],
//   '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
//   '4xl': ['2.25rem', { lineHeight: '2.5rem' }],
//   '5xl': ['3rem', { lineHeight: '1' }],
//   '6xl': ['3.75rem', { lineHeight: '1' }],
//   '7xl': ['4.5rem', { lineHeight: '1' }],
//   '8xl': ['6rem', { lineHeight: '1' }],
//   '9xl': ['8rem', { lineHeight: '1' }],
// };
export const FONTWEIGHT = {
  thin: '100',
  extralight: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extrabold: '800',
  black: '900',
};

export const LETTERSPACING = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};
export const LINEHEIGHT = {
  none: '1',
  tight: '1.25',
  snug: '1.375',
  normal: '1.5',
  relaxed: '1.625',
  loose: '2',
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
};

export const OPACITY = {
  0: '0',
  5: '0.05',
  10: '0.1',
  20: '0.2',
  25: '0.25',
  30: '0.3',
  40: '0.4',
  50: '0.5',
  60: '0.6',
  70: '0.7',
  75: '0.75',
  80: '0.8',
  90: '0.9',
  95: '0.95',
  100: '1',
};

export const borderLessSelect = {
  '&:before': {
    borderBottom: 'none',
  },
  '&:after': {
    borderBottom: 'none',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
};
