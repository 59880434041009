import {
  FILE_TYPE,
  MimeTypes,
  WHITELIST_URLS,
  fetchFileWithAuth,
} from '@gigin-work-space/utils';
import React, { useEffect, useState } from 'react';
import DisplayImage from '../display-image/display-image';

interface FileDisplayComponentProps {
  fileUrl: string;
  fileClassName?: string;
  fileType?: string;
  fileStyle?: React.CSSProperties;
}

const getFileTypeFromUrl = (url: string) => {
  const extensionToMimeType: { [key: string]: string } = MimeTypes;

  const extension = url.substring(url.lastIndexOf('.'));
  return extensionToMimeType[extension] || 'application/octet-stream';
};

const isUrlWhitelisted = (url: string) => {
  return WHITELIST_URLS.some(whitelistedUrl => url?.startsWith(whitelistedUrl));
};

const FileDisplayComponent: React.FC<FileDisplayComponentProps> = ({
  fileUrl,
  fileClassName,
  fileStyle = {},
  fileType = null,
}) => {
  const [fileObjectURL, setFileObjectURL] = useState<string | null>(null);
  const [currentFileType, setCurrentFileType] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndSetFile = async () => {
      try {
        const fileBlob = await fetchFileWithAuth(fileUrl);
        const objectURL = URL.createObjectURL(fileBlob);
        setFileObjectURL(objectURL);

        // Set file type either from url or from provided value.
        if (!fileType) {
          setCurrentFileType(fileBlob.type);
        } else {
          setCurrentFileType(fileType);
        }
      } catch (error) {
        setError('Failed to fetch file');
      }
    };

    if (isUrlWhitelisted(fileUrl)) {
      setFileObjectURL(fileUrl);
      setCurrentFileType(fileType || getFileTypeFromUrl(fileUrl));
    } else {
      fetchAndSetFile();
    }
    // Cleanup object URL when component unmounts
    return () => {
      if (fileObjectURL) {
        URL.revokeObjectURL(fileObjectURL);
      }
    };
  }, [fileUrl]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!fileObjectURL) {
    return <p>Loading...</p>;
  }

  // Determine the file type and render accordingly
  if (
    currentFileType?.startsWith(FILE_TYPE.IMAGE_STARTSWITH) ||
    currentFileType === FILE_TYPE.IMAGE
  ) {
    return (
      <DisplayImage
        imageName={fileObjectURL}
        imageType={'url'}
        className={fileClassName}
        style={fileStyle}
      />
    );
  } else if (currentFileType === FILE_TYPE.PDF) {
    return (
      <iframe
        src={fileObjectURL}
        width="100%"
        height="100%"
        title="bulk upload pdf"
      />
    );
  } else if (currentFileType?.startsWith(FILE_TYPE.VIDEO_STARTSWITH)) {
    return (
      <video width="100%" height="100%" controls>
        <source src={fileObjectURL} />
        {'Your browser does not support the video tag.'}
      </video>
    );
  } else if (currentFileType?.startsWith(FILE_TYPE.AUDIO_STARTSWITH)) {
    return (
      <audio controls>
        <source src={fileObjectURL} />
        {'Your browser does not support the audio element.'}
      </audio>
    );
  } else {
    return <p>Unsupported file type</p>;
  }
};

export default FileDisplayComponent;
