import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { ImageComponent } from './report-image';
import { reportStatusIconMapper } from './report-status-mapper';

export interface IndividualCheckTableProps {
  subCheckData: any;
}

export const IndividualCheckTable = (props: IndividualCheckTableProps) => {
  const { subCheckData } = props;

  // function to apply subtitle style based on status
  const handleSubtitleStyle = (status: string) => {
    switch (status) {
      case 'CLEAR':
        return [styles.textBody2, styles.clear];
      case 'DISCREPANT':
        return [styles.textBody2, styles.discrepant];
      case 'INSUFFICIENT':
        return [styles.textBody2, styles.insufficient];
      case 'NO_RESPONSE':
        return [styles.textBody2, styles.noResponse];
      case 'UNABLE_TO_VERIFY':
        return [styles.textBody2, styles.unableToVerify];
      default:
        return styles.textBody2;
    }
  };

  const shouldShowTable = subCheckData.details.some(
    (item: { fieldVisibility: any }) => item.fieldVisibility
  );

  // If none of the items have fieldVisibility set to true, return null
  if (!shouldShowTable) return null;

  return (
    <View style={[styles.table]}>
      {/* Header Row */}
      <View
        style={[
          styles.tableRow,
          { borderTopLeftRadius: 4, borderTop: '1px solid #DCD9DD' },
        ]}
        fixed>
        <Text style={[styles.tableHeader, styles.textSubHeading3]}>
          Parameters
        </Text>
        <Text style={[styles.tableHeader, styles.textSubHeading3, { flex: 2 }]}>
          Candidate Details
        </Text>
        <Text style={[styles.tableHeader, styles.textSubHeading3]}>
          Verification Result
        </Text>
      </View>

      {/* Data Rows */}
      {subCheckData.details.map(
        (row: any, id: number) =>
          // Check if the field should be visible
          row.fieldVisibility && (
            <View style={[styles.tableRow]} key={id} wrap={false}>
              {/* First Column */}
              <Text
                style={
                  id % 2 === 0
                    ? [
                        styles.tableCell,
                        styles.textSubHeading3,
                        { backgroundColor: '#F1F1F1' },
                      ]
                    : [styles.tableCell, styles.textSubHeading3]
                }>
                {row.parameters}
              </Text>
              {/* Second Column */}
              <View style={[styles.tableCell, { flex: 2 }]}>
                <Text style={[styles.textBody1]}>{row.detail.value}</Text>
                {row?.detail?.message.map(
                  (tableRowData: any) =>
                    tableRowData.comment &&
                    tableRowData.comment.trim().length > 0 && ( // Check if data.comment has content
                      <View
                        style={[styles.iconTextContainer, { marginTop: 14 }]}>
                        <ImageComponent
                          src={reportStatusIconMapper(tableRowData.icon)}
                          style={styles.iconImageSmall}
                        />

                        <Text style={[styles.textBody3]}>
                          {tableRowData.comment}
                        </Text>
                      </View>
                    )
                )}
              </View>
              {/* Third Column */}
              <View
                style={[
                  styles.tableCell,
                  styles.iconTextContainer,
                  { alignItems: 'flex-start' },
                ]}>
                <ImageComponent
                  src={reportStatusIconMapper(row.detail.field_status)}
                  style={styles.iconImageSmall}
                />

                <Text style={handleSubtitleStyle(row.detail.field_status)}>
                  {row.detail.field_status === 'INSUFFICIENT'
                    ? 'ATTENTION \n REQUIRED'
                    : row.detail.field_status.replace(/_/g, ' ')}
                </Text>
              </View>
            </View>
          )
      )}
    </View>
  );
};
