import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { axiosInstance, endpoints } from '../../utils';
import AccountAutocomplete from './components/AccountAutoComplete';
import CreatePackage from './ops-plateform-create-package';

export const OpsPlatformPackageCreation = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [accountData, setAccountData] = useState<any>(null);
  const [isAccountSelected, setIsAccountSelected] = useState(false);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to get all accounts for account DD
  const getAccounts = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_ALL_ACCOUNTS);
      if (response.data.success) {
        setAllAccounts(response.data.data);
        return;
      }

      throw new Error('Failed to fetch accounts');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to fetch accounts', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  const goToOpsDashboard = () => {
    globalNavigateTo('/ops-platform', navigate);
  };

  const handleChangeAccount = (event: any) => {
    const confirmed = window.confirm(
      'Are you sure you want to change the account?'
    );

    if (confirmed) {
      const selectedAccount = event.target.value;
      setAccountData(selectedAccount);
      setIsAccountSelected(true);
      navigate(
        `?code=${selectedAccount?.company_code?.toLowerCase()}&name=${selectedAccount?.account_name?.toLowerCase()}`
      );
    }
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {}, [location.search]);

  return (
    <Box>
      {/* Top header section */}
      <Grid className="sticky top-0 z-10 bg-bk_bg_primary">
        <Stack
          direction="row"
          className="flex justify-between items-center p-4">
          <Typography className="bk-headline1">
            OPS Platform Create Package
          </Typography>
          <Box className="w-full max-w-[312px]">
            <FormControl fullWidth>
              <AccountAutocomplete
                allAccounts={allAccounts}
                accountData={accountData}
                handleChangeAccount={handleChangeAccount}
                inputLabel={'Select Account Company'}
              />
            </FormControl>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={goToOpsDashboard}
            className="float-right h-12">
            Ops Dashboard
          </Button>
        </Stack>
        <Divider />
        {isAccountSelected && <CreatePackage account={accountData} />}
      </Grid>
    </Box>
  );
};
