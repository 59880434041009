import { ProgressLineSVG } from '@gigin-work-space/common-ui';
import {
  CheckoutStore,
  EmployerCDCStore,
  IStepperProps,
} from '@gigin-work-space/store';
import {
  BULK_UPLOAD_STATUS,
  COLORS,
  findActiveStep,
} from '@gigin-work-space/utils';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
interface IEBIndividualStepper {
  index: number;
  step: IStepperProps;
}
const EBIndividualStepper = ({ step, index }: IEBIndividualStepper) => {
  const { toggle } = useSnapshot(EmployerCDCStore);
  useEffect(() => {}, [toggle]);
  return (
    <Stack
      direction={'column'}
      className="rounded-lg  pt-3  overflow-hidden cursor-pointer"
      rowGap={0.5}
      sx={{
        background: findActiveStep(index) ? COLORS.bk_bg_primary : '#F8F8F8',
        minWidth: '20%',
        border: findActiveStep(index)
          ? `1px solid ${COLORS.bk_stroke_primary}`
          : '',
        boxShadow: findActiveStep(index)
          ? `0px 4px 4px rgba(0, 0, 0, 0.25)`
          : '',
      }}
      onClick={() => {
        EmployerCDCStore.activeStep = index;
      }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        className="px-3">
        <Typography className="bk-sub-heading1">Step {step.step}</Typography>
        {findActiveStep(index) ? (
          <></>
        ) : step.status === BULK_UPLOAD_STATUS.COMPLETED ? (
          <DisplayImage
            imageName="success_tick.svg"
            imageType="icon"
            width="16px"
            height="16px"
          />
        ) : (
          <Typography
            className="bK-body2 text-bk_bg_primary px-1 bg-bk_tag_yellow rounded-xl flex justify-center"
            sx={{ minWidth: '100px', py: '2px' }}>
            {step.status}
          </Typography>
        )}
      </Stack>
      <Typography className="bK-body1 px-3">{step.description}</Typography>
      <Stack
        className="w-full h-1"
        sx={{
          background:
            findActiveStep(index) &&
            !(step.status === BULK_UPLOAD_STATUS.COMPLETED)
              ? COLORS.bk_action_primary
              : step.status === BULK_UPLOAD_STATUS.COMPLETED
              ? COLORS.bk_alert_success
              : COLORS.bk_transparent,
        }}></Stack>
    </Stack>
  );
};

const EBStepperComponent = () => {
  const [steppers, setSteppers] = useState<IStepperProps[]>([]);

  useEffect(() => {
    const orderId = EmployerCDCStore.request_id;
    const packageId = EmployerCDCStore.request_role_id;
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${endpoints.BULK_UPLOAD_STEP_STATUS}/${orderId}/${packageId}`
        );
        const { step_1, step_2, step_3 } = response.data.data;

        CheckoutStore.step_3 = step_3 === BULK_UPLOAD_STATUS.COMPLETED;

        const initialSteppers: IStepperProps[] = [
          {
            step: 1,
            description: 'Upload Candidate details Excel',
            status: step_1,
          },
          {
            step: 2,
            description: 'Upload documents of candidates',
            status: step_2,
          },
          {
            step: 3,
            description: 'Document mapping',
            status: step_3,
          },
        ];

        EmployerCDCStore.stepperStatus = [
          {
            step: 1,
            progress: step_1,
          },
          {
            step: 2,
            progress: step_2,
          },
          {
            step: 3,
            progress: step_3,
          },
        ];

        EmployerCDCStore.dataMapped = response.data.data.dataMapped;

        // Set the initial array in the state
        setSteppers(initialSteppers);
      } catch (e) {
        console.error('Error fetching response.');
      }
    };

    fetchData();
  }, [EmployerCDCStore.toggle]);

  return (
    <Stack direction={'row'} className="items-center" rowGap={0.5}>
      {steppers.map((step: IStepperProps, index: number) => (
        <>
          <EBIndividualStepper step={step} index={index + 1} />
          {steppers.length - 1 !== index ? (
            <ProgressLineSVG color={COLORS.bk_bg_dark} />
          ) : (
            <></>
          )}
        </>
      ))}
    </Stack>
  );
};

export default EBStepperComponent;
