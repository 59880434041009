import { IconButton } from '@mui/material';
import { DisplayImage } from 'src/app/components';

const EBDeleteRecord = () => {
  return (
    <IconButton
      style={{ marginLeft: '0.3rem' }}
      className="cursor-pointer"
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={() => () => {}}>
      <DisplayImage
        imageName={'delete-icon.svg'}
        imageType="icon"
        width="14px"
        height="16px"
      />
    </IconButton>
  );
};

export default EBDeleteRecord;
