import { View } from '@react-pdf/renderer';
import {
  IndividualCheckDocuments,
  IndividualCheckMeta,
  IndividualCheckSummary,
  IndividualCheckTable,
} from '../components';
import { styles } from '../styles';

export interface IndividualCheckDetailsProps {
  checkData: any;
  subCheckData: any;
}

export const IndividualCheckDetails = (props: IndividualCheckDetailsProps) => {
  const { checkData, subCheckData } = props;
  return (
    <View style={styles.mainSectionContainer}>
      {/* individual check Summary - fixed section */}
      <IndividualCheckSummary
        checkData={checkData}
        subCheckData={subCheckData}
      />
      {/* individual check table - breakable section */}
      {subCheckData.details.length > 0 && (
        <IndividualCheckTable subCheckData={subCheckData} />
      )}
      {/* individual check meta - fixed section */}
      {<IndividualCheckMeta subCheckData={subCheckData} />}
      {/* Individual Checks Documents */}
      {
        <IndividualCheckDocuments
          checkData={checkData}
          subCheckData={subCheckData}
        />
      }
    </View>
  );
};
