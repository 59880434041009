/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BKStyledButton } from '@gigin-work-space/common-ui';
import { ServerResponse } from '@gigin-work-space/model';
import {
  AUTO_HIDE_TIMEOUT,
  BGV_OPS_EMAIl,
  OrderCreationPackageContent,
  SUPPORT_MOBILE_NUMBER,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { IApiResponse, IPackage } from 'src/app/constant/interfaces';
import {
  useFooter,
  useOrderCreationContext,
  useOrderCreationStepContext,
  useOrderCreationStepperError,
} from 'src/app/context';
import { DataCollectionModal } from 'src/app/templates';
import { axiosInstance, endpoints } from 'src/app/utils';
import {
  default as NotFound,
  default as NotFoundPage,
} from '../bgv-orders-table/components/not-found-page';

export interface AddPackageProps {}

export const AddPackages = React.memo((props: AddPackageProps) => {
  const { nextStep, activeStep } = useWizard();
  const { dispatch: stepDispatch } = useOrderCreationStepContext();
  const { state: footerState, dispatch: footerDispatch } = useFooter();
  const { addError, removeError } = useOrderCreationStepperError();
  const {
    state: { selectedPackage, orderInitiationId, dataCollectionMode },
    dispatch: orderDispatch,
  } = useOrderCreationContext();
  const { enqueueSnackbar } = useSnackbar();

  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');
  const [allPackages, setAllPackages] = useState<IPackage[]>([]);
  const [filteredPackages, setFilteredPackages] = useState<IPackage[]>([]);
  const [openDataCollectionModeModal, setOpenDataCollectionModeModal] =
    useState(false);
  const [packageLimit, setPackageLimit] = useState(50);

  const getPackageDetails = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const response = await axiosInstance.get<
        ServerResponse<IApiResponse<IPackage>>
      >(
        `${endpoints.GET_PACKAGE_DETAILS}/list/list-of-packages?limit=${packageLimit}&page=1`
      );
      const result = response.data.data;
      if (result) {
        setAllPackages(result?.data || []);
        setFilteredPackages(result?.data || []);
        setTotalNumberOfPages(result?.meta?.totalPages || 1);
      } else {
        throw new Error('Data is undefined');
      }
    } catch (error) {
      setError(true);
      enqueueSnackbar('Failed to fetch document list', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
      });
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar]);

  const handleSelectPackage = useCallback(
    (packageId: string | null) => {
      const newPackage =
        selectedPackage?.id === packageId
          ? null
          : allPackages.find(pkg => pkg.id === packageId) || null;
      orderDispatch({
        type: 'SET_SELECTED_PACKAGE',
        payload: newPackage as IPackage,
      });

      // Remove error if a package is selected
      if (newPackage) {
        removeError(activeStep, 'package');
      }
    },
    [selectedPackage, allPackages, orderDispatch, removeError, activeStep]
  );

  const debounceSearch = useCallback(
    debounce((query: string) => {
      const filtered = allPackages.filter(pkg =>
        pkg.packageName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredPackages(filtered);
    }, 300),
    [allPackages]
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    debounceSearch(e.target.value);
  };

  const handleRetry = () => {
    setSearchQuery('');
    setFilteredPackages(allPackages);
  };

  const handleOpenModal = () => setOpenDataCollectionModeModal(true);
  const handleCloseModal = () => setOpenDataCollectionModeModal(false);

  const handlePrimaryCTA = async () => {
    if (selectedPackage) {
      footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
      try {
        const response = await axiosInstance.patch(
          `${endpoints.UPDATE_CHECKOUT_INITIATION}/${orderInitiationId}`,
          {
            initialPackageId: selectedPackage?.id,
          }
        );

        if (response?.data?.statusCode === HttpStatus.OK) {
          stepDispatch({
            type: 'MARK_STEP_COMPLETED',
            payload: activeStep,
          });
          if (!dataCollectionMode) {
            handleOpenModal();
            return;
          }
          nextStep();
          return;
        }
        throw new Error('Failed to update package selection');
      } catch (error) {
        enqueueSnackbar('Failed to update package selection', {
          variant: VARIANT_TYPE.ERROR,
          autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
        });
      } finally {
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
      }
    }
  };

  // Side Effects
  useEffect(() => {
    getPackageDetails();
  }, [getPackageDetails]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  //  useEffect to handle footer button state based on parseResponse
  useEffect(() => {
    if (!selectedPackage) {
      footerDispatch({ type: 'SET_PRIMARY_DISABLED', disabled: true });
    } else {
      footerDispatch({ type: 'SET_PRIMARY_DISABLED', disabled: false });
    }
  }, [selectedPackage, footerDispatch]);

  useEffect(() => {
    footerDispatch({
      type: 'SET_PRIMARY_ACTION',
      action: handlePrimaryCTA,
      label: 'Continue',
    });
    stepDispatch({
      type: 'MARK_STEP_VISITED',
      payload: activeStep,
    });
  }, [footerDispatch, handlePrimaryCTA, stepDispatch, activeStep]);

  const handlePackageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPageNumber(value);
  };

  return (
    <Box
      p={2}
      component={Paper}
      className="w-[90vw] h-full block my-0 mx-auto overflow-auto rounded-lg py-xl2 px-xl3">
      <Box className="flex flex-col gap-2">
        <Typography
          variant="body2"
          className="font-normal font-secondary tracking-wide"
          gutterBottom>
          Select the package or search that most closely meets your needs. You
          can add more to the package once you select one.
        </Typography>
        <Box mb={1} className="max-w-md relative">
          <TextField
            variant="outlined"
            placeholder="Search for package"
            className="w-full"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              sx: {
                '& .MuiInputBase-input': {
                  padding: '12px',
                },
              },
            }}
          />
        </Box>
      </Box>

      <Box className="overflow-y-auto">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ border: 'none' }}>
                <TableCell className="bk-typography-subTitle1 bku_grey_600">
                  Package
                </TableCell>
                <TableCell className="bk-typography-subTitle1 bk_grey_600">
                  Checks
                </TableCell>
                <TableCell className="opacity-0">Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} style={{ border: 'none' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100vw">
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={4} style={{ border: 'none' }}>
                    <NotFoundPage
                      header={
                        OrderCreationPackageContent.PACKAGE_API_FAIL_HEADER
                      }
                      content={
                        OrderCreationPackageContent.PACKAGE_API_FAIL_CONTENT
                      }
                      handleRetry={getPackageDetails}
                      typography="bk-typography-h5"
                    />
                  </TableCell>
                </TableRow>
              ) : allPackages.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} style={{ border: 'none' }}>
                    <NotFoundPage
                      header={
                        OrderCreationPackageContent.EMPTY_PACKAGE_ARRAY_HEADER
                      }
                      content={
                        OrderCreationPackageContent.EMPTY_PACKAGE_ARRAY_CONTENT
                      }
                      secondaryContent={
                        <Typography className="bk-typography-body2 text-bk_text_dark">
                          Please reach us at {BGV_OPS_EMAIl} or call us @
                          {SUPPORT_MOBILE_NUMBER} for any queries.{' '}
                          <span className="text-[#87c9f9]">Report here</span>
                        </Typography>
                      }
                      typography="bk-typography-h5"
                    />
                  </TableCell>
                </TableRow>
              ) : filteredPackages.length > 0 ? (
                filteredPackages.map(pkg => (
                  <TableRow
                    component={Paper}
                    key={pkg.id}
                    className="bk_card_default bk_card_container border-[2px] h-[120px] border-solid border-bk_stroke_primary m-lg"
                    onClick={() => handleSelectPackage(pkg.id)}
                    style={{ cursor: 'pointer' }}>
                    <TableCell
                      className={`${
                        pkg.listOfChecks.length === 1 ? '' : 'flex items-start'
                      }  border-0`}>
                      <Box>
                        <Typography className="bk-typography-subTitle1 font-medium text-bku_text_dark">
                          {pkg.packageName}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box className="flex flex-col gap-1">
                        {pkg.listOfChecks.map((check, index) => (
                          <Box key={index} className="flex items-center gap-1">
                            <CheckOutlinedIcon className="text-base h-4 w-4 text-bk_bg_primary_dark font-bold self-auto" />
                            <Typography className="bk-typography-body2 text-bku_text_dark max-md:bk-typography-btn-small">
                              {check.check}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>

                    <TableCell className="text-end min-w-[250px]">
                      <Box display="flex" justifyContent="flex-end">
                        <BKStyledButton
                          className="py-sm px-xl2 text-[15px] max-md:text-xs font-secondary rounded-md max-md:px-lg"
                          variant={
                            pkg.id === selectedPackage?.id
                              ? 'contained'
                              : 'outlined'
                          }
                          color={
                            pkg.id === selectedPackage?.id
                              ? 'primary'
                              : undefined
                          }
                          onClick={e => {
                            e.stopPropagation();
                            handleSelectPackage(pkg.id);
                          }}
                          startIcon={
                            pkg.id === selectedPackage?.id ? (
                              <CheckCircleIcon />
                            ) : null
                          }>
                          {pkg.id === selectedPackage?.id
                            ? 'Package Selected'
                            : 'Select Package'}
                        </BKStyledButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} style={{ border: 'none' }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100vw">
                      <NotFound
                        header={
                          OrderCreationPackageContent.PACKAGE_NOT_FOUND_HEADER
                        }
                        content={
                          OrderCreationPackageContent.PACKAGE_NOT_FOUND_CONTENT
                        }
                        handleRetry={handleRetry}
                        typography="bk-typography-h4"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <DataCollectionModal
        isModalOpen={openDataCollectionModeModal}
        handleCloseModal={handleCloseModal}
      />
    </Box>
  );
});

export default AddPackages;
