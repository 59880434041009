/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import React, { createContext, ReactNode, useContext, useReducer } from 'react';

interface ErrorObject {
  message: string;
  field: string;
}

interface OrderCreationStepperErrorContextInterface {
  errors: Record<string, ErrorObject>;
  addError: (step: number, field: string, message: string) => void;
  removeError: (step: number, field: string) => void;
  getErrors: (step: number) => ErrorObject[];
}

type ErrorAction =
  | { type: 'ADD_ERROR'; step: number; field: string; message: string }
  | { type: 'REMOVE_ERROR'; step: number; field: string };

const initialState: Record<string, ErrorObject> = {};

const errorReducer = (
  state: Record<string, ErrorObject>,
  action: ErrorAction
): Record<string, ErrorObject> => {
  switch (action.type) {
    case 'ADD_ERROR': {
      const key = `${action.step}-${action.field}`;
      return {
        ...state,
        [key]: { message: action.message, field: action.field },
      };
    }
    case 'REMOVE_ERROR': {
      const key = `${action.step}-${action.field}`;
      const { [key]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
};

const OrderCreationStepperErrorContext = createContext<
  OrderCreationStepperErrorContextInterface | undefined
>(undefined);

export const OrderCreationStepperErrorProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [errors, dispatch] = useReducer(errorReducer, initialState);

  const addError = (step: number, field: string, message: string) => {
    dispatch({ type: 'ADD_ERROR', step, field, message });
  };

  const removeError = (step: number, field: string) => {
    dispatch({ type: 'REMOVE_ERROR', step, field });
  };

  const getErrors = (step: number) => {
    return Object.keys(errors)
      .filter(key => key.startsWith(`${step}-`))
      .map(key => errors[key]);
  };

  return (
    <OrderCreationStepperErrorContext.Provider
      value={{ errors, addError, removeError, getErrors }}>
      {children}
    </OrderCreationStepperErrorContext.Provider>
  );
};

export const useOrderCreationStepperError = () => {
  const context = useContext(OrderCreationStepperErrorContext);
  if (!context) {
    throw new Error(
      'useOrderCreationStepperError must be used within an OrderCreationStepperErrorProvider'
    );
  }
  return context;
};
