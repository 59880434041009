import { CommonTabBar } from '@gigin-work-space/common-ui';
import { ICandidateIndividualData } from '@gigin-work-space/model';
import { OrderCandidateStore } from '@gigin-work-space/store';
import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import { TabAccordion } from './tabs-accordion';

interface ICandidateIndividualCheckProps {
  onClose(): void;
}

export const CandidateIndividualCheck = ({
  onClose,
}: ICandidateIndividualCheckProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [candidateData, setCandidateData] =
    useState<ICandidateIndividualData>();
  const { report_id } = useSnapshot(OrderCandidateStore);
  // API call for fetch candidate
  const fetchSpecificUser = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.FETCH_SPECIFIC_CANDIDATE_DATA}/${report_id}`
      );
      if (response.data) {
        setCandidateData(response.data.data);
      } else {
        onClose();
      }
    } catch (e) {
      onClose();
      enqueueSnackbar(`Candidate Fetching Failed!`, {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    fetchSpecificUser();
  }, []);

  return (
    <Box>
      {candidateData ? (
        <>
          <Stack direction={'row'} m={'2rem'} justifyContent={'space-between'}>
            {/* Header Candidate */}
            <Stack direction={'row'} columnGap={'1.5rem'}>
              {/* image and no */}
              <FileDisplayComponent
                fileUrl={candidateData.basic_details.profile_image}
                fileStyle={{
                  borderRadius: '50%',
                  width: '72px',
                  height: '72px',
                }}
                fileType="image"
              />
              {/* verification status */}
              <Stack direction={'column'}>
                <Typography className="bk-headline2">
                  {candidateData.basic_details.name}
                </Typography>
                <Typography className="bK-body1 text-bk_text_secondary">
                  {candidateData.basic_details.job_role}
                </Typography>
                <Stack direction={'row'} columnGap={2} mt={2}>
                  <Stack
                    direction={'row'}
                    columnGap={0.5}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="call-icon.svg"
                      imageType="icon"
                      width="9.6px"
                      height="9.6px"
                      className="cursor-pointer"
                    />
                    <Typography className="bK-body1 text-bk_text_secondary">
                      {candidateData.basic_details.mobile}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={'row'}
                    columnGap={0.5}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="jobs-icon.svg"
                      imageType="icon"
                      width="9.6px"
                      height="9.6px"
                      className="cursor-pointer"
                    />

                    <Typography className="bK-body1 text-bk_text_secondary">
                      {candidateData.basic_details.email}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <CommonTabBar
            listLabel={['Verification Details']}
            listComponent={[
              <TabAccordion
                reports={candidateData.reports}
                request={candidateData.request}
                dataCollection={candidateData.dataCollection}
                basicDetail={candidateData.basic_details}
              />,
            ]}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
