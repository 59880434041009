import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

export interface itemInterface {
  title: string;
  isMandatory: boolean;
}
interface MultiColumnListProps {
  items: itemInterface[];
  columns: number;
  itemsPerColumn: number;
  bulletType?: 'number' | 'bullet';
  containerStyle?: React.CSSProperties;
  columnStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  asteriskStyle?: React.CSSProperties;
}

const ListContainer = styled(Box)(({ style }) => ({
  display: 'flex',
  flexDirection: 'row',
  ...style,
}));

const Column = styled(Box)(({ style }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...style,
}));

export const MultiColumnList: React.FC<MultiColumnListProps> = React.memo(
  ({
    items,
    columns,
    itemsPerColumn,
    bulletType = 'number',
    containerStyle,
    columnStyle,
    itemStyle,
    asteriskStyle,
  }) => {
    const columnItems = Array.from({ length: columns }, (_, colIndex) =>
      items.slice(colIndex * itemsPerColumn, (colIndex + 1) * itemsPerColumn)
    );

    return (
      <ListContainer style={containerStyle}>
        {columnItems.map((colItems, colIndex) => (
          <Column key={colIndex} style={columnStyle}>
            {colItems.map((item, itemIndex) => (
              <Typography
                key={itemIndex}
                style={itemStyle}
                className="bk-typography-body2">
                {bulletType === 'number'
                  ? `${colIndex * itemsPerColumn + itemIndex + 1}. ${
                      item.title
                    }`
                  : `• ${item.title}`}
                {item.isMandatory && (
                  <span style={asteriskStyle} className="text-bk_error_main">
                    *
                  </span>
                )}
              </Typography>
            ))}
          </Column>
        ))}
      </ListContainer>
    );
  }
);
