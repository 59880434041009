import { COLORS, SPACING } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { reportStatusColorMapper, reportStatusTextMapper } from '../components';
import { ImageComponent } from '../components/report-image';
import StatusTxtBtn from '../components/status-txt-btn';
import { styles } from '../styles';
import {
  getAnnexuresByCheckId,
  getDocumentsByCheckId,
} from '../utility/get-documents-by-checkid';
import {
  DocumentWithIndex,
  FlattenedSubcheck,
} from '../utility/restructure-data';

const IndividualCheckConsolidated = ({
  flattenedSubchecks,
  documentList,
}: {
  flattenedSubchecks: FlattenedSubcheck[];
  documentList: DocumentWithIndex[];
}) => {
  return (
    <View>
      {flattenedSubchecks?.map((report, index) => (
        <View key={index}>
          <View
            style={{
              flexDirection: 'row',
            }}>
            <View style={{ width: 16 }}>
              <Text
                style={[
                  styles.typov3Body3,
                  {
                    color: COLORS.bk_report_text_secondary,
                    fontWeight: 700,
                    textAlign: 'left',
                    marginTop: 1,
                  },
                ]}>
                {index + 1}
              </Text>
            </View>
            <View style={{ width: SPACING.md }} />
            <View style={{ flex: 1 }}>
              {/* Title and Status */}
              <View style={styles.checkTitleAndStatusContainer}>
                <Text
                  style={[
                    styles.typov3Body3,
                    { fontWeight: 700, marginRight: SPACING.sm },
                  ]}>
                  {`${report?.reportTitle}: ${report?.subcheckName}`}
                </Text>
                <StatusTxtBtn status={report?.subcheckStatus} />
              </View>
              {/* Check description */}
              {report?.meta?.description && (
                <View style={{ marginTop: SPACING.lg }}>
                  <Text style={styles.typov3Body3}>
                    {report?.meta?.description}
                  </Text>
                </View>
              )}
              {/* Check details */}
              <View style={{ marginTop: SPACING.lg }}>
                {report?.details?.map(
                  (detail: any, detailIndex: any) =>
                    detail?.fieldVisibility && (
                      <View
                        key={detailIndex}
                        style={[
                          styles.twoColumnContainer,
                          {
                            marginBottom: SPACING.sm,
                          },
                        ]}>
                        <Text
                          style={[
                            styles.typov3Body3,
                            { flex: 1, color: COLORS.bk_report_text_secondary },
                          ]}>
                          {detail.parameters}
                        </Text>
                        <View style={{ flex: 2 }}>
                          <Text style={[styles.typov3Body3]}>
                            {detail.detail.value || 'N/A'}
                          </Text>
                          {detail?.detail?.message?.map(
                            (message: any, msgIndex: any) =>
                              message?.comment && (
                                <View
                                  key={msgIndex}
                                  style={styles.iconTextContainer}>
                                  <ImageComponent
                                    src={reportStatusTextMapper(
                                      message?.icon?.toUpperCase()
                                    )}
                                    style={[styles.statusBtnIconImage]}
                                  />
                                  <Text
                                    style={[
                                      styles.typov3HintText,
                                      {
                                        color: reportStatusColorMapper(
                                          message?.icon?.toUpperCase()
                                        ),
                                      },
                                    ]}>
                                    {message?.comment}
                                  </Text>
                                </View>
                              )
                          )}
                        </View>
                      </View>
                    )
                )}
              </View>
              {/* Additional Comments */}
              {report?.meta?.additional_comments && (
                <View style={[styles.twoColumnContainer]}>
                  <Text
                    style={[
                      styles.typov3Body3,
                      { flex: 1, color: COLORS.bk_report_text_secondary },
                    ]}>
                    Additional comments
                  </Text>
                  <View style={{ flex: 2 }}>
                    <Text style={[styles.typov3Body3]}>
                      {report?.meta?.additional_comments}
                    </Text>
                  </View>
                </View>
              )}
              {/* Supporting documents and Source of verification */}
              <View style={[styles.displayFlexRowBetween]}>
                {/* Source of verification */}
                {/* Supporting documents */}
                {getDocumentsByCheckId(documentList, report?.meta?.check_id)
                  .length > 0 && (
                  <View style={{ marginTop: SPACING.lg }}>
                    <Text
                      style={[
                        styles.typov3Body3,
                        { color: COLORS.bk_report_text_secondary },
                      ]}>
                      Supporting documents
                    </Text>
                    <Text
                      style={[
                        styles.typov3Body3,
                        { color: COLORS.bk_report_selection_secondary_main },
                      ]}>
                      {getAnnexuresByCheckId(
                        documentList,
                        report?.meta?.check_id
                      )}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          {index !== flattenedSubchecks.length - 1 && (
            <View style={styles.sectionBreakSolid} />
          )}
        </View>
      ))}
    </View>
  );
};

export default React.memo(IndividualCheckConsolidated);
