/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  COLORS,
  DataCollectionItemSource,
  SPACING,
} from '@gigin-work-space/utils';
import { AssignmentInd, Close, InfoOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { IPackage, IParsedExcelDetails } from 'src/app/constant/interfaces';

export interface ConfirmOrderCreationModalProps {
  open: boolean;
  packageDetails: IPackage;
  parsedExcelDetails: IParsedExcelDetails;
  mode:
    | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
    | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION;
  handleClose: () => void;
  handleConfirm: () => void;
}

interface WarningChipProps {
  invalidRecords: number;
}

const WarningChip = React.memo(({ invalidRecords }: WarningChipProps) => {
  return (
    <Box className="flex flex-row gap-x-sm items-center w-fit p-sm bg-bk_error_states_hover rounded-lg">
      <InfoOutlined
        sx={{ fontSize: SPACING.md, color: COLORS.bk_warning_main }}
      />
      <Typography className="bk-typography-body2">
        {invalidRecords > 1
          ? `${invalidRecords} candidates`
          : `${invalidRecords} candidate`}{' '}
        with invalid data will be ignored
      </Typography>
    </Box>
  );
});

export const ConfirmOrderCreationModal = React.memo(
  ({
    open,
    packageDetails,
    mode,
    parsedExcelDetails,
    handleClose,
    handleConfirm,
  }: ConfirmOrderCreationModalProps) => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={open}>
            <Box className="bk_close_modal p-xl2 rounded-lg flex flex-col gap-y-xl2 md:min-w-[400px] lg:min-w-[600px]">
              {/* Header section */}
              <Box className="flex flex-row items-center justify-between gap-x-lg">
                <Box className="flex flex-row items-center gap-x-sm2">
                  <AssignmentInd
                    sx={{ fontSize: SPACING.xl2, color: COLORS.bk_info_main }}
                  />
                  <Typography className="bk-typography-h6">
                    Confirm Order
                  </Typography>
                </Box>
                <IconButton aria-label="close" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
              {/* Main section */}
              <Box className="flex flex-col gap-y-sm">
                {parsedExcelDetails?.totalItems !==
                parsedExcelDetails?.validRecords ? (
                  <WarningChip
                    invalidRecords={
                      parsedExcelDetails?.totalItems -
                      parsedExcelDetails?.validRecords
                    }
                  />
                ) : (
                  <Typography className="bk-typography-body2">
                    Please confirm the order details below:
                  </Typography>
                )}

                <Typography className="bk-typography-body2">
                  Order details:
                </Typography>
                <Typography className="bk-typography-body2">
                  Package Selected:{' '}
                  <span className="font-bold">
                    {packageDetails?.packageName}
                  </span>
                  <br />
                  No. of candidates :{' '}
                  <span className="font-bold">
                    {parsedExcelDetails?.validRecords}
                  </span>
                </Typography>
                <Typography className="bk-typography-body2 mt-lg">
                  Note: After confirming the order, the candidate details or
                  package can not be edited after confirming the order
                </Typography>
              </Box>
              {/* Footer section */}
              <Box className="flex flex-row items-center justify-between gap-x-sm">
                <BKStyledButton
                  className="rounded-[4px]"
                  variant="outlined"
                  fullWidth
                  onClick={handleClose}>
                  Go back
                </BKStyledButton>
                <BKStyledButton
                  className="rounded-[4px]"
                  variant="contained"
                  fullWidth
                  onClick={handleConfirm}>
                  Yes, Confirm order
                </BKStyledButton>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
);
