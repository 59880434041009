import { IValidateCandidate } from '@gigin-work-space/model';
import { proxy } from 'valtio';

// userStore Type
interface ICandidateStoreType {
  selectedCandidate: IValidateCandidate[];
}

// User dummy store creation
export const CandidateStore: ICandidateStoreType = proxy<ICandidateStoreType>({
  selectedCandidate: [],
});

// Define an interface for the state
interface IDataCollectionStatusStore {
  DataCollectionStatusStore: string;
}

// Use the interface as the generic type for the proxy function
export const DataCollectionStatusStore = proxy<IDataCollectionStatusStore>({
  DataCollectionStatusStore: '',
});

// Function to update the status
export const updateDataCollectionStatus = (status: string) => {
  DataCollectionStatusStore.DataCollectionStatusStore = status;
};
