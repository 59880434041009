import {
  BGV_OPS_EMAIl,
  EmployerAppAssetIconsFileNames,
  SPACING,
  SUPPORT_MOBILE_NUMBER,
} from '@gigin-work-space/utils';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import { DisplayImage } from 'src/app/components';

interface ComingSoonModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ComingSoonModal: React.FC<ComingSoonModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="bk-feature-pending-modal-centered">
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: SPACING.sm,
            right: SPACING.sm,
          }}>
          <CloseIcon />
        </IconButton>
        <Grid className="bk-container-center" gap={3}>
          <Grid>
            <Typography className="bk-typography-h6">
              We are currently improving the experience of the bulk upload
              feature.
            </Typography>
          </Grid>
          <Grid>
            <DisplayImage
              imageName={EmployerAppAssetIconsFileNames.COMING_SOON_FEATURE}
              imageType="icon"
              width="278px"
              height="213px"
            />
          </Grid>
          <Grid className="bk-text-center-align">
            <Typography className="bk-body2-v2">
              Apologies for the inconvenience caused. Meanwhile we request you
              to share the candidate details with us via mail at{' '}
              <a href={`mailto:${BGV_OPS_EMAIl}`}>{BGV_OPS_EMAIl}</a>
            </Typography>
            <Typography className="bk-body2-v2 pt-4">
              For further support, please contact {SUPPORT_MOBILE_NUMBER}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ComingSoonModal;
