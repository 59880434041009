import { OrderCandidateStore } from '@gigin-work-space/store';
import { PROGRESS_STATUS, ProcessType } from '@gigin-work-space/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, List, Paper, Popover, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import ProcessObject from './processObject';

interface ProcessObject {
  _id: string;
  id: number;
  title: string;
  createdAt: string;
  processType: ProcessType;
  processStatus: PROGRESS_STATUS;
  result: { data: { count: number; url: string } };
}

const Processes = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [downloadItems, setDownloadItems] = useState<ProcessObject[]>([]);
  const { updateConsentStatus } = useSnapshot(OrderCandidateStore);

  const [isLoading, setIsLoading] = useState(true);
  // const buttonRef = useRef(null);

  useEffect(() => {
    const fetchDownloadItems = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          endpoints.BULK_DOWNLOAD_REQUEST_LIST
        );

        if (response.data && response.data.data.length) {
          setDownloadItems(response.data.data);
        }
      } catch (error) {
        enqueueSnackbar('Error fetching download items', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDownloadItems();
  }, [updateConsentStatus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'download-options-popover' : undefined;
  return (
    <div>
      {!isLoading && downloadItems.length > 0 && (
        <Box className="p-lg">
          <Button
            aria-describedby={id}
            startIcon={<DownloadIcon className="text-bk_text_secondary" />}
            endIcon={<ArrowDropDownIcon className="text-bk_text_secondary" />}
            onClick={handleClick}
            className="downloadStatusButton textColorGray">
            <Typography color="primary">Download Status</Typography>
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Paper sx={{ p: 2 }} className="linkOptionsDownload">
              <List>
                {downloadItems.map(item => (
                  <ProcessObject item={item} />
                ))}
              </List>
            </Paper>
          </Popover>
        </Box>
      )}
    </div>
  );
};

export default Processes;
