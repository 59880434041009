import { COLORS } from '@gigin-work-space/utils';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { SyntheticEvent } from 'react';
import { CommonCheckStatus } from '../../candidate-individual';
import { TabCloseAccordion, TabOpenAccordion } from './tabs-accordion';

interface ReportTabAccordion {
  reports: any[];
  expanded: string | false;
  handleChange: (
    _panel: string
  ) => (_event: SyntheticEvent, _newExpanded: boolean) => void; //putting "_" as a prefix so that it doesn't throw error
}

const ReportTabAccordian = ({
  reports,
  handleChange,
  expanded,
}: ReportTabAccordion) => {
  return (
    <>
      {reports.map((report: any, index: number) => {
        const panelId = index.toString();
        return (
          <Accordion
            key={index}
            elevation={0}
            expanded={expanded === index.toString()}
            onChange={handleChange(panelId)}
            disableGutters={true}
            sx={{
              '& .MuiAccordionDetails-root': {
                padding: 0,
                borderBottom: `1px solid ${COLORS.bk_stroke_divider}`,
              },
              '& .MuiAccordionSummary-root': {
                padding: 0,
              },
              '& .MuiAccordion-root.Mui-expanded ': {
                margin: 0,
              },
              '&.Mui-expanded': {
                borderBottom: 'none',
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
                p: 2,
                borderRadius: '12px 12px 0px 0px',
                '&.Mui-expanded': {
                  background: COLORS.bk_tag_action_bg,
                  borderBottom: `1px solid ${COLORS.bk_stroke_divider}`,
                },
                '&:hover': {
                  '&:not(.Mui-expanded)': {
                    backgroundColor: COLORS.bk_tag_blue_bg,
                  },
                },
              },
              overflow: 'hidden',
              boxShadow: 'none',
              border: `1px solid ${COLORS.bk_stroke_divider}`,
              borderRadius: '12px 12px 0px 0px',
            }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header">
              {expanded === panelId ? (
                <TabOpenAccordion data={report} />
              ) : (
                <TabCloseAccordion data={report} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <CommonCheckStatus data={report} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default ReportTabAccordian;
