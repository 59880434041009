import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { ImageComponent } from './report-image';
import {
  reportStatusIconMapper,
  reportStatusMapper,
} from './report-status-mapper';

export interface CheckMenuListProps {
  menu: any;
}

export const CheckMenuList = (props: CheckMenuListProps) => {
  const { menu } = props;
  return (
    <View style={styles.reportMenuList} wrap={false}>
      <View style={styles.checkLogoContainer}>
        <ImageComponent src={menu.icon} style={styles.checkLogoImage} />
      </View>
      <View style={styles.checkMenuDetailsContainer}>
        {/* <View style={styles.checkInfoSection}> */}
        <Text style={styles.textSubHeading1}>{menu.title}</Text>
        <View style={styles.checkInfoSubheading}>
          {/* TODO: Replace with API data later */}
          {menu.documentlist.map((data: any) => (
            <View
              style={[
                styles.iconTextContainer,
                { flexWrap: 'nowrap', flexBasis: 'auto', flexShrink: 1 },
              ]}
              key={data.check_number}>
              {reportStatusIconMapper(data.check_status.status) && (
                <ImageComponent
                  src={reportStatusIconMapper(data.check_status.status)}
                  style={styles.iconImageSmall}
                />
              )}
              <Text style={styles.textBody2}>
                {/* {capitalizeString(data.product_listing_name)} */}
                {data.product_listing_name}
              </Text>
            </View>
          ))}
        </View>
        {/* </View> */}
      </View>
      <ImageComponent
        src={reportStatusMapper(menu.product_type_status.status)}
        style={[
          styles.pillImage,
          {
            position: 'absolute',
            top: '50%',
            right: 12,
            zIndex: 10,
          },
        ]}
      />
    </View>
  );
};
