import { Box, Divider, Tab } from '@mui/material';
import { ReactElement, useState } from 'react';
import { BKStyledTabs } from '../bk-styled-component/bk-styled-component';

/* eslint-disable-next-line */
export interface CommonTabBarProps {
  listLabel: string[];
  listComponent: ReactElement[];
}

export const CommonTabBar = ({ listLabel, listComponent }: CommonTabBarProps) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <BKStyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        sx={{ mx: 4, '& .MuiButtonBase-root': { textTransform: 'none' } }}>
        {listLabel.map((label, index) => {
          return (
            // <Box>
            <Tab value={index} label={label} />
            // {listLabel.length - 1 !== index && <Divider orientation="vertical" flexItem />}
            // </Box>
          );
        })}
      </BKStyledTabs>
      <Divider orientation="vertical" flexItem />
      {listComponent.map((component, index) => {
        return value === index && <Box mx={4}> {component}</Box>;
      })}
    </div>
  );
};

export default CommonTabBar;
