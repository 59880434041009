import { AUTO_HIDE_TIMEOUT } from '@gigin-work-space/utils';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import i18next from './app/utils/localization/i18n';
import './styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

root.render(
  <I18nextProvider i18n={i18next}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={AUTO_HIDE_TIMEOUT.LONGEST}
        maxSnack={5}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        preventDuplicate
        action={key => (
          <IconButton onClick={onClickDismiss(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        )}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  </I18nextProvider>
);
