// Import necessary libraries and components
import { ExportExcelFileName, capitalizeString } from '@gigin-work-space/utils';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  ExportOrchestrator,
  ExportType,
} from 'src/app/utils/data-manipulation';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { axiosInstance, endpoints } from '../../utils';

// Define CSS styles and interfaces
const checkListRowStyle = {
  cursor: 'pointer',
  padding: '10px',
  borderBottom: '1px solid #ccc',
  transition: 'background-color 0.3s',
};

const tableStyle = {
  borderCollapse: 'collapse' as const,
  width: '100%',
  position: 'absolute' as const,
};

const cellStyle = {
  border: '1px solid black',
  padding: '8px',
};

const reportDetailsStyle = {
  paddingLeft: '30px',
  paddingTop: '10px',
  paddingBottom: '10px',
  borderBottom: '1px solid #ddd',
};

const textStyle = {
  color: 'blue',
  cursor: 'pointer',
};

const pdfPanel = {
  position: 'fixed' as const,
  right: 0,
  top: 0,
  width: '50%',
  height: '100%',
  backgroundColor: 'white',
  boxShadow: '-5px 0px 10px rgba(0, 0, 0, 0.2)',
  zIndex: 1000,
  padding: '20px',
  overflowY: 'auto' as const,
};

const closeButtonStyle: React.CSSProperties = {
  marginTop: '10px',
  padding: '10px 20px',
  backgroundColor: '#f44336', // Example color, change as needed
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  float: 'right',
};
// Define interfaces for TypeScript
interface CrcCheckListInterface {
  _id: string;
  caseId: string;
  name: string;
  status: string;
}

interface ReportInterface {
  name: string;
  raw_address: string;
  case_number_record: string;
  under_sections: string;
  pdf_report: string;
  case_status: string;
  year_of_case: string;
  under_acts: string;
  case_category: string;
  opposite_party_name: string;
  court_judge_details: string;
  street: string;
  district: string;
  state: string;
}

interface CaseDetailsInterface {
  response: {
    data: {
      output: {
        source: {
          reports: ReportInterface[];
        };
      };
    };
  };
  caseId: string;
  name: string;
  fatherName: string;
  address: string;
  status: string;
}

export const OpsPlatformCrcCheckList = () => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [accountData, setAccountData] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [crcCheckList, setcrcCheckList] = useState<CrcCheckListInterface[]>([]);
  const [selectedCrcCheckId, setSelectedCrcCheckId] = useState<string | null>(
    null
  );

  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);

  const [caseDetails, setCaseDetails] = useState<CaseDetailsInterface | null>(
    null
  );
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);

  const exportCheckDetailsClass = new ExportOrchestrator(
    ExportType.CLIENT_CRC_CHECK
  );
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const crcCheckIdFromQuery = queryParams.get('crcId');
    if (crcCheckIdFromQuery) {
      setSelectedCrcCheckId(crcCheckIdFromQuery);
      getCaseDetails(crcCheckIdFromQuery);
    }
  }, [location.search]);

  const handleGreenCaseClick = () => {
    setIsConfirmOpen(true);
  };

  // Function to get all accounts for account DD
  const getAccounts = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_ALL_ACCOUNTS);
      if (response.data.success) {
        setAllAccounts(response.data.data);
        return;
      }

      throw new Error('Failed to fetch accounts');
    } catch (error) {
      enqueueSnackbar('Failed to fetch accounts', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  const handleConfirm = async () => {
    setIsConfirmOpen(false);
    try {
      const response = await axiosInstance.get(
        `${endpoints.OPS_CHECK_GREEN}/${selectedCrcCheckId}`
      );

      if (!response.data || Object.keys(response.data).length === 0) {
        enqueueSnackbar('No case details found', { variant: 'info' });
      } else {
        setCaseDetails(response.data);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  // Function to get all CRC check lists
  const getCrcCheckList = useCallback(
    async (accountId: string, tenantId: string, page: number = 1) => {
      try {
        const url = `${endpoints.OPS_CHECK_LIST}/${accountId}/${tenantId}?limit=10&page=${page}`;
        const response = await axiosInstance.get(url);

        // Check if the response data is empty or not in the expected format
        if (!response.data || response.data.length === 0) {
          enqueueSnackbar('No data available', { variant: 'info' });
        } else {
          setcrcCheckList(prevCrcCheckList => [
            ...prevCrcCheckList,
            ...response.data,
          ]);
          setCurrentPage(page);
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch CheckList', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const queryParams = new URLSearchParams(location.search);
    const accountId = queryParams.get('accountId');
    const tenantId = queryParams.get('tenantId');
    if (accountId && tenantId) {
      getCrcCheckList(accountId, tenantId, nextPage);
    }
  };

  const goToUpload = () => {
    globalNavigateTo('/ops-platform/crc-upload-list', navigate); // Navigate to the desired route
  };

  const exportExcelSheetData = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const accountId = queryParams.get('accountId');
      const tenantId = queryParams.get('tenantId');
      if (!accountId && !tenantId) {
        return;
      }

      const response = await axiosInstance.get(
        `${endpoints.OPS_CHECK_LIST}/all/${accountId}/${tenantId}`
      );

      if (response.status === 200) {
        exportCheckDetailsClass.exportToExcel(
          response.data,
          ExportExcelFileName.CLIENT_CHECK_DETAILS
        );
        return;
      }
      throw new Error('Failed to fetch case details');
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const goToOpsDashboard = () => {
    globalNavigateTo('/ops-platform', navigate); // Navigate to the desired route
  };

  const getCaseDetails = async (crcCheckId: string) => {
    try {
      setSelectedCrcCheckId(crcCheckId);
      const response = await axiosInstance.get(
        `${endpoints.OPS_CHECK_LIST}/${crcCheckId}`
      );

      // Check if the response data is empty or not in the expected format
      if (!response.data || Object.keys(response.data).length === 0) {
        enqueueSnackbar('No case details found', { variant: 'info' });
        setCaseDetails(null); // Reset or clear the case details state
      } else {
        setCaseDetails(response.data);
        if (response.data.status === 'RESPONSE_RECEIVED') {
          setIsConfirmOpen(false);
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch case details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleChangeAccount = (event: any) => {
    const selectedAccount = event.target.value;
    setAccountData(selectedAccount);

    navigate(
      `?accountId=${selectedAccount._id}&tenantId=${selectedAccount.tenantId}`
    );
  };

  const handleCopyText = async (textToCopy: string) => {
    // Open the link in a new tab
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
    window.open('https://services.ecourts.gov.in/ecourtindia_v6/', '_blank');

    // Copy text to clipboard
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accountId = queryParams.get('accountId');
    const tenantId = queryParams.get('tenantId');
    setcrcCheckList([]);
    if (accountId && tenantId) {
      getCrcCheckList(accountId, tenantId);
    }
  }, [location.search]);

  return (
    <Stack>
      {/* Top header section */}
      <Box className="sticky top-0 z-10 bg-bk_bg_primary">
        <Grid className="flex justify-between items-center p-4">
          <Typography className="bk-headline1">CRC LIST</Typography>
          <Box sx={{ width: '100%', maxWidth: 312 }}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">
                Select Account/Company
              </InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select-label"
                value={accountData ? accountData : ''}
                label="Select Account/Company"
                onChange={handleChangeAccount}>
                {allAccounts.length &&
                  allAccounts?.map((account: any) => (
                    <MenuItem value={account} key={account?._id}>
                      {capitalizeString(account?.account_name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={goToOpsDashboard}
            style={{ float: 'right' }}>
            Ops Dashboard
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={goToUpload}
            style={{ float: 'right' }}>
            Upload Crc List
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={exportExcelSheetData}
            style={{ float: 'right' }}>
            Export CRC List
          </Button>
        </Grid>
        <Divider />
      </Box>

      <Grid className="flex">
        {/* Left Section - CRC Check List */}
        <div className="w-1/3 p-4" style={{ overflow: 'auto', height: '80vh' }}>
          {crcCheckList.map(crcCheck => (
            <div
              key={crcCheck._id}
              onClick={() => getCaseDetails(crcCheck._id)}
              style={checkListRowStyle}>
              <Typography
                variant="body1"
                style={{
                  color:
                    crcCheck.status === 'RESPONSE_RECEIVED'
                      ? 'inherit'
                      : '#048218',
                  fontWeight:
                    crcCheck.status === 'RESPONSE_RECEIVED' ? '400' : '600',
                }}>
                {crcCheck.caseId}
              </Typography>
              <Typography variant="body2">{crcCheck.name}</Typography>
            </div>
          ))}

          {accountData && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleLoadMore}
              style={{ float: 'left', marginTop: '20px' }}>
              Load More
            </Button>
          )}
        </div>

        {/* Right Section - Case Details */}
        <div className="w-2/3 p-4">
          {selectedCrcCheckId && caseDetails && (
            <>
              <Typography>
                <strong>Case ID:</strong> {caseDetails.caseId}
              </Typography>
              <div
                style={{
                  float: 'right',
                  background: '#ffffff',
                  marginTop: '-38px',
                  zIndex: 1,
                  position: 'absolute',
                  right: '27px',
                  padding: '2px',
                  width: '161px',
                  textAlign: 'center',
                }}>
                <Button
                  style={{
                    backgroundColor:
                      caseDetails.status === 'RESPONSE_RECEIVED'
                        ? '#F1F1F1'
                        : '#048218',
                    color:
                      caseDetails.status === 'RESPONSE_RECEIVED'
                        ? 'black'
                        : 'white',
                  }}
                  onClick={handleGreenCaseClick}
                  disabled={caseDetails.status === 'COMPLETED'}>
                  {caseDetails.status === 'COMPLETED'
                    ? 'Completed'
                    : 'Mark it Green'}
                </Button>
                {isConfirmOpen && caseDetails.status !== 'COMPLETED' && (
                  <div>
                    <p>Are you sure?</p>
                    <Button onClick={handleConfirm}>Yes</Button>
                    <Button onClick={() => setIsConfirmOpen(false)}>No</Button>
                  </div>
                )}
              </div>

              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td style={cellStyle}>
                      <strong>Name:</strong> {caseDetails.name}
                    </td>
                    <td style={cellStyle}>
                      <strong>Father's Name:</strong> {caseDetails.fatherName}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={cellStyle}>
                      <strong>Address:</strong> {caseDetails.address}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {caseDetails && (
            <div
              style={{
                height: '450px',
                overflow: 'auto',
                marginTop: '100px',
                border: '1px solid #000000',
              }}>
              {caseDetails &&
              caseDetails.response &&
              caseDetails.response.data &&
              caseDetails.response.data.output ? (
                caseDetails.response.data.output.source.reports.length > 0 ? (
                  caseDetails.response.data.output.source.reports.map(
                    (report, index) => (
                      <div key={index}>
                        <div
                          className="reportRowStyle"
                          onClick={() =>
                            setSelectedReportId(
                              index === selectedReportId ? null : index
                            )
                          }>
                          <Typography variant="body1" style={cellStyle}>
                            <strong>Name:</strong> {report.name}
                          </Typography>
                          <Typography variant="body2" style={cellStyle}>
                            <strong>Address:</strong> {report.raw_address}
                          </Typography>
                          <Typography
                            variant="body2"
                            onClick={() =>
                              handleCopyText(report.case_number_record)
                            }
                            style={textStyle}>
                            <strong>Case Number Record:</strong>{' '}
                            {report.case_number_record} →
                          </Typography>
                          <button
                            onClick={() => setSelectedPdf(report.pdf_report)}
                            style={closeButtonStyle}>
                            View PDF
                          </button>
                          <Typography variant="body2">
                            <strong>Section:</strong> {report.under_sections}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Year:</strong> {report.year_of_case}
                          </Typography>
                        </div>
                        {selectedReportId === index && (
                          <div style={reportDetailsStyle}>
                            <Typography variant="body2">
                              <strong>Case Status:</strong> {report.case_status}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Under Acts:</strong> {report.under_acts}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Case Category:</strong>{' '}
                              {report.case_category}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Opposite Party Name:</strong>{' '}
                              {report.opposite_party_name}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Case Judge Details:</strong>{' '}
                              {report.court_judge_details}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Case Street:</strong> {report.street}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Case District:</strong> {report.district}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Case State:</strong> {report.state}
                            </Typography>
                          </div>
                        )}
                      </div>
                    )
                  )
                ) : (
                  <Typography variant="body1">No Case found</Typography>
                )
              ) : (
                <Typography variant="body1">Still In Progress...</Typography>
              )}
            </div>
          )}

          {selectedPdf && (
            <div style={pdfPanel}>
              <button
                onClick={() => setSelectedPdf(null)}
                style={closeButtonStyle}>
                Close
              </button>

              <iframe
                src={selectedPdf}
                style={{ width: '100%', height: '100%' }}
                title="PDF Viewer"
                frameBorder="0">
                Your browser does not support iframes.
              </iframe>
            </div>
          )}
        </div>
      </Grid>
    </Stack>
  );
};
