import { Paper, styled } from '@mui/material';

export const StyledAuthenticationPaper = styled(Paper)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: '1px solid #DCD9DD',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
  borderRadius: 24,
  padding: '32px',
  width: '470px',
  height: 'auto',
}));
