import { BKStyledButton, BKStyledTableCell } from '@gigin-work-space/common-ui';
import {
  AUTO_HIDE_TIMEOUT,
  COLORS,
  InputLabel,
  LocalStorageData,
  OVERALL_STATUS,
  REPORT_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  VARIANT_TYPE,
  capitalizeString,
} from '@gigin-work-space/utils';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import StopIcon from '@mui/icons-material/Stop';

import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { DisplayImage, TablePaginationActions } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import verifyIcon from '../../../../assets/image/verifyin-green-icon.svg';
import verifyIconRed from '../../../../assets/image/verifyin-red-icon.svg';
import { globalFormatDate } from '../../../utils/data-manipulation';

interface ICaseDetailTable {
  caseDetails: any;
  onCaseDetailClick?: () => void; // Add this line to accept the callback function as a prop
}

export const CaseDetailTable = ({
  caseDetails,
  onCaseDetailClick,
}: ICaseDetailTable) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(caseDetails.length);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [requestId, setRequestCase] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [options, setOptions] = useState(
    caseDetails.map((detail: { ext_case_id: string }) => detail.ext_case_id)
  );
  const ReportStatus = [
    REPORT_STATUS.CLEAR,
    REPORT_STATUS.DISCREPANT,
    REPORT_STATUS.NO_RESPONSE,
    REPORT_STATUS.INSUFFICIENT,
    REPORT_STATUS.UNABLE_TO_VERIFY,
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultOnCaseDetailClick = () => {};
  const safeOnCaseDetailClick = onCaseDetailClick || defaultOnCaseDetailClick;
  const CancelCase = async (caseId: string) => {
    if (caseId === null) {
      return;
    }

    try {
      const response = await axiosInstance.patch(
        `${endpoints.ORDER_CHECK_CANCEL}/${caseId}`
      );
      if (response.data) {
        enqueueSnackbar('Case cancelled successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
        setIsModalOpen(false);
        safeOnCaseDetailClick();
      }
    } catch (err) {
      setIsModalOpen(false);
      enqueueSnackbar('Failed fetching owner', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  const handleRowClick = (value: any) => {
    globalNavigateTo(`/ops-individual-case/${value._id}`, navigate, {
      caseDetails: value,
    });
  };

  const handleViewReport = (event: SyntheticEvent, caseId: string) => {
    event.stopPropagation();

    globalNavigateTo(`/ops-platform/report/${caseId}`, navigate);
  };

  const handleViewReportConsolidated = (
    event: SyntheticEvent,
    caseId: string
  ) => {
    event.stopPropagation();

    globalNavigateTo(`/ops-platform/report-consolidated/${caseId}`, navigate);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Use this to initialize userId from local storage
  useEffect(() => {
    const userAccountDetail = localStorage.getItem(
      LocalStorageData.USER_ACCOUNT_DETAIL
    );
    if (userAccountDetail) {
      const parsedUserDetail = JSON.parse(userAccountDetail);
      setUserId(parsedUserDetail.userId || '');
    }
  }, []);

  useMemo(() => setTotalPage(caseDetails.length), [caseDetails]);

  const renderVerifyIcon = (reportStatus: string) => {
    switch (reportStatus) {
      case REPORT_STATUS.CLEAR:
        return (
          <Stack direction="row" className="gap-1.5">
            <Box>
              <DisplayImage
                imageName={verifyIcon}
                imageType="URL"
                className="mt-0.5"
                height="12px"
                width="12px"
              />
            </Box>
            <Typography className="bK-body2">Clear</Typography>
          </Stack>
        );
      case REPORT_STATUS.DISCREPANT:
        return (
          <Stack direction="row" className="gap-1.5">
            <Box>
              <DisplayImage
                imageName={verifyIconRed}
                className="mt-0.5"
                imageType="URL"
                height="12px"
                width="12px"
              />
            </Box>
            <Typography className="bK-body2">Discrepant</Typography>
          </Stack>
        );
      default:
        return (
          <Typography className="bK-body2">
            {capitalizeString(reportStatus)}
          </Typography>
        );
    }
  };

  const filteredCaseDetails = caseDetails.filter(
    (detail: { ext_case_id: string | string[] }) =>
      detail.ext_case_id.includes(selectedCaseId)
  );

  useEffect(() => {
    setOptions(
      caseDetails.map((detail: { ext_case_id: string }) => detail.ext_case_id)
    );
  }, [caseDetails.length]);
  return (
    <Box className="mt-4">
      <TableContainer className="bk-ops-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          padding="none"
          size="small"
          sx={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '& .MuiTableCell-root': {
              paddingX: '8px !important',
            },
          }}>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              boxShadow: '3px -4px 10px 1px gray',
              backgroundColor: COLORS.bk_bg_primary_smoke,
              '& .MuiTableCell-head': {
                padding: '8px !important',
              },
            }}>
            <TableRow>
              <BKStyledTableCell>
                <Autocomplete
                  value={selectedCaseId}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSelectedCaseId(newValue);
                    } else {
                      setSelectedCaseId('');
                    }
                  }}
                  options={options}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Case ID"
                      className="bk-case-id-search-box"
                    />
                  )}
                />
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Name</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">
                  Mobile Number
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography align="left" className="bk-sub-heading3">
                  {InputLabel.EMAIL}
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">
                  Progress Status
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">
                  Report Status
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Report</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Case Closed</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3"></Typography>
              </BKStyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCaseDetails &&
              (rowsPerPage > 0
                ? filteredCaseDetails.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredCaseDetails
              ).map((caseDetail: any) => (
                <TableRow
                  key={caseDetail.ext_case_id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    '&:hover': { background: COLORS.bk_tag_action_bg },
                  }}
                  onClick={() => handleRowClick(caseDetail)}>
                  <BKStyledTableCell size="small">
                    <Typography className="bK-body2">
                      {caseDetail.ext_case_id}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography className="bK-body2">
                      {caseDetail.actor_id[0]?.name}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography className="bK-body2">
                      {caseDetail.actor_id[0]?.mobile}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography className="bK-body2">
                      {caseDetail.actor_id[0]?.email}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography className="bK-body2">
                      {capitalizeString(caseDetail.order_service_status)}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    {renderVerifyIcon(caseDetail?.report_status)}
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <BKStyledButton
                      variant="text"
                      onClick={event =>
                        handleViewReport(event, caseDetail._id)
                      }>
                      View
                    </BKStyledButton>
                    <BKStyledButton
                      variant="text"
                      onClick={event =>
                        handleViewReportConsolidated(event, caseDetail._id)
                      }>
                      Consolidated Report
                    </BKStyledButton>
                  </BKStyledTableCell>
                  {caseDetail?.reportIntimation ? (
                    <BKStyledTableCell size="small">
                      <Typography className="bK-body2">
                        {globalFormatDate(
                          caseDetail?.reportIntimation[
                            caseDetail?.reportIntimation.length - 1
                          ]?.updatedAt
                        )}
                      </Typography>
                    </BKStyledTableCell>
                  ) : (
                    <BKStyledTableCell size="small">
                      <Typography className="bK-body2"></Typography>
                    </BKStyledTableCell>
                  )}
                  {process.env['NX_OPS_ADMIN']?.includes(userId) &&
                  !ReportStatus.includes(caseDetail?.report_status) ? (
                    <BKStyledTableCell size="small">
                      {caseDetail?.report_status !==
                      OVERALL_STATUS.CANCELATION_REQUESTED ? (
                        <Tooltip title={'Cancel Check'} placement="top" arrow>
                          <IconButton
                            onClick={event => {
                              event.stopPropagation();
                              setIsModalOpen(true);
                              setRequestCase(caseDetail._id.toString());
                            }}>
                            <StopIcon
                              sx={{
                                color: COLORS.bk_stroke_divider,
                                '&:hover': {
                                  color: COLORS.bk_action_pressed,
                                },
                              }}
                              className="cursor-pointer"
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={'Cancelled'} placement="top" arrow>
                          <IconButton
                            onClick={event => {
                              event.stopPropagation(); // This will prevent the event from bubbling up
                            }}>
                            <HourglassTopIcon
                              sx={{
                                color: 'orange', // Set the color to orange
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </BKStyledTableCell>
                  ) : (
                    <BKStyledTableCell size="small"></BKStyledTableCell>
                  )}
                </TableRow>
              ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                colSpan={9}
                count={totalPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="bk-all-cases-table-pagination-grid fixed"
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        fullWidth>
        <DialogTitle>
          <Typography className="bk-headline2">
            Request to cancel check(s)
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className="bk-sub-heading2">
            All the checks for Candidate will be stopped. OPS team will look
            into this and confirm shortly. Client might receive a call from the
            team if any further clarity required.
          </Typography>
          <Box className="buttonBoxRight">
            <BKStyledButton
              variant="text"
              className="text-bk_text_secondary bk-btn-large"
              sx={{ padding: '12px 20px' }}
              onClick={() => {
                setIsModalOpen(false);
              }}>
              No, go back
            </BKStyledButton>
            <BKStyledButton
              variant="contained"
              className="bg-bk_action_primary rounded-lg bk-btn-large button-padding"
              onClick={() => {
                if (requestId !== null) {
                  CancelCase(requestId);
                } else {
                  enqueueSnackbar('Failed fetching owner', {
                    variant: VARIANT_TYPE.ERROR,
                    autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
                  });
                }
              }}>
              Request cancelation
            </BKStyledButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
