import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { DisplayImage } from 'src/app/components';
import { BKStyledButton } from '@gigin-work-space/common-ui';

interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonUrl?: string; // New prop for the primary button URL
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  open,
  onClose,
  title,
  content,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonUrl,
}) => {
  const navigate = useNavigate();

  const handlePrimaryButtonClick = () => {
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    }

    // Check if a URL is provided for the primary button and navigate to it
    if (primaryButtonUrl) {
      globalNavigateTo(primaryButtonUrl, navigate);
    }
  };

  const handleSecondaryButtonClick = () => {
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction={'row'} spacing={1} className="flex items-center">
          <DisplayImage
            imageName="warning_icon.svg"
            imageType="icon"
            width="20px"
            height="18px"
          />
          <Typography className="bk-headline2">{title}</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
        {content}
      </DialogContent>
      <DialogActions>
        {primaryButtonLabel && (
          <BKStyledButton
            variant="contained"
            className="bg-bk_action_primary rounded-lg bK-body1"
            onClick={handlePrimaryButtonClick}>
            {primaryButtonLabel}
          </BKStyledButton>
        )}
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bK-body1"
          onClick={handleSecondaryButtonClick}>
          {secondaryButtonLabel}
        </BKStyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
