/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper, DisplayImage } from '../../../components';

export interface LinkSentSuccessProps {
  emailId: string;
}

export function LinkSentSuccess(props: LinkSentSuccessProps) {
  const { emailId } = props;
  const navigate = useNavigate();
  return (
    <section className="absolute bottom-[-11%] left-[-160px]">
      <AuthenticationPaper>
        <Typography className="bk-headline1 text-bk_text_primary">
          Link sent successfully
        </Typography>
        <Typography className="bK-body1 text-bk_text_secondary mt-1">
          A link to reset your password has been sent to {emailId}. Please open
          your mail and click the link to reset password
        </Typography>
        <DisplayImage
          imageName="reset_password_sent_illustration.svg"
          imageType="image"
          className="flex justify-center items-center object-contain ml-9"
        />
        {/* <BKStyledButton
          className="rounded-lg"
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => globalNavigateTo('/login', navigate)}>
          Go to login
        </BKStyledButton> */}
      </AuthenticationPaper>
    </section>
  );
}

export default LinkSentSuccess;
