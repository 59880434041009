import { IProgressStatusProps } from '@gigin-work-space/model';
import {
  COLORS,
  ORDER_FLOW_STATUS,
  PROGRESS_STATUS,
  getStatusBgColor,
  getStatusColor,
  hasCheckStatusError,
} from '@gigin-work-space/utils';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import { ProgressLineSVG } from './progress-line';

export const QualityCheckSVG: React.FC<IProgressStatusProps> = ({ message, status }) => {
  const color = getStatusColor(status);
  const bgColor = getStatusBgColor(status);
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={message ? message : ORDER_FLOW_STATUS.QUALITY_CHECK} placement="top" arrow>
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="13"
            cy="13"
            r="12.5"
            fill={bgColor}
            stroke={status.toUpperCase() === PROGRESS_STATUS.PENDING ? COLORS.bk_tag_gray_bg : color}
          />
          <path
            d="M18.9998 11.0939C18.9998 7.73273 16.3065 5 12.9999 5C9.6933 5 7 7.73296 7 11.0939C7 12.9308 7.80899 14.5764 9.07738 15.696L7.16381 18.6176C7.0954 18.7218 7.09292 18.8584 7.16133 18.9651C7.22727 19.0719 7.34957 19.1265 7.47176 19.1091L9.30229 18.8211L9.74469 20.6481C9.77405 20.7697 9.87183 20.8641 9.99402 20.884C10.0111 20.8865 10.0307 20.8889 10.0478 20.8889C10.1504 20.8889 10.2507 20.8368 10.3093 20.7474L12.6531 17.1704C12.768 17.1778 12.8829 17.1878 12.9978 17.1878C13.1151 17.1878 13.2275 17.1778 13.3424 17.1704L15.6862 20.7474C15.7448 20.8368 15.8426 20.8889 15.9477 20.8889C15.9648 20.8889 15.9844 20.8864 16.0015 20.884C16.1237 20.8617 16.2215 20.7698 16.2508 20.6481L16.6932 18.8211L18.5238 19.1091C18.6459 19.129 18.7681 19.0719 18.8342 18.9651C18.9001 18.8584 18.9001 18.7218 18.8317 18.6176L16.9156 15.696C18.191 14.5765 19 12.9307 19 11.0939H18.9998ZM10.1843 19.7917L9.84213 18.3843C9.80297 18.2254 9.64905 18.1212 9.49017 18.146L8.07753 18.3694L9.57322 16.0882C10.2747 16.5846 11.0812 16.9321 11.9561 17.0886L10.1843 19.7917ZM17.9219 18.3669L16.5093 18.1435C16.3504 18.1187 16.1965 18.2204 16.1573 18.3818L15.8152 19.7892L14.0433 17.0885C14.9157 16.9321 15.7247 16.5846 16.4261 16.0881L17.9219 18.3669ZM12.9996 16.5499C10.0374 16.5499 7.62534 14.1024 7.62534 11.0915C7.62534 8.08285 10.0351 5.63302 12.9996 5.63302C15.9618 5.63302 18.3739 8.08051 18.3739 11.0915C18.3739 14.1024 15.9617 16.5499 12.9996 16.5499Z"
            fill={color}
          />
          <path
            d="M17.6484 10.2163C17.6063 10.1099 17.4941 10.0313 17.3595 10.0151L14.5706 9.67972L13.3221 7.59622C13.2014 7.39501 12.8002 7.39501 12.6767 7.59622L11.4282 9.67972L8.63927 10.0151C8.50454 10.0313 8.39243 10.1099 8.35032 10.2163C8.30821 10.3226 8.34476 10.4406 8.44009 10.5191L10.4602 12.1402L9.98314 14.4318C9.96066 14.5429 10.0168 14.6561 10.1263 14.7208C10.2385 14.7879 10.3843 14.7948 10.505 14.7439L13.0021 13.6617L15.4992 14.7439C15.5525 14.767 15.6086 14.7786 15.6675 14.7786C15.7405 14.7786 15.8162 14.7601 15.8779 14.7232C15.9873 14.6561 16.0435 14.5451 16.021 14.4342L15.544 12.1425L17.5641 10.5193C17.6568 10.4428 17.6906 10.3249 17.6484 10.2163ZM14.9017 11.828C14.8175 11.895 14.7782 11.9945 14.7978 12.0892L15.1822 13.9415L13.1622 13.0674C13.1088 13.0443 13.0528 13.0327 12.9939 13.0327C12.935 13.0327 12.8788 13.0443 12.8256 13.0674L10.8055 13.9415L11.1899 12.0892C11.2095 11.9921 11.1702 11.8949 11.086 11.828L9.45307 10.5168L11.7117 10.2463C11.8295 10.2325 11.9305 10.1723 11.981 10.0845L12.9911 8.39874L14.0013 10.0845C14.0546 10.1723 14.1556 10.2325 14.2706 10.2463L16.5292 10.5168L14.9017 11.828Z"
            fill={color}
          />
          {hasCheckStatusError(status) && (
            <path
              d="M15.7929 29C16.2383 29 16.4614 28.4614 16.1464 28.1464L13.3536 25.3536C13.1583 25.1583 12.8417 25.1583 12.6464 25.3536L9.85355 28.1464C9.53857 28.4614 9.76165 29 10.2071 29H15.7929Z"
              fill={color}
            />
          )}
        </svg>
      </Tooltip>
      {status.toUpperCase() === PROGRESS_STATUS.INPROGRESS ||
      status.toUpperCase() === PROGRESS_STATUS.FAIL ||
      status.toUpperCase() === PROGRESS_STATUS.PENDING ||
      status.toUpperCase() === PROGRESS_STATUS.BLOCKED ? (
        <ProgressLineSVG color={COLORS.bk_stroke_divider} />
      ) : (
        <ProgressLineSVG color={color} />
      )}{' '}
    </Stack>
  );
};
