import { EMAIL_DOMAIN } from '@gigin-work-space/utils';
import i18next from 'i18next';
import * as Yup from 'yup';

// Object Schema validation for employer details signup page
export const RegisterSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(2, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  userPassword: Yup.string()
    .required(i18next.t('bk_password_check') as string)
    .min(8, i18next.t('bk_password_check') as string)
    .matches(
      /^(?=.*[a-zA-Z])/,
      i18next.t('bk_password_one_lowercase') as string
    )
    .matches(/^(?=.*[0-9])/, i18next.t('bk_password_one_number') as string)
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      i18next.t('bk_password_one_special') as string
    ),
  userConfirmPassword: Yup.string()
    .required(i18next.t('bk_password_retype') as string)
    .oneOf(
      [Yup.ref('userPassword')],
      i18next.t('bk_password_doesnot_match') as string
    ),
});

// Object Schema validation for employer details after Google signup
export const GoogleRegisterSchema = Yup.object().shape({
  hireOption: Yup.string().required('Please select one of the options'),
  userName: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(5, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  companyName: Yup.string().required(
    i18next.t('bk_company_name_required') as string
  ),
});

// Object Schema validation for employer details login page
export const LoginSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string),
  userPassword: Yup.string()
    .required(i18next.t('bk_password_required') as string)
    .min(3, i18next.t('bk_password_min_length') as string),
});

export const SelfSignUpSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(3, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  userEmail: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string)
    .test('domain-check', 'Domain not allowed', function (value) {
      const emailDomain = value?.split('@')[1]; // Safely access the value
      return emailDomain ? !EMAIL_DOMAIN.includes(emailDomain) : false;
    }),
});

export const AccountRegistrationSchema = Yup.object().shape({
  account: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(3, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  name: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(3, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),

  email: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string)
    .test('domain-check', 'Domain not allowed', function (value) {
      const emailDomain = value?.split('@')[1]; // Safely access the value
      return emailDomain ? !EMAIL_DOMAIN.includes(emailDomain) : false;
    }),

  mobile: Yup.string()
    .required(i18next.t('bk_mobile_required') as string)
    .test('phone-check', 'incorrect mobile no', function (value) {
      return value?.match(/^\d{10}$/) ? true : false;
    }),

  designation: Yup.string().required(
    i18next.t('bk_designation_required') as string
  ),

  address: Yup.string().required(i18next.t('bk_signup_company_name') as string),

  gst: Yup.string().required(i18next.t('bk_signup_company_name') as string),
});

export const SelfSignUpOtpSchema = Yup.object({
  otp: Yup.string()
    .length(6, 'OTP must be exactly 6 characters')
    .required('OTP is required'),
});

// Object Schema validation for employer details reset password page
export const ResetPasswordSchema = Yup.object().shape({
  userPassword: Yup.string()
    .required(i18next.t('bk_password_check') as string)
    .min(8, i18next.t('bk_password_check') as string)
    .matches(
      /^(?=.*[a-zA-Z])/,
      i18next.t('bk_password_one_lowercase') as string
    )
    .matches(/^(?=.*[0-9])/, i18next.t('bk_password_one_number') as string)
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      i18next.t('bk_password_one_special') as string
    ),
  userConfirmPassword: Yup.string()
    .required(i18next.t('bk_password_retype') as string)
    .oneOf([Yup.ref('userPassword')], 'Confirm password do not match'),
});

// Object Schema validation for company info page
export const CompanyInfoSchema = Yup.object().shape({
  accountName: Yup.string().required(
    i18next.t('bk_signup_company_name') as string
  ),

  address: Yup.string().required(i18next.t('bk_signup_company_name') as string),

  gstInNo: Yup.string().required(i18next.t('bk_signup_company_name') as string),
});

// Object Schema validation for company info page
export const UserInfoSchema = Yup.object().shape({
  userName: Yup.string().required(
    i18next.t('bk_signup_company_name') as string
  ),

  designation: Yup.string().required(
    i18next.t('bk_designation_required') as string
  ),

  mobile: Yup.string()
    .required(i18next.t('bk_mobile_required') as string)
    .test('phone-check', 'incorrect mobile no', function (value) {
      return value?.match(/^\d{10}$/) ? true : false;
    }),
  email: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string),
});

// Object Schema validation for employer details login page
export const ForgotPasswordSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string),
});

// Mail Invitee details schema
export const MailInviteeSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(5, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  companyName: Yup.string().optional(),
  workEmail: Yup.string()
    .email(i18next.t('bk_email_invalid') as string)
    .optional(),
});

// Link Invitee details schema
export const LinkInviteeSchema = Yup.object().shape({
  userName: Yup.string()
    .required(i18next.t('bk_username_required') as string)
    .min(5, i18next.t('bk_username_min_length') as string)
    .max(100, i18next.t('bk_username_max_length') as string),
  workEmail: Yup.string()
    .required(i18next.t('bk_email_required') as string)
    .email(i18next.t('bk_email_invalid') as string),
});

// Candidate filter schema
export const CandidateFilterSchema = Yup.object().shape({
  filter: Yup.object({
    status: Yup.string(),
    role: Yup.string(),
    recent: Yup.string(),
  }),
});
