import { IDBPDatabase, openDB } from 'idb';
import { EnumIndexedDbStore } from './enums';

const DB_NAME = 'gigin-mono-db';
const DB_VERSION = 1.0;

let dbPromise: Promise<IDBPDatabase>;

const initDB = async () => {
  if (!dbPromise) {
    dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        // Create object stores here if they don't exist
        if (!db.objectStoreNames.contains(EnumIndexedDbStore.FILES)) {
          db.createObjectStore(EnumIndexedDbStore.FILES);
        }
      },
    });
  }
  return dbPromise;
};

export const getItem = async <T>(storeName: string, key: IDBValidKey): Promise<T | undefined> => {
  const db = await initDB();
  return db.get(storeName, key);
};

export const setItem = async <T>(storeName: string, key: IDBValidKey, value: T): Promise<void> => {
  const db = await initDB();
  await db.put(storeName, value, key);
};

export const deleteItem = async (storeName: string, key: IDBValidKey): Promise<void> => {
  const db = await initDB();
  await db.delete(storeName, key);
};

export const clearStore = async (storeName: string): Promise<void> => {
  const db = await initDB();
  await db.clear(storeName);
};

export const getAllItems = async <T>(storeName: string): Promise<T[]> => {
  const db = await initDB();
  return db.getAll(storeName);
};

export const clearIndexedDBEntries = async (storeName: string) => {
  try {
    await clearStore(storeName);
  } catch (error) {
    console.error('Failed to clear files from IndexedDB:', error);
  }
};
