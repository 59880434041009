import { COLORS } from '@gigin-work-space/utils';
import { Box } from '@mui/material';

/* eslint-disable-next-line */
export interface PasswordStrengthBarProps {
  strength: number | null;
  barCount?: number;
  colors?: {
    weak: string;
    average: string;
    strong: string;
    default: string;
  };
  barHeight?: string;
  barSpacing?: string;
}

export function PasswordStrengthBar({
  strength,
  barCount = 4,
  colors = {
    weak: COLORS.bk_alert_failure,
    average: COLORS.bk_alert_new,
    strong: COLORS.bk_alert_success,
    default: COLORS.bk_stroke_secondary,
  },
  barHeight = '8px',
  barSpacing = '0.5rem',
}: PasswordStrengthBarProps) {
  const getColor = (index: number) => {
    if (strength === null) return colors.default;
    if (strength >= index) {
      if (strength === 4) return colors.strong;
      if (strength === 3) return colors.average;
      return colors.weak;
    }
    return colors.default;
  };

  return (
    <Box display="flex" alignItems="center" mt={2}>
      {Array.from({ length: barCount }, (_, index) => (
        <Box
          key={index}
          width={`${100 / barCount}%`}
          height={barHeight}
          bgcolor={getColor(index + 1)}
          mx={barSpacing}
          borderRadius="4px"
        />
      ))}
    </Box>
  );
}

export default PasswordStrengthBar;
