import {
  BlockedCandidateCheck,
  CompleteCandidateCheck,
  InprogressCandidateCheck,
  InprogressCheckLine,
  PendingCandidateCheck,
  PendingCheckLine,
} from '@gigin-work-space/common-ui';
import { CheckStatus } from '@gigin-work-space/model';
import {
  COLORS,
  OVERALL_STATUS,
  isBlockedorFailed,
} from '@gigin-work-space/utils';
import { Stack, Typography } from '@mui/material';

const SVGRender = ({
  step,
  total,
  index,
}: {
  step: CheckStatus;
  total: number;
  index: number;
}) => {
  return (
    <Stack direction={'column'} spacing={0.5}>
      <Stack direction={'row'}>
        {/* render Icon */}
        <Stack direction={'row'}>
          {step.status.toUpperCase() === OVERALL_STATUS.BLOCKED ||
          step.status.toUpperCase() === OVERALL_STATUS.FAIL ? (
            <BlockedCandidateCheck status={step.status} />
          ) : step.status.toUpperCase() === OVERALL_STATUS.INPROGRESS ? (
            <InprogressCandidateCheck status={step.status} />
          ) : step.status.toUpperCase() === OVERALL_STATUS.SUCCESS ? (
            <CompleteCandidateCheck status={step.status} />
          ) : (
            <PendingCandidateCheck status={step.status} />
          )}
        </Stack>
        {/* RenderLine */}
        {total - 1 !== index ? (
          <Stack direction={'row'}>
            {step.status.toUpperCase() === OVERALL_STATUS.SUCCESS ? (
              <InprogressCheckLine />
            ) : (
              <PendingCheckLine />
            )}
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <Stack direction={'column'}>
        <Typography
          className="bK-body3 "
          style={{
            color: isBlockedorFailed(step.status)
              ? COLORS.bk_alert_failure
              : COLORS.bk_text_primary,
          }}>
          {step.title}
        </Typography>
        <Typography
          className="bK-hint-text text-bk_text_secondary"
          style={{
            color: isBlockedorFailed(step.status)
              ? COLORS.bk_alert_failure
              : COLORS.bk_text_primary,
          }}>
          {step.message}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ProgressIndividualCandidate = ({
  steps,
}: {
  steps: CheckStatus[];
}) => {
  return (
    <Stack direction={'row'}>
      {steps
        .sort((a, b) => a.step - b.step) // handeling sorting order from api
        .map((step, index) => {
          return <SVGRender step={step} total={steps.length} index={index} />;
        })}
    </Stack>
  );
};
