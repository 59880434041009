/* eslint-disable no-unused-vars */
import {
  BKBody1,
  BKSubHeading2,
  COLORS,
  guardedOpsTab,
  guardedSettingsTab,
  guardedVerificationTab,
  pathSpliter,
} from '@gigin-work-space/utils';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SecurityIcon from '@mui/icons-material/Security';
import {
  ListSubheader,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { DisplayImage } from '../../../components';

const drawerWidth = 196;

export const BgvSideBar = ({
  isSideBaseActive,
  updateSideBaseStatus,
}: {
  isSideBaseActive: boolean;
  updateSideBaseStatus(x: boolean): void;
}) => {
  const [open, setOpen] = React.useState(isSideBaseActive);
  const location = useLocation();

  // theme Break points for responsive
  const theme = useTheme();
  // const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [selectionStatus, setSelectionStatus] = React.useState(
    location.pathname.endsWith('/bgv')
      ? 'verification'
      : pathSpliter(location.pathname as string)
  );
  const navigate = useNavigate();

  const userAccountDetails = JSON.parse(
    localStorage.getItem('userAccountDetail') as string
  );

  const hanldeTabChange = (text: string): void => {
    //TODO: Need to change because this can not allow to improve
    const endRoute = text.toLowerCase().replace(/\s/g, '-');
    setSelectionStatus(endRoute);
    if (text.toLowerCase() === 'verification') {
      const temp = `/bgv`;
      globalNavigateTo(temp, navigate);
    } else {
      const temp = `/bgv/${endRoute}`;
      globalNavigateTo(temp, navigate);
    }
  };

  /**
   * Handles the navigation to the Ops Platform with the given text.
   *
   * @param {string} text - The text to navigate to.
   * @returns {void}
   */
  const handleNavigateOpsPlatform = (text: string): void => {
    updateSideBaseStatus(false);
    //hanldeTabChange(text);
    //TODO: Need to change because this can not allow to improve
    const endRoute = text.toLowerCase().replace(/\s/g, '-');
    globalNavigateTo(`/${endRoute}`, navigate);
  };

  const iconGenerator = (text: string): JSX.Element => {
    switch (text.toLowerCase().replace(/\s/g, '-')) {
      case 'verification':
        return (
          <SecurityIcon
            className={`w-lg2 h-lg2 ${
              selectionStatus === 'verification'
                ? 'text-bk_bg_primary_dark'
                : 'text-bk_text_secondary2'
            }`}
          />
        );

      case 'candidates':
        return (
          <DisplayImage
            imageName={
              selectionStatus === 'candidates'
                ? 'candidate-icon-selected.svg'
                : 'candidate-icon.svg'
            }
            imageType="icon"
            width="12px"
            height="12px"
          />
        );

      case 'analytics':
        return (
          <AssessmentIcon
            className={`w-lg2 h-lg2 ${
              selectionStatus === 'analytics'
                ? 'text-bk_bg_primary_dark'
                : 'text-bk_text_secondary2'
            }`}
          />
        );
      case 'company-settings':
        return (
          <ApartmentIcon
            className={`w-lg2 h-lg2 ${
              selectionStatus === 'company-settings'
                ? 'text-bk_bg_primary_dark'
                : 'text-bk_text_secondary2'
            }`}
          />
        );

      case 'subscriptions':
        return (
          <DisplayImage
            imageName={
              selectionStatus === 'subscriptions'
                ? 'subscription-icon-selected.svg'
                : 'subscription-icon.svg'
            }
            imageType="icon"
            width="12px"
            height="12px"
          />
        );

      case 'ops-platform':
        return (
          <DisplayImage
            imageName={
              selectionStatus === 'ops-platform'
                ? 'subscription-icon-selected.svg'
                : 'subscription-icon.svg'
            }
            imageType="icon"
            width="12px"
            height="12px"
          />
        );

      default:
        return (
          <DisplayImage
            imageName={
              selectionStatus === 'verification'
                ? 'bgv-dashboard-icon-selected.svg'
                : 'bgv-dashboard-icon.svg'
            }
            imageType="icon"
            width="12px"
            height="12px"
          />
        );
    }
  };
  React.useEffect(() => {
    setOpen(isSideBaseActive);
  }, [isSideBaseActive]);

  React.useMemo(() => {
    setSelectionStatus(
      location.pathname.endsWith('/bgv')
        ? 'verification'
        : pathSpliter(location.pathname as string)
    );
  }, [location.pathname]);
  return (
    <Box className="bk-bgv-sidebar">
      <Drawer
        variant={
          isNotLargeScreen || location.pathname.includes('/bgv/create-request')
            ? 'temporary'
            : 'permanent'
        }
        //    variant="permanent"
        sx={{
          width: open ? drawerWidth : '60px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: 'border-box',
            borderWidth: 0,
            position: 'initial',
            background: `${COLORS.bk_bg_primary}`,
          },
        }}
        onClose={() => {
          updateSideBaseStatus(false);
        }}
        open={open}>
        <Box>
          {/* User Profile Card */}
          {open ? (
            <Paper
              elevation={0}
              className="py-2 px-3 border border-bk_stroke_primary rounded-[8px]">
              <Stack direction={'row'} spacing={1}>
                <DisplayImage
                  imageName={userAccountDetails?.company_logo}
                  imageType="url"
                  width="32px"
                  height="32px"
                  className="rounded-full"
                />
                <Stack width={'68%'}>
                  <Typography className="bk-sub-heading2 truncate">
                    {userAccountDetails?.name}
                  </Typography>
                  <Typography className="bK-body2 text-bk_text_secondary truncate mt-1">
                    {userAccountDetails?.account_name}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          ) : (
            <Box className="flex justify-center mb-2">
              <DisplayImage
                imageName={userAccountDetails?.company_logo}
                imageType="url"
                width="32px"
                height="32px"
                className="rounded-full"
              />
            </Box>
          )}

          {/* <Toolbar /> */}
          {/* MENU SECTION START */}
          {guardedVerificationTab().length ? (
            <List
              subheader={
                <Box>
                  {open ? (
                    <ListSubheader
                      component="div"
                      className="bg-transparent pt-6 pr-6 pb-3 pl-6">
                      <Typography className="bK-body2 text-bk_text_secondary">
                        Menu
                      </Typography>
                    </ListSubheader>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              }>
              {guardedVerificationTab().map((text, index) => (
                <ListItem
                  onClick={() => hanldeTabChange(text)}
                  key={text}
                  disablePadding
                  sx={{
                    display: 'block',
                  }}
                  style={
                    selectionStatus === text.toLowerCase().replace(/\s/g, '-')
                      ? {
                          borderLeft: `4px solid ${COLORS.bk_action_primary}`,
                          backgroundColor: `${COLORS.bk_tag_action_bg}`,
                        }
                      : { borderLeft: '4px solid transparent' }
                  }>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}>
                      {iconGenerator(text)}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={
                        selectionStatus === text ? BKSubHeading2 : BKBody1
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <></>
          )}
          {/* MENU SECTION END*/}
          {guardedSettingsTab().length ? (
            <Divider sx={{ margin: '0 8px' }} />
          ) : (
            <></>
          )}
          {/* ACCOUNT SECTION START */}
          {guardedSettingsTab().length ? (
            <List
              subheader={
                <Box>
                  {open ? (
                    <ListSubheader
                      component="div"
                      className="bg-transparent pt-6 pr-6 pb-3 pl-6">
                      <Typography className="bK-body2 text-bk_text_secondary">
                        Account
                      </Typography>
                    </ListSubheader>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              }>
              {guardedSettingsTab().map((text, index) => (
                <ListItem
                  onClick={() => hanldeTabChange(text)}
                  key={text}
                  disablePadding
                  sx={{
                    display: 'block',
                  }}
                  style={
                    location.pathname.includes('company-settings')
                      ? {
                          borderLeft: `4px solid ${COLORS.bk_action_primary}`,
                          backgroundColor: `${COLORS.bk_tag_action_bg}`,
                        }
                      : { borderLeft: '4px solid transparent' }
                  }>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}>
                      {iconGenerator(text)}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={
                        location.pathname.includes('company-settings')
                          ? BKSubHeading2
                          : BKBody1
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <></>
          )}
          {/* ACCOUNT SECTION END */}
          {guardedOpsTab().length ? (
            <Divider sx={{ margin: '0 8px' }} />
          ) : (
            <></>
          )}
          {/* OPS SECTION START */}
          {guardedOpsTab().length ? (
            <List
              subheader={
                <Box>
                  {open ? (
                    <ListSubheader
                      component="div"
                      style={{
                        background: 'transparent',
                        padding: '24px 24px 12px',
                      }}>
                      <Typography className="bK-body2 text-bk_text_secondary">
                        Operations
                      </Typography>
                    </ListSubheader>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              }>
              {guardedOpsTab().map((text, index) => (
                <ListItem
                  onClick={() => handleNavigateOpsPlatform(text)}
                  key={text}
                  disablePadding
                  sx={{
                    display: 'block',
                  }}
                  style={
                    selectionStatus === text.toLowerCase().replace(/\s/g, '-')
                      ? {
                          borderLeft: `4px solid ${COLORS.bk_action_primary}`,
                          backgroundColor: `${COLORS.bk_tag_action_bg}`,
                        }
                      : { borderLeft: '4px solid transparent' }
                  }>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}>
                      {iconGenerator(text)}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={
                        selectionStatus === text ? BKSubHeading2 : BKBody1
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <></>
          )}
          {/* OPS SECTION END */}
        </Box>
        {open && (
          <Stack
            direction={'row'}
            paddingLeft="24px"
            alignItems={'center'}
            className="fixed bottom-6">
            <Typography className="bK-body3 text-bk_text_secondary self-start mt-[2px]">
              Powered by{' '}
            </Typography>
            <DisplayImage
              imageName="gigin_logo.svg"
              imageType="image"
              width="32px"
              height="15px"
              className="ml-1"
            />
          </Stack>
        )}
      </Drawer>
    </Box>
  );
};
