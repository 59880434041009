/* eslint-disable no-unused-expressions */
import { CheckoutStore } from '@gigin-work-space/store';
import {
  COLORS,
  isNotEmpty,
  isValidEmail,
  isValidMobileNumber,
} from '@gigin-work-space/utils';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { useSnapshot } from 'valtio';
import { CustomTableCellProps } from './IEditable-types';

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  placeholder,
  id,
  cell,
  cellError,
  handleSaveCell,
  type,
}: CustomTableCellProps) => {
  const { candidateData } = useSnapshot(CheckoutStore);
  const [isEditing, setIsEditing] = useState(false);
  const tempCandidate =
    CheckoutStore.candidateData[id] && CheckoutStore.candidateData[id];
  // theme Break points for responsive
  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const handleCellClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (candidateData[id][cell] !== value && CheckoutStore.candidateData[id]) {
      if (type === 'email')
        CheckoutStore.candidateData[id][cell] =
          event.target.value.toLowerCase();
      if (type === 'mobile') {
        const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        CheckoutStore.candidateData[id][cell] = sanitizedValue;
      } else CheckoutStore.candidateData[id][cell] = event.target.value;
      // CheckoutStore.candidateData[id].isRowEditing = true;
    }
  };

  // validate mobile and email
  const handleValidation = () => {
    if (type === 'mobile') {
      isValidMobileNumber(tempCandidate[cell]) &&
        (tempCandidate[cellError] = '');
    } else if (type === 'email') {
      isValidEmail(tempCandidate[cell]) && (tempCandidate[cellError] = '');
    } else {
      isNotEmpty(tempCandidate[cell]) && (tempCandidate[cellError] = '');
    }
  };
  const handleInputBlur = () => {
    handleValidation();
    handleSaveCell(id);
    setIsEditing(false);
  };

  return (
    <TableCell
      onClick={handleCellClick}
      align="left"
      sx={{
        maxWidth: isNotExtraLargeScreen ? '6em' : '8em',
        minWidth: isNotExtraLargeScreen ? '6em' : '8em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        overflowX: 'scroll',
      }}
      className="text-ellipsis">
      {isEditing ? (
        <TextField
          sx={{
            '& .MuiOutlinedInput-input': {
              padding: '4px 8px',
            },
          }}
          size="small"
          value={candidateData[id][cell]}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          // to hanlde when mobile it should not go more than 10 character
          onKeyDown={(event: any) => {
            const { key } = event;
            if (type === 'mobile' && event.target.value.length >= 10) {
              if (key !== 'Backspace' && key !== 'Delete') {
                event.preventDefault();
              }
            }
          }}
          autoFocus
          inputProps={{
            maxLength: type === 'mobile' ? 10 : 50,
            type: 'text', // Input type set to number
            pattern: type === 'mobile' ? '[0-9]*' : undefined, // Use pattern for mobile field
            inputMode: type === 'mobile' ? 'numeric' : undefined, // Set input mode for mobile field
          }}
          className="p-0 m-0"
        />
      ) : tempCandidate?.[cell] ? (
        <Typography
          className="flex items-center bK-body2 text-ellipsis"
          sx={{
            color: tempCandidate?.[cellError]?.length
              ? COLORS.bk_alert_failure
              : '',
          }}>
          {tempCandidate?.[cellError] && (
            <Box marginRight={'10px'}>
              <DisplayImage
                imageName="error-value-icon.svg"
                imageType="icon"
                width="9.6px"
                height="9.6px"
              />
            </Box>
          )}

          {tempCandidate?.[cell]}
        </Typography>
      ) : (
        <span style={{ color: 'gray' }}>{placeholder}</span>
      )}
      {/* TODO: error */}
      {tempCandidate?.[cellError] && (
        <div
          className="bK-body3 text-ellipsis flex-none"
          style={{ color: COLORS.bk_alert_failure }}>
          {tempCandidate?.[cellError]}
        </div>
      )}
    </TableCell>
  );
};
export default CustomTableCell;
