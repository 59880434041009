import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

export interface AuthenticationHOCProps {
  children: React.ReactNode;
}

export const AuthenticationHOCForSignup = (props: AuthenticationHOCProps) => {
  const { children } = props;
  const navigate = useNavigate();
  return (
    <Grid
      container
      className="auth-hoc-container"
      display="flex"
      justifyContent="center"
      alignItems="center">
      {children}
      <Typography className="absolute bottom-6 right-8 bk-btn-large mt-6">
        <span
          className="text-bk_tag_blue_light cursor-pointer"
          onClick={() => globalNavigateTo('/terms_condition', navigate)}>
          Terms & Conditions
        </span>
        <span className="text-bk_text_primary">&</span>
        <span
          className="text-bk_tag_blue_light cursor-pointer"
          onClick={() => globalNavigateTo('/privacy_policy', navigate)}>
          Privacy policy
        </span>
      </Typography>
    </Grid>
  );
};
