/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  BKStyledButton,
  QRCodeBox,
  StyledDialog,
  StyledList,
} from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import {
  COLORS,
  ConsentType,
  SPACING,
  useLocalStorage,
} from '@gigin-work-space/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { withViewPermission } from 'src/app/HOC';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';

export interface CompanyPreferencesProps {}

interface Policies {
  type: string;
  mobileVerification: boolean;
  selfDeclaration: {
    signType: string;
    documentUrl: string;
  };
}
export interface AccountPreference {
  _id: string;
  preferences: {
    policies: Policies[];
  };
}

type consentType =
  | ConsentType.OTP_VERIFICATION
  | ConsentType.E_SIGN
  | ConsentType.MANUAL
  | null;

const mfaSchema = yup.object().shape({
  mfaCode: yup
    .string()
    .matches(/^\d{6}$/, 'MFA code must be exactly 6 digits')
    .required('MFA code is required'),
});

export const CompanyPreferences = () => {
  const { canReadPreference, canCreatePreference, canUpdatePreference } =
    useSnapshot(permissionStore);
  const [preference, setPreferences] = useState<AccountPreference>();
  const [policies, setPolicies] = useState<Policies>();
  const [selectedValue, setSelectedValue] = useState<consentType>(null);
  const [openMfaModal, setOpenMfaModal] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [totpId, setTotpId] = useState<string | null>(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [loadingQrCode, setLoadingQrCode] = useState(false);

  const [userDetails, setUserDetails] = useLocalStorage(
    'userAccountDetail',
    {}
  );

  const navigate = useNavigate();

  interface FormData {
    mfaCode: string;
  }

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(mfaSchema) as unknown as Resolver<FormData>,
  });

  const getPreferencesList = async () => {
    try {
      const response = await axiosInstance.get(endpoints.PREFERENCE_POLICY);
      if (response.status === HttpStatusCode.Ok) {
        setPreferences(response.data);
        const selectedPolicy =
          response.data?.preferences?.policies[0]?.selfDeclaration?.signType;
        if (selectedPolicy) {
          setPolicies(response.data?.preferences?.policies[0]);
          setSelectedValue(
            response.data?.preferences?.policies[0]?.selfDeclaration
              ?.signType || ConsentType.OTP_VERIFICATION
          );
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to get preferences details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedValue(value as ConsentType);
    updatePreferences(value);
  };

  const updatePreferences = async (value: string) => {
    try {
      const data = {
        preferences: {
          policies: [
            {
              type: policies?.type,
              mobileVerification: true,
              selfDeclaration: {
                signType: value,
                documentUrl: 'URL',
              },
            },
          ],
        },
      };
      const response = await axiosInstance.patch(
        `${endpoints.ACCOUNT_PREFERENCES}/${preference?._id}`,
        data
      );
      if (response.status === HttpStatusCode.Ok) {
        enqueueSnackbar(`Consent preference update successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
      setSelectedValue(value as ConsentType);
    } catch (error) {
      enqueueSnackbar('Failed to update preferences details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const initiateMfaSetup = useCallback(async () => {
    const response = await axiosInstance.post(
      endpoints.POST_AUTH_INITIATE_TOTP
    );
    if (response?.data?.success) {
      setQrCode(response?.data?.data?.qr_code);
      setTotpId(response?.data?.data?.totp_id);
    } else {
      throw new Error('Failed to initiate MFA setup, Please try after 30min');
    }
  }, []);

  useEffect(() => {
    getPreferencesList();
  }, []);

  const handleOpenMfaModal = async () => {
    setOpenMfaModal(true);
  };

  const handleCloseMfaModal = () => {
    setOpenMfaModal(false);
    setQrCode(null);
    setTotpId(null);
    clearErrors('mfaCode');
  };

  const handleShowQrCode = useCallback(async () => {
    setLoadingQrCode(true);
    try {
      await initiateMfaSetup();
      setShowQrCode(true);
    } catch (error) {
      enqueueSnackbar(
        'MFA set up can be tried only once in 30 mins. Please try after few moments',
        {
          variant: 'error',
          autoHideDuration: 2000,
        }
      );
    } finally {
      setLoadingQrCode(false);
    }
  }, [initiateMfaSetup]);

  const handleAddMfa = async (data: { mfaCode: string }) => {
    try {
      const { data: response } = await axiosInstance.post(
        endpoints.POST_AUTH_REGISTER_TOTP_DEVICE,
        {
          totpDeviceId: totpId,
          totpCode: data.mfaCode,
        }
      );
      if (response?.success) {
        enqueueSnackbar('MFA setup successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        // close modal and logout
        handleCloseMfaModal();
        localStorage.clear();
        globalNavigateTo('/login', navigate);
      } else {
        throw new Error('Invalid MFA code');
      }
    } catch (error) {
      setError('mfaCode', {
        type: 'manual',
        message: 'Invalid MFA code',
      });
    }
  };

  const children = (
    <Box className="w-full h-full mt-6 px-6">
      <Typography className="bk-headline2">Preferences</Typography>
      <Typography
        className={`bk-sub-heading1 mt-12 mb-4 ${COLORS.bk_text_primary}`}>
        Candidate data collection consent
      </Typography>
      <FormControl
        sx={{
          border: `1px solid ${COLORS.bk_stroke_primary}`,
        }}
        className="bk-preferences">
        {selectedValue && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedValue}
            onChange={handleChange}
            name="radio-buttons-group">
            <FormControlLabel
              className="bk-sub-heading3"
              value={ConsentType.OTP_VERIFICATION}
              control={<Radio />}
              label="Consent with Mobile OTP"
            />
            <Typography className={`bK-body2 pl-8 ${COLORS.bk_text_secondary}`}>
              Candidate reads the consent form and verifies with an One-Time
              Password sent to their mobile number
            </Typography>
            <FormControlLabel
              className="bk-sub-heading3 mt-4"
              value={ConsentType.E_SIGN}
              control={<Radio />}
              label="E-Signature from candidate"
            />
            <Typography className={`bK-body2 pl-8 ${COLORS.bk_text_secondary}`}>
              Candidate reads the consent form and signs the document digitally
            </Typography>
            <FormControlLabel
              value={ConsentType.MANUAL}
              className="bk-sub-heading3 mt-4"
              control={<Radio />}
              label="Ink Signature from candidate"
            />
            <Typography className={`bK-body2 pl-8 ${COLORS.bk_text_secondary}`}>
              Candidate downloads, prints and reads the consent form. Then Signs
              the form with ink & uploads back on platform to verify
            </Typography>
          </RadioGroup>
        )}
      </FormControl>
      {/* MFA Section */}
      <Box className="mt-xl2">
        <Typography className="bk-sub-heading1">
          Set up MFA{' '}
          {userDetails?.is2faEnabled && <span>(Already Enabled)</span>}
        </Typography>
        <Box className="flex flex-row justify-between items-center gap-x-sm mt-sm">
          <Typography className="bk-body2 text-bk_info">
            Candidate downloads, prints and reads the consent form. Then Signs
            the form with ink & uploads back on platform to verify
          </Typography>
          <BKStyledButton
            variant="outlined"
            className="rounded-lg"
            disabled={userDetails?.is2faEnabled}
            onClick={handleOpenMfaModal}>
            Setup MFA
          </BKStyledButton>
        </Box>
      </Box>

      {/* Modal for MFA Setup */}
      <StyledDialog open={openMfaModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ padding: 0 }}>
          <Box className="flex justify-between items-center">
            <Typography className="bk-headline2">Authenticator App</Typography>
            <IconButton onClick={handleCloseMfaModal}>
              <Close />
            </IconButton>
          </Box>
          <Typography className="bK-body1 text-bk_info">
            A virtual MFA device is an application running on your device that
            you can configure by scanning a QR code
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <StyledList>
            <li className="mt-xl2">
              <div className="flex flex-col gap-y-lg">
                <Typography className="bk-sub-heading2 font-tertiary text-bk_bg_dark">
                  Install a compatible application such as Google Authenticator,
                  Duo Mobile, or Authy app on your mobile device or computer
                </Typography>
              </div>
            </li>
            <li className="mt-xl2">
              <div className="flex flex-col gap-y-lg">
                <Typography className="bk-sub-heading2 font-tertiary text-bk_bg_dark">
                  Open your authenticator app, choose{' '}
                  <strong className="text-bk_text_primary">Show QR code</strong>{' '}
                  on this page, then use the app to scan the code.
                </Typography>
                <QRCodeBox onClick={handleShowQrCode}>
                  {loadingQrCode ? (
                    <CircularProgress />
                  ) : showQrCode && qrCode ? (
                    <img src={qrCode} alt="QR Code" />
                  ) : (
                    <Typography className="bk-sub-heading2 font-tertiary text-bk_action_primary">
                      Show QR code
                    </Typography>
                  )}
                </QRCodeBox>
                <Box className="flex flex-row gap-x-xs items-center">
                  <InfoOutlined
                    sx={{ color: COLORS.bk_info, fontSize: SPACING.lg }}
                  />
                  <Typography className="bk-typography-caption text-bk_info">
                    QR once generated, cannot be generated again for 30 mins.
                  </Typography>
                </Box>
              </div>
            </li>
            <li className="mt-xl2">
              <div className="flex flex-col gap-y-lg">
                <Box>
                  <Typography className="bk-sub-heading2 font-tertiary text-bk_bg_dark">
                    Enter a code from your virtual app below
                  </Typography>
                  <Controller
                    name="mfaCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Enter MFA Code"
                        variant="outlined"
                        className="mt-2"
                        size="small"
                        error={!!errors.mfaCode}
                        helperText={errors.mfaCode?.message}
                      />
                    )}
                  />
                </Box>
              </div>
            </li>
          </StyledList>
        </DialogContent>
        <DialogActions>
          <BKStyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleAddMfa)}>
            Add MFA
          </BKStyledButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );

  return withViewPermission({
    permissions: [canReadPreference, canCreatePreference, canUpdatePreference],
    children: children,
  });
};
