import { BKStyledSmallSelectGroup } from '@gigin-work-space/common-ui';

import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ReportAdditionalDetails = (props: {
  reportAdditionalDetail: any;
  setCheckDataState: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const { reportAdditionalDetail, setCheckDataState } = props;

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const clonedreportAdditionalDetail = { ...reportAdditionalDetail };

    clonedreportAdditionalDetail.report.verified_by = value;

    setCheckDataState(clonedreportAdditionalDetail);
  };

  const handleAditionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const clonedreportAdditionalDetail = { ...reportAdditionalDetail };

    clonedreportAdditionalDetail.report.additional_comments = value;

    setCheckDataState(clonedreportAdditionalDetail);
  };

  const handleChangeSource = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const clonedreportAdditionalDetail = { ...reportAdditionalDetail };

    clonedreportAdditionalDetail.report.verification_source = value;

    setCheckDataState(clonedreportAdditionalDetail);
  };

  const handleDateInitiatedChange = (value: any | null) => {
    if (value) {
      const clonedreportAdditionalDetail = { ...reportAdditionalDetail };

      clonedreportAdditionalDetail.report.timeline.initiated_on = value;

      setCheckDataState(clonedreportAdditionalDetail);
    }
  };

  const handleDateCompletedChange = (value: any | null) => {
    if (value) {
      const clonedreportAdditionalDetail = { ...reportAdditionalDetail };

      clonedreportAdditionalDetail.report.timeline.completed_on = value;

      setCheckDataState(clonedreportAdditionalDetail);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={5} spacing={2}>
        <Grid item xs={4}>
          <BKStyledSmallSelectGroup fullWidth>
            <InputLabel id="source">Source</InputLabel>
            <Select
              labelId="select-source"
              id="select-source"
              value={reportAdditionalDetail.report.verification_source}
              label="Source"
              size="small"
              onChange={handleChangeSource}>
              {/* TODO: render menuitem */}
              <MenuItem value={'ONLINE'}>Online</MenuItem>
              <MenuItem value={'OFFLINE'}>Offline</MenuItem>
            </Select>
          </BKStyledSmallSelectGroup>
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="Verified By"
            name="verifiedBy"
            size="small"
            value={reportAdditionalDetail.report.verified_by}
            onChange={handleStateChange}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Initiated On"
            format="YYYY-MM-DD"
            defaultValue={dayjs()} // Set the default value to today's date
            value={dayjs(reportAdditionalDetail.report.timeline.initiated_on)}
            onChange={newValue => handleDateInitiatedChange(newValue)}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            label="Completed On"
            defaultValue={dayjs()} // Set the default value to today's date
            format="YYYY-MM-DD"
            value={dayjs(reportAdditionalDetail.report.timeline.completed_on)}
            onChange={newValue => handleDateCompletedChange(newValue)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          label="Additional Comments"
          name="additionalComment"
          multiline
          minRows={3}
          value={reportAdditionalDetail.report.additional_comments}
          onChange={handleAditionChange}
          margin="none"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ReportAdditionalDetails;
