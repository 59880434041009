import { COLORS } from '@gigin-work-space/utils';
import { createTheme } from '@mui/material';

export const tableTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.bk_tag_blue,
    },
  },
});
