import {
  EmployerCDCStore,
  handleModalOpen,
  isModalOpenStore,
} from '@gigin-work-space/store';
import {
  COLORS,
  DATA_MAPPING_PROGRESS_STEP,
  maxTwoRole,
} from '@gigin-work-space/utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { AlertSubmitData } from '../common-alert-popup';
import EBMainHeader from './employer-bulk-main-header';
import EBStepperComponent from './employer-bulk-stepper';
import EBUploadDetails from './employer-bulk-upload-details';
import EBDataMapping from './employer-mapping';

const PrevNextComponent = (props: {
  isStatusComplete: any;
  isFirstStepComplete: any;
}) => {
  const isStatusComplete = props.isStatusComplete;
  const isFirstStepComplete = props.isFirstStepComplete;
  const { activeStep } = useSnapshot(EmployerCDCStore);
  useState<boolean>(false);
  const hasNextStep = () => {
    return activeStep === 3 ? false : true;
  };
  const hasPrevStep = () => {
    return activeStep === 1 ? false : true;
  };
  const { isOpen } = useSnapshot(isModalOpenStore);

  return (
    <Stack direction={'row'} columnGap={2} sx={{ mr: 4 }}>
      {hasPrevStep() ? (
        <Typography
          className="flex items-center cursor-pointer px-2 py-1 rounded h-fit"
          sx={{ color: COLORS.bk_info }}
          onClick={() => {
            EmployerCDCStore.activeStep = EmployerCDCStore.activeStep - 1;
          }}>
          <u>Previous Step</u>
        </Typography>
      ) : (
        <></>
      )}
      {hasNextStep() ? (
        <Typography
          className="flex items-center cursor-pointer px-2 py-1 rounded h-fit"
          sx={{
            border: `1px solid ${COLORS.bk_stroke_divider}`,
            marginLeft: !hasPrevStep() && isFirstStepComplete ? '12px' : '0',
          }}
          onClick={() => {
            EmployerCDCStore.activeStep = EmployerCDCStore.activeStep + 1;
          }}>
          Next Step
          <ArrowForwardIosIcon style={{ fontSize: 12 }} />
        </Typography>
      ) : (
        <Typography
          className="flex items-center cursor-pointer px-8 py-1 rounded h-fit"
          sx={{
            border: `1px solid ${COLORS.bk_stroke_divider}`,
            color: COLORS.bk_info,
          }}
          onClick={() => {
            if (EmployerCDCStore.dataMapped === 'SUBMITTED') {
              EmployerCDCStore.activeStep = 0;
            } else if (isStatusComplete) {
              handleModalOpen({
                ...isOpen,
                alertSubmitData: true,
              });
            } else {
              enqueueSnackbar(
                'Some steps are pending. Your progress has been saved to continue later.',
                {
                  variant: 'warning',
                  autoHideDuration: 2000,
                }
              );
              EmployerCDCStore.activeStep = 0;
            }
          }}>
          {EmployerCDCStore.dataMapped === 'SUBMITTED'
            ? 'Done'
            : isStatusComplete
            ? 'Submit'
            : 'Done'}
        </Typography>
      )}
      {isOpen.alertSubmitData ? <AlertSubmitData /> : ''}
    </Stack>
  );
};
const EBMainContentTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const { activeStep } = useSnapshot(EmployerCDCStore);
  const [isStatusComplete, setIsStatusComplete] = useState<boolean>(false);
  const [isFirstStepComplete, setIsFirstStepComplete] =
    useState<boolean>(false);
  const snapshot = useSnapshot(EmployerCDCStore);
  const getStepperStatus = async () => {
    if (
      EmployerCDCStore.stepperStatus[0].progress.toLocaleUpperCase() ===
        DATA_MAPPING_PROGRESS_STEP.COMPLETE ||
      EmployerCDCStore.stepperStatus[0].progress.toLocaleUpperCase() ===
        DATA_MAPPING_PROGRESS_STEP.COMPLETED
    ) {
      setIsFirstStepComplete(true);
      if (
        (EmployerCDCStore.stepperStatus[1].progress.toLocaleUpperCase() ===
          DATA_MAPPING_PROGRESS_STEP.COMPLETE ||
          EmployerCDCStore.stepperStatus[1].progress.toLocaleUpperCase() ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED) &&
        (EmployerCDCStore.stepperStatus[2].progress.toLocaleUpperCase() ===
          DATA_MAPPING_PROGRESS_STEP.COMPLETE ||
          EmployerCDCStore.stepperStatus[2].progress.toLocaleUpperCase() ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED)
      ) {
        setIsStatusComplete(true);
      } else {
        setIsStatusComplete(false);
      }
    } else {
      setIsFirstStepComplete(false);
      setIsStatusComplete(false);
    }
  };

  useEffect(() => {
    getStepperStatus();
  }, snapshot.stepperStatus);
  return (
    <Stack
      direction={'row'}
      justifyContent={
        activeStep === 1 && isFirstStepComplete ? 'flex-start' : 'space-between'
      }>
      <Stack direction={'column'}>
        <Typography className="bk-headline2">{title}</Typography>
        {description.length ? (
          <Stack
            direction={'row'}
            columnGap={0.25}
            alignItems={'center'}
            sx={{ pt: 1 }}>
            <InfoOutlinedIcon sx={{ color: '#167FCC', fontSize: '14px' }} />
            <Typography className="bK-body2 text-bk_text_secondary">
              {description}
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <PrevNextComponent
        isFirstStepComplete={isFirstStepComplete}
        isStatusComplete={isStatusComplete}
      />
    </Stack>
  );
};

const EBMainContent = () => {
  const { activeStep, toggle } = useSnapshot(EmployerCDCStore);
  useEffect(() => {}, [toggle]);
  return (
    <Stack direction={'column'} rowGap={2}>
      <EBMainHeader />
      <Divider />
      <Stack
        direction={'column'}
        rowGap={5}
        className="pl-11 md:pr-5 lg:pr-20 xl:pr-80">
        <EBStepperComponent />
        {activeStep === 1 ? (
          <>
            <EBMainContentTitle
              title={'Step 1: Upload Candidate details'}
              description=""
            />
            <EBUploadDetails
              acceptedFileTypes={['.xlsx,.csv,.xls']}
              description={`Excel file with details of ${maxTwoRole(
                EmployerCDCStore.currentJobRoles
              )}`}
              type="excel"
            />
          </>
        ) : activeStep === 2 ? (
          <>
            <EBMainContentTitle
              title="Step 2: Upload documents of candidates"
              description=""
            />
            <EBUploadDetails
              acceptedFileTypes={['.zip']}
              description={`Zip file with documents of ${EmployerCDCStore.totalCount} ${EmployerCDCStore.currentRole} (Max 50 MB)`}
              type="doc"
            />
          </>
        ) : (
          <>
            <EBMainContentTitle
              title="Step 3: Document mapping"
              description="Map documents to the respective candidates"
            />
            <EBDataMapping />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default EBMainContent;
