import { BKStyledSmallSelectGroup } from '@gigin-work-space/common-ui';
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useState } from 'react';

const ReportSummaryComponent = () => {
  interface FormData {
    title: string;
    comments: string;
    subtitle: string;
  }

  const [formData, setFormData] = useState<FormData>({
    title: '',
    comments: '',
    subtitle: '',
  });
  const [bullet, setBullet] = useState('');
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleChangeBullet = (event: SelectChangeEvent) => {
    setBullet(event.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          label="Report Summary Title"
          name="title"
          multiline
          minRows={3}
          value={formData.title}
          onChange={handleStateChange}
          error={!!errors.title}
          helperText={errors.title}
          margin="none"
          fullWidth
        />
      </Grid>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Subtitle"
            name="subtitle"
            size="small"
            value={formData.subtitle}
            onChange={handleStateChange}
            error={!!errors.subtitle}
            helperText={errors.subtitle}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item direction={'row'} container xs={12} spacing={1}>
          <Grid item xs={12} lg={3}>
            <BKStyledSmallSelectGroup fullWidth>
              <InputLabel id="bullet">Bullet</InputLabel>
              <Select
                labelId="select-bullet"
                id="select-bullet"
                value={bullet ? bullet : ''}
                label="Bullet"
                size="small"
                onChange={handleChangeBullet}>
                {/* TODO: render menuitem */}
                <MenuItem value={'Clear'}>Clear</MenuItem>
                <MenuItem value={'Discrepant'}>Discrepant</MenuItem>
                <MenuItem value={'Insufficient'}>Insufficient</MenuItem>
                <MenuItem value={'No Response'}>No Response</MenuItem>
              </Select>
            </BKStyledSmallSelectGroup>
          </Grid>
          <Grid item xs={12} lg={9}>
            <TextField
              label="comments"
              name="comments"
              size="small"
              value={formData.comments}
              onChange={handleStateChange}
              error={!!errors.comments}
              helperText={errors.comments}
              margin="none"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportSummaryComponent;
