import { COLORS, SPACING } from '@gigin-work-space/utils';
import { Font, StyleSheet } from '@react-pdf/renderer';
import interRegular from '../../../../fonts/Inter-Regular.ttf';
import manropeBold from '../../../../fonts/Manrope-Bold.ttf';
import manropeMedium from '../../../../fonts/Manrope-Medium.ttf';
import manropeRegular from '../../../../fonts/Manrope-Regular.ttf';
import manropeSemiBold from '../../../../fonts/Manrope-SemiBold.ttf';

Font.register({
  family: 'Inter',
  src: interRegular,
  fontWeight: 'normal',
});

Font.register({
  family: 'Manrope',
  fonts: [
    { src: manropeRegular, fontWeight: 400 },
    { src: manropeBold, fontWeight: 700 },
    { src: manropeSemiBold, fontWeight: 600 },
    { src: manropeMedium, fontWeight: 500 },
  ],
});

export const styles = StyleSheet.create({
  // Typography
  textHeadLine1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 10,
  },
  textHeadLine2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
  },
  textHeadLine3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
  },
  textSubHeading1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
  },
  textSubHeading2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
  },
  textSubHeading3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
  },
  textBody1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
  },
  textBody2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
  },
  textBody3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 11,
  },
  textSecondary: {
    color: '#55505A',
  },
  textLight: {
    color: '#fff',
  },
  textLink: {
    color: '#167FCC',
    textDecoration: 'underline',
  },

  // page style
  page: {
    position: 'relative',
    padding: '18px 24px 75px',
  },
  watermarkRotatedContainer: {
    position: 'absolute',
    top: '50%',
    left: '15%',
    transform: 'translate(-50%, -50%) rotate(-40.17deg)',
    opacity: 0.5,
  },
  watermarkContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    opacity: 0.5,
  },
  watermarkText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 88,
    color: '#C8CBCE',
  },
  watermarkImage: {
    width: 334,
    height: 324,
    objectFit: 'contain',
  },
  // header
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 24px',
    height: 45,
    border: '1px solid #DCD9DD',
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  candidateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  logoImage: {
    width: '56px',
    height: '31px',
    objectFit: 'contain',
    alignSelf: 'flex-start',
  },
  profileImageSmall: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  profileImageLarge: {
    width: '54px',
    height: '54px',
    objectFit: 'cover',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  candidateDetails: {
    flexDirection: 'column',
  },
  // footer
  footer: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: '100vw',
    flexDirection: 'column',
  },
  pageContainer: {
    backgroundColor: '#F3F8FC',
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    padding: '8px 14px',
  },
  captionContainer: {
    height: 34,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    padding: '8px 14px',
  },
  captionSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  supportSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  captionImage: {
    width: '19px',
    height: '9px',
    objectFit: 'contain',
  },
  // main section
  mainSectionContainer: {
    top: 35,
    flexDirection: 'column',
    gap: 10,
    paddingBottom: 10,
  },
  disclaimerContent: {
    fontSize: '11px',
  },
  disclaimerColumnContainer: {
    fontSize: '13px',
    marginBottom: '10px',
  },
  stackColumnContainer: {
    flexDirection: 'column',
    gap: 12,
    width: '100%',
  },
  iconTextContainer: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  iconImageSmall: {
    width: '12px',
    height: '12px',
    objectFit: 'contain',
  },
  iconImageMedium: {
    width: '16px',
    height: '16px',
    objectFit: 'contain',
  },
  checkLegendContainer: {
    position: 'relative',
    top: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    padding: 16,
  },
  // Instruction container
  instructionContainer: {
    flexDirection: 'column',
    gap: 8,
    borderTop: '1px solid #DCD9DD',
    borderRight: '1px solid #DCD9DD',
    borderBottom: '1px solid #DCD9DD',
    padding: 15,
    borderRadius: 8,
    borderBottomLeftRadius: 27,
    borderTopLeftRadius: 27,
  },
  // User details box
  userDetailsContainer: {
    borderRadius: 8,
    width: '100%',
    height: '182px',
  },
  profileSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  profileInfo: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '88%',
    gap: 4,
  },
  nameSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  contactDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  orderSection: {
    flexDirection: 'row',
    gap: 32,
  },
  orderColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 4,
  },
  // Checks Menu List Styles
  reportMenuList: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    border: '1px solid #DCD9DD',
    borderRadius: 8,
    padding: 15,
  },
  checkLogoContainer: {
    width: '9%',
  },
  checkLogoImage: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  checkInfoSection: {
    gap: 8,
  },
  checkInfoSubheading: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
  },
  checkMenuDetailsContainer: {
    width: '63%',
    gap: 8,
  },
  checkDetailsContainer: {
    width: '91%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  // Check Summary Container
  leftBorderedContainer: {
    borderTop: '1px solid #DCD9DD',
    borderBottom: '1px solid #DCD9DD',
    borderRight: '1px solid #DCD9DD',
    borderLeft: '4px solid #167FCC',
    borderRadius: 4,
    width: '100%',
  },
  checkInfoContainer: {
    height: 91,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F3F8FC',
    borderRadius: 4,
    padding: 16,
  },
  summaryIconImage: {
    width: 34,
    height: 26,
    objectFit: 'contain',
  },
  checkCommentContainer: {
    flexDirection: 'column',
    gap: 8,
    width: '100%',
    padding: '0 14px 14px',
    marginLeft: '9%',
  },
  // Table Style
  table: {
    flexDirection: 'column',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #DCD9DD',
    borderLeft: '4px solid #167FCC',
  },
  tableHeader: {
    flex: 1,
    padding: 12,
    backgroundColor: '#F3F8FC',
    borderRight: '1px solid #DCD9DD',
  },
  tableCell: {
    flex: 1,
    padding: 12,
    borderRight: '1px solid #DCD9DD',
  },
  comment: {
    fontStyle: 'italic',
    fontSize: 10,
    marginTop: 5,
    backgroundColor: '#f8f8f8',
    padding: 3,
  },
  // verification meta container
  verificationMetaContainer: {
    flexDirection: 'column',
    gap: 0,
    borderBottom: '1px solid #DCD9DD',
    padding: '16px 0px 24px 0px',
  },
  // verification documents section
  documentHeaderContainer: {
    backgroundColor: '#F3F8FC',
    padding: 16,
    gap: 6,
    width: '100%',
  },
  // Report Status Styles
  pillImage: {
    width: 130,
    height: 28,
    objectFit: 'contain',
  },
  clear: {
    color: '#048218',
  },
  discrepant: {
    color: '#D94415',
  },
  insufficient: {
    color: '#EE6E12',
  },
  unableToVerify: {
    color: '#EE6E12',
  },
  noResponse: {
    color: '#55505A',
  },
  clearBg: {
    color: '#048218',
  },
  discrepantBg: {
    color: '#D94415',
  },
  insufficientBg: {
    color: '#EE6E12',
  },
  noResponseBg: {
    color: '#55505A',
  },
  // REPORT CONSOLIDATED STYLES - REPORT V2 START
  // Manrope Typography V3
  typov3Header1: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 22,
    // lineHeight: 30.8,
  },
  typov3Header2: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 20,
    // lineHeight: 28,
  },
  typov3SubHeader1: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 16,
    // lineHeight: 22.4,
  },
  typov3SubHeader2: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    // lineHeight: 19.6,
  },
  typov3SubHeader2Emphasis: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 14,
    // lineHeight: 19.6,
  },
  typov3SubHeader3: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 12,
    // lineHeight: 16.8,
  },
  typov3SubHeader3Underline: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 12,
    // lineHeight: 16.8,
    textDecoration: 'underline',
  },
  typov3Body1: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 14,
    // lineHeight: 19.6,
  },
  typov3Body2: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 12,
    // lineHeight: 16.8,
  },
  typov3Body2Underline: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 12,
    // lineHeight: 16.8,
    textDecoration: 'underline',
  },
  typov3Body3: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 11,
    // lineHeight: 15.4,
  },
  typov3Body3Underline: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 11,
    // lineHeight: 15.4,
    textDecoration: 'underline',
  },
  typov3HintText: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: 11,
    // lineHeight: 15.4,
  },
  typov3BtnSmallText: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: 12,
    // lineHeight: 15.4,
  },

  displayFlexRowBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    rowGap: 8,
  },
  displayFlexColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  twoColumnContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: SPACING.lg,
  },
  pageConsolidated: {
    position: 'relative',
    padding: SPACING.xl2,
  },
  // Header Styles
  reportHeaderConsolidated: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  reportHeaderConsolidatedHeroImage: {
    width: 'auto',
    height: 24,
    objectFit: 'contain',
  },
  // New styles for the report details section
  reportDetailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  reportDetailsColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  reportDetailsColumnRight: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end', // Align items to the right
  },
  reportDetailsRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: SPACING.xs3,
    gap: SPACING.sm,
  },
  reportDetailsLabel: {
    color: COLORS.bk_report_text_secondary,
    alignSelf: 'flex-start',
    width: 100,
  },
  reportDetailsValue: {
    color: COLORS.bk_report_text_primary,
    width: 'auto',
  },
  // Button Image Status Styles START
  statusBtnContainer: {
    paddingHorizontal: SPACING.sm,
    paddingVertical: SPACING.xs,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: SPACING.xs2,
    borderRadius: 4,
  },
  statusBtnIconImage: {
    width: 16,
    height: 16,
    objectFit: 'contain',
  },
  // Button Image Status Styles END
  // Summary Table Styles START
  summaryTableHeader: {
    flexDirection: 'row',
    paddingHorizontal: SPACING.xl2,
    paddingVertical: SPACING.sm,
    alignItems: 'center', // Ensure vertical alignment
    backgroundColor: COLORS.bk_report_stroke_secondary_100,
  },
  summaryTableRow: {
    flexDirection: 'row',
    paddingHorizontal: SPACING.xl2,
    paddingVertical: SPACING.sm,
    alignItems: 'center', // Ensure vertical alignment
  },
  alternateRow: {
    backgroundColor: COLORS.bk_report_grey_100,
  },
  summaryTableCell: {
    flex: 1,
  },
  summaryTableStatusCell: {
    flex: 1,
    width: 100, // Set a fixed width for the status column
    textAlign: 'left', // Align text to the left
  },
  summaryTableStatusIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
    objectFit: 'contain',
  },
  // Summary Table Styles END
  // Section Breaker styles START
  sectionBreak: {
    paddingTop: SPACING.xl3,
    paddingBottom: SPACING.lg,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: SPACING.lg,
  },
  breakerDivider: {
    height: 1,
    flex: 1,
    backgroundColor: 'transparent', // Set background to transparent
    borderBottomWidth: 1, // Add border bottom width
    borderColor: COLORS.bk_report_text_secondary, // Set border color
    borderStyle: 'dashed', // Set border style to dotted
  },
  sectionBreakSolid: {
    height: SPACING.xs2,
    backgroundColor: COLORS.bk_report_stroke_secondary_100,
    marginHorizontal: -24,
    marginVertical: SPACING.lg,
  },
  // Section Breaker styles END
  // Individual Check Consolidated styles START
  checkTitleAndStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: SPACING.sm,
  },
  // Individual Check Consolidated styles END
});
