import { BKStyledButton } from '@gigin-work-space/common-ui';
import { CORE_USER_ROLE } from '@gigin-work-space/utils';
import { Box, TextField, Typography } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper } from 'src/app/components';
import { AuthenticationHOCForSignup } from 'src/app/features/authentication/authentication-hoc/authentication-hoc-without-background';
import { UserInfoSchema, axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { User } from '../../../component/employer-upload/interface';

interface FormValues {
  userName: string;
  designation: string;
  mobile: string;
  email: string;
}

// interface Role {
//   name: string;
// }
// export interface User {
//   name: string;
//   designation: string;
//   phone: string;
//   email: string;
//   _id: string;
//   location: string;
//   role: Role;
// }

export function UserInfo() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      fetchUserDetails();
    } else {
      globalNavigateTo('/bgv', navigate);
    }
  }, []);

  async function fetchUserDetails() {
    try {
      const response = await axiosInstance.get(
        `${endpoints.UPDATE_USER}/user-profile`
      );
      setUser(response.data);
    } catch (error) {
      enqueueSnackbar('Failed to get details your details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      userName: user?.name || '',
      designation: user?.designation || '',
      mobile: user?.phone || '',
      email: user?.email || '',
    },
    validationSchema: UserInfoSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSubmit(values: FormValues, { resetForm }: any) {
    try {
      const response = await axiosInstance.patch(
        `${endpoints.UPDATE_USER}/${user?._id}`,
        {
          name: values.userName,
          designation: values.designation,
          phone: values.mobile,
          email: values.email,
        }
      );
      if (response.status === HttpStatusCode.Ok) {
        enqueueSnackbar('Your details have been updated successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        if (user?.role?.name === CORE_USER_ROLE.TEMPORARY_MEMBER) {
          globalNavigateTo('/user/on-board', navigate);
          return;
        } else {
          globalNavigateTo('/bgv', navigate);
        }
      }
    } catch (error) {
      enqueueSnackbar('Sorry, we were unable to update your details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      resetForm();
    }
  }

  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled;
  }, [formik.values]);

  return (
    <AuthenticationHOCForSignup>
      <AuthenticationPaper>
        <Typography className="bk-headline1 text-bk_text_primary">
          User details
        </Typography>
        <Typography className="bK-body1 text-bk_text_secondary mt-1">
          Please enter the user details here
        </Typography>
        <Box
          className="mt-6"
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}>
          {['userName', 'designation', 'mobile'].map(field => (
            <TextField
              key={field}
              id={field}
              name={field}
              label={`Enter your ${field === 'userName' ? 'Name' : field}`}
              className="bk-company-info-inputBase"
              variant="outlined"
              type={'text'}
              fullWidth
              required
              value={formik.values[field as keyof FormValues]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[field as keyof FormValues] &&
                Boolean(formik.errors[field as keyof FormValues])
              }
              helperText={
                formik.touched[field as keyof FormValues] &&
                formik.errors[field as keyof FormValues]
              }
              inputProps={{ 'data-testid': field }}
            />
          ))}
          <BKStyledButton
            data-testid="submitBtn"
            className="bg-bk_action_primary mt-2 rounded-lg"
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            disabled={isBtnDisabled}>
            Submit
          </BKStyledButton>
        </Box>
      </AuthenticationPaper>
    </AuthenticationHOCForSignup>
  );
}

export default React.memo(UserInfo);
