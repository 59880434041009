import { BKStyledButton, EmptyState } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import {
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  FAIL_TO_GENERATE_PDF_DESCRIPTION,
  FAIL_TO_GENERATE_PDF_TITLE,
  GENERATE_REPORT_LOADER_TITLE,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Document, Page, usePDF } from '@react-pdf/renderer';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withViewPermission } from 'src/app/HOC';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio/react';
import { DisplayImage } from '../../../components';
import {
  CoverPage,
  DisclaimerPage,
  IndividualCheckDetails,
  InstructionPage,
  MenuPage,
} from './Pages';
import { ReportFooter, ReportHeader, Watermark } from './components';
import { GenerateReportLoader } from './generate-report-loader';
import { styles } from './styles';

export interface PDFDocumentProps {
  reportDataObject: any;
}
type PDFGeneratorProps = {
  reportData: any;
};

export const PDFDocument = (props: PDFDocumentProps) => {
  const { reportDataObject } = props;
  return (
    <Document>
      {/* Cover Page */}
      <CoverPage reportDataObject={reportDataObject} />
      {/* Instruction Page */}
      <InstructionPage reportDataObject={reportDataObject} />
      {/* Menu Page */}
      <MenuPage reportDataObject={reportDataObject} />
      {/* Individual Checks Details Pages. Each Check is having its subcheck */}
      {reportDataObject.reports.map((check: any) =>
        check.documentlist.map((subcheck: any) => (
          <Page size="A4" style={styles.page} key={check.check_id} wrap>
            {/* Header */}
            <ReportHeader reportDataObject={reportDataObject} />
            {/* Individual Subchecks */}
            <IndividualCheckDetails
              checkData={check}
              subCheckData={subcheck}
              key={subcheck.check_number}
            />
            {/* Watermark */}
            <Watermark checkStatus={reportDataObject.overall_status.status} />
            {/* Footer */}
            <ReportFooter reportDataObject={reportDataObject} />
          </Page>
        ))
      )}

      {/* Disclaimer Page */}
      <DisclaimerPage reportDataObject={reportDataObject} />
    </Document>
  );
};

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ reportData }) => {
  const navigate = useNavigate();
  const [instance] = usePDF({
    document: <PDFDocument reportDataObject={reportData} />,
  });

  if (instance.loading) {
    return <GenerateReportLoader title={GENERATE_REPORT_LOADER_TITLE} />;
  }

  if (instance.error) {
    return (
      <EmptyState
        maxHeight={'100vh'}
        imageComponent={
          <DisplayImage
            imageName={EmployerAppAssetIconsFileNames.ERROR_404_SVG}
            imageType={DisplayImageType.ICON}
            width="370px"
            height="370px"
          />
        }
        buttonComponent={
          <BKStyledButton
            variant="contained"
            className="bg-bk_action_primary mt-2 rounded-lg"
            onClick={() => globalNavigateTo('/bgv', navigate)}>
            Back to dashboard
          </BKStyledButton>
        }
        title={FAIL_TO_GENERATE_PDF_TITLE}
        description={FAIL_TO_GENERATE_PDF_DESCRIPTION}
      />
    );
  }

  return (
    <iframe
      src={instance.url as string}
      title="Interim Report"
      style={{ width: '100%', height: '100vh' }}
    />
  );
};

const BgvReport = () => {
  const { caseId } = useParams();
  const [reportDataObject, setReportDataObject] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const getReportData = async () => {
    try {
      setIsDataLoaded(false);
      const response = await axiosInstance.get(
        `${endpoints.GET_REPORT_DATA}/${caseId}`
      );

      if (!response.data.success) {
        throw new Error('Failed to generate report pdf');
      }

      setReportDataObject(response.data.data);
      setIsDataLoaded(prev => !prev);
    } catch (error) {
      enqueueSnackbar('Failed to generate report pdf', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    getReportData();
  }, [caseId]);

  if (!isDataLoaded) {
    return <GenerateReportLoader title={GENERATE_REPORT_LOADER_TITLE} />;
  }
  return <PDFGenerator reportData={reportDataObject} />;
};

export const WithPermissionBgvReport = () => {
  const { canReadOrder } = useSnapshot(permissionStore);
  return withViewPermission({
    permissions: [canReadOrder],
    children: <BgvReport />,
  });
};
