import { Grid, Typography } from '@mui/material';

/* eslint-disable-next-line */
export interface ChartEmptyStateProps {
  imageComponent?: React.ReactNode;
  description?: string;
  maxHeight?: string;
}

export function ChartEmptyState({ imageComponent, description, maxHeight }: ChartEmptyStateProps) {
  return (
    <Grid
      container
      alignItems={'center'}
      direction={'column'}
      justifyContent={'center'}
      spacing={2}
      sx={{
        display: 'flex',
        height: `${maxHeight ? maxHeight : 'auto'}`,
        overflow: 'hidden',
      }}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        {/* Your Image goes here */}
        {imageComponent}
      </Grid>
      <Grid item rowSpacing={5} xs={12} sx={{ display: 'flex', overflow: 'hidden' }}>
        {/* Your content goes here */}
        <Typography className="bk-headline3 text-bk_text_secondary">{description}</Typography>
      </Grid>
    </Grid>
  );
}

export default ChartEmptyState;
