/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IValidateCandidate } from '@gigin-work-space/model';
import { CheckoutStore, resetCheckoutStore } from '@gigin-work-space/store';
import {
  AUTO_HIDE_TIMEOUT,
  clearIndexedDBEntries,
  DataCollectionItemSource,
  EnumIndexedDbContextKey,
  EnumIndexedDbStore,
  getItem,
  guardedPageNavigate,
  setItem,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useWizard } from 'react-use-wizard';
import { IPackage, IParsedExcelDetails } from 'src/app/constant/interfaces';
import {
  useFooter,
  useOrderCreationContext,
  useOrderCreationStepContext,
} from 'src/app/context';
import {
  ConfirmOrderCreationModal,
  OrderCreatedSuccessModal,
} from 'src/app/templates';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import { CandidateInstruction } from '../candidate-import';
import ErrorDialog from '../common-alert-dialogBox';
import { DragDropComponent } from '../drag-drop-component';
import EditableInitiateTable from '../initiate-bgv/editable-table/editable-initiate-table';

export interface CandidateDetailUploadProps {
  contextKey?: EnumIndexedDbContextKey;
}

export interface IpersistedData {
  candidateList: IValidateCandidate[];
  parsedExcelDetails: IParsedExcelDetails;
}

const CandidateDetailUpload: React.FC<CandidateDetailUploadProps> = React.memo(
  ({ contextKey = EnumIndexedDbContextKey.BGV_CANDIDATE_LINK_CSV_UPLOAD }) => {
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [candidates, setCandidates] = useState<IValidateCandidate[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { meta } = useSnapshot(CheckoutStore);
    const theme = useTheme();
    const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const { enqueueSnackbar } = useSnackbar();
    const {
      dispatch: orderDispatch,
      state: {
        orderInitiationId,
        selectedPackage,
        parsedExcelDetailsCandidate,
      },
    } = useOrderCreationContext();
    const { dispatch: footerDispatch } = useFooter();
    const { dispatch: stepperDispatch } = useOrderCreationStepContext();
    const { activeStep } = useWizard();
    const navigate = useNavigate();

    const [isOrderSuccessModalOpen, setIsOrderSuccessModalOpen] =
      useState(false);
    const [isOrderConfirmModalOpen, setIsOrderConfirmModalOpen] =
      useState(false);

    const updateAllStates = useCallback(
      async (
        newCandidates: IValidateCandidate[],
        newParsedExcelDetails: IParsedExcelDetails
      ) => {
        setCandidates(newCandidates);

        const updatedParsedExcelDetails = {
          ...newParsedExcelDetails,
          totalItems: newParsedExcelDetails?.totalItems,
          validRecords: newParsedExcelDetails?.validRecords,
        };

        orderDispatch({
          type: 'SET_PARSED_EXCEL_DETAILS_CANDIDATE',
          payload: updatedParsedExcelDetails,
        });

        await setItem(EnumIndexedDbStore.FILES, contextKey, {
          candidateList: newCandidates,
          parsedExcelDetails: updatedParsedExcelDetails,
        });
      },
      [contextKey, orderDispatch]
    );

    const handleFileUploadSuccess = useCallback(
      async (parsedData: IpersistedData, message: string) => {
        const { candidateList, parsedExcelDetails } = parsedData;
        try {
          if (parsedExcelDetails?.totalItems === 0) {
            enqueueSnackbar(
              'The file uploaded was empty. Can you please fill the data and try uploading again',
              {
                variant: VARIANT_TYPE.WARNING,
              }
            );
          } else {
            enqueueSnackbar(message, {
              variant: VARIANT_TYPE.SUCCESS,
              autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
            });
          }

          await updateAllStates(candidateList, parsedExcelDetails);
        } catch (error) {
          enqueueSnackbar('Error processing file upload', {
            variant: VARIANT_TYPE.ERROR,
          });
        }
      },
      [enqueueSnackbar, updateAllStates]
    );

    const handleFileUploadError = useCallback(
      (error: any) => {
        enqueueSnackbar(error, {
          variant: VARIANT_TYPE.ERROR,
        });
      },
      [enqueueSnackbar]
    );

    const getTemplate = useCallback(() => {
      window.open(`${endpoints.GET_TEMPALATE}`, '_blank', 'noreferrer');
      enqueueSnackbar(`Template download Successfully`, {
        variant: VARIANT_TYPE.SUCCESS,
        autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
      });
    }, [enqueueSnackbar]);

    const createOrder = useCallback(async () => {
      setIsLoading(true);
      footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
      try {
        const response = await axiosInstance.post(
          `${endpoints.CONFIRM_ORDER_CHECKOUT}`,
          { checkout_initiation_id: orderInitiationId }
        );
        return response?.data;
      } catch (error) {
        console.error('Failed to create order:', error);
        enqueueSnackbar('Failed to create order', {
          variant: VARIANT_TYPE.ERROR,
        });
        throw error;
      } finally {
        setIsLoading(false);
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
      }
    }, [orderInitiationId, footerDispatch, enqueueSnackbar]);

    const handlePrimaryCTA = useCallback(() => {
      if (candidates.length > 0) {
        setIsOrderConfirmModalOpen(true);
      }
    }, [candidates]);

    const handleConfirmOrderCreation = useCallback(async () => {
      setIsOrderConfirmModalOpen(false);
      try {
        const orderCreateResponse = await createOrder();
        if (orderCreateResponse?.statusCode === HttpStatus.CREATED) {
          const orderId = orderCreateResponse?.data?._id;
          orderDispatch({ type: 'SET_ORDER_ID', payload: orderId });
          setIsOrderSuccessModalOpen(true);
        } else {
          throw new Error('Failed to create order');
        }
      } catch (error) {
        enqueueSnackbar('Failed to create order', {
          variant: VARIANT_TYPE.ERROR,
        });
      }
    }, [createOrder, enqueueSnackbar, orderDispatch]);

    const handleOrderSuccessAction = useCallback(async () => {
      try {
        setIsOrderSuccessModalOpen(false);
        stepperDispatch({ type: 'MARK_STEP_COMPLETED', payload: activeStep });
        resetCheckoutStore();
        await clearIndexedDBEntries(EnumIndexedDbStore.FILES);
        globalNavigateTo(guardedPageNavigate(), navigate);
      } catch (error) {
        console.error('Error in order success action:', error);
      }
    }, [stepperDispatch, activeStep, navigate]);

    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_DISABLED',
        disabled:
          parsedExcelDetailsCandidate?.validRecords === 0 || !candidates.length,
      });
    }, [parsedExcelDetailsCandidate, candidates, footerDispatch]);

    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_ACTION',
        action: handlePrimaryCTA,
        label: 'Done',
      });
      stepperDispatch({
        type: 'MARK_STEP_VISITED',
        payload: activeStep,
      });
    }, [footerDispatch, handlePrimaryCTA, stepperDispatch, activeStep]);

    useEffect(() => {
      const fetchPersistedData = async () => {
        try {
          const persistedData = (await getItem(
            EnumIndexedDbStore.FILES,
            contextKey
          )) as IpersistedData;
          if (persistedData) {
            setCandidates(persistedData.candidateList || []);
            orderDispatch({
              type: 'SET_PARSED_EXCEL_DETAILS_CANDIDATE',
              payload: persistedData.parsedExcelDetails,
            });
          }
        } catch (error) {
          console.error(
            'Failed to fetch persisted data from IndexedDB:',
            error
          );
        }
      };
      fetchPersistedData();
    }, [contextKey, orderDispatch]);

    return (
      <Box className="max-w-[1172px] block my-0 mx-auto">
        <Box
          position={'relative'}
          sx={{ display: 'flex', flexDirection: 'row' }}>
          <Stack
            direction={'column'}
            spacing={2}
            className="initiate-candidate"
            sx={{ minWidth: '55vw' }}>
            <Paper
              sx={{
                borderRadius: '1em',
                minHeight: '42.25em',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.07);',
              }}
              elevation={2}>
              <Typography
                borderRadius="18px 18px 0px 0px"
                className="bK-body1 bg-bk_action_primary text-center w-full text-bk_bg_primary h-9 flex justify-center items-center">
                To add candidates you can&nbsp;
                <span
                  className="underline text-bk_bg_primary cursor-pointer"
                  onClick={getTemplate}>
                  {' '}
                  download csv template
                </span>
                &nbsp; from here
              </Typography>
              <Box
                sx={{
                  px: isNotExtraLargeScreen ? 2 : 4,
                  py: isNotExtraLargeScreen ? 2 : 4,
                }}
                className="w-full">
                <DragDropComponent
                  candidates={candidates}
                  onFileUploadSuccess={handleFileUploadSuccess}
                  onFileUploadError={handleFileUploadError}
                  UpdateCandidates={updateAllStates}
                  packageId={selectedPackage?.id || ''}
                />
              </Box>
              <Box
                sx={{ px: isNotExtraLargeScreen ? 2 : 4 }}
                className="w-full">
                {meta.totalItems ? (
                  <Typography className="bk-sub-heading1">
                    {meta.totalItems} total candidates
                  </Typography>
                ) : (
                  <Typography className="bk-sub-heading1">
                    Or add candidate details manually
                  </Typography>
                )}
              </Box>
              <Box
                sx={{ py: 1.5, px: isNotExtraLargeScreen ? 2 : 4 }}
                className="w-full">
                <EditableInitiateTable
                  candidates={candidates}
                  UpdateCandidates={updateAllStates}
                  packageId={selectedPackage?.id || ''}
                />
              </Box>
            </Paper>
          </Stack>
          <Stack className="w-full mt-md">
            <CandidateInstruction />
          </Stack>
          <ErrorDialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
            title={'Package Not Found'}
            content={
              'Please create the package first before attempting to create the request.'
            }
            secondaryButtonLabel={'Close'}
            primaryButtonLabel={'View Package'}
            primaryButtonUrl="/bgv/company-settings/packages"
          />
        </Box>
        <ConfirmOrderCreationModal
          open={isOrderConfirmModalOpen}
          packageDetails={selectedPackage as IPackage}
          parsedExcelDetails={
            parsedExcelDetailsCandidate as IParsedExcelDetails
          }
          mode={DataCollectionItemSource.CANDIDATE_DATA_COLLECTION}
          handleClose={() => setIsOrderConfirmModalOpen(false)}
          handleConfirm={handleConfirmOrderCreation}
        />
        <OrderCreatedSuccessModal
          open={isOrderSuccessModalOpen}
          handleClose={() => setIsOrderSuccessModalOpen(false)}
          handleCTA={handleOrderSuccessAction}
          mode={DataCollectionItemSource.CANDIDATE_DATA_COLLECTION}
        />
      </Box>
    );
  }
);

export default CandidateDetailUpload;
