import { AUTO_HIDE_TIMEOUT, VARIANT_TYPE } from '@gigin-work-space/utils';
import { enqueueSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { frontendRoutes } from '../services/url-constants';

//Interfaces for Vender Check Allocation
interface DataCollectionItem {
  address_detail_current: string[];
  address_detail_permanent: string[];
  primary_education: string[];
  secondary_education: string[];
  graduation_check: string[];
  master_check: string[];
}

interface IDataCollection {
  data: DataCollectionItem;
  caseId: string;
  checkId: string;
  clientName: string;
  candidate: string;
  contact: string;
  checkAllocation: string;
  initiatedOn: Date;
}

// Interface for the data structure of Process Steps
interface IProgressStep {
  _id: string;
  progress_step: string;
  status: string;
  message: string;
}

interface IReportIntimation {
  updatedAt: Date;
}

// Interface for the data structure of case details
interface ICaseDetail {
  _id: string;
  account_id: { account_name: string }[];
  ext_case_id: string;
  report_status: string;
  actor_id: IActorDetail[];
  link: string;
  createdAt: Date;
  service_progress_status: IProgressStep[];
  reportIntimation: IReportIntimation[];
}

// Interface for the data structure of actor details
interface IActorDetail {
  name: string;
  mobile: string;
}

// Interface for the data structure of status
interface IStatus {
  status: string;
  message: string;
}

// Interface for the data structure of timeline
interface ITimeline {
  initiatedOn: Date;
  lastUpdate: Date;
  completedOn: Date;
}

// Interface for the data structure of check details
interface ICheckDetail {
  accountName: string;
  candidateName: string;
  orderId: string;
  subCheckType: string;
  checkType: string;
  checkNumber: string;
  checkNumberText: string;
  customerId: string;
  documentType: string;
  documentTypeGroup: string;
  serviceRequestId: string;
  status: IStatus;
  reportStatus: string;
  subChecks: string[];
  verificationSource: string;
  timeline: ITimeline;
  actorId: string;
  verifiedBy: string;
  verifiedOn: Date;
  verificationComment: string;
}

interface IClientCrcCheckDetail {
  caseId: string;
  name: string;
  fatherName: string;
  address: string;
  status: string;
  tenantId: string;
  account_id: string;
  _id: string;
  response: {
    data: {
      output: {
        source: {
          reports: any;
        };
      };
    };
  };
}

// Interface for the preprocessDataForExport method
interface IExportProcessor {
  preprocessDataForExport(
    _data:
      | ICaseDetail[]
      | ICheckDetail[]
      | IClientCrcCheckDetail[]
      | IDataCollection[]
  ): unknown[];
}

// Processor for exporting case details to Excel
class CaseDetailsExportProcessor implements IExportProcessor {
  preprocessDataForExport(caseDetails: ICaseDetail[]): unknown[] {
    return caseDetails.map(detail => ({
      'Company Name': detail?.account_id[0]?.account_name,
      'Case ID': detail?._id,
      'Case No': detail?.ext_case_id,
      'Data Collection': detail?.service_progress_status[1]?.status,
      'Report Status': detail?.report_status,
      Name: detail?.actor_id[0]?.name || '',
      Mobile: detail?.actor_id[0]?.mobile || '',
      Link: `${process.env['NX_OPS_PORTAL_URL']}/report/${detail?._id}`,
      'Initiated On': globalFormatDate(detail?.createdAt),
      'Closed Date':
        detail?.reportIntimation &&
        globalFormatDate(
          detail?.reportIntimation[detail?.reportIntimation?.length - 1]
            ?.updatedAt
        ),
    }));
  }
}

class CheckAllocationDetailsExportProcessor implements IExportProcessor {
  preprocessDataForExport(checkAllocation: IDataCollection[]): unknown[] {
    return checkAllocation.map(detail => ({
      'Check ID': detail?.checkId,
      'Case Allocation Date': '',
      'Client Name': detail?.clientName,
      'Candidate Name': detail?.candidate,
      'Contact Number': detail?.contact,
      Address: `${
        detail?.data?.address_detail_current
          ? detail?.data?.address_detail_current?.join('\n ')
          : ''
      } ${
        detail?.data?.address_detail_permanent
          ? detail?.data?.address_detail_permanent?.join('\n')
          : ''
      }`,
      'Education Details': `${
        detail?.data?.primary_education
          ? detail?.data?.primary_education?.join('\n ')
          : ''
      } ${
        detail?.data?.secondary_education
          ? detail?.data?.secondary_education?.join('\n')
          : ''
      } ${
        detail?.data?.graduation_check
          ? detail?.data?.graduation_check?.join('\n')
          : ''
      } ${
        detail?.data?.master_check ? detail?.data?.master_check?.join('\n') : ''
      }`,
      'Check Allocation': detail?.checkAllocation,
      'Initiated On': globalFormatDate(detail?.initiatedOn),
    }));
  }
}

// Processor for exporting check details to Excel
class CheckDetailsExportProcessor implements IExportProcessor {
  preprocessDataForExport(caseDetails: ICheckDetail[]): unknown[] {
    return caseDetails.map(test => {
      try {
        return {
          'Company Name': test.accountName,
          'Candidate Name': test.candidateName,
          'Check ID': test.checkNumberText,
          'Case No': this.retrieveCheckIdFromCaseNumber(test.checkNumberText),
          'Check Name': test.subCheckType,
          'Check Type': test.checkType,
          System_Check_ID: test.checkNumber,
          Initiated_On: globalFormatDate(test.timeline.initiatedOn),
          Last_Update: globalFormatDate(test.timeline.lastUpdate),
          Completed_On: globalFormatDate(test.timeline.completedOn),
          Verified_On: globalFormatDate(test.verifiedOn),
          Service_Request_Id: test.serviceRequestId,
          Status: test.status.status,
          'Report Status': test.reportStatus,
          Status_Message: test.status.message,
          Sub_Checks: test.subChecks.join(', '),
          Verified_By: test.verifiedBy,
          Verification_Source: test.verificationSource,
          Verification_Comment: test.verificationComment,
        };
      } catch (e) {
        throw new Error('Error in processing check details excel.');
      }
    });
  }

  // Helper method to format date
  private formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  // Helper method to retrieve check ID from case number
  private retrieveCheckIdFromCaseNumber(checkNo: string): string {
    if (!checkNo) {
      return '';
    }

    const lastIndex = checkNo.lastIndexOf('_');
    if (lastIndex !== -1) {
      return checkNo.substring(0, lastIndex);
    } else {
      return checkNo;
    }
  }
}

// Processor for Client CRC Check

class ClientCrcCheckDetailsExportProcessor implements IExportProcessor {
  preprocessDataForExport(caseDetails: IClientCrcCheckDetail[]): unknown[] {
    return caseDetails.map(test => {
      try {
        return {
          Case_ID: test.caseId,
          Name: test.name,
          Father_Name: test.fatherName,
          Address: test.address,
          Status: test.status,
          Case_Status: this.getCaseStatus(test.status, test.response),
          Link: this.getLink(test.tenantId, test.account_id, test._id),
        };
      } catch (e) {
        throw new Error('Error in processing check details excel.');
      }
    });
  }

  private getLink(tenantId: string, accountId: string, caseId: string): string {
    return `${process.env['NX_OPS_PORTAL_URL']}${frontendRoutes.CRC_CHECKLIST}?accountId=${accountId}&tenantId=${tenantId}&crcId=${caseId}`;
  }

  private getCaseStatus(status: string, data: any): string {
    if (status === 'COMPLETED' || status === 'RESPONSE_RECEIVED') {
      const reports = data?.data?.output?.source?.reports;
      return reports && reports.length > 0 ? 'Records Found' : 'No Case';
    } else {
      return 'No response received';
    }
  }
}

// ExportType Constants
export enum ExportType {
  // eslint-disable-next-line no-unused-vars
  CHECK = 'Check',
  // eslint-disable-next-line no-unused-vars
  CASE = 'Case',
  // eslint-disable-next-line no-unused-vars
  CLIENT_CRC_CHECK = 'ClientCrcCheck',
  // eslint-disable-next-line no-unused-vars
  CHECK_ALLOCATION_TO_VENDOR = 'CheckAllocation',
}

// Orchestrator class to manage the selection and execution of processors
export class ExportOrchestrator {
  private processor: IExportProcessor;

  constructor(exportType: string) {
    switch (exportType) {
      case ExportType.CASE:
        this.processor = new CaseDetailsExportProcessor();
        break;
      case ExportType.CHECK:
        this.processor = new CheckDetailsExportProcessor();
        break;
      case ExportType.CLIENT_CRC_CHECK:
        this.processor = new ClientCrcCheckDetailsExportProcessor();
        break;
      case ExportType.CHECK_ALLOCATION_TO_VENDOR:
        this.processor = new CheckAllocationDetailsExportProcessor();
        break;
      default:
        throw new Error(`Unsupported export type: ${exportType}`);
    }
  }

  // Export data to Excel based on the export type
  exportToExcel(
    data:
      | ICaseDetail[]
      | ICheckDetail[]
      | IClientCrcCheckDetail[]
      | IDataCollection[],
    fileName: string
  ): void {
    if (data.length === 0) {
      enqueueSnackbar('No case details to export', {
        variant: VARIANT_TYPE.WARNING,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
      return;
    }

    const processedData = this.processor.preprocessDataForExport(data);
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}

export const globalFormatDate = (dateString: Date) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const formattedDate = date
    .toLocaleString('sv-SE', options)
    .replace(' ', ': ');
  return formattedDate;
};
