import { EmployerCDCStore } from '@gigin-work-space/store';
import { COLORS, DATA_MAPPING_PROGRESS_STEP } from '@gigin-work-space/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import CircularIndeterminate from '../skelton/bgv-loader';
import EditableTable from './editable-table';
import EBDataCompleted from './employer-bulk-data-completed';
import { EBDragDropDoc, EBDragDropExcel } from './employer-bulk-drag-drop';
import EBNoFiles, { generateTemplate } from './employer-bulk-empty-state';
import EBFileDetails from './employer-bulk-file-details';
interface DocItem {
  id: string;
  title: string;
}

type HeaderKey = { label: string; name: string };
type HeaderKeys = HeaderKey[];
export const EBUploadDocInstructions = () => {
  const [docList, setDoclist] = useState<DocItem[]>([]);

  const fetchDocumentList = useCallback(async () => {
    const orderId = EmployerCDCStore.request_id;
    const packageId = EmployerCDCStore.request_role_id;
    try {
      const res = await axiosInstance.get(
        `${endpoints.GET_LIST_OF_DOC}/${orderId}/${packageId}`
      );
      if (res.data) {
        setDoclist(res.data?.data?.listOfDocuments);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch document list', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  EmployerCDCStore.docListNumber = docList.length;
  useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  return (
    <Stack direction={'column'} rowGap={1}>
      <Typography className="bk-sub-heading2">
        What’s to be done here?
      </Typography>
      <Stack direction={'column'} rowGap={2}>
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          &#8226; Collect documents from each candidate. List of documents
          required is listed below:
        </Typography>
        <Stack
          direction={'column'}
          sx={{ background: COLORS.bk_bg_light, borderRadius: 1, p: 2 }}>
          {docList.map((doc: DocItem, index: number) => (
            <div key={doc.id}>
              <Typography
                className="bK-body1 text-[#616063]"
                sx={{ wordWrap: 'break-word' }}>
                {index + 1}. {doc.title}
              </Typography>
            </div>
          ))}
        </Stack>
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          &#8226; Make a zip file on the collected documents
        </Typography>
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          &#8226; Upload the zip file{' '}
        </Typography>
      </Stack>
    </Stack>
  );
};
export const EBUploadExcelInstructions = () => {
  const downloadTemplate = async () => {
    await generateTemplate();
  };

  return (
    <Stack direction={'column'} rowGap={1} className="mr-40px">
      <Typography className="bk-sub-heading2">
        What’s to be done here?
      </Typography>
      <Stack direction={'column'} rowGap={2}>
        {' '}
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          <span
            onClick={downloadTemplate}
            className="text-bk_action_primary cursor-pointer">
            Download Excel template
          </span>{' '}
          for candidate data
        </Typography>
        <Typography
          sx={{ wordWrap: 'break-word' }}
          className="bK-body1 text-bk_text_secondary">
          Fill the excel with appropriate details and upload on right panel
        </Typography>
        <Stack>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={downloadTemplate}
            sx={{ '&.MuiButton-root': { textTransform: 'none' } }}>
            Download template
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
const EBUploadDetails = ({
  acceptedFileTypes,
  description,
  type,
}: {
  type: string;
  description: string;
  acceptedFileTypes: string[];
}) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [docs, setDocs] = useState([]);
  const [headerKeys, setHeaderKeys] = useState<HeaderKeys>();

  const snapshot = useSnapshot(EmployerCDCStore);

  const fetchDataTable = async (order_id: string, package_id: string) => {
    setLoading(true);
    setHeaderKeys([]);
    if (type === 'excel') {
      try {
        setFiles([]);
        const response = await axiosInstance.get(
          `${endpoints.UPLOAD_EMPLOYER_BULK_S3}/list/${order_id}/${package_id}/excel`
        );
        const excelData = response.data.data;

        const responseHeader = await axiosInstance.get(
          `${endpoints.BULK_UPLOAD_TEMPLATE}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
        );

        const dataGroups = responseHeader.data.data.header;
        const header: any[] = [];

        dataGroups.forEach(
          (item: {
            field_product_type_group: any;
            objects: Array<{ name: string; label: string } | string>;
          }) => {
            item.objects.forEach(obj => {
              if (typeof obj !== 'string') {
                header.push(obj);
              } else {
                header.push({ name: obj, label: obj });
              }
            });
          }
        );

        setHeaderKeys(header);

        if (response.data) {
          setDocs([]);
          setFiles(excelData.responseData);
        }
      } catch (e) {
        enqueueSnackbar('Template is incorrect', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      try {
        const response = await axiosInstance.get(
          `${endpoints.UPLOAD_EMPLOYER_BULK_S3}/list/${order_id}/${package_id}/zip`
        );
        const excelData = response.data.data;
        if (response.data) {
          setFiles([]);
          setDocs(excelData.responseData);
          EmployerCDCStore.docUploadCount = excelData.responseData.length;
          if (excelData.responseData.length > 0) {
            EmployerCDCStore.stepperStatus = EmployerCDCStore.stepperStatus.map(
              item => {
                if (item.step === 2) {
                  return { ...item, progress: excelData.status };
                }
                return item;
              }
            );
          }
        }
      } catch (e) {
        enqueueSnackbar('Doc not uploaded', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataTable(
      EmployerCDCStore.request_id,
      EmployerCDCStore.request_role_id
    );
  }, [type, snapshot.toggle]);

  return !loading ? (
    <Grid
      container
      direction={'row'}
      // justifyContent={'space-between'}
      columnSpacing={5}
      className="w-full ml-1">
      {files?.length ? (
        <EditableTable files={files} headerKeys={headerKeys} />
      ) : (
        ''
      )}
      {!files?.length ? (
        <Grid
          item
          container
          rowGap={3}
          direction={'row'}
          className="bk-drag-drop-excel">
          {type === 'excel' ? (
            <EBDragDropExcel
              acceptedFileTypes={acceptedFileTypes}
              type={type}
              description={description}
              fetchDataTable={fetchDataTable}
            />
          ) : EmployerCDCStore.dataMapped ===
            DATA_MAPPING_PROGRESS_STEP.SUBMITTED ? (
            <EBDataCompleted />
          ) : (
            <EBDragDropDoc
              acceptedFileTypes={acceptedFileTypes}
              type={type}
              description={description}
              fetchDataTable={fetchDataTable}
            />
          )}
          {docs?.length ? (
            <EBFileDetails files={docs} fetchDataTable={fetchDataTable} />
          ) : type === 'excel' && !files?.length ? (
            <EBNoFiles />
          ) : (
            <></>
          )}
        </Grid>
      ) : null}
      <Grid item sx={{ maxWidth: '30%' }}>
        {type === 'excel' ? (
          !files?.length ? (
            <EBUploadExcelInstructions />
          ) : (
            ''
          )
        ) : (
          <EBUploadDocInstructions />
        )}
      </Grid>
    </Grid>
  ) : (
    <CircularIndeterminate />
  );
};

export default EBUploadDetails;
