import { BKStyledEBTabs } from '@gigin-work-space/common-ui';
import { EmployerCDCStore } from '@gigin-work-space/store';
import { Box, Tab } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

/* eslint-disable-next-line */
interface CommonTabBarProps {
  listLabel: string[];
  listComponent: ReactElement[];
  disabled: boolean[] | undefined;
  vertical: boolean;
  defaultIndex?: number;
}

export const EBTabBar = ({
  listLabel,
  listComponent,
  disabled = [],
  vertical,
  defaultIndex,
}: CommonTabBarProps) => {
  const [value, setValue] = useState<number>(defaultIndex ?? 0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    // reseting tab value on tab change
    EmployerCDCStore.activeStep = 0;
  }, [value]);

  return (
    <div>
      <BKStyledEBTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        orientation={vertical ? 'vertical' : 'horizontal'}
        sx={{ '& .MuiButtonBase-root': { textTransform: 'none' } }}>
        {listLabel.map((label, index) => {
          return (
            // <Box>
            <Tab
              value={index}
              key={index.toString()}
              label={label}
              disabled={disabled ? disabled[index] : false}
            /> // Use the provided disabled prop or default to false
            // {listLabel.length - 1 !== index && <Divider orientation="vertical" flexItem />}
            // </Box>
          );
        })}
      </BKStyledEBTabs>

      {/* <Divider orientation={vertical ? 'horizontal' : 'vertical'} flexItem /> */}
      {listComponent.map((component, index) => {
        return value === index && <Box> {component}</Box>;
      })}
    </div>
  );
};

export default EBTabBar;
