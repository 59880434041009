import { UnAuthorizedEmptyState } from '@gigin-work-space/common-ui';
import { ReactNode } from 'react';

interface WithPermissionProps {
  permissions: boolean[];
  children: ReactNode;
}

export const withViewPermission = ({
  permissions,
  children,
}: WithPermissionProps) => {
  if (permissions.some(permission => permission === true)) {
    return <>{children}</>;
  } else {
    return <UnAuthorizedEmptyState />;
  }
};

export default withViewPermission;
