import React from 'react';

interface IconProps {
  color: string;
}

export const ProgressLineSVG: React.FC<IconProps> = ({ color }) => {
  return (
    <svg width="60" height="2" viewBox="0 0 60 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="2" fill={color} />
    </svg>
  );
};
