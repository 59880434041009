/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as XLSX from 'xlsx';

export interface RecruiterBulkDataGroup {
  field_product_type_group: string;
  objects: Array<{ name: string; label: string }>;
}

// Process data to extract headers and subheaders
export const extractHeadersAndSubheaders = (
  dataGroups: RecruiterBulkDataGroup[]
) => {
  if (!Array.isArray(dataGroups)) {
    throw new Error('Invalid dataGroups parameter');
  }

  const headers: string[] = [];

  const subHeaders: string[] = [];

  dataGroups.forEach(group => {
    if (!group || !Array.isArray(group.objects)) {
      return;
    }

    const groupLabel = group.field_product_type_group;
    group.objects.forEach(obj => {
      headers.push(groupLabel);
      subHeaders.push(obj.label);
    });
  });

  return { headers, subHeaders };
};

// Generate Excel template
export const createExcelTemplate = (dataGroups: RecruiterBulkDataGroup[]) => {
  if (!Array.isArray(dataGroups)) {
    throw new Error('Invalid dataGroups parameter');
  }

  const { headers, subHeaders } = extractHeadersAndSubheaders(dataGroups);

  const workbook = XLSX.utils.book_new();

  const worksheetData: any[][] = [headers, subHeaders];
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Merge cells for headers
  const merges: XLSX.Range[] = [];
  let colIndex = 0;

  dataGroups.forEach(group => {
    if (!group || !Array.isArray(group.objects)) {
      return;
    }

    const subheaderCount = group.objects.length;

    if (subheaderCount > 1) {
      merges.push({
        s: { c: colIndex, r: 0 }, // Start of merge (column, row)
        e: { c: colIndex + subheaderCount - 1, r: 0 }, // End of merge
      });
    }

    colIndex += subheaderCount;
  });

  worksheet['!merges'] = merges;
  worksheet['!cols'] = headers.map(() => ({ wch: 20 })); // Set column width

  // Apply bold style to headers
  headers.forEach((label, index) => {
    const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
    if (!worksheet[cellAddress]) worksheet[cellAddress] = {};

    worksheet[cellAddress].s = { font: { bold: true } };
  });

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
  XLSX.writeFile(workbook, 'Template.xlsx');
};
