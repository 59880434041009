import { BKStyledButton, EmptyState } from '@gigin-work-space/common-ui';
import { Box, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationPaper, DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { AuthenticationHOCForSignup } from '../../authentication/authentication-hoc/authentication-hoc-without-background';
import CircularIndeterminate from './skelton/bgv-loader';

function OnBoardedRequestApproved() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const data = searchParams.get('data');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data) {
      globalNavigateTo('/bgv', navigate);
      return;
    }

    const authToken = localStorage.getItem('auth_token');

    if (!authToken) {
      globalNavigateTo(
        `/login?redirectTo=user/on-board/approval?data=${encodeURIComponent(
          data
        )}`,
        navigate
      );

      return;
    }
    const decodedData = decodeURIComponent(data);
    handleRequestApproved(decodedData);
  }, [data]);

  const handleRequestApproved = async (param: string) => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.UPDATE_USER}/request/approve`,
        {
          params: { data: param },
        }
      );

      if (response.data.success) {
        setIsLoading(false);
      } else {
        enqueueSnackbar('Failed to send request', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to send request', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const goToDashboard = () => {
    globalNavigateTo('/bgv', navigate);
  };

  return (
    <>
      {!data && (
        <EmptyState
          maxHeight={'100vh'}
          imageComponent={
            <DisplayImage
              imageName="404.svg"
              imageType="icon"
              width="370px"
              height="370px"
            />
          }
          buttonComponent={
            <BKStyledButton
              variant="contained"
              className="bg-bk_action_primary mt-2 rounded-lg"
              onClick={goToDashboard}>
              Go To Dashboard
            </BKStyledButton>
          }
          title="Not Found"
          description="The page you were looking for doesn't exist."
        />
      )}
      <AuthenticationHOCForSignup>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <AuthenticationPaper>
            <Box className="bk-on-boarded p-4">
              <Grid
                className="mt-8"
                display={'flex'}
                justifyContent={'space-around'}>
                <Grid className="bk-company-verified-item mb-8">
                  <Typography className="bk-caption-verified">
                    Request Approved
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Grid className="bk-company-verified-request-join">
              <BKStyledButton
                data-testid="submitBtn"
                className="bg-bk_action_primary mt-8"
                size="small"
                variant="contained"
                onClick={goToDashboard}>
                Go To Dashboard
              </BKStyledButton>
            </Grid>
          </AuthenticationPaper>
        )}
      </AuthenticationHOCForSignup>
    </>
  );
}

export default React.memo(OnBoardedRequestApproved);
