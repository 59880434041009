import {
  EmployerCDCStore,
  IPermissionStoreProps,
} from '@gigin-work-space/store';
import {
  DATA_MAPPING_PROGRESS_STEP,
  EmployerAppAssetIconsFileNames,
} from '@gigin-work-space/utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import ViewCandidateList from './employeer-bulk-view-candidate-list';
import { HoverContainer } from './employer-bulk-both-link-and-bulk';
import { BulkUploadProgress } from './employer-bulk-upload-progress';

const EmployerBulkOnlyBulk = (props: {
  packageInfo: any;
  permissions: IPermissionStoreProps;
}) => {
  const packageInfo = props.packageInfo;
  const [isStepperStatusCompleted, setIsStepperStatusCompleted] =
    useState<boolean>(false);
  const [successPercentage, setSuccessPercentage] = useState<number>(0);

  const { canUploadTemplate } = props.permissions;
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleLink = async () => {
    try {
      const dataCollectionIDList: string[] = [];
      packageInfo.candidateDetails.map((item: { dataCollectionId: string }) => {
        dataCollectionIDList.push(item.dataCollectionId);
      });
      const response = await axiosInstance.post(
        `${endpoints.BULK_LINK_SEND}`,
        dataCollectionIDList
      );

      if (response.data.success) {
        const linkStatusUpdated = await axiosInstance.patch(
          `${endpoints.BULK_DATA_COLLECTION_LINK}/${EmployerCDCStore.request_id}/${packageInfo.packageDetails.id}`
        );

        if (linkStatusUpdated.data.success) {
          enqueueSnackbar('Link sent successfully', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
        }
      }
    } catch (error) {
      enqueueSnackbar('Error while sending link', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleClick = () => {
    if (!canUploadTemplate) {
      enqueueSnackbar(`You don't have permission, Please constact your admin`, {
        variant: 'warning',
        autoHideDuration: 2000,
      });
      return;
    }
    handleNavigateEBMainContent(packageInfo);
  };
  const handleNavigateEBMainContent = (pkg: {
    candidateDetails: { jobRole: string }[];
    packageDetails: { id: string; title: string };
    dataCollectionSurvey: { numberOfCandidates: number };
  }) => {
    const uniqueJobRoles: string[] = [
      ...new Set(
        pkg.candidateDetails.map(
          (item: { jobRole: string }) => item.jobRole as string
        )
      ),
    ];

    const jobRoleCount: { [key: string]: number } = {};

    packageInfo.candidateDetails.forEach((candidate: { jobRole: any }) => {
      const jobRole = candidate.jobRole;
      jobRoleCount[jobRole] = (jobRoleCount[jobRole] || 0) + 1;
    });

    const result = Object.entries(jobRoleCount).map(([title, total_count]) => ({
      title,
      total_count,
    }));

    EmployerCDCStore.activeStep = 1;
    EmployerCDCStore.request_role_id = pkg.packageDetails.id;
    EmployerCDCStore.totalCount = pkg.dataCollectionSurvey.numberOfCandidates;
    EmployerCDCStore.currentJobRoles = result;
    EmployerCDCStore.currentRole = uniqueJobRoles;
    EmployerCDCStore.currentPackage = pkg.packageDetails.title;
  };

  useEffect(() => {
    const orderId = EmployerCDCStore.request_id;
    const packageId = packageInfo.packageDetails.id;
    const fetchData = async () => {
      try {
        const stepStatusResponse = await axiosInstance.get(
          `${endpoints.BULK_UPLOAD_STEP_STATUS}/${orderId}/${packageId}`
        );

        if (stepStatusResponse.data.success) {
          let percentage = 0;
          //TODO: Pull this from Enum
          if (
            stepStatusResponse.data.data.step_1 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.step_2 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.step_3 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.dataMapped ===
            DATA_MAPPING_PROGRESS_STEP.SUBMITTED
          ) {
            percentage = 100;
            setIsStepperStatusCompleted(true);
          }
          setSuccessPercentage(percentage);
        }
      } catch (e) {
        enqueueSnackbar('Error while fetching status', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Stack className="mt-7" direction={'column'} rowGap={0.5}>
        <Stack direction={'row'} rowGap={0.5}>
          <DisplayImage
            imageName={EmployerAppAssetIconsFileNames.UPLOAD_BULK_ICON_SVG}
            imageType="icon"
            width="12px"
            height="12px"
            className="mb-2"
          />
          <Typography className="ml-1 bk-sub-heading2">
            <b>Uploaded for candidate data</b>
          </Typography>
          <Typography className="bk-only-bulk-typography" onClick={handleClick}>
            {isStepperStatusCompleted ? 'View Details' : 'Go to Upload'}
            <ArrowForwardIosIcon className="ml-[3px] text-xs" />
          </Typography>
        </Stack>
        <BulkUploadProgress
          orderCompleteStatus={{
            fail_count: 0,
            fail_percentage: 0,
            success_count: 0,
            success_percentage: successPercentage,
            total_count: packageInfo.dataCollectionSurvey.numberOfCandidates,
            warn_count: 0,
            warn_percentage: 0,
          }}
          isStepperStatusCompleted={isStepperStatusCompleted}
        />
      </Stack>
      <Stack direction={'row'}>
        <HoverContainer
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleOpenModal}>
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">
              {packageInfo.bulkUpload.candidateDetailsUploaded}{' '}
            </b>
            Candidate details Uploaded
            {isHovered && <VisibilityIcon className="bk-eye-icon" />}
          </Typography>
        </HoverContainer>
        {packageInfo?.bulkUpload?.numberOfCandidates -
        packageInfo?.bulkUpload?.candidateDetailsUploaded ? (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="bk-only-bulk-divider"
          />
        ) : (
          ''
        )}

        {packageInfo?.bulkUpload?.numberOfCandidates -
        packageInfo?.bulkUpload?.candidateDetailsUploaded ? (
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body2">
              {packageInfo.bulkUpload.numberOfCandidates -
                packageInfo.bulkUpload.candidateDetailsUploaded}{' '}
            </b>{' '}
            Yet to upload
          </Typography>
        ) : (
          ''
        )}
      </Stack>
      <Box className="bk-only-bulk-box">
        <Stack direction={'row'}>
          <Typography className="bK-body1 p-[14px]">
            Collect data directly from candidate by sending a survey link to
            their Inbox
          </Typography>
          <Button
            className="bk-only-bulk-btn"
            onClick={() => {
              handleLink();
            }}>
            <DisplayImage
              imageName="send-link-icon.svg"
              imageType="icon"
              width="16px"
              height="16px"
            />
            <span className="bk-only-bulk-btn-text">Send Link</span>
          </Button>
        </Stack>
      </Box>
      <ViewCandidateList
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        packageInfo={packageInfo}
        // handleDataCollectionLink={handleDataCollectionLink}
      />
    </>
  );
};

export default EmployerBulkOnlyBulk;
