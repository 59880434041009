// eslint-disable-next-line @nx/enforce-module-boundaries
import { isModalOpenStore, permissionStore } from '@gigin-work-space/store';
import { BKBody1, BKBody1Select, COLORS } from '@gigin-work-space/utils';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { withViewPermission } from 'src/app/HOC';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { setTenantName, tenantState } from 'src/app/utils/router/tenantState';
import { useSnapshot } from 'valtio';
import {
  AlertCreateTeam,
  AlertInviteEmployee,
} from '../component/common-alert-popup';
import { CompanyProfile } from './components';
import {
  AlertEditTeam,
  AlertViewTeamList,
} from './components/teams/view-team-list';

export function CompanySettings() {
  const { isOpen } = useSnapshot(isModalOpenStore);
  const location = useLocation();
  setTenantName(tenantState.tenantName);
  const navigate = useNavigate();

  const {
    canCreateTeams,
    canReadTeams,
    canUpdateTeams,
    canReadUsers,
    canCreateUsers,
    canUpdateUsers,
    canReadPackage,
    canCreatePackage,
    canUpdatePackage,
    canReadPreference,
    canCreatePreference,
    canUpdatePreference,
    canReadCompanyProfile,
    canCreateCompanyProfile,
    canUpdateCompanyProfile,
    canReadRoles,
    canCreateRoles,
    canUpdateRoles,
  } = useSnapshot(permissionStore);
  const children = (
    <>
      {/* Top header section START*/}
      <section className="sticky top-0 z-10 bg-bk_bg_primary">
        <div className="flex justify-between items-center p-4">
          <Typography className="bk-headline1">Company settings</Typography>
        </div>
        <Divider sx={{ color: COLORS.bk_stroke_secondary }} />
      </section>
      {/* Top header section END*/}
      {/* Middle Analytics section START */}

      {/* TODO:perment drawer */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex !important',
          width: '100%',
          minHeight: 'calc(100% - 4.25rem)',
        }}>
        <Drawer
          variant="permanent"
          sx={{
            width: '12.25rem',
            flexShrink: 0,
            borderRight: `1px solid ${COLORS.bk_stroke_divider}`,
            [`& .MuiDrawer-paper`]: {
              boxSizing: 'border-box',
              borderWidth: 0,
              position: 'initial',
              background: `${COLORS.bk_bg_secondary}`,
            },
          }}>
          <List className="px-2">
            <ListItem
              disablePadding
              onClick={() => {
                globalNavigateTo('/bgv/company-settings', navigate);
              }}
              style={
                location.pathname.endsWith('/bgv/company-settings') ||
                location.pathname.endsWith('/bgv/company-settings/')
                  ? {
                      backgroundColor: `${COLORS.bk_tag_action_bg}`,
                      borderRadius: '8px',
                    }
                  : { backgroundColor: 'transparent' }
              }>
              <ListItemButton>
                <ListItemText
                  primary={'Company profile'}
                  primaryTypographyProps={
                    location.pathname.endsWith('/bgv/company-settings') ||
                    location.pathname.endsWith('/bgv/company-settings/')
                      ? BKBody1Select
                      : BKBody1
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                globalNavigateTo('/bgv/company-settings/user_teams', navigate);
              }}
              style={
                location.pathname.includes('company-settings/user_teams')
                  ? {
                      backgroundColor: `${COLORS.bk_tag_action_bg}`,
                      borderRadius: '8px',
                    }
                  : { backgroundColor: 'transparent' }
              }>
              <ListItemButton>
                <ListItemText
                  primary={'User & Teams'}
                  primaryTypographyProps={
                    location.pathname.includes('company-settings/user_teams')
                      ? BKBody1Select
                      : BKBody1
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                globalNavigateTo(
                  '/bgv/company-settings/role_management',
                  navigate
                );
              }}
              style={
                location.pathname.includes('company-settings/role_management')
                  ? {
                      backgroundColor: `${COLORS.bk_tag_action_bg}`,
                      borderRadius: '8px',
                    }
                  : { backgroundColor: 'transparent' }
              }>
              <ListItemButton>
                <ListItemText
                  primary={'Role management'}
                  primaryTypographyProps={
                    location.pathname.includes(
                      'company-settings/role_management'
                    )
                      ? BKBody1Select
                      : BKBody1
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List
            subheader={
              <Box>
                <ListSubheader
                  style={{
                    background: 'transparent',
                    padding: '16px',
                  }}>
                  <Typography className="bk-sub-heading2 text-bk_text_primary">
                    Verification services
                  </Typography>
                </ListSubheader>
              </Box>
            }>
            <ListItem
              disablePadding
              onClick={() => {
                globalNavigateTo('/bgv/company-settings/packages', navigate);
              }}
              style={
                location.pathname.includes('company-settings/packages')
                  ? {
                      backgroundColor: `${COLORS.bk_tag_action_bg}`,
                      borderRadius: '8px',
                    }
                  : { backgroundColor: 'transparent' }
              }>
              <ListItemButton>
                <ListItemText
                  primary={'Packages'}
                  primaryTypographyProps={
                    location.pathname.includes('company-settings/packages')
                      ? BKBody1Select
                      : BKBody1
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                globalNavigateTo('/bgv/company-settings/preferences', navigate);
              }}
              style={
                location.pathname.includes('company-settings/preferences')
                  ? {
                      backgroundColor: `${COLORS.bk_tag_action_bg}`,
                      borderRadius: '8px',
                    }
                  : { backgroundColor: 'transparent' }
              }>
              <ListItemButton>
                <ListItemText
                  primary={'Preferences'}
                  primaryTypographyProps={
                    location.pathname.includes('company-settings/preferences')
                      ? BKBody1Select
                      : BKBody1
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box className="flex grow">
          {' '}
          {location.pathname ===
          `/${tenantState.tenantName}/bgv/company-settings` ? (
            <CompanyProfile />
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
      {/* Middle Analytics section END */}
      {/* Modal Section START */}
      {isOpen.createTeam && <AlertCreateTeam />}
      {isOpen.inviteEmployee && <AlertInviteEmployee />}
      {isOpen.teamList && <AlertViewTeamList />}
      {isOpen.editTeam && <AlertEditTeam />}
      {/* Modal Section END */}
    </>
  );
  return withViewPermission({
    permissions: [
      canReadPackage,
      canCreatePackage,
      canUpdatePackage,
      canReadPreference,
      canCreatePreference,
      canUpdatePreference,
      canReadTeams,
      canUpdateTeams,
      canCreateTeams,
      canReadUsers,
      canCreateUsers,
      canUpdateUsers,
      canReadCompanyProfile,
      canCreateCompanyProfile,
      canUpdateCompanyProfile,
      canReadRoles,
      canCreateRoles,
      canUpdateRoles,
    ],
    children: children,
  });
}

export default CompanySettings;
