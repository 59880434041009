/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  COUNTDOWN_TIMER_FIVE,
  DataCollectionItemSource,
} from '@gigin-work-space/utils';
import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import * as React from 'react';
import successTickLottie from 'src/assets/lottieJson/success_tick_lottie.json';

export interface OrderCreatedSuccessModalProps {
  open: boolean;
  mode:
    | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
    | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION;
  handleClose: () => void;
  handleCTA: () => void;
}

export const OrderCreatedSuccessModal = React.memo(
  ({ open, mode, handleClose, handleCTA }: OrderCreatedSuccessModalProps) => {
    const [countdown, setCountdown] =
      React.useState<number>(COUNTDOWN_TIMER_FIVE);

    const handleModalClose = (event: React.SyntheticEvent, reason: string) => {
      if (reason === 'backdropClick') {
        // Do nothing, don't close the modal
        return;
      }
      // Close the modal as usual
      handleClose();
    };

    React.useEffect(() => {
      let timer: NodeJS.Timeout;
      if (open) {
        setCountdown(COUNTDOWN_TIMER_FIVE); // Reset countdown to 5 when modal opens
        timer = setInterval(() => {
          setCountdown(prev => {
            if (prev === 1) {
              clearInterval(timer);
              handleCTA(); // Trigger handleCTA when countdown reaches 0
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }

      return () => {
        clearInterval(timer); // Clear timer when modal closes or component unmounts
      };
    }, [open, handleCTA]);

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleModalClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={open}>
            <Box className="bk_close_modal p-xl2 rounded-lg flex flex-col items-center justify-center gap-y-xl2 md:min-w-[400px] lg:min-w-[600px]">
              {/* Header section */}
              <Box className="w-[168px] h-auto">
                <Lottie animationData={successTickLottie} loop={true} />
              </Box>
              {/* Main section */}
              <Box className="flex flex-col gap-y-xs2 items-center justify-center">
                <Typography className="bk-typography-h6">
                  Order created sucessfully
                </Typography>
                <Typography className="bk-typography-body2">
                  You will be redirected to the documents upload in ({countdown}
                  s)
                </Typography>
              </Box>
              {/* Footer section */}
              <Box className="w-[272px]">
                <BKStyledButton
                  className="rounded-[4px]"
                  variant="contained"
                  fullWidth
                  onClick={handleCTA}>
                  {mode === DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
                    ? `Upload documents`
                    : `Go to dashboard`}
                </BKStyledButton>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
);
