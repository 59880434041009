import { COLORS } from '@gigin-work-space/utils';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
interface FilterInterface {
  filterDays: string | number;
  handleDaysChange: (_event: SelectChangeEvent<string | number>) => void;
}

interface IFilterValues {
  value: string;
  label: number | string;
}

const filterValues = [
  { value: 'All', label: 'All' },
  { value: '90', label: 'Last 90 days' },
  { value: '30', label: 'Last 30 days' },
  { value: '15', label: 'Last 15 days' },
  { value: '7', label: 'Last 7 days' },
];

const FilterByDays = ({ filterDays, handleDaysChange }: FilterInterface) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <FormControl
        variant="standard"
        sx={{
          minWidth: 300,
          '& .MuiInput-input': {
            padding: '8px',
            border: `1px solid ${COLORS.bk_stroke_primary}`,
            borderRadius: '8px',
            background: COLORS.bk_bg_primary,
          },
        }}>
        <Select
          id="filterDays"
          name="filterDays"
          value={filterDays}
          onChange={handleDaysChange}
          className="bK-body2 text-base text-bk_text_secondary">
          {filterValues?.map((option: IFilterValues) => (
            <MenuItem id={option?.value} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default FilterByDays;
