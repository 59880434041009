import {
  capitalizeString,
  firstLetterReturner,
  getAccordionColor,
} from '@gigin-work-space/utils';
import { Avatar, Typography } from '@mui/material';
import { Stack } from '@mui/system';

const EBTableUserDetails = ({
  createdBy,
  index,
}: {
  createdBy: string;
  index: number;
}) => {
  return (
    <Stack spacing={0.5} direction={'row'} className="flex items-center">
      <Avatar
        variant="circular"
        style={{ height: '24px', width: '24px' }}
        sx={{ bgcolor: getAccordionColor(index) }}>
        {firstLetterReturner(createdBy.toString())}
      </Avatar>
      <Typography className="bK-body2">
        {capitalizeString(createdBy)}
      </Typography>
    </Stack>
  );
};

export default EBTableUserDetails;
