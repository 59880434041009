/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { BKStyledButton } from '@gigin-work-space/common-ui';
import { guardedPageNavigate, SPACING } from '@gigin-work-space/utils';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper, OTP } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { setTenantName } from 'src/app/utils/router/tenantState';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';

export function MfaOtpVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState('');
  const [isOtpValid, setIsOtpValid] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const userDetails = React.useMemo(() => {
    return JSON.parse(localStorage.getItem('userAccountDetail') || '{}');
  }, []);

  const verificationToken = React.useMemo(() => {
    return JSON.parse(localStorage.getItem('auth_token') || '{}');
  }, []);

  const handleSubmit = async () => {
    try {
      const otpVerificationResponse = await axiosInstance.post(
        endpoints.POST_AUTH_VERIFY_TOTP,
        {
          totpCode: otp,
          token: verificationToken,
        }
      );

      const response = otpVerificationResponse?.data;
      console.log('response', response);
      if (response && response?.success) {
        setOtp('');
        enqueueSnackbar('Logged in successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        localStorage.setItem(
          'auth_token',
          JSON.stringify(response?.data?.session_token)
        );
        setTenantName(userDetails?.tenantName);
        globalNavigateTo(guardedPageNavigate(), navigate);
      } else {
        throw new Error('Failed to verify OTP. Try again');
      }
    } catch (error) {
      setOtp('');
      enqueueSnackbar('Failed to verify OTP. Try again', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const isBtnDisabled = otp.length !== 6;

  return (
    <AuthenticationHOC>
      <section className="absolute bottom-[5%] left-[-160px]">
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary">
            Two Factor Authentication
          </Typography>

          <Box
            className="mt-lg flex flex-col justify-start"
            component="form"
            noValidate
            autoComplete="off">
            <>
              <Typography className="bk-body1 my-2 bk-text-primary-color">
                Enter the code from your MFA virtual app below
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: SPACING.xl,
                }}>
                <OTP
                  separator={null}
                  value={otp}
                  onChange={setOtp}
                  length={6}
                  isOtpValid={isOtpValid}
                />
              </Box>
              <BKStyledButton
                data-testid="submitBtn"
                className="bg-bk_action_primary mt-xl"
                fullWidth
                size="large"
                variant="contained"
                onClick={handleSubmit}
                disabled={isBtnDisabled}>
                Submit
              </BKStyledButton>
            </>
          </Box>
        </AuthenticationPaper>
      </section>
    </AuthenticationHOC>
  );
}

export default React.memo(MfaOtpVerification);
