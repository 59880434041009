import { IProgressStatusProps } from '@gigin-work-space/model';
import {
  COLORS,
  ORDER_FLOW_STATUS,
  PROGRESS_STATUS,
  getStatusBgColor,
  getStatusColor,
  hasCheckStatusError,
} from '@gigin-work-space/utils';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import { ProgressLineSVG } from './progress-line';

export const CheckInProgressSvg: React.FC<IProgressStatusProps> = ({ status, message }) => {
  const color = getStatusColor(status);
  const bgColor = getStatusBgColor(status);

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={message ? message : ORDER_FLOW_STATUS.VERIFICATION} placement="top" arrow>
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          {hasCheckStatusError(status) && (
            <path
              d="M15.7929 29C16.2383 29 16.4614 28.4614 16.1464 28.1464L13.3536 25.3536C13.1583 25.1583 12.8417 25.1583 12.6464 25.3536L9.85355 28.1464C9.53857 28.4614 9.76165 29 10.2071 29H15.7929Z"
              fill={color}
            />
          )}
          <circle
            cx="13"
            cy="13"
            r="12.5"
            fill={bgColor}
            stroke={status.toUpperCase() === PROGRESS_STATUS.PENDING ? COLORS.bk_tag_gray_bg : color}
          />
          <path
            d="M12.4808 14.9521C12.6492 14.9521 12.8175 14.8812 12.9354 14.7394L15.2082 12.1518C15.4439 11.8861 15.4271 11.4783 15.1745 11.2302C14.922 10.9821 14.5348 10.9998 14.2991 11.2657L12.4809 13.3215L11.7233 12.453C11.4876 12.1872 11.1005 12.1694 10.8479 12.4175C10.5954 12.6656 10.5785 13.0732 10.8142 13.3391L12.0431 14.7215C12.1442 14.8811 12.3125 14.9521 12.4808 14.9521Z"
            fill={color}
          />
          <path
            d="M16.4879 17.3405C15.5115 18.209 14.2825 18.6874 13.003 18.6874C10.0232 18.6874 7.59897 16.1353 7.59897 12.9984V12.644H8.37345C8.57543 12.644 8.76063 12.5199 8.86161 12.3427C8.9626 12.1478 8.94581 11.9173 8.84481 11.7402L7.4475 9.43617C7.24552 9.0994 6.70673 9.0994 6.50476 9.43617L5.09065 11.7402C4.97274 11.9174 4.97274 12.1478 5.07385 12.3427C5.17485 12.52 5.36004 12.6441 5.56201 12.6441H6.33649V12.9985C6.33649 16.8621 9.31633 19.9991 12.9863 19.9991C14.552 19.9991 16.084 19.4142 17.2793 18.3509C17.5487 18.1205 17.5823 17.6952 17.3466 17.4293C17.1446 17.1457 16.7573 17.1103 16.488 17.3406L16.4879 17.3405Z"
            fill={color}
          />
          <path
            d="M20.9323 13.6562C20.8313 13.4613 20.6461 13.3549 20.4442 13.3549H19.6697V13.0005C19.6697 9.13694 16.6899 6 13.0198 6C11.4542 6 9.92218 6.58485 8.72688 7.64816C8.4575 7.8786 8.42391 8.30387 8.65959 8.56978C8.87849 8.85337 9.28246 8.88873 9.53505 8.64062C10.5115 7.77217 11.7404 7.29371 13.0199 7.29371C15.9998 7.29371 18.424 9.84583 18.424 12.9827V13.3372H17.6495C17.4475 13.3372 17.2623 13.4613 17.1613 13.6384C17.0604 13.8334 17.0771 14.0638 17.1781 14.241L18.5755 16.545C18.6764 16.7223 18.8616 16.8108 19.0468 16.8108C19.232 16.8108 19.4172 16.7045 19.5182 16.545L20.9155 14.241C21.0163 14.0815 21.0332 13.8511 20.9322 13.6561L20.9323 13.6562Z"
            fill={color}
          />
        </svg>
      </Tooltip>
      {status.toUpperCase() === PROGRESS_STATUS.INPROGRESS ||
      status.toUpperCase() === PROGRESS_STATUS.FAIL ||
      status.toUpperCase() === PROGRESS_STATUS.PENDING ||
      status.toUpperCase() === PROGRESS_STATUS.BLOCKED ? (
        <ProgressLineSVG color={COLORS.bk_stroke_divider} />
      ) : (
        <ProgressLineSVG color={color} />
      )}{' '}
    </Stack>
  );
};
