import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { COLORS, borderLessSelect } from '@gigin-work-space/utils';
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Plugin,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { IVsdAll, LegendPosition } from './IAnalytics';

ChartJS.register(ArcElement, Tooltip, Legend);

export function RoleWiseChart() {
  const [selectRange, setSelectRange] = useState(30);
  const [rwdAllData, setRwdAllData] = useState({} as IVsdAll);

  const handleSelectRange = (value: any) => {
    setSelectRange(value);
  };
  const data = {
    labels: rwdAllData?.distribution?.map(data => data.label) ?? [],
    datasets: [
      {
        label: '',
        data: rwdAllData?.distribution?.map(data => data.value) ?? [],
        backgroundColor: ['#079CB0', '#0755B0', '#E4A80E', '#EBEBEB'],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as LegendPosition,
        labels: {
          usePointStyle: true, // Use a point style legend
          fontSize: 6, // Set the font size of the legend labels
        },
      },
    },
    maintainAspectRatio: false, //to maintain custom chart height
    cutout: 90,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const textCenter: Plugin = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();

      // First line (bold)
      ctx.font = 'bolder 18px sans-serif';
      ctx.fillStyle = `${COLORS.bk_text_primary}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `${'0'}`,
        chart?.getDatasetMeta(0)?.data[0]?.x,
        chart?.getDatasetMeta(0)?.data[0]?.y + 15
      );

      // Second line (normal)
      ctx.font = '18px sans-serif';
      ctx.fillStyle = `${COLORS.bk_text_primary}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        'Total Candidate',
        chart?.getDatasetMeta(0)?.data[0]?.x,
        chart?.getDatasetMeta(0)?.data[0]?.y - 15
      );
    },
  };

  const fetchAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.RWD_ALL}?duration=${selectRange}&type=day`
      );
      if (response.data) {
        setRwdAllData(response?.data?.data ?? {});
      }
    } catch (e) {
      // console.error('Failed to fetch role wise distribution data:', e);
    }
  };
  useEffect(() => {
    fetchAll();
  }, [selectRange]);

  return (
    <Paper elevation={0} sx={{ background: COLORS.bk_bg_primary }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 1.5,
          borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
        }}>
        <Typography className="bk-heading1">Role wise distribution</Typography>
        <FormControl
          variant="standard"
          sx={{
            minWidth: 120,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '2px',
          }}>
          <Select
            id="selectRange"
            name="selectRange"
            value={selectRange}
            onChange={(event: any) => {
              handleSelectRange(event.target.value);
            }}
            label="recent"
            className="bK-btn-small"
            sx={borderLessSelect}>
            <MenuItem value={7}>Last 7 Days</MenuItem>
            <MenuItem value={15}>Last 15 Days</MenuItem>
            <MenuItem value={30}>Last 30 Days</MenuItem>
            <MenuItem value={60}>Last 60 Days</MenuItem>
            <MenuItem value={90}>Last 3 months</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Grid container direction={'column'} alignItems={'center'}>
        <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
          <Typography className="bk-headline2 my-4 text-center">
            Total Candidates {rwdAllData?.title?.value}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
          {rwdAllData.distribution?.length ? (
            <Doughnut
              data={data}
              options={options}
              height={300}
              // plugins={[textCenter]} // ignore this warning
            />
          ) : (
            <ChartEmptyState
              // maxHeight={'100vh'}
              imageComponent={
                <DisplayImage
                  imageName="rnd-chart.svg"
                  imageType="icon"
                  style={{
                    height: '250px',
                    width: 'auto',
                  }}
                />
              }
              description="Volume share of verification by roles"
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
