import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { COLORS, borderLessSelect } from '@gigin-work-space/utils';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Plugin,
  Title,
  Tooltip,
  registerables,
} from 'chart.js';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { IVsdAll, LegendPosition, indexAxis } from './IAnalytics';

// Register chartjs
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

/* eslint-disable-next-line */
export interface VerificationStatusDistributionProps {
  // analytics: AnalyticsData;
}

// eslint-disable-next-line no-empty-pattern
export function VerificationStatusDistribution({}: VerificationStatusDistributionProps) {
  const [selectRange, setSelectRange] = useState(30);
  const [vsdAllData, setVsdAllData] = useState({} as IVsdAll);
  const [vsdTopFiveData, setVsdTopFiveData] = useState([] as IVsdAll[]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectRange = (value: any) => {
    setSelectRange(value);
  };

  const statusValueFinder = (
    arr: {
      label: string;
      value: number;
    }[],
    status: string
  ): number => {
    const value = arr?.find(obj => obj.label === status)?.value;
    return value ? value : 0;
  };

  const fetchTopFiveAnalytics = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.VSD_TOPFIVE_FETCH}?duration=${selectRange}&type=day`
      );
      if (response.data) {
        setVsdTopFiveData(response?.data?.data ?? []);
      }
    } catch (e) {
      enqueueSnackbar(`Fetching TopFiveAnalytics is Failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const fetchAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.VSD_ALL}?duration=${selectRange}&type=day`
      );

      if (response.data) {
        setVsdAllData(response?.data?.data ?? {});
      }
    } catch (e) {
      enqueueSnackbar(`Fetching Verification Status Distribution is Failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    fetchAll();
    fetchTopFiveAnalytics();
  }, [selectRange]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const textCenter: Plugin = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();

      // First line (bold)
      ctx.font = 'bolder 18px sans-serif';
      ctx.fillStyle = `${COLORS.bk_text_primary}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `${vsdAllData?.title?.value ? vsdAllData?.title?.value : ''}`,
        chart?.getDatasetMeta(0)?.data[0]?.x,
        chart?.getDatasetMeta(0)?.data[0]?.y + 15
      );

      // Second line (normal)
      ctx.font = '18px sans-serif';
      ctx.fillStyle = `${COLORS.bk_text_primary}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        'Total Candidate',
        chart?.getDatasetMeta(0)?.data[0]?.x,
        chart?.getDatasetMeta(0)?.data[0]?.y - 15
      );
    },
  };
  //Options to add in charts
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as LegendPosition,
        labels: {
          usePointStyle: true, // Use a point style legend
          fontSize: 6, // Set the font size of the legend labels
        },
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    cutout: 90,
  };

  const barOptions = {
    indexAxis: 'x' as indexAxis,

    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false, //to maintain custom chart height
    barThickness: 12,
  };

  // sample data to show on chart
  const data = {
    labels: ['Green case', 'Red case', 'Unsure', 'In progress'],
    datasets: [
      {
        label: ' No of candidate',
        data: [
          statusValueFinder(vsdAllData?.distribution, 'SUCCESS'),
          statusValueFinder(vsdAllData?.distribution, 'BLOCKED'),
          statusValueFinder(vsdAllData?.distribution, 'UNSURE'),
          statusValueFinder(vsdAllData?.distribution, 'PENDING'),
        ],
        backgroundColor: ['#33B007', '#B04407', '#E4CF0E', '#EBEBEB'],
      },
    ],
  };

  const barData = {
    labels: vsdTopFiveData
      ? vsdTopFiveData?.map(data => data.title.label) ?? []
      : [],
    datasets: [
      {
        label: 'Progress',
        data: vsdTopFiveData?.map(data =>
          statusValueFinder(data.distribution, 'SUCCESS')
        ),
        backgroundColor: '#659CF5',
      },
      {
        label: 'Blocked',
        data: vsdTopFiveData?.map(data =>
          statusValueFinder(data.distribution, 'BLOCKED')
        ),
        backgroundColor: '#EE6E12',
      },
      {
        label: 'Unsure',
        data: vsdTopFiveData?.map(data =>
          statusValueFinder(data.distribution, 'UNSURE')
        ),
        backgroundColor: '#E4CF0E',
      },
      {
        label: 'Pending',
        data: vsdTopFiveData?.map(data =>
          statusValueFinder(data.distribution, 'PENDING')
        ),
        backgroundColor: '#EBEBEB',
      },
    ],
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        width: '100%',
        // px: 2,
        // py: 3,
      }}>
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${COLORS.bk_stroke_primary}`,
          borderRadius: '8px',
        }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            p: 2,
            pb: 1.5,
            borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
          }}>
          <Typography className="bk-sub-heading2">
            Verification status distribution
          </Typography>
          <FormControl
            variant="standard"
            sx={{
              minWidth: 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Select
              id="selectRange"
              name="selectRange"
              value={selectRange}
              onChange={(event: any) => {
                handleSelectRange(event.target.value);
              }}
              label="recent"
              className="bK-btn-small"
              sx={borderLessSelect}>
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={15}>Last 15 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={60}>Last 60 Days</MenuItem>
              <MenuItem value={90}>Last 3 months</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {vsdAllData.distribution?.length && vsdTopFiveData.length ? (
          <Grid container sx={{ mt: '40px', mx: '10px', mb: 4 }}>
            {/* <Grid item> */}
            <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
              <Typography className="bk-headline2 mb-6" sx={{ ml: '12%' }}>
                Total Candidates {vsdAllData?.title?.value}
              </Typography>
              <Doughnut
                data={data}
                options={options}
                height={300}
                // plugins={[textCenter]} // ignore this warning
              />
            </Grid>
            {/* </Grid> */}
            <Grid
              item
              container
              xs={12}
              md={7}
              justifyContent={'center'}
              direction={'column'}
              rowGap={'40px'}>
              {/* <Grid item>
              <Stack direction={'row'}>
                <Stack
                  sx={{
                    background: '#F1F1F1',
                    borderRadius: '50%',
                    width: '100px',
                    mr: 2,
                  }}></Stack>
                <Stack direction={'column'} spacing={0.25}>
                  <Typography className="bk-sub-heading2">90%</Typography>
                  <Typography className="bK-body2">Time compliance</Typography>
                </Stack>
              </Stack>
            </Grid> */}
              <Grid item>
                <Bar options={{ ...barOptions }} data={barData} height={250} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <ChartEmptyState
              // maxHeight={'100vh'}
              imageComponent={
                <DisplayImage
                  imageName="vsd-chart.svg"
                  imageType="icon"
                  style={{
                    height: '300px',
                    width: 'auto',
                  }}
                />
              }
              description="Analyse the the volume of verification requested across roles"
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default VerificationStatusDistribution;
