import { customAlphabet } from 'nanoid';

export const getDateFormated = (value: Date): string => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const formatted = `${day} ${month} ${year}`;
  return formatted;
};

export const genNanoId = (size: number): string => {
  const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', size);
  return nanoid();
};

export function getDifferenceInYearsAndMonths(date1: Date, date2: Date) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  let years = d2.getFullYear() - d1.getFullYear();
  let months = d2.getMonth() - d1.getMonth();

  // Adjust the years and months if necessary
  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months };
}

export function getHashTagBasedOnLang(hashTag: any, lang: string) {
  const formattedData = hashTag.map((item: any) => {
    return { ...item, skillName: item['skillName'][lang] };
  });
  return formattedData;
}

export function getJobRoleBasedOnLang(jobRole: any, lang: string) {
  const formattedData = jobRole.map((item: any) => {
    return { ...item, name: item['name'][lang] };
  });
  return formattedData;
}

import {
  BadRequestException,
  HttpException,
  HttpStatus,
  InternalServerErrorException,
  NotFoundException,
} from '@nestjs/common';

export function handleError(error: any): { status: number } {
  if (error instanceof HttpException) {
    return { status: error.getStatus() };
  } else if (error instanceof BadRequestException) {
    // Handle other error types
    return { status: HttpStatus.BAD_REQUEST };
  } else if (error instanceof InternalServerErrorException) {
    return { status: HttpStatus.INTERNAL_SERVER_ERROR };
  } else if (error instanceof NotFoundException) {
    return { status: HttpStatus.NOT_FOUND };
  }
  return { status: HttpStatus.INTERNAL_SERVER_ERROR };
}

export function compareDates(date1: Date, date2: Date) {
  // Convert both dates to timestamp for easy comparison
  const date1Time = new Date(date1).getTime();
  const date2Time = new Date(date2).getTime();

  if (date1Time < date2Time) {
    return 1;
  } else if (date1Time > date2Time) {
    return -1;
  } else {
    return 0;
  }
}

export function getDifferenceInSeconds(timestamp1: Date, timestamp2: Date) {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
    throw new Error('Invalid timestamp format');
  }

  const differenceInMilliseconds = date1.getTime() - date2.getTime();
  const differenceInSeconds = differenceInMilliseconds / 1000;
  return Math.abs(differenceInSeconds); // Ensure positive difference
}
export function getUniqueValues<T>(array: T[]): T[] {
  return [...new Set(array)];
}
