import { BKStyledButton } from '@gigin-work-space/common-ui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { AuthenticationPaper } from '../../../components';
import { RegisterSchema, axiosInstance, endpoints } from '../../../utils';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';

export const SignupViaLink = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      userName: '',
      userPassword: '',
      userConfirmPassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsApiLoading(true);
      const body = JSON.parse(localStorage.getItem('userDetails') as string);
      try {
        const response = await axiosInstance.post(endpoints.POST_AUTH_SIGNUP, {
          ...body,
          name: values.userName,
          password: values.userPassword,
          confirm_password: values.userConfirmPassword,
        });
        if (response.data.success) {
          enqueueSnackbar(response.data.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          globalNavigateTo('/login', navigate);
          return;
        }
        throw new Error('Failed to signup');
      } catch (error) {
        enqueueSnackbar('Failed to signup', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setIsApiLoading(false);
        resetForm();
      }
    },
  });

  // function to show the password on click
  const handleTogglePassword = () => {
    setTogglePassword(!togglePassword);
  };

  // function to show the confirm password on click
  const handleToggleConfirmPassword = () => {
    setToggleConfirmPassword(!toggleConfirmPassword);
  };

  // memoized variable to return the boolean value of btn disabled state
  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled || isApiLoading;
  }, [formik.values, isApiLoading]);

  return (
    <AuthenticationHOC>
      <section className="absolute bottom-[-18%] left-[-160px]">
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary">
            <span role="img" aria-label="Welcome">
              👋
            </span>{' '}
            Welcome
          </Typography>
          <Typography className="bK-body1 text-bk_text_secondary">
            Please set up your log-in password
          </Typography>
          <Box
            className="mt-6"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}>
            <TextField
              id="userName"
              name="userName"
              label="Your name"
              variant="outlined"
              fullWidth
              required
              value={formik.values.userName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={
                formik.touched.userName &&
                formik.errors.userName &&
                'Username must be at least 2 characters'
              }
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': { borderRadius: '8px !important' },
              }}
              inputProps={{ 'data-testid': 'userName' }}
            />
            <TextField
              id="userPassword"
              name="userPassword"
              label="Enter new password"
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': { borderRadius: '8px !important' },
              }}
              variant="outlined"
              type={togglePassword ? 'text' : 'password'}
              fullWidth
              required
              value={formik.values.userPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.userPassword &&
                Boolean(formik.errors.userPassword)
              }
              helperText={
                formik.touched.userPassword && formik.errors.userPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton sx={{ p: 0 }} onClick={handleTogglePassword}>
                      {togglePassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-testid': 'userPassword' }}
            />
            <TextField
              id="userConfirmPassword"
              name="userConfirmPassword"
              label="Re-enter password"
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': { borderRadius: '8px !important' },
              }}
              variant="outlined"
              type={toggleConfirmPassword ? 'text' : 'password'}
              fullWidth
              required
              value={formik.values.userConfirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.userConfirmPassword &&
                Boolean(formik.errors.userConfirmPassword)
              }
              helperText={
                formik.touched.userConfirmPassword &&
                formik.errors.userConfirmPassword &&
                'Passwords do not match'
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={handleToggleConfirmPassword}>
                      {toggleConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-testid': 'userConfirmPassword' }}
            />
            <BKStyledButton
              data-testid="submitBtn"
              className="bg-bk_action_primary mt-2 rounded-lg"
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              disabled={isBtnDisabled}
              // endIcon={<EastIcon />}
            >
              Confirm password
            </BKStyledButton>
          </Box>
        </AuthenticationPaper>
      </section>
    </AuthenticationHOC>
  );
};

export default SignupViaLink;
