import {
  FormControlLabel,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { styled, FormHelperText, Button } from '@mui/material';

// eslint-disable-next-line no-unused-vars
export const StyledGAuthExtraForm = styled('form')(({ theme }) => ({
  margin: '1rem 0 0 !important',
}));

export const StyledGAuthExtraTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fontSize: theme.customTheme?.fontSize?.small,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: theme.customTheme?.color?.black_rgb87,
}));

export const StyledFormHelperTextErr = styled(FormHelperText)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fontSize: theme.customTheme?.fontSize?.xsmall,
  lineHeight: '166%',
  letterSpacing: '0.4px',
  display: 'flex',
  color: theme.customTheme?.color?.error_text,
  alignItems: 'center',
  margin: theme.customTheme?.margin?.signup_option_err,
}));

export const StyledCompanyRegistered = styled(FormHelperText)(({ theme }) => ({
  marginTop: '-0.75rem !important',
  marginBottom: '0.55rem !important',
  color: theme.customTheme?.color?.success_text,
}));

// eslint-disable-next-line no-unused-vars
export const StyledUserDetailInput = styled(TextField)(({ theme }) => ({
  marginBottom: '1rem !important',
}));

export const StyledUserDetailRadioGroup = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.customTheme?.gap?.custom_6_percentage,
}));

export const StyledUserDetailRadioSelected = styled(FormControlLabel)(
  ({ theme, hireOption }: { theme: Theme; hireOption: string }) => ({
    // width: `${calc(50 % -calc(theme.customTheme.gap.custom_6_percentage / 2))}`,
    border: hireOption
      ? theme.customTheme?.border?.normal_selected
      : theme.customTheme?.border?.normal_black,
    borderRadius: theme.customTheme?.borderRadius?.custom_32,
    padding: '0.5rem 1rem 0.5rem 0 !important',
    margin: '0 !important',
  })
);

export const StyledUserDetailRadioTxt = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fonrSize: theme.customTheme?.fontSize?.small,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: theme.customTheme?.color?.black_rgb87,
}));

// eslint-disable-next-line no-unused-vars
export const StyledButtonSignupSubmit = styled(Button)(({ theme }) => ({
  margin: '0.75rem 0 0.5rem !important',
}));

export const StyledWelcomeHeader = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.medium,
  fontSize: theme.customTheme?.fontSize?.custom_34,
  lineHeight: '123.5%',
  letterSpacing: '0.25px',
  color: theme.customTheme?.color?.black_rgb87,
}));

export const StyledWelcomeHeaderEmoji = styled('span')(({ theme }) => ({
  marginLeft: theme.customTheme?.margin?.header_emoji,
}));
export const StyledWelcomeSubtitleText = styled(Typography)(({ theme }) => ({
  marginTop: '1rem !important',
  fontFamily: theme.customTheme?.fontFamily?.inter,
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fontSize: theme.customTheme?.fontSize?.small,
  lineHeight: '143% !important',
  letterSpacing: '0.17px !important',
  color: theme.customTheme?.color?.black_rgb87,
}));
