import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  InputAdornment,
  Radio,
  autocompleteClasses,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import { CenterLayout } from '../../../components';
import { GoogleRegisterSchema } from '../../../utils';
import {
  CompanyOptionType,
  mockCompanyData,
} from '../../../utils/dummy-data/companyData';
import { CompanyManager, CompanyStore } from '../../../utils/store';
import {
  StyledButtonSignupSubmit,
  StyledCompanyRegistered,
  StyledFormHelperTextErr,
  StyledGAuthExtraForm,
  StyledGAuthExtraTypography,
  StyledUserDetailInput,
  StyledUserDetailRadioGroup,
  StyledUserDetailRadioSelected,
  StyledUserDetailRadioTxt,
  StyledWelcomeHeader,
  StyledWelcomeHeaderEmoji,
  StyledWelcomeSubtitleText,
} from './gauth-extra-ino-styled';

// filter option to filter companies of type CompanyOptionType
const filterCompany = createFilterOptions<CompanyOptionType>();

/* eslint-disable-next-line */
export interface GauthExtraInfoProps {}

export function GauthExtraInfo() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const company = useSnapshot(CompanyStore);

  useEffect(() => {
    CompanyManager.setCompany();
  }, []);

  // function to render User Detail Form Component
  const RenderUserDetailForm = () => {
    const [companyValue, setCompanyValue] = useState<CompanyOptionType | null>(
      null
    );

    // change compnay name on selecting options list
    const handleCompanyChange = (
      event: SyntheticEvent<Element, Event>,
      newCompany: string | CompanyOptionType | null
    ) => {
      if (typeof newCompany === 'string') {
        setCompanyValue({
          companyName: newCompany,
        });
        formik.values.companyName = newCompany;
      } else if (newCompany && newCompany.inputValue) {
        setCompanyValue({
          companyName: newCompany.inputValue,
        });
        formik.values.companyName = newCompany.inputValue;
        if (
          mockCompanyData.filter(
            data => data.companyName === newCompany.inputValue
          ).length === 0
        ) {
          mockCompanyData.push(newCompany);
        }
        CompanyManager.setCompany(formik.values.companyName, false, false);
      } else {
        setCompanyValue(newCompany);
        formik.values.companyName = newCompany ? newCompany.companyName : '';
        CompanyManager.setCompany(formik.values.companyName, true, false);
      }
    };

    // function to filter out the company based on user input
    const handleFilterCompany = (options: CompanyOptionType[], params: any) => {
      const filtered = filterCompany(options, params);
      const { inputValue } = params;
      const isExisting = options.some(
        option => inputValue === option.companyName
      );
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          companyName: inputValue,
        });
      }
      return filtered;
    };

    // function to get company options in option list
    const handleGetCompanyOptions = (option: string | CompanyOptionType) => {
      if (typeof option === 'string') {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return option.companyName;
    };

    // function to create an Input field for company autocomplete field
    const RenderCompanyInput = (params: AutocompleteRenderInputParams) => {
      return (
        <FormControl sx={{ width: '100%' }}>
          <StyledUserDetailInput
            {...params}
            label={t('bk_signup_company_name')}
            name="companyName"
            variant="outlined"
            size="small"
            required
            color={
              company.selectedCompany.isCompanyRegistered
                ? 'success'
                : 'primary'
            }
            error={
              !companyValue &&
              formik.touched.companyName &&
              Boolean(formik.errors.companyName)
            }
            helperText={
              !companyValue &&
              formik.touched.companyName &&
              formik.errors.companyName
            }
          />
          <StyledCompanyRegistered>
            {company.selectedCompany.isCompanyRegistered &&
            formik.values.companyName !== ''
              ? 'This company is already resistered'
              : null}
          </StyledCompanyRegistered>
        </FormControl>
      );
    };

    const formik = useFormik({
      initialValues: {
        hireOption: '',
        userName: '',
        companyName: '',
      },
      validationSchema: GoogleRegisterSchema,
      onSubmit: (values, { resetForm }) => {
        // handle API Calls
        //

        // Reset form fields on submit button
        resetForm({
          values: {
            hireOption: '',
            userName: '',
            companyName: '',
          },
        });
        setCompanyValue(null);

        // redirect on API Call Success
        if (company.selectedCompany.isCompanyRegistered) {
          globalNavigateTo('/accept-invitation', navigate);
        } else {
          globalNavigateTo('/onboard', navigate);
        }
      },
    });

    return (
      <StyledGAuthExtraForm
        noValidate
        autoComplete="off"
        // component="form"
        onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '100%' }}>
          {formik.touched.hireOption && Boolean(formik.errors.hireOption) ? (
            <StyledFormHelperTextErr>
              {formik.touched.hireOption && formik.errors.hireOption}
            </StyledFormHelperTextErr>
          ) : (
            ''
          )}
          <StyledUserDetailRadioGroup
            row
            name="hireOption"
            value={formik.values.hireOption}
            onChange={formik.handleChange}>
            <StyledUserDetailRadioSelected
              theme={theme}
              hireOption={formik.values.hireOption}
              value="hire_company"
              control={<Radio />}
              label={
                <StyledGAuthExtraTypography variant="body1">
                  {t('bk_signup_hireclient_option')}
                </StyledGAuthExtraTypography>
              }
            />
            <StyledUserDetailRadioSelected
              theme={theme}
              value="hire_client"
              hireOption={formik.values.hireOption}
              control={<Radio />}
              label={
                <StyledUserDetailRadioTxt variant="body1">
                  {t('bk_signup_hireclient_option')}
                </StyledUserDetailRadioTxt>
              }
            />
          </StyledUserDetailRadioGroup>
        </FormControl>
        <StyledUserDetailInput
          id="userName"
          name="userName"
          label={t('bk_signup_username')}
          variant="outlined"
          size="small"
          fullWidth
          required
          value={formik.values.userName}
          onChange={formik.handleChange}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'userName' }}
        />
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          value={companyValue}
          options={mockCompanyData}
          popupIcon={<StarIcon sx={{ marginRight: '5px' }} />}
          forcePopupIcon={true}
          sx={{
            [`& .${autocompleteClasses.popupIndicator}`]: {
              transform: 'none',
            },
          }}
          onChange={(event, newValue) => handleCompanyChange(event, newValue)}
          filterOptions={(options, params) =>
            handleFilterCompany(options, params)
          }
          getOptionLabel={option => handleGetCompanyOptions(option)}
          renderInput={params => RenderCompanyInput(params)}
          renderOption={(props, option) => (
            <li {...props}>{option.companyName}</li>
          )}
        />
        <StyledButtonSignupSubmit
          data-testid="submitBtn"
          fullWidth
          variant="contained"
          type="submit">
          {t('bk_continue')}
        </StyledButtonSignupSubmit>
      </StyledGAuthExtraForm>
    );
  };

  return (
    <CenterLayout>
      <Box>
        <StyledWelcomeHeader variant="h3">
          <StyledWelcomeHeaderEmoji role="img" aria-label="wavinghand">
            👋
          </StyledWelcomeHeaderEmoji>{' '}
          {t('bk_welcome_gigin')}
        </StyledWelcomeHeader>
        <StyledWelcomeSubtitleText variant="body2">
          {t('bk_gauth_userdetails')}
        </StyledWelcomeSubtitleText>
      </Box>

      {/* Render User Details Form */}
      {RenderUserDetailForm()}
    </CenterLayout>
  );
}

export default GauthExtraInfo;
