import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { CheckColorLegend } from './check-color-legend';
import { giginLogo } from './image-library';
import { ImageComponent } from './report-image';

export interface ReportFooterProps {
  renderCheckLegend?: boolean;
  reportDataObject: any;
}

export const ReportFooter = (props: ReportFooterProps) => {
  const { renderCheckLegend = false, reportDataObject } = props;

  return (
    <View fixed style={styles.footer}>
      {renderCheckLegend && <CheckColorLegend />}
      <View style={styles.pageContainer}>
        <Text style={[styles.textBody3, styles.textSecondary]}>
          Confidential report {reportDataObject.report_details.report_version}
        </Text>
        <Text
          style={[styles.textBody3, styles.textSecondary]}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
        <Text style={[styles.textBody3, styles.textSecondary]}>
          CIN : {reportDataObject.report_details.cin}
        </Text>
      </View>
      <View style={styles.captionContainer}>
        <View style={styles.captionSection}>
          <Text style={[styles.textBody3, styles.textSecondary]}>
            {reportDataObject.report_details.copy_right}
          </Text>
          <ImageComponent src={giginLogo} style={styles.captionImage} />
          <Text style={[styles.textBody3, styles.textSecondary]}>
            {reportDataObject.report_details.year}{' '}
            {reportDataObject.report_details.verdor_company_info}
          </Text>
        </View>
        <View style={styles.supportSection}>
          <Text style={[styles.textBody3, styles.textSecondary]}>
            For support contact
          </Text>
          <Text style={[styles.textBody3, styles.textLink]}>
            {reportDataObject.report_details.contact_email}
          </Text>
        </View>
      </View>
    </View>
  );
};
