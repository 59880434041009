import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import FileDisplayComponent from 'src/app/components/display-file/display-file';

interface PreviewDialogProps {
  open: boolean;
  onClose: () => void;
  contentUrl: string | null;
}

const PreviewDialog: React.FC<PreviewDialogProps> = ({
  open,
  onClose,
  contentUrl,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent
        sx={{
          width: 'auto',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
        }}>
        {contentUrl ? (
          <FileDisplayComponent
            fileUrl={contentUrl}
            fileStyle={{
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              maxWidth: '100%',
            }}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;
