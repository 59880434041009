import { PROGRESS_STATUS, ProcessType } from '@gigin-work-space/utils';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';

import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DisplayImage from 'src/app/components/display-image/display-image';
import { axiosInstance, endpoints } from 'src/app/utils';

interface DownloadItemProps {
  item: {
    _id: string;
    processType: ProcessType;
    processStatus: PROGRESS_STATUS;
    result: { data: { count: number; url: string } };
    createdAt: string;
  };
}

const ProcessObject: React.FC<DownloadItemProps> = ({ item }) => {
  const [currentItem, setCurrentItem] = useState(item);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentItem(item);
  }, [item]);

  const renderStatusIcon = (status: PROGRESS_STATUS) => {
    switch (status) {
      case PROGRESS_STATUS.INPROGRESS:
        return (
          <DisplayImage
            imageName="download-pending-icon.svg"
            imageType="icon"></DisplayImage>
        );
      case PROGRESS_STATUS.SUCCESS:
        return <CheckCircleOutlinedIcon color="success" />;
      case PROGRESS_STATUS.FAIL:
        return <ErrorIcon color="error" />;
      default:
        return null;
    }
  };

  const handleDownloadRefresh = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `${endpoints.BULK_DOWNLOAD_REQUEST_LIST}/${id}`
      );
      if (response.data.success) {
        enqueueSnackbar('Refreshed successfully', {
          variant: 'info',
          autoHideDuration: 2000,
          key: id,
        });
        // Update the current item with the refreshed data
        setCurrentItem(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar('Failed to refresh', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleDownload = async (url: string) => {
    if (!url) {
      enqueueSnackbar('Preparing for download ...', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      return;
    }
    try {
      const auth_token = localStorage.getItem('auth_token');
      const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';

      const decodedUrl = decodeURIComponent(url);
      const fileName = decodedUrl.split('/').pop();
      const config: AxiosRequestConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${trimmed_token}`,
        },
      };

      const response = await axios.request<Blob>(config);

      const blob = new Blob([response.data], { type: 'application/zip' });
      const fileUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileName || 'Reports.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Clean up the URL object
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      enqueueSnackbar('Download failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ListItemIcon>
          {renderStatusIcon(currentItem.processStatus)}
        </ListItemIcon>
        <div className="bk-company-verified-item">
          <ListItemText
            primary={
              currentItem.processType === ProcessType.BULK_CONSENT_DOWNLOAD
                ? `Consent form for ${currentItem.result.data.count} candidates`
                : `BGV Report for ${currentItem.result.data.count} candidates`
            }
            secondary={
              currentItem.processStatus === PROGRESS_STATUS.SUCCESS
                ? 'Ready to download'
                : 'Preparing for download'
            }
          />
          <Typography className="bk-hint-text pt-3">
            Initiated:{' '}
            {new Date(currentItem.createdAt).toLocaleDateString('en-US', {
              year: 'numeric',
              day: '2-digit',
              month: 'long',
            })}
          </Typography>
        </div>
        <IconButton
          onClick={() => {
            if (currentItem.processStatus === PROGRESS_STATUS.SUCCESS) {
              handleDownload(currentItem.result?.data?.url);
            } else {
              handleDownloadRefresh(currentItem._id);
            }
          }}>
          {currentItem.processStatus === PROGRESS_STATUS.SUCCESS ? (
            <FileDownloadOutlinedIcon className="downloadIcon" />
          ) : (
            <LoopIcon className={`downloadIcon ${isLoading ? 'spin' : ''}`} />
          )}
        </IconButton>
      </ListItem>
      <Divider sx={{ pt: 2 }} />
    </>
  );
};

export default ProcessObject;
