import { BKStyledButton } from '@gigin-work-space/common-ui';
import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AuthenticationPaper } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import CircularIndeterminate from '../../bgv/component/skelton/bgv-loader';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';
import LinkExpired from '../link-expired/link-expired';

export function EmailLinkVerifiedInfo() {
  const navigate = useNavigate();
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [countdown, setCountdown] = useState(5);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const data = searchParams.get('data');

  const getUserDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_AUTH_USERDETAILS, {
        params: { data: data },
      });

      if (response.data.success) {
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
        setIsUserRegistered(response.data.data.isRegistered);
        // if already registered then go to login page
        if (response.data.data.isRegistered) {
          globalNavigateTo('/login', navigate);
        }
        return;
      }
      throw new Error(response.data.message);
    } catch (error) {
      setIsLinkExpired(true);
    } finally {
      setLoading(false);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (data) {
      getUserDetails();
    }

    let timer: number | undefined;

    if (countdown > 0) {
      timer = window.setTimeout(() => {
        setCountdown(prevCount => prevCount - 1);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [data, countdown, getUserDetails]);

  if (isUserRegistered || loading) {
    globalNavigateTo('/login', navigate);
  }

  if (isLinkExpired) {
    return <LinkExpired />;
  }

  if (loading) {
    return <CircularIndeterminate />;
  }
  const handleNavigateToSetPassword = () => {
    globalNavigateTo('/set-password/set', navigate);
  };

  if (!countdown) {
    globalNavigateTo('/set-password/set', navigate);
  }

  return (
    <AuthenticationHOC>
      <section className="absolute bottom-[-4%] left-[-160px]">
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary text-center">
            👋 Welcome to the BGV Services
          </Typography>
          <Box
            className="mt-6 flex flex-col justify-start items-center"
            component="form"
            noValidate
            autoComplete="off">
            <CheckCircleOutline className="bk-custom-text-style" />
            <Typography className="bk-body1 text-bk_text_primary">
              Link Successfully Verified
            </Typography>
            <BKStyledButton
              data-testid="submitBtn"
              className="bg-bk_action_primary mt-8"
              fullWidth
              size="large"
              variant="contained"
              onClick={handleNavigateToSetPassword}>
              Continue ({countdown})...
            </BKStyledButton>
          </Box>
        </AuthenticationPaper>
      </section>
    </AuthenticationHOC>
  );
}

export default React.memo(EmailLinkVerifiedInfo);
