import { Stack } from '@mui/material';

export const InprogressCheckLine = () => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <svg style={{ width: '100%', height: '2' }} viewBox="0 0 108 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="108" y2="1" stroke="#0053F3" stroke-width="2" />
      </svg>
    </Stack>
  );
};
