import { SPACING } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React from 'react';
import CheckSummaryTable from '../components/check-summary-table';
import ReportHeaderConsolidated from '../components/report-header-consolidated';
import StatusBtn from '../components/status-btn';
import { ReportDataInterface } from '../constants/interfaces/ReportDataInterface';
import { styles } from '../styles';
import { toCamelCase } from '../utility/camel-case-sentence';
import { FlattenedSubcheck } from '../utility/restructure-data';

const ReportSummary = ({
  reportDataObject,
  flattenedSubchecks,
}: {
  reportDataObject: ReportDataInterface;
  flattenedSubchecks: FlattenedSubcheck[];
}) => {
  return (
    <View>
      {/* Header Section */}
      <ReportHeaderConsolidated reportDataObject={reportDataObject} />
      {/* Overall Status Section */}
      <View style={[styles.displayFlexRowBetween, { marginTop: SPACING.lg }]}>
        <View>
          <Text
            style={
              styles.typov3SubHeader2
            }>{`${reportDataObject?.basic_detail?.name}'s Report`}</Text>
          <Text style={styles.typov3Body3}>
            {`${reportDataObject?.basic_detail?.mobile}  |  ${reportDataObject?.basic_detail?.email}`}
          </Text>
        </View>
        {/* Button Section */}
        <StatusBtn status={reportDataObject?.overall_status?.status} />
      </View>
      {/* Report Details Section */}
      <View style={[styles.reportDetailsContainer, { marginTop: SPACING.xl2 }]}>
        {/* Left Column */}
        <View style={styles.reportDetailsColumn}>
          <View style={styles.reportDetailsRow}>
            <Text style={[styles.reportDetailsLabel, styles.typov3Body3]}>
              Requested for
            </Text>
            <Text style={[styles.reportDetailsValue, styles.typov3Body3]}>
              {toCamelCase(reportDataObject?.company_details?.name)}
            </Text>
          </View>
          <View style={styles.reportDetailsRow}>
            <Text style={[styles.reportDetailsLabel, styles.typov3Body3]}>
              Package Selected
            </Text>
            <Text style={[styles.reportDetailsValue, styles.typov3Body3]}>
              {reportDataObject?.package_details?.name}
            </Text>
          </View>
          {/* TODO: Uncomment this section when we have the work location data */}
          {/* <View style={styles.reportDetailsRow}>
            <Text style={[styles.reportDetailsLabel, styles.typov3Body3]}>
              Work location
            </Text>
            <Text style={[styles.reportDetailsValue, styles.typov3Body3]}>
              Edison, NJ
            </Text>
          </View> */}
        </View>
        {/* Right Column */}
        <View style={styles.reportDetailsColumnRight}>
          <View style={styles.reportDetailsRow}>
            <Text style={[styles.reportDetailsLabel, styles.typov3Body3]}>
              Requested on
            </Text>
            <Text style={[styles.reportDetailsValue, styles.typov3Body3]}>
              {reportDataObject?.request?.initiated_on
                ? dayjs(reportDataObject?.request?.initiated_on).format(
                    'DD/MM/YYYY'
                  )
                : ''}
            </Text>
          </View>
          <View style={styles.reportDetailsRow}>
            <Text style={[styles.reportDetailsLabel, styles.typov3Body3]}>
              Report completed
            </Text>
            <Text style={[styles.reportDetailsValue, styles.typov3Body3]}>
              {reportDataObject?.request?.request_completed_on
                ? dayjs(reportDataObject?.request?.request_completed_on).format(
                    'DD/MM/YYYY'
                  )
                : 'Completion Pending'}
            </Text>
          </View>
        </View>
      </View>
      {/* Check Summary Table Section */}
      <View style={{ marginTop: SPACING.xl2 }}>
        <CheckSummaryTable data={flattenedSubchecks} />
      </View>
    </View>
  );
};

export default React.memo(ReportSummary);
