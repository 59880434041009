import { Box, Divider, Grid, Typography } from '@mui/material';

const InsufficiencyReport = ({ item, itemIndex, dateFormate }: any) => {
  return (
    <Box key={itemIndex}>
      {item.remark.length === 0 ? (
        <Typography>No Remarks</Typography>
      ) : (
        item?.remark
          .slice() // Creating a shallow copy for arranging them from latest to least w.r.t. issueRaised
          .sort(
            (a: any, b: any) =>
              new Date(b.issueRaised).getTime() -
              new Date(a.issueRaised).getTime()
          ) // Sort by issueRaised date
          .map((rm: any, remarkIndex: number) => (
            <>
              <Grid display={'flex'} flexDirection={'column'} key={remarkIndex}>
                {rm?.comment && (
                  <Typography variant="h6">Remark: {rm.comment}</Typography>
                )}
                {rm?.issueRaised && (
                  <Typography variant="subtitle1">
                    Issue raised: {dateFormate(new Date(rm.issueRaised))}
                  </Typography>
                )}
                {rm?.issueResolved && (
                  <Typography className="bK-body2">
                    Resolved on: {dateFormate(new Date(rm.issueResolved))}
                  </Typography>
                )}
                {rm?.mailSentAt && (
                  <Typography className="bK-body2">
                    Last Mail sent: {dateFormate(new Date(rm.mailSentAt))}
                  </Typography>
                )}
                <Typography className="bK-body1 font-[700]">
                  Check: {item.check}
                </Typography>
              </Grid>
              <Divider className="my-2 mx-auto bg-bk_stroke_divider" w-full />
            </>
          ))
      )}{' '}
    </Box>
  );
};

export default InsufficiencyReport;
