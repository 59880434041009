import { IProgressStatusProps } from '@gigin-work-space/model';
import {
  COLORS,
  ORDER_FLOW_STATUS,
  PROGRESS_STATUS,
  getStatusBgColor,
  getStatusColor,
  hasCheckStatusError,
} from '@gigin-work-space/utils';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import { ProgressLineSVG } from './progress-line';

export const DataCollectSVG: React.FC<IProgressStatusProps> = ({ message, status }) => {
  const color = getStatusColor(status);
  const bgColor = getStatusBgColor(status);

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={message ? message : ORDER_FLOW_STATUS.DATA_COLLECTION} placement="top" arrow>
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="13"
            cy="13"
            r="12.5"
            fill={bgColor}
            stroke={status.toUpperCase() === PROGRESS_STATUS.PENDING ? COLORS.bk_tag_gray_bg : color}
          />
          {hasCheckStatusError(status) && (
            <path
              d="M15.7929 29C16.2383 29 16.4614 28.4614 16.1464 28.1464L13.3536 25.3536C13.1583 25.1583 12.8417 25.1583 12.6464 25.3536L9.85355 28.1464C9.53857 28.4614 9.76165 29 10.2071 29H15.7929Z"
              fill={color}
            />
          )}
          <path
            d="M16.7 18V17.3647C16.7 16.4752 16.7 16.0305 16.5202 15.6907C16.362 15.3919 16.1096 15.1489 15.7991 14.9966C15.4461 14.8235 14.9841 14.8235 14.06 14.8235H11.64C10.7159 14.8235 10.2539 14.8235 9.90092 14.9966C9.59045 15.1489 9.33803 15.3919 9.17984 15.6907C9 16.0305 9 16.4752 9 17.3647V18M16.7 10.0588L17.8 11.1176L20 9M14.775 10.8529C14.775 11.8763 13.9131 12.7059 12.85 12.7059C11.7869 12.7059 10.925 11.8763 10.925 10.8529C10.925 9.82959 11.7869 9 12.85 9C13.9131 9 14.775 9.82959 14.775 10.8529Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Tooltip>
      {status.toUpperCase() === PROGRESS_STATUS.INPROGRESS ||
      status.toUpperCase() === PROGRESS_STATUS.FAIL ||
      status.toUpperCase() === PROGRESS_STATUS.PENDING ||
      status.toUpperCase() === PROGRESS_STATUS.BLOCKED ? (
        <ProgressLineSVG color={COLORS.bk_stroke_divider} />
      ) : (
        <ProgressLineSVG color={color} />
      )}{' '}
    </Stack>
  );
};
