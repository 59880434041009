/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function

export interface IToggleValueType {
  status: boolean;
  message: string;
}
export interface ISelectionType {
  status: number;
}

const initialValue = {
  toggleValue: {
    status: false,
    message: '',
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToggleValue: (value: IToggleValueType) => {},
  formSelectionStatus: {
    status: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFormSelectionStatus: (value: ISelectionType) => {},
};

export const Context = createContext(initialValue);
