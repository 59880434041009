import { Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { ReportHeader } from '../components';
import { styles } from '../styles';
interface CustomListItemProps {
  children: React.ReactNode; // Using ReactPDF.Style for style prop
}
export interface DisclaimerPageProps {
  reportDataObject: object;
}
const CustomListItem: React.FC<CustomListItemProps> = ({ children }) => (
  <View style={{ flexDirection: 'row', marginBottom: 3 }}>
    <Text style={{ marginRight: 8, marginTop: '0px' }}>•</Text>
    <Text style={styles.disclaimerContent}>{children}</Text>
  </View>
);
export const DisclaimerPage = (props: DisclaimerPageProps) => {
  const { reportDataObject } = props;
  const [hasError, setHasError] = useState(false);

  // Example useEffect to handle data fetching or processing
  useEffect(() => {
    try {
      // Your data fetching or processing logic here
    } catch (error) {
      console.error('Error occurred in DisclaimerPage: ', error);
      setHasError(true);
    }
  }, []); // Dependencies array can be adjusted as needed

  if (hasError) {
    return (
      <Page size="A4" wrap style={styles.page}>
        <Text>
          An error occurred while generating the report. Please try again later.
        </Text>
      </Page>
    );
  }

  return (
    <Page size="A4" wrap style={styles.page}>
      <ReportHeader reportDataObject={reportDataObject} />
      {/* Main Content Section */}
      <View style={styles.mainSectionContainer}>
        {/* Terms and Conditions Title */}
        <Text style={{ ...styles.textHeadLine2, marginTop: 4 }}>
          Terms and Conditions:
        </Text>

        {/* Custom List */}
        <View>
          <Text style={styles.disclaimerColumnContainer}>
            This background verification report ("Report") is submitted pursuant
            to the agreement dated ("Agreement") executed by and between us and
            the Client, and shall be subject to the following terms and
            conditions ("T&Cs"):
          </Text>
          <CustomListItem>
            This Report contains personal and sensitive information which has
            been provided in strict confidence solely, pursuant to the Agreement
            and for the purpose of verification of the statements made by the
            Candidates for employment and/or other permitted purposes. The
            Client shall therefore use the information provided in the Report
            strictly for the permitted purpose and in compliance with the
            applicable information technology, data protection and other laws.
          </CustomListItem>
          <CustomListItem>
            The records / information provided in the Report are provided on an
            'as is' basis, i.e., as they were found at the source as on the date
            and time of verification, whether on a computer information system,
            retrieved by manual search, telephonic interviews or field work. The
            records / information contained in the Report are simply compiled in
            a structured way for Client's review, from various databases
            including public records that are available and as updated at
            defined, undefined or infrequent intervals, and therefore, may or
            may not have the most current information...
          </CustomListItem>
          <CustomListItem>
            Where criminal record check is conducted either through online
            databases or through police records or through law firms, there is a
            possibility of not finding criminal records in the name of the
            Candidate; where address verification (for current/ previous/
            permanent) is either not initiated for any reason including but not
            limited to non-disclosure of an address by the Candidate or is
            discrepant as in such case the Candidate may never have
            resided/situated at the address.
          </CustomListItem>
          <CustomListItem>
            The information provided in the Report shall not be construed to
            constitute a legal opinion or a definitive pronouncement on the
            subject or a recommendation. The Client shall be fully and solely
            responsible for the final verification and for applying its
            independent judgment, with respect to the findings provided in the
            Report, to make appropriate decisions in relation to the future
            course of action, if any. We shall not be responsible for any
            decision or any other consequences resulting from such decisions
            taken by the Client based on information included in the Report.
          </CustomListItem>
          <CustomListItem>
            We do not warrant or provide guarantee of the information's accuracy
            or completeness. We disclaim any and all responsibilities and
            liabilities which Client may incur due to any reliance or use of the
            information provided through this Report, in contrary to the purpose
            agreed in the Agreement or otherwise. This Report cannot be
            reproduced in part or full without our prior written approval.
            Further, under no circumstances shall we will be liable for any loss
            or damage of whatsoever nature, arising from the information being
            withheld or concealed or misrepresented by any person/agency to
            which information requests were made by us. We also do not undertake
            any responsibility for events or circumstances occurring after the
            date of completion of the research, verification and enquiries.
          </CustomListItem>
          <CustomListItem>
            The Report has been prepared basis the facts reported to us. We
            reserve the right to amend the Report where additional information
            or documentation is made available impacting the facts in the Report
            post publishing the Report.
          </CustomListItem>
          <CustomListItem>
            Except where required by law, no information provided in the Report
            shall be revealed, directly or indirectly, to any unauthorized third
            party. Further, the Client shall secure storing of the Report and
            its destruction once it has been used for the purpose for which it
            was intended, as per the applicable law.
          </CustomListItem>

          {/* More CustomListItem components as needed */}
        </View>
      </View>
      {/* Footer Section (if any) */}
    </Page>
  );
};
