import { BKStyledButton } from '@gigin-work-space/common-ui';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

import {
  AUTO_HIDE_TIMEOUT,
  BgvServiceTeam,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  AccountRegistrationSchema,
  axiosInstance,
  endpoints,
} from 'src/app/utils';

interface AddAccountProps {
  onSuccess?: () => void;
}

export const AddAccount: React.FC<AddAccountProps> = ({ onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    values: {
      account: string;
      name: string;
      email: string;
      mobile: string;
      designation: string;
      address: string;
      gst: string;
    },
    formikHelpers: { resetForm: any }
  ) => {
    const { resetForm } = formikHelpers;
    try {
      const payload = {
        accountName: values.account,
        userName: values.name,
        email: values.email,
        mobile: values.mobile,
        designation: values.designation,
        address: values.address,
        gst: values.gst,
        createdBy: BgvServiceTeam.OPS_TEAM,
      };
      const response = await axiosInstance.post(
        endpoints.SELF_SIGN_UP,
        payload
      );
      if (response.status === HttpStatus.CREATED) {
        enqueueSnackbar(`Client added successfully`, {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
        });
        resetForm();
        if (onSuccess) {
          onSuccess();
        }
      }
      if (response.request.status === HttpStatus.CONFLICT) {
        resetForm();
        enqueueSnackbar(
          `Client already exist with this mail id: ${values.email}`,
          {
            variant: VARIANT_TYPE.ERROR,
          }
        );
      }
    } catch (error) {
      enqueueSnackbar('Failed to add client', {
        variant: VARIANT_TYPE.ERROR,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      account: '',
      name: '',
      email: '',
      mobile: '',
      designation: '',
      gst: '',
      address: '',
    },
    validationSchema: AccountRegistrationSchema,
    onSubmit: handleSubmit,
  });

  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled;
  }, [formik.values]);

  return (
    <Grid item>
      <Box
        className="px-8"
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}>
        <TextField
          id="account"
          name="account"
          label="Client Name"
          variant="outlined"
          fullWidth
          required
          value={formik.values.account}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.account && Boolean(formik.errors.account)}
          helperText={formik.touched.account && formik.errors.account}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircleOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'account' }}
        />
        <TextField
          id="email"
          name="email"
          label="Client Email"
          variant="outlined"
          fullWidth
          required
          value={formik.values.email.toLowerCase()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MailOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'email' }}
        />
        <TextField
          id="name"
          name="name"
          label="SPOC Name"
          variant="outlined"
          fullWidth
          required
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircleOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'name' }}
        />
        <TextField
          id="mobile"
          name="mobile"
          label="Mobile"
          variant="outlined"
          fullWidth
          required
          value={formik.values.mobile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mobile && Boolean(formik.errors.mobile)}
          helperText={formik.touched.mobile && formik.errors.mobile}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhoneOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'mobile' }}
        />
        <TextField
          id="designation"
          name="designation"
          label="Designation"
          variant="outlined"
          fullWidth
          required
          value={formik.values.designation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.designation && Boolean(formik.errors.designation)
          }
          helperText={formik.touched.designation && formik.errors.designation}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SupervisorAccountOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'designation' }}
        />
        <TextField
          id="address"
          name="address"
          label="Client Address"
          variant="outlined"
          fullWidth
          required
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <HomeOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'address' }}
        />

        <TextField
          id="gst"
          name="gst"
          label="GST No"
          variant="outlined"
          fullWidth
          required
          value={formik.values.gst}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.gst && Boolean(formik.errors.gst)}
          helperText={formik.touched.gst && formik.errors.gst}
          className="bk-custom-input-style-user"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MonetizationOnOutlinedIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'data-testid': 'gst' }}
        />

        <BKStyledButton
          data-testid="submitBtn"
          className="bg-bk_action_primary mt-6"
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          disabled={isBtnDisabled}>
          Add Account
        </BKStyledButton>
      </Box>
    </Grid>
  );
};
