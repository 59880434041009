import { Stack, Tooltip } from '@mui/material';

export const PendingCandidateCheck = ({ status }: { status: string }) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={status} placement="top" arrow>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11" cy="11" r="6" fill="#DCD9DD" />
        </svg>
      </Tooltip>
    </Stack>
  );
};
