import { BKStyledButton } from '@gigin-work-space/common-ui';
import { Report, Request } from '@gigin-work-space/model';
import { OrderCandidateStore, permissionStore } from '@gigin-work-space/store';
import {
  COLORS,
  ConsentType,
  SelfDeclaration,
  capitalizeString,
  getDateMonth,
} from '@gigin-work-space/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import ConsentDocument from '../../candidate-individual/consent-download';
import ReportTabAccordian from './ReportTabAccordian';
import ViewReport from './ViewReport';
import { StatusChip } from './status-chip';
// closed accordion
export const TabCloseAccordion = ({ data }: { data: Report }) => {
  const { canReadReport } = useSnapshot(permissionStore);
  const [attachment, setAttachment] = useState(0);

  useEffect(() => {
    const count = data.documentlist.reduce((acc, docList) => {
      return acc + docList.documentFileList.length;
    }, 0);

    setAttachment(count);
  }, [data]);
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      className="w-full p-4">
      <Stack direction={'row'} spacing={1.5}>
        {/* col1 */}
        <FileDisplayComponent
          fileUrl={data?.icon}
          fileStyle={{
            width: '16px',
            height: '16px',
          }}
        />
        {/* col2 */}
        <Stack direction={'column'} rowGap={1}>
          <Stack direction={'row'} columnGap={1}>
            <Typography className="bk-sub-heading2">{data?.title}</Typography>

            {attachment ? (
              <Tooltip title={`${attachment} Attachments`} placement="top">
                <Stack
                  columnGap={0.15}
                  direction={'row'}
                  alignItems={'center'}
                  className="bk-attachment-chip">
                  <Typography className="bK-body2">{attachment}</Typography>
                  <DisplayImage
                    imageName={'attachment-icon.svg'}
                    imageType="icon"
                    width="12px"
                    height="12px"
                  />
                </Stack>
              </Tooltip>
            ) : (
              ''
            )}
          </Stack>

          <Stack direction={'row'} spacing={3} alignItems={'center'}>
            {/* TODO: display icon and Identity (description) */}
            {data?.documentlist.map(check => {
              return (
                <Stack direction={'row'} columnGap={0.5} alignItems={'center'}>
                  <FileDisplayComponent
                    fileUrl={check?.details.image}
                    fileType="image"
                    fileStyle={{
                      width: '16px',
                      height: '16px',
                    }}
                    fileClassName="cursor-pointer"
                  />
                  <Typography className="bK-body3  text-bk_text_secondary">
                    {check.details.check_name}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={3} alignItems={'center'}>
        {/* TODO: Need to map with api data */}
        <Stack>
          {data.tat.status ? (
            <Typography className="bK-hint-text  text-bk_text_primary">
              {' '}
              {'Yet to start'}
            </Typography>
          ) : (
            <StatusChip
              {...{
                message: data?.check_status?.message,
                title: data?.check_status?.title,
                status: data?.check_status?.status,
              }}
            />
          )}
        </Stack>
        {/* cta col */}
        {data?.view_report && canReadReport ? (
          <Stack direction={'column'} justifyContent={'center'}>
            {/* TODO: onclick funtion need to integrate */}
            <Button
              variant="outlined"
              className="bK-body2 text-bk_action_primary rounded-lg">
              View Report
            </Button>
          </Stack>
        ) : data.tat.status ? (
          // ON tat
          <Stack direction={'column'} justifyContent={'center'}>
            <TATComponent date={data?.tat?.value} />
          </Stack>
        ) : (
          // ON eta
          <Stack direction={'column'} justifyContent={'center'}>
            <ETAComponent date={data?.eta?.value} />
          </Stack>
        )}
        <ExpandMoreIcon
          sx={{ fontSize: 20, color: COLORS.bk_text_secondary }}
        />
      </Stack>
    </Stack>
  );
};
// TAT Component
export const TATComponent = ({ date }: { date: string }) => {
  return (
    <Stack direction={'row'} columnGap={0.5}>
      {/* TODO: Need to api data */}
      <DisplayImage
        imageName="tat-icon.svg"
        imageType="icon"
        width="11px"
        height="10px"
      />
      {/* TODO: Need to api data */}
      <Typography className="bK-hint-text  text-bk_text_secondary">
        {' '}
        {date.toString()}
      </Typography>
    </Stack>
  );
};

// ETA Component
export const ETAComponent = ({ date }: { date: string }) => {
  return (
    <Stack direction={'row'} columnGap={0.5}>
      {/* TODO: Need to api data */}
      <DisplayImage
        imageName="tat-icon.svg"
        imageType="icon"
        width="11px"
        height="10px"
      />
      {/* TODO: Need to api data */}
      <Typography className="bK-hint-text  text-bk_text_secondary">
        {' '}
        {getDateMonth(date)}
      </Typography>
    </Stack>
  );
};
// open accordion
export const TabOpenAccordion = ({ data }: { data: Report }) => {
  const { canReadReport } = useSnapshot(permissionStore);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      className="w-full">
      <Stack direction={'row'} className="w-full" spacing={'12px'}>
        <Stack alignItems={'center'}>
          <FileDisplayComponent
            fileUrl={data?.icon}
            fileStyle={{
              borderRadius: '50%',
              objectFit: 'cover',
              width: '40px',
              height: '40px',
            }}
            fileType="image"
          />
        </Stack>
        <Stack direction={'column'} spacing={0.5}>
          <Stack className="bk-sub-heading2">{data?.title}</Stack>
          <Stack className="bK-body2">{data?.description}</Stack>
        </Stack>
      </Stack>
      {/* TODO: get this one from api and bind */}
      <Stack
        direction={'row'}
        spacing={'40px'}
        className="w-full"
        alignItems={'center'}
        justifyContent={'flex-end'}>
        <StatusChip
          {...{
            message: data?.check_status?.message,
            title: data?.check_status?.title,
            status: data?.check_status?.status,
          }}
        />
        {data?.view_report && canReadReport && (
          <Stack className="bK-body2 underline text-bk_tag_blue">
            View Report
          </Stack>
        )}
        <ExpandMoreIcon
          sx={{ fontSize: 20, color: COLORS.bk_text_secondary }}
        />
      </Stack>
    </Stack>
  );
};

export interface ITabAccordion {
  request: Request;
  reports: Report[];
  dataCollection: any;
  basicDetail: any;
}
export const TabAccordion = ({
  reports,
  request,
  dataCollection,
  basicDetail,
}: ITabAccordion) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { report_id } = useSnapshot(OrderCandidateStore);
  const [consentDocumentURL, setConsentDocumentURL] = useState<string>('');
  const [consentType, setConsentType] = useState<string>(
    ConsentType.OTP_VERIFICATION
  );

  const navigate = useNavigate();

  // function to generate Report on click of view report, if report btn is visible
  const handleGenerateReport = useCallback(async (event: SyntheticEvent) => {
    event.stopPropagation();
    globalNavigateTo(`/bgv/report/${report_id}`, navigate);
  }, []);

  const handleGenerateConsolidatedReport = useCallback(
    async (event: SyntheticEvent) => {
      event.stopPropagation();
      globalNavigateTo(`/bgv/report-consolidated/${report_id}`, navigate);
    },
    []
  );

  const handleChange =
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (
      dataCollection?.selfDeclarationDetails?.selfDeclaration === false ||
      dataCollection?.selfDeclarationDetails?.signatureStatus?.toLowerCase() ===
        SelfDeclaration.COMPLETED
    ) {
      setConsentDocumentURL(
        dataCollection?.selfDeclarationDetails?.signedDocument?.value
      );
    }

    const signatureType =
      dataCollection?.selfDeclarationDetails?.signatureType?.toUpperCase();
    if (
      signatureType === ConsentType.E_SIGN ||
      signatureType === ConsentType.MANUAL
    ) {
      setConsentType(signatureType);
    }
  }, [dataCollection]);
  return (
    <Box className="flex flex-col gap-4 my-[10px]">
      <Box className="consent-download-position">
        {' '}
        <ConsentDocument
          consentDocumentURL={consentDocumentURL}
          consentType={consentType}
          candidateName={basicDetail?.name}
        />
      </Box>

      <Stack direction={'column'}>
        <Stack
          direction={'row'}
          my={3}
          sx={{
            backgroundColor: COLORS.bk_light_gray,
            borderRadius: '8px',
          }}
          justifyContent={'space-between'}
          paddingX={2}
          paddingY={'12px'}>
          {/* col1 */}
          <Stack direction={'column'} spacing={0.5}>
            <Typography className="bK-body1 text-bk_text_secondary">
              Initiated on
            </Typography>
            <Typography className="bk-sub-heading2">
              {getDateMonth(request?.initiated_on)}
            </Typography>
          </Stack>
          {/* col2 */}
          <Stack direction={'column'} spacing={0.5}>
            <Typography className="bK-body1 text-bk_text_secondary">
              Initiated by
            </Typography>
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
              <FileDisplayComponent
                fileUrl={request?.initiated_by?.profile_image}
                fileType="image"
                fileStyle={{
                  borderRadius: '50%',
                  objectFit: 'cover',
                  width: '20px',
                  height: '20px',
                }}
              />
              <Typography className="bk-sub-heading2">
                {request?.initiated_by?.name}
              </Typography>
            </Stack>
          </Stack>

          <Divider orientation="vertical" flexItem />
          {/* col3 */}
          <Stack direction={'column'} spacing={0.5}>
            <Typography className="bK-body1 text-bk_text_secondary">
              Order
            </Typography>
            <Typography className="bk-sub-heading2">
              #{request?.order_id.substring(request?.order_id.length - 5)}{' '}
              <span className="ml-[5px]"></span>
            </Typography>
          </Stack>
          <Divider orientation="vertical" flexItem />
          {/* col4 */}
          <Stack direction={'column'} spacing={0.5}>
            <Typography className="bK-body1 text-bk_text_secondary">
              {/* TODO: Name data progress need to send and status */}
              {capitalizeString(request?.current_status?.name)}
            </Typography>
            <Typography className="bk-sub-heading2">
              {' '}
              {request?.current_status?.status}
            </Typography>
          </Stack>
          {request?.view_report && (
            <BKStyledButton
              variant="outlined"
              className="py-1 px-4 text-bk_action_primary border-bk_action_primary rounded-lg"
              onClick={handleGenerateReport}>
              View Full Report
            </BKStyledButton>
          )}
          {/* col5 */}
          {/* TODO: Add condidtion to toggle view report btn. currently showing to test report */}
          {/* {request?.view_report && (
            <Stack direction={'column'}>
              <BKStyledButton
                variant="outlined"
                sx={{ py: '0.25rem', px: '1rem' }}
                className="text-bk_action_primary border-bk_action_primary rounded-lg"
                onClick={handleGenerateReport}>
                View reports
              </BKStyledButton>
            </Stack>
          )} */}
        </Stack>
        <ViewReport
          request={request}
          handleGenerateReport={handleGenerateReport}
          handleGenerateConsolidatedReport={handleGenerateConsolidatedReport}
        />

        <Typography className="bk-sub-heading2"> Check details:</Typography>
      </Stack>

      <ReportTabAccordian
        reports={reports}
        handleChange={handleChange}
        expanded={expanded}
      />
    </Box>
  );
};
