import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, endpoints } from '../utils'; // Import your axiosInstance and endpoints correctly
import { globalNavigateTo } from '../utils/router/navigateTo';
import { tenantState } from '../utils/router/tenantState';

interface MatchParams {
  tenantName: string;
}

export const withTenantSetup = <P extends MatchParams>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    const { tenantName } = useParams();
    const navigate = useNavigate();
    const initialTenantName = tenantName || 'default';
    tenantState.tenantName = initialTenantName;

    // console.log('tenantName- withTenantSetup', tenantName);

    useEffect(() => {
      const validateTenant = async () => {
        try {
          // Replace this with your actual API endpoint for tenant validation
          const response = await axiosInstance.get(
            `${endpoints.VALIDATE_TENANT}/${tenantName}`
          );

          if (!response.data) {
            globalNavigateTo('/unauthorized', navigate);
          }
        } catch (error) {
          console.error('Error validating tenant:', error);
        }
      };

      if (tenantName !== 'default' && tenantName !== undefined) {
        validateTenant();
      }

      return () => {
        // Reset tenant name to the initial value when component unmounts
        tenantState.tenantName = initialTenantName;
      };
    }, []);

    return <Component {...props} />;
  };
};
