import { MetaInitialState } from '@gigin-work-space/model';
import { CheckoutStore, isModalOpenStore } from '@gigin-work-space/store';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { JobHeader } from '../../components';
import { BgvDashboard, BgvSideBar } from '../bgv';
import {
  AlertDocumentCollection,
  AlertUploadDocument,
} from './component/common-alert-popup';
/* eslint-disable-next-line */
export interface BgvProps {}

export function BgvNav() {
  const { tenantName } = useParams();

  const [isSideBaseActive, setSideBaseActive] = useState<boolean>(
    // isNotLargeScreen ? false : true
    true
  );
  const location = useLocation();
  const { isOpen } = useSnapshot(isModalOpenStore);
  const updateSideBaseStatus = (isActive: boolean) => {
    setSideBaseActive(isActive);
  };

  React.useEffect(() => {
    CheckoutStore.initiate_id = '';
    CheckoutStore.meta = MetaInitialState;
    CheckoutStore.nextPage = false;
  }, []);

  return (
    <Box sx={{ height: '100vh' }}>
      <JobHeader
        updateSideBaseStatus={updateSideBaseStatus}
        sideBarState={isSideBaseActive}
      />
      <Box sx={{ display: 'flex', height: '100%', width: '100%', pt: '3rem' }}>
        {/* Sidebar Section START */}
        <BgvSideBar
          isSideBaseActive={isSideBaseActive}
          updateSideBaseStatus={updateSideBaseStatus}
        />
        {/* Sidebar Section END */}
        {/* Main Section START */}
        {/* TODO: NEED TO WORK ON THIS LOGIC IT SHOULD BE HANDEL BEFORE THIS */}
        {/* <main className="w-full h-full overflow-x-hidden overflow-y-auto">
         
          {location.pathname === '/bgv' ? (
        <main className="w-full h-full overflow-x-hidden overflow-y-auto">
          {location.pathname === '/bgv' || location.pathname === '/bgv/' ? (
            canReadOrder ? (
              <BgvDashboard />
            ) : canReadCompanySettings ? (
              <Navigate to={'/bgv/company-settings'} />
            ) : (
              <Outlet />
            )
          ) : (
            <Outlet />
          )}
        </main> */}
        <main className="w-full h-full overflow-hidden bg-bk_bgv_bg">
          {/* Use the tenantName in the condition */}
          {location.pathname === `/${tenantName}/bgv` ? (
            // (
            <BgvDashboard />
          ) : (
            <Outlet />
          )}
        </main>
        {/* Main Section END */}
        {/* Quick Actions Section START */}
        {/* <QuickActions /> */}
        {/* Quick Actions Section END */}
        {isOpen.alertDocCollection && <AlertDocumentCollection />}
        {isOpen.alertUploadDoc && <AlertUploadDocument />}
      </Box>
    </Box>
  );
}

export default BgvNav;
