/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { BKStyledButton } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import {
  COLORS,
  MAX_FILE_SIZE,
  ONLY_IMAGE_ACCEPTED,
  canAccess,
  useBytesToMB,
} from '@gigin-work-space/utils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withViewPermission } from 'src/app/HOC';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';

/* eslint-disable no-unused-vars */
export interface CompanyProfileProps {}

export const CompanyProfile = (props: CompanyProfileProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [logoUploaded, setLogoUploaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { fileSize, fileUnit } = useBytesToMB(MAX_FILE_SIZE);
  const {
    canReadAgreement,
    canUpdateCompanyProfile,
    canReadCompanyProfile,
    canCreateCompanyProfile,
  } = useSnapshot(permissionStore);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: ONLY_IMAGE_ACCEPTED,
    multiple: false,
  });
  // function to fetch company's Details
  const getCompanyDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_ACCOUNT_DETAILS);
      if (response.data.success) {
        setCompanyDetails(response.data.data);
        return;
      }
      throw new Error("You don't have permission to fetch company profile!");
    } catch (error) {
      enqueueSnackbar("You don't have permission to fetch company profile!", {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
  }, [logoUploaded]);

  // function to update company's logo
  const updateCompanyLogo = async () => {
    try {
      setIsUploading(true);
      // TODO: replace hardcoded account ID. currently put to run API
      const body = {
        account_name: companyDetails?.account_name as string,
        account_type: companyDetails?.account_type as string,
        email: companyDetails?.email as string,
        logo: companyLogo?.value as string,
      };
      const response = await axiosInstance.patch(
        `${endpoints.PATCH_ACCOUNT_DETAILS}/update_my_account`,
        body
      );

      if (response.data.success) {
        setLogoUploaded(!logoUploaded);
        setModalOpen(false);
        enqueueSnackbar('Logo updated successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        handleCancelUpload();
      }
    } catch (error) {
      enqueueSnackbar('Failed to upload logo', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setIsUploading(false);
    }
  };

  // function to upload file on S3 and return image url to parent
  const uploadFileOnS3 = useCallback(async () => {
    if (acceptedFiles[0].size > MAX_FILE_SIZE) {
      enqueueSnackbar(`Maximum file size is ${fileSize}${fileUnit} only`, {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      const mediaData = {
        field_id: 'company-profile',
        isEncrypted: true,
        filePathContext: 'company-settings',
        isTenantIsolated: true,
        isPrivate: false,
        shouldCompress: false,
      };
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      formData.append('media', JSON.stringify(mediaData));

      const response = await axiosInstance.post(
        `${endpoints.POST_FILE_S3}`,
        formData
      );

      // TODO: Handle success & error scenarios
      if (response.data.statusCode === 200) {
        setCompanyLogo(response.data.data);
        return;
      }
      // throw new Error('Failed to upload logo');
    } catch (error) {
      enqueueSnackbar('Failed to upload logo', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [acceptedFiles]);

  // function to cancel file upload
  const handleCancelUpload = () => {
    setCompanyLogo(null);
    setModalOpen(false);
  };

  // effect to fetch company's details on page load
  useEffect(() => {
    if (
      canAccess([
        canReadCompanyProfile,
        canCreateCompanyProfile,
        canUpdateCompanyProfile,
      ])
    )
      getCompanyDetails();
  }, [getCompanyDetails]);

  // Side Effect to trigger file upload to upload logo
  useEffect(() => {
    if (acceptedFiles.length) {
      uploadFileOnS3();
    }
  }, [uploadFileOnS3]);

  const children = (
    <div className="w-full h-full mt-6 px-6">
      {/* Title Section */}
      <Typography className="bk-headline2">Company profile</Typography>
      {/* Details View Section */}
      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={6}>
          <Typography className="bK-body1">Company name</Typography>
          <Typography className="bk-sub-heading2">
            {companyDetails?.account_name ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className="bK-body1">Company code</Typography>
          <Typography className="bk-sub-heading2">
            {companyDetails?.company_code ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="bK-body1">Address</Typography>
          <Typography className="bk-sub-heading2">
            {companyDetails?.address ?? '-'}
          </Typography>
        </Grid>
        {canReadAgreement && (
          <Grid item xs={12}>
            <Typography className="bK-body1">Sales agreement</Typography>

            <Typography
              className="bk-sub-heading2 text-bk_tag_blue_light underline underline-offset-2 cursor-pointer"
              onClick={() =>
                window.open(companyDetails?.sales_agreement, '_blank')
              }>
              View
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* Divider */}
      <Divider sx={{ my: '24px' }} />
      {/* Company Logo Section */}
      <Stack spacing={1}>
        <Typography className="bK-body1">
          Company logo{' '}
          {canUpdateCompanyProfile && (
            <span
              className="text-bk_tag_blue_light ml-1 underline underline-offset-2 cursor-pointer"
              onClick={() => setModalOpen(true)}>
              Edit
            </span>
          )}
        </Typography>

        {companyDetails?.logo ? (
          <DisplayImage
            imageName={companyDetails?.logo}
            imageType="url"
            className="w-auto h-24 object-cover cursor-pointer"
          />
        ) : (
          <DisplayImage
            imageName="no_company_logo.png"
            imageType="image"
            className="w-auto h-24 object-cover cursor-pointer"
          />
        )}

        <div className="flex items-center gap-1 max-w-[376px]">
          <DisplayImage
            imageName="info_blue.svg"
            imageType="icon"
            className="self-start"
          />
          <Typography className="bK-body3 text-bk_text_secondary">
            This logo will be displayed to the employee during data collection.
            Updating the logo will reflect in further BGV initiated
          </Typography>
        </div>
      </Stack>

      {/* Modal section to upload file */}
      <Dialog onClose={handleCancelUpload} open={modalOpen} fullWidth>
        <DialogTitle>Upload Company Logo</DialogTitle>
        <DialogContent>
          <Paper
            elevation={0}
            sx={{
              width: '100%',
              height: '120px',
              padding: '0.75rem',
              border: `1px dashed ${COLORS.bk_action_disabled}`,
              borderRadius: 8,
              cursor: 'pointer',
            }}>
            <Stack
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              height={'100%'}
              {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {companyLogo && (
                <Typography
                  className="bK-body3 text-bk_text_secondary text-center"
                  sx={{ marginTop: '4px !important' }}>
                  {companyLogo?.file_name}
                </Typography>
              )}
              {!companyLogo && (
                <Typography
                  className="bK-body3 text-bk_text_secondary text-center"
                  sx={{ marginTop: '4px !important' }}>
                  Supported formats: jpeg, png & max size 5mb only
                </Typography>
              )}
            </Stack>
          </Paper>
        </DialogContent>
        <DialogActions>
          <BKStyledButton
            sx={{ borderRadius: '8px' }}
            variant="text"
            onClick={handleCancelUpload}>
            Cancel
          </BKStyledButton>
          <BKStyledButton
            sx={{ borderRadius: '8px' }}
            variant="contained"
            disabled={!companyLogo}
            onClick={updateCompanyLogo}>
            Upload
          </BKStyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
  return withViewPermission({
    permissions: [
      canReadCompanyProfile,
      canCreateCompanyProfile,
      canUpdateCompanyProfile,
    ],
    children: children,
  });
};
