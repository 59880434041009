import { CheckoutStore } from '@gigin-work-space/store';
import { COLORS } from '@gigin-work-space/utils';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import { useSnapshot } from 'valtio';
import { CustomTableSelectProps } from './IEditable-types';

export const borderLessSelect = {
  '&:before': {
    borderBottom: 'none',
  },
  '&:after': {
    borderBottom: 'none',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-input': {
    padding: '2.5px 14px !important',
  },
};

const CustomTableCell: React.FC<CustomTableSelectProps> = ({
  id,
  options,
  handleSaveCell,
}) => {
  const { candidateData } = useSnapshot(CheckoutStore);
  const [isEditing, setIsEditing] = useState(false);
  // theme Break points for responsive
  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const handleCellClick = () => {
    setIsEditing(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    if (CheckoutStore.candidateData[id]?.job_role !== selectedValue) {
      CheckoutStore.candidateData[id].job_role = selectedValue;
    }
    setIsEditing(false);
  };

  const handleInputBlur = () => {
    handleSaveCell(id);
    setIsEditing(false);
  };

  return (
    <TableCell
      onClick={handleCellClick}
      sx={{
        maxWidth: isNotExtraLargeScreen ? '6em' : '8em',
        minWidth: isNotExtraLargeScreen ? '6em' : '8em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        overflowX: 'scroll',
      }}
      className="text-ellipsis">
      {isEditing ? (
        <Select
          value={candidateData[id]?.job_role}
          onChange={handleSelectChange}
          onBlur={handleInputBlur}
          autoFocus
          sx={borderLessSelect}
          fullWidth
          error={candidateData[id]?.job_roleError ? true : false} // Set error state on the Select component
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : candidateData[id]?.job_role ? (
        <Typography
          className="flex items-center bK-body2 text-ellipsis"
          sx={{
            color: candidateData[id]?.job_roleError?.length
              ? COLORS.bk_alert_failure
              : '',
          }}>
          {candidateData[id]?.job_role}
        </Typography>
      ) : (
        <span style={{ color: 'gray' }}>Role</span>
      )}
      {candidateData[id]?.job_roleError && (
        <div
          className="bK-body3 text-ellipsis"
          style={{ color: COLORS.bk_alert_failure }}>
          {candidateData[id]?.job_roleError}
        </div>
      )}
    </TableCell>
  );
};

export default CustomTableCell;
