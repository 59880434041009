import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  clearIndexedDBEntries,
  // AUTO_HIDE_TIMEOUT,
  COLORS,
  EnumIndexedDbStore,
  // DataCollectionItemSource,
  guardedPageNavigate,
  SPACING,
} from '@gigin-work-space/utils';
import { Close, Info } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  Box,
  // CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
// import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useWizard } from 'react-use-wizard';
// import { useOrderCreationContext } from 'src/app/context';
// import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

export interface CloseModalProps {}

export const CloseModal = React.memo(() => {
  // TODO: commented for now, will work on this in future
  // const {
  //   state: {
  //     // orderInitiationId,
  //     dataCollectionMode,
  //     parsedExcelDetailsCandidate,
  //     parsedExcelDetailsRecruiter,
  //   },
  // } = useOrderCreationContext();
  const { activeStep } = useWizard();

  const [open, setOpenModal] = useState(false);
  // const [isSavingDraft, setIsSavingDraft] = useState(false);
  const navigate = useNavigate();

  //  =============TODO: Commented for now will work on this in future

  // const { enqueueSnackbar } = useSnackbar();

  // const isCandidate = useMemo(() => {
  //   return (
  //     dataCollectionMode === DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
  //   );
  // }, [dataCollectionMode]);

  // const parsedDetails = useMemo(() => {
  //   return isCandidate
  //     ? parsedExcelDetailsCandidate
  //     : parsedExcelDetailsRecruiter;
  // }, [isCandidate, parsedExcelDetailsCandidate, parsedExcelDetailsRecruiter]);

  const handleExitOrderFlow = useCallback(async () => {
    try {
      await clearIndexedDBEntries(EnumIndexedDbStore.FILES);
    } catch (error) {
      console.error('Failed to clear IndexedDB entries:', error);
    }

    globalNavigateTo(guardedPageNavigate(), navigate);
  }, [navigate]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    // if (isSavingDraft) {
    //   return;
    // }
    setOpenModal(false);
  }, []);
  // }, [isSavingDraft]);

  //  =======TODO: will work on this in future===========

  // const handleSaveDraft = useCallback(async () => {
  //   setIsSavingDraft(true);
  //   try {
  //     const response = await axiosInstance.get(
  //       `${endpoints.CREATE_CHECKOUT_INIT}/${orderInitiationId}`
  //     );
  //     if (response?.data) {
  //       enqueueSnackbar('Draft saved successfully', {
  //         variant: VARIANT_TYPE.SUCCESS,
  //         autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
  //       });
  //       handleExitOrderFlow();
  //     }
  //   } catch (error) {
  //     enqueueSnackbar('Failed to save draft', {
  //       variant: VARIANT_TYPE.ERROR,
  //       autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
  //     });
  //   } finally {
  //     setIsSavingDraft(false);
  //     setOpenModal(false);
  //   }
  // }, [orderInitiationId, enqueueSnackbar, handleExitOrderFlow]);

  const ConfirmExitDialog = React.memo(() => {
    return (
      <>
        <Box className="flex flex-row items-center justify-between gap-x-lg">
          <Box className="flex flex-row items-center gap-x-sm2">
            <Info
              sx={{
                fontSize: SPACING.xl2,
                color: COLORS.bk_error_main,
              }}
            />
            <Typography className="bk-typography-h6 text-bk_error_main">
              Are you sure you want to exit?
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Box>

        <Box className="flex flex-col gap-y-sm">
          <Typography className="bk-typography-body2 font-bold">
            {activeStep === 2
              ? 'The documents you have uploaded will be saved.'
              : 'If you exit the order creation process, your data will be lost and you would have to start from scratch.'}
          </Typography>
        </Box>

        <Box className="flex flex-row items-center justify-between gap-x-sm mt-lg">
          <BKStyledButton
            className="rounded-[4px]"
            variant="outlined"
            fullWidth
            onClick={handleCloseModal}>
            No, I'll continue
          </BKStyledButton>
          <BKStyledButton
            className="rounded-[4px]"
            variant="contained"
            fullWidth
            onClick={handleExitOrderFlow}>
            Yes, Exit
          </BKStyledButton>
        </Box>
      </>
    );
  });

  // TODO: will work on this in future, commented for now

  // const SaveDraftDialog = React.memo(() => {
  //   return (
  //     <>
  //       <Box className="flex flex-row items-center justify-between gap-x-lg">
  //         <Typography className="bk-typography-h6 ">
  //           Do you want to save your progress?
  //         </Typography>
  //         <IconButton aria-label="close" onClick={handleCloseModal}>
  //           <Close />
  //         </IconButton>
  //       </Box>
  //       <Box className="flex flex-col gap-y-sm">
  //         <Typography className="bk-typography-body2">
  //           If you want to process this order at a later time, please save it to
  //           draft. You will loose the progress so far otherwise.
  //         </Typography>
  //       </Box>

  //       <Box className="flex flex-row items-center justify-between gap-x-sm mt-lg">
  //         <BKStyledButton
  //           className="rounded-[4px]"
  //           variant="outlined"
  //           fullWidth
  //           // disabled={isSavingDraft}
  //           onClick={handleExitOrderFlow}>
  //           No, Exit without saving
  //         </BKStyledButton>
  //         {/* <BKStyledButton
  //           className="rounded-[4px]"
  //           variant="contained"
  //           fullWidth
  //           disabled={isSavingDraft}
  //           endIcon={
  //             isSavingDraft ? (
  //               <CircularProgress
  //                 size={20}
  //                 sx={{ color: COLORS.bk_text_secondary2 }}
  //               />
  //             ) : null
  //           }
  //           onClick={handleSaveDraft}>
  //           Yes, Save as draft
  //         </BKStyledButton> */}
  //       </Box>
  //     </>
  //   );
  // });

  // const renderDialogContent = useMemo(() => {
  //   if (activeStep === 0 || activeStep > 1) {
  //     return <ConfirmExitDialog />;
  //   }

  //   if (activeStep === 1) {
  //     if (!parsedDetails?.totalItems) {
  //       return <ConfirmExitDialog />;
  //     } else if (parsedDetails?.totalItems === parsedDetails?.validRecords) {
  //       return <SaveDraftDialog />;
  //     } else {
  //       return <ConfirmExitDialog />;
  //     }
  //   }
  //   return null;
  // }, [activeStep, parsedDetails]);

  return (
    <>
      <CloseIcon
        className="cursor-pointer text-bk_bg_dark w-6 h-6"
        onClick={handleOpenModal}
      />

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={open}>
          <Box className="bk_close_modal p-xl2 rounded-lg flex flex-col gap-y-sm md:min-w-[400px] lg:min-w-[600px]">
            {/* {renderDialogContent} */}
            <ConfirmExitDialog />
          </Box>
        </Fade>
      </Modal>
    </>
  );
});
