import {
  EmployerCDCStore,
  IPermissionStoreProps,
} from '@gigin-work-space/store';
import { DATA_MAPPING_PROGRESS_STEP } from '@gigin-work-space/utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider, Stack, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import ViewCandidateList from './employeer-bulk-view-candidate-list';
import { BulkUploadProgress } from './employer-bulk-upload-progress';

export const HoverContainer = styled('div')({
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#F8F8F8',
  },
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  padding: 10,
});
const EmployerBulkBothLinkAndBulk = (props: {
  packageInfo: any;
  permissions: IPermissionStoreProps;
}) => {
  const packageInfo = props.packageInfo;

  const sentDate = packageInfo.dataCollectionSurvey.sentDate;
  const [timeDifference, setTimeDifference] = useState<any>({ days: 1 });
  const [isStepperStatusCompleted, setIsStepperStatusCompleted] =
    useState<boolean>(false);
  const [successPercentage, setSuccessPercentage] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true); //To enable list ofcandidate model change it true
  };
  useEffect(() => {
    const currentDate = moment();
    const sentDateTime = moment(sentDate);

    const duration = moment.duration(currentDate.diff(sentDateTime));

    setTimeDifference({
      days: duration.days(),
    });
  }, [sentDate]);

  const handleNavigateEBMainContent = (pkg: {
    candidateDetails: { jobRole: string }[];
    packageDetails: { id: string; title: string };
    dataCollectionSurvey: { numberOfCandidates: number };
  }) => {
    const uniqueJobRoles: string[] = [
      ...new Set(
        pkg.candidateDetails.map(
          (item: { jobRole: string }) => item.jobRole as string
        )
      ),
    ];

    const jobRoleCount: { [key: string]: number } = {};

    packageInfo.candidateDetails.forEach((candidate: { jobRole: any }) => {
      const jobRole = candidate.jobRole;
      jobRoleCount[jobRole] = (jobRoleCount[jobRole] || 0) + 1;
    });

    const result = Object.entries(jobRoleCount).map(([title, total_count]) => ({
      title,
      total_count,
    }));

    EmployerCDCStore.activeStep = 1;
    EmployerCDCStore.request_role_id = pkg.packageDetails.id;
    EmployerCDCStore.totalCount = pkg.dataCollectionSurvey.numberOfCandidates;
    EmployerCDCStore.currentJobRoles = result;
    EmployerCDCStore.currentRole = uniqueJobRoles;
    EmployerCDCStore.currentPackage = pkg.packageDetails.title;
  };

  useEffect(() => {
    const orderId = EmployerCDCStore.request_id;
    const packageId = packageInfo.packageDetails.id;
    const fetchData = async () => {
      try {
        const stepStatusResponse = await axiosInstance.get(
          `${endpoints.BULK_UPLOAD_STEP_STATUS}/${orderId}/${packageId}`
        );

        if (stepStatusResponse.data.success) {
          let percentage = 0;
          if (
            stepStatusResponse.data.data.step_1 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.step_2 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.step_3 ===
            DATA_MAPPING_PROGRESS_STEP.COMPLETED
          ) {
            percentage += 33.33;
          }
          if (
            stepStatusResponse.data.data.dataMapped ===
            DATA_MAPPING_PROGRESS_STEP.SUBMITTED
          ) {
            percentage = 100;
            setIsStepperStatusCompleted(true);
          }
          setSuccessPercentage(percentage);
        }
      } catch (e) {
        enqueueSnackbar('Error while fetching status', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Stack className="mt-7" direction={'column'} rowGap={0.5}>
        <Stack direction={'row'} rowGap={0.5}>
          <Typography className="bk-sub-heading2">
            <b>Data collected for candidate</b>
          </Typography>
          <Typography
            className="bk-both-link-and-bulk-typography"
            onClick={() => handleNavigateEBMainContent(packageInfo)}>
            {isStepperStatusCompleted ? 'View Details' : 'Go to Upload'}
            <ArrowForwardIosIcon className="ml-1 text-xs" />
          </Typography>
        </Stack>
        <BulkUploadProgress
          orderCompleteStatus={{
            fail_count: 0,
            fail_percentage: 0,
            success_count: 0,
            success_percentage: successPercentage,
            total_count: packageInfo.dataCollectionSurvey.numberOfCandidates,
            warn_count: 0,
            warn_percentage: 0,
          }}
          isStepperStatusCompleted={isStepperStatusCompleted}
        />
      </Stack>
      <Stack className="mt-4 flex items-center" direction={'row'}>
        <HoverContainer
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleOpenModal}>
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">
              {packageInfo.bulkUpload.candidateDetailsUploaded}{' '}
            </b>
            Candidate details Uploaded
            {isHovered && <VisibilityIcon className="bk-eye-icon" />}
          </Typography>
        </HoverContainer>

        {packageInfo?.dataCollectionSurvey?.dataCollected?.completed ? (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="bk-both-link-and-bulk-divider-1"
          />
        ) : (
          ''
        )}
        {packageInfo?.dataCollectionSurvey?.dataCollected?.completed ? (
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">
              {packageInfo?.dataCollectionSurvey?.dataCollected?.completed}{' '}
            </b>{' '}
            Received via link
          </Typography>
        ) : (
          ''
        )}

        {packageInfo?.bulkUpload?.numberOfCandidates -
        packageInfo?.bulkUpload?.candidateDetailsUploaded ? (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="bk-both-link-and-bulk-divider-2"
          />
        ) : (
          ''
        )}
        {packageInfo?.bulkUpload?.numberOfCandidates -
        packageInfo?.bulkUpload?.candidateDetailsUploaded ? (
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">
              {packageInfo?.bulkUpload?.numberOfCandidates -
                packageInfo?.bulkUpload?.candidateDetailsUploaded}{' '}
            </b>{' '}
            Yet to upload
          </Typography>
        ) : (
          ''
        )}
      </Stack>
      {/* <Stack direction={'row'}> */}
      <Box className="bk-both-link-and-bulk-box">
        <Stack className="p-5 items-center" direction={'row'}>
          <DisplayImage
            imageName="xl-icon.svg"
            imageType="icon"
            width="18px"
            height="18px"
            className="ml-[10px]"
          />{' '}
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">1</b> File Uploaded
          </Typography>
          <DisplayImage
            imageName="zip-icon.svg"
            imageType="icon"
            width="18px"
            height="18px"
            className="ml-5"
          />{' '}
          <Typography className="bK-body2 text-bk_text_secondary">
            <b className="bk-body1">
              {packageInfo.bulkUpload.candidateDocumentsUploaded}{' '}
            </b>{' '}
            Documents uploaded
          </Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="bk-both-link-and-bulk-divider-3"
          />
          <Typography className="bK-body2 text-bk_text_secondary">
            {`Link sent ${
              timeDifference.days === 0
                ? 'today'
                : timeDifference.days === 1
                ? '1 day ago'
                : `${timeDifference.days} days ago`
            }`}
          </Typography>
        </Stack>
      </Box>
      {/* </Stack> */}
      <ViewCandidateList
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        packageInfo={packageInfo}
        // handleDataCollectionLink={handleDataCollectionLink}
      />
    </>
  );
};

export default EmployerBulkBothLinkAndBulk;
