import { ConsentType, fetchFileWithAuth } from '@gigin-work-space/utils';
import { Box, Button, Grid, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { DisplayImage } from 'src/app/components';

interface ConsentDocumentProps {
  consentDocumentURL: string;
  consentType: string;
  candidateName: string;
}

const ConsentDocument: React.FC<ConsentDocumentProps> = ({
  consentDocumentURL,
  consentType,
  candidateName,
}) => {
  const handleDownload = async () => {
    if (consentDocumentURL) {
      try {
        const pdfData = await fetchFileWithAuth(consentDocumentURL);
        const blob = new Blob([pdfData], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `consent_document-${candidateName}.pdf`;
        document.body.appendChild(link); // For Firefox it seems necessary to add the element to the DOM before clicking it
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        enqueueSnackbar('Failed to download consent', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      enqueueSnackbar('Consent document is not available for download', {
        variant: 'warning',
        autoHideDuration: 2000,
      });
    }
  };

  const getConsentText = () => {
    switch (consentType) {
      case ConsentType.OTP_VERIFICATION:
        return 'Consent via OTP';
      case ConsentType.E_SIGN:
        return 'Consent via E-Sign';
      default:
        return 'Manual Consent form';
    }
  };

  return (
    <Grid sx={{ mt: 10, mr: 16, flex: 1 }}>
      <Box
        sx={{
          border: '1px solid #DCD9DD',
          borderRadius: '8px',
          width: 300, // Removed quotes to use numbers directly
          height: 77, // Same as above
          display: 'flex',
          alignItems: 'center',
        }}>
        <DisplayImage
          className="ml-3"
          imageName="pdf-icon.svg"
          imageType="icon"
        />
        <Grid className="ml-1">
          <Typography className="bk-sub-heading1">
            {getConsentText()}
          </Typography>
          {consentDocumentURL ? (
            <Button onClick={handleDownload} className="normal-case p-0">
              Download
              <DisplayImage
                className="ml-1"
                imageName="download-icon.svg"
                imageType="icon"
              />
            </Button>
          ) : (
            <Typography className="bK-hint-text mt-1" sx={{ fontSize: '11px' }}>
              Yet to be uploaded
            </Typography>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default ConsentDocument;
