/* eslint-disable array-callback-return */
import {
  EmployerCDCStore,
  IPermissionStoreProps,
} from '@gigin-work-space/store';
import {
  COLORS,
  EmployerAppAssetIconsFileNames,
  SPACING,
} from '@gigin-work-space/utils';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import ComingSoonModal from '../skelton/coming-soon-model';

const EmployerBulkNeitherLinkNorBulk = (props: {
  packageInfo: any;
  permissions: IPermissionStoreProps;
}) => {
  const [isModal, setIsModalOpen] = useState(false);
  const packageInfo = props.packageInfo;

  const handleLink = async () => {
    try {
      const dataCollectionIDList: string[] = [];
      packageInfo.candidateDetails.map((item: { dataCollectionId: string }) => {
        dataCollectionIDList.push(item.dataCollectionId);
      });
      const response = await axiosInstance.post(
        `${endpoints.BULK_LINK_SEND}`,
        dataCollectionIDList
      );

      if (response.data.success) {
        const linkStatusUpdated = await axiosInstance.patch(
          `${endpoints.BULK_DATA_COLLECTION_LINK}/${EmployerCDCStore.request_id}/${packageInfo.packageDetails.id}`
        );

        if (linkStatusUpdated.data.success) {
          enqueueSnackbar('Link sent successfully', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
        }
      }
    } catch (error) {
      enqueueSnackbar('Error while sending link', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseComingSoonModal = () => {
    setIsModalOpen(false);
  };

  function openModelComingSoon() {
    handleOpenModal();
  }
  //TODO: This is temporally disabled
  // const handleNavigateEBMainContent = (pkg: {
  //   candidateDetails: { jobRole: string }[];
  //   packageDetails: { id: string; title: string };
  //   dataCollectionSurvey: { numberOfCandidates: number };
  // }) => {
  //   const uniqueJobRoles: string[] = [
  //     ...new Set(
  //       pkg.candidateDetails.map(
  //         (item: { jobRole: string }) => item.jobRole as string
  //       )
  //     ),
  //   ];

  //   const jobRoleCount: { [key: string]: number } = {};

  //   packageInfo.candidateDetails.forEach((candidate: { jobRole: any }) => {
  //     const jobRole = candidate.jobRole;
  //     jobRoleCount[jobRole] = (jobRoleCount[jobRole] || 0) + 1;
  //   });

  //   const result = Object.entries(jobRoleCount).map(([title, total_count]) => ({
  //     title,
  //     total_count,
  //   }));

  //   EmployerCDCStore.activeStep = 1;
  //   EmployerCDCStore.request_role_id = pkg.packageDetails.id;
  //   EmployerCDCStore.totalCount = pkg.dataCollectionSurvey.numberOfCandidates;
  //   EmployerCDCStore.currentJobRoles = result;
  //   EmployerCDCStore.currentRole = uniqueJobRoles;
  //   EmployerCDCStore.currentPackage = pkg.packageDetails.title;
  // };

  return (
    <>
      <Stack direction={'row'} alignItems={'flex-start'}>
        <Box className="bk-neither-link-nor-bulk-box-1">
          <Stack direction={'column'}>
            <Typography className="bK-body1 p-[14px]">
              Collect data directly from candidate by sending a survey link to
              their Inbox
            </Typography>
            <Button
              className="bk-neither-link-nor-bulk-send-link-btn"
              onClick={() => {
                handleLink();
              }}>
              <DisplayImage
                imageName="send-link-icon.svg"
                imageType="icon"
                width="14px"
                height="14px"
              />
              <span className="bk-neither-link-nor-bulk-send-link-text">
                Send Link
              </span>
            </Button>
          </Stack>
        </Box>
        <Box className="bk-neither-link-nor-bulk-box-2">
          <Stack direction={'column'}>
            <Typography className="bK-body1 p-[14px]">
              Upload the details and documents of the candidates in bulk & with
              ease
            </Typography>
            <Stack className="flex items-start p-[14px]" direction={'row'}>
              <DisplayImage
                imageName={EmployerAppAssetIconsFileNames.RED_ICON_SVG}
                imageType="icon"
                width={SPACING.lg}
                height={SPACING.lg}
              />
              <Typography
                className={`bK-body2 pl-[5px] text-[${COLORS.bk_alert_failure}]`}>
                We are currently improving the experience of this feature.
                Please contact our team to share candidate details
              </Typography>
            </Stack>

            <Button
              className="bk-neither-link-nor-bulk-upload-data-btn"
              onClick={() => {
                // handleNavigateEBMainContent(packageInfo);
                openModelComingSoon();
              }}>
              <DisplayImage
                imageName={EmployerAppAssetIconsFileNames.UPLOAD_BULK_ICON_SVG}
                imageType="icon"
                width="14px"
                height="14px"
              />
              <span className="bk-neither-link-nor-bulk-upload-data-text">
                Upload Data
              </span>
            </Button>
            <ComingSoonModal
              isOpen={isModal}
              onClose={handleCloseComingSoonModal}
            />
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default EmployerBulkNeitherLinkNorBulk;
