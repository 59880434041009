import { ThemeOptions } from '@mui/material';
import { proxy } from 'valtio';

// user data type
interface IUserDataType {
  user: string | null;
  email: string | null;
  authToken?: string | null;
}

// userStore Type
interface IUserStoreType {
  path: string | null;
  themeOption: ThemeOptions;
  selectedUser: IUserDataType;
  isLoading: boolean | null;
}

// User dummy store creation
export const UserStore: IUserStoreType = proxy({
  path: '',
  themeOption: {},
  selectedUser: {
    user: null,
    email: null,
    authToken: null,
  },
  isLoading: true,
});
