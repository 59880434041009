import { BKSelectedButton } from '@gigin-work-space/common-ui';
import { borderLessSelect, FilterStatus } from '@gigin-work-space/utils';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

interface IOwner {
  _id: string;
  name: string;
  email: string;
  teams: string[];
  accounts: string[];
  role: string;
}

interface ICheckCTAProps {
  isScreenLarger: boolean;
  selectedCases: string[];
  handleAssignBulkOwner: (_event: SelectChangeEvent<string>) => Promise<void>;
  filteredUser: string;
  owners: IOwner[] | any[];
  isClicked: boolean;
  handleShowMyChecks: () => void;
  handleUnassign: () => void;
  showUnassigned: boolean;
  orderStatus: FilterStatus;
  handleSetOrder: (_status: FilterStatus) => void;
  handleFilterByOwner: (_owner: string) => void;
}

const ChecksFIlterCTA = ({
  isScreenLarger,
  selectedCases,
  handleAssignBulkOwner,
  filteredUser,
  owners,
  isClicked,
  handleShowMyChecks,
  handleUnassign,
  showUnassigned,
  orderStatus,
  handleSetOrder,
  handleFilterByOwner,
}: ICheckCTAProps) => {
  const menuItems = [
    { value: FilterStatus.ALL, label: FilterStatus.ALL_ORDERS },
    { value: FilterStatus.CLEAR, label: FilterStatus.CLEAR_STATUS },
    { value: FilterStatus.INPROGRESS, label: FilterStatus.INPROGRESS_STATUS },
    { value: FilterStatus.DISCREPANT, label: FilterStatus.DISCREPANT_STATUS },
    {
      value: FilterStatus.INSUFFICIENT,
      label: FilterStatus.INSUFFICIENT_STATUS,
    },
    {
      value: FilterStatus.UNABLE_TO_VERIFY,
      label: FilterStatus.UNABLE_TO_VERIFY_STATUS,
    },
    {
      value: FilterStatus.NO_RESPONSE,
      label: FilterStatus.NO_RESPONSE_STATUS,
    },
    {
      value: FilterStatus.NOT_REQUIRED,
      label: FilterStatus.NOT_REQUIRED_STATUS,
    },
  ];

  return (
    <Grid
      container
      direction={isScreenLarger ? 'row' : 'column'}
      className="items-center gap-4 justify-end">
      <Grid item className="flex items-center" p={0}>
        <Box
          display="flex"
          alignItems="center"
          borderRadius={2}
          className="bk-bulk-assignment">
          <Box className="bk-bulk-check">
            <Typography className="text-[#616063] font-semibold">
              {selectedCases?.length}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            className="mr-2 text-bk_text_secondary text-[15px]">
            Cases selected
          </Typography>
          <FormControl className="min-w-[200px]" size="small">
            <InputLabel id="assigned-to-label">Assigned to</InputLabel>
            <Select
              labelId="assigned-to-label"
              label="Assigned to"
              id="assigned-to"
              className="rounded-3xl"
              onChange={handleAssignBulkOwner}
              value={filteredUser ?? ''}
              disabled={selectedCases?.length === 0}>
              <MenuItem value="" disabled>
                Select an owner
              </MenuItem>
              {owners?.map(user => (
                <MenuItem
                  key={user?._id}
                  value={user}
                  className=" text-bk_text_secondary text-[15px] py-2">
                  {user?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item>
        <BKSelectedButton isClicked={isClicked} onClick={handleShowMyChecks}>
          My Checks
        </BKSelectedButton>
      </Grid>

      <Grid item>
        <BKSelectedButton onClick={handleUnassign} isClicked={showUnassigned}>
          Unassigned
        </BKSelectedButton>
      </Grid>
      <Grid item padding={2}>
        <FormControl
          variant="standard"
          className={`${isScreenLarger ? 'w-2/3' : 'w-full'} bk-border-cta`}>
          <Select
            id="orderStatus"
            name="orderStatus"
            value={orderStatus}
            onChange={(event: any) => {
              handleSetOrder(event.target.value as FilterStatus);
            }}
            className="bK-body2 text-bk_text_secondary text-sm"
            label="All"
            sx={borderLessSelect}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                className="text-bk_text_secondary text-[15px]">
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <FormControl className="m-2 min-w-[180px] mt-4" size="small">
        <InputLabel id="demo-simple-select-helper-label">
          Assigned to
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={filteredUser}
          label="Assigned to"
          className="rounded-3xl"
          onChange={(event: SelectChangeEvent<string>) => {
            handleFilterByOwner(event.target.value);
          }}>
          <MenuItem
            value={'All Users'}
            className="text-bk_text_secondary text-[15px] py-2">
            All Users
          </MenuItem>
          {owners?.map(user => (
            <MenuItem
              key={user?._id}
              value={user?.name}
              className=" text-bk_text_secondary text-[15px] py-2">
              {user?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default ChecksFIlterCTA;
