import {
  BKStyledButton,
  CommonVeriticalTab,
} from '@gigin-work-space/common-ui';
import { COLORS, OPS_PLATFORM_MENU } from '@gigin-work-space/utils';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { axiosInstance, endpoints } from '../../../utils';
import { BasicDetails } from './basic-detail';
import { CheckDetailHOC } from './check-detail-hoc';

export function IndividualCaseDetail() {
  const location = useLocation();
  const { caseDetails } = location?.state || {};
  const [allChecks, setAllChecks] = useState<any>([]);
  const [checkMenuMapper, setCheckMenuMapper] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { caseId } = useParams();

  const navigate = useNavigate();

  const createCheckMenu = (menu: any) => {
    const menuList = [
      { id: caseId, component: <BasicDetails checkData={menu} /> },
    ];
    menu.forEach((element: any) => {
      menuList.push({
        id: element.check_id?._id,
        component: <CheckDetailHOC checkData={element} />,
      });
    });

    return menuList;
  };

  const getAllChecks = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_CHECK_LIST}/${caseId}`
      );
      if (response.data.success) {
        const checkMenu = createCheckMenu(response.data.data);

        setCheckMenuMapper(checkMenu);
        setAllChecks(response.data.data);
        return;
      }
      throw new Error('Failed to fetch checks');
    } catch (error) {
      enqueueSnackbar('Error fetching response', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  useEffect(() => {
    getAllChecks();
  }, [getAllChecks]);

  const handleback = () => {
    navigate(-1);
  };

  // to copy text to clipboard

  const handleClipToBoard = async () => {
    try {
      const textToCopy = caseDetails?.ext_case_id;
      await navigator.clipboard.writeText(textToCopy);
      setSnackbarMessage('Copied to clipboard');
      setOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to copy');
      setOpen(true);
    }
  };

  //  to view Report separately in individual case detail

  const handleViewReport = (event: SyntheticEvent, caseId: string) => {
    event.stopPropagation();

    globalNavigateTo(`/ops-platform/report/${caseId}`, navigate);
  };

  const handleViewReportConsolidated = (
    event: SyntheticEvent,
    caseId: string
  ) => {
    event.stopPropagation();

    globalNavigateTo(`/ops-platform/report-consolidated/${caseId}`, navigate);
  };

  return (
    <>
      {/* Top header section START*/}
      <Box className="sticky top-0 z-10 bg-bk_bg_primary">
        <Grid className="flex items-center gap-0">
          <KeyboardBackspaceIcon
            onClick={handleback}
            className="ml-2 cursor-pointer"
          />
          <Grid className="w-full flex justify-between items-center p-4">
            <Typography className="bk-headline1 flex items-center justify-center gap-4">
              {caseDetails?.actor_id?.[0]?.name} -{' '}
              <span className="text-sm mt-1">
                {caseDetails?.ext_case_id}
                <IconButton onClick={handleClipToBoard}>
                  <ContentCopyRoundedIcon
                    className={`text-sm ${COLORS.bk_bg_light}`}
                  />
                </IconButton>
              </span>
            </Typography>

            <Box className="flex flex-row items-center gap-x-4">
              <BKStyledButton
                variant="text"
                className={`border border-${COLORS.bk_tag_blue} border-solid`}
                onClick={event => handleViewReport(event, caseId ?? '')}>
                View Report
              </BKStyledButton>
              <BKStyledButton
                variant="text"
                className={`border border-${COLORS.bk_tag_blue} border-solid`}
                onClick={event =>
                  handleViewReportConsolidated(event, caseId ?? '')
                }>
                Consolidated Report
              </BKStyledButton>
            </Box>
          </Grid>

          <Snackbar
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000} // shorter duration for better UX
            onClose={() => setOpen(false)}
            open={open}
          />
        </Grid>
        <Divider className={`${COLORS.bk_stroke_secondary}`} />
      </Box>
      {/* Top header section END*/}
      {/* Middle Analytics section START */}
      {allChecks.length && checkMenuMapper.length && (
        <CommonVeriticalTab
          tabComponents={checkMenuMapper}
          type={OPS_PLATFORM_MENU}
          tabMenu={allChecks}
        />
      )}
      {/* Middle Analytics section END */}
    </>
  );
}
