import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { verifyInDark } from './image-library';
import { ImageComponent } from './report-image';
import React from 'react';

export interface ReportHeaderProps {
  reportDataObject: any;
}

export const ReportHeader = React.memo((props: ReportHeaderProps) => {
  const { reportDataObject } = props;
  return (
    <View fixed style={styles.header}>
      <View style={styles.logoContainer}>
        <ImageComponent src={verifyInDark} style={styles.logoImage} />
        <Text style={styles.textBody3}>
          {reportDataObject.report_details.title}
        </Text>
      </View>
      <View style={styles.candidateContainer}>
        <ImageComponent
          src={reportDataObject.basic_detail.profile_image}
          style={styles.profileImageSmall}
        />
        <View style={styles.candidateDetails}>
          <Text style={styles.textSubHeading3}>
            {reportDataObject.basic_detail.name}
          </Text>
          <Text style={[styles.textBody3, styles.textSecondary]}>
            {reportDataObject.basic_detail.case_id}
          </Text>
        </View>
      </View>
    </View>
  );
});
