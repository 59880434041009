import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { COLORS } from '@gigin-work-space/utils';
import { Paper, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { IVsdAll } from './IAnalytics';
import './MyChart.css'; // Import the CSS file for chart styling

export const QualityHire = () => {
  const [qualityAll, setQualityAll] = useState([] as IVsdAll[]);
  const { enqueueSnackbar } = useSnackbar();

  // function for array of object data need to feed for chart
  const returnChartData = (
    name: string[],
    role1: number[],
    role2: number[],
    role1Name: string,
    role2Name: string
  ) => {
    let arr = [];
    arr = name.map((item, index) => ({
      name: item,
      role1: role1[index],
      role2: role2[index],
      role1Name,
      role2Name,
    }));

    return arr;
  };

  const data = returnChartData(
    qualityAll
      ? qualityAll[0]?.distribution?.map(data => data.label) ?? []
      : [],
    qualityAll
      ? qualityAll[0]?.distribution?.map(data => data.value) ?? []
      : [],
    qualityAll
      ? qualityAll[1]?.distribution?.map(data => data.value) ?? []
      : [],
    qualityAll[0]?.title?.value.toString() ?? 'Role 1 Default Name',
    qualityAll[1]?.title?.value.toString() ?? 'Role 2 Default Name'
  );

  // custom tooltip
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <Paper className="p-4" elevation={1}>
          <Typography className="label">{`${label}`}</Typography>
          <Typography className="intro">{`${data.role1Name}: ${data.role1}`}</Typography>
          <Typography className="intro">{`${data.role2Name}: ${data.role2}`}</Typography>
        </Paper>
      );
    }

    return null;
  };

  //draw chart
  const chartDraw = (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        {/* gradient color code */}
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#BCE1FF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#BCE1FF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FFE4BC" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FFE4BC" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name">
          <Label
            value={qualityAll[0]?.title?.value.toString() ?? 'unknown'}
            offset={0}
            position="insideBottom"
          />
        </XAxis>
        <YAxis
          label={{
            value: qualityAll[1]?.title?.value.toString() ?? 'unknown',
            angle: -90,
            position: 'insideLeft',
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign="top"
          height={36}
          payload={[
            {
              value: qualityAll[0]?.title?.value.toString() ?? 'unknown',
              type: 'circle',
              color: '#167FCC',
            },
            {
              value: qualityAll[1]?.title?.value.toString() ?? 'unknown',
              type: 'circle',
              color: '#E4A80E',
            },
          ]}
        />
        <Area
          dataKey="role1"
          connectNulls
          // stackId="1"
          stroke="#167FCC"
          fill="url(#colorUv)"
        />
        <Area
          dataKey="role2"
          connectNulls
          // stackId="1"
          stroke="#E4A80E"
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  const fetchAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.QUALITY_ALL}/role?type=month&duration=6`
      );

      if (response.data) {
        setQualityAll(response?.data?.data ?? []);
      }
    } catch (e) {
      enqueueSnackbar(`Fetching Quality Hire is Failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <Paper elevation={0} sx={{ background: COLORS.bk_bg_primary }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 1.5,
          borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
        }}>
        <Typography className="bk-heading1">Quality of Hires</Typography>
        {/* <MoreVertIcon
          className="cursor-pointer"
          sx={{
            color: COLORS.bk_stroke_divider,
          }}
        /> */}
      </Stack>
      <Stack
        // direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 2,
          height: '100%',
        }}>
        {qualityAll.length ? (
          chartDraw
        ) : (
          <ChartEmptyState
            // maxHeight={'100vh'}
            imageComponent={
              <DisplayImage
                imageName="quality-empty-state.svg"
                imageType="icon"
                style={{
                  height: '250px',
                  width: 'auto',
                }}
              />
            }
            description="Advance your hiring process through insights on quality."
          />
        )}
      </Stack>
    </Paper>
  );
};
