import { ICurrentProgess } from '@gigin-work-space/model';
import {
  getCandidateFullStatus,
  orderStatusMap,
} from '@gigin-work-space/utils';
import { Stack, Tooltip, Typography } from '@mui/material';
import { DisplayImage } from 'src/app/components';

export const StatusChip = (currentProgess: ICurrentProgess) => {
  const { bgColor, color, icon, outline, message, status } =
    getCandidateFullStatus(currentProgess);
  return (
    <Tooltip title={orderStatusMap(message)} placement="top" arrow>
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'center', // Center align the items (including text) in the Stack
          justifyContent: 'center', // Optionally, center align the Stack horizontally
          px: 1,
          py: 0.5,
          borderRadius: '3.125rem',
          bgcolor: bgColor,
          border: `1px solid ${outline}`,
          gap: '0.25rem',
          width: 'max-content',
          textAlign: 'left',
        }}>
        {icon === 'Warning' ? (
          <DisplayImage
            imageName="warning_icon.svg"
            imageType="icon"
            width="16px"
            height="16px"
          />
        ) : icon === 'Success' ? (
          <DisplayImage
            imageName="tick-white-icon.svg"
            imageType="icon"
            width="16px"
            height="16px"
          />
        ) : icon === 'Failure' ? (
          <DisplayImage
            imageName="round-warning.svg"
            imageType="icon"
            width="16px"
            height="16px"
          />
        ) : (
          <></>
        )}

        {/* TODO: color change sx={{color:row.original.status.}} */}
        <Typography
          className="bK-body2 bk-text-capitalize"
          sx={{ color: color }}>
          {orderStatusMap(status)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export const ProgressStep = (currentProgess: ICurrentProgess) => {
  const { bgColor, color, outline, title } =
    getCandidateFullStatus(currentProgess);
  return (
    <Tooltip title={''} placement="top" arrow>
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'center', // Center align the items (including text) in the Stack
          justifyContent: 'center', // Optionally, center align the Stack horizontally
          px: 1,
          py: 0.5,
          borderRadius: '3.125rem',
          bgcolor: bgColor,
          border: `1px solid ${outline}`,
          gap: '0.25rem',
          width: 'max-content',
          textAlign: 'left',
        }}>
        <Typography
          className="bK-body2 bk-text-capitalize"
          sx={{ color: color }}>
          {title}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
