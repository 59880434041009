import {
  BKStyledEBTableCell,
  DrawerComponent,
} from '@gigin-work-space/common-ui';
import { OrderCandidateStore } from '@gigin-work-space/store';
import {
  COLORS,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  FilterStatus,
  OrderBy,
  ROWS_PER_PAGE_ARRAY,
  ROWS_TO_BE_SHOWN,
  SortingOrder,
} from '@gigin-work-space/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { useSnapshot } from 'valtio';
import { CandidateIndividualCheck } from './components';
import { AllCasesSearchFilterComponent } from './components/all-cases-search-filter-component';

interface TableData {
  _id: string;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  sla: string;
  packageName: string;
  currentProgess: {
    message: string;
    status: string;
    title: string;
  };
  jobRole: string;
  caseId: string;
  overallStatus: string;
  order_service_status: string;
}

export const BgvAllCasesTable = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [page, setPage] = useState(0);
  const [totalCases, setTotalCases] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_TO_BE_SHOWN);

  const [orderBy, setOrderBy] = useState<keyof TableData>(OrderBy.CREATED);

  const [order, setOrder] = useState<SortingOrder.ASC | SortingOrder.DESC>(
    SortingOrder.DESC
  );

  const [selectedRole, setSelectedRole] = useState([] as string[]);
  const [selectedReportGeneratedStatus, setSelectedReportGeneratedStatus] =
    useState<string>('');
  const [selectedProgressStatus, setSelectedProgressStatus] = useState(
    [] as string[]
  );
  const [selectedInitiatedStatus, setSelectedInitiatedStatus] =
    useState<string>('');
  const [selectedReportStatusModal, setSelectedReportStatusModal] =
    useState<string>(FilterStatus.ALL);
  const [selectedPackages, setSelectedPackages] = useState([] as string[]);

  const [dates, setDates] = useState<any>([]);

  const [open, setOpen] = useState(false);

  const { caseList, metaData, caseTablePage } =
    useSnapshot(OrderCandidateStore);

  const handleSelectSelectedRole = (
    event: React.SyntheticEvent,
    value: string[]
  ) => {
    setSelectedRole(value);
  };

  const handleSelectSelectedPackages = (
    event: React.SyntheticEvent,
    value: string[]
  ) => {
    setSelectedPackages(value);
  };

  const handleSelectSelectedProgressStatus = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    setSelectedProgressStatus(value);
  };

  const handleFilterDate = (dates: any[]) => {
    OrderCandidateStore.range_date = [dates[0], dates[1]];
    setDates([dates[0], dates[1]]);
  };

  const handleSelectSelectedReportGeneratedStatus = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelectedReportGeneratedStatus(value);
  };

  const handleSelectSelectedInitiatedStatus = (value: string) => {
    if (value === FilterStatus.RANGE_DATE) {
      if (
        (OrderCandidateStore.range_date[0] === null &&
          OrderCandidateStore.range_date[1] === null) ||
        (OrderCandidateStore.range_date[0] === '' &&
          OrderCandidateStore.range_date[1] === '')
      ) {
        setSelectedInitiatedStatus('');
        setDates(['', '']);
        OrderCandidateStore.range_date = ['', ''];
      } else {
        if (
          OrderCandidateStore.range_date[0] === null ||
          OrderCandidateStore.range_date[0] === ''
        ) {
          OrderCandidateStore.range_date[0] = OrderCandidateStore.range_date[1];
        } else if (
          OrderCandidateStore.range_date[1] === null ||
          OrderCandidateStore.range_date[1] === ''
        ) {
          OrderCandidateStore.range_date[1] = OrderCandidateStore.range_date[0];
        }
        setSelectedInitiatedStatus(FilterStatus.CUSTOM);
        setDates([
          OrderCandidateStore.range_date[0],
          OrderCandidateStore.range_date[1],
        ]);
      }
    } else {
      setSelectedInitiatedStatus(value);
      setDates(['', '']);
      OrderCandidateStore.range_date = ['', ''];
    }
  };

  const handleSelectSelectedReportStatusModal = (
    _event: React.MouseEvent<HTMLElement> | null,
    value: string
  ) => {
    if (value === null) {
      setSelectedReportStatusModal(FilterStatus.ALL);
    } else {
      setSelectedReportStatusModal(value);
    }
  };

  const handleSortRequest = (property: keyof TableData) => {
    const newOrderBy: keyof TableData = property;

    const isAsc = orderBy === newOrderBy && order === SortingOrder.ASC;
    setOrderBy(newOrderBy);
    setOrder(isAsc ? SortingOrder.DESC : SortingOrder.ASC);
    // Perform sorting logic here and update the tableData state accordingly
    // You may use Array.prototype.sort() for sorting
  };

  const getMenuItemContent = (value: string) => {
    switch (value) {
      case FilterStatus.CLEAR:
        return (
          <div className="flex">
            <DisplayImage
              containerClass="items-center"
              imageName="verifyin-green-icon.svg"
              imageType="image"
              width="12px"
              height="12px"
            />
            <span className="ml-1">Verifyin Green</span>
          </div>
        );
      case FilterStatus.DISCREPANT:
        return (
          <div className="flex">
            <DisplayImage
              containerClass="items-center"
              imageName="verifyin-red-icon.svg"
              imageType="image"
              width="12px"
              height="12px"
            />
            <span className="ml-1">Verifyin Red</span>
          </div>
        );
      case FilterStatus.NO_RESPONSE:
        return (
          <div className="flex">
            <DisplayImage
              containerClass="items-center"
              imageName="verifyin-grey-icon.svg"
              imageType="image"
              width="12px"
              height="12px"
            />
            <span className="ml-1">Verifyin Grey</span>
          </div>
        );
      case FilterStatus.INSUFFICIENT:
        return (
          <div className="flex">
            <DisplayImage
              containerClass="items-center"
              imageName="verifyin-amber-icon.svg"
              imageType="image"
              width="12px"
              height="12px"
            />
            <span className="ml-1">Verifyin Amber</span>
          </div>
        );
      case FilterStatus.INPROGRESS:
        return 'Yet to generate';

      case FilterStatus.CANCELATION_REQUESTED:
        return 'Cancellation requested';
      // Add other cases as needed
      default:
        return <span className="ml-1 bk-text-capitalize">{value}</span>;
    }
  };

  const headers = [
    { label: 'Candidate Name' },
    { label: 'Report status' },
    { label: 'Initiated date' },
    { label: 'Completion date' },
    { label: 'Time taken' },
    { label: 'Package Selected' },
    { label: 'Progress Status' },
    { label: 'Role' },
    { label: 'Order Number' },
    { label: 'Actions' },
  ];

  const sortedData = stableSort(tableData, getComparator(order, orderBy));

  function stableSort(
    array: TableData[],
    comparator: (_a: TableData, _b: TableData) => number
  ) {
    const stabilizedThis = array.map(
      (el, index) => [el, index] as [TableData, number]
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getComparator<T>(
    order: SortingOrder.ASC | SortingOrder.DESC,
    orderBy: keyof T
  ): (_a: T, _b: T) => number {
    return order === SortingOrder.DESC
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const valueA = a[orderBy];
    const valueB = b[orderBy];

    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  }

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const calculateTimeTaken = (startDate: Date, endDate: Date) => {
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return days;
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setPage(caseTablePage - 1);
  }, [caseTablePage]);

  useEffect(() => {
    setTableData(OrderCandidateStore.caseList);
    setTotalCases(OrderCandidateStore.metaData.totalItems);
  }, [caseList, metaData]);

  const handleRowsPerPageChange = async (event: SelectChangeEvent) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    OrderCandidateStore.caseTableLimit = Number(event.target.value);
    OrderCandidateStore.caseTablePage = 1;
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
    OrderCandidateStore.caseTablePage = newPage + 1;
  };

  const totalPages = Math.ceil(Number(totalCases) / Number(rowsPerPage));

  return (
    <>
      <AllCasesSearchFilterComponent
        handleFilterDate={handleFilterDate}
        dates={dates}
        selectedRole={selectedRole}
        handleSelectSelectedRole={handleSelectSelectedRole}
        selectedReportStatusModal={selectedReportStatusModal}
        handleSelectSelectedReportStatusModal={
          handleSelectSelectedReportStatusModal
        }
        selectedReportGeneratedStatus={selectedReportGeneratedStatus}
        handleSelectSelectedReportGeneratedStatus={
          handleSelectSelectedReportGeneratedStatus
        }
        selectedInitiatedStatus={selectedInitiatedStatus}
        handleSelectSelectedInitiatedStatus={
          // Removed 'event' parameter from the function call
          (value: string) => handleSelectSelectedInitiatedStatus(value)
        }
        selectedProgressStatus={selectedProgressStatus}
        handleSelectSelectedProgressStatus={handleSelectSelectedProgressStatus}
        selectedPackages={selectedPackages}
        handleSelectSelectedPackages={handleSelectSelectedPackages}
      />
      <DrawerComponent open={open} onClose={onClose} anchor="right">
        <CandidateIndividualCheck onClose={onClose} />
      </DrawerComponent>
      <TableContainer className="bk-table-container mt-xs2 rounded-lg md:max-h-[52vh] lg:max-h-[60vh]">
        <Table className="bk-all-cases-table-scroll-shadow">
          <TableHead className="bk-all-cases-table-head">
            <TableRow className="border border-bk_grey_90">
              {headers.map((header, index) => (
                <BKStyledEBTableCell
                  key={index}
                  className={`bk-styled-header bk-typography-caption white-background !px-8 ${
                    header.label === 'Candidate Name'
                      ? 'bk-fixed-column-header z-10'
                      : ''
                  } ${header.label === 'Actions' ? 'sticky right-0' : ''}`}>
                  {header.label === 'Initiated date' ? (
                    <TableSortLabel
                      active={orderBy === OrderBy.CREATED}
                      direction={
                        orderBy === OrderBy.CREATED ? order : SortingOrder.ASC
                      }
                      onClick={() => handleSortRequest(OrderBy.CREATED)}>
                      <b>{header.label}</b>
                    </TableSortLabel>
                  ) : header.label === 'Completion date' ? (
                    <TableSortLabel
                      active={orderBy === OrderBy.UPDATED}
                      direction={
                        orderBy === OrderBy.UPDATED ? order : SortingOrder.ASC
                      }
                      onClick={() => handleSortRequest(OrderBy.UPDATED)}>
                      <b>Completion date</b>
                    </TableSortLabel>
                  ) : (
                    <b>{header.label}</b>
                  )}
                </BKStyledEBTableCell>
              ))}
            </TableRow>
          </TableHead>
          {sortedData.length ? (
            <TableBody className="bk-text-capitalize bg-bk_bg_primary">
              {sortedData.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className="cursor-pointer bk-all-cases-table-row "
                  onClick={() => {
                    OrderCandidateStore.report_id = row._id;
                    setOpen(true);
                  }}>
                  <BKStyledEBTableCell className="!px-8 bk-all-cases-table-first-column">
                    {row.name + ''}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {getMenuItemContent(row.overallStatus)}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {formatDate(row.createdAt)}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {row.order_service_status === 'REPORT'
                      ? formatDate(row.updatedAt)
                      : '-'}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {row.order_service_status === 'REPORT'
                      ? calculateTimeTaken(
                          new Date(row.createdAt),
                          new Date(row.updatedAt)
                        ) + ' day(s)'
                      : '-'}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {row.packageName}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell
                    className="!px-8"
                    sx={{ alignItems: 'center' }}>
                    {row.currentProgess.message}
                    {row.currentProgess.message === 'Status not available' ? (
                      <Tooltip
                        title={'For more clarity, please reach out to our team'}
                        placement="top"
                        arrow>
                        <IconButton
                          sx={{
                            height: '18px',

                            color: COLORS.bk_stroke_divider,
                            '&:hover': {
                              color: COLORS.bk_tag_blue,
                            },
                          }}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {row.jobRole}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8">
                    {row.caseId}
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="!px-8 bk-all-cases-table-last-column">
                    <Tooltip
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0',
                        },
                      }}
                      title={'View Candidate'}
                      placement="top"
                      arrow>
                      <IconButton>
                        <OpenInNewIcon
                          sx={{
                            color: COLORS.bk_stroke_divider,
                            '&:hover': {
                              color: COLORS.bk_action_pressed,
                            },
                          }}
                          className="cursor-pointer"
                          onClick={() => {
                            OrderCandidateStore.report_id = row._id;
                            setOpen(true);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </BKStyledEBTableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            ''
          )}
        </Table>
        {sortedData?.length === 0 && (
          <Grid className="flex justify-center m-24">
            <DisplayImage
              imageName={EmployerAppAssetIconsFileNames.FILES_ICON_SVG}
              imageType={DisplayImageType.ICON}
              width="79px"
              height="77px"
            />
            <div>
              <Typography className="bk-subheading-2 ml-6 mt-8">
                No data found with applied filters
              </Typography>
            </div>
          </Grid>
        )}
      </TableContainer>

      {sortedData?.length > 0 && (
        <Grid className="bk-bulk-data-mapping-table bk-all-cases-table-pagination p-sm w-[90%]">
          <Grid>
            <Typography className="bK-btn-small font-normal">
              Total {totalCases} cases
            </Typography>
          </Grid>
          <Grid className="flex items-center">
            <Grid className="flex">
              <Typography className="bK-btn-small self-center">
                Rows per page:
              </Typography>
              <FormControl
                variant="standard"
                className="mr-2 bg-bk_bg_light rounded-lg">
                <Select
                  onChange={handleRowsPerPageChange}
                  className="m-auto ml-3 bK-btn-small"
                  value={rowsPerPage}>
                  {ROWS_PER_PAGE_ARRAY?.map(menu => {
                    return (
                      <MenuItem value={menu?.label} className="bK-btn-small">
                        {menu?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid className="flex relative">
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(event, newPage) =>
                  handleChangePage(event, newPage - 1)
                }
                siblingCount={1}
                boundaryCount={1}
                renderItem={item => (
                  <PaginationItem
                    {...item}
                    sx={{
                      color: item.selected
                        ? COLORS.bk_bg_primary
                        : COLORS.bk_all_cases_pagination,
                      '&.Mui-selected': {
                        backgroundColor: COLORS.bk_all_cases_pagination,
                        color: COLORS.bk_bg_primary,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/*  Rows per page */}
        </Grid>
      )}
      {/* </div> */}
    </>
  );
};
