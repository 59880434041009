import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  IMetaProps,
  IUserInfo,
  IUserRole,
  IUserTeams,
} from '@gigin-work-space/model';
import {
  handleModalOpen,
  isModalOpenStore,
  permissionStore,
  teamStore,
} from '@gigin-work-space/store';
import { canAccess } from '@gigin-work-space/utils';
import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { withViewPermission } from 'src/app/HOC';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import FilterTeam from './filter-team';
import SettingsEmployeeTable from './settings-table';

export const CompanyTeams = () => {
  const [roleOption, setRoleOption] = useState<IUserRole[]>([] as IUserRole[]);
  const [teamOption, setTeamOption] = useState<IUserTeams[]>(
    [] as IUserTeams[]
  );
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen } = useSnapshot(isModalOpenStore);

  // Teams
  const [selectedTeams, setSelectedTeams] = useState<IUserTeams[]>([]);
  const [empData, setEmpData] = useState<IUserInfo[]>([]);
  const [empDataFetching, setEmpDataFetching] = useState<boolean>(false);
  const [meta, setMeta] = useState<IMetaProps>({} as IMetaProps);
  const [page, setPage] = useState(0);
  const { hasRefresh } = useSnapshot(teamStore);
  const {
    canCreateTeams,
    canReadTeams,
    canUpdateTeams,
    canReadUsers,
    canReadRoles,
    canCreateRoles,
    canUpdateRoles,
    canCreateUsers,
    canUpdateUsers,
  } = useSnapshot(permissionStore);
  const handleCheckboxChange = (value: IUserTeams) => {
    if (selectedTeams.some(v => v.name === value.name)) {
      setSelectedTeams(selectedTeams.filter(v => v.name !== value.name));
    } else {
      setSelectedTeams([...selectedTeams, value]);
    }
  };

  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e: any) => {
    setSearchValue(e?.target?.value ?? '');
  };

  const fetchUserTeams = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_TEAM}`);
      if (response.data) {
        setTeamOption(response.data);
      }
    } catch (e) {
      enqueueSnackbar(`Fetch Teams failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const fetchUserRoles = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_USER_ROLE}`);
      if (response.data) {
        setRoleOption(response.data);
      }
    } catch (e) {
      enqueueSnackbar(`Fetch User Roles failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const fetchUserData = async () => {
    try {
      setEmpDataFetching(true);
      const response = await axiosInstance.get(
        `${endpoints.FETCH_ALL_USER}?searchTerm=${
          searchValue ? searchValue : ''
        }&selectedTeams=${
          selectedTeams ? selectedTeams?.map(team => team._id) : []
        }&page=${page + 1}&limit=${10}`
      );
      if (response.data) {
        setEmpData(response.data?.data);
        setMeta(response.data?.meta);
        // setPage(0); // to reseting page
        teamStore.hasRefresh = false;
      }
    } catch (e) {
      enqueueSnackbar(`Employer data fetch failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setEmpDataFetching(false);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    // Perform any action needed on page change
    setPage(newPage);
  };

  // onPagination change upload file function will call
  const handlePageChange = useCallback(() => {
    if (canReadUsers || canCreateUsers || canUpdateUsers) fetchUserData();
  }, [page, selectedTeams]);

  // debounce Logic
  // TODO: Calling api twice
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    function debounce(func: () => void, delay: number) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    }
    if (
      canAccess([canReadUsers, canCreateUsers, canUpdateUsers]) &&
      searchValue &&
      searchValue.length > 2
    ) {
      debounce(fetchUserData, 500); // Adjust the delay time as needed
    }
    if (
      canAccess([canReadUsers, canCreateUsers, canUpdateUsers]) &&
      searchValue.length === 0
    ) {
      fetchUserData();
    }
  }, [searchValue]);

  useEffect(() => {
    if (
      hasRefresh &&
      canAccess([canReadUsers, canCreateUsers, canUpdateUsers])
    ) {
      fetchUserData();
    }
  }, [fetchUserData, hasRefresh]);
  useEffect(() => {
    if (canAccess([canReadRoles, canUpdateRoles, canCreateRoles]))
      fetchUserRoles();
    if (canAccess([canCreateTeams, canReadTeams, canUpdateTeams]))
      fetchUserTeams();
  }, [isOpen]);

  useEffect(() => {
    handlePageChange();
  }, [handlePageChange]);

  const children = (
    <Stack
      direction="column"
      spacing={2}
      sx={{ width: '100%', padding: '1.5rem' }}>
      {/* Top row */}
      <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 1 }}>
        <Stack>
          <Typography className="bk-headline2">User & Teams</Typography>
        </Stack>
        <Stack spacing={1} direction={'row'}>
          {canAccess([canCreateTeams, canUpdateTeams]) && (
            <BKStyledButton
              className="rounded-lg"
              variant="outlined"
              onClick={() => {
                handleModalOpen({
                  ...isOpen,
                  createTeam: true,
                });
              }}>
              + Create team
            </BKStyledButton>
          )}
          {canAccess([canCreateUsers, canUpdateUsers]) && (
            <BKStyledButton
              className="rounded-lg"
              variant="contained"
              onClick={() => {
                handleModalOpen({
                  ...isOpen,
                  inviteEmployee: true,
                });
              }}>
              Invite Members
            </BKStyledButton>
          )}
        </Stack>
      </Stack>
      {/* Filter row */} {/* TODO: filter and search */}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Stack>
          {canAccess([canCreateTeams, canReadTeams, canUpdateTeams]) &&
            canAccess([canReadUsers, canCreateUsers, canUpdateUsers]) && (
              <FilterTeam
                handleSearchValue={handleSearchChange}
                searchValue={searchValue}
                teamOption={teamOption}
                selectedTeams={selectedTeams}
                handleCheckboxChange={handleCheckboxChange}
                hanldeClearCheckbox={() => setSelectedTeams([])}
              />
            )}
        </Stack>
        <Stack direction={'row'}>
          {canAccess([canCreateTeams, canReadTeams, canUpdateTeams]) && (
            <Typography
              className="bK-body2 text-bk_tag_blue_light cursor-pointer"
              onClick={() => {
                handleModalOpen({
                  ...isOpen,
                  teamList: true,
                });
              }}>
              View team list
            </Typography>
          )}
        </Stack>
      </Stack>
      {/* Main Table content */}
      {withViewPermission({
        permissions: [canReadUsers, canCreateUsers, canUpdateUsers],
        children: (
          <SettingsEmployeeTable
            roleOption={roleOption}
            teamOption={teamOption}
            handleChangePage={handleChangePage}
            meta={meta}
            empData={empData}
            page={page}
            empDataFetching={empDataFetching}
          />
        ),
      })}
    </Stack>
  );
  return withViewPermission({
    permissions: [
      canReadTeams,
      canUpdateTeams,
      canCreateTeams,
      canReadUsers,
      canCreateUsers,
      canUpdateUsers,
    ],
    children: children,
  });
};
