import { Box, styled } from '@mui/material';

export const StyledCenterLayoutBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -25%)',
  border: theme.customTheme?.color?.normal_text,
  borderRadius: theme.customTheme?.borderRadius?.custom_16,
  minHeight: '60vh',
  [theme.breakpoints.between('sm', 'md')]: {
    width: '80%',
    padding: '4rem 12%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '70%',
    padding: '4rem 12%',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '50%',
    padding: '4rem 8%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
    padding: '4rem 8%',
  },
}));

/* Media Queries */

// /* 1) size: md */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
//     /* main container width */
//     .container {
//       width: 80%;
//       padding: 4rem 12%;
//     }
//   }

//   /* 2) size: lg */
//   @media screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
//     /* main container width */
//     .container {
//       width: 70%;
//       padding: 4rem 12%;
//     }
//   }

//   /* 3) size: xl */
//   @media screen and (min-device-width: 1200px) and (max-device-width: 1599px) {
//     /* main container width */
//     .container {
//       width: 50%;
//       padding: 4rem 8%;
//     }
//   }

//   /* 4) size: xxl */
//   @media screen and (min-device-width: 1600px) {
//     /* main container width */
//     .container {
//       width: 40%;
//       padding: 4rem 8%;
//     }
//   }

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

// export const Breakpoints = {
//     xs: 0,
//     sm: 768,
//     md: 1366,
//     lg: 1536,
//     xl: 1920,
//   };
