import { CheckoutStore, EmployerCDCStore } from '@gigin-work-space/store';
import {
  AUTO_HIDE_TIMEOUT,
  BULK_UPLOAD_STATUS,
  COLORS,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { CloseOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
interface DocItem {
  id: string;
  title: string;
}
interface CandidateItem {
  id: string;
  name: string;
}
const EBDataMapModel = ({
  isOpen,
  handleCloseModel,
  index,
  tableData,
}: {
  isOpen: boolean;
  handleCloseModel(): void;
  index?: number;
  tableData: any;
}) => {
  const [selectedCandidate, setselectedCandidate] =
    useState<CandidateItem | null>(null);
  const [selectedDoc, setselectedDoc] = useState<DocItem | null>(null);
  const [docList, setDoclist] = useState<DocItem[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [candidateList, setCandidateList] = useState<CandidateItem[]>([]);
  const [imageIndex, setImageIndex] = useState(index || 0);
  const [isModalOpen] = useState(true);

  const { currentPage, totalItems } = EmployerCDCStore.dataMapMeta;

  const handleSelectDoc = (event: DocItem | null) => {
    const selectedDocId = event?.id;
    const selectedDocItem =
      docList.find(doc => doc.id === selectedDocId) || null;
    setselectedDoc(selectedDocItem);
  };

  const PageNumberComponent = () => {
    return (
      <Stack
        sx={{ color: 'white' }}
        className="flex justify-center align-middle p-3 bg-bk_text_primary absolute bottom-3 rounded-2xl opacity-40 font-semibold">
        {(imageIndex + 1) * Number(currentPage) > Number(totalItems)
          ? Number(totalItems)
          : (imageIndex + 1) * Number(currentPage)}
        /{Number(totalItems)}
      </Stack>
    );
  };
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_LIST_OF_CANDIDATE}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
      );
      if (response.data.success) {
        setCandidateList(response.data.data);
      }
    } catch (error) {
      enqueueSnackbar('Error fetching candidate list', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const {
    currentSelectedDocumentCandidate,
    currentSelectedDocumentCandidateId,
    currentSelectedDocumentTypeLabel,
    currentSelectedDocumentTypeId,
  } = useSnapshot(EmployerCDCStore);

  const navigateImage = (direction: number) => {
    const totalItems = EmployerCDCStore.dataMapMeta.totalItems;
    const newIndex = (imageIndex + direction + totalItems) % totalItems;
    setImageIndex(newIndex);
    EmployerCDCStore.dataMapMeta.index = newIndex;
    const nextImageURL = tableData[newIndex]['fileUrl'];
    EmployerCDCStore.selectedDocumentId = tableData[newIndex]['_id'];
    EmployerCDCStore.currentSelectedImage = nextImageURL;
    EmployerCDCStore.currentSelectedImageName =
      tableData[newIndex]['fileName'].slice(-15);
    EmployerCDCStore.currentSelectedDocumentCandidate =
      tableData[newIndex]['candidateId']?.name;
    EmployerCDCStore.currentSelectedDocumentCandidateId =
      tableData[newIndex]['candidateId']?._id;
    EmployerCDCStore.currentSelectedDocumentTypeName =
      tableData[newIndex]['documentId']?.field_name;
    EmployerCDCStore.currentSelectedDocumentTypeLabel =
      tableData[newIndex]['documentId']?.field_label.en;
    EmployerCDCStore.currentSelectedDocumentTypeId =
      tableData[newIndex]['documentId']?._id;
  };

  const handleSubmit = async () => {
    try {
      const selectedDocumentId = EmployerCDCStore.selectedDocumentId;
      const response = await axiosInstance.patch(
        `${endpoints.BULK_UPLOAD_FILE_MAPPING}/${selectedDocumentId}`,
        {
          candidateId: selectedCandidate?.id,
          documentId: selectedDoc?.id,
          orderId: EmployerCDCStore.request_id,
          packageId: EmployerCDCStore.request_role_id,
          document: {
            status: BULK_UPLOAD_STATUS.COMPLETED,
            data_collected: {
              value: EmployerCDCStore.currentSelectedImage,
              type: 'file',
            },
          },
        }
      );
      if (response.data.success) {
        if (response.data.data.isAlreadyMapped) {
          enqueueSnackbar('Updated previously mapped data', {
            variant: 'warning',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar('Candidate data mapped Successfully', {
            variant: VARIANT_TYPE.SUCCESS,
            autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
          });
        }
        if (CheckoutStore.step_3 || imageIndex === totalItems)
          handleCloseModel();
        fetchData();
        EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
      }
    } catch (error) {
      enqueueSnackbar('Error mapping candidate data', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    navigateImage(1);
  };

  useEffect(() => {
    fetchData();
  }, [EmployerCDCStore.toggle]);

  useEffect(() => {
    if (
      !selectedCandidate &&
      currentSelectedDocumentCandidate &&
      currentSelectedDocumentCandidateId
    ) {
      setselectedCandidate({
        name: currentSelectedDocumentCandidate,
        id: currentSelectedDocumentCandidateId,
      });
    }
  }, [currentSelectedDocumentCandidate, currentSelectedDocumentCandidateId]);

  useEffect(() => {
    if (
      !selectedDoc &&
      currentSelectedDocumentTypeId &&
      currentSelectedDocumentTypeLabel
    ) {
      setselectedDoc({
        id: currentSelectedDocumentTypeId,
        title: currentSelectedDocumentTypeLabel,
      });
    }
  }, [currentSelectedDocumentTypeId]);

  const fetchDocumentList = useCallback(async () => {
    const orderId = EmployerCDCStore.request_id;
    const packageId = EmployerCDCStore.request_role_id;
    try {
      const res = await axiosInstance.get(
        `${endpoints.GET_LIST_OF_DOC}/${orderId}/${packageId}`
      );
      if (res.data) {
        setDoclist(res.data?.data?.listOfDocuments);
      }
    } catch (error) {
      enqueueSnackbar('Error fetching document list', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, []);

  useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList, EmployerCDCStore.toggle]);

  if (!isOpen) return null;
  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModel}
      className="flex items-center justify-center "
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Grid>
        <Grid
          item
          xs={12}
          className="h-14 text-center flex items-center px-4 text-bk_bg_primary bg-bk_text_primary justify-between fixed left-0 right-0">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CloseOutlined
              onClick={() => {
                handleCloseModel();
              }}
              style={{
                color: 'white',
                cursor: 'pointer',
              }}
            />
            <PhotoSizeSelectActualIcon
              sx={{ color: '#A30000', fontSize: '28px', mx: 1 }}
            />
            <div style={{ marginLeft: '10px' }}>
              <Typography>
                {EmployerCDCStore.currentSelectedImageName.slice(-15)}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          container
          className="rounded-lg shadow-md h-screen w-screen"
          columnSpacing={1}>
          {/* 80% width for the image */}
          <Grid
            item
            xs={9}
            className="flex justify-center items-center mt-14 relative h-full"
            style={{ backdropFilter: 'brightness(0.3)' }}>
            <Stack className="w-[80%]">
              <FileDisplayComponent
                fileUrl={EmployerCDCStore.currentSelectedImage}
                fileClassName="w-[600px] h-[600px] flex justify-center items-end"
              />
            </Stack>

            <ArrowBackIosIcon
              onClick={() => navigateImage(-1)}
              style={{
                position: 'absolute',
                top: '50vh',
                left: 20,
                color: 'white',
                cursor: 'pointer',
              }}
            />

            <ArrowForwardIosIcon
              onClick={() => navigateImage(1)}
              style={{
                position: 'absolute',
                top: '50vh',
                right: 20,
                color: 'white',
                cursor: 'pointer',
              }}
            />
            <PageNumberComponent />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ background: COLORS.bk_bg_primary }}
            className="mt-14">
            <Stack direction={'column'} rowGap={3} sx={{ mx: 3, pt: 2 }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography className="bk-headline2">Map to:</Typography>
                <Typography></Typography>
              </Stack>
              <Box sx={{ maxWidth: '100%' }}>
                <Autocomplete
                  value={selectedCandidate} // Set the selected value (candidate object)
                  onChange={(event, newValue: CandidateItem | null) => {
                    setselectedCandidate(newValue); // Update the selected value to CandidateItem or null
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="candidate-select"
                  options={candidateList}
                  getOptionLabel={option => option.name} // Display candidate names in the dropdown
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select candidate"
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: 2,
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ maxWidth: '100%' }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ borderRadius: 4 }}>
                  <InputLabel id="doc-select-label">Select Document</InputLabel>
                  <Select
                    labelId="doc-select-label"
                    id="doc-select-label"
                    value={selectedDoc ? selectedDoc.id : ''}
                    label="Select Document"
                    onChange={event => {
                      const selectedDocId = event.target.value;
                      if (selectedDocId) {
                        const selectedDoc = docList.find(
                          doc => doc.id === selectedDocId
                        );
                        handleSelectDoc(selectedDoc || null); // Pass the entire selected object or null if not found
                      } else {
                        handleSelectDoc(null); // Handle the case when no item is selected
                      }
                    }}
                    sx={{
                      borderRadius: 2,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: 2,
                      },
                    }}>
                    {docList?.length &&
                      docList?.map((doc: DocItem, index: number) => (
                        <MenuItem value={doc.id} key={index}>
                          {doc.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ margin: '0 5px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'capitalize', width: '100px' }}
                  onClick={handleCloseModel}>
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'capitalize', width: '100px' }}
                  onClick={handleSubmit}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EBDataMapModel;
