/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BKStyledButton } from '@gigin-work-space/common-ui';
import {
  COLORS,
  DataCollectionItemSource,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  SPACING,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { CheckCircle, Close, Warning } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { DisplayImage } from 'src/app/components';
import { useOrderCreationContext } from 'src/app/context';
import { axiosInstance, endpoints } from 'src/app/utils';

interface DataCollectionModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  clearDataAndFiles?: (
    mode:
      | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
      | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
  ) => Promise<void>;
}

const OptionCard = ({
  imageName,
  title,
  description,
  isSelected,
  onClick,
}: {
  imageName: string;
  title: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Box
    className={`w-full flex flex-col items-center justify-center gap-xl2 py-xl2 px-lg rounded-2xl cursor-pointer relative transition-all duration-300 ease-in-out ${
      isSelected ? 'bg-selected border-selected' : 'bg-unselected'
    }`}
    sx={{
      backgroundColor: isSelected
        ? COLORS.bk_bg_primary_states_selected
        : COLORS.bk_bg_divider,
      border: isSelected ? `1px solid ${COLORS.bk_primary_main}` : 'none',
    }}
    onClick={onClick}>
    {isSelected && (
      <Box className="absolute top-xl2 right-lg">
        <CheckCircle
          sx={{ color: COLORS.bk_green_400, fontSize: SPACING.xl4 }}
        />
      </Box>
    )}
    <Box>
      <DisplayImage
        imageName={imageName}
        imageType={DisplayImageType.ICON}
        className="w-[60px] h-xxl"
      />
    </Box>
    <Box>
      <Typography className="bk-typography-subTitle1 text-center text-bk_text_primary">
        {title}
      </Typography>
      <Typography className="bk-typography-caption text-center text-bk_text_primary max-w-[200px]">
        {description}
      </Typography>
    </Box>
  </Box>
);

export const DataCollectionModal = React.memo(
  ({
    isModalOpen,
    handleCloseModal,
    clearDataAndFiles,
  }: DataCollectionModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { nextStep, activeStep } = useWizard();
    const { state, dispatch } = useOrderCreationContext();
    const { dataCollectionMode, orderInitiationId } = state;

    const [isSubmittingMode, setIsSubmittingMode] = useState<boolean>(false);
    const [isConfirmSelectionEnabled, setIsConfirmSelectionEnabled] =
      useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<
      | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
      | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
      | null
    >(dataCollectionMode);

    const handleClose = useCallback(() => {
      if (!isSubmittingMode) {
        handleCloseModal();
      }
    }, [isSubmittingMode, handleCloseModal]);

    const handleSelectOption = (
      mode:
        | DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
        | DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
    ) => {
      setSelectedOption(mode);
    };

    const updateDataCollectionMode = async (mode: DataCollectionItemSource) => {
      const response = await axiosInstance.patch(
        `${endpoints.UPDATE_CHECKOUT_INITIATION}/${orderInitiationId}`,
        { dataCollectionMethod: mode }
      );
      if (response?.data?.statusCode !== HttpStatus.OK) {
        throw new Error('Failed to update data collection method');
      }
    };

    const handleProceedConfirmation = useCallback(async () => {
      setIsSubmittingMode(true);
      try {
        if (dataCollectionMode && clearDataAndFiles) {
          await clearDataAndFiles(dataCollectionMode);
        }

        if (selectedOption && selectedOption !== dataCollectionMode) {
          await updateDataCollectionMode(selectedOption);
          dispatch({
            type: 'SET_DATA_COLLECTION_MODE',
            payload: selectedOption,
          });

          // Clear data for the new mode as well
          if (clearDataAndFiles) {
            await clearDataAndFiles(selectedOption);
          }
        }

        if (activeStep === 0) {
          nextStep();
        }
        handleCloseModal();
      } catch (error) {
        console.error('Error:', error);
        setSelectedOption(dataCollectionMode);
        enqueueSnackbar('Failed to update data collection method', {
          variant: VARIANT_TYPE.ERROR,
        });
      } finally {
        setIsSubmittingMode(false);
        setIsConfirmSelectionEnabled(false);
      }
    }, [
      selectedOption,
      dataCollectionMode,
      dispatch,
      activeStep,
      nextStep,
      handleCloseModal,
      clearDataAndFiles,
      enqueueSnackbar,
    ]);

    const handleModeConfirmation = useCallback(() => {
      if (dataCollectionMode) {
        setIsConfirmSelectionEnabled(true);
      } else {
        handleProceedConfirmation();
      }
    }, [dataCollectionMode, handleProceedConfirmation]);

    useEffect(() => {
      setSelectedOption(dataCollectionMode);
    }, [dataCollectionMode]);

    return (
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={isModalOpen}>
          <Box className="bk_close_modal p-xl2 rounded-lg flex flex-col gap-y-sm md:min-w-[400px] lg:min-w-[600px]">
            {isConfirmSelectionEnabled ? (
              <Box className="flex flex-row items-center justify-between gap-x-lg">
                <Box className="flex flex-row items-center gap-x-sm2">
                  <Warning
                    sx={{ fontSize: SPACING.xl2, color: COLORS.bk_error_main }}
                  />
                  <Typography className="bk-typography-h6 text-bk_error_main">
                    Data will not be saved if you change the mode
                  </Typography>
                </Box>
                <IconButton aria-label="close" onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </Box>
            ) : (
              <Typography className="bk-typography-h6 w-full text-center text-bku_text_dark">
                Select your preference for sharing data
              </Typography>
            )}
            {isConfirmSelectionEnabled ? (
              <Typography className="bk-typography-body2">
                By changing the mode, your entered data will be lost
              </Typography>
            ) : (
              <Box className="w-full flex flex-row items-center gap-x-xl2 mt-xl3">
                <OptionCard
                  description="A link  will be sent to the candidate on their Email ID"
                  title="Collect data from candidates"
                  imageName={
                    selectedOption ===
                    DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
                      ? EmployerAppAssetIconsFileNames.SEND_MAIL_SELECTED
                      : EmployerAppAssetIconsFileNames.SEND_MAIL_UNSELECTED
                  }
                  isSelected={
                    selectedOption ===
                    DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
                  }
                  onClick={() =>
                    handleSelectOption(
                      DataCollectionItemSource.CANDIDATE_DATA_COLLECTION
                    )
                  }
                />
                <OptionCard
                  description="Manually upload the required candidate details"
                  title="I will upload the data"
                  imageName={
                    selectedOption ===
                    DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
                      ? EmployerAppAssetIconsFileNames.UPLOAD_SELECTED
                      : EmployerAppAssetIconsFileNames.UPLOAD_UNSELECTED
                  }
                  isSelected={
                    selectedOption ===
                    DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
                  }
                  onClick={() =>
                    handleSelectOption(
                      DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
                    )
                  }
                />
              </Box>
            )}
            {isConfirmSelectionEnabled ? (
              <Box className="flex flex-row items-center justify-between gap-x-sm">
                <BKStyledButton
                  className="rounded-[4px] py-md"
                  variant="outlined"
                  fullWidth
                  disabled={isSubmittingMode}
                  onClick={handleCloseModal}>
                  Cancel
                </BKStyledButton>

                <BKStyledButton
                  className="rounded-[4px] py-md"
                  variant="contained"
                  fullWidth
                  disabled={isSubmittingMode || !selectedOption}
                  endIcon={
                    isSubmittingMode ? (
                      <CircularProgress
                        size={20}
                        sx={{ color: COLORS.bk_text_secondary2 }}
                      />
                    ) : null
                  }
                  onClick={handleProceedConfirmation}>
                  Change mode anyway
                </BKStyledButton>
              </Box>
            ) : (
              <Box className="flex flex-row items-center justify-between gap-x-sm mt-xl3">
                {dataCollectionMode && (
                  <BKStyledButton
                    className="rounded-[4px] py-md"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmittingMode}
                    onClick={handleCloseModal}>
                    Cancel
                  </BKStyledButton>
                )}

                <BKStyledButton
                  className="rounded-[4px] py-md"
                  variant="contained"
                  fullWidth
                  disabled={
                    isSubmittingMode ||
                    !selectedOption ||
                    dataCollectionMode === selectedOption
                  }
                  endIcon={
                    isSubmittingMode ? (
                      <CircularProgress
                        size={20}
                        sx={{ color: COLORS.bk_text_secondary2 }}
                      />
                    ) : null
                  }
                  onClick={handleModeConfirmation}>
                  {activeStep === 0 ? 'Confirm and Proceed' : 'Change mode'}
                </BKStyledButton>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    );
  }
);
