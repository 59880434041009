import { Link, Page, Text, View } from '@react-pdf/renderer';
import {
  CheckMenuList,
  ReportFooter,
  ReportHeader,
  UserDetailContainer,
  Watermark,
} from '../components';
import { styles } from '../styles';

export interface MenuPageProps {
  reportDataObject: any;
}

export const MenuPage = (props: MenuPageProps) => {
  const { reportDataObject } = props;

  return (
    <Page size="A4" wrap style={[styles.page, { paddingBottom: 100 }]}>
      {/* header section */}
      <ReportHeader reportDataObject={reportDataObject} />
      {/* Main Section */}
      <View style={styles.mainSectionContainer}>
        {/* User Detail Section */}
        <UserDetailContainer reportDataObject={reportDataObject} />
        {/* check Status section menu */}
        <Text style={(styles.textHeadLine2, { marginTop: 4 })}>
          Check Status
        </Text>
        {/* Menu List Container */}
        <View style={[styles.stackColumnContainer]}>
          {/* TODO: Replace with API data later */}
          {reportDataObject.reports.map((data: any) => (
            <Link
              src={`#${data.first_subcheck_id}`}
              style={{
                textDecoration: 'none',
                fontWeight: 'normal',
                color: '#000',
              }}>
              <CheckMenuList key={data.check_id} menu={data} />
            </Link>
          ))}
        </View>
      </View>
      {/* Watermark */}
      <Watermark checkStatus={reportDataObject.overall_status.status} />
      {/* footer section */}
      <ReportFooter
        renderCheckLegend={true}
        reportDataObject={reportDataObject}
      />
    </Page>
  );
};
