import { IOrderCompletionStatus } from '@gigin-work-space/model';
import { COLORS } from '@gigin-work-space/utils';
import { Box, Tooltip } from '@mui/material';

interface IStatusbar {
  orderCompleteStatus: IOrderCompletionStatus;
}
export const StatusProgress = ({ orderCompleteStatus }: IStatusbar) => {
  const {
    fail_count,
    fail_percentage,
    success_count,
    success_percentage,
    total_count,
    warn_count,
    warn_percentage,
  } = orderCompleteStatus;
  return (
    <Box className="bk-status-progress-bar">
      <Tooltip
        title={`Green case ${success_count}/${total_count}  ${success_percentage}%`}
        placement="top"
        arrow>
        <span
          className="flex"
          style={{
            width: `${success_percentage}%`,
            background: COLORS.bk_alert_success,
          }}></span>
      </Tooltip>
      <Tooltip
        title={`Red case ${fail_count}/${total_count}  ${fail_percentage}%`}
        placement="top"
        arrow>
        <span
          className="flex"
          style={{
            width: `${fail_percentage}%`,
            background: COLORS.bk_alert_failure,
          }}></span>
      </Tooltip>
      <Tooltip title={`${warn_count}/${total_count}%`} placement="top" arrow>
        <span
          className="flex"
          style={{
            width: `${warn_percentage}%`,
            background: 'red',
          }}></span>
      </Tooltip>
    </Box>
  );
};
