import { BKStyledButton, BKStyledTableCell } from '@gigin-work-space/common-ui';
import {
  IMetaProps,
  IUserInfo,
  IUserRole,
  IUserTeams,
} from '@gigin-work-space/model';
import { permissionStore, teamStore } from '@gigin-work-space/store';
import { COLORS, CORE_USER_ROLE, userRoleMap } from '@gigin-work-space/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import CircularIndeterminate from '../../../component/skelton/bgv-loader';

interface ISettingsEmployeeTable {
  roleOption: IUserRole[];
  teamOption: IUserTeams[];
  // eslint-disable-next-line no-unused-vars
  handleChangePage(event: unknown, newPage: number): void;
  meta: IMetaProps;
  empData: IUserInfo[];
  page: number;
  empDataFetching: boolean;
}

const SettingsEmployeeTable = ({
  roleOption,
  teamOption,
  meta,
  empData,
  handleChangePage,
  page,
  empDataFetching,
}: ISettingsEmployeeTable) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IUserInfo>({} as IUserInfo);
  const [editedName, setEditedName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  // const [selectedTeam, setSelectedTeam] = useState<IUserTeams[]>(
  //   [] as IUserTeams[]
  // );
  const [selectedTeam, setSelectedTeam] = useState<IUserTeams[]>();
  const [role, setRole] = useState('');
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { canUpdateTeams } = useSnapshot(permissionStore);
  const roleReturn = (id: string) => {
    const roleKey = roleOption.find(obj => obj._id === id)?.name ?? '';
    return roleKey;
  };

  const roleReturnName = (id: string) => {
    const roleName = roleOption.find(obj => obj._id === id)?.name ?? '';
    return userRoleMap(roleName);
  };

  const handleTeamSelection = (event: any, value: IUserTeams[]) => {
    setSelectedTeam(value);
  };

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const handleEditClick = (row: IUserInfo) => {
    setSelectedRow(row);
    setEditedName(row.name);
    setRole(roleReturn(row.role));
    setSelectedTeam(getNamesFromTeamId(row.teams));

    setEditMode(true);
  };

  const handleSaveClick = async () => {
    const createData = {
      name: editedName,
      email: selectedRow.email ?? '',
      team: selectedTeam?.map(team => team._id) ?? [],
      role: roleOption.find(x => x.name === role)?._id,
    };
    try {
      const response = await axiosInstance.patch(
        `${endpoints.UPDATE_USER}/${selectedRow._id}`,
        createData
      );
      if (response.data) {
        teamStore.hasRefresh = true;
        enqueueSnackbar(`Employer data updated Successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      teamStore.hasRefresh = false;
      enqueueSnackbar(`Employer data update failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setEditMode(false);
      teamStore.hasRefresh = true;
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  function getNamesFromIds(arr: string[]): string {
    const names: string[] = [];

    arr.forEach(id => {
      const match = teamOption.find(obj => obj._id === id);
      if (match) {
        names.push(match.name);
      }
    });

    return names.join(', ');
  }

  function getNamesFromTeamId(arr: string[]): IUserTeams[] {
    const selectedTeamObjects = arr.map(teamId =>
      teamOption.find(team => team._id === teamId)
    );
    return selectedTeamObjects.filter(Boolean) as IUserTeams[];
  }

  if (empDataFetching) return <CircularIndeterminate />;
  return (
    <Box>
      <TableContainer
        sx={{
          border: `1px solid ${COLORS.bk_stroke_primary}`,
          overflowX: 'hidden',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        }}>
        <Table
          padding="none"
          size="small"
          sx={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            overflow: 'hidden',
            '& .MuiTableCell-root': {
              paddingX: '8px !important',
            },
          }}>
          <TableHead
            sx={{
              position: 'relative',
              zIndex: -1,
              backgroundColor: COLORS.bk_bg_primary_smoke,
              '& .MuiTableCell-head': {
                padding: '8px !important',
              },
            }}>
            <TableRow>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">
                  Employee name
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Email</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Role</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                {' '}
                <Typography className="bk-sub-heading3">Team</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell></BKStyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {empData?.length ? (
              empData?.map(row => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                  // onClick={() => handleRowClick(row)}
                >
                  <BKStyledTableCell size="small">
                    {row.isInviteSent && !row.isRegistered ? (
                      <Typography
                        variant="body2"
                        className="bK-body2"
                        color="textSecondary">
                        - (Invitation Pending)
                      </Typography>
                    ) : (
                      <Typography sx={{ pl: 1 }} className="bK-body2">
                        {row.name}
                      </Typography>
                    )}
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography sx={{ pl: 1 }} className="bK-body2">
                      {row.email}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography sx={{ pl: 1 }} className="bK-body2">
                      {roleReturnName(row.role)}
                      {/* CORE_USER_ROLE */}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography sx={{ pl: 1 }} className="bK-body2">
                      {getNamesFromIds(row.teams)}
                    </Typography>
                  </BKStyledTableCell>
                  <TableCell size="small">
                    {row.isInviteSent && !row.isRegistered ? (
                      <Tooltip
                        title="Please accept invite after that you can edit"
                        placement="top">
                        <IconButton
                          aria-label="edit"
                          sx={{ p: 0 }}
                          disabled={true}>
                          <DisplayImage
                            imageName="edit_row_icon.svg"
                            imageType="icon"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      canUpdateTeams && (
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            aria-label="edit"
                            sx={{
                              p: 0,
                            }}
                            onClick={() => handleEditClick(row)}>
                            <DisplayImage
                              imageName="hover_edit_row_icon.svg"
                              imageType="icon"
                            />
                          </IconButton>
                        </Tooltip>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className="m-4">
                <TableCell colSpan={4}>
                  <Typography variant="subtitle2" align="center">
                    No data found!
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={meta?.totalItems ?? 0}
        page={page ?? 0}
        rowsPerPage={10}
        rowsPerPageOptions={[]}
        onPageChange={handleChangePage}
      />

      <Dialog
        open={editMode}
        onClose={(event, reason) => {
          if (
            (reason && reason === 'backdropClick') ||
            reason === 'escapeKeyDown'
          )
            return;
          handleCancelClick();
        }}
        sx={{
          padding: '28px 53px',
          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        }}>
        <DialogTitle>Edit Employee</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ py: 2 }}>
            <TextField
              label="Name"
              fullWidth
              value={editedName}
              onChange={e => setEditedName(e.target.value)}
            />
            <TextField
              label="Email ID"
              fullWidth
              value={selectedRow.email ?? ''}
              disabled
              variant="filled"
              InputProps={{ readOnly: true, disableUnderline: true }}
            />
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={teamOption}
              getOptionLabel={option => option.name}
              value={selectedTeam ?? []}
              className="custom-scrollbar"
              //defaultValue={getNamesFromTeamId(selectedRow.teams)}
              onChange={handleTeamSelection}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Team"
                  placeholder="Select Team"
                />
              )}
            />
            <FormControl variant="standard" fullWidth>
              <InputLabel>Select role</InputLabel>
              <Select
                id="role"
                name="role"
                value={role}
                onChange={handleRoleChange}
                label="role"
                disabled={userRoleMap(role) === 'Administrator'}
                // sx={borderLessSelect}
                className="bK-body2 text-bk_text_secondary">
                {roleOption
                  ?.filter(data => data.name !== CORE_USER_ROLE.SUPER_ADMIN)
                  ?.map(role => {
                    return (
                      <MenuItem
                        value={role.name}
                        className="bK-body2 text-bk_text_secondary">
                        {userRoleMap(role?.name)}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: '16px !important',
              gap: '20px',
            }}>
            <BKStyledButton
              variant="text"
              className="rounded-lg bk-btn-large text-bk_text_secondary"
              sx={{ padding: '12px 20px' }}
              onClick={() => {
                handleCancelClick();
              }}>
              Cancel
            </BKStyledButton>
            {canUpdateTeams && (
              <BKStyledButton
                variant="contained"
                className="rounded-lg bk-btn-large"
                sx={{ padding: '12px 20px' }}
                onClick={() => {
                  handleSaveClick();
                }}>
                Save
              </BKStyledButton>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      {/* {!empData && (
        <Typography sx={{ textAlign: 'center' }} className="bk-sub-heading3">
          No records found
        </Typography>
      )} */}
    </Box>
  );
};

export default SettingsEmployeeTable;
