/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  BKStyledButton,
  EmptyState,
  UnAuthorizedEmptyState,
} from '@gigin-work-space/common-ui';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { SetPassword } from 'src/app/features/authentication/reset-password/set-password';
import { MfaOtpVerification } from 'src/app/features/authentication/signin/mfa-otp-verification';
import { EmailLinkVerifiedInfo } from 'src/app/features/authentication/signup-via-link/email-link-verified-info';
import SelfSignUp from 'src/app/features/authentication/signup-via-link/self-signup';
import { WithPermissionBgvConsolidatedReport } from 'src/app/features/bgv/bgv-report/bgv-consolidated-report';
import {
  CompanyPackages,
  CompanyTeams,
  RolesPermission,
} from 'src/app/features/bgv/company-settings';
import { CompanyPreferences } from 'src/app/features/bgv/company-settings/components/company-preferences/company-preferences';
import { CompanyInfo } from 'src/app/features/bgv/company-settings/components/company-profile/company-info-details';
import userDetails from 'src/app/features/bgv/company-settings/components/user-component/user-details';
import OnBoardedRequestApproved from 'src/app/features/bgv/component/bgv-admin-approved';
import { OnBoardedNewUser } from 'src/app/features/bgv/component/bgv-new-user-on-boarding-request';
import { NewUserRequestInfo } from 'src/app/features/bgv/component/bgv-welcome-page';
import CircularIndeterminate from 'src/app/features/bgv/component/skelton/bgv-loader';
import { OrderCreation } from 'src/app/features/bgv/create-new-bgv-request/order-creation';
import ChecksDashboard from 'src/app/features/ops-platform/ChecksDashboard';
import Layout from 'src/app/features/ops-platform/Layout';
import SingleCheckDetails from 'src/app/features/ops-platform/components/SingleCheckDetails';
import { ClientList } from 'src/app/features/ops-platform/ops-client-list';
import { OpsPlatformPackageCreation } from 'src/app/features/ops-platform/ops-plateform-package-creation';
import { DisplayImage } from '../../components';
import { withTenantSetup } from '../../components/withTenantSetup'; // Import the HOC
import {
  Authentication,
  ForgotPassword,
  Signin,
} from '../../features/authentication';
import { BgvNav } from '../../features/bgv';
import { WithPermissionBgvReport } from '../../features/bgv/bgv-report/bgv-report';
import CompanySettings from '../../features/bgv/company-settings/company-settings';
import {
  AnalyticsPage,
  CDCUploadTab,
  PrivacyPolicy,
  TermsConditions,
} from '../../features/bgv/component';
import {
  IndividualCaseDetail,
  IndividualCaseDetailHOC,
  OpsPlatformCrcCheckList,
  OpsPlatformCrcUploadList,
  OpsPlatformDashboard,
} from '../../features/ops-platform';
import ProtectedRoute from './protected-route';

// Route configuration for entire application

const EnhancedSelfEmailLinkVerifiedInfo = withTenantSetup(
  EmailLinkVerifiedInfo
);

const EnhancedSelfSigUp = withTenantSetup(SelfSignUp);
const EnhancedSetPassword = withTenantSetup(SetPassword);
const EnhancedCompanyInfo = withTenantSetup(CompanyInfo);
const EnhancedUserDetails = withTenantSetup(userDetails);
const EnhancedNewUserRequestInfo = withTenantSetup(NewUserRequestInfo);
const EnhancedOnBoardedNewUser = withTenantSetup(OnBoardedNewUser);
const EnhancedOnBoardedRequestApproved = withTenantSetup(
  OnBoardedRequestApproved
);
const EnhancedSignin = withTenantSetup(Signin);
const EnhancedMfaOtpVerification = withTenantSetup(MfaOtpVerification);
const EnhancedForgotPassword = withTenantSetup(ForgotPassword);
const EnhancedBgvNav = withTenantSetup(BgvNav);
const EnhancedCDCUploadTab = withTenantSetup(CDCUploadTab);
const EnhancedBgvReport = withTenantSetup(WithPermissionBgvReport);
const EnhancedBgvConsolidatedReport = withTenantSetup(
  WithPermissionBgvConsolidatedReport
);
const EnhancedCompanyPackages = withTenantSetup(CompanyPackages);
const EnhancedCompanyPreferences = withTenantSetup(CompanyPreferences);
const EnhancedRolesPermission = withTenantSetup(RolesPermission);
const EnhancedCompanyTeams = withTenantSetup(CompanyTeams);
const EnhancedCompanySettings = withTenantSetup(CompanySettings);
const EnhancedAnalyticsPage = withTenantSetup(AnalyticsPage);
const EnhancedOpsPlatformDashboard = withTenantSetup(OpsPlatformDashboard);
const EnhancedOpsPlatformClientList = withTenantSetup(ClientList);
const EnhancedOpsPlatformCheckDashboard = withTenantSetup(ChecksDashboard);
const EnhancedOpsPlatformCheckName = withTenantSetup(SingleCheckDetails);
const EnhancedOpsPlatformCrcCheckList = withTenantSetup(
  OpsPlatformCrcCheckList
);
const EnhancedOpsPlatformPackageCreation = withTenantSetup(
  OpsPlatformPackageCreation
);
const EnhancedOpsPlatformCrcUploadList = withTenantSetup(
  OpsPlatformCrcUploadList
);
const EnhancedWithPermissionBgvReport = withTenantSetup(
  WithPermissionBgvReport
);
const EnhancedWithPermissionBgvReportConsolidated = withTenantSetup(
  WithPermissionBgvConsolidatedReport
);
const EnhancedIndividualCaseDetail = withTenantSetup(IndividualCaseDetail);
const EnhancedCreateNewBgvOrderRequest = withTenantSetup(OrderCreation);

const RouterWidget = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Simulate async token retrieval (replace with actual async logic)
    const fetchUserToken = async () => {
      try {
        const token = await localStorage.getItem('auth_token');
        setUserToken(token);
      } catch (error) {
        setUserToken(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserToken();

    // Cleanup effect to avoid memory leaks
    return () => {
      setIsLoading(false);
    };
  }, []);
  if (isLoading) {
    // Render loading indicator while fetching token
    return (
      <Typography className="w-full text-center">
        <CircularIndeterminate />
      </Typography>
    );
  }
  const buttonText = userToken ? 'Go to Dashboard' : 'Go to Login';
  const buttonAction = () => navigate('/login');

  return (
    <Routes>
      {/* AUTH Routes START */}
      <Route
        path="/password-reset/reset"
        element={<EnhancedSetPassword tenantName={''} />}
      />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route
        path="/self-signup"
        element={<EnhancedSelfSigUp tenantName={''} />}
      />
      <Route
        path="/:tenantName/self-signup"
        element={<EnhancedSelfSigUp tenantName={''} />}
      />
      <Route
        path="/:tenantName/mfa/otp-verification"
        element={<EnhancedMfaOtpVerification tenantName={''} />}
      />
      <Route
        path="/:tenantName/self-signup/email-verification"
        element={<EnhancedSelfEmailLinkVerifiedInfo tenantName={''} />}
      />
      <Route
        path="/self-signup/email-verification"
        element={<EnhancedSelfEmailLinkVerifiedInfo tenantName={''} />}
      />
      <Route path="/signup" element={<Authentication />} />
      <Route path="/login" element={<Signin />} />
      <Route
        path="/:tenantName/login"
        element={<EnhancedSignin tenantName={''} />}
      />
      <Route
        path="/:tenantName/update/company-info"
        element={<EnhancedCompanyInfo tenantName={''} />}
      />
      <Route
        path="/:tenantName/update/user-info"
        element={<EnhancedUserDetails tenantName={''} />}
      />
      <Route
        path="/:tenantName/user/on-board/request-info"
        element={<EnhancedNewUserRequestInfo tenantName={''} />}
      />
      <Route
        path="/:tenantName/user/on-board"
        element={<EnhancedOnBoardedNewUser tenantName={''} />}
      />
      <Route
        path="/:tenantName/user/on-board/approval"
        element={<EnhancedOnBoardedRequestApproved tenantName={''} />}
      />
      <Route path="/reset-password/forgot" element={<ForgotPassword />} />
      <Route
        path="/:tenantName/reset-password/forgot"
        element={<EnhancedForgotPassword tenantName={''} />}
      />
      <Route
        path="/:tenantName/bgv"
        element={
          <ProtectedRoute>
            <EnhancedBgvNav tenantName={''} />
          </ProtectedRoute>
        }>
        <Route
          path="/:tenantName/bgv/analytics"
          element={
            <ProtectedRoute>
              <EnhancedAnalyticsPage tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/bgv/company-settings"
          element={
            <ProtectedRoute>
              <EnhancedCompanySettings tenantName={''} />
            </ProtectedRoute>
          }>
          <Route
            path="/:tenantName/bgv/company-settings/user_teams"
            element={
              <ProtectedRoute>
                <EnhancedCompanyTeams tenantName={''} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:tenantName/bgv/company-settings/role_management"
            element={
              <ProtectedRoute>
                <EnhancedRolesPermission tenantName={''} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:tenantName/bgv/company-settings/packages"
            element={
              <ProtectedRoute>
                <EnhancedCompanyPackages tenantName={''} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:tenantName/bgv/company-settings/preferences"
            element={
              <ProtectedRoute>
                <EnhancedCompanyPreferences tenantName={''} />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/:tenantName/bgv/bulk-upload/:orderId/candidate-data-management"
          element={
            <ProtectedRoute>
              <EnhancedCDCUploadTab tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/bgv/bulk-upload/:orderId/candidate-data-management/upload-document"
          element={
            <ProtectedRoute>
              <EnhancedCDCUploadTab tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/bgv/bulk-upload/:orderId/candidate-data-management/map-document"
          element={
            <ProtectedRoute>
              <EnhancedCDCUploadTab tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/bgv/bulk-upload/:orderId/candidate-data-management/upload-data"
          element={
            <ProtectedRoute>
              <EnhancedCDCUploadTab tenantName={''} />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/:tenantName/bgv/report/:caseId"
        element={
          <ProtectedRoute>
            <EnhancedBgvReport tenantName={''} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:tenantName/bgv/report-consolidated/:caseId"
        element={
          <ProtectedRoute>
            <EnhancedBgvConsolidatedReport tenantName={''} />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route
          path="/:tenantName/ops-platform"
          element={
            <ProtectedRoute>
              <EnhancedOpsPlatformDashboard tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/report/:caseId"
          element={
            <ProtectedRoute>
              <EnhancedWithPermissionBgvReport tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/report-consolidated/:caseId"
          element={
            <ProtectedRoute>
              <EnhancedWithPermissionBgvReportConsolidated tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-individual-case/:caseId"
          element={
            <ProtectedRoute>
              <IndividualCaseDetailHOC>
                <EnhancedIndividualCaseDetail tenantName={''} />
              </IndividualCaseDetailHOC>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/crc-check-list"
          element={
            <ProtectedRoute>
              <EnhancedOpsPlatformCrcCheckList tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/client-list"
          element={
            <ProtectedRoute>
              <EnhancedOpsPlatformClientList tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/checks-dashboard/"
          element={
            <ProtectedRoute>
              <EnhancedOpsPlatformCheckDashboard tenantName={''} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:tenantName/ops-platform/checks-dashboard/:check"
          element={
            <ProtectedRoute>
              <EnhancedOpsPlatformCheckName tenantName={''} />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/:tenantName/ops-platform/create-package"
        element={
          <ProtectedRoute>
            <EnhancedOpsPlatformPackageCreation tenantName={''} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:tenantName/bgv/create-order-request"
        element={
          <ProtectedRoute>
            <EnhancedCreateNewBgvOrderRequest tenantName={''} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:tenantName/ops-platform/crc-upload-list"
        element={
          <ProtectedRoute>
            <EnhancedOpsPlatformCrcUploadList tenantName={''} />
          </ProtectedRoute>
        }
      />
      {/* Terms and conditions */}
      <Route path="/terms_condition" element={<TermsConditions />} />
      {/* Privacy and policy */}
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      {/* Unauthorized component */}
      <Route path="/unauthorized" element={<UnAuthorizedEmptyState />} />
      {/* Wildcard route */}
      <Route
        path="/:tenantName/terms_condition"
        element={<TermsConditions />}
      />
      <Route path="/:tenantName/privacy_policy" element={<PrivacyPolicy />} />
      <Route
        path="/:tenantName/unauthorized"
        element={<UnAuthorizedEmptyState />}
      />

      <Route
        path="*"
        element={
          <EmptyState
            maxHeight={'100vh'}
            imageComponent={
              <DisplayImage
                imageName="404.svg"
                imageType="icon"
                width="370px" //todo: px to rem
                height="370px"
              />
            }
            title={
              userToken
                ? "Sorry! We couldn't find the page"
                : "Sorry! We couldn't find the page"
            }
            description={
              userToken
                ? 'Please continue to dashboard by clicking below'
                : 'Please Login to access this page'
            }
            buttonComponent={
              <BKStyledButton
                variant="contained"
                className="bg-bk_action_primary mt-2 rounded-lg"
                onClick={buttonAction}>
                {buttonText}
              </BKStyledButton>
            }
          />
        }
      />
    </Routes>
  );
};

export default RouterWidget;
