import { BKStyledButton } from '@gigin-work-space/common-ui';
import { Box, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationPaper } from 'src/app/components';
import { AuthenticationHOCForSignup } from 'src/app/features/authentication/authentication-hoc/authentication-hoc-without-background';
import { CompanyInfoSchema, axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { tenantState } from 'src/app/utils/router/tenantState';
import CircularIndeterminate from '../../../component/skelton/bgv-loader';

interface AccountDetails {
  account_name: string;
  account_type: string;
  account_role: string;
  email: string;
  address: string;
  gstin_no: string;
  isRegistered: boolean;
}

interface HandleFormSubmitData {
  accountName: any;
  accountType: any;
  email: any;
  gstInNo: any;
  address: any;
}
export function CompanyInfo() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(
    null
  );

  const fetchAccountDetails = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(
        `${endpoints.PATCH_ACCOUNT_DETAILS}/my_account`
      );
      setAccountDetails(response.data.data);
    } catch (error) {
      enqueueSnackbar('Failed to fetch company details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('Failed to fetch account details:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };
  if (accountDetails && accountDetails.isRegistered) {
    // Redirect to user-info page
    globalNavigateTo('/update/user-info', navigate);
  }

  const handleFormSubmit = async (
    values: HandleFormSubmitData,
    { resetForm }: any
  ) => {
    try {
      const body = {
        account_name: values.accountName,
        account_type: values.accountType,
        email: values.email,
        gstin_no: values.gstInNo,
        address: values.address,
        isRegistered: true,
      };
      const response = await axiosInstance.patch(
        `${endpoints.PATCH_ACCOUNT_DETAILS}/update_my_account`,
        body
      );

      if (response.data.success) {
        enqueueSnackbar('Company details has been updated successfully', {
          variant: 'success',
          autoHideDuration: 2000,
        });

        globalNavigateTo('/update/user-info', navigate);
        return;
      }
      enqueueSnackbar('Failed to update company details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } catch (error) {
      enqueueSnackbar('Sorry, we were unable to update your details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      accountName: accountDetails?.account_name,
      accountType: accountDetails?.account_type || '',
      accountRole: accountDetails?.account_role || '',
      email: accountDetails?.email || '',
      address: accountDetails?.address || '',
      gstInNo: accountDetails?.gstin_no || '',
    },
    validationSchema: CompanyInfoSchema,
    onSubmit: handleFormSubmit, // Use the abstracted function here
    enableReinitialize: true,
  });

  useEffect(() => {
    // Check if auth_token is present in local storage
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      fetchAccountDetails();
      // If auth_token is present, try to get userAccountDetail
      const userAccountDetailStr = localStorage.getItem('userAccountDetail');
      if (userAccountDetailStr) {
        // If userAccountDetailStr is not null, parse it and proceed
        const userDetail = JSON.parse(userAccountDetailStr);
        tenantState.tenantName = userDetail.tenantName;
      }
    } else {
      globalNavigateTo('/bgv', navigate);
    }
  }, [navigate]);

  const isBtnDisabled = useMemo(() => {
    const isAllFieldsFilled = Object.values(formik.values).every(
      value => !!value
    );
    return !isAllFieldsFilled;
  }, [formik.values]);

  return (
    <AuthenticationHOCForSignup>
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary">
            Company details
          </Typography>
          <Typography className="bK-body1 text-bk_text_secondary mt-1">
            Please enter the company details here
          </Typography>
          <Box
            className="mt-6"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}>
            <TextField
              id="accountName"
              name="accountName"
              label="Enter company name"
              className="bk-company-info-inputBase"
              variant="outlined"
              type={'text'}
              fullWidth
              required
              value={formik.values.accountName}
              onChange={formik.handleChange}
              error={
                formik.touched.accountName && Boolean(formik.errors.accountName)
              }
              helperText={
                formik.touched.accountName && formik.errors.accountName
              }
              inputProps={{ 'data-testid': 'accountName' }}
            />
            <TextField
              id="address"
              name="address"
              label="Enter address"
              className="bk-company-info-inputBase"
              variant="outlined"
              type={'text'}
              fullWidth
              required
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              inputProps={{ 'data-testid': 'address' }}
            />

            <TextField
              id="gstInNo"
              name="gstInNo"
              label="Enter GST no"
              className="bk-company-info-inputBase"
              variant="outlined"
              type={'text'}
              fullWidth
              required
              value={formik.values.gstInNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.gstInNo && Boolean(formik.errors.gstInNo)}
              helperText={formik.touched.gstInNo && formik.errors.gstInNo}
              inputProps={{ 'data-testid': 'gstInNo' }}
            />

            <BKStyledButton
              data-testid="submitBtn"
              className="bg-bk_action_primary mt-2 rounded-lg"
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              disabled={isBtnDisabled}>
              Update
            </BKStyledButton>
          </Box>
        </AuthenticationPaper>
      )}
    </AuthenticationHOCForSignup>
  );
}

export default React.memo(CompanyInfo);
