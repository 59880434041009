import { COLORS, SPACING } from '@gigin-work-space/utils';
import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { ImageComponent } from '../components/report-image';
import { styles } from '../styles';
import { getDocumentsByCheckId } from '../utility/get-documents-by-checkid';
import {
  DocumentWithIndex,
  SubcheckMapValue,
} from '../utility/restructure-data';

const ReportAnnexurePage = ({
  documentList,
  subcheckMap,
}: {
  documentList: DocumentWithIndex[];
  subcheckMap: Map<string, SubcheckMapValue>;
}) => {
  // Get unique checkIds
  const checkIds = Array.from(new Set(documentList.map(doc => doc.checkId)));

  return checkIds.map((checkId, index) => {
    const documents = getDocumentsByCheckId(documentList, checkId);
    const subcheck = subcheckMap.get(checkId);

    return (
      <Page size="A4" style={styles.pageConsolidated} wrap>
        {/* <View style={styles.sectionBreakSolid} /> */}
        <View
          style={{
            flexDirection: 'row',
          }}>
          <View style={{ width: 16 }}>
            <Text
              style={[
                styles.typov3Body3,
                {
                  color: COLORS.bk_report_text_secondary,
                  fontWeight: 700,
                  textAlign: 'left',
                },
              ]}>
              {index + 1}
            </Text>
          </View>
          <View style={{ width: SPACING.md }} />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', marginBottom: SPACING.xl2 }}>
              <Text style={[styles.typov3Body3, { fontWeight: 700 }]}>
                {documents.map(doc => doc.index).join(' & ')}{' '}
                {subcheck?.reportTitle}
                {': '}
              </Text>
              <Text style={[styles.typov3Body3]}>{subcheck?.subcheckName}</Text>
            </View>
            <View>
              {documents.map(doc => (
                <View key={doc.index} style={{ marginHorizontal: 'auto' }}>
                  <ImageComponent
                    src={doc?.document?.file_url}
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: 500,
                      maxHeight: 500,
                      objectFit: 'contain',
                    }}
                  />
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    );
  });
};

export default React.memo(ReportAnnexurePage);
