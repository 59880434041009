import { permissionStore } from '@gigin-work-space/store';
import { COLORS } from '@gigin-work-space/utils';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { withViewPermission } from 'src/app/HOC';
import { useSnapshot } from 'valtio';
import { QualityHire } from './quality-hire-component';
import { RoleWiseChart } from './role-wise-component';
import { TimeCompliance } from './time-compaliance';
import VerificationStatusDistribution from './verification-status-distribution';
import { VerificationTrend } from './verification-trend';

/* eslint-disable-next-line */
export interface AnalyticsPageProps {}

export function AnalyticsPage() {
  const { canReadAnalytics } = useSnapshot(permissionStore);
  const children = (
    <Box className="flex flex-col gap-xl3 h-[90vh] overflow-y-auto">
      <Paper
        elevation={0}
        sx={{
          borderY: `1px solid ${COLORS.bk_stroke_primary}`,
          // borderRadius: '8px',
          background: COLORS.bk_bg_primary,
        }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            p: 2,
            pb: 1.5,
            borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
          }}>
          <Typography className="bk-heading1">Analytics</Typography>
          {/* <MoreVertIcon
            className="cursor-pointer"
            sx={{
              color: COLORS.bk_stroke_divider,
            }}
          /> */}
        </Stack>
        <Grid
          container
          direction={'column'}
          sx={{ px: 3, py: 2 }}
          rowSpacing={2}>
          <Grid item direction={'row'} container xs={12} columnGap={2}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${COLORS.bk_stroke_divider}`,
                overflow: 'hidden',
              }}>
              <QualityHire />
            </Grid>
            <Grid
              item
              md
              xs={12}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${COLORS.bk_stroke_divider}`,
                overflow: 'hidden',
              }}>
              {/* time compliance */}
              <TimeCompliance />
            </Grid>
          </Grid>
          {/* row 2 */}
          <Grid item direction={'row'} container xs={12} columnGap={2}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${COLORS.bk_stroke_divider}`,
                overflow: 'hidden',
              }}>
              <VerificationTrend />
            </Grid>
            <Grid
              item
              md
              xs={12}
              sx={{
                borderRadius: '8px',
                border: `1px solid ${COLORS.bk_stroke_divider}`,
                overflow: 'hidden',
              }}>
              <RoleWiseChart />
            </Grid>
          </Grid>
          {/* row 3 */}
          <Grid item direction={'row'} container xs={12} columnGap={2}>
            <VerificationStatusDistribution />{' '}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
  return withViewPermission({
    permissions: [canReadAnalytics],
    children: children,
  });
}

export default AnalyticsPage;
