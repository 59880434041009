import {
  CheckInProgressSvg,
  DataCollectSVG,
  InviteSentSVG,
  QualityCheckSVG,
  ReportGeneratedSVG,
} from '@gigin-work-space/common-ui';
import { ICheckInProgress } from '@gigin-work-space/model';
import { Box, Stack } from '@mui/material';

// Progress Component
export const ProgressOrderTableComponent = ({
  invite,
  checkInProgress,
  dataCollection,
  quality,
  reportGenerated,
}: ICheckInProgress) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Box>
        <InviteSentSVG status={invite.status} message={invite.message} />
      </Box>
      <Box>
        <DataCollectSVG
          status={dataCollection.status}
          message={dataCollection.message}
        />
      </Box>
      <Box>
        <CheckInProgressSvg
          status={checkInProgress.status}
          message={checkInProgress.message}
        />
      </Box>
      <Box>
        <QualityCheckSVG status={quality.status} message={quality.message} />
      </Box>
      <Box>
        <ReportGeneratedSVG
          status={reportGenerated.status}
          message={reportGenerated.message}
        />
      </Box>
    </Stack>
  );
};
