import { BKStyledButton, BKStyledTabs } from '@gigin-work-space/common-ui';
import {
  CheckoutStore,
  OrderCandidateStore,
  permissionStore,
} from '@gigin-work-space/store';
import { canAccess } from '@gigin-work-space/utils';
// import ArchiveIcon from '@mui/icons-material/Archive'; TODO: will implement in future
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Box, Tab, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withViewPermission } from 'src/app/HOC/withPermission';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../../../utils';
import { DashboardAnalyticsData } from '../analytics-dashboard';
import AnalyticsDashboard from '../analytics-dashboard/analytics-dashboard';
import { BgvAllCasesTable } from '../bgv-orders-table/bgv-all-cases-table';
import { BgvNewOrdersTable } from '../bgv-orders-table/bgv-new-orders-list-table';
// import { DraftComponent } from '../bgv-orders-table/draft-component';TODO: will implement in future
import CircularIndeterminate from '../skelton/bgv-loader';
import OrderEmptyState from '../skelton/order-empty-state';

/* eslint-disable-next-line */
export interface BgvDashboardProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  isPadding?: boolean;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, isPadding = true, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box className={`${isPadding ? '' : 'pb-0'} pt-lg`}>{children}</Box>
      )}
    </div>
  );
}

export function BgvDashboard() {
  const [tabValue, setTabValue] = useState(CheckoutStore.tabIndex ?? 1);
  // TODO: commented for now
  // const [draftOrderCount, setDraftOrderCount] = useState(0);
  const [filterDays, setFilterDays] = useState(180);
  const [orderCount, setOrderCount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const snap = useSnapshot(OrderCandidateStore);
  const navigate = useNavigate();
  const [, setCountStatus] = useState({
    ACTIVE: 0,
    INACTIVE: 0,
    PENDING: 0,
    CONFIRMED: 0,
    CANCELED: 0,
    confirmed: 0,
  });
  const [analytics, setAnalytics] = useState<DashboardAnalyticsData | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const { canCreateOrder, canUpdateOrder, canReadOrder } =
    useSnapshot(permissionStore);
  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number
  ) => {
    setTabValue(newTabValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // function to create new bgv request
  const handleCreateNewRequest = () => {
    globalNavigateTo('/bgv/create-order-request', navigate);
  };
  const updateFilterDate = (newCount: number) => {
    setFilterDays(newCount);
  };

  // TODO: will implement this in future
  // const updateCountDStats = (newCount: number) => {
  //   setDraftOrderCount(newCount);
  // };

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(endpoints.ANALYTICS_FETCH, {
        params: {
          filterDays: filterDays,
        },
      });
      const analyticsData = response.data.data;
      setOrderCount(analyticsData?.order_status_stats?.CONFIRMED);
      if (analyticsData) {
        setAnalytics(analyticsData);
        setCountStatus(analyticsData.order_status_stats);
        OrderCandidateStore.totalCandidate =
          analyticsData?.candidates_stats?.case_wise_count
            ?.total_number_of_candidates ?? 0;
        OrderCandidateStore.totalCases =
          analyticsData?.candidates_stats?.case_wise_count
            ?.total_number_of_candidates ?? 0;
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(
        "You don't have permission to fetch  analytics permission.",
        {
          variant: 'warning',
          autoHideDuration: 2000,
        }
      );
    }
  };

  // TODO: Commented for now, will into this in future

  // const fetchDraftOrders = async () => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `${endpoints.FETCH_ALL_DRAFT_ORDER}`
  //     );

  //     const allDraftOrder = response.data.data;
  //     if (allDraftOrder) {
  //       if (allDraftOrder) setDraftOrderCount(allDraftOrder.length);
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(`Fetching draft order Failed`, {
  //       variant: 'error',
  //       autoHideDuration: 2000,
  //     });
  //   }
  // };

  useEffect(() => {
    if (canAccess([canReadOrder, canCreateOrder, canUpdateOrder]))
      fetchAnalytics();
  }, [filterDays]);

  // TODO: Commented for now, will into this in future

  // useEffect(() => {
  //   if (canAccess([canCreateOrder, canUpdateOrder])) fetchDraftOrders();
  // }, [filterDays]);

  const emptyState = (
    <>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <OrderEmptyState handleCreateNewRequest={handleCreateNewRequest} />
      )}
    </>
  );

  const children = (
    <>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <div>
          {/* Top header section START*/}
          <section className="sticky top-0 z-10">
            <div className="flex justify-between items-center p-lg pb-xs">
              <Typography className="bk-typography-h5 text-bku_text_dark bg-bk_bgv_bg">
                Verification Dashboard
              </Typography>
              <div className="flex justify-end items-center gap-x-5">
                {canCreateOrder && (
                  <BKStyledButton
                    className="py-2 px-4 bg-bk_action_primary rounded-lg bK-body2 text-bk_tag_blue_bg"
                    variant="contained"
                    sx={{ boxShadow: 0 }}
                    onClick={handleCreateNewRequest}>
                    Create verification request
                  </BKStyledButton>
                )}
              </div>
            </div>
            {/* <Divider /> */}
          </section>
          {/* Top header section END*/}

          {/* Middle Analytics section END */}
          {/* List View of Orders START */}
          <section
            className={` ${
              tabValue === 1 ? 'px-6 pt-sm' : 'p-lg pt-sm'
            } w-full`}>
            <Box>
              <BKStyledTabs
                value={tabValue}
                onChange={handleTabChange}
                className="sticky top-[20%]"
                aria-label="BGV Dashboard Orders Tabs">
                <Tab
                  label={
                    <Box className="flex items-center gap-xs">
                      <AutoAwesomeMosaicIcon className="w-xl h-xl" />
                      <strong>Overview</strong>{' '}
                    </Box>
                  }
                  className="text-bku_text_dark"
                  {...a11yProps(0)}
                />

                <Tab
                  label={
                    <Box className="flex items-center gap-xs">
                      <FactCheckIcon className="w-xl h-xl" />
                      <strong>Candidate list</strong>{' '}
                    </Box>
                  }
                  {...a11yProps(1)}
                  className="bk-sub-heading2 text-bku_text_dark"
                />

                <Tab
                  label={
                    <Box className="flex items-center gap-xs">
                      <AssignmentIndIcon className="w-xl h-xl" />
                      <strong>{`Order list (${orderCount ?? 0})`}</strong>
                    </Box>
                  }
                  {...a11yProps(2)}
                  className="bk-sub-heading2 text-bku_text_dark"
                />
                {/* TODO: Draft page  || commented for now will work in future*/}
                {/* {canAccess([canCreateOrder, canUpdateOrder]) && (
                  <Tab
                    label={
                      <Box className="flex items-center gap-xs">
                        <ArchiveIcon className="w-xl h-xl" />
                        <strong>Draft ({draftOrderCount ?? 0})</strong>
                      </Box>
                    }
                    {...a11yProps(3)}
                    className="bk-sub-heading2"
                  />
                )} */}
              </BKStyledTabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Box className="bk-bgv-dashboard-tabs">
                {canAccess([canReadOrder, canCreateOrder, canUpdateOrder]) &&
                analytics ? (
                  <section>
                    <AnalyticsDashboard
                      analytics={analytics}
                      filterDays={filterDays}
                      updateFilterDate={updateFilterDate}
                    />
                  </section>
                ) : (
                  <></>
                )}
              </Box>
            </TabPanel>

            <TabPanel isPadding={false} value={tabValue} index={1}>
              <Box className="bk-bgv-dashboard-tabs">
                <BgvAllCasesTable />
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Box className="bk-bgv-dashboard-tabs">
                <BgvNewOrdersTable />
              </Box>
            </TabPanel>

            {/* TODO: Draft Compoent || will work this in future */}
            {/* {canAccess([canCreateOrder, canUpdateOrder]) && (
              <TabPanel value={tabValue} index={3}>
                <Box className="bk-bgv-dashboard-tabs">
                  <DraftComponent updateDraftCount={updateCountDStats} />
                </Box>
              </TabPanel>
            )} */}
          </section>
          {/* List View of Orders END */}
        </div>
      )}
    </>
  );
  return withViewPermission({
    permissions: [canReadOrder, canCreateOrder, canUpdateOrder],
    // children: orderCount || draftOrderCount ? children : emptyState,

    // TODO: draftOrderCount removed for now, will work this in future
    children: orderCount ? children : emptyState,
  });
}

export default BgvDashboard;
