import { Box, Button, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

interface LoadingButtonProps {
  isLoading: boolean;
  children: ReactNode;
  [x: string]: any;
}

export const LoadingButton = ({
  isLoading,
  children,
  ...props
}: LoadingButtonProps) => (
  <Box position="relative" display="inline-flex">
    <Button {...props} disabled={isLoading}>
      {children}
    </Button>
    {isLoading && (
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <CircularProgress size={24} />
      </Box>
    )}
  </Box>
);
