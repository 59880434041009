/* eslint-disable array-callback-return */
/* eslint-disable valtio/state-snapshot-rule */
import {
  EmployerCDCStore,
  handleModalOpen,
  isModalOpenStore,
} from '@gigin-work-space/store';
import {
  COLORS,
  ExculdedFieldDataType,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import { AlertConfirmClear } from '../common-alert-popup';

type EmployeeDataCell = null | {
  dataCollected: { value: string };
  parameterLabel: string;
  dataValidationStatus: string;
  fieldValidationRules: {
    isOptional: boolean;
  };
  fieldError: string;
  shouldEncryptHash: boolean;
};

type DataCollectionField = {
  dataCollected: { value: string };
  parameterLabel: string;
  dataValidationStatus: string;
  fieldError: string;
  fieldValidationRules: {
    isOptional: boolean;
  };
  shouldEncryptHash: boolean;
};

type DataCollection = {
  dataCollectionId: any;
  dataCollectionFields: DataCollectionField[];
};
type ShowUnmaskedValueState = {
  [key: string]: boolean;
};

type UnmaskedValueState = {
  [key: string]: string;
};

type HeaderKey = { label: string; name: string };
type HeaderKeys = HeaderKey[];

function isHeaderObjectWithName(
  header: HeaderKey
): header is { label: string; name: string } {
  return typeof header === 'object' && 'name' in header;
}

const EditableTable = (props: any) => {
  const header: HeaderKeys = props.headerKeys;
  const files = props.files;
  const [employeeData, setEmployeeData] = useState<EmployeeDataCell[][]>([[]]);
  const [prevEmployeeData, setPrevEmployeeData] = useState<
    EmployeeDataCell[][] | null
  >(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [candidateDetails, setCandidateDetails] = useState<
    Record<string, string>
  >({});
  const [numberOfCandidates, setNumberOfCandidates] = useState<number>(0);
  const [numberOfRows, setNumberOfRows] = useState<number>(0);

  const { isOpen } = useSnapshot(isModalOpenStore);

  const handleClearFile = () => {
    handleModalOpen({
      ...isOpen,
      alertConfirmClear: true,
    });
  };

  const onChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (columnIndex === undefined) {
      return;
    }
    let { value } = e.target;
    value = value.toUpperCase();

    const editData = employeeData.map((row, i) =>
      i === rowIndex
        ? row.map((cell, __j) => {
            if (
              cell != null &&
              isHeaderObjectWithName(header[columnIndex]) &&
              header[columnIndex].name === cell?.parameterLabel
            ) {
              cell.dataCollected.value = value;
            }
            return cell;
          })
        : row
    );
    setEmployeeData(editData);
  };

  // Check this for AxiosError
  const debouncedApiCall = debounce(async modifiedData => {
    try {
      const response = await axiosInstance.patch(
        `${endpoints.BULK_TEMPLATE_DATA_PATCH}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`,
        modifiedData
      );

      // Manually check the status code and throw an error if it's not 2xx
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      if (response.data.statusCode === 200) {
        // Transform the updatedDocs to ensure it is an array of objects
        const updatedDocs: DataCollection[] = Object.values(
          response.data.data
        ).flat() as DataCollection[];

        // Correctly map the updatedDocs to employeeData
        const updatedEmployeeData = employeeData.map(row => {
          const docToUpdate = updatedDocs.find(
            (doc: DataCollection) =>
              doc.dataCollectionId === row[0]?.dataCollected.value
          );

          if (docToUpdate) {
            return row.map(cell => {
              if (
                cell &&
                docToUpdate.dataCollectionFields.some(
                  (field: DataCollectionField) =>
                    field.parameterLabel === cell.parameterLabel
                )
              ) {
                const updatedField = docToUpdate.dataCollectionFields.find(
                  (field: DataCollectionField) =>
                    field.parameterLabel === cell.parameterLabel
                );
                if (updatedField) {
                  cell.dataCollected.value = updatedField.dataCollected.value;
                  cell.dataValidationStatus = updatedField.dataValidationStatus;
                  cell.fieldError = updatedField.fieldError;
                }
              }
              return cell;
            });
          }
          return row;
        });
        // Deep copy to prevEmployeeData to avoid reference issues
        setPrevEmployeeData(JSON.parse(JSON.stringify(employeeData)));
        setEmployeeData(updatedEmployeeData);
      } else {
        throw new Error(
          `Request failed with status code ${response.data.statusCode}`
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.message) {
          const errorMessages = error.response.data.message.errorDetails;

          // Update employeeData with the errors based on dataCollectionId and parameterLabel
          const updatedEmployeeData = employeeData.map(row => {
            const dataCollectionId = row[0]?.dataCollected.value;

            // Find if there's an error for this dataCollectionId
            const errorForRow = errorMessages.find(
              (err: { dataCollectionId: string }) =>
                err.dataCollectionId === dataCollectionId
            );

            if (errorForRow) {
              return row.map(cell => {
                if (cell) {
                  // Find if there's an error for this specific parameterLabel
                  const fieldError =
                    errorForRow?.dataCollectionFieldErrors?.find(
                      (errorField: DataCollectionField) =>
                        errorField.parameterLabel === cell.parameterLabel
                    );

                  if (fieldError) {
                    // Update cell with the error message and validation status
                    cell.fieldError = fieldError.fieldError;
                    cell.dataValidationStatus = fieldError.dataValidationStatus;
                  }
                }
                return cell;
              });
            }
            return row;
          });

          // Deep copy to prevEmployeeData to avoid reference issues
          setPrevEmployeeData(JSON.parse(JSON.stringify(employeeData)));
          setEmployeeData(updatedEmployeeData);
          enqueueSnackbar('Errors found in some fields', {
            variant: VARIANT_TYPE.ERROR,
          });
        }
      } else {
        enqueueSnackbar('Error updating data', {
          variant: VARIANT_TYPE.ERROR,
        });
      }
    }
  }, 300);

  const onBlur = async (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (columnIndex === undefined) {
      return; // Handle the case where columnIndex is undefined
    }
    let { value } = e.target;
    value = value.trim();

    // Get the first cell value
    const firstCellValue =
      rowIndex < employeeData.length
        ? employeeData[rowIndex][0]?.dataCollected.value
        : undefined;

    // Construct the modified data
    const modifiedData = [
      { [header[0].label]: firstCellValue, [header[columnIndex].label]: value },
    ];

    // Get the current value using the correct key
    const currentValue = modifiedData[0][header[columnIndex].label];

    // Check if the value has changed and trigger the debounced API call
    if (
      (prevEmployeeData?.length &&
        currentValue !==
          prevEmployeeData[rowIndex][columnIndex]?.dataCollected.value) ||
      !prevEmployeeData
    ) {
      debouncedApiCall(modifiedData);
    }
  };

  const getTableData = async (order_id: string, package_id: string) => {
    setEmployeeData([]);
    try {
      const responseDataWithValidation = await axiosInstance.get(
        `${endpoints.BULK_TEMPLATE_DATA_GET}/${order_id}/${package_id}`
      );

      setNumberOfRows(responseDataWithValidation?.data?.data?.length);
      const updatedData: (() => EmployeeDataCell[][]) | any[][] = [];
      responseDataWithValidation?.data?.data.map(
        (data: {
          _id: any;
          dataCollectionFields: {
            dataCollected: { value: string };
            fieldDataType: string;
          }[];
        }) => {
          const tempData: any[] = [];
          tempData.push({
            dataCollected: {
              value: data._id,
            },
            parameterLabel: 'dataCollectionId (Do not change)',
            dataValidationStatus: 'VALID',
            fieldError: '',
            fieldValidationRules: {},
            shouldStreamOutput: false,
          });
          data?.dataCollectionFields.map(dataCollectionField => {
            if (
              dataCollectionField?.fieldDataType !==
                ExculdedFieldDataType.FILE &&
              dataCollectionField?.fieldDataType !==
                ExculdedFieldDataType.EMPLOYMENT_CHECKBOX &&
              dataCollectionField?.fieldDataType !==
                ExculdedFieldDataType.ADDRESS_CHECKBOX
            ) {
              dataCollectionField.dataCollected.value =
                dataCollectionField.dataCollected.value?.toUpperCase();
              dataCollectionField.dataCollected.value =
                dataCollectionField.dataCollected.value?.trim();
              tempData.push(dataCollectionField);
            }
          });
          updatedData.push(tempData);
        }
      );

      setEmployeeData(updatedData);
    } catch (error) {
      enqueueSnackbar('Error fetching data', { variant: VARIANT_TYPE.ERROR });
    }
  };

  const getStepperStatus = async () => {
    if (EmployerCDCStore.dataMapped === 'SUBMITTED') {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const getListOfPackages = async (order_id: string, package_id: string) => {
    const data = EmployerCDCStore.listOfPackage;

    for (const packageItem of data) {
      if (packageItem.packageDetails.id === package_id) {
        const result: Record<string, string> = {};
        const candidateDetails = packageItem.candidateDetails;
        setNumberOfCandidates(candidateDetails.length);
        for (const candidate of candidateDetails) {
          result[candidate.dataCollectionId] = candidate.name;
        }
        setCandidateDetails(result);
        break; // Assuming there's only one matching package_id
      }
    }
  };

  const [showUnmaskedValue, setShowUnmaskedValue] =
    useState<ShowUnmaskedValueState>({});
  const [maskedValue, setMaskedValue] = useState<UnmaskedValueState>({});

  // Toggle masked value
  const handleToggleVisibility = async (
    rowIndex: number,
    columnIndex: number,
    cell: EmployeeDataCell | null | undefined
  ) => {
    const cellKey = `${rowIndex}-${columnIndex}`;
    const firstCellValue =
      rowIndex < employeeData.length
        ? employeeData[rowIndex][0]?.dataCollected.value
        : undefined;

    // Toggle visibility state
    setShowUnmaskedValue(prevState => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));

    // If showing unmasked value for the first time, make the API call
    if (!showUnmaskedValue[cellKey]) {
      try {
        const response = await axiosInstance.get(
          `${endpoints.BULK_UPLOAD_GET_UNMASKED_CELL_VALUE}/${firstCellValue}/${cell?.parameterLabel}`
        );

        const fetchedUnmaskedValue = response.data.dataCollected.value;

        // Store the masked value for future use (if we haven't already)
        if (!maskedValue[`masked-${cellKey}`]) {
          setMaskedValue(prevState => ({
            ...prevState,
            [`masked-${cellKey}`]: cell?.dataCollected.value ?? '', // Store the masked value, default to empty string
          }));
        }

        // Update employeeData state with the unmasked value
        const updatedEmployeeData = employeeData.map((row, i) =>
          i === rowIndex
            ? row.map(currentCell => {
                if (
                  currentCell &&
                  currentCell.parameterLabel === cell?.parameterLabel
                ) {
                  // Store the unmasked value in the matching cell
                  currentCell.dataCollected.value = fetchedUnmaskedValue;
                }
                return currentCell;
              })
            : row
        );

        // Update state with the unmasked value
        setEmployeeData(updatedEmployeeData);
      } catch (error) {
        enqueueSnackbar('Error fetching unmasked value', {
          variant: 'error',
        });
      }
    } else if (showUnmaskedValue[cellKey]) {
      // If we're toggling to show the masked value (i.e., hiding the unmasked value)
      const updatedEmployeeData = employeeData.map((row, i) =>
        i === rowIndex
          ? row.map(currentCell => {
              if (
                currentCell &&
                currentCell.parameterLabel === cell?.parameterLabel
              ) {
                // Restore the masked value from the stored masked value
                currentCell.dataCollected.value =
                  maskedValue[`masked-${cellKey}`] || '';
              }
              return currentCell;
            })
          : row
      );

      setEmployeeData(updatedEmployeeData);
    }
  };

  useEffect(() => {
    getTableData(EmployerCDCStore.request_id, EmployerCDCStore.request_role_id);
  }, []);

  useEffect(() => {
    getStepperStatus();
    getListOfPackages(
      EmployerCDCStore.request_id,
      EmployerCDCStore.request_role_id
    );
  }, []);

  return (
    <>
      <Grid width="100%" display={'flex'} justifyContent="space-between">
        <div className="flex items-center">
          <Typography className="bk-subheading-2">
            <b>
              {numberOfRows} of {numberOfCandidates} data complete
            </b>
          </Typography>
        </div>
        <Button
          disabled={isButtonDisabled}
          variant="outlined"
          onClick={handleClearFile}
          sx={{ '&.MuiButton-root': { textTransform: 'none' } }}>
          Clear all data
        </Button>
      </Grid>
      <div className="overflow-x-auto">
        <table className="bk-editable-table-table">
          <thead>
            <tr>
              {header.map((keys, columnIndex) => {
                // Determine whether the current header item is a string or an object
                const columnKeys = typeof keys === 'object' ? keys.label : keys;

                return (
                  <Tooltip
                    key={columnIndex} // Ensuring a unique key for the Tooltip
                    title={
                      columnIndex === 0 ? 'Full Candidate Name' : columnKeys
                    }
                    placement="top">
                    <th
                      className={`bk-editable-table-header bk-sub-heading2 ${
                        columnIndex === 0
                          ? 'bk-fixed-column-header bk-first-header'
                          : ''
                      }`}>
                      <b>
                        {columnIndex === 0 ? 'Full Candidate Name' : columnKeys}
                      </b>
                    </th>
                  </Tooltip>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {employeeData.map((row, rowIndex) => (
              <tr className="bk-border-left-stroke-primary" key={rowIndex}>
                {header.map((key, columnIndex) => {
                  const headerKeys = typeof key === 'object' ? key.name : key;
                  const cell = row.find(
                    field => field?.parameterLabel === headerKeys
                  );

                  return (
                    <td
                      key={columnIndex}
                      style={{
                        backgroundColor:
                          cell &&
                          (cell.dataValidationStatus === 'INVALID' ||
                            (cell.fieldValidationRules?.isOptional === false &&
                              cell.dataCollected?.value === ''))
                            ? COLORS.bk_table_bg_input
                            : COLORS.bk_bg_primary,
                      }}
                      className={`bk-editable-table-td ${
                        columnIndex === 0 ? 'bk-fixed-column-header' : ''
                      }`}>
                      {columnIndex === 0 ? (
                        // Render non-editable content for the first column (e.g., Candidate Name)
                        cell?.dataCollected?.value &&
                        candidateDetails[cell.dataCollected.value] ? (
                          candidateDetails[cell.dataCollected.value]
                        ) : (
                          ''
                        )
                      ) : (
                        <div className="flex items-center">
                          <Tooltip
                            title={
                              cell?.dataValidationStatus === 'INVALID' ||
                              (cell?.fieldValidationRules?.isOptional ===
                                false &&
                                cell?.dataCollected?.value === '')
                                ? cell?.fieldError
                                : cell?.dataCollected?.value ?? ''
                            }
                            placement="top">
                            <input
                              className="bk-editable-table-input bk-light-placeholder"
                              value={
                                showUnmaskedValue[`${rowIndex}-${columnIndex}`]
                                  ? maskedValue[`${rowIndex}-${columnIndex}`] ||
                                    cell?.dataCollected?.value ||
                                    ''
                                  : cell?.dataCollected?.value || ''
                              }
                              style={{
                                backgroundColor:
                                  cell &&
                                  (cell.dataValidationStatus === 'INVALID' ||
                                    (cell.fieldValidationRules?.isOptional ===
                                      false &&
                                      cell.dataCollected?.value === ''))
                                    ? COLORS.bk_table_bg_input
                                    : 'inherit',
                              }}
                              title={
                                cell &&
                                (cell.dataValidationStatus === 'INVALID' ||
                                  (cell.fieldValidationRules?.isOptional ===
                                    false &&
                                    cell.dataCollected?.value === ''))
                                  ? cell.fieldError
                                  : undefined
                              }
                              onChange={e => onChange(e, rowIndex, columnIndex)}
                              onBlur={e => onBlur(e, rowIndex, columnIndex)}
                              placeholder={'Enter value'}
                              disabled={
                                EmployerCDCStore.dataMapped === 'SUBMITTED'
                              }
                            />
                          </Tooltip>
                          {cell?.shouldEncryptHash &&
                          cell.dataCollected.value ? (
                            <div
                              onClick={() =>
                                handleToggleVisibility(
                                  rowIndex,
                                  columnIndex,
                                  cell
                                )
                              }>
                              {showUnmaskedValue[
                                `${rowIndex}-${columnIndex}`
                              ] ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </div>
                          ) : null}
                          {cell &&
                          (cell.dataValidationStatus === 'INVALID' ||
                            (cell.fieldValidationRules?.isOptional === false &&
                              cell.dataCollected?.value === '')) ? (
                            <ReportProblemOutlinedIcon
                              sx={{ color: COLORS.bk_alert_failure }}
                            />
                          ) : null}
                        </div>
                      )}
                      {/* <td key={columnIndex}> */}
                      {/* Your td content */}
                      {/* <div className="flex items-center">
                        <Tooltip
                          title={cell?.dataCollected?.value ?? ''}
                          placement="top">
                          <input
                            value={
                              showUnmaskedValue[`${rowIndex}-${columnIndex}`]
                                ? unmaskedValue[`${rowIndex}-${columnIndex}`] ||
                                  cell?.dataCollected?.value ||
                                  ''
                                : cell?.dataCollected?.value || ''
                            }
                            type={
                              showUnmaskedValue[`${rowIndex}-${columnIndex}`]
                                ? 'text'
                                : 'password'
                            }
                          />
                        </Tooltip>
                        {cell?.shouldEncryptHash && (
                          <div
                            onClick={() =>
                              handleToggleVisibility(
                                rowIndex,
                                columnIndex,
                                cell
                              )
                            }>
                            {showUnmaskedValue[`${rowIndex}-${columnIndex}`] ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </div>
                        )}
                      </div> */}
                    </td>
                    // <td
                    //   key={columnIndex}
                    //   style={{
                    //     backgroundColor:
                    //       cell &&
                    //       (cell.dataValidationStatus === 'INVALID' ||
                    //         (cell.fieldValidationRules?.isOptional === false &&
                    //           cell.dataCollected?.value === ''))
                    //         ? COLORS.bk_table_bg_input
                    //         : COLORS.bk_bg_primary,
                    //   }}
                    //   className={`bk-editable-table-td ${
                    //     columnIndex === 0 ? 'bk-fixed-column-header' : ''
                    //   }`}>
                    //   {columnIndex === 0 ? (
                    //     // Render non-editable content for the first column (e.g., Candidate Name)
                    //     cell?.dataCollected?.value &&
                    //     candidateDetails[cell.dataCollected.value] ? (
                    //       candidateDetails[cell.dataCollected.value]
                    //     ) : (
                    //       ''
                    //     )
                    //   ) : (
                    //     <div className="flex items-center">
                    //       <Tooltip
                    //         title={
                    //           cell?.dataValidationStatus === 'INVALID' ||
                    //           (cell?.fieldValidationRules?.isOptional ===
                    //             false &&
                    //             cell?.dataCollected?.value === '')
                    //             ? cell?.fieldError
                    //             : cell?.dataCollected?.value ?? ''
                    //         }
                    //         placement="top">
                    //         <input
                    //           className="bk-editable-table-input bk-light-placeholder"
                    //           value={
                    //             showUnmaskedValue[`${rowIndex}-${columnIndex}`]
                    //               ? unmaskedValue[
                    //                   `${rowIndex}-${columnIndex}`
                    //                 ] ||
                    //                 cell?.dataCollected?.value ||
                    //                 ''
                    //               : cell?.dataCollected?.value || ''
                    //           }
                    //           style={{
                    //             backgroundColor:
                    //               cell &&
                    //               (cell.dataValidationStatus === 'INVALID' ||
                    //                 (cell.fieldValidationRules?.isOptional ===
                    //                   false &&
                    //                   cell.dataCollected?.value === ''))
                    //                 ? COLORS.bk_table_bg_input
                    //                 : 'inherit',
                    //           }}
                    //           title={
                    //             cell &&
                    //             (cell.dataValidationStatus === 'INVALID' ||
                    //               (cell.fieldValidationRules?.isOptional ===
                    //                 false &&
                    //                 cell.dataCollected?.value === ''))
                    //               ? cell.fieldError
                    //               : undefined
                    //           }
                    //           type={
                    //             showUnmaskedValue[`${rowIndex}-${columnIndex}`]
                    //               ? 'text'
                    //               : 'password'
                    //           } // Toggle between text and password
                    //           onChange={e => onChange(e, rowIndex, columnIndex)}
                    //           onBlur={e => onBlur(e, rowIndex, columnIndex)}
                    //           placeholder={'Enter value'}
                    //           disabled={
                    //             EmployerCDCStore.dataMapped === 'SUBMITTED'
                    //           }
                    //         />
                    //       </Tooltip>
                    //       {cell.shouldEncryptHash ? (
                    //         <div
                    //           onClick={() =>
                    //             handleToggleVisibility(
                    //               rowIndex,
                    //               columnIndex,
                    //               cell
                    //             )
                    //           }>
                    //           {showUnmaskedValue[
                    //             `${rowIndex}-${columnIndex}`
                    //           ] ? (
                    //             <VisibilityOffOutlinedIcon />
                    //           ) : (
                    //             <VisibilityOutlinedIcon />
                    //           )}
                    //         </div>
                    //       ) : null}
                    //       {/* <Tooltip
                    //         title={
                    //           cell?.dataValidationStatus === 'INVALID' ||
                    //           (cell?.fieldValidationRules?.isOptional ===
                    //             false &&
                    //             cell?.dataCollected?.value === '')
                    //             ? cell?.fieldError
                    //             : cell?.dataCollected?.value ?? ''
                    //         }
                    //         placement="top">
                    //         <input
                    //           className="bk-editable-table-input bk-light-placeholder"
                    //           value={cell?.dataCollected?.value || ''}
                    //           style={{
                    //             backgroundColor:
                    //               cell &&
                    //               (cell.dataValidationStatus === 'INVALID' ||
                    //                 (cell.fieldValidationRules?.isOptional ===
                    //                   false &&
                    //                   cell.dataCollected?.value === ''))
                    //                 ? COLORS.bk_table_bg_input
                    //                 : 'inherit',
                    //           }}
                    //           title={
                    //             cell &&
                    //             (cell.dataValidationStatus === 'INVALID' ||
                    //               (cell.fieldValidationRules?.isOptional ===
                    //                 false &&
                    //                 cell.dataCollected?.value === ''))
                    //               ? cell.fieldError
                    //               : undefined
                    //           }
                    //           type="text"
                    //           onChange={e => onChange(e, rowIndex, columnIndex)}
                    //           onBlur={e => onBlur(e, rowIndex, columnIndex)}
                    //           placeholder={'Enter value'}
                    //           disabled={
                    //             EmployerCDCStore.dataMapped === 'SUBMITTED'
                    //           }
                    //         />
                    //       </Tooltip> */}
                    //       {cell &&
                    //       (cell.dataValidationStatus === 'INVALID' ||
                    //         (cell.fieldValidationRules?.isOptional === false &&
                    //           cell.dataCollected?.value === '')) ? (
                    //         <ReportProblemOutlinedIcon
                    //           sx={{ color: COLORS.bk_alert_failure }}
                    //         />
                    //       ) : null}
                    //     </div>
                    //   )}
                    // </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isOpen.alertConfirmClear ? (
        <AlertConfirmClear candidateDetails={candidateDetails} files={files} />
      ) : (
        ''
      )}
    </>
  );
};

export default EditableTable;
