import { COLORS } from '@gigin-work-space/utils';
import CasesIcon from '@mui/icons-material/Description';
import ChecksIcon from '@mui/icons-material/ListAlt';
import ClientsIcon from '@mui/icons-material/People';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

const OpsSidebar = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavigation = (path: any, index: any) => {
    setActiveIndex(index);
    globalNavigateTo(path, navigate);
  };

  const icons = [
    { name: 'Cases', path: '/ops-platform', icon: <CasesIcon /> },
    {
      name: 'Checks',
      path: '/ops-platform/checks-dashboard',
      icon: <ChecksIcon />,
    },
    {
      name: 'Clients',
      path: '/ops-platform/client-list',
      icon: <ClientsIcon />,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      component={Paper}
      sx={{
        width: 60,
        flexShrink: 0,
        zIndex: 0,
        '& .MuiDrawer-paper': {
          width: 60,
          boxSizing: 'border-box',
          backgroundColor: '#F7F7F9',
          borderRight: `1px solid ${COLORS.bk_bg_dark}`,
        },
      }}>
      <Box className="overflow-auto mt-16">
        <List className="flex flex-col gap-6">
          {icons?.map((icon, index) => (
            <ListItem
              button
              onClick={() => handleNavigation(icon?.path, index)}
              key={icon?.name}
              className={`bk-ops-icon-bg ${index === activeIndex && 'active'}`}
              sx={{
                backgroundColor:
                  index === activeIndex ? '#f0f0f0' : 'transparent',
                borderLeft:
                  index === activeIndex
                    ? '4px solid #6200ea'
                    : '4px solid transparent',
                color: '#8D4DFD',
                '&.active': {
                  borderLeft: '4px solid #6200ea', // Ensure active class applies the border
                },
              }}>
              <ListItemIcon className="min-w-0">{icon?.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography className="text-bk_text_secondary">
                    {icon?.name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default OpsSidebar;
