/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useReducer } from 'react';

interface FooterState {
  primaryAction: (() => void) | null;
  primaryLabel: string | null;
  secondaryAction: (() => void) | null;
  secondaryLabel: string | null;
  footerText: string | null;
  primarySubmitting: boolean;
  secondarySubmitting: boolean;
  primaryDisabled: boolean;
  secondaryDisabled: boolean;
}

type FooterAction =
  | {
      type: 'SET_PRIMARY_ACTION';
      action: (() => void) | null;
      label: string | null;
    }
  | {
      type: 'SET_SECONDARY_ACTION';
      action: (() => void) | null;
      label: string | null;
    }
  | { type: 'SET_FOOTER_TEXT'; text: string | null }
  | { type: 'SET_PRIMARY_SUBMITTING'; submitting: boolean }
  | { type: 'SET_SECONDARY_SUBMITTING'; submitting: boolean }
  | { type: 'SET_PRIMARY_DISABLED'; disabled: boolean }
  | { type: 'SET_SECONDARY_DISABLED'; disabled: boolean };

const initialState: FooterState = {
  primaryAction: null,
  primaryLabel: null,
  secondaryAction: null,
  secondaryLabel: null,
  footerText: null,
  primarySubmitting: false,
  secondarySubmitting: false,
  primaryDisabled: false,
  secondaryDisabled: false,
};

const footerReducer = (
  state: FooterState,
  action: FooterAction
): FooterState => {
  switch (action.type) {
    case 'SET_PRIMARY_ACTION':
      return {
        ...state,
        primaryAction: action.action,
        primaryLabel: action.label,
      };
    case 'SET_SECONDARY_ACTION':
      return {
        ...state,
        secondaryAction: action.action,
        secondaryLabel: action.label,
      };
    case 'SET_FOOTER_TEXT':
      return { ...state, footerText: action.text };
    case 'SET_PRIMARY_SUBMITTING':
      return { ...state, primarySubmitting: action.submitting };
    case 'SET_SECONDARY_SUBMITTING':
      return { ...state, secondarySubmitting: action.submitting };
    case 'SET_PRIMARY_DISABLED':
      return { ...state, primaryDisabled: action.disabled };
    case 'SET_SECONDARY_DISABLED':
      return { ...state, secondaryDisabled: action.disabled };
    default:
      return state;
  }
};

const FooterContext = createContext<
  | {
      state: FooterState;
      dispatch: React.Dispatch<FooterAction>;
    }
  | undefined
>(undefined);

export const FooterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(footerReducer, initialState);

  return (
    <FooterContext.Provider value={{ state, dispatch }}>
      {children}
    </FooterContext.Provider>
  );
};

export const useFooter = () => {
  const context = useContext(FooterContext);
  if (!context) {
    throw new Error('useFooter must be used within a FooterProvider');
  }
  return context;
};
