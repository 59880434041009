import { BKStyledTableCell } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import { COLORS } from '@gigin-work-space/utils';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { useSnapshot } from 'valtio';

export interface PackagesTableProps {
  packageList: any;
}

// interface Row {
//   id: number;
//   packageName: string;
//   check: string;
//   role: string;
// }

export const PackagesTable = (props: PackagesTableProps) => {
  const { packageList } = props;
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { canReadpackage, canCreatePackage, canUpdatePackage } =
    useSnapshot(permissionStore);
  const handleRowClick = (row: any) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const getRoles = (roles: any, toWrap: boolean) => {
    const roleList = roles.map((role: { role_title: any }) => role.role_title);
    const displayedRoles = roleList.slice(0, 3).join(', ');
    const remainingRolesCount = roleList.length - 3;

    let displayText = displayedRoles;
    if (remainingRolesCount > 0) {
      displayText += ` +${remainingRolesCount} more`;
    }

    if (!toWrap) {
      return roleList.join(', ');
    }
    return displayText;
  };

  return (
    <>
      <TableContainer
        className="overflow-x-hidden rounded-tr-lg rounded-tl-lg max-h-[300px] overflow-y-auto"
        sx={{
          border: `1px solid ${COLORS.bk_stroke_primary}`,
        }}>
        <Table>
          <TableHead
            sx={{
              position: 'relative',
              zIndex: -1,
              backgroundColor: COLORS.bk_bg_primary_smoke,
            }}>
            <TableRow>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">
                  Package name
                </Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Checks</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell>
                <Typography className="bk-sub-heading3">Role</Typography>
              </BKStyledTableCell>
              <BKStyledTableCell></BKStyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packageList &&
              packageList?.map((row: any) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRowClick(row)}>
                  <BKStyledTableCell size="small">
                    <Typography className="bk-sub-heading2">
                      {row.package_title}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      {row.product_list
                        .slice(0, 3)
                        .map((product: any, id: number) => (
                          <DisplayImage
                            key={id}
                            imageName={product?.product_img}
                            imageType="url"
                            className="w-5 h-5 object-contain cursor-pointer"
                          />
                        ))}
                      {row.product_list.length > 3 && (
                        <Typography className="bK-body3">
                          +{row.product_list.length - 3} more
                        </Typography>
                      )}
                      {/* {row.product_list.map((product: any, id: number) =>
                        id < 4 ? (
                          <DisplayImage
                            imageName={product.icon}
                            imageType="url"
                            className="w-5 h-5 object-contain cursor-pointer"
                          />
                        ) : (
                          <Typography className="bK-body3">
                            +{row.product_list.length - id} more
                          </Typography>
                        )
                      )} */}
                    </Stack>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    <Typography className="bk-sub-heading2">
                      {/* {row.role} */}
                      {/* TODO: role need to be dynamic */}
                      {row.jobRoles.length ? getRoles(row.jobRoles, true) : '-'}
                    </Typography>
                  </BKStyledTableCell>
                  <BKStyledTableCell size="small">
                    {canReadpackage ||
                      canUpdatePackage ||
                      (canCreatePackage && (
                        <IconButton aria-label="edit">
                          {/* <DisplayImage
                        imageName="edit_row_icon.svg"
                        imageType="icon"
                      /> */}
                          <RemoveRedEyeIcon
                            sx={{
                              color: COLORS.bk_stroke_divider,
                              '&:hover': {
                                color: COLORS.bk_action_pressed,
                              },
                            }}
                            className="cursor-pointer"
                          />
                        </IconButton>
                      ))}
                  </BKStyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Dialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        fullWidth>
        <DialogTitle>
          <Typography className="bk-headline2">
            {selectedRow?.package_title}
          </Typography>
          <Typography className="bK-body1">
            {/*  TODO: role need to be dynamic           */}
            For role:{' '}
            {selectedRow?.jobRoles?.length
              ? getRoles(selectedRow.jobRoles, false)
              : '-'}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className="bk-sub-heading2">Checks included</Typography>
          <Stack
            direction={'row'}
            spacing={1}
            marginTop={1}
            marginLeft={0}
            flexWrap={'wrap'}>
            {selectedRow &&
              selectedRow.product_list.map((product: any) => (
                <Grid className="bk-package-icon-table">
                  <DisplayImage
                    imageName={product.product_img}
                    imageType="url"
                    className="w-10 h-10 object-contain cursor-pointer"
                  />
                  <span>{product.product_name}</span>
                </Grid>
              ))}
          </Stack>
        </DialogContent>
        {/* <DialogActions>dcxsd</DialogActions> */}
      </Dialog>
    </>
  );
};

export default PackagesTable;
