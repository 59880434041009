import { MENU_WITH_HEADERS, OPS_PLATFORM_MENU } from '@gigin-work-space/utils';
import { Box, Divider, Tab, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import { BKStyledVerticalTab } from '../bk-styled-component/bk-styled-component';

export interface CommonVeriticalTabProps {
  firstSectionLabel?: any;
  secondSectionLabel?: any;
  tabMenu?: any;
  tabComponents?: any;
  type?: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  currentValue: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, currentValue, index, ...other } = props;

  return (
    <div
      className="w-full h-full"
      role="tabpanel"
      hidden={currentValue !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {currentValue === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: string) {
  return {
    value: index,
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export function CommonVeriticalTab(props: CommonVeriticalTabProps) {
  const { firstSectionLabel, secondSectionLabel, tabComponents, tabMenu, type = MENU_WITH_HEADERS } = props;
  const [currentValue, setCurrentValue] = React.useState(tabComponents[0].id);

  const tabRef = useRef<null | HTMLDivElement>(null);

  const handleClickToTop = () => {
    tabRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentValue(newValue);
    handleClickToTop();
  };

  return (
    <>
      {' '}
      <Box className="bk-vertical-tab" ref={tabRef}>
        {type === MENU_WITH_HEADERS && (
          <BKStyledVerticalTab
            orientation="vertical"
            variant="scrollable"
            value={currentValue ?? 'companyProfile'}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            textColor="primary">
            {/* First Section */}
            <Typography className="bk-sub-heading3 py-4">{firstSectionLabel?.sectionTitle}</Typography>
            {firstSectionLabel?.sectionLabels?.map((label: any) => (
              <Tab label={label?.label} key={label?.id} {...a11yProps(label?.id)} />
            ))}
            <Divider variant="middle" sx={{ margin: '4px 0', marginBottom: '16px' }} />
            {/* Second Section */}
            <Typography className="bk-sub-heading3 py-4">{secondSectionLabel?.sectionTitle}</Typography>
            {secondSectionLabel?.sectionLabels?.map((label: any) => (
              <Tab label={label.label} key={label.id} {...a11yProps(label.id)} />
            ))}
          </BKStyledVerticalTab>
        )}

        {type === OPS_PLATFORM_MENU && (
          <BKStyledVerticalTab
            orientation="vertical"
            variant="scrollable"
            value={currentValue}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            textColor="primary">
            <Tab label="Basic Details" {...a11yProps(tabComponents[0].id)} sx={{ textAlign: 'left' }} />
            {tabMenu?.map((menu: any) => (
              <Tab
                label={menu?.check_id?.product_listing_name}
                key={menu?.check_id?._id}
                {...a11yProps(menu?.check_id?._id)}
                sx={{ textAlign: 'left' }}
              />
            ))}
          </BKStyledVerticalTab>
        )}
        {tabComponents?.map((component: any) => (
          <TabPanel currentValue={currentValue} index={component.id} key={component.id}>
            {component.component}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}
