/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BKStyledButton,
  DropzoneFileUploader,
} from '@gigin-work-space/common-ui';
import { resetCheckoutStore } from '@gigin-work-space/store';
import {
  AUTO_HIDE_TIMEOUT,
  COLORS,
  DataCollectionItemSource,
  deleteItem,
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
  EnumIndexedDbContextKey,
  EnumIndexedDbStore,
  FILE_TYPE,
  getItem,
  MAX_FILE_SIZE,
  MimeTypes,
  setItem,
  SPACING,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Delete, Download, FilePresent } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Paper, Typography } from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useWizard } from 'react-use-wizard';
import { DisplayImage } from 'src/app/components';
import { IPackage, IParsedExcelDetails } from 'src/app/constant/interfaces';
import {
  useFooter,
  useOrderCreationContext,
  useOrderCreationStepContext,
} from 'src/app/context';
import {
  ConfirmOrderCreationModal,
  OrderCreatedSuccessModal,
} from 'src/app/templates';
import { axiosInstance, createExcelTemplate, endpoints } from 'src/app/utils';

interface PersistedData {
  files?: File[];
  parsedResponse?: IParsedExcelDetails;
}

export interface BgvRecruiterBulkUploadProps {
  contextKey?: EnumIndexedDbContextKey;
}

type State = {
  files: File[];
  isDownloadingTemplate: boolean;
  isUploadingAndParsingExcel: boolean;
  isDeletingFile: boolean;
  isOrderConfirmModalOpen: boolean;
  isOrderSuccessModalOpen: boolean;
};

type Action =
  | { type: 'SET_FILES'; payload: File[] }
  | { type: 'SET_IS_DOWNLOADING_TEMPLATE'; payload: boolean }
  | { type: 'SET_IS_UPLOADING_AND_PARSING_EXCEL'; payload: boolean }
  | { type: 'SET_IS_DELETING_FILE'; payload: boolean }
  | { type: 'SET_IS_ORDER_CONFIRM_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_IS_ORDER_SUCCESS_MODAL_OPEN'; payload: boolean };

const initialState: State = {
  files: [],
  isDownloadingTemplate: false,
  isUploadingAndParsingExcel: false,
  isDeletingFile: false,
  isOrderConfirmModalOpen: false,
  isOrderSuccessModalOpen: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FILES':
      return { ...state, files: action.payload };
    case 'SET_IS_DOWNLOADING_TEMPLATE':
      return { ...state, isDownloadingTemplate: action.payload };
    case 'SET_IS_UPLOADING_AND_PARSING_EXCEL':
      return { ...state, isUploadingAndParsingExcel: action.payload };
    case 'SET_IS_DELETING_FILE':
      return { ...state, isDeletingFile: action.payload };
    case 'SET_IS_ORDER_CONFIRM_MODAL_OPEN':
      return { ...state, isOrderConfirmModalOpen: action.payload };
    case 'SET_IS_ORDER_SUCCESS_MODAL_OPEN':
      return { ...state, isOrderSuccessModalOpen: action.payload };
    default:
      return state;
  }
};

export const BgvRecruiterBulkUpload = React.memo(
  ({
    contextKey = EnumIndexedDbContextKey.BGV_RECRUITER_BULK_CSV_UPLOAD,
  }: BgvRecruiterBulkUploadProps) => {
    const { dispatch: stepDispatch } = useOrderCreationStepContext();
    const {
      dispatch: orderDispatch,
      state: {
        selectedPackage,
        orderInitiationId,
        parsedExcelDetailsRecruiter,
      },
    } = useOrderCreationContext();
    const { dispatch: footerDispatch } = useFooter();
    const { nextStep, activeStep } = useWizard();
    const { enqueueSnackbar } = useSnackbar();
    const {
      state: { stepsConfig },
    } = useOrderCreationStepContext();

    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchTemplateMetaData = useCallback(async () => {
      const response = await axiosInstance.get(
        `${endpoints.BULK_UPLOAD_TEMPLATE}/${selectedPackage?.id}`
      );
      return response.data.data;
    }, [selectedPackage]);

    const generateTemplate = useCallback(async () => {
      dispatch({ type: 'SET_IS_DOWNLOADING_TEMPLATE', payload: true });
      try {
        const { header } = await fetchTemplateMetaData();
        createExcelTemplate(header);
      } catch (error) {
        enqueueSnackbar('Failed to download template', {
          variant: VARIANT_TYPE.ERROR,
        });
      } finally {
        dispatch({ type: 'SET_IS_DOWNLOADING_TEMPLATE', payload: false });
      }
    }, [fetchTemplateMetaData, enqueueSnackbar]);

    const downloadTemplate = useCallback(async () => {
      await generateTemplate();
    }, [generateTemplate]);

    const handleFilesChange = useCallback(
      async (newFiles: File[]) => {
        dispatch({ type: 'SET_FILES', payload: newFiles });
        dispatch({ type: 'SET_IS_UPLOADING_AND_PARSING_EXCEL', payload: true });
        try {
          const mediaData = {
            field_id: 'initiated-item-excel',
            isEncrypted: false,
            filePathContext: 'initiated-item',
            isTenantIsolated: true,
            isPrivate: true,
            shouldCompress: false,
          };

          const formData = new FormData();
          formData.append('files', newFiles[0]);
          formData.append('media', JSON.stringify(mediaData));
          formData.append('checkoutInitationId', orderInitiationId || '');
          formData.append('packageId', selectedPackage?.id || '');
          formData.append(
            'excelImportFor',
            DataCollectionItemSource.EMPLOYER_BULK_UPLOAD
          );

          const response = await axiosInstance.post(
            `${endpoints.UPLOAD_AND_PARSE_EXCEL_FILE}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          if (response?.data?.statusCode === HttpStatus.OK) {
            const parsedData = response?.data?.data as IParsedExcelDetails;
            if (parsedData?.totalItems === 0) {
              enqueueSnackbar(
                'The file uploaded was empty. Can you please fill the data and try uploading again',
                {
                  variant: VARIANT_TYPE.WARNING,
                }
              );
            } else {
              enqueueSnackbar('Candidate details uploaded successfully.', {
                variant: VARIANT_TYPE.SUCCESS,
                autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
              });
            }
            orderDispatch({
              type: 'SET_PARSED_EXCEL_DETAILS_RECRUITER',
              payload: parsedData,
            });

            await setItem(EnumIndexedDbStore.FILES, contextKey, {
              files: newFiles,
              parseResponse: parsedData,
            });
          }
        } catch (error: any) {
          const errorMessage =
            error?.response?.data?.message || 'Failed to upload parsed file';
          enqueueSnackbar(errorMessage, {
            variant: VARIANT_TYPE.ERROR,
          });
        } finally {
          dispatch({
            type: 'SET_IS_UPLOADING_AND_PARSING_EXCEL',
            payload: false,
          });
        }
      },
      [
        contextKey,
        enqueueSnackbar,
        orderDispatch,
        orderInitiationId,
        selectedPackage,
      ]
    );

    const handleRemoveFile = useCallback(async () => {
      dispatch({ type: 'SET_IS_DELETING_FILE', payload: true });
      try {
        const deleteBulkCandidateFile = await axiosInstance.delete(
          `${endpoints.DELETE_BULK_CANDIDATE_FILE}/${orderInitiationId}/${parsedExcelDetailsRecruiter?.mediaDetails?.media_id}/${DataCollectionItemSource.EMPLOYER_BULK_UPLOAD}`
        );
        if (deleteBulkCandidateFile?.data?.statusCode === HttpStatus.OK) {
          await deleteItem(EnumIndexedDbStore.FILES, contextKey);
          dispatch({ type: 'SET_FILES', payload: [] });
          orderDispatch({
            type: 'SET_PARSED_EXCEL_DETAILS_RECRUITER',
            payload: null,
          });
        }
      } catch (error) {
        console.error('Failed to clear files from IndexedDB or server:', error);
        enqueueSnackbar('Failed to remove the file', {
          variant: VARIANT_TYPE.ERROR,
        });
      } finally {
        dispatch({ type: 'SET_IS_DELETING_FILE', payload: false });
      }
    }, [
      contextKey,
      enqueueSnackbar,
      orderInitiationId,
      parsedExcelDetailsRecruiter,
      orderDispatch,
    ]);

    const createOrder = useCallback(async () => {
      footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: true });
      try {
        const response = await axiosInstance.post(
          `${endpoints.CONFIRM_ORDER_CHECKOUT}`,
          {
            checkout_initiation_id: orderInitiationId,
          }
        );

        return response?.data;
      } catch (error) {
        console.error('Failed to create order:', error);
        throw error;
      } finally {
        footerDispatch({ type: 'SET_PRIMARY_SUBMITTING', submitting: false });
      }
    }, [
      orderInitiationId,
      orderDispatch,
      enqueueSnackbar,
      nextStep,
      footerDispatch,
      stepDispatch,
      activeStep,
    ]);

    const handlePrimaryCTA = useCallback(() => {
      if (parsedExcelDetailsRecruiter) {
        // createOrder();
        handleOpenOrderConfirmModal();
      }
    }, [parsedExcelDetailsRecruiter, createOrder]);

    const handleOpenOrderConfirmModal = useCallback(() => {
      dispatch({ type: 'SET_IS_ORDER_CONFIRM_MODAL_OPEN', payload: true });
    }, []);
    const handleCloseOrderConfirmModal = useCallback(() => {
      dispatch({ type: 'SET_IS_ORDER_CONFIRM_MODAL_OPEN', payload: false });
    }, []);

    const handleOpenOrderSuccessModal = useCallback(() => {
      dispatch({ type: 'SET_IS_ORDER_SUCCESS_MODAL_OPEN', payload: true });
    }, []);
    const handleCloseOrderSuccessModal = useCallback(() => {
      dispatch({ type: 'SET_IS_ORDER_SUCCESS_MODAL_OPEN', payload: false });
    }, []);

    const handleConfirmOrderCreation = useCallback(async () => {
      handleCloseOrderConfirmModal();
      try {
        const orderCreateResponse = await createOrder();

        if (orderCreateResponse?.statusCode === HttpStatus.CREATED) {
          const orderId = orderCreateResponse?.data?._id;
          orderDispatch({ type: 'SET_ORDER_ID', payload: orderId });
          handleOpenOrderSuccessModal();
          return;
        }
        throw new Error('Failed to create order');
      } catch (error) {
        enqueueSnackbar('Failed to create order', {
          variant: VARIANT_TYPE.ERROR,
        });
      }
    }, [createOrder, handleOpenOrderSuccessModal]);

    const handleOrderSuccessAction = useCallback(() => {
      handleCloseOrderSuccessModal();
      stepDispatch({ type: 'MARK_STEP_COMPLETED', payload: activeStep });
      resetCheckoutStore();
      nextStep();
    }, [handleCloseOrderSuccessModal, nextStep]);

    // Side Effects
    //  useEffect to handle footer button state based on parseResponse
    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_DISABLED',
        disabled:
          !parsedExcelDetailsRecruiter ||
          !parsedExcelDetailsRecruiter?.totalItems ||
          !parsedExcelDetailsRecruiter?.validRecords,
      });
    }, [parsedExcelDetailsRecruiter, footerDispatch]);

    // useEffect to initialise footer contents
    useEffect(() => {
      footerDispatch({
        type: 'SET_PRIMARY_ACTION',
        action: handlePrimaryCTA,
        label: 'Continue',
      });
      stepDispatch({
        type: 'MARK_STEP_VISITED',
        payload: activeStep,
      });
    }, [footerDispatch, handlePrimaryCTA, stepDispatch, activeStep]);

    // Fetch persisted data from IndexedDB on component mount

    useEffect(() => {
      const fetchPersistedData = async () => {
        try {
          const persistedData = (await getItem(
            EnumIndexedDbStore.FILES,
            contextKey
          )) as PersistedData;
          if (
            persistedData &&
            persistedData.parsedResponse &&
            persistedData.files
          ) {
            dispatch({
              type: 'SET_FILES',
              payload: persistedData.files,
            });
            orderDispatch({
              type: 'SET_PARSED_EXCEL_DETAILS_RECRUITER',
              payload: persistedData.parsedResponse,
            });
          }
        } catch (error) {
          console.error('Error fetching persisted data:', error);
        }
      };

      fetchPersistedData();
    }, [contextKey, orderDispatch]);

    return (
      <Box className="w-[90vw] h-full mx-auto overflow-auto flex flex-col items-center justify-center gap-y-xl2">
        <Paper className="w-full p-xl3 rounded-lg" elevation={0}>
          <Box className="flex flex-row items-center justify-between gap-x-xl3">
            <Box className="max-w-[700px] flex flex-col gap-md">
              <Typography className="bk-typography-h6">
                Upload candidate details
              </Typography>
              <Typography className="bk-typography-body2">
                A template has been generated based on the package you have
                selected. Please download the excel file, fill in all the
                candidate details requested for in the excel. Upload the same
                excel below
              </Typography>
              <Typography className="bk-typography-body2">
                <strong>Note: </strong> Please do not edit the template. Name &
                Mobile number are mandatory to create valid candidate record,
                where mobile number is to be unique for each candidate.
              </Typography>
            </Box>
            <BKStyledButton
              size="large"
              variant="outlined"
              className="min-w-[260px]"
              startIcon={<Download />}
              onClick={downloadTemplate}
              disabled={state.isDownloadingTemplate}>
              {state.isDownloadingTemplate
                ? 'Downloading...'
                : 'Download CSV template'}
            </BKStyledButton>
          </Box>
          {!parsedExcelDetailsRecruiter && (
            <Box className="mt-xl2">
              <DropzoneFileUploader
                className="border-[2px] border-dashed border-bk_primary_light p-xl2 min-h-[30vh] flex items-center justify-center relative rounded-lg bg-bk_bg_primary_states_hover"
                label={
                  <Typography className="bk-typography-h6">
                    Drag & drop your file here or{' '}
                    <span className="text-bk_primary_main cursor-pointer">
                      Upload the template
                    </span>
                  </Typography>
                }
                description={
                  <Typography className="bk-typography-body2">
                    Download your .csv template from above and fill in the
                    candidate details precisely with each column
                  </Typography>
                }
                icon={
                  <DisplayImage
                    imageName={
                      EmployerAppAssetIconsFileNames.CUSTOM_FILE_UPLOAD_OUTLINE_SVG
                    }
                    imageType={DisplayImageType.ICON}
                  />
                }
                loaderLabel={
                  <Typography className="bk-typography-h6">
                    Your file is being uploaded, Please wait for few seconds
                  </Typography>
                }
                loaderDescription={
                  <Typography className="bk-typography-body2">
                    Once uploaded, kindly go through the number of candidates
                  </Typography>
                }
                onFilesChange={handleFilesChange}
                isUploading={state.isUploadingAndParsingExcel}
                allowedFileTypes={FILE_TYPE.CSV}
                fileSizeLimits={{
                  [MimeTypes['.csv']]: MAX_FILE_SIZE,
                  [MimeTypes['.xls']]: MAX_FILE_SIZE,
                  [MimeTypes['.xlsx']]: MAX_FILE_SIZE,
                }}
              />
            </Box>
          )}
        </Paper>
        {parsedExcelDetailsRecruiter && (
          <Paper className="w-full p-xl3 rounded-lg" elevation={0}>
            <Box className="flex flex-row items-center justify-between gap-y-xl3">
              <Box className="flex flex-row items-center justify-between gap-x-xxl">
                <Box className="flex flex-row items-center gap-x-xs3 self-start">
                  <FilePresent
                    sx={{
                      fontSize: SPACING.xl2,
                      color: COLORS.bk_selection_secondary,
                    }}
                  />
                  <Typography className="bk-typography-body1">
                    <span className="bk-typography-subTitle1 font-bold">
                      {parsedExcelDetailsRecruiter?.mediaDetails?.file_name}
                    </span>{' '}
                    has been uploaded
                  </Typography>
                </Box>
                <Box className="flex flex-col gap-y-xs2">
                  <Typography className="bk-typography-subTitle1 text-bk_bg_primary_dark">
                    Records found in excel:{' '}
                    <strong>{parsedExcelDetailsRecruiter?.totalItems}</strong>
                  </Typography>
                  <Typography className="bk-typography-subTitle1 text-bk_bg_primary_dark">
                    Valid record :{' '}
                    <strong>{parsedExcelDetailsRecruiter?.validRecords}</strong>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <BKStyledButton
                  className="bk-typography-btn-large text-bk_error_main min-w-[136px]"
                  size="large"
                  variant="text"
                  disabled={state.isDeletingFile}
                  startIcon={
                    <Delete
                      sx={{
                        fontSize: SPACING.xl2,
                        color: COLORS.bk_error_main,
                      }}
                    />
                  }
                  onClick={handleRemoveFile}>
                  Remove file
                </BKStyledButton>
              </Box>
            </Box>
          </Paper>
        )}
        {parsedExcelDetailsRecruiter && (
          <Box className="flex gap-sm items-center">
            <InfoOutlinedIcon className="text-bku_text_dark w-xl2 h-xl2" />
            <Typography className="bk-typography-body2 text-bku_text_dark max-w-[80%]">
              Please ensure that all mandatory fields are filled for all
              candidates. This will help us process the BGV request at the
              earliest or else our team will reach out to you if any
              insufficiencies / missing details.
            </Typography>
          </Box>
        )}
        {/* Modals section */}
        <ConfirmOrderCreationModal
          open={state.isOrderConfirmModalOpen}
          packageDetails={selectedPackage as IPackage}
          parsedExcelDetails={
            parsedExcelDetailsRecruiter as IParsedExcelDetails
          }
          mode={DataCollectionItemSource.EMPLOYER_BULK_UPLOAD}
          handleClose={handleCloseOrderConfirmModal}
          handleConfirm={handleConfirmOrderCreation}
        />
        <OrderCreatedSuccessModal
          open={state.isOrderSuccessModalOpen}
          handleClose={handleCloseOrderSuccessModal}
          handleCTA={handleOrderSuccessAction}
          mode={DataCollectionItemSource.EMPLOYER_BULK_UPLOAD}
        />
      </Box>
    );
  }
);

export default BgvRecruiterBulkUpload;
