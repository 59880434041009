import {
  DisplayImageType,
  EmployerAppAssetIconsFileNames,
} from '@gigin-work-space/utils';
import { Box, Button, Typography } from '@mui/material';
import { memo } from 'react';
import { DisplayImage } from 'src/app/components';

/* eslint-disable-next-line */
export interface NotFoundProps {
  handleRetry?: () => void;
  header: string;
  content: string;
  secondaryContent?: React.ReactNode;
  typography: string;
}

export function NotFound(props: NotFoundProps) {
  const { handleRetry, header, content, typography, secondaryContent } = props;

  return (
    <Box className="flex justify-center items-center flex-wrap py-xl2">
      <Box>
        <DisplayImage
          imageName={EmployerAppAssetIconsFileNames.PACKAGE_NOT_FOUND}
          imageType={DisplayImageType.ICON}
          width="300px"
          height="300px "
        />
      </Box>
      <Box className="max-w-[420px]">
        <Typography className={typography} gutterBottom>
          {header}
        </Typography>
        <Typography className="bk-typography-body2 text-bk_text_dark">
          {content}
        </Typography>
        {!secondaryContent && (
          <Button
            onClick={handleRetry}
            variant="text"
            color="primary"
            className="bk-typography-btn-small text-bk_mail_bg mt-lg flex items-center gap-xs">
            <DisplayImage
              imageName={EmployerAppAssetIconsFileNames.RETRY_PACKAGE}
              imageType={DisplayImageType.ICON}
              width="24px"
              height="24px"
            />{' '}
            Retry
          </Button>
        )}
        {secondaryContent && <Box className="mt-xl2"> {secondaryContent}</Box>}
      </Box>
    </Box>
  );
}

export default memo(NotFound);
