import { IOrderCompletionStatus } from '@gigin-work-space/model';
import { COLORS } from '@gigin-work-space/utils';
import { Box, Tooltip } from '@mui/material';

interface IStatusbar {
  orderCompleteStatus: IOrderCompletionStatus;
  isStepperStatusCompleted?: boolean;
}
export const BulkUploadProgress = ({
  orderCompleteStatus,
  isStepperStatusCompleted,
}: IStatusbar) => {
  const { success_percentage } = orderCompleteStatus;
  return (
    <Box className="bk-bulk-upload-progress-bar">
      <Tooltip title={''} placement="top" arrow>
        <span
          className="flex"
          style={{
            width: `${success_percentage}%`,
            background: isStepperStatusCompleted
              ? COLORS.bk_alert_success
              : COLORS.bk_tag_blue_light,
          }}></span>
      </Tooltip>
    </Box>
  );
};
