import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { ImageComponent } from './report-image';
import {
  reportStatusColorMapper,
  reportStatusLabelMapper,
  reportStatusTextMapper,
} from './report-status-mapper';

interface StatusTxtBtnProps {
  status: string;
}

const StatusTxtBtn = ({ status }: StatusTxtBtnProps) => {
  return (
    <View
      style={[
        styles.statusBtnContainer,
        { padding: 0, justifyContent: 'flex-start' },
      ]}>
      <ImageComponent
        src={reportStatusTextMapper(status?.toUpperCase())}
        style={[styles.statusBtnIconImage]}
      />
      <Text
        style={[
          styles.typov3BtnSmallText,
          { color: reportStatusColorMapper(status?.toUpperCase()) },
        ]}>
        {reportStatusLabelMapper(status?.toUpperCase())}
      </Text>
    </View>
  );
};

export default React.memo(StatusTxtBtn);
