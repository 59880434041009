import { permissionStore } from '@gigin-work-space/store';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { withViewPermission } from 'src/app/HOC';
import { useSnapshot } from 'valtio';
import { EBTabBar } from './common-employer-tab';
import EBHeader from './employer-bulk-header';
import EBCandidateDataManagement from './employer-candidate-details';

export const CDCUploadTab = () => {
  const {
    canUploadTemplate,
    canUpdateTemplate,
    canDeleteTemplate,
    canReadTemplate,
    canUploadDocument,
    canUpdateDocument,
    canDeleteDocument,
    canReadDocument,
  } = useSnapshot(permissionStore);
  // container responsive height
  // Define your desired initial height (e.g., 124px)
  const initialHeight = 124;
  // Use state to manage the container's height
  const [containerHeight, setContainerHeight] = useState(initialHeight);

  // Function to update the container's height based on the window width
  const updateContainerHeight = () => {
    const windowWidth = window.innerWidth;

    // Example: Adjust height for different screen widths
    if (windowWidth < 576) {
      setContainerHeight(80);
    } else if (windowWidth < 768) {
      setContainerHeight(100);
    } else {
      setContainerHeight(initialHeight);
    }
  };

  // Listen for window resize events to update the height
  useEffect(() => {
    window.addEventListener('resize', updateContainerHeight);
    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);
  // end responsive height

  const children = (
    <Grid container height={containerHeight} rowGap={1} sx={{ mt: 2, ml: 3 }}>
      <EBHeader />
      <Grid item width={'100%'}>
        <EBTabBar
          defaultIndex={0}
          vertical={false}
          listLabel={[
            // 'Order Overview',
            // 'Candidate Details',
            'Candidate Data management',
            // 'Verification details',
            // 'Invoice & Billing',
          ]}
          listComponent={[
            <EBCandidateDataManagement />,
            <></>,
            <></>,
            <></>,
            <></>,
          ]}
          disabled={[false, true, true, true, true]} // Use the provided disabled prop or default to false
        />
      </Grid>
    </Grid>
  );
  return withViewPermission({
    permissions: [
      canUploadTemplate,
      canUpdateTemplate,
      canDeleteTemplate,
      canReadTemplate,
      canUploadDocument,
      canUpdateDocument,
      canDeleteDocument,
      canReadDocument,
    ],
    children: children,
  });
};

export default CDCUploadTab;
