import { BKStyledStandardSelect } from '@gigin-work-space/common-ui';
import { permissionStore } from '@gigin-work-space/store';
import { COLORS } from '@gigin-work-space/utils';
import {
  Box,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { withViewPermission } from 'src/app/HOC';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import PackagesTable from './packages-table';
export interface CompanyPackagesProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`package-tabpanel-${index}`}
      aria-labelledby={`package-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `package-tab-${index}`,
    'aria-controls': `package-tabpanel-${index}`,
  };
}

export const CompanyPackages = () => {
  const [roleMappingSuggestion, setRoleMappingSuggestion] = useState(
    'Suggestive mapping to role'
  );
  const [activePackages, setActivePackages] = useState<any>(null);
  const [value, setValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { canReadPackage, canUpdatePackage, canCreatePackage } =
    useSnapshot(permissionStore);
  // function to get list of packages
  const getPackageList = useCallback(async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_PACKAGE_DETAILS);
      if (response.data.success) {
        setActivePackages(response.data.data);
        return;
      }
      throw new Error('Failed to get package details');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to get package details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [value]);

  // Function to change tabs - active, archive
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // function to change role mapping
  const handleRoleMappingChange = (event: SelectChangeEvent<unknown>) => {
    setRoleMappingSuggestion(event.target.value as string);
  };

  // Side effect to fetch package list on pageload and on tab switch
  useEffect(() => {
    if (canReadPackage || canUpdatePackage || canCreatePackage)
      getPackageList();
  }, [getPackageList]);

  const children = (
    <div className="w-full h-full mt-6 px-6">
      {/* Title Section */}
      <Typography className="bk-headline2">Packages</Typography>
      {/* suggestive role section */}
      <Box
        sx={{
          width: '100%',
          padding: '8px 12px 12px',
          border: `1px solid ${COLORS.bk_stroke_primary}`,
          marginTop: 4,
          borderRadius: '8px',
        }}>
        <Box sx={{ width: 'fit-content' }}>
          <FormControl fullWidth variant="standard">
            <BKStyledStandardSelect
              labelId="suggestive-role-select-label"
              id="suggestive-role-select-label"
              value={roleMappingSuggestion}
              label={roleMappingSuggestion}
              disableUnderline
              onChange={handleRoleMappingChange}>
              <MenuItem value={'Suggestive mapping to role'}>
                Suggestive mapping to role
              </MenuItem>
            </BKStyledStandardSelect>
          </FormControl>
        </Box>
        <Typography className="bK-body2">
          Your team will be able to select any package for a role during
          initiation
        </Typography>
      </Box>
      {/* Tab section with tables */}
      <Box sx={{ width: '100%', marginTop: '24px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="Packages Tab"
            sx={{ '& .MuiButtonBase-root': { textTransform: 'none' } }}>
            <Tab label="Active" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {activePackages && <PackagesTable packageList={activePackages} />}
        </TabPanel>
      </Box>
    </div>
  );
  return withViewPermission({
    permissions: [canReadPackage, canUpdatePackage, canCreatePackage],
    children: children,
  });
};
