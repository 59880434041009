import axios, { AxiosRequestConfig } from 'axios';
import { tenantState } from '../router/tenantState';
import { baseUrl } from './url-constants';

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// Add a method for making GET requests to an external URL
export const getExternal = async (url: any) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const auth_token = localStorage.getItem('auth_token');
    const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${trimmed_token}`,
      },
    };

    const response = await axios.get(url, config);

    return response;
  } catch (error) {
    throw error;
  }
};

axiosInstance.interceptors.request.use(
  config => {
    // Exclude /login path from adding the Authorization header
    if (config.url !== 'auth') {
      const auth_token = localStorage.getItem('auth_token');
      const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';

      if (trimmed_token) {
        config.headers['Authorization'] = `Bearer ${trimmed_token}`;
      }
    }
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['X-Tenant-Name'] = tenantState.tenantName;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.setItem('auth_token', '');
      // session expired screen come immediately
      window.location.reload();
    }
    // Reject the error to allow catch blocks to handle it
    return Promise.reject(error);
  }
);
