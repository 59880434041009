import { Box, Grid, styled, Theme } from '@mui/material';

export const StyledCompanyDetailsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '1rem !important',
  padding: '0.75rem 1rem !important',
  background: theme.customTheme?.color?.company_ribbon,
  borderRadius: theme.customTheme?.borderRadius?.custom_16,
}));

export const StyledTyphographyCompanyName = styled(Grid)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.bolder,
  fontSize: theme.customTheme?.fontSize?.small,
  color: theme.customTheme?.color?.black_rgb87,
  lineHeight: '157%',
  letterSpacing: '0.1px',
}));

export const StyledCompanyVerifed = styled(Grid)(
  ({
    theme,
    isCompanyVerified,
  }: {
    theme: Theme;
    isCompanyVerified: boolean;
  }) =>
    isCompanyVerified
      ? {
          fontFamily: theme.customTheme?.fontFamily?.inter,
          fontStyle: 'normal',
          fontSize: theme.customTheme?.fontSize?.xsmall,
          lineHeight: '166%',
          letterSpacing: '0.4px',
          fontWeight: theme.customTheme?.fontWeight?.normal,
          color: theme.customTheme?.color?.success_text,
        }
      : {
          fontFamily: theme.customTheme?.fontFamily?.inter,
          fontStyle: 'normal',
          fontSize: theme.customTheme?.fontSize?.xsmall,
          lineHeight: '166%',
          letterSpacing: '0.4px',
          fontWeight: theme.customTheme?.fontWeight?.normal,
          color: theme.customTheme?.color?.orange_warning,
        }
);

export const StyledTyphographyCreatedAt = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: theme.customTheme?.fontFamily.inter,
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fontSize: theme.customTheme?.fontSize?.xsmall,
  lineHeight: '166%',
  letterSpacing: '0.4px',
  color: theme.customTheme?.color?.black_rgb6,
}));

export const StyledTyphographyTotalmember = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  fontFamily: theme.customTheme?.fontFamily?.inter,
  fontStyle: 'normal',
  fontWeight: theme.customTheme?.fontWeight?.normal,
  fontSize: theme.customTheme?.fontSize?.xsmall,
  lineHeight: '166%',
  letterSpacing: '0.4px',
  color: theme.customTheme?.color?.black_rgb87,
}));
