export const toCamelCase = (sentence: string) => {
  if (!sentence) return '';

  return sentence
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
