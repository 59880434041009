import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { ImageComponent } from './report-image';

export interface IndividualCheckDocumentsProps {
  checkData: any;
  subCheckData: any;
}

interface DocumentContainerHOCProps {
  check_id: string;
  title: string;
  imageUrl: string;
  documentsUploaded: boolean;
  firstDocumentId: string;
}

const DocumentContainerHOC = (props: DocumentContainerHOCProps) => {
  const { check_id, title, imageUrl, documentsUploaded, firstDocumentId } =
    props;

  return (
    <View
      style={[styles.leftBorderedContainer, styles.stackColumnContainer]}
      wrap={false}
      id={firstDocumentId}>
      {/* Header */}
      <View style={[styles.documentHeaderContainer]} fixed>
        <Text style={styles.textSubHeading1}>
          {title} - Supporting document
        </Text>
        <Text style={[styles.textSubHeading3, styles.textSecondary]}>
          Check ID: {check_id}
        </Text>
      </View>
      {/* Documents media section */}
      <View
        style={[
          styles.checkCommentContainer,
          {
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 6,
          },
        ]}>
        <ImageComponent
          src={imageUrl}
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: 500,
            maxHeight: 500,
            objectFit: 'contain',
          }}
        />
        {!documentsUploaded && (
          <Text style={[styles.textSubHeading1]}>No documents uploaded</Text>
        )}
      </View>
    </View>
  );
};

export const IndividualCheckDocuments = (
  props: IndividualCheckDocumentsProps
) => {
  const { checkData, subCheckData } = props;

  return (
    <View>
      {/* // If no documents then show illustration of no document */}
      {/* TODO NEED TO REVIEW THIS SECTION */}
      {/* {subCheckData.documents_provided.length === 0 && (
        <DocumentContainerHOC
          check_id={subCheckData.check_number}
          title={checkData.title}
          imageUrl={noDocumentsUploaded}
          documentsUploaded={false}
          firstDocumentId={subCheckData.first_document_id}
        />
      )} */}
      {/* // If documents then map the list and show documents */}
      {subCheckData.documents_provided.length > 0 &&
        subCheckData.documents_provided.map((row: any) => (
          <DocumentContainerHOC
            check_id={subCheckData.check_number}
            title={checkData.title}
            imageUrl={row.file_url}
            documentsUploaded={true}
            firstDocumentId={subCheckData.first_document_id}
          />
        ))}
    </View>
  );
};
