import {
  AUTO_HIDE_TIMEOUT,
  COLORS,
  IMAGE_AND_FILE_ACCEPTED,
  ONE_MB,
  SPACING,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneFileUploaderProps {
  label: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  onFilesChange: (files: File[]) => void;
  allowedFileTypes?: string[];
  className?: string;
  multiple?: boolean;
  fileSizeLimits: { [key: string]: number };
  isUploading?: boolean;
  loaderLabel?: React.ReactNode;
  loaderDescription?: React.ReactNode;
}

export const DropzoneFileUploader: React.FC<DropzoneFileUploaderProps> = React.memo(
  ({
    label,
    description,
    icon,
    onFilesChange,
    allowedFileTypes = IMAGE_AND_FILE_ACCEPTED, // Default allowed types
    className = '',
    multiple = false,
    fileSizeLimits,
    isUploading = false,
    loaderLabel,
    loaderDescription,
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [, setFiles] = useState<File[]>([]);

    const handleFileUpload = useCallback(
      (acceptedFiles: File[]) => {
        setUploadProgress(0);

        const validFiles = acceptedFiles.filter((file) => {
          const fileTypeLimit = fileSizeLimits[file.type];
          if (file.size > fileTypeLimit) {
            enqueueSnackbar(`File ${file.name} exceeds the size limit of ${fileTypeLimit / ONE_MB}MB`, {
              variant: VARIANT_TYPE.ERROR,
              autoHideDuration: AUTO_HIDE_TIMEOUT.LONGER,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            });
            return false;
          }
          return true;
        });

        setFiles(validFiles);
        onFilesChange(validFiles);
        setUploadProgress(null);
      },
      [allowedFileTypes, onFilesChange, fileSizeLimits, enqueueSnackbar],
    );

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      accept: Array.isArray(allowedFileTypes)
        ? allowedFileTypes.reduce((acc: { [key: string]: any[] }, type) => {
            acc[type] = [];
            return acc;
          }, {})
        : allowedFileTypes,
      multiple,
      onDrop: handleFileUpload,
      onDropRejected: (rejectedFiles) => {
        rejectedFiles.forEach((file) => {
          enqueueSnackbar(`File ${file.file.name} was rejected.`, {
            variant: VARIANT_TYPE.ERROR,
            autoHideDuration: AUTO_HIDE_TIMEOUT.LONGER,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          });
        });
        onFilesChange([]);
      },
    });

    return (
      <Box {...getRootProps()} className={className}>
        <input {...getInputProps()} />
        {uploadProgress !== null || isUploading ? (
          <Box className="flex flex-col items-center justify-center gap-y-md bg-transparent">
            {<CircularProgress sx={{ fontSize: SPACING.xxl2, color: COLORS.bk_primary_light }} />}
            <>
              {loaderLabel}
              {loaderDescription}
            </>
          </Box>
        ) : (
          <Box className="flex flex-col items-center justify-center gap-y-md bg-transparent">
            {icon ?? <CloudUploadIcon sx={{ fontSize: SPACING.xxl, color: COLORS.bk_primary_light }} />}
            <>
              {label}
              {description}
            </>
          </Box>
        )}
      </Box>
    );
  },
);
