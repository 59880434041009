import { capitalizeString } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';
import { checkInProgress } from './image-library';
import { ImageComponent } from './report-image';
import {
  reportStatusIconMapper,
  reportStatusMapper,
} from './report-status-mapper';

export interface IndividualCheckSummaryProps {
  checkData: any;
  subCheckData: any;
}

export const IndividualCheckSummary = (props: IndividualCheckSummaryProps) => {
  const { checkData, subCheckData } = props;

  // function to apply subtitle style based on status
  const handleSubtitleStyle = (status: string) => {
    switch (status) {
      case 'CLEAR':
        return [styles.textBody1, styles.clear];
      case 'DISCREPANT':
        return [styles.textBody1, styles.discrepant];
      case 'INSUFFICIENT':
        return [styles.textBody1, styles.insufficient];
      case 'NO_RESPONSE':
        return [styles.textBody1, styles.noResponse];
      case 'UNABLE_TO_VERIFY':
        return [styles.textBody1, styles.noResponse];
      default:
        return styles.textBody1;
    }
  };

  return (
    <View
      style={styles.leftBorderedContainer}
      wrap={false}
      id={
        subCheckData.check_number === checkData.first_subcheck_id
          ? subCheckData.check_number
          : null
      }>
      <View style={styles.stackColumnContainer}>
        {/* Check detail and check status section */}
        <View style={styles.checkInfoContainer}>
          <View style={styles.checkLogoContainer}>
            <ImageComponent
              src={subCheckData.meta.icon}
              style={styles.summaryIconImage}
            />
          </View>
          <View style={styles.checkDetailsContainer}>
            <View style={[styles.stackColumnContainer, { gap: 4 }]}>
              <Text style={[styles.textSubHeading3, styles.textSecondary]}>
                Check ID: {subCheckData.check_number}
              </Text>
              <Text style={styles.textHeadLine2}>
                {subCheckData.meta.title}
              </Text>
              <Text style={styles.textBody3}>
                {capitalizeString(subCheckData.meta.subtitle)}
              </Text>
            </View>
            <ImageComponent
              src={reportStatusMapper(subCheckData.check_status.status)}
              style={[styles.pillImage]}
            />
          </View>
        </View>
        {/* If check in progress or details are not present then show in progress illustration */}
        {subCheckData.check_status.status === 'INPROGRESS' &&
          (subCheckData.details.length === 0 ? (
            <View
              style={[
                styles.checkCommentContainer,
                { alignItems: 'center', justifyContent: 'center', margin: 0 },
              ]}>
              <ImageComponent
                src={checkInProgress}
                style={{
                  maxWidth: 480,
                  maxHeight: 300,
                  objectFit: 'contain',
                  padding: 32,
                }}
              />
              {subCheckData.check_status.status === 'UNABLE_TO_VERIFY' ? (
                <Text style={styles.textSubHeading1}>Unable to Verify</Text>
              ) : (
                <Text style={styles.textSubHeading1}>
                  This check is still in progress
                </Text>
              )}
            </View>
          ) : (
            <Text style={styles.textHeadLine1}>
              This check is still in progress
            </Text>
          ))}
        {/* Check Comments section */}
        {subCheckData.meta.messages && (
          <View style={styles.checkCommentContainer}>
            <Text style={[styles.textBody1]}>
              {subCheckData.meta.messages.title}
            </Text>
            {subCheckData.meta.messages.subtitle && (
              <Text
                style={handleSubtitleStyle(subCheckData.check_status.status)}>
                {subCheckData.meta.messages.subtitle}
              </Text>
            )}
            {subCheckData?.meta?.messages?.additional_point?.map(
              (data: any) => (
                <View
                  style={[styles.iconTextContainer, { marginLeft: 4 }]}
                  key={data}>
                  <ImageComponent
                    src={reportStatusIconMapper(data.type)}
                    style={styles.iconImageSmall}
                  />
                  <Text style={[styles.textBody2]}>{data.message}</Text>
                </View>
              )
            )}
          </View>
        )}
      </View>
    </View>
  );
};
