import { BKStyledTableCell } from '@gigin-work-space/common-ui';
import {
  AccountWithPackages,
  AUTO_HIDE_TIMEOUT,
  COLORS,
  ROWS_PER_PAGE_OPTIONS,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';

import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { HttpStatus } from '@nestjs/common';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import { AddAccount } from './components/add-account-form';

export const ClientList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [clients, setClients] = useState<AccountWithPackages[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseAfterSuccess = () => {
    handleClose();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.LIST_OF_CLIENT_WITH_PACKAGE
      );
      if (response.status === HttpStatus.OK) {
        setClients(response.data);
        setTotalPage(response.data.length);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch list of client', {
        variant: VARIANT_TYPE.ERROR,
        autoHideDuration: AUTO_HIDE_TIMEOUT.DEFAULT,
      });
    }
  };

  useMemo(() => setTotalPage(clients.length), [clients]);
  useEffect(() => {
    fetchClients();
  }, []);
  return (
    <Box>
      <Box className="sticky top-0 z-10 bg-bk_bg_primary">
        <Grid className="flex justify-between items-center p-4">
          <Typography className="bk-headline1">Client List</Typography>
        </Grid>
        <Divider />
      </Box>
      <Grid
        container
        columnSpacing={1}
        className="px-8 pt-2"
        justifyContent={'flex-end'}>
        <Button
          variant="text"
          color="primary"
          sx={{ textDecoration: 'capitalization' }}
          onClick={handleOpen}>
          Add Account
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { paddingTop: '64px', paddingBottom: '64px' },
          }}>
          <AddAccount onSuccess={handleCloseAfterSuccess} />
        </Dialog>
        <TableContainer className="bk-ops-table-container">
          <Table
            stickyHeader
            aria-label="sticky table"
            padding="none"
            size="small"
            sx={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              '& .MuiTableCell-root': {
                paddingX: '8px !important',
              },
            }}>
            <TableHead
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
                boxShadow: '3px -4px 10px 1px gray',
                backgroundColor: COLORS.bk_bg_primary_smoke,
                '& .MuiTableCell-head': {
                  padding: '8px !important',
                },
              }}>
              <TableRow>
                <BKStyledTableCell>
                  <Typography className="bk-sub-heading2">
                    Company Name
                  </Typography>
                </BKStyledTableCell>
                <BKStyledTableCell>
                  <Typography className="bk-sub-heading2">Packages</Typography>
                </BKStyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className="pl-12">
              {clients &&
                clients.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '& .MuiTypography-root': {
                        padding: '8px !important',
                      },
                    }}>
                    <BKStyledTableCell>
                      <Typography className="bK-body1">
                        {item.accountName}
                      </Typography>
                    </BKStyledTableCell>
                    <BKStyledTableCell>
                      {item?.packages.length > 2 ? (
                        <Typography className="bK-body1">
                          {`${item?.packages
                            .slice(0, 2)
                            .map(packageItem => packageItem?.package_title)
                            .join(', ')}, and ${
                            item?.packages.length - 2
                          } more packages`}
                        </Typography>
                      ) : (
                        <Typography className="bK-body1">
                          {item?.packages
                            .map(packageItem => packageItem?.package_title)
                            .join(', ')}
                        </Typography>
                      )}
                    </BKStyledTableCell>
                  </TableRow>
                ))}

              <TableRow>
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  count={totalPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="bk-all-cases-table-pagination-grid fixed"
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};
