import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { CheckoutStore } from '@gigin-work-space/store';
import { COLORS, borderLessSelect } from '@gigin-work-space/utils';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { DisplayImage } from 'src/app/components';
import { LegendPosition, indexAxis } from '../analytics-page/IAnalytics';
import { DashboardAnalyticsData } from './IAnalytics-dashboard';
// Register chartjs
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export interface AnalyticsDashboardProps {
  analytics: DashboardAnalyticsData;
  filterDays: number;
  // eslint-disable-next-line no-unused-vars
  updateFilterDate: (selectedValue: number) => void;
}

export function AnalyticsDashboard({
  analytics,
  filterDays,
  updateFilterDate,
}: AnalyticsDashboardProps) {
  // theme Break points for responsive
  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedValue: number = parseInt(event.target.value as string, 10);
    updateFilterDate(selectedValue);
    CheckoutStore.FilterDays = selectedValue;
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as LegendPosition,
        labels: {
          usePointStyle: true, // Use a point style legend
        },
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    cutout: 90,
  };

  const labels = [
    'Invite sent',
    'Data collected',
    'Screening Completed',
    'QC Completed',
  ];

  const barOptions = {
    indexAxis: 'x' as indexAxis,

    scales: {
      x: {
        stacked: true,
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    barThickness: 16,
  };

  // sample data to show on chart
  const data = {
    labels: ['Green case', 'Red case', 'Unsure', 'In progress'],
    datasets: [
      {
        label: ' No of candidate',
        data: [
          analytics?.candidates_stats?.case_wise_count?.status_count_list
            ?.CLEAR,
          analytics?.candidates_stats?.case_wise_count?.status_count_list
            ?.DISCREPANT,
          analytics?.candidates_stats?.case_wise_count?.status_count_list
            ?.INSUFFICIENT,
          analytics?.candidates_stats?.case_wise_count?.status_count_list
            ?.NO_RESPONSE,
        ],
        backgroundColor: ['#33B007', '#B04407', '#E4CF0E', '#EBEBEB'],
      },
    ],
  };

  const barData = {
    labels,
    datasets: [
      {
        label: 'Progress',
        data: [
          analytics?.candidates_stats?.progress_wise_count?.INVITE_SEND
            ?.completed,
          analytics?.candidates_stats?.progress_wise_count?.DATACOLLECTION
            ?.completed,
          analytics?.candidates_stats?.progress_wise_count?.VERIFICATION
            ?.completed,
          analytics?.candidates_stats?.progress_wise_count?.QUALITY_CHECK
            ?.completed,
        ],
        backgroundColor: '#659CF5',
      },
      {
        label: 'Blocked',
        data: [
          analytics?.candidates_stats?.progress_wise_count?.INVITE_SEND
            ?.pending,
          analytics?.candidates_stats?.progress_wise_count?.DATACOLLECTION
            ?.blocked,
          analytics?.candidates_stats?.progress_wise_count?.VERIFICATION
            ?.blocked,
          analytics?.candidates_stats?.progress_wise_count?.QUALITY_CHECK
            ?.pending,
        ],
        backgroundColor: '#EE6E12',
      },
    ],
  };

  return (
    <Box className="flex flex-col h-[80vh] gap-sm overflow-y-scroll pb-[10vh]">
      <Paper
        elevation={0}
        sx={{
          borderRadius: '8px',
        }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            p: 2,
            pb: 1.5,
            borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
          }}>
          <Typography className="bk-sub-heading2">
            Verification status
          </Typography>
          <FormControl variant="standard" sx={{ mr: 1, minWidth: 120 }}>
            <Select
              id="filter.recent"
              name="filter.recent"
              value={filterDays}
              onChange={handleChange}
              className="bK-btn-small"
              sx={borderLessSelect}>
              <MenuItem value={15}>Last 15 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={60}>Last 2 months</MenuItem>
              <MenuItem value={90}>Last 3 months</MenuItem>
              <MenuItem value={180}>Last 6 months</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {analytics ? (
          <Grid container sx={{ mt: '40px', mx: '10px', mb: 4 }}>
            {/* <Grid item> */}
            <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
              <Typography className="bk-headline2 mb-6" sx={{ ml: '12%' }}>
                Total Candidates{' '}
                {
                  analytics?.candidates_stats?.case_wise_count
                    ?.total_number_of_candidates
                }
              </Typography>
              <Doughnut data={data} options={options} width={300} height={300}>
                {/* Totalcount {analytics?.status_wise_count?.total_count} */}
              </Doughnut>
              {/* <Doughnut data={data} options={options} /> */}
            </Grid>
            {/* </Grid> */}
            <Grid
              item
              container
              xs={12}
              md={7}
              justifyContent={'center'}
              direction={'column'}
              rowGap={'40px'}
              sx={{ ml: isNotExtraLargeScreen ? '5%' : '0%' }}>
              <Grid item>
                <Stack direction={'row'}>
                  <Stack
                    sx={{
                      background: '#F1F1F1',
                      borderRadius: '50%',
                      width: '100px',
                      mr: 2,
                    }}></Stack>
                  {/* <Stack direction={'column'} spacing={0.25}>
                    <Typography className="bk-sub-heading2">
                      {analytics?.candidates_stats?.time_compliance?.value}%
                    </Typography>
                    <Typography className="bK-body2">
                      Time compliance
                    </Typography>
                  </Stack> */}
                </Stack>
              </Grid>
              <Grid
                item
                style={{
                  width: isNotExtraLargeScreen ? '450px' : '550px',
                  height: '300px',
                }}>
                <Bar
                  options={{ ...barOptions }}
                  data={barData}
                  width={isNotExtraLargeScreen ? 450 : 500}
                  height={300}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <ChartEmptyState
              // maxHeight={'100vh'}
              imageComponent={
                <DisplayImage
                  imageName="vsd-chart.svg"
                  imageType="icon"
                  style={{
                    height: '300px',
                    width: 'auto',
                  }}
                />
              }
              description="Analyze the the volume of verification requested across roles"
            />
          </Box>
        )}
      </Paper>
      <Paper
        elevation={0}
        sx={{
          borderRadius: '8px',
        }}>
        {analytics ? (
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className="py-md px-xl3">
            {/* data collection pending */}
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <DisplayImage
                imageName="data-collection-progress.svg"
                imageType="icon"
                width="48px"
                height="48px"
              />
              <Stack direction={'column'} spacing={0.5}>
                <Typography className="bk-sub-heading2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.data_collection_pending?.count
                  }
                </Typography>
                <Typography className="bK-body2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.data_collection_pending?.name
                  }
                </Typography>
              </Stack>
            </Stack>
            {/* Check in progress*/}
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <DisplayImage
                imageName="checkin-progress.svg"
                imageType="icon"
                width="48px"
                height="48px"
              />
              <Stack direction={'column'} spacing={0.5}>
                <Typography className="bk-sub-heading2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.check_in_progress?.count
                  }
                </Typography>
                <Typography className="bK-body2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.check_in_progress?.name
                  }
                </Typography>
              </Stack>
            </Stack>
            {/* Blockers */}
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <DisplayImage
                imageName="warning-rounded.svg"
                imageType="icon"
                width="48px"
                height="48px"
              />
              <Stack direction={'column'} spacing={0.5}>
                <Typography className="bk-sub-heading2">
                  {
                    analytics?.candidates_stats?.overall_stats_count?.blockers
                      ?.count
                  }
                </Typography>
                <Typography className="bK-body2">
                  {
                    analytics?.candidates_stats?.overall_stats_count?.blockers
                      ?.name
                  }
                </Typography>
              </Stack>
            </Stack>
            {/* Report genarated */}
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <DisplayImage
                imageName="report-generated-round.svg"
                imageType="icon"
                width="48px"
                height="48px"
              />
              <Stack direction={'column'} spacing={0.5}>
                <Typography className="bk-sub-heading2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.report_generated?.count
                  }
                </Typography>
                <Typography className="bK-body2">
                  {
                    analytics?.candidates_stats?.overall_stats_count
                      ?.report_generated?.name
                  }
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <section className="p-4">
            <div className="bg-bk_tag_gray_bg w-full h-28 rounded-2xl"></div>
          </section>
        )}
      </Paper>
    </Box>
  );
}

export default AnalyticsDashboard;
