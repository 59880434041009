import { WHITELIST_URLS } from '@gigin-work-space/utils';
import { Image } from '@react-pdf/renderer';
export interface ImageProps {
  src: string;
  style: object;
}

export const ImageComponent = ({ src, style }: ImageProps) => {
  const auth_token = localStorage.getItem('auth_token');
  const trimmed_token = auth_token ? auth_token.replace(/"/g, '') : '';

  const isWhitelisted = WHITELIST_URLS.some(whitelistUrl =>
    src?.startsWith(whitelistUrl)
  );

  if (isWhitelisted) {
    return <Image style={style} src={src} />;
  }

  const headers = {
    Authorization: `Bearer ${trimmed_token}`,
  };

  return (
    <Image
      style={style}
      source={{
        uri: src,
        method: 'GET',
        body: null,
        headers: headers,
        credentials: 'include',
      }}
    />
  );
};
