import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Stack } from '@mui/material';
/* eslint-disable-next-line */
type anchorType = 'top' | 'right' | 'bottom' | 'left';

export interface DrawerComponentProps {
  children: React.ReactNode;
  anchor: anchorType;
  open: boolean;
  onClose: () => void;
}

export function DrawerComponent({ children, anchor, open, onClose }: DrawerComponentProps) {
  return (
    <Box>
      {' '}
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        <Box sx={{ width: '60vw' }}>
          <Stack direction={'row'} m={'1rem'}>
            <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'grey' } }} />
          </Stack>
          <Stack direction={'column'}>{children}</Stack>
        </Box>
      </Drawer>
    </Box>
  );
}

export default DrawerComponent;
