import { Box } from '@mui/material';
import React, { useState } from 'react';
import { JobHeader } from '../../components';

interface IndividualCaseDetailHOCProps {
  children: React.ReactNode;
}

export const IndividualCaseDetailHOC = (
  props: IndividualCaseDetailHOCProps
) => {
  const { children } = props;
  const [isSideBaseActive, setSideBaseActive] = useState<boolean>(false);
  const updateSideBaseStatus = (isActive: boolean) => {
    setSideBaseActive(isActive);
  };
  return (
    <Box sx={{ height: '100vh' }}>
      <JobHeader
        updateSideBaseStatus={updateSideBaseStatus}
        sideBarState={isSideBaseActive}
      />
      <Box sx={{ display: 'flex', height: '100%', width: '100%', pt: '3rem' }}>
        {/* Main Section START */}
        <main className="w-full h-full overflow-auto">{children}</main>
        {/* Main Section END */}
      </Box>
    </Box>
  );
};
