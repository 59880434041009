import { Box, Divider, Grid, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import {
  DataCollectionStatusStore,
  updateDataCollectionStatus,
} from 'src/app/utils/store';
import { CheckHeader } from './check-header';
import { ParameterTable } from './parameter-table';
import ReportAdditionalDetails from './report-additional-details';
import SupportDocumentComponent from './support-document';

interface CheckDetailHOCProps {
  checkData: any;
}
export interface DocumentListItem {
  file_url: string;
  type: string;
  document_id: string;
  deleted?: boolean;
}

interface IDataCollectionCheckListStatus {
  collectionType: string;
  collectionGroupType: string;
  status: string;
  serviceRequestItemId: string;
  _id: string;
}
export const CheckDetailHOC = (props: CheckDetailHOCProps) => {
  const { checkData } = props;
  const { order_request_id } = checkData;
  const check_id = checkData?._id;
  const [checkDataState, setCheckDataState] = useState<any>(null);
  const [parameterList, setParameterList] = useState<any[]>([]);
  const [selectedReportStatus, setSelectedReportStatus] = useState<string>('');
  const [filteredChecksState, setFilteredChecksState] = useState<any>([]);

  checkData.listOfCheckStatus
    .map((item: IDataCollectionCheckListStatus) => {
      if (item.serviceRequestItemId === checkData._id) {
        updateDataCollectionStatus(item.status);
        return item.status;
      }

      return undefined;
    })
    .filter(Boolean);

  const [documentList, setDocumentList] = useState<DocumentListItem[]>([
    // Your initial documentList data
  ]);
  const updateCheckDataDocumentList = (newDocumentList: DocumentListItem[]) => {
    setCheckDataState((prevCheckDataState: any) => ({
      ...prevCheckDataState,
      report: {
        ...prevCheckDataState.report,
        documentList: newDocumentList,
      },
    }));
  };

  const updateNewParameterList = (newParameterList: any[]) => {
    checkDataState.report.parameter_list.details = newParameterList;
    setCheckDataState(checkDataState);
  };
  const getCheckDetail = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.GET_CHECK_DETAIL}/${order_request_id}/${check_id}`
      );
      setCheckDataState(response.data);

      return;
    } catch (error) {
      enqueueSnackbar('Failed to fetch check detail', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [order_request_id, check_id]);

  const fetchCheckDetails = async () => {
    try {
      const { data: checkInfo } = await axiosInstance.get(
        `${endpoints.CASE_INFO}/opps/${order_request_id}`
      );

      const filteredChecks = checkInfo.data
        .filter(({ remark }: { remark: string }) => remark.length > 0)
        .map(
          ({
            order_service_verification_status: checkStatus,
            check_id: { product_listing_name: check },
            remark,
          }: {
            order_service_verification_status: string;
            check_id: { product_listing_name: string };
            remark: string;
          }) => ({
            checkStatus,
            check,
            remark,
          })
        );
      setFilteredChecksState(filteredChecks);
      return filteredChecks;
    } catch (error) {
      enqueueSnackbar('Error fetching check details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      throw error;
    }
  };
  useEffect(() => {
    getCheckDetail();
    fetchCheckDetails();
  }, [order_request_id, check_id]);

  useEffect(() => {
    if (checkDataState) {
      setParameterList(checkDataState?.report?.parameter_list?.details || []);
      setDocumentList(checkDataState?.report?.documentList || []);
    }
  }, [checkDataState, setDocumentList]);

  const handleSubmit = async (reportStatus: string) => {
    try {
      checkDataState.report.status.status = reportStatus;
      const response = await axiosInstance.patch(
        `${endpoints.UPDATE_CHECK_DETAIL}/${order_request_id}/${check_id}`,
        checkDataState
      );

      if (response.data) {
        enqueueSnackbar('Data updated successful', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        getCheckDetail();
      }
    } catch (error) {
      enqueueSnackbar('Failed to update data. Please try again.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleReportStatusChange = async (selectedReportStatus: string) => {
    setSelectedReportStatus(selectedReportStatus);
  };

  return (
    <Box className="w-full h-[100vh] relative flex-grow">
      {/* Header section */}
      {checkDataState ? (
        <CheckHeader
          onSubmit={handleSubmit}
          onReportStatusChange={handleReportStatusChange}
          checkTitle={checkData?.check_id?.product_listing_name}
          checkId={checkDataState?.report?.check_number_text}
          report_status={checkDataState?.report?.status.status}
          dataCollectionStatus={
            DataCollectionStatusStore.DataCollectionStatusStore
          }
          checkStatus={filteredChecksState}
        />
      ) : (
        <div>Loading...</div>
      )}
      <Box className="bk-parameter-table">
        {/* Parameter & document section */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className="bk-sub-heading2">Parameter Table</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* Parameter table section */}
          <Grid item xs={8}>
            {parameterList ? (
              <ParameterTable
                parameterList={parameterList}
                setParameterList={setParameterList}
                updateNewParameterList={updateNewParameterList}
                selectedReportStatus={selectedReportStatus}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Grid>
          {/* Document dropzone section */}
          <Grid item xs={4} className="relative right-[15%]">
            {documentList.length > 0 ? (
              <SupportDocumentComponent
                documentList={documentList}
                setDocumentList={setDocumentList} // Pass the setDocumentList function
                updateCheckDataDocumentList={updateCheckDataDocumentList}
              />
            ) : (
              <SupportDocumentComponent
                documentList={documentList}
                setDocumentList={setDocumentList} // Pass the setDocumentList function
                updateCheckDataDocumentList={updateCheckDataDocumentList}
              />
            )}
          </Grid>
        </Grid>
        {/* Meta section */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="bk-sub-heading2">
              Additional Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {checkDataState ? (
              <ReportAdditionalDetails
                reportAdditionalDetail={checkDataState}
                setCheckDataState={setCheckDataState}
              />
            ) : (
              <div>Loading...</div>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
