import { BKStyledSmallSelectGroup } from '@gigin-work-space/common-ui';
// import { DateTypeLabel, formatDateByYear } from '@gigin-work-space/utils';
import { RemoveRedEye } from '@mui/icons-material';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import CircularIndeterminate from '../../bgv/component/skelton/bgv-loader';
import PreviewDialog from './previewDailog';

interface ParameterTableProps {
  parameterList: any[];
  setParameterList: React.Dispatch<React.SetStateAction<any[]>>;
  // eslint-disable-next-line no-unused-vars
  updateNewParameterList: (newParameterList: any[]) => void;
  selectedReportStatus: string;
}

export const ParameterTable = (props: ParameterTableProps) => {
  const { parameterList, setParameterList, selectedReportStatus } = props;

  const [toggleCommentSection, setToggleCommentSection] = useState(false);
  const [individualStatuses, setIndividualStatuses] = useState<string[]>([]);
  const [manuallyUpdatedIndices, setManuallyUpdatedIndices] = useState<
    number[]
  >([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<string | null>(null);

  const handleToggleParameterNameVisibility = (index: number) => {
    const clonedParameterList = [...parameterList];

    clonedParameterList[index].fieldVisibility =
      !clonedParameterList[index].fieldVisibility;
    setParameterList(clonedParameterList);
  };

  const handleChangeParameterStatus = (
    event: SelectChangeEvent<any>,
    index: number
  ) => {
    const { value } = event.target;
    setIndividualStatuses(prevStatuses => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = value;
      return newStatuses;
    });

    setParameterList(prevParameterList => {
      const clonedParameterList = [...prevParameterList];

      clonedParameterList[index].detail.field_status = value;

      return clonedParameterList;
    });

    setManuallyUpdatedIndices(prevIndices => [...prevIndices, index]);
  };

  const handleChangeParameterDetail = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;
    const clonedParameterList = [...parameterList];
    clonedParameterList[index].detail.value = value;
    setParameterList(clonedParameterList);
  };

  const clonedParameterList = [...parameterList];
  clonedParameterList.forEach((item, index) => {
    if (!manuallyUpdatedIndices.includes(index)) {
      item.detail.field_status =
        selectedReportStatus || item.detail.field_status;
    }
  });

  const handleChangeComment = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;
    const clonedParameterList = [...parameterList];
    clonedParameterList[index].detail.message.comment = value;
    setParameterList(clonedParameterList);
  };

  if (!parameterList) {
    return <CircularIndeterminate />;
  }

  const handleIconClick = (url: string) => {
    setDialogContent(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent(null);
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      {parameterList.map((parameterItem, index) => (
        <Grid container spacing={3} key={index} className="flex min-h-[90px]">
          <Grid item xs={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={parameterItem?.fieldVisibility}
                    onChange={() => handleToggleParameterNameVisibility(index)}
                    tabIndex={-1}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
          <Grid item container xs={7} spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label={parameterItem?.parametersLevel}
                  name="parameterDetailValue"
                  size="small"
                  value={parameterItem?.detail?.value}
                  onChange={(event: any) =>
                    handleChangeParameterDetail(event, index)
                  }
                  margin="none"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <BKStyledSmallSelectGroup fullWidth>
                  <InputLabel id="bullet">Status</InputLabel>
                  <Select
                    labelId="select-status"
                    id="select-status"
                    value={
                      individualStatuses[index] ||
                      parameterItem.detail.field_status
                    }
                    label="Status"
                    size="small"
                    onChange={(event: SelectChangeEvent<any>) =>
                      handleChangeParameterStatus(
                        event as ChangeEvent<HTMLInputElement>,
                        index
                      )
                    }>
                    <MenuItem value={'CLEAR'}>Clear</MenuItem>
                    <MenuItem value={'DISCREPANT'}>Discrepant</MenuItem>
                    <MenuItem value={'INSUFFICIENT'}>Insufficient</MenuItem>
                    <MenuItem value={'NO_RESPONSE'}>No Response</MenuItem>
                    <MenuItem value={'INPROGRESS'}>Inprogress</MenuItem>
                    <MenuItem value={'UNABLE_TO_VERIFY'}>
                      Unable to Verify
                    </MenuItem>
                  </Select>
                </BKStyledSmallSelectGroup>
              </Grid>
              <Grid item xs={1}>
                {!toggleCommentSection && (
                  <IconButton
                    onClick={() => setToggleCommentSection(true)}
                    tabIndex={-1}>
                    <DisplayImage
                      imageName="comment-icon.svg"
                      imageType="icon"
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={1}>
                {parameterItem?.detail?.value &&
                  isValidUrl(parameterItem?.detail?.value) && (
                    <IconButton
                      onClick={() =>
                        handleIconClick(parameterItem.detail.value)
                      }>
                      <RemoveRedEye />
                    </IconButton>
                  )}
              </Grid>
            </Grid>
            {toggleCommentSection && (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={11}>
                  {' '}
                  <TextField
                    label="Comment"
                    name="parameterDetailComment"
                    size="small"
                    value={parameterItem?.detail?.message?.comment}
                    onChange={(event: any) => handleChangeComment(event, index)}
                    style={{ marginBottom: '20px' }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <PreviewDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        contentUrl={dialogContent}
      />
    </>
  );
};
