import { ChartEmptyState } from '@gigin-work-space/common-ui';
import { COLORS } from '@gigin-work-space/utils';
import { Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { IVsdAll } from './IAnalytics';
import { enqueueSnackbar } from 'notistack';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Set display to false to hide x-axis grid lines
      },
    },
    y: {
      beginAtZero: true,
      // defining min and max so hiding the dataset does not change scale range
      min: 0,
      max: 120,
    },
  },
  maintainAspectRatio: false, //to maintain custom chart height
};

export function TimeCompliance() {
  const [timeAllData, setTimeAllData] = useState({} as IVsdAll);

  const fetchAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.TIME_COMPLIENCE}?duration=6&type=month`
      );
      if (response.data) {
        setTimeAllData(response?.data?.data ?? {});
      }
    } catch (e) {
      enqueueSnackbar('Error fetching time compliance data.', {
        variant: 'error',
      });
    }
  };

  // function for array of object data need to feed for chart
  const returnChartData = (
    name: string[],
    value: number[],
    timeComplience: number[]
  ) => {
    let arr = [];
    arr = name.map((item, index) => ({
      name: item,
      value: value[index],
      timeComplience: timeComplience[index],
    }));

    return arr;
  };

  const data = returnChartData(
    timeAllData?.distribution?.map(data => data.label) ?? [],
    timeAllData?.distribution?.map(data => data.value) ?? [],
    [100, 100, 100, 100, 100, 100]
  );

  //draw chart
  const chartDraw = (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        {/* gradient color code */}
        <defs>
          <linearGradient id="colorTimeComp" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#C2FFE0" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#C2FFE0" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name"></XAxis>
        <YAxis />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        {/* <Legend /> */}
        {/* <ReferenceLine y={100} label="Max" stroke="red" /> */}
        <Area
          dataKey="value"
          connectNulls
          // stackId="1"
          stroke="#048218"
          fill="url(#colorTimeComp)"
        />
        <Area
          dataKey="timeComplience"
          connectNulls
          // stackId="1"
          stroke="#048218"
          type={'monotone'}
          fill="url(#colorTimeComp)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <Paper elevation={0} sx={{ background: COLORS.bk_bg_primary }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 1.5,
          borderBottom: `1px solid ${COLORS.bk_stroke_primary}`,
        }}>
        <Typography className="bk-heading1">Time compliance</Typography>
        {/* <MoreVertIcon
          className="cursor-pointer"
          sx={{
            color: COLORS.bk_stroke_divider,
          }}
        /> */}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          p: 2,
          pb: 2,
        }}>
        {/* time compliance */}
        {timeAllData?.distribution?.length ? (
          chartDraw
        ) : (
          <ChartEmptyState
            // maxHeight={'100vh'}
            imageComponent={
              <DisplayImage
                imageName="time-comp-chart.svg"
                imageType="icon"
                style={{
                  height: '250px',
                  width: 'auto',
                }}
              />
            }
            description="Know how early your verification was delivered!"
          />
        )}
      </Stack>
    </Paper>
  );
}
