import { capitalizeString } from '@gigin-work-space/utils';
import { Autocomplete, TextField } from '@mui/material';
import { IAccountData } from '../../bgv/component/employer-upload/interface';

interface AccountAutocompleteProps {
  allAccounts: IAccountData[];
  accountData: IAccountData | null;
  handleChangeAccount: (_event: {
    target: { value: IAccountData | null };
  }) => void;
  inputLabel: string;
  showAllAccountsOption?: boolean;
}

const AccountAutocomplete = ({
  allAccounts,
  accountData,
  handleChangeAccount,
  showAllAccountsOption = false,
}: AccountAutocompleteProps) => {
  const options = showAllAccountsOption
    ? [{ _id: '', account_name: 'All Accounts' }, ...allAccounts]
    : allAccounts;

  return (
    <Autocomplete
      size="small"
      disableClearable
      id="combo-box-demo"
      value={accountData ? accountData : (null as unknown as IAccountData)}
      onChange={(event, newValue) =>
        handleChangeAccount({ target: { value: newValue as IAccountData } })
      }
      options={options}
      getOptionLabel={option => capitalizeString(option?.account_name || '')}
      className="w-[300px]"
      renderInput={params => (
        <TextField
          {...params}
          label={capitalizeString(accountData?.account_name || 'All Accounts')}
          variant="outlined"
        />
      )}
    />
  );
};

export default AccountAutocomplete;
