/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { BKStyledEBTableCell } from '@gigin-work-space/common-ui';
import {
  CheckoutStore,
  EmployerCDCStore,
  intialStepperStatus,
} from '@gigin-work-space/store';
import { formatDateFromTimestamp } from '@gigin-work-space/utils';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { TablePaginationActions } from 'src/app/components';
import EBDataMapModel from './employer-data-mapping-model';
interface IEBDataMappingTableProps {
  tableData: any[];
  fetchData: () => void;
}
const EBDataMappingTable = ({
  tableData,
  fetchData,
}: IEBDataMappingTableProps) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const { currentPage, itemsPerPage, totalItems, totalPages } =
    EmployerCDCStore.dataMapMeta;
  const [page, setPage] = useState(0);

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: any
  ) => {
    EmployerCDCStore.dataMapMeta.currentPage = newPage + 1;
    fetchData();
  };

  return (
    <Box className="w-full">
      {tableData.length ? (
        <>
          <Grid className="bk-bulk-data-mapping-table">
            <Typography className="bK-btn-small font-normal">
              Showing {tableData.length} documents
            </Typography>
            <TablePagination
              className="border-b-0"
              rowsPerPageOptions={[]}
              count={Number(totalItems) ?? 0}
              rowsPerPage={Number(itemsPerPage) ?? 10}
              page={Number(currentPage) - 1}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
          <TableContainer component={Paper} className="bk-table-container">
            <Table
              stickyHeader
              aria-label="customized table"
              className="w-full">
              <TableHead>
                <TableRow>
                  <BKStyledEBTableCell className="bk-subheading-2 font-bold">
                    <b>Document list</b>
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="bK-body2">
                    Candidate
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="bK-body2">
                    Document type
                  </BKStyledEBTableCell>
                  <BKStyledEBTableCell className="bK-body2">
                    Activity
                  </BKStyledEBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        className="cursor-pointer"
                        sx={{
                          backgroundColor: row['documentId']
                            ? '#F8F8F8'
                            : '#FFFFFF',
                        }}
                        onClick={() => {
                          if (
                            EmployerCDCStore.stepperStatus[2].progress !==
                            'COMPLETED'
                          ) {
                            EmployerCDCStore.currentSelectedImage =
                              row['fileUrl'];
                            EmployerCDCStore.currentSelectedImageName =
                              row['fileName'].slice(-15);
                            EmployerCDCStore.selectedDocumentId = row['_id'];
                            EmployerCDCStore.dataModel = true;
                            EmployerCDCStore.dataMapMeta.index = index;
                            EmployerCDCStore.currentSelectedDocumentTypeName =
                              row['documentId']?.field_name;
                            EmployerCDCStore.currentSelectedDocumentTypeLabel =
                              row['documentId']?.field_label.en;
                            EmployerCDCStore.currentSelectedDocumentTypeId =
                              row['documentId']?._id;
                            EmployerCDCStore.currentSelectedDocumentCandidate =
                              row['candidateId']?.name;
                            EmployerCDCStore.currentSelectedDocumentCandidateId =
                              row['candidateId']?._id;
                            setIsModelOpen(true);
                          }
                        }}>
                        <BKStyledEBTableCell>
                          <div className="bk-eb-table-cell">
                            <img
                              src={row['fileUrl']}
                              alt={row['fileName']}
                              className="py-1 w-[42px] h-[42px] mr-[10px]"
                            />
                            <div>
                              <h2 className="m-0 text-xs font-medium">
                                {row['fileName'].slice(-15)}
                              </h2>
                              <p className="bk-file-size">
                                {row['fileSize'] < 1024
                                  ? `${Math.floor(row['fileSize'] / 1024)}KB`
                                  : `${(
                                      row['fileSize'] /
                                      (1024 * 1024)
                                    ).toFixed(2)}MB`}
                              </p>
                            </div>
                          </div>
                        </BKStyledEBTableCell>
                        <BKStyledEBTableCell className="text-bk_text_secondary">
                          {row['candidateId'] ? (
                            row['candidateId'].name
                          ) : (
                            <Typography className="bk-file-size">
                              Yet to be mapped
                            </Typography>
                          )}
                        </BKStyledEBTableCell>
                        <BKStyledEBTableCell className=" text-bk_text_secondary">
                          {row['documentId']
                            ? row['documentId'].field_label.en.replace(
                                'Upload ',
                                ''
                              )
                            : '---'}
                        </BKStyledEBTableCell>

                        <BKStyledEBTableCell className="text-bk_text_secondary">
                          {row['documentId']
                            ? `Mapped on ${formatDateFromTimestamp(
                                row['updatedAt'],
                                'MMM DD'
                              )}`
                            : `Added on ${formatDateFromTimestamp(
                                row['createdAt'],
                                'MMM DD'
                              )}`}
                        </BKStyledEBTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <BKStyledEBTableCell
                      colSpan={4}
                      className="bK-body2 text-bk_text_secondary text-center font-bold">
                      No records found!
                    </BKStyledEBTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Grid className="bk-document-upload-info">
          {!CheckoutStore.step_3 ? (
            EmployerCDCStore.step3CurrentStatus === 'All' ? (
              EmployerCDCStore.docUploadCount === 0 ? (
                <>
                  No documents uploaded yet.
                  <span
                    style={{ color: '#167FCC' }}
                    onClick={() => {
                      EmployerCDCStore.activeStep =
                        EmployerCDCStore.activeStep - 1;
                    }}
                    className="cursor-pointer">
                    <u> Click to Upload documents</u>
                  </span>
                </>
              ) : (
                <>No data found</>
              )
            ) : EmployerCDCStore.step3CurrentStatus === 'MAPPED' ? (
              <>Mapped Data not found</>
            ) : (
              <>Un-mapped data not found</>
            )
          ) : (
            'Un-mapped data not found'
          )}
        </Grid>
      )}
      {isModelOpen && (
        <EBDataMapModel
          isOpen={isModelOpen}
          handleCloseModel={handleCloseModel}
          index={EmployerCDCStore.dataMapMeta.index}
          tableData={tableData}
        />
      )}
    </Box>
  );
};

export default EBDataMappingTable;
