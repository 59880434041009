import { darkTheme, lightTheme } from '../theme';
import { UserStore } from './user-store';

//user manager class to Access with UserStore
export class UserManger {
  //temp set data
  static setUser = () => {
    UserStore.path = '/set-password';
    UserStore.selectedUser.user = 'Rajesh';
    UserStore.selectedUser.email = 'harsh@gigin.com';
  };

  static setTheme = (theme: string): void => {
    UserStore.themeOption = (() => {
      switch (theme) {
        case 'light':
          return lightTheme;
        case 'dark':
          return darkTheme;
        default:
          return lightTheme;
      }
    })();
  };
}
