import { BKStyledButton, DrawerComponent } from '@gigin-work-space/common-ui';
import { IOrderCandidateListData } from '@gigin-work-space/model';
import {
  CheckoutStore,
  OrderCandidateStore,
  handlePagination,
  setReportData,
} from '@gigin-work-space/store';
import {
  COLORS,
  PROGRESS_STATUS,
  maxSevenIcons,
} from '@gigin-work-space/utils';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import StopIcon from '@mui/icons-material/Stop';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import { useSnackbar } from 'notistack';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import FileDisplayComponent from 'src/app/components/display-file/display-file';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { useSnapshot } from 'valtio';
import { CandidateIndividualCheck } from './candidate-check';
import { ProgressOrderTableComponent } from './progress-order-table-component';
import { ProgressStep } from './status-chip';

interface IOrderTableProps {
  order_id: string;
  orderColor: string;
  expend: boolean;
}
export const OrderTable = ({
  order_id,
  expend,
  orderColor,
}: IOrderTableProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const navigate = useNavigate();
  // eslint-disable-next-line prefer-const
  let { candidateList, metaData, searchValue } =
    useSnapshot(OrderCandidateStore);
  const [fetchingCandidates, setIsFetchingCandidates] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);
  // TODO: DELETE After twesting report
  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setOpen(false);
  };
  const handlePageChange = useCallback(async () => {
    handlePagination(pagination);
    if (expend && searchValue?.length <= 0) {
      setIsFetchingCandidates(true);
      try {
        const response = await axiosInstance.get(
          `${endpoints.GET_CANDIDATE_BY_ORDER_ID}${order_id}&limit=${
            pagination.pageSize
          }&page=${pagination.pageIndex + 1}`
        );
        if (response.data) {
          OrderCandidateStore.candidateList = response.data.data.candidateList;
          OrderCandidateStore.metaData = response.data.meta;
        }
      } catch (err) {
        enqueueSnackbar('Failed to fetch candidates', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setIsFetchingCandidates(false);
      }
    }
  }, [pagination.pageIndex]);

  const CancelCase = async (caseId: string) => {
    // Show confirmation dialog
    if (selectedCaseId === null) {
      console.error('No case ID selected');
      return;
    }

    handlePagination(pagination);

    try {
      const response = await axiosInstance.patch(
        `${endpoints.ORDER_CHECK_CANCEL}/${caseId}`
      );
      if (response.data) {
        handleClearTriggered();
        setIsModalOpen(false);
      }
    } catch (err) {
      console.error(err);
      setIsModalOpen(false);
    }
  };

  const handleClearTriggered = async () => {
    handlePagination(pagination);
    if (
      OrderCandidateStore.searchValue.length === 0 ||
      OrderCandidateStore.hasClearClicked
    ) {
      setIsFetchingCandidates(true);
      try {
        const response = await axiosInstance.get(
          `${endpoints.GET_CANDIDATE_BY_ORDER_ID}${order_id}&limit=${
            pagination.pageSize
          }&page=${pagination.pageIndex + 1}`
        );
        if (response.data) {
          OrderCandidateStore.candidateList = response.data.data.candidateList;
          OrderCandidateStore.metaData = response.data.meta;
        }
      } catch (err) {
        enqueueSnackbar('Failed to fetch candidates', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setIsFetchingCandidates(false);
      }
    }
  };

  // TODO: Delete later
  // function to generate Report on click of view report, if report btn is visible
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleGenerateReport = useCallback(
    async (event: SyntheticEvent, id: any) => {
      event.stopPropagation();
      try {
        const response = await axiosInstance.get(
          `${endpoints.GET_REPORT_DATA}/${id}`
        );
        if (response.data.success) {
          setReportData(response.data.data);
          globalNavigateTo('/bgv/report', navigate);
          return;
        }
        throw new Error('Failed to generate report pdf');
      } catch (error) {
        enqueueSnackbar('Failed to generate report pdf', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    []
  );

  useEffect(() => {
    if (
      OrderCandidateStore.hasClearClicked &&
      OrderCandidateStore.searchValue.length === 0
    )
      handleClearTriggered();
  }, [OrderCandidateStore.hasClearClicked, OrderCandidateStore.searchValue]);
  // OrderCandidateStore.hasClearClicked
  // order ID update
  OrderCandidateStore.order_specific_id = order_id;
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<IOrderCandidateListData>[]>(
    () => [
      {
        id: 'Candidate',
        header: 'Candidate name',
        // maxSize: 200,
        muiTableHeadCellProps: () => ({
          align: 'left',
        }),
        Cell: ({ row }) => (
          <Stack
            direction={'column'}
            spacing={'0.125rem'}
            alignItems={'flex-start'}
            flexWrap={'wrap'}
            className="truncate">
            <Typography className="bk-sub-heading2">
              {row.original.name}
            </Typography>
            <Typography className="bK-body2">{row.original.jobRole}</Typography>
          </Stack>
        ),
      },
      {
        header: 'Status',
        // maxSize: 250,
        muiTableHeadCellProps: () => ({
          align: 'left',
        }),
        muiTableBodyCellProps: () => ({}),
        Cell: ({ row }) => (
          <Stack direction={'row'} columnGap={3}>
            <Stack alignItems={'flex-start'}>
              {/* progress component */}
              <ProgressOrderTableComponent {...row.original.checkProgress} />
            </Stack>
            <Stack alignItems={'flex-start'}>
              {/* chip status */}
              <ProgressStep {...row.original.currentProgess} />
            </Stack>
          </Stack>
        ),
      },
      {
        header: 'Checks',
        muiTableHeadCellProps: () => ({
          colSpan: 2,
        }),
        muiTableBodyCellProps: () => ({
          colSpan: 2,
        }),
        Cell: ({ row }) => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            spacing={'0.125rem'}>
            {/* check icons */}
            <Stack direction={'row'} spacing={1}>
              {maxSevenIcons(row.original.checkList) ? (
                row.original.checkList.map((check, id) => {
                  return (
                    <Tooltip title={check.product_name} placement="top" arrow>
                      <Stack key={id}>
                        {/* TODO: Tooltip not working */}
                        <FileDisplayComponent
                          fileUrl={check.icon}
                          fileStyle={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </Stack>
                    </Tooltip>
                  );
                })
              ) : (
                <Stack direction={'row'} spacing={2}>
                  <Stack direction={'row'} gap={1}>
                    {row.original.checkList.slice(0, 7).map(check => {
                      return (
                        <Box>
                          {/* TODO: Tooltip not working */}
                          <Tooltip
                            title={check.product_name}
                            placement="top"
                            arrow>
                            <FileDisplayComponent
                              fileUrl={check.icon}
                              fileStyle={{
                                width: '24px',
                                height: '24px',
                              }}
                            />
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </Stack>
                  <Stack direction={'row'}>
                    + {row.original.checkList.length - 7} more
                  </Stack>
                </Stack>
              )}
            </Stack>
            {/* action icons */}

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              {row.original.currentProgess &&
                row.original.currentProgess.status ===
                  PROGRESS_STATUS.INPROGRESS && (
                  <Tooltip title={'Cancel Check'} placement="top" arrow>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation(); // This will prevent the event from bubbling up
                        // CancelCase(row.original._id);
                        setIsModalOpen(true);
                        setSelectedCaseId(row.original._id.toString());
                        //row.original.currentProgess.status =
                        //  PROGRESS_STATUS.BLOCKED;
                      }}>
                      <StopIcon
                        sx={{
                          color: COLORS.bk_stroke_divider,
                          '&:hover': {
                            color: COLORS.bk_action_pressed,
                          },
                        }}
                        className="cursor-pointer"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {row.original.currentProgess &&
                row.original.currentProgess.status ===
                  PROGRESS_STATUS.CANCELATION_REQUESTED && (
                  <Tooltip
                    title={'Cancellation requested'}
                    placement="top"
                    arrow>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation(); // This will prevent the event from bubbling up
                      }}>
                      <HourglassTopIcon
                        sx={{
                          color: 'orange', // Set the color to orange
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              <Tooltip title={'View Candidate'} placement="top" arrow>
                <IconButton>
                  <RemoveRedEyeIcon
                    sx={{
                      color: COLORS.bk_stroke_divider,
                      '&:hover': {
                        color: COLORS.bk_action_pressed,
                      },
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      OrderCandidateStore.report_id = row.original._id;
                      setOpen(true);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    handlePageChange();
  }, [handlePageChange, CheckoutStore.reload]);

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={candidateList}
        enableColumnActions={false}
        state={{ pagination: pagination, isLoading: fetchingCandidates }}
        manualPagination
        onPaginationChange={setPagination}
        // enable pagination on top
        // positionPagination="both"
        // enableToolbarInternalActions={false}
        initialState={{ pagination: pagination }}
        enableColumnFilters={false}
        // enablePagination={true}
        enableSorting={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            OrderCandidateStore.report_id = row.original._id;
            setOpen(true);
          },
          hover: false,
          sx: {
            '&:nth-of-type(odd)': {
              backgroundColor: COLORS.bk_bg_primary,
            },
            '&:nth-of-type(even)': {
              backgroundColor: COLORS.bk_light_gray,
            },
            '&:hover': {
              backgroundColor: `${COLORS.bk_bg_primary}`,
              boxShadow: `0px -4px 10px ${COLORS.bk_stroke_secondary}`,
            },
            '&:hover .MuiSvgIcon-root': {
              color: `${COLORS.bk_action_primary}`, // Change the hover color here
            },
          },
        })}
        muiTablePaginationProps={{
          rowsPerPageOptions: [],
          showFirstButton: false,
          showLastButton: false,
          SelectProps: { native: true },
          labelRowsPerPage: 'Number of rows visible',
        }}
        // TODO: table border radius
        muiTablePaperProps={{
          sx: {
            borderRadius: 8,
            boxShadow: 'none',
          },
        }}
        muiTableContainerProps={{
          className: 'table-style',
          sx: {
            table: {
              borderCollapse: 'collapse',
            },
            'th,td': {
              border: `1px solid ${COLORS.bk_stroke_secondary}`,
            },
            'tr:first-child th': {
              borderTop: 0,
            },
            'tr:last-child td': {
              borderLeft: 0,
            },
            'tr td:first-child, tr th:first-child': {
              borderLeft: 0,
            },
            'tr td:last-child,tr th:last-child': {
              borderRight: 0,
            },
          },
        }}
        muiTableHeadRowProps={{
          color: '#FBFCFC',
        }}
        muiTableBodyCellProps={{
          sx: {
            padding: '12px 32px',
            '& .MuiTableCell-root': {
              borderLeft: `1px solid ${COLORS.bk_stroke_primary}`,
            },
          },
        }}
        muiTableProps={{
          sx: {
            border: `1px solid ${COLORS.bk_stroke_divider}`,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            '& .MuiTableCell-head': {
              backgroundColor: '#FBFCFC',
              padding: '12px 32px 12px 32px',
            },
            borderLeft: `4px solid ${orderColor}`,
            // overflow: 'hidden',
          },
        }}
        rowCount={metaData.totalItems}
        muiBottomToolbarProps={{
          sx: {
            boxShadow: 'none',
          },
        }}
      />
      <DrawerComponent open={open} onClose={onClose} anchor="right">
        <CandidateIndividualCheck onClose={onClose} />
      </DrawerComponent>
      {/* Modal */}
      <Dialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        fullWidth>
        <DialogTitle>
          <Typography className="bk-headline2">
            Request to cancel check(s)
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className="bk-sub-heading2">
            All the checks for Candidate will be stopped. Our team will look
            into this and confirm shortly. You might receive a call from the
            team if any further clarity required.
          </Typography>
          <Box className="buttonBoxRight">
            <BKStyledButton
              variant="text"
              className="text-bk_text_secondary bk-btn-large"
              sx={{ padding: '12px 20px' }}
              onClick={() => {
                setIsModalOpen(false);
              }}>
              No, go back
            </BKStyledButton>
            <BKStyledButton
              variant="contained"
              className="bg-bk_action_primary rounded-lg bk-btn-large button-padding"
              onClick={() => {
                if (selectedCaseId !== null) {
                  CancelCase(selectedCaseId);
                } else {
                  console.error('No case ID selected');
                  // Optionally, handle the error case here, such as showing a notification to the user
                }
              }}>
              Request cancelation
            </BKStyledButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
