/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CHECK_WITH_SUBTITLE, COLORS, SPACING } from '@gigin-work-space/utils';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { FlattenedSubcheck } from '../utility/restructure-data';
import StatusTxtBtn from './status-txt-btn';

interface CheckSummaryTableProps {
  data: FlattenedSubcheck[];
}

const CheckSummaryTable = ({ data }: CheckSummaryTableProps) => {
  return (
    <View style={{ marginHorizontal: -24 }}>
      <View style={styles.summaryTableHeader}>
        <Text
          style={[
            styles.summaryTableCell,
            styles.typov3SubHeader3,
            { flex: 3 },
          ]}>
          Summary of Checks included
        </Text>
        <Text
          style={[
            styles.summaryTableStatusCell,
            styles.typov3SubHeader3,
            { textAlign: 'left' },
          ]}>
          Status
        </Text>
      </View>
      {data.map((item, index) => (
        <View
          key={index}
          style={[
            styles.summaryTableRow,
            ...(index % 2 === 1 ? [styles.alternateRow] : []),
          ]}>
          <View
            style={[
              styles.summaryTableCell,
              { flex: 3, flexDirection: 'row' },
            ]}>
            <Text style={styles.typov3Body3}>{index + 1}.</Text>
            <View style={{ marginLeft: SPACING.md }}>
              {!CHECK_WITH_SUBTITLE.includes(item.reportType) && (
                <Text
                  style={
                    styles.typov3Body3
                  }>{`${item.reportTitle}: ${item.subcheckName}`}</Text>
              )}
              {CHECK_WITH_SUBTITLE.includes(item.reportType) && (
                <Text style={styles.typov3Body3}>{`${item.reportTitle}`}</Text>
              )}
              {CHECK_WITH_SUBTITLE.includes(item.reportType) && (
                <Text
                  style={[
                    styles.typov3HintText,
                    { color: COLORS.bk_report_text_secondary },
                  ]}>
                  {item.subcheckName}
                </Text>
              )}
            </View>
          </View>
          <View style={[styles.summaryTableStatusCell]}>
            <StatusTxtBtn status={item?.subcheckStatus} />
          </View>
        </View>
      ))}
    </View>
  );
};

export default React.memo(CheckSummaryTable);
