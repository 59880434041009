import { BKStyledButton } from '@gigin-work-space/common-ui';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { DisplayImage } from 'src/app/components';

interface OrderEmptyStateProps {
  handleCreateNewRequest: () => void;
}

const OrderEmptyState: React.FC<OrderEmptyStateProps> = ({
  handleCreateNewRequest,
}) => {
  return (
    <Grid>
      <section className="sticky top-0 ml-3 z-10 bg-bk_bg_primary">
        <Grid className="flex justify-between items-center p-4">
          <Typography className="bk-headline1 mb-4">
            Verification Dashboard
          </Typography>
          <Grid className="flex justify-end items-center gap-x-5">
            <BKStyledButton
              startIcon={<AddIcon />}
              className="py-2 px-4 rounded-lg bK-body2"
              variant="outlined"
              sx={{ boxShadow: 0 }}
              onClick={handleCreateNewRequest}>
              New BGV Request
            </BKStyledButton>
          </Grid>
        </Grid>
        <Divider />
      </section>

      <Grid container className="bk-flex-row-item-content-center mt-8">
        <Grid item xs={4}>
          <DisplayImage
            imageName="order-empty-state.svg"
            imageType="icon"
            width="374px"
            height="397px"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="bk-headline1">
            Start creating your first order
          </Typography>
          <Typography className="bK-body2" paragraph width={'50%'}>
            We are excited for you to place your BGV order with us! To create an
            order for single or multiple candidates, start by clicking the
            button below.
          </Typography>
          <BKStyledButton
            variant="outlined"
            sx={{ boxShadow: 0, mt: 2 }}
            onClick={handleCreateNewRequest}>
            Create verification request
          </BKStyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderEmptyState;
