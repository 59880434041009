import { handleModalClose, isModalOpenStore } from '@gigin-work-space/store';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSnapshot } from 'valtio';
/* eslint-disable-next-line */
export interface CommonDialogueProps {
  DialogueHeader?: JSX.Element;
  Content?: JSX.Element;
  ActionButton?: JSX.Element;
  size?: DialogProps['maxWidth'];
}

export function CommonDialogue({ DialogueHeader, Content, ActionButton, size = 'sm' }: CommonDialogueProps) {
  const { isOpen } = useSnapshot(isModalOpenStore);

  return (
    <Dialog
      open={Object.values(isOpen).some((value) => value === true)}
      fullWidth
      maxWidth={size}
      // disable out side click
      onClose={(event, reason) => {
        if ((reason && reason === 'backdropClick') || reason === 'escapeKeyDown') return;
        handleModalClose();
      }}
      sx={{
        padding: '28px 53px',
        '& .MuiPaper-root': {
          borderRadius: '16px',
        },
      }}>
      <DialogTitle>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          {DialogueHeader}
          {/*Fire handleClose after a click on close button*/}
          <IconButton
            onClick={() => {
              handleModalClose();
            }}
            sx={{ ml: 'auto' }}>
            <CloseIcon className="black-highlighter" />
          </IconButton>
        </Stack>
      </DialogTitle>
      {/* dialog content */}
      <Divider />
      <DialogContent sx={{ maxHeight: '70vh', overflowY: 'auto' }}>{Content}</DialogContent>
      <DialogActions>{ActionButton}</DialogActions>
    </Dialog>
  );
}

export default CommonDialogue;
