import {
  BKStyledButton,
  BKStyledSmallSelectGroup,
} from '@gigin-work-space/common-ui';
import {
  COLORS,
  DataCollectionStatus,
  REPORT_STATUS,
} from '@gigin-work-space/utils';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { HttpStatus } from '@nestjs/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import InsufficiencyReport from './InsufficiencyReport';

export interface Remark {
  status: string;
  mailSentAt: Date | null;
  issueRaised: Date | null;
  issueResolved: Date | null;
  comment: string;
}

interface CheckDetails {
  remark: Remark[];
  ext_check_id: string;
  order_request_id: string;
  actor_id: string;
  check_id: string;
  order_service_item_status: string;
  order_service_verification_status: string;
}

interface CaseCheck {
  case_id: string;
  check_id: string;
  checkDetails: CheckDetails;
}

interface CheckHeaderProps {
  checkTitle: string;
  checkId: string;

  report_status: string;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (reportStatus: string) => void;
  // eslint-disable-next-line no-unused-vars
  onReportStatusChange: (reportStatus: string) => void;
  dataCollectionStatus: string;
  checkStatus: [{ checkStatus: string; check: string; remark: Remark[] }];
}

export const CheckHeader = (props: CheckHeaderProps) => {
  const {
    checkTitle,
    checkId,
    report_status,
    onReportStatusChange,
    onSubmit,
    dataCollectionStatus,
    checkStatus,
  } = props;

  const [reportStatus, setReportStatus] = useState(report_status);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [remark, setRemark] = useState<Remark[]>([]);
  const [comment, setComment] = useState<string>('');

  // for making the basic detail to insufficient when any checkTitles is marked as insufficency
  const [isAnyInsufficient, setIsAnyInsufficient] = useState(false);

  const [checkItem, setCheckItem] = useState<CaseCheck>({
    case_id: '',
    check_id: '',
    checkDetails: {
      remark: [],
      ext_check_id: '',
      order_request_id: '',
      actor_id: '',
      order_service_item_status: '',
      order_service_verification_status: '',
      check_id: '',
    },
  });

  useEffect(() => {
    // Check if any checkStatus has an INSUFFICIENT status
    const insufficientExists = checkStatus.some(
      item => item.checkStatus === 'INSUFFICIENT'
    );
    setIsAnyInsufficient(insufficientExists);
    // If the current title is 'Basic Details' and any check is insufficient, set the report status to INSUFFICIENT in Basic details
    if (checkTitle === 'Basic Details' && insufficientExists) {
      setReportStatus('INSUFFICIENT');
      onReportStatusChange('INSUFFICIENT');
    }
  }, [checkStatus, checkTitle, onReportStatusChange]);

  const handleChangeReportStatus = (event: SelectChangeEvent) => {
    const selectedReportStatus = event.target.value as string;
    setReportStatus(selectedReportStatus);
    onReportStatusChange(selectedReportStatus);

    if (
      checkItem.checkDetails.order_service_verification_status !==
        'INSUFFICIENT' &&
      checkTitle !== 'Basic Details' &&
      selectedReportStatus === 'INSUFFICIENT'
    ) {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  };

  const dateFormate = (date: Date) => {
    // Format the date
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmitReason = async () => {
    remark.push({
      status: reportStatus,
      issueRaised: new Date(),
      comment: comment,
      mailSentAt: null,
      issueResolved:
        reportStatus === REPORT_STATUS.INSUFFICIENT ? null : new Date(),
    });

    try {
      const response = await axiosInstance.patch(
        `${endpoints.CASE_INFO}/${checkItem.check_id}`,
        {
          order_service_verification_status: reportStatus,
          remark: remark,
        }
      );
      if (response && response.status === HttpStatus.OK) {
        enqueueSnackbar('Remark update successful', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        // Handle unexpected responses or errors
        enqueueSnackbar('Failed to update remark', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
      setComment('');
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }

    setOpenDialog(false);
    // might want to call onReportStatusChange or onSubmit here with the new status and reason
  };

  const getCheckDetailsStatus = async () => {
    try {
      if (checkTitle === 'Basic Details') {
        return;
      }
      const response = await axiosInstance.get(
        `${endpoints.CASE_INFO}/case-info/${checkId}`
      );
      setCheckItem(response?.data?.data);

      setRemark(response?.data?.data?.checkDetails?.remark);
    } catch (error) {
      enqueueSnackbar('Error getting details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmit = () => {
    onSubmit(reportStatus);
    getCheckDetailsStatus();
  };

  const handleRemarkChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    getCheckDetailsStatus();
  }, []);
  //  for adding shortcut key for Submit button

  const handleKeyDown = (event: globalThis.KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);

  // for copying text to clipboard

  const handleClipToBoard = async () => {
    try {
      const textToCopy = checkId;
      await navigator.clipboard.writeText(textToCopy);
      setSnackbarMessage('Copied to clipboard');
      setOpen(true);
    } catch (error) {
      setSnackbarMessage('Falied to copy');
      setOpen(true);
    }
  };

  const onSubmitClickHandler = () => {
    handleSubmitReason();
    handleSubmit();
  };

  // for filtering the checkTitle so that we can display the comment separately on that particular checkTitle

  const filteredCheckStatus = checkStatus.filter(
    item => item?.check === checkTitle
  );

  return (
    <AppBar position="fixed" color="inherit" className="bk-individual-header">
      <Toolbar>
        <Stack
          direction={'row'}
          justifyContent={'space-evenly'}
          gap={'10%'}
          alignItems={'center'}
          width={'100%'}
          spacing={1}
          className="p-2">
          <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <Stack marginRight={'56px'}>
              <Typography className="bk-headline1 text-2xl">
                {checkTitle}
              </Typography>
              <Typography className="bk-sub-heading2 flex items-center">
                {checkId}
                <IconButton onClick={handleClipToBoard}>
                  <ContentCopyRoundedIcon
                    className={`text-sm ${COLORS.bk_bg_light}`}
                  />
                </IconButton>
              </Typography>
            </Stack>
            <Snackbar
              message={snackbarMessage}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={2000} // shorter duration for better UX
              onClose={() => setOpen(false)}
              open={open}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label="QC completed"
                labelPlacement="start"
              />
            </FormGroup>
            <Box className="min-w-[168px]" display={'flex'} columnGap={2}>
              <BKStyledSmallSelectGroup fullWidth>
                <InputLabel id="report-status-select-label">
                  Report status
                </InputLabel>
                <Select
                  labelId="report-status-select-label"
                  id="report-status-select"
                  size="small"
                  value={reportStatus}
                  label="Report status"
                  onChange={handleChangeReportStatus}>
                  <MenuItem value={'CLEAR'}>Clear</MenuItem>
                  <MenuItem value={'DISCREPANT'}>Discrepant</MenuItem>
                  <MenuItem value={'INSUFFICIENT'}>Insufficient</MenuItem>
                  <MenuItem value={'NO_RESPONSE'}>No Response</MenuItem>
                  <MenuItem value={'INPROGRESS'}>Inprogress</MenuItem>
                  <MenuItem value={'UNABLE_TO_VERIFY'}>
                    Unable to Verify
                  </MenuItem>
                  <MenuItem value={'NOT_REQUIRED'}>NOT REQUIRED</MenuItem>
                </Select>
              </BKStyledSmallSelectGroup>

              {/* Remark for better understanding */}
              {/*  the first checks, if there is any check in checkStatus that matches the checkTitle and checkStatus is insufficient OR checkTitle is "Basic Detail" and if there is any insufficiency in any of the checkTitles OR if there is any remark in any of the remark it displays the icon on that particluar check and on Basic Detail */}

              {checkStatus?.some(
                check =>
                  check?.check === checkTitle &&
                  check?.checkStatus === 'INSUFFICIENT'
              ) ||
              (checkTitle === 'Basic Details' && isAnyInsufficient) ||
              remark?.length > 0 ? (
                <Box
                  onClick={() => setOpenDialog(true)}
                  className="flex items-center">
                  <DisplayImage
                    className="ml-3 cursor-pointer"
                    imageName="remark-icon.svg"
                    imageType="icon"
                  />
                </Box>
              ) : null}
            </Box>

            <Box className="min-w-[168px]">
              <Typography className="bK-body2">Data Collection</Typography>
              <Typography
                sx={{
                  color:
                    dataCollectionStatus?.toLowerCase() ===
                    DataCollectionStatus.COMPLETED.toLowerCase()
                      ? COLORS.bk_tag_green
                      : dataCollectionStatus?.toLowerCase() ===
                        DataCollectionStatus.PENDING.toLowerCase()
                      ? COLORS.bk_tag_orange
                      : COLORS.bk_text_dark,
                }}
                className="bk-sub-heading1">
                {dataCollectionStatus}
              </Typography>
            </Box>
          </Stack>
          <Grid className="flex flex-col">
            <BKStyledButton
              variant="contained"
              size="small"
              onClick={handleSubmit}>
              Update
            </BKStyledButton>
            <Typography variant="caption">Ctrl + Enter</Typography>
          </Grid>
        </Stack>
      </Toolbar>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        className="bk-border-radius">
        <DialogTitle
          className="bk-border-radius"
          display={'flex'}
          justifyContent={'space-between'}>
          <Typography>Insufficiency Remark </Typography>
          <Typography>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => {
                handleCloseDialog();
              }}
            />
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/*  for displaying the input in the checkTItles other than Basic Detail  */}
          {checkTitle !== 'Basic Details' && (
            <>
              <TextField
                autoFocus
                multiline
                rows={4}
                margin="dense"
                id="Remark"
                label="Remark"
                type="text"
                placeholder="Enter your remarks here."
                fullWidth
                value={comment}
                onChange={e => handleRemarkChange(e)}
              />
            </>
          )}

          {checkTitle === 'Basic Details'
            ? // Render remarks of the checkTitle in Basic Details
              checkStatus?.map((item, itemIndex) => (
                <InsufficiencyReport
                  item={item}
                  itemIndex={itemIndex}
                  dateFormate={dateFormate}
                />
              ))
            : // Render remarks for the selected checkTitle only
              filteredCheckStatus.map((item, itemIndex) => (
                <InsufficiencyReport
                  item={item}
                  itemIndex={itemIndex}
                  dateFormate={dateFormate}
                />
              ))}
        </DialogContent>

        {checkTitle !== 'Basic Details' && (
          <DialogActions>
            <>
              <BKStyledButton variant="text" onClick={handleCloseDialog}>
                Cancel
              </BKStyledButton>

              <BKStyledButton
                variant="contained"
                disabled={comment?.length === 0}
                onClick={() => onSubmitClickHandler()}>
                Save
              </BKStyledButton>
            </>{' '}
          </DialogActions>
        )}
      </Dialog>
    </AppBar>
  );
};
