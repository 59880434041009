/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CandidateAddNewRow,
  ICandidateInterfaceTable,
  ICandidateRole,
  IValidateCandidate,
  MetaInitialState,
} from '@gigin-work-space/model';
import { CheckoutStore } from '@gigin-work-space/store';
import {
  AUTO_HIDE_TIMEOUT,
  BKSubHeading2,
  candidateCreateMaker,
  COLORS,
  EnumIndexedDbContextKey,
  EnumIndexedDbStore,
  getItem,
  isNotEmpty,
  isValidEmail,
  isValidMobileNumber,
  responseImportExcelDataMaker,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { IParsedExcelDetails } from 'src/app/constant/interfaces';
import { axiosInstance, endpoints } from 'src/app/utils';
import { subscribe, useSnapshot } from 'valtio';
import { IpersistedData } from '../../candidate-detail-upload/candidate-detail-upload';
import { CandidateDragDropProps } from '../../drag-drop-component';
import CircularIndeterminate from '../../skelton/bgv-loader';
import CustomSelectField from './custom-select-cell';
import CustomTableCell from './custom-table-cell';

const StyledPaper = styled(Paper)`
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const StyledTableContainer = styled(TableContainer)<{
  component: React.ElementType;
  isNotExtraLargeScreen: boolean;
  hasUploadFileName: boolean;
}>`
  overflow-y: auto;
  max-height: ${({ isNotExtraLargeScreen, hasUploadFileName }) =>
    isNotExtraLargeScreen ? '20vh' : hasUploadFileName ? '45vh' : '35vh'};
`;

const StyledTable = styled(Table)<{ isNotExtraLargeScreen: boolean }>`
  border: 1px solid ${COLORS.bk_stroke_primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & .MuiTableCell-head {
    background-color: #fbfcfc;
    padding: ${({ isNotExtraLargeScreen }) =>
      isNotExtraLargeScreen ? '0.5em' : '0.75em 1.5em'};
    ${BKSubHeading2}
  }

  & .MuiTableRow-root.Mui-selected {
    background-color: ${COLORS.bk_tag_blue_bg};
  }

  & .MuiTableCell-root {
    padding: ${({ isNotExtraLargeScreen }) =>
      isNotExtraLargeScreen ? '0.5em' : '0.75rem 1.5rem'};

    &:hover {
      border: 1px solid ${COLORS.bk_tag_blue_bg};
      outline: none;
    }
  }
`;

const StyledTableBody = styled(TableBody)<{ isNotExtraLargeScreen: boolean }>`
  min-height: ${({ isNotExtraLargeScreen }) =>
    isNotExtraLargeScreen ? '35vh' : '50vh'};
  max-height: ${({ isNotExtraLargeScreen }) =>
    isNotExtraLargeScreen ? '35vh' : '50vh'};
  overflow-y: scroll;
`;

enum ActionType {
  SET_ROLES = 'SET_ROLES',
  SET_PAGE = 'SET_PAGE',
  SET_TABLE_DATA = 'SET_TABLE_DATA',
  SET_IS_NEW_ROW = 'SET_IS_NEW_ROW',
}

const initialState = {
  roles: [] as string[],
  page: 0,
  tableData: [] as ICandidateInterfaceTable[],
  isNewRow: false,
};

type State = typeof initialState;

type Action =
  | { type: ActionType.SET_ROLES; payload: string[] }
  | { type: ActionType.SET_PAGE; payload: number }
  | { type: ActionType.SET_TABLE_DATA; payload: ICandidateInterfaceTable[] }
  | { type: ActionType.SET_IS_NEW_ROW; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_ROLES:
      return { ...state, roles: action.payload };
    case ActionType.SET_PAGE:
      return { ...state, page: action.payload };
    case ActionType.SET_TABLE_DATA:
      return { ...state, tableData: action.payload };
    case ActionType.SET_IS_NEW_ROW:
      return { ...state, isNewRow: action.payload };
    default:
      return state;
  }
};

const useFetchRoles = (dispatch: React.Dispatch<Action>) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get(endpoints.FETCH_ROLE);
        if (response.data) {
          const returnedRole = response.data.data.map(
            (role: ICandidateRole) => role.role_title
          );
          dispatch({ type: ActionType.SET_ROLES, payload: returnedRole });
        } else {
          dispatch({ type: ActionType.SET_ROLES, payload: [] });
        }
      } catch (e) {
        enqueueSnackbar('Error while fetching roles', {
          variant: VARIANT_TYPE.ERROR,
        });
      }
    };

    fetchRoles();
  }, [enqueueSnackbar, dispatch]);
};

const useFetchCandidates = (
  initiate_id: string | undefined,
  page: number,
  UpdateCandidates: (
    data: IValidateCandidate[],
    parsedData: IParsedExcelDetails
  ) => void,
  dispatch: React.Dispatch<Action>
) => {
  const { enqueueSnackbar } = useSnackbar();

  const fetchExeclCandidateList = useCallback(async () => {
    CheckoutStore.isLoading = true;
    const persistedData = (await getItem(
      EnumIndexedDbStore.FILES,
      EnumIndexedDbContextKey.BGV_CANDIDATE_LINK_CSV_UPLOAD
    )) as IpersistedData;
    try {
      if (initiate_id) {
        const response = await axiosInstance.get(
          `${endpoints.UPDATE_INITIATE_ITEM}/${initiate_id}?page=${
            page + 1
          }&limit=10`
        );
        if (response.data) {
          const candidateParsedData: IpersistedData = {
            candidateList: response?.data?.data ?? [],
            parsedExcelDetails: {
              ...persistedData?.parsedExcelDetails,
              totalItems: response?.data?.meta?.totalItems ?? 0,
              validRecords: response?.data?.meta?.validItem ?? 0,
            },
          };
          if (UpdateCandidates) {
            UpdateCandidates(
              candidateParsedData.candidateList,
              candidateParsedData.parsedExcelDetails
            );
          }
          CheckoutStore.meta = response.data.meta;
          CheckoutStore.totalCandidate = CheckoutStore.meta.validItem;
        } else {
          const candidateParsedData: IpersistedData = {
            candidateList: [],
            parsedExcelDetails: {
              ...(persistedData?.parsedExcelDetails ?? {}),
            },
          };
          if (UpdateCandidates) {
            UpdateCandidates(
              candidateParsedData.candidateList,
              candidateParsedData.parsedExcelDetails
            );
          }
        }
      }
    } catch (e) {
      const candidateParsedData: IpersistedData = {
        candidateList: [],
        parsedExcelDetails: { ...(persistedData?.parsedExcelDetails ?? {}) },
      };
      if (UpdateCandidates) {
        UpdateCandidates(
          candidateParsedData.candidateList,
          candidateParsedData.parsedExcelDetails
        );
      }
      CheckoutStore.meta = MetaInitialState;
      enqueueSnackbar('Excel Candidate failed!', {
        variant: VARIANT_TYPE.ERROR,
      });
    } finally {
      CheckoutStore.isLoading = false;
    }
  }, [initiate_id, page, UpdateCandidates, enqueueSnackbar]);

  useEffect(() => {
    fetchExeclCandidateList();
  }, [fetchExeclCandidateList]);

  return fetchExeclCandidateList;
};

const EditableInitiateTable: React.FC<CandidateDragDropProps> = ({
  candidates,
  UpdateCandidates,
  packageId,
}: CandidateDragDropProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { initiate_id, meta, isLoading, candidateData } =
    useSnapshot(CheckoutStore);
  const fetchExeclCandidateList = useFetchCandidates(
    initiate_id,
    state.page,
    UpdateCandidates || (() => {}),
    dispatch
  );
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isNotExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

  useFetchRoles(dispatch);

  useEffect(() => {
    const unsubscribe = subscribe(CheckoutStore, () => {
      dispatch({
        type: ActionType.SET_PAGE,
        payload: CheckoutStore.meta.currentPage - 1,
      });
    });
    return unsubscribe;
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage !== state.page) {
      console.log(newPage);

      dispatch({ type: ActionType.SET_PAGE, payload: newPage });
      CheckoutStore.meta.currentPage = newPage + 1;
    }
  };

  const handleDeleteInitiatedItem = async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `${endpoints.INITIATE_ITEM}/${id}`
      );
      if (response.data) {
        enqueueSnackbar('Candidate Deleted successfully', {
          variant: VARIANT_TYPE.SUCCESS,
          autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
        });
        if (initiate_id && candidates.length) fetchExeclCandidateList();
      }
    } catch (e) {
      enqueueSnackbar('Candidate Deleted Failed', {
        variant: VARIANT_TYPE.ERROR,
        // autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
      });
    }
  };

  const handleSaveCell = async (index: number) => {
    const valData = CheckoutStore.candidateData[index];
    const valueCache = state.tableData[index];
    if (
      (valData && valData.name !== valueCache?.name) ||
      valData.mobile !== valueCache?.mobile ||
      valData.email !== valueCache?.email ||
      valData.job_role !== valueCache?.job_role
    ) {
      const { email, job_role, mobile, name, checkout_initiation_id } = valData;
      const validName = isNotEmpty(name);
      const validMail = isValidEmail(email);
      const validJobRole = isNotEmpty(job_role);
      const validMobile = isValidMobileNumber(mobile);

      if (validName && validMail && validJobRole && validMobile) {
        const createData = candidateCreateMaker(
          name,
          email,
          mobile,
          job_role,
          initiate_id,
          packageId || ''
        );
        if (state.isNewRow) {
          try {
            const response = await axiosInstance.post(
              '/initiated-item/',
              createData
            );
            if (response.data) {
              const tempCandidate = response?.data?.data?.candidate;
              const errorMessage = tempCandidate?.mobile?.error_message;
              if (errorMessage) {
                enqueueSnackbar(`Candidate add failed: ${errorMessage}`, {
                  variant: VARIANT_TYPE.ERROR,
                });
              } else {
                enqueueSnackbar('Candidate added successfully', {
                  variant: VARIANT_TYPE.SUCCESS,
                  autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
                });
              }
              dispatch({ type: ActionType.SET_IS_NEW_ROW, payload: false });
              fetchExeclCandidateList();
            }
          } catch (e) {
            enqueueSnackbar('Candidate add failed', {
              variant: VARIANT_TYPE.ERROR,
            });
          }
        } else {
          if (checkout_initiation_id) {
            try {
              const response = await axiosInstance.patch(
                `${endpoints.INITIATE_ITEM}/${checkout_initiation_id}`,
                createData
              );
              if (response.data) {
                enqueueSnackbar('Candidate Updated successfully', {
                  variant: VARIANT_TYPE.SUCCESS,
                  autoHideDuration: AUTO_HIDE_TIMEOUT.AVERAGE,
                });
                fetchExeclCandidateList();
              }
            } catch (e) {
              enqueueSnackbar('Candidate Update failed', {
                variant: VARIANT_TYPE.ERROR,
              });
            }
          }
        }
      } else {
        valData.nameError = !validName ? 'Name should be valid' : '';
        valData.emailError = !validMail ? 'Email should be valid' : '';
        valData.mobileError = !validMobile ? 'Mobile No should be valid' : '';
        valData.job_roleError = !validJobRole
          ? 'Job role should not be empty'
          : '';
      }
    }
  };

  const addNewRowFunction = () => {
    dispatch({ type: ActionType.SET_IS_NEW_ROW, payload: true });
    CheckoutStore.candidateData.unshift(new CandidateAddNewRow());
  };

  useMemo(() => {
    const data = candidates?.map((candidate, index) =>
      responseImportExcelDataMaker(candidate, index)
    );
    dispatch({ type: ActionType.SET_TABLE_DATA, payload: data });
    CheckoutStore.candidateData = data;
  }, [candidates]);

  return (
    <StyledPaper elevation={0}>
      <Box className="flex justify-between">
        <Tooltip
          arrow
          title="Add New Candidate"
          placement="top"
          className="ml-2">
          <Button
            variant="text"
            className="cursor-pointer text-bk_action_primary"
            onClick={addNewRowFunction}
            sx={{ alignItems: 'center', textTransform: 'none' }}>
            + Add New
          </Button>
        </Tooltip>
        <TablePagination
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          component="div"
          count={meta.totalItems}
          rowsPerPage={10}
          page={state.page}
          onPageChange={handleChangePage}
        />
      </Box>
      <StyledTableContainer
        component={Paper}
        isNotExtraLargeScreen={isNotExtraLargeScreen}
        hasUploadFileName={!!CheckoutStore.UploadFileName}>
        <StyledTable
          size="small"
          stickyHeader
          aria-label="sticky table"
          isNotExtraLargeScreen={isNotExtraLargeScreen}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Role</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <StyledTableBody isNotExtraLargeScreen={isNotExtraLargeScreen}>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <CircularIndeterminate />
                </TableCell>
              </TableRow>
            ) : candidateData.length ? (
              candidateData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background: !row.isRowError
                      ? COLORS.bk_tag_orange_bg
                      : COLORS.bk_bg_primary,
                  }}>
                  <CustomTableCell
                    id={index}
                    placeholder="Candidate Name"
                    cell="name"
                    cellError="nameError"
                    handleSaveCell={handleSaveCell}
                  />
                  <CustomTableCell
                    id={index}
                    placeholder="Mobile No."
                    cell="mobile"
                    cellError="mobileError"
                    handleSaveCell={handleSaveCell}
                    type="mobile"
                  />
                  <CustomTableCell
                    id={index}
                    placeholder="Type Email"
                    cell="email"
                    cellError="emailError"
                    handleSaveCell={handleSaveCell}
                    type="email"
                  />
                  <CustomSelectField
                    id={index}
                    options={state.roles}
                    handleSaveCell={handleSaveCell}
                  />
                  {!row.isRowNew ? (
                    <TableCell
                      sx={{
                        maxWidth: isNotExtraLargeScreen ? '0.5em' : '1em',
                        minWidth: isNotExtraLargeScreen ? '0.5em' : '1em',
                      }}>
                      <IconButton
                        onClick={() =>
                          handleDeleteInitiatedItem(row.checkout_initiation_id)
                        }
                        sx={{ ml: 'auto', padding: 0 }}>
                        <DeleteOutlineIcon
                          sx={{
                            '&:hover': {
                              color: COLORS.bk_action_primary,
                            },
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{ maxWidth: '0.5em', minWidth: '0.5em' }}></TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={5}>
                <Box sx={{ textAlign: 'center' }}>No record found</Box>
              </TableCell>
            )}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </StyledPaper>
  );
};

export default EditableInitiateTable;
