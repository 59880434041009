import { BKStyledButton } from '@gigin-work-space/common-ui';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { AuthenticationPaper, DisplayImage } from '../../../components';
import { AuthenticationHOC } from '../authentication-hoc/authentication-hoc';

export function LinkExpired() {
  const navigate = useNavigate();
  return (
    <AuthenticationHOC>
      <section className="absolute bottom-[-7%] left-[-160px]">
        <AuthenticationPaper>
          <Typography className="bk-headline1 text-bk_text_primary">
            Link Expired
          </Typography>
          <Typography className="bK-body1 text-bk_text_secondary mt-1">
            The link to reset password is expired now.
          </Typography>
          <DisplayImage
            imageName="reset_password_link_expired.svg"
            imageType="image"
            className="flex justify-center items-center object-contain mt-11 mb-7 ml-11"
          />
          <BKStyledButton
            className="rounded-lg"
            fullWidth
            size="large"
            variant="outlined"
            onClick={() =>
              globalNavigateTo('/reset-password/forgot', navigate)
            }>
            Send password reset link now
          </BKStyledButton>
        </AuthenticationPaper>
      </section>
    </AuthenticationHOC>
  );
}

export default LinkExpired;
