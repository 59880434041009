import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Grid, Stack, Typography } from '@mui/material';
export function UnAuthorizedEmptyState() {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" className="w-full h-full flex justify-center align-middle" gap={3}>
      <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        {/* Your Image goes here */}
        <img src={`../../../assets/${'image'}/${'unauthorized.svg'}`} alt="display" />
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        {/* Your content goes here */}
        <Grid item>
          <Stack spacing={1.5} alignItems={'center'}>
            <Typography className="bk-headline1 text-bk_text_primary">You don’t have access to this page</Typography>
            <Typography className="bK-body1 text-bk_text_secondary">Please contact your admin</Typography>
          </Stack>
        </Grid>
        {/* <Grid item>{buttonComponent}</Grid> */}
      </Grid>
    </Grid>
  );
}
