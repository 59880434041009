import { TableCell } from '@mui/material';

interface ITableCell {
  align: string;
  cellName: string;
  id: number;
}

interface IDetailCheckHeaderProps {
  cell: ITableCell;
}

const DetailCheckHeader = ({ cell }: IDetailCheckHeaderProps) => {
  return (
    <>
      <TableCell
        key={cell?.id}
        className={`text-${cell?.align} bk-sub-heading3`}>
        {cell?.cellName}
      </TableCell>
    </>
  );
};

export default DetailCheckHeader;
