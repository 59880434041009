/* eslint-disable no-unused-vars */
import { CheckoutStore } from '@gigin-work-space/store';
import { guardedPageNavigate } from '@gigin-work-space/utils';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import DisplayImage from '../display-image/display-image';
/* eslint-disable-next-line */
export interface JobHeaderProps {
  hideSidebar?: boolean;
  sideBarState?: boolean;
  updateSideBaseStatus?(x: boolean): void;
}

export function JobHeader({
  updateSideBaseStatus,
  hideSidebar,
  sideBarState = true,
}: JobHeaderProps) {
  const [anchorAccount, setAnchorAccount] = useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(anchorAccount);
  const navigate = useNavigate();
  const onClickHanbur = () => {
    if (updateSideBaseStatus) {
      updateSideBaseStatus(!sideBarState);
    }
  };

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccount(event.currentTarget);
  };
  const handleCloseAccountMenu = () => {
    setAnchorAccount(null);
  };

  const handleClickLogout = async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_AUTH_LOGOUT);

      if (response.data.statusCode === HttpStatusCode.Ok) {
        localStorage.clear();
        globalNavigateTo('/login', navigate);
        enqueueSnackbar('You have successfully logged out.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        throw new Error('Logout request failed');
      }
    } catch (error) {
      handleError('An error occurred during logout:', error);
    }
  };

  const handleError = (message: string, error: unknown) => {
    console.error(message, error);
    enqueueSnackbar('An error occurred during logout', {
      variant: 'error',
      autoHideDuration: 2000,
    });
  };

  return (
    <div className="h-12 text-center flex items-center px-4 text-bk_bg_primary max-h-max bg-bk_action_pressed justify-between top-0 z-50 fixed left-0 right-0">
      <div className="header-left flex justify-start gap-6 items-center">
        {!hideSidebar && (
          <div className="header-hamburger-icon cursor-pointer">
            {/* <SvgIcon component={StarIcon} inheritViewBox /> */}
            {/* <DisplayImage /> */}
            <div onClick={() => onClickHanbur()}>
              <DisplayImage
                imageName="hamburger_icon.svg"
                imageType="icon"
                width="28px"
                height="14px"
              />
            </div>
          </div>
        )}
        <div
          className="header-logo cursor-pointer pt-1"
          onClick={() => {
            CheckoutStore.initiate_id = '';
            globalNavigateTo(guardedPageNavigate(), navigate);
          }}>
          {' '}
          <DisplayImage
            imageName="verifyin-light.svg"
            imageType="image"
            width="140px"
            height="auto"
          />
        </div>
      </div>
      <div className="header-right flex flex-row gap-8 text-bk_bg_primary font-bold">
        {/* TODO: Commented for now, will be used in future scope */}
        {/* <DisplayImage
          imageName="calender_icon.svg"
          imageType="icon"
          width="20px"
          height="20px"
          className="cursor-pointer"
        />
        <DisplayImage
          imageName="question_icon.svg"
          imageType="icon"
          width="20px"
          height="20px"
          className="cursor-pointer"
        />
        <DisplayImage
          imageName="bell_icon.svg"
          imageType="icon"
          width="20px"
          height="20px"
          className="cursor-pointer"
        /> */}
        <IconButton
          onClick={handleOpenAccountMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={openAccountMenu ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openAccountMenu ? 'true' : undefined}>
          <DisplayImage
            imageName="account_icon.svg"
            imageType="icon"
            width="20px"
            height="20px"
          />
        </IconButton>
      </div>

      {/* Account Menu START */}
      <Menu
        id="account-menu"
        anchorEl={anchorAccount}
        open={openAccountMenu}
        onClose={handleCloseAccountMenu}
        MenuListProps={{
          'aria-labelledby': 'account-menu-button',
        }}
        sx={{ '& .MuiPaper-root': { width: 180, maxWidth: '100%' } }}>
        <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
      </Menu>
      {/* Account Menu END */}
    </div>
  );
}

export default JobHeader;
