import { Stack, Tooltip } from '@mui/material';

export const BlockedCandidateCheck = ({ status }: { status: string }) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={status} placement="top" arrow>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11" cy="11" r="11" fill="#EE6E12" />
          <path
            d="M11 9.45152V11.4511M11 13.4506H11.0047M10.3524 6.89796L6.50581 13.9996C6.29245 14.3935 6.18577 14.5905 6.20154 14.7521C6.21529 14.8931 6.2844 15.0213 6.39167 15.1046C6.51465 15.2002 6.72756 15.2002 7.1534 15.2002H14.8466C15.2725 15.2002 15.4854 15.2002 15.6084 15.1046C15.7156 15.0213 15.7847 14.8931 15.7985 14.7521C15.8143 14.5905 15.7076 14.3935 15.4942 13.9996L11.6476 6.89796C11.435 6.50547 11.3287 6.30923 11.19 6.24332C11.0691 6.18582 10.931 6.18582 10.81 6.24332C10.6713 6.30923 10.565 6.50547 10.3524 6.89796Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Tooltip>
    </Stack>
  );
};
