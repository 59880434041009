import { IProgressStatusProps } from '@gigin-work-space/model';
import {
  COLORS,
  ORDER_FLOW_STATUS,
  PROGRESS_STATUS,
  getStatusBgColor,
  getStatusColor,
  hasCheckStatusError,
} from '@gigin-work-space/utils';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import { ProgressLineSVG } from './progress-line';

export const InviteSentSVG: React.FC<IProgressStatusProps> = ({ message, status }) => {
  const color = getStatusColor(status);
  const bgColor = getStatusBgColor(status);
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Tooltip title={message ? message : ORDER_FLOW_STATUS.INVITE_SEND} placement="top" arrow>
        <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="13"
            cy="13"
            r="12.5"
            fill={bgColor}
            stroke={status.toUpperCase() === PROGRESS_STATUS.PENDING ? COLORS.bk_tag_gray_bg : color}
          />
          <path
            d="M11.2234 14.7766L16.6831 9.31694M11.2898 14.9471L12.6563 18.4611C12.7767 18.7706 12.8369 18.9254 12.9236 18.9706C12.9988 19.0098 13.0884 19.0098 13.1636 18.9707C13.2504 18.9256 13.3107 18.7709 13.4315 18.4615L16.8582 9.6805C16.9672 9.40119 17.0217 9.26153 16.9919 9.17229C16.966 9.09479 16.9052 9.03397 16.8277 9.00808C16.7385 8.97827 16.5988 9.03277 16.3195 9.14177L7.53849 12.5685C7.22907 12.6893 7.07436 12.7496 7.02928 12.8364C6.99019 12.9116 6.99024 13.0012 7.02942 13.0764C7.07461 13.1631 7.22938 13.2233 7.53894 13.3437L11.0529 14.7102C11.1157 14.7347 11.1471 14.7469 11.1736 14.7657C11.197 14.7825 11.2175 14.803 11.2343 14.8264C11.2531 14.8529 11.2653 14.8843 11.2898 14.9471Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {hasCheckStatusError(status) && (
            <path
              d="M15.7929 29C16.2383 29 16.4614 28.4614 16.1464 28.1464L13.3536 25.3536C13.1583 25.1583 12.8417 25.1583 12.6464 25.3536L9.85355 28.1464C9.53857 28.4614 9.76165 29 10.2071 29H15.7929Z"
              fill={color}
            />
          )}
        </svg>
      </Tooltip>
      {status.toUpperCase() === PROGRESS_STATUS.INPROGRESS ||
      status.toUpperCase() === PROGRESS_STATUS.FAIL ||
      status.toUpperCase() === PROGRESS_STATUS.PENDING ||
      status.toUpperCase() === PROGRESS_STATUS.BLOCKED ? (
        <ProgressLineSVG color={COLORS.bk_stroke_divider} />
      ) : (
        <ProgressLineSVG color={color} />
      )}{' '}
    </Stack>
  );
};
