/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BKStyledButton,
  CustomConnector,
  CustomStepIconRoot,
} from '@gigin-work-space/common-ui';
import {
  CheckoutStore,
  updateCheckoutStoreOnOrderCreation,
} from '@gigin-work-space/store';
import {
  clearIndexedDBEntries,
  COLORS,
  DataCollectionItemSource,
  EnumIndexedDbStore,
  VARIANT_TYPE,
} from '@gigin-work-space/utils';
import { Check } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Paper,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWizard, Wizard } from 'react-use-wizard';
import { OrderCreationStepConfigInterface } from 'src/app/constant/interfaces';
import {
  FooterProvider,
  OrderCreationProvider,
  OrderCreationStepperErrorProvider,
  OrderCreationStepProvider,
  useFooter,
  useOrderCreationContext,
  useOrderCreationStepContext,
  useOrderCreationStepperError,
} from 'src/app/context';
import { CloseModal } from 'src/app/templates';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';

const Header = React.memo(() => {
  const {
    state: { stepsConfig },
  } = useOrderCreationStepContext();
  const { activeStep, goToStep } = useWizard();
  const {
    state: {
      primarySubmitting,
      secondarySubmitting,
      primaryDisabled,
      secondaryDisabled,
    },
  } = useFooter();
  const {
    state: {
      dataCollectionMode,
      parsedExcelDetailsRecruiter,
      selectedPackage,
      orderInitiationId,
    },
  } = useOrderCreationContext();

  const handleStepClick = (index: number) => {
    if (
      !primarySubmitting &&
      !secondarySubmitting &&
      (stepsConfig[index].isVisited || stepsConfig[index].isCompleted)
    ) {
      if (index > activeStep && (primaryDisabled || secondaryDisabled)) {
        return;
      } else {
        goToStep(index);
      }
    }
  };

  const candidateInfo = useMemo(() => {
    const { validRecords } = parsedExcelDetailsRecruiter || {};
    const candidateText = validRecords === 1 ? 'Candidate' : 'Candidates';
    return validRecords ? `${validRecords} ${candidateText}` : 'No Candidates';
  }, [parsedExcelDetailsRecruiter]);

  function CustomStepIcon({
    active = false,
    completed = false,
    icon,
  }: StepIconProps) {
    return (
      <CustomStepIconRoot
        completed={completed}
        active={active}
        default={!active && !completed}>
        {completed ? (
          <Check className="!w-[14px] !h-[14px] bk-typography-subTitle2" />
        ) : (
          <Typography className="text-xs font-semibold">{icon}</Typography>
        )}
      </CustomStepIconRoot>
    );
  }

  return (
    <Box className="w-[90vw] mx-auto flex justify-start flex-col items-start py-xl2 gap-y-xl2">
      <Box className="w-full flex flex-row items-center gap-x-sm">
        <CloseModal />
        <Typography className="bk-typography-h6">
          {activeStep === 2 ? `Upload Documents` : `Order Background Check`}
        </Typography>
      </Box>

      {/* Stepper Section */}

      <Box
        className={`w-full ${
          stepsConfig.length === 2 ? 'max-w-xs' : 'max-w-xl'
        }`}>
        {activeStep < 2 && (
          <Stepper activeStep={activeStep} connector={<CustomConnector />}>
            {stepsConfig.map((step, index) => (
              <Step
                key={step.label}
                completed={step.isCompleted}
                onClick={() => handleStepClick(index)}
                className={`${
                  index === activeStep ? 'bk_stepper_bg' : 'bk_stepper-normal'
                }`}
                style={{
                  cursor:
                    step.isCompleted || step.isVisited
                      ? 'pointer'
                      : 'not-allowed',
                }}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <span
                    className="bk-typography-subTitle2"
                    style={{
                      color:
                        index === activeStep
                          ? COLORS.bk_bg_primary_dark
                          : COLORS.bk_report_text_primary,
                    }}>
                    {step.label}
                  </span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {activeStep === 2 &&
          dataCollectionMode ===
            DataCollectionItemSource.EMPLOYER_BULK_UPLOAD && (
            <Box>
              <Typography className="bk-typography-subTitle2">
                {candidateInfo} | {selectedPackage?.packageName}
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
});

const Footer = React.memo(() => {
  const { state } = useFooter();
  const {
    primaryAction,
    primaryLabel,
    secondaryAction,
    secondaryLabel,
    footerText,
    primarySubmitting,
    secondarySubmitting,
    primaryDisabled,
    secondaryDisabled,
  } = state;
  const { activeStep } = useWizard();
  const { getErrors } = useOrderCreationStepperError();

  const errors = getErrors(activeStep);
  const hasErrors = errors.length > 0;

  return (
    <Paper
      elevation={3}
      className="fixed bottom-0 left-0 w-full bg-white"
      style={{ zIndex: 1000 }}>
      <Box className="flex flex-row-reverse items-center justify-between py-lg px-[5vw]">
        <Box className="flex flex-row-reverse items-center gap-x-md">
          {primaryLabel && (
            <BKStyledButton
              variant="contained"
              color="primary"
              onClick={primaryAction || undefined}
              disabled={hasErrors || primarySubmitting || primaryDisabled}
              endIcon={
                primarySubmitting ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: COLORS.bk_text_secondary2 }}
                  />
                ) : null
              }
              className="px-xl2 py-sm w-[306px]">
              {primaryLabel}
            </BKStyledButton>
          )}
          {secondaryLabel && (
            <BKStyledButton
              variant="outlined"
              onClick={secondaryAction || undefined}
              disabled={secondarySubmitting || secondaryDisabled}
              endIcon={
                secondarySubmitting ? <CircularProgress size={20} /> : null
              }
              className="px-xl2 py-sm w-[306px]">
              {secondaryLabel}
            </BKStyledButton>
          )}
        </Box>
        <Box className="items-center">
          {footerText && !hasErrors && (
            <Box>
              <Typography variant="body2">{footerText}</Typography>
            </Box>
          )}
          {hasErrors && (
            <Box>
              <Typography variant="body2" color="error">
                Fill in the required details to move forward
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
});

interface StepContentProps {
  step: OrderCreationStepConfigInterface;
  index: number;
}

const StepContent: React.FC<StepContentProps> = React.memo(
  ({ step, index }) => {
    const { activeStep } = useWizard();

    return (
      <Box
        className="pb-[15vh] max-h-[80vh] overflow-auto"
        hidden={index !== activeStep}>
        {step.component}
      </Box>
    );
  }
);

const RenderStepperWizard = React.memo(() => {
  const {
    state: { stepsConfig },
  } = useOrderCreationStepContext();
  const { dispatch, state } = useOrderCreationContext();
  const { account_id, isactive, service_suite_id } = useSnapshot(CheckoutStore);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchOrderInitiationId = useCallback(async () => {
    if (!state.orderInitiationId) {
      // Clear the store if creating a new order
      await clearIndexedDBEntries(EnumIndexedDbStore.FILES);
      const data = {
        account_id: account_id,
        isactive: isactive,
        service_suite_id,
      };
      try {
        const response = await axiosInstance.post(
          `${endpoints.CHECKOUT_INITIATION}`,
          data
        );
        if (response.data) {
          const orderInitiationId = response?.data?.data?._id;
          const accountId = response?.data?.data?.account_id;
          const serviceSuiteId = response?.data?.data?.service_suite_id;
          dispatch({
            type: 'SET_ORDER_INITIATION_ID',
            payload: orderInitiationId,
          });
          updateCheckoutStoreOnOrderCreation(
            accountId,
            serviceSuiteId,
            orderInitiationId
          );
        }
      } catch (e) {
        setError(true);
        enqueueSnackbar('Error in initiation', {
          variant: VARIANT_TYPE.ERROR,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [isactive, state.orderInitiationId, dispatch, clearIndexedDBEntries]);

  useEffect(() => {
    fetchOrderInitiationId();
  }, [fetchOrderInitiationId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Typography className="bk-typography-h6">
          Error in initiation
        </Typography>
      </div>
    );
  }

  return (
    <Wizard header={<Header />} footer={<Footer />} startIndex={0}>
      {stepsConfig.map((step, index) => (
        <StepContent key={index} step={step} index={index} />
      ))}
    </Wizard>
  );
});

export const OrderCreation = React.memo(() => {
  return (
    <FooterProvider>
      <OrderCreationStepProvider>
        <OrderCreationStepperErrorProvider>
          <OrderCreationProvider>
            <Box className="w-screen h-screen m-0 p-0 bg-bk_bg_tertiary">
              <RenderStepperWizard />
            </Box>
          </OrderCreationProvider>
        </OrderCreationStepperErrorProvider>
      </OrderCreationStepProvider>
    </FooterProvider>
  );
});
