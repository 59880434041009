/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  BKStyledButton,
  BKStyledTextField,
  CommonDialogue,
} from '@gigin-work-space/common-ui';
import { IUserTeams } from '@gigin-work-space/model';
import {
  handleModalClose,
  handleModalOpen,
  isModalOpenStore,
  permissionStore,
  teamStore,
} from '@gigin-work-space/store';
import { canAccess } from '@gigin-work-space/utils';
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { useSnapshot } from 'valtio';
import CircularIndeterminate from '../../../component/skelton/bgv-loader';

// Employee alert document Edit
export const AlertViewTeamList = () => {
  // const { isOpen } = useSnapshot(isModalOpenStore);
  const [fetchingTeam, setFethcingTeam] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const [teamOption, setTeamOption] = useState<IUserTeams[]>(
    [] as IUserTeams[]
  );
  const { isOpen } = useSnapshot(isModalOpenStore);
  const { canReadTeams, canCreateTeams, canUpdateTeams } =
    useSnapshot(permissionStore);
  const fetchUserTeams = async () => {
    try {
      setFethcingTeam(true);
      const response = await axiosInstance.get(`${endpoints.FETCH_TEAM}`);
      if (response.data) {
        setTeamOption(response.data ?? []);
      }
    } catch (e) {
      setFethcingTeam(false);

      enqueueSnackbar(`Fetch Teams failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setFethcingTeam(false);
    }
  };

  useEffect(() => {
    if (canAccess([canCreateTeams, canReadTeams, canUpdateTeams]))
      fetchUserTeams();
  }, []);
  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">Teams</Typography>
      </Stack>
    );
  };

  const Content = () => {
    if (fetchingTeam)
      return (
        <Typography className="w-full text-center">
          <CircularIndeterminate />
        </Typography>
      );
    return (
      <Stack component={Typography}>
        {teamOption ? (
          teamOption.map(team => {
            return (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Typography className="bK-body1">{team.name}</Typography>
                <Stack
                  direction={'row'}
                  justifyContent={'space-evenly'}
                  sx={{ width: '50%', alignItems: 'center' }}>
                  <Stack direction={'row'} spacing={4}>
                    <Typography className="bK-body1">
                      {team?.memberEmails?.length ?? 0} members
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={4}>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                      {canUpdateTeams && (
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            onClick={() => {
                              teamStore.id = team?._id;
                              handleModalOpen({
                                ...isOpen,
                                teamList: false,
                                editTeam: true,
                              });
                            }}>
                            <DisplayImage
                              imageName={'edit-icon.svg'}
                              imageType="icon"
                              className="w-8 h-8 object-contain"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            );
          })
        ) : (
          <Typography variant="h6" className="w-full text-center m-4">
            No Team found!
          </Typography>
        )}
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        {canUpdateTeams && (
          <BKStyledButton
            variant="contained"
            className="rounded-lg bk-btn-large"
            sx={{ padding: '12px 20px' }}
            onClick={() => {
              handleModalClose();
            }}>
            Done
          </BKStyledButton>
        )}
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

export const AlertEditTeam = () => {
  // const { isOpen } = useSnapshot(isModalOpenStore);
  const { enqueueSnackbar } = useSnackbar();
  const [fetchingTeam, setFethcingTeam] = useState<boolean>(false);

  // const navigate = useNavigate();
  const [team, setTeam] = useState<IUserTeams>({} as IUserTeams);
  const [teamName, setTeamName] = useState('');

  const [selectedEmail, setSelectedEmail] = useState<string[]>([] as string[]);
  const [emailOption, setEmailOption] = useState<string[]>([] as string[]);
  const { canUpdateTeams } = useSnapshot(permissionStore);
  const handleEmailSelection = (event: any, value: string[]) => {
    setSelectedEmail(value);
  };
  const fetchTeam = async () => {
    try {
      setFethcingTeam(true);
      const response = await axiosInstance.get(
        `${endpoints.FETCH_TEAM}/${teamStore.id}`
      );
      if (response.data) {
        setTeam(response.data ?? []);
        setTeamName(response?.data?.name);
        setSelectedEmail(response?.data?.memberEmails);
      }
    } catch (e) {
      enqueueSnackbar(`Fetch Team failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } finally {
      setFethcingTeam(false);
    }
  };
  const handleSubmit = async () => {
    if (teamName) {
      const createData = {
        name: teamName ?? '',
        memberEmails: selectedEmail ?? [],
      };
      try {
        setFethcingTeam(true);
        const response = await axiosInstance.patch(
          `${endpoints.FETCH_TEAM}/${teamStore?.id}`,
          createData
        );
        if (response.data) {
          setFethcingTeam(false);
          handleModalClose();
          enqueueSnackbar(`Team Updated successfully!`, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        }
      } catch (e) {
        enqueueSnackbar(`Create New Team failed`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        teamStore.id = '';
        teamStore.hasRefresh = true;
        setFethcingTeam(false);
        handleModalClose();
      }
    }
  };

  const fetchAllUser = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.INVITE_EMPLOYERS_EMAIL}`
      );
      if (response.data) {
        setEmailOption(response.data?.data);
      }
    } catch (e) {
      enqueueSnackbar(`Fetch User Email failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  useEffect(() => {
    fetchTeam();
  }, [teamStore.id]);

  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">Edit Team</Typography>
      </Stack>
    );
  };

  const Content = () => {
    if (fetchingTeam) {
      return <CircularIndeterminate />;
    }
    return (
      <Stack>
        {team ? (
          <Stack component={Typography} spacing={2}>
            <Stack>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <BKStyledTextField
                  label="Name"
                  name="name"
                  value={teamName}
                  onChange={e => {
                    setTeamName(e.target.value);
                  }}
                  autoFocus
                  // error={!!errors.name}
                  // helperText={errors.name}ivn
                  inputProps={{ maxLength: 30 }}
                  required
                  margin="none"
                  sx={{ width: 260 }}
                />
              </Stack>
            </Stack>
            <Stack>
              <Divider flexItem />
            </Stack>
            <Stack>
              <Autocomplete
                multiple
                id="tags-standard"
                options={emailOption}
                value={selectedEmail}
                disableCloseOnSelect
                onChange={handleEmailSelection}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Enter Email to add"
                    placeholder="Ex: xyz@gmail.com"
                  />
                )}
                getOptionDisabled={option => selectedEmail.includes(option)}
              />
            </Stack>
          </Stack>
        ) : (
          <Typography variant="h6" className="w-full text-center m-4">
            No Team found!
          </Typography>
        )}
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        <BKStyledButton
          variant="text"
          className="rounded-lg bk-btn-large text-bk_text_secondary"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        {canUpdateTeams && (
          <BKStyledButton
            variant="contained"
            className="rounded-lg bk-btn-large"
            sx={{ padding: '12px 20px' }}
            onClick={() => {
              handleSubmit();
            }}>
            Update Team
          </BKStyledButton>
        )}
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};
