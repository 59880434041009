/* eslint-disable no-unused-vars */
import { BKFilterButton } from '@gigin-work-space/common-ui';
import { OrderCandidateStore } from '@gigin-work-space/store';
import {
  BKBody2,
  COLORS,
  FilterStatus,
  borderLessSelect,
} from '@gigin-work-space/utils';
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { DisplayImage } from 'src/app/components';
import { axiosInstance, endpoints } from 'src/app/utils';
import { EndDatePicker, FromDatePicker } from './search-filter-from-end-date';

interface IFilterProps {
  dates?: any;
  handleFilterDate?(date: any[]): void;
  selectedReportStatusModal: string;
  selectedReportGeneratedStatus: string;
  selectedStatus: string[];
  selectedInitiatedStatus: string;
  selectedProgressStatus: string[];
  handleSelectstatusChange(
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLElement>,
    // eslint-disable-next-line no-unused-vars
    value: string[]
  ): void;
  handleSelectSelectedReportGeneratedStatus(
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLElement>,
    // eslint-disable-next-line no-unused-vars
    value: string
  ): void;
  handleSubmit(): void;
  handleClose(): void;
  roles: string[];
  handleSelectSelectedRole( // eslint-disable-next-line no-unused-vars
    event: React.SyntheticEvent,
    // eslint-disable-next-line no-unused-vars
    value: string[]
  ): void;
  handleSelectSelectedInitiatedStatus(
    // eslint-disable-next-line no-unused-vars
    value: string
  ): void;
  handleSelectSelectedReportStatus(
    _event: React.MouseEvent<HTMLElement> | null, // eslint-disable-next-line no-unused-vars
    value: string
  ): void;
  handleSelectSelectedReportStatusModal(
    _event: React.MouseEvent<HTMLElement> | null, // eslint-disable-next-line no-unused-vars
    value: string
  ): void;
  handleSelectSelectedProgressStatus( // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLElement>,
    // eslint-disable-next-line no-unused-vars
    value: string[]
  ): void;
  handleSelectSelectedPackages( // eslint-disable-next-line no-unused-vars
    event: React.SyntheticEvent,
    // eslint-disable-next-line no-unused-vars
    value: string[]
  ): void;
  selectedRole: string[];
  selectedPackages: string[];
  selectedReportStatus: string;
}

interface IFilterPopUpDateProps {
  selectedDateOption: string;
  // eslint-disable-next-line no-unused-vars
  handleOptionChange(event: any): void;
  selectedOption: string;
  // eslint-disable-next-line no-unused-vars
  handleSelectDateOptionChange(event: any): void;
  handleFromDateChange(event: any): void;
  handleEndDateChange(event: any): void;
  handleSubmit(): void;
  resetValues(): void;
  handleClose(): void;
  fromDate: string;
  endDate: string;
  selectedFromDate: string;
  selectedEndDate: string;
  handleSelectedFromDateChange(event: any): void;
  handleSelectedEndDateChange(event: any): void;
}
export const FilterPopupComponent = ({
  dates,
  handleFilterDate,
  selectedStatus,
  selectedReportGeneratedStatus,
  handleSelectstatusChange,
  selectedInitiatedStatus,
  handleSubmit,
  handleClose,
  roles,
  selectedPackages,
  handleSelectSelectedRole,
  selectedRole,
  selectedReportStatus,
  selectedReportStatusModal,
  selectedProgressStatus,
  handleSelectSelectedReportGeneratedStatus,
  handleSelectSelectedPackages,
  handleSelectSelectedInitiatedStatus,
  handleSelectSelectedProgressStatus,
  handleSelectSelectedReportStatus,
  handleSelectSelectedReportStatusModal,
}: IFilterProps) => {
  const [activePackages, setActivePackages] = useState<any>([]);
  const applyFiltersButtonRef = useRef<HTMLButtonElement | null>(null);

  const { RangePicker } = DatePicker;

  const isRangeDateEmpty =
    OrderCandidateStore.range_date[0] === null ||
    OrderCandidateStore.range_date[0] === '';

  const backgroundColor = isRangeDateEmpty ? 'none' : '#f1ecfd !important';
  const border = isRangeDateEmpty ? 'none' : '1px solid #6F35F2 !important';

  const getPackageList = async () => {
    try {
      const response = await axiosInstance.get(endpoints.GET_PACKAGE_DETAILS);
      if (response.data.success) {
        setActivePackages(response.data.data);
      }
    } catch (error) {
      enqueueSnackbar('Failed to get package details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates && !dates[0] && !dates[1]) {
      handleSelectSelectedInitiatedStatus('');
    }
    if (dates && handleFilterDate) {
      handleFilterDate(dates);
    }
  };

  const disabledFutureDate = (current: Dayjs) => {
    return current.isAfter(dayjs(), 'day');
  };

  useEffect(() => {
    getPackageList();
  }, []);

  return (
    <div style={{ overflow: 'none' }}>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: '#FFF',
        }}>
        <Stack className="bK-body3 text-bk_text_secondary mb-3 px-3 pt-3">
          <span style={{ color: '#161616' }}>
            {selectedRole.length ||
            selectedPackages.length ||
            selectedProgressStatus.length ||
            selectedReportStatusModal !== FilterStatus.ALL ||
            selectedInitiatedStatus?.length ||
            selectedReportGeneratedStatus?.length
              ? `${
                  selectedRole.length +
                  selectedPackages.length +
                  selectedProgressStatus.length +
                  (selectedReportStatusModal !== FilterStatus.ALL ? 1 : 0) +
                  (selectedInitiatedStatus ? 1 : 0) +
                  (selectedReportGeneratedStatus ? 1 : 0)
                } filter(s) applied`
              : 'Select filter(s)'}
          </span>
        </Stack>
        <Divider className="mt-2" />
      </div>
      <Stack direction={'column'} className="pb-2 pt-4">
        <Stack direction={'column'}>
          <Stack className="px-3" direction={'column'} spacing={4}>
            <Stack direction={'row'} spacing={1}>
              <Typography className="bK-body2 text-bk_text_secondary black-highlighter flex !mt-2 w-20">
                <b>Roles</b>
              </Typography>
              <Autocomplete
                className="!w-[320px]"
                sx={{
                  '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                  },
                }}
                multiple
                onChange={(event, value) =>
                  handleSelectSelectedRole(event, value)
                }
                id="fixed-tags-demo"
                value={selectedRole}
                options={roles.map(role => role.toString())}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    return <Chip label={option} {...getTagProps({ index })} />;
                  })
                }
                renderInput={params => (
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '11px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '13px',
                      },
                    }}
                    {...params}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    className="!pt-0"
                    placeholder="Select Roles"
                  />
                )}
              />
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Typography className="bK-body2 text-bk_text_secondary black-highlighter flex !mt-2 w-20">
                <b>Packages</b>
              </Typography>
              <Autocomplete
                className="!w-[320px]"
                sx={{
                  '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                  },
                }}
                multiple
                value={selectedPackages}
                onChange={(event, value) =>
                  handleSelectSelectedPackages(event, value)
                }
                id="tags-filled"
                options={activePackages.map(
                  (pkg: { package_title: any }) => pkg.package_title
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '11px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '13px',
                      },
                    }}
                    {...params}
                    id="packages"
                    size="small"
                    variant="outlined"
                    placeholder="Select Packages assigned to candidates"
                  />
                )}
              />
            </Stack>

            <Stack direction={'row'} spacing={1}>
              <Typography className="bK-body2 text-bk_text_secondary black-highlighter flex !mt-2 w-20">
                <b>Report</b>
              </Typography>
              <ToggleButtonGroup
                value={selectedReportStatusModal}
                orientation="horizontal"
                exclusive
                onChange={handleSelectSelectedReportStatusModal}
                className="w-[450px] flex flex-wrap"
                sx={{
                  gap: 1,
                  '& .MuiToggleButton-root': {
                    padding: 1,
                    borderRadius: '8px !important',
                  },
                }}>
                <BKFilterButton
                  value={FilterStatus.CLEAR}
                  // sx={{
                  //   background: arrayHasValue(selectedReportStatus, 'CLEAR')
                  //     ? COLORS.bk_tag_gray_bg
                  //     : COLORS.bk_tag_action_bg,
                  // }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="verifyin-green-icon.svg"
                      imageType="image"
                      width="24px"
                      height="15px"
                    />
                    <Typography className="bK-body2 text-bk_text_primary">
                      Verifyin Green
                    </Typography>
                  </Stack>
                </BKFilterButton>
                <BKFilterButton value={FilterStatus.DISCREPANT}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="verifyin-red-icon.svg"
                      imageType="image"
                      width="24px"
                      height="15px"
                    />
                    <Typography className="bK-body2 text-bk_text_primary">
                      Verifyin Red
                    </Typography>
                  </Stack>
                </BKFilterButton>
                <BKFilterButton
                  value={FilterStatus.NO_RESPONSE}
                  className="bK-body2 text-bk_text_primary">
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="verifyin-grey-icon.svg"
                      imageType="image"
                      width="24px"
                      height="15px"
                    />
                    <Typography className="bK-body2 text-bk_text_primary">
                      Verifyin Grey
                    </Typography>
                  </Stack>
                </BKFilterButton>
                <BKFilterButton value={FilterStatus.INSUFFICIENT}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <DisplayImage
                      imageName="verifyin-amber-icon.svg"
                      imageType="image"
                      width="24px"
                      height="15px"
                    />
                    <Typography className="bK-body2 text-bk_text_primary">
                      Verifyin Amber
                    </Typography>
                  </Stack>
                </BKFilterButton>
                <BKFilterButton value={FilterStatus.INPROGRESS}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <Typography className="bK-body2 text-bk_text_primary">
                      Yet to generate
                    </Typography>
                  </Stack>
                </BKFilterButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Typography className="bK-body2 text-bk_text_secondary black-highlighter flex !mt-2 w-20">
                <b>Initiated</b>
              </Typography>
              {/* <BKFilterBKFilterButton label="TeleCaller" /> */}
              <ToggleButtonGroup
                value={selectedInitiatedStatus}
                exclusive
                orientation="horizontal"
                onChange={(event, value: string) =>
                  handleSelectSelectedInitiatedStatus(value)
                }
                className="bK-body2 text-bk_text_primary w-[450px] flex flex-wrap"
                sx={{
                  gap: 1,
                  '& .MuiToggleButton-root': {
                    padding: 1,
                    borderRadius: '16px !important',
                    maxHeight: '90%',
                    overflowY: 'auto',
                  },
                }}>
                <BKFilterButton
                  value={FilterStatus.INITIATED_THIS_MONTH}
                  className="bK-body2 text-bk_text_primary">
                  This Month
                </BKFilterButton>
                <BKFilterButton
                  value={FilterStatus.INITITATED_LAST_30_DAYS}
                  className="bK-body2 text-bk_text_primary">
                  Last 30 days
                </BKFilterButton>
                <BKFilterButton
                  value={FilterStatus.INITITATED_LAST_90_DAYS}
                  className="bK-body2 text-bk_text_primary">
                  Last 90 days
                </BKFilterButton>
                <BKFilterButton
                  className="!p-0"
                  value={FilterStatus.RANGE_DATE}
                  sx={{
                    backgroundColor,
                    border,
                  }}>
                  <RangePicker
                    disabledDate={disabledFutureDate}
                    value={dates}
                    onCalendarChange={onRangeChange}
                  />
                </BKFilterButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
          backgroundColor: '#FFF',
        }}>
        <Divider className="mt-8 mb-2 w-full" />
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className="pb-3">
          <Button
            variant="text"
            onClick={e => {
              handleSelectSelectedRole(e, []);
              handleSelectSelectedInitiatedStatus('');
              handleSelectSelectedPackages(e, []);
              handleSelectSelectedProgressStatus(e, []);
              handleSelectSelectedReportGeneratedStatus(e, '');
              handleSelectSelectedReportStatusModal(null, FilterStatus.ALL);
              handleSelectstatusChange(e, []);

              setTimeout(() => {
                if (applyFiltersButtonRef.current) {
                  applyFiltersButtonRef.current.click();
                }
              }, 500);
            }}
            className="bK-body2 text-bk_text_secondary bk-filter-reset-btn">
            Reset
          </Button>
          <Stack direction={'row'}>
            <Button
              variant="text"
              onClick={e => {
                handleClose();
              }}
              className="bK-body2 text-bk_text_secondary transform-none">
              Cancel
            </Button>
            <Button
              ref={applyFiltersButtonRef}
              variant="contained"
              onClick={() => {
                handleSubmit();
                const totalFilters =
                  selectedRole.length +
                  selectedPackages.length +
                  selectedProgressStatus.length +
                  (selectedReportStatusModal !== FilterStatus.ALL ? 1 : 0) +
                  (selectedInitiatedStatus ? 1 : 0) +
                  (selectedReportGeneratedStatus ? 1 : 0);
                if (OrderCandidateStore.allCasesFilterApplied && totalFilters) {
                  enqueueSnackbar(
                    `${totalFilters} filter(s) applied successfully`,
                    {
                      variant: 'success',
                      autoHideDuration: 2000,
                    }
                  );
                }
                handleClose();
              }}
              className="bK-body2 mr-2 bk-apply-filter-btn">
              Apply filter(s)
            </Button>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export const FilterPopupDateComponent = ({
  handleOptionChange,
  handleSelectDateOptionChange,
  selectedDateOption,
  selectedOption,
  handleFromDateChange,
  handleEndDateChange,
  handleSubmit,
  resetValues,
  handleClose,
  fromDate,
  endDate,
  selectedEndDate,
  selectedFromDate,
  handleSelectedEndDateChange,
  handleSelectedFromDateChange,
}: IFilterPopUpDateProps) => {
  return (
    <Stack direction={'column'} className="px-4 pb-2 pt-4">
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Stack>
          <Typography>
            <span className="bk-sub-heading2 mr-1">Date Filter</span>
          </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Button
            variant="text"
            onClick={() => {
              resetValues();
            }}
            className="bK-body2 text-bk_text_secondary cursor-pointer transform-none">
            Clear all
          </Button>
          <Button
            disabled={
              dayjs(selectedFromDate).format('DD-MM-YYYY') === 'Invalid Date' ||
              dayjs(selectedEndDate).format('DD-MM-YYYY') === 'Invalid Date'
                ? true
                : false
            }
            variant="contained"
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
            className="rounded-lg bK-body2 transform-none">
            Apply filter(s)
          </Button>
        </Stack>
      </Stack>
      <Stack direction={'column'} className="py-2 px-4" spacing={1.5}>
        <RadioGroup
          row
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{
            ...BKBody2,
            color: COLORS.bk_text_secondary,
          }}
          // className="bK-body2 text-bk_text_secondary"
        >
          <FormControlLabel
            value="order_date"
            control={<Radio />}
            // className="bK-body2 text-bk_text_secondary"
            sx={{
              ...BKBody2,
              color: COLORS.bk_text_secondary,
            }}
            label="By Initiation Date"
          />
          <FormControlLabel
            value="report_date"
            // className="bK-body2 text-bk_text_secondary"
            sx={{
              ...BKBody2,
              color: COLORS.bk_text_secondary,
            }}
            control={<Radio />}
            label="By Report Date"
          />
        </RadioGroup>
        <FormControl
          variant="standard"
          sx={{
            minWidth: 360,
            '& .MuiInput-input': {
              padding: '8px',
              border: `1px solid ${COLORS.bk_stroke_primary}`,
              borderRadius: '8px',
              background: COLORS.bk_bg_primary,
            },
          }}>
          <Select
            id="option-date"
            name="option-date"
            value={selectedDateOption}
            onChange={handleSelectDateOptionChange}
            label="option date"
            sx={borderLessSelect}
            className="bK-body2 text-bk_text_secondary">
            {/* TODO: HAve to split function return values */}
            <MenuItem
              value={'last7days'}
              className="bK-body2 text-bk_text_secondary">
              Last 7 days
            </MenuItem>
            <MenuItem
              value={'last15days'}
              className="bK-body2 text-bk_text_secondary">
              Last 15 days
            </MenuItem>
            <MenuItem
              value={'last30days'}
              className="bK-body2 text-bk_text_secondary">
              Last 30 days
            </MenuItem>
            <MenuItem
              value={'last60days'}
              className="bK-body2 text-bk_text_secondary">
              Last 60 days
            </MenuItem>
            <MenuItem
              value={'custom'}
              className="bK-body2 text-bk_text_secondary">
              Custom date
            </MenuItem>
          </Select>
        </FormControl>
        {selectedDateOption === 'custom' && (
          <Stack direction={'row'} spacing={0.5}>
            <FromDatePicker
              handleFromDateChange={handleFromDateChange}
              fromDate={fromDate}
              handleSelectedFromDateChange={handleSelectedFromDateChange}
              selectedFromDate={selectedFromDate}
            />
            <EndDatePicker
              handleEndDateChange={handleEndDateChange}
              minDate={fromDate}
              handleSelectedEndDateChange={handleSelectedEndDateChange}
              selectedEndDate={selectedEndDate}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
