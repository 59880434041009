import { tenantState } from './tenantState';

export const globalNavigateTo = (
  originalUrl: string,
  navigate: any,
  state = {}
) => {
  // Get the current tenant name from the tenantState
  const tenantName = tenantState.tenantName;

  // console.log('tenantName- globalnavigateTo', tenantName);

  // Construct the target URL based on the tenant name
  // If the tenant is 'default', use the original URL as-is.
  // Otherwise, prefix the tenant name to the original URL.
  const targetUrl =
    tenantName === 'default' ? originalUrl : `/${tenantName}${originalUrl}`;

  // Perform the navigation to the target URL with state for passing props might delete later
  navigate(targetUrl, { state });
};
