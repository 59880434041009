import { COLORS } from '@gigin-work-space/utils';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { InviteEmailProps } from './interface';

const InviteEmailComponent = ({ state, setState }: InviteEmailProps) => {
  const processInput = () => {
    const value = state.value.trim();

    if (value && isValid(value)) {
      setState(prevState => ({
        ...prevState,
        items: [...prevState.items, state.value],
        value: '',
      }));
    }
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      processInput();
    }
  };

  const handleBlur = () => {
    processInput();
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      value: evt.target.value,
      error: null,
    }));
  };

  const handleDelete = (item: string) => {
    setState(prevState => ({
      ...prevState,
      items: prevState.items.filter((i: string) => i !== item),
    }));
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email));

      setState(prevState => ({
        ...prevState,
        items: [...prevState.items, ...toBeAdded],
      }));
    }
  };

  const isValid = (email: string): boolean => {
    let error: string | null = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setState(prevState => ({
        ...prevState,
        error,
      }));

      return false;
    }

    return true;
  };

  const isInList = (email: string): boolean => {
    return state.items?.includes(email);
  };

  const isEmail = (email: string): boolean => {
    return /[\w\d\\.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the input field after each render
  React.useEffect(() => {
    if (inputRef.current && state.value) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        border: `1px solid ${COLORS.bk_stroke_primary}`,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          gap: 1,
          display: 'flex',
          mb: 1,
          flexWrap: 'wrap',
        }}>
        {state.items?.map(item => (
          <Box
            component={Typography}
            className="bK-body1"
            sx={{
              py: 0.5,
              px: 1,
              display: 'flex',
              borderRadius: 4,
              border: `1px solid ${COLORS.bk_stroke_primary}`,
              width: 'min-content',
              alignItems: 'center',
            }}
            key={item}>
            {item}
            <CloseIcon
              sx={{ ml: 0.5, fontSize: 'initial', cursor: 'pointer' }}
              onClick={() => handleDelete(item)}
            />
          </Box>
        ))}
      </Stack>
      <TextField
        inputRef={inputRef} //to make focus after changing state
        error={state.error ? true : false}
        value={state.value}
        placeholder="Type or paste email addresses and press Enter ..."
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        onBlur={handleBlur}
        fullWidth
        variant="standard"
        helperText={state.error}
        autoFocus
      />
    </Box>
  );
};

export default InviteEmailComponent;
