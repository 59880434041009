/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Context,
  ISelectionType,
  IToggleValueType,
} from '@gigin-work-space/store';
import {
  DEFAULT_USER_NAME,
  isProduction,
  PRODUCT_FRUITS_LANGUAGE,
  useLocalStorage,
  useRoutePermission,
} from '@gigin-work-space/utils';
import { CssBaseline, GlobalStyles } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material/styles';
import React, { useState } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useSnapshot } from 'valtio';
import { AccountsProvider } from './features/ops-platform/store/AccountContext';
import { CheckProvider } from './features/ops-platform/store/ChecksContext';
import RouterWidget from './utils/router/router-widget';
import { UserManger, UserStore } from './utils/store';

declare module 'valtio' {
  function useSnapshot<T extends object>(p: T): T;
}

const workspaceCode = isProduction()
  ? process.env['NX_REACT_PRODUCT_FRUITS_WORKSPACE_CODE']
  : null;

export function App() {
  const [userTheme] = useState('light');
  const { themeOption } = useSnapshot(UserStore);
  UserManger.setTheme(userTheme);
  const theme = createTheme(themeOption as ThemeOptions);
  // for maintain Toggle
  const [toggle, setToggle] = useState({
    status: false,
    message: '',
  });
  const [selctionValue, setSelctionValue] = useState({
    status: 0,
  });

  const [userDetails] = useLocalStorage('userAccountDetail', {});

  const userInfo = {
    username: userDetails?.name || DEFAULT_USER_NAME,
    role: userDetails?.roleName || '',
    email: userDetails?.email || '',
  };

  const toggleValueChange = (value: IToggleValueType) => {
    setToggle(value);
  };
  const formSelectionValueChange = (value: ISelectionType) => {
    setSelctionValue(value);
  };
  // permission Hook
  useRoutePermission();
  return (
    <React.Suspense fallback={null}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles
            styles={{
              '*': {
                margin: 0,
                padding: 0,
                outline: 0,
              },
              '*:focus': {
                outline: 'none !important',
              },
              fontFamily: 'Inter',
            }}
          />
          <CssBaseline />

          {workspaceCode && (
            <ProductFruits
              workspaceCode={workspaceCode as string}
              language={PRODUCT_FRUITS_LANGUAGE}
              user={userInfo}
            />
          )}

          <AccountsProvider>
            <CheckProvider>
              <Context.Provider
                value={{
                  toggleValue: toggle,
                  setToggleValue: toggleValueChange,
                  formSelectionStatus: selctionValue,
                  setFormSelectionStatus: formSelectionValueChange,
                }}>
                <RouterWidget />
              </Context.Provider>
            </CheckProvider>
          </AccountsProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Suspense>
  );
}

export default App;
