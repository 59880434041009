import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

export interface AuthenticationHOCProps {
  children: React.ReactNode;
}

export const AuthenticationHOC = (props: AuthenticationHOCProps) => {
  const { children } = props;
  const navigate = useNavigate();
  return (
    <Grid container className="auth-hoc-container">
      <Grid
        item
        xl={6}
        lg={5}
        md={5}
        className="relative"
        // display={isNotExtraLargeScreen ? 'flex' : 'block'}
      >
        <section className="auth-logo-container">
          <DisplayImage
            imageName="auth_page_logo_image.svg"
            imageType="image"
            width="464px"
            height="180px"
          />
        </section>
      </Grid>
      <Grid item md={7} xl={6} lg={7}>
        <section className="auth-illustration-container">
          <DisplayImage
            imageName="auth_page_form_illustration.svg"
            imageType="image"
            width="456px"
            height="486px"
          />
          {children}
        </section>
        {/* Terms & Conditions */}
        <Typography className="absolute bottom-6 right-8 bk-btn-large mt-6">
          <span
            className="text-bk_tag_blue_light cursor-pointer"
            onClick={() => globalNavigateTo('/terms_condition', navigate)}>
            Terms & Conditions{' '}
          </span>{' '}
          <span className="text-bk_text_primary">&</span>{' '}
          <span
            className="text-bk_tag_blue_light cursor-pointer"
            onClick={() => globalNavigateTo('/privacy_policy', navigate)}>
            {' '}
            Privacy policy
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
