import { proxy } from 'valtio';

// userdata type
interface ICompanydataType {
  companyName: string | null;
  isCompanyRegistered: boolean;
  isCompanyVerified: boolean;
}

// userStore Type
interface ICompanyStoreType {
  selectedCompany: ICompanydataType;
}

// User dummy store creation
export const CompanyStore: ICompanyStoreType = proxy({
  selectedCompany: {
    companyName: '',
    isCompanyRegistered: false,
    isCompanyVerified: false,
  },
});
