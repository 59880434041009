import {
  BKStyledButton,
  BKStyledEBTableCell,
  BKStyledTextField,
  CommonDialogue,
} from '@gigin-work-space/common-ui';
import { IUserRole, IUserTeams } from '@gigin-work-space/model';
import {
  CheckoutStore,
  EmployerCDCStore,
  handleModalClose,
  handleModalOpen,
  isModalOpenStore,
  permissionStore,
  teamStore,
} from '@gigin-work-space/store';
import {
  COLORS,
  CORE_USER_ROLE,
  borderLessSelect,
  canAccess,
  userRoleMap,
} from '@gigin-work-space/utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GavelIcon from '@mui/icons-material/Gavel';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DuplicateCandidateTable from 'src/app/components/duplicate-candidate-table/duplicate-candidate-table';
import { InvalidCandidateTable } from 'src/app/components/duplicate-candidate-table/invalid-table';
import { axiosInstance, baseUrl, endpoints } from 'src/app/utils';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';
import { tenantState } from 'src/app/utils/router/tenantState';
import { useSnapshot } from 'valtio';
import { DisplayImage } from '../../../components';
import { InviteEmailState } from '../company-settings';
import InviteEmailComponent from '../company-settings/components/teams/invite-email-component';

// Missing ConfirmationAlert
export const MissingRecordConfirmationAlert = ({
  handleVerifyStep,
}: {
  handleVerifyStep: () => void;
}) => {
  const { meta, initiate_id } = useSnapshot(CheckoutStore);

  const InitiateApiCall = async () => {
    try {
      if (initiate_id) {
        const response = await axiosInstance.get(
          `${endpoints.CREATE_CHECKOUT_INIT}/${initiate_id}`
        );
        if (response.data) {
          handleModalClose();

          CheckoutStore.order_init_id = response.data.data._id;
          CheckoutStore.nextPage = true;
          handleVerifyStep();
        }
      }
    } catch (e) {
      enqueueSnackbar('Error initiating verification', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const Header = () => {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ px: 1, py: 2, display: 'flex', gap: 1 }}>
        <DisplayImage
          imageName="warning_icon.svg"
          imageType="icon"
          width="24px"
          height="24px"
        />
        <Typography
          className="bk-headline2"
          sx={{
            width: '553px',
            display: 'flex',
            flexWrap: 'wrap',
            whiteSpace: 'initial',
          }}>
          Only {meta.validItem} candidates will be processed for verification
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2">
          {meta.invalidItem} candidate records have missing/Invalid data. You
          can download the list and upload them with valid data.{' '}
        </Typography>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            InitiateApiCall();
          }}>
          Continue with {meta.validItem}
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// missing invalid data alert
export const MissingInvalidDataAlert = () => {
  const { meta, initiate_id } = useSnapshot(CheckoutStore);
  const { enqueueSnackbar } = useSnackbar();

  // dummy data

  const handleDowloadInvalid = () => {
    const accessToken = localStorage.getItem('auth_token');
    const trimmedAccessToken = accessToken ? accessToken.replace(/"/g, '') : '';
    const apiUrl = `${baseUrl}${endpoints.DOWNLOAD_INVALID}/${initiate_id}/download`;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${trimmedAccessToken}`);
    headers.append('x-tenant-name', tenantState.tenantName);
    headers.append(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    //TODO: NEED TO CONVERTED TO AXIS WITH PROPER CONTENT TYPE
    fetch(apiUrl, { headers })
      .then(response => {
        // Handle the response
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(data => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'invalidList.xlsx'; // Set the desired file name and extension
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the object URL

        enqueueSnackbar(`File downloaded successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        handleModalClose(); // Call your function to close the modal or handle any other cleanup
      });
  };

  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1} className="flex items-center">
        <DisplayImage
          imageName="warning_icon.svg"
          imageType="icon"
          width="20px"
          height="18px"
        />
        <Typography className="bk-headline2">
          {meta.invalidItem} Candidate with Missing/invalid Data
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2">
          The verification for these candidates will not be initiated when u
          continue. You cn download the list and upload it again with valid data
        </Typography>
        {/* duplicate candidate Table */}
        <InvalidCandidateTable />
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleDowloadInvalid();
          }}>
          Download list
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// Duplicate candidate Alert
export const DuplicateCandidateRecordAlert = () => {
  const { meta, initiate_id } = useSnapshot(CheckoutStore);
  const { enqueueSnackbar } = useSnackbar();

  // dummy data

  const handleDowloadDuplicate = () => {
    const accessToken = localStorage.getItem('auth_token');
    const trimmedAccessToken = accessToken ? accessToken.replace(/"/g, '') : '';
    const apiUrl = `${baseUrl}${endpoints.DOWNLOAD_DUBLICATE}/${initiate_id}/download`;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${trimmedAccessToken}`);
    headers.append('x-tenant-name', tenantState.tenantName);
    headers.append(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    //TODO: NEED TO CONVERTED TO AXIS WITH PROPER CONTENT TYPE
    fetch(apiUrl, { headers })
      .then(response => {
        // Handle the response
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(data => {
        // Handle the data (blob)
        // For example, you can create a downloadable link or display the file in the browser
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'duplicateList.xlsx'; // Set the desired file name and extension
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the object URL
        enqueueSnackbar(`File downloaded successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        handleModalClose(); // Call your function to close the modal or handle any other cleanup
      });
  };

  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1} className="flex items-center">
        <DisplayImage
          imageName="warning_icon.svg"
          imageType="icon"
          width="20px"
          height="18px"
        />
        <Typography className="bk-headline2">
          {meta.duplicateItem} Duplicate Candidate records found
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2">
          These candidate records are already present in your database. To
          request verification of these candidates as well, select ‘Include’
        </Typography>
        {/* duplicate candidate Table */}
        <DuplicateCandidateTable />
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Do not include these candidates
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleDowloadDuplicate();
          }}>
          {/* Include ({meta.duplicateItem}) candidates */}
          Download list
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// Additional check alert
export const AdditionalCheckAlert = () => {
  const [selectCheck, setSelectCheck] = React.useState('');
  const [checked, setChecked] = React.useState(true);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectCheck(event.target.value as string);
  };
  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">
          Additional check- Delivery executive
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        {/* select box */}
        {!selectCheck ? (
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Check
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectCheck}
                label="Select Check"
                onChange={handleChange}>
                {[0, 1, 2, 3, 4].map(data => {
                  return (
                    <MenuItem value={data}>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}>
                        <GavelIcon />
                        <Stack direction={'column'}>
                          <Typography className="bk-sub-heading1">
                            Court room Check
                          </Typography>
                          <Typography className="text-bk_text_secondary bk-body2">
                            Checking criminal records
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <GavelIcon />
              <Stack direction={'column'}>
                <Typography className="bk-sub-heading1">
                  Court room Check{' '}
                  <span
                    className="text-[#0053F3] cursor-pointer"
                    onClick={() => {
                      setSelectCheck('');
                    }}>
                    Change
                  </span>
                </Typography>
                <Typography className="text-bk_text_secondary bk-body2">
                  Checking criminal records
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}

        <Typography className="bK-body2">
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckChange} />
            }
            label="Apply this check for all 25 Delivery Executives"
          />
        </Typography>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          disabled={selectCheck ? false : true}
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {}}>
          Add Check(s)
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// Additional check alert
export const AlertUploadDocument = () => {
  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">
          Links sent to Candidates for uploading documents
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack direction={'column'}>
        {/* image col */}
        <Stack justifyContent={'center'} alignItems={'center'}>
          <DisplayImage
            imageName="telegram-send-gif-icon.gif"
            imageType="icon"
            width="208px"
            height="208px"
          />
        </Stack>
        {/* bottom instruction */}
        <Stack direction={'column'} spacing={1}>
          <Typography className="bK-body1">
            We had sent links to candidates to provide the details. the checks
            will be initated when necessary data is received for the check.
          </Typography>
          <Typography className="bK-body1">
            Alternatively, you can also{' '}
            <span className="underline text-[#0053F3]">
              upload the candidate data
            </span>{' '}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
            CheckoutStore.reload = !CheckoutStore.reload;
          }}>
          Okay, Understood
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

export const AlertSubmitData = () => {
  const Header = () => {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ display: 'flex' }}>
        <Typography>
          <Typography
            className="bk-headline2"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              whiteSpace: 'initial',
            }}>
            Submit Candidate data
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2">
          Once the data is submitted, you will not be able to edit the data of
          candidates. Do you want to submit?
        </Typography>
      </Stack>
    );
  };

  const handleSubmit = async () => {
    try {
      await axiosInstance.get(
        `${endpoints.BULK_UPLOAD_CONFIRM}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
      );

      enqueueSnackbar('Data submitted successfully for all candidates.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      handleModalClose();
      EmployerCDCStore.activeStep = 0;
    } catch (error) {
      // Handle errors, e.g., log or set state
      console.error('Error fetching data:', error);
    }
  };

  const handleContinueLater = () => {
    try {
      enqueueSnackbar(
        'You are yet to submit the data. All your changes have been saved.',
        {
          variant: 'warning',
          autoHideDuration: 2000,
        }
      );
      handleModalClose();
      EmployerCDCStore.activeStep = 0;
    } catch (error) {
      // Handle errors, e.g., log or set state
      console.error('Error fetching data:', error);
    }
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
        }}>
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large"
          onClick={handleContinueLater}
          sx={{ padding: '12px 20px' }}>
          Continue editing later
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          onClick={handleSubmit}
          sx={{ padding: '12px 20px' }}>
          Submit now
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

export const AlertConfirmClear = (props: any) => {
  const files = props.files;

  // todo: Future release
  const Header = () => {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ display: 'flex' }}>
        <DisplayImage
          imageName="warning_icon.svg"
          imageType="icon"
          width="24px"
          height="24px"
        />
        <Typography>
          <Typography className="bk-headline2 bk-alert-confirm-clear-typography">
            Are you sure you want to clear all data?
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2">
          All the candidate details you have entered here will be deleted and
          cannot be retrieved. The data which is collected and confirmed by the
          candidate will not be deleted. Do you want to continue?
        </Typography>
      </Stack>
    );
  };

  const handleClearData = async () => {
    try {
      await axiosInstance.delete(
        `${endpoints.BULK_TEMPLATE_DATA_DELETE}/${EmployerCDCStore.request_id}/${EmployerCDCStore.request_role_id}`
      );

      files.map(async (row: { _id: any }) => {
        try {
          await axiosInstance.delete(
            `${endpoints.UPLOAD_EMPLOYER_BULK_S3}/${row?._id}`
          );
        } catch (e) {
          enqueueSnackbar('Error deleting file!', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      });
      EmployerCDCStore.toggle = !EmployerCDCStore.toggle;
      handleModalClose();
    } catch (error) {
      // Handle errors, e.g., log or set state
      console.error('Error fetching data:', error);
    }
  };

  const ActionButton = () => {
    return (
      <Box className="bk-action-button">
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-btn-large mb-8 py-3 px-5"
          onClick={handleModalClose}>
          No, go back
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large w-52 mr-8 mb-8 py-3 px-5"
          onClick={handleClearData}>
          Clear data
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

interface ModifiedDataType {
  [key: string]: string;
}
type RolesState = Record<string, string>;
export const DataMapping = (props: any) => {
  const [roles, setRoles] = useState<RolesState>({});
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [numberOfColumnsNotMatched, setNumberOfColumnsNotMatched] =
    useState<number>(0);

  const postDocUploadToOrderID = props.postDocUploadToOrderID;
  const mediaID = props.mediaID;
  const headerKeys = props.headerKeys;
  const excelData = props.excelData;

  const matchHeader = async () => {
    const tempRoles: RolesState = {};
    headerKeys.map((header: string) => {
      for (const excelHeader of Object.keys(excelData[0])) {
        if (header === excelHeader) {
          tempRoles[header] = excelHeader;
          break;
        }
      }
    });

    setRoles(tempRoles);

    setNumberOfColumnsNotMatched(
      headerKeys.length - Object.keys(tempRoles).length
    );

    if (headerKeys.length - Object.keys(tempRoles).length === 0) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    matchHeader();
  }, []);

  useEffect(() => {
    if (!openModal) {
      handleSubmit();
    }
  }, [openModal]);

  const handleChangeRole = (
    event: SelectChangeEvent<any>,
    headerValue: string
  ) => {
    const selectedRole = event.target.value;

    const keys = Object.keys(roles);

    let flag = 0;
    keys.map(key => {
      if (roles[key] === selectedRole) {
        let newRoles = {};
        setRoles(prevRoles => {
          delete prevRoles[`${key}`];
          newRoles = {
            ...prevRoles,
            [headerValue]: selectedRole,
          };
          return newRoles;
        });
        setNumberOfColumnsNotMatched(
          headerKeys.length - Object.keys(newRoles).length
        );
        flag = 1;
      }
    });

    if (flag === 0) {
      let newRoles = {};
      setRoles(prevRoles => {
        newRoles = {
          ...prevRoles,
          [headerValue]: selectedRole,
        };
        return newRoles;
      });

      setNumberOfColumnsNotMatched(
        headerKeys.length - Object.keys(newRoles).length
      );
    }
  };

  // todo: Future release
  const Header = () => {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className="flex gap-2">
        <Typography className="bk-headline2 bk-common-alert-modal-header-typography">
          Match column header with fields
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack spacing={1}>
        <Typography className="bK-body2 !mb-4 black-highlighter">
          We found that a few of the column headers do not match with the
          required fields. Select the column header that matches with the field
          name.
        </Typography>

        {numberOfColumnsNotMatched > 0 ? (
          <Typography className="bK-body2 bk-orange-highlighter mt-4">
            {numberOfColumnsNotMatched} column(s) not matching
          </Typography>
        ) : (
          ''
        )}
        <TableContainer>
          <Table
            stickyHeader
            aria-label="customized table"
            className="w-full bk-data-mapping-table-border">
            <TableHead>
              <TableRow className="text-left">
                <BKStyledEBTableCell className="bk-sub-heading3 bk-data-mapping-table-header">
                  Field Name
                </BKStyledEBTableCell>
                <BKStyledEBTableCell className="bk-sub-heading3 bk-data-mapping-table-header">
                  Column header in Excel
                </BKStyledEBTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {headerKeys
                .slice(1)
                .map(
                  (
                    headerValue: string,
                    index: React.Key | null | undefined
                  ) => (
                    <TableRow key={index}>
                      <BKStyledEBTableCell className="bk-sub-heading2 bk-data-mapping-table-body">
                        <b>{headerValue}</b>
                      </BKStyledEBTableCell>
                      <BKStyledEBTableCell className="bk-data-mapping-table-body">
                        <Box>
                          <FormControl fullWidth>
                            <Select
                              labelId="role-select-label"
                              id="role-select-label"
                              style={{
                                maxWidth: '200px',
                              }}
                              value={
                                roles[headerValue] ? roles[headerValue] : ''
                              }
                              onChange={event =>
                                handleChangeRole(event, headerValue)
                              }>
                              {excelData.length &&
                                Object.keys(excelData[0])
                                  .slice(1)
                                  .map((role: any, roleIndex) => (
                                    <MenuItem key={roleIndex} value={role}>
                                      <span className="bK-body2">{role}</span>
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </BKStyledEBTableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  };

  const handleSubmit = async () => {
    if (numberOfColumnsNotMatched > 0) {
      enqueueSnackbar('Some columns are not matched', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      const orderId = EmployerCDCStore.request_id;
      const packageId = EmployerCDCStore.request_role_id;

      const modifiedData: ModifiedDataType[] = [];
      excelData.map((data: { [x: string]: string }) => {
        const tempData: Record<string, string> = {};
        headerKeys.map((header: string) => {
          tempData[header] = data[roles[header]];
        });
        modifiedData.push(tempData);
      });

      try {
        await axiosInstance.patch(
          `${endpoints.BULK_TEMPLATE_DATA_PATCH}/${orderId}/${packageId}`,
          modifiedData
        );
      } catch (error) {
        enqueueSnackbar('Submitted data is incomplete', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }

      // Adding this line here becuase we are executing all the action regardless of error,
      // we'll throw error for data but we still show excel data
      postDocUploadToOrderID(mediaID);

      handleModalClose();
    }
  };

  const ActionButton = () => {
    return (
      <Box className="bk-action-button mb-5">
        <BKStyledButton
          variant="text"
          className="text-bk_text_secondary bk-common-alert-modal-actionButton mr-8"
          onClick={handleModalClose}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-common-alert-modal-actionButton mr-5"
          onClick={handleSubmit}>
          Confirm schema
        </BKStyledButton>
      </Box>
    );
  };
  return openModal ? (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  ) : (
    ''
  );
};

// Candidate alert document collection
export const AlertDocumentCollection = () => {
  const { isOpen } = useSnapshot(isModalOpenStore);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // todo: Future release
  const handleMailSendApi = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.INITIAITE_EMAIL}/${CheckoutStore.confirmation_id}`
      );
      if (response.data) {
        enqueueSnackbar(
          `Link send successfully to ${
            CheckoutStore.totalCandidate ?? ''
          } candidates!`,
          {
            variant: 'success',
            autoHideDuration: 2000,
          }
        );
        handleModalOpen({
          ...isOpen,
          alertUploadDoc: true,
        });
      }
    } catch (error) {
      enqueueSnackbar(`Link not sent to Candidates`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      handleModalClose();
    }
  };

  const handleCollectFromCandidate = () => {
    localStorage.setItem('order_id', CheckoutStore.confirmation_id);
    globalNavigateTo(
      `/bgv/bulk-upload/${CheckoutStore.orderId?.toLowerCase()}/candidate-data-management`,
      navigate
    );
    handleModalClose();
  };
  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">
          Candidate details and document collection
        </Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack component={Typography} className="bK-body1">
        You can allow for the candidate to self upload from their end by sending
        them a link or you can upload the documents manually
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        <BKStyledButton
          variant="outlined"
          className="rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleCollectFromCandidate();
          }}>
          Upload candidate details
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleMailSendApi();
          }}>
          Send link to candidate
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// Employee alert document Edit
export const AlertEditEmployee = () => {
  const { enqueueSnackbar } = useSnackbar();
  interface FormData {
    name: string;
    email: string;
  }

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [typingTimeout, setTypingTimeout] = useState<number | null>(null);

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '',
    }));
    // Clear previous typing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set new typing timeout
    setTypingTimeout(
      window.setTimeout(() => {
        handleValidation(name, value);
      }, 500)
    );
  };

  const handleValidation = (name: string, value: string) => {
    const validationErrors: Partial<FormData> = {};

    if (name === 'email') {
      // Email validation logic
      const emailRegex = /^\S+@\S+\.\S+$/;
      if (!value.trim()) {
        validationErrors.email = 'Email is required';
      } else if (!emailRegex.test(value)) {
        validationErrors.email = 'Invalid email';
      }
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...validationErrors,
    }));
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const validationErrors: Partial<FormData> = {};

    if (name === 'email') {
      // Email validation logic
      const emailRegex = /^\S+@\S+\.\S+$/;
      if (!value.trim()) {
        validationErrors.email = 'Email is required';
      } else if (!emailRegex.test(value)) {
        validationErrors.email = 'Invalid email';
      }
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...validationErrors,
    }));
  };

  const handleSubmit = async () => {
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // event.preventDefault();
    const { name, email } = formData;
    const validationErrors: Partial<FormData> = {};

    // Validation logic for name
    if (!name.trim()) {
      validationErrors.name = 'Name is required';
    }

    // Validation logic for email
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      validationErrors.email = 'Invalid email';
    }

    if (Object.keys(validationErrors).length === 0) {
      // Perform any additional form submission logic here
    }
    setErrors(validationErrors);

    if (formData && role) {
      const createData = {
        name: formData.name ?? '',
        email: formData.email ?? '',
        memberEmails: team ?? [],
        role: role ?? '',
      };
      try {
        const response = await axiosInstance.post(
          endpoints.CREATE_TEAM,
          createData
        );
        if (response.data) {
          handleModalClose();
        }
      } catch (e) {
        enqueueSnackbar(`Create New Team failed`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const [role, setRole] = useState('recrutier');

  const names = [
    'Humaira Sims',
    'Santiago Solis',
    'Dawid Floyd',
    'Mateo Barlow',
    'Samia Navarro',
    'Kaden Fields',
    'Genevieve Watkins',
    'Mariah Hickman',
    'Rocco Richardson',
    'Harris Glenn',
  ];

  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };
  const [team, setTeam] = React.useState([] as string[]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setTeam(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">Edit</Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack component={Typography} spacing={3}>
        <Typography className="bK-body2">
          An email will be sent to the team member after creation, for them to
          log on to the platform
        </Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <BKStyledTextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleStateChange}
            onBlur={handleBlur}
            error={!!errors.name}
            helperText={errors.name}
            margin="none"
            sx={{ width: 260 }}
            inputProps={{ maxLength: 30 }}
          />
          <BKStyledTextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleStateChange}
            onBlur={handleBlur}
            error={!!errors.email}
            helperText={errors.email}
            margin="none"
            sx={{ width: 260 }}
            inputProps={{ maxLength: 30 }}
          />
        </Stack>
        <Stack>
          <Divider flexItem />
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <FormControl
            variant="standard"
            sx={{
              '& .MuiInput-input': {
                padding: '8px',
                border: `1px solid ${COLORS.bk_stroke_primary}`,
                borderRadius: '8px',
                background: COLORS.bk_bg_primary,
              },
            }}
            fullWidth>
            <InputLabel>Select Team</InputLabel>
            <Select
              sx={borderLessSelect}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={team}
              onChange={handleChange}
              // input={<OutlinedInput label="Tag" />}
              className="bK-body2 text-bk_text_secondary"
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}>
              <Stack
                className="justify-end text-bk_tag_blue_light cursor-pointer"
                onClick={() => {
                  setTeam([]);
                }}>
                Clear all
              </Stack>
              {names.map(name => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={team.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* role */}
          <FormControl
            variant="standard"
            sx={{
              '& .MuiInput-input': {
                padding: '8px',
                border: `1px solid ${COLORS.bk_stroke_primary}`,
                borderRadius: '8px',
                background: COLORS.bk_bg_primary,
              },
            }}
            fullWidth>
            <InputLabel>Select role</InputLabel>
            <Select
              id="role"
              name="role"
              value={role}
              onChange={handleRoleChange}
              label="role"
              sx={borderLessSelect}
              className="bK-body2 text-bk_text_secondary"
              MenuProps={MenuProps}>
              <MenuItem
                value={'recrutier'}
                className="bK-body2 text-bk_text_secondary">
                Recruiter
              </MenuItem>
              <MenuItem
                value={'admin'}
                className="bK-body2 text-bk_text_secondary">
                admin
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        <BKStyledButton
          variant="outlined"
          className="rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="bg-bk_action_primary rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleSubmit();
          }}>
          Create
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

// Employee alert document Invite Emp
export const AlertInviteEmployee = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [roleOption, setRoleOption] = useState<IUserRole[]>([] as IUserRole[]);
  const [teamOption, setTeamOption] = useState<IUserTeams[]>(
    [] as IUserTeams[]
  );
  const [role, setRole] = useState(roleOption[0]);
  const {
    canReadTeams,
    canReadRoles,
    canCreateTeams,
    canUpdateTeams,
    canUpdateRoles,
    canCreateRoles,
  } = useSnapshot(permissionStore);
  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  const [selectedEmail, setSelectedEmail] = useState<InviteEmailState>({
    items: [],
    error: '',
    value: '',
  });

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectedTeam, setSelectedTeam] = useState<IUserTeams[]>(
    [] as IUserTeams[]
  );
  const { isOpen } = useSnapshot(isModalOpenStore);

  const handleTeamSelection = (event: any, value: IUserTeams[]) => {
    setSelectedTeam(value);
  };

  const fetchUserRoles = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_USER_ROLE}`);
      if (response.data) {
        setRoleOption(response.data ?? []);
      }
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(`Fetch User Roles failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const fetchUserTeams = async () => {
    try {
      const response = await axiosInstance.get(`${endpoints.FETCH_TEAM}`);
      if (response.data) {
        setTeamOption(response.data ?? []);
      }
    } catch (error) {
      console.error('error', error);
      enqueueSnackbar(`Fetch Teams failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const handleSubmit = async () => {
    if (role && selectedEmail) {
      const createData = {
        memberEmails: selectedEmail.items ?? [],

        roleId:
          roleOption.find(obj => obj.name === (role as unknown as string))
            ?._id ?? '',
        teamId: selectedTeam.map(team => team._id) ?? [],
      };
      try {
        const response = await axiosInstance.post(
          endpoints.INVITE_EMPLOYERS,
          createData
        );
        if (response.data) {
          handleModalClose();
        }
        response.data.map((message: string) =>
          enqueueSnackbar(`${message}`, {
            variant: 'warning',
            autoHideDuration: 2000,
          })
        );
      } catch (error) {
        console.error('error', error);
        enqueueSnackbar(`Invite Your New Team failed`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        handleModalClose();
        teamStore.hasRefresh = true;
      }
    }
  };
  useEffect(() => {
    if (canAccess([canReadRoles, canUpdateRoles, canCreateRoles]))
      fetchUserRoles();
    if (canAccess([canCreateTeams, canReadTeams, canUpdateTeams]))
      fetchUserTeams();
  }, []);
  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">Invite members</Typography>
      </Stack>
    );
  };

  const Content = () => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const isEmailValid = (email: string): boolean => {
    //   return emailPattern.test(email);
    // };

    return (
      <Stack component={Typography} spacing={3}>
        <Typography className="bK-body2">
          {t('bk_bgv_company_setting_invite_member_info_text')}
        </Typography>
        <Stack>
          <InviteEmailComponent
            state={selectedEmail}
            setState={setSelectedEmail}
          />
        </Stack>
        <Stack>
          <Divider flexItem />
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Autocomplete
            multiple
            id="team-selection"
            options={teamOption ?? []}
            getOptionLabel={option => option?.name}
            value={selectedTeam ?? []}
            onChange={handleTeamSelection}
            disableCloseOnSelect
            className="team-selection-container" // Use consistent class names
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option?.name}
              </li>
            )}
            style={{ width: '100%' }} // Make the component responsive
            renderInput={params => (
              <TextField
                {...params}
                label="Select Team"
                placeholder="Select Team"
              />
            )}
          />

          {/* role */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
            <Select
              id="demo-simple-select"
              value={role || ''}
              label="Select Role"
              onChange={handleRoleChange}>
              {roleOption
                ?.filter(data => data.name !== CORE_USER_ROLE.SUPER_ADMIN)
                ?.map(role => (
                  <MenuItem key={role?.name} value={role?.name}>
                    {userRoleMap(role?.name)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        <Typography className="bK-body3 flex">
          <DisplayImage
            imageName="blue-rounded-warning.svg"
            imageType="icon"
            width="12px"
            height="12px"
          />
          {t('bk_bgv_company_setting_invite_member_create_text')}
          <span
            className="text-bk_tag_blue_light ml-1 underline cursor-pointer"
            onClick={() => {
              handleModalOpen({
                ...isOpen,
                createTeam: true,
                inviteEmployee: false,
              });
            }}>
            {t('bk_bgv_create_new_team')}
          </span>
        </Typography>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        <BKStyledButton
          variant="text"
          className="rounded-lg bk-btn-large text-bk_text_secondary"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          disabled={
            selectedEmail.items?.length && role && selectedTeam.length
              ? false
              : true
          }
          className="rounded-lg bk-btn-large"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleSubmit();
          }}>
          Send invite
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};

export const AlertCreateTeam = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedEmail, setSelectedEmail] = useState<string[]>([] as string[]);
  const [emailOption, setEmailOption] = useState<string[]>([] as string[]);

  const handleEmailSelection = (event: any, value: string[]) => {
    setSelectedEmail(value);
  };

  const [teamName, setTeamName] = useState('');

  const handleSubmit = async () => {
    if (teamName) {
      const createData = {
        name: teamName ?? '',
        memberEmails: selectedEmail ?? [],
      };
      try {
        const response = await axiosInstance.post(
          endpoints.CREATE_TEAM,
          createData
        );
        if (!response.data.success) {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
          handleModalClose();
        }
        if (response.data.success) {
          enqueueSnackbar(`Team Created Successfully!`, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          handleModalClose();
        }
      } catch (e) {
        enqueueSnackbar(`Create New Team failed`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        handleModalClose();
      }
    }
  };

  const fetchAllUser = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.INVITE_EMPLOYERS_EMAIL}`
      );
      if (response.data) {
        setEmailOption(response.data?.data ?? []);
      }
    } catch (e) {
      enqueueSnackbar(`Fetch User Email failed`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  const Header = () => {
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography className="bk-headline2">Create team</Typography>
      </Stack>
    );
  };

  const Content = () => {
    return (
      <Stack component={Typography} spacing={2}>
        <Typography className="bK-body2">
          An email will be sent to the team member after creation, for them to
          log on to the platform
        </Typography>
        <Stack>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <BKStyledTextField
              label="Team Name"
              name="team name"
              value={teamName}
              onChange={e => {
                setTeamName(e.target.value);
              }}
              autoFocus
              // error={!!errors.name}
              // helperText={errors.name}ivn
              inputProps={{ maxLength: 30 }}
              required
              margin="none"
              sx={{ width: 260 }}
            />
          </Stack>
        </Stack>
        <Stack>
          <Divider flexItem />
        </Stack>
        <Stack>
          <Autocomplete
            multiple
            id="tags-standard"
            options={emailOption}
            value={selectedEmail}
            onChange={handleEmailSelection}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Enter Email ID to invite"
                placeholder="Enter Email ID to invite"
              />
            )}
            getOptionDisabled={option => selectedEmail.includes(option)}
          />
        </Stack>
        <Typography className="bK-body3 flex">
          <DisplayImage
            imageName="blue-rounded-warning.svg"
            imageType="icon"
            width="12px"
            height="12px"
          />
          You will be able to add users you have already invited
        </Typography>
      </Stack>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '16px !important',
          gap: '20px',
        }}>
        <BKStyledButton
          variant="text"
          className="rounded-lg bk-btn-large text-bk_text_secondary"
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleModalClose();
          }}>
          Cancel
        </BKStyledButton>
        <BKStyledButton
          variant="contained"
          className="rounded-lg bk-btn-large"
          disabled={teamName.length ? false : true}
          sx={{ padding: '12px 20px' }}
          onClick={() => {
            handleSubmit();
          }}>
          Create team
        </BKStyledButton>
      </Box>
    );
  };
  return (
    <CommonDialogue
      DialogueHeader={<Header />}
      ActionButton={<ActionButton />}
      Content={<Content />}
    />
  );
};
