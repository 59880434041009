import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DisplayImage } from 'src/app/components';
import { globalNavigateTo } from 'src/app/utils/router/navigateTo';

export function TermsConditions() {
  const navigate = useNavigate();
  return (
    <div className="mb-20 overflow-scroll" style={{ height: '100vh' }}>
      <div className="m-12">
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Stack direction={'column'}>
            <Typography
              onClick={() => navigate(-1)}
              variant="h6"
              className="text-bk_action_primary">
              &lt;&nbsp;Go back
            </Typography>
            <h1 className="mb-8">Terms and Conditions!</h1>
          </Stack>
          <Stack>
            <Typography onClick={() => globalNavigateTo('/login', navigate)}>
              <DisplayImage
                imageName="verifyin-dark.svg"
                imageType="image"
                width="200px"
                height="100px"
                className="ml-1"
              />
            </Typography>
          </Stack>
        </Stack>

        <p>
          <strong>1. Acceptance of Terms</strong>
        </p>
        <p>
          Your use of the products, software, services and websites and
          including www.giginjobs.com (collectively, the "Website"), owned or
          operated by Gigin Technologies Private Limited ("Gigin"), by
          registering with Gigin, the user (henceforth referred to as "
          <strong>the User or You/Your</strong>") agrees to the following
          general terms and conditions (henceforth referred to as "
          <strong>the Terms of Use"</strong>). Registration with Gigin creates a
          valid contract between the User and Gigin (henceforth known as
          <strong>"Gigin" or "we/us")</strong> and the may be accessed by you
          only if you fully comply with the terms of use described herein.
        </p>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE SERVICES (AS
          DEFINED BELOW). BY ACCESSING THE SITES, USING CONTENT OR USING ANY
          PART OF THE SITES OR ANY SERVICES (AS DEFINED BELOW), YOU AGREE TO
          BECOME BOUND BY THESE TERMS AND CONDITIONS, INCLUDING DISCLAIMERS OF
          WARRANTIES, BINDING ARBITRATION, WAIVER OF A RIGHT FOR A LEGAL DISPUTE
          UNDER INDIAN OR ANY INTERNATIONAL LAWS. IF YOU DO NOT AGREE TO ALL THE
          TERMS AND CONDITIONS HEREUNDER, THEN YOU MAY NOT ACCESS THE SITES OR
          USE CONTENT OR ANY SERVICES. GIGIN'S ACCEPTANCE IS EXPRESSLY
          CONDITIONED UPON YOUR ASSENT TO ALL OF THESE TERMS AND CONDITIONS, TO
          THE EXCLUSION OF ALL OTHER TERMS. IF THESE TERMS AND CONDITIONS ARE
          CONSIDERED AN OFFER BY GIGIN, ACCEPTANCE IS EXPRESSLY LIMITED TO THESE
          TERMS.
        </p>
        <p>
          YOU ACKNOWLEDGE AND UNDERSTAND THAT GIGIN IS A PRIVATE LIMITED COMPANY
          AND IS NOT AN ENTITY THAT IS ADMINISTERED BY GOVERNMENT ORGANIZATION.
        </p>
        <p>
          YOU ACKNOWLEDGE AND UNDERSTAND THAT YOUR USE OF THE SERVICES DOES NOT
          GUARANTEE YOU ANY PARTICULAR OUTCOME OR SUCCESS, OR THAT YOU WILL
          OBTAIN EMPLOYMENT THROUGH THE SERVICES.
        </p>
        <p className="mt-6">
          <strong>2. Eligibility</strong>
        </p>
        <p>
          The Services are extended all over the world. To receive the Services
          you must be either a company or, if you are an individual, at least 13
          years old.
          <strong>
            If you are between 13 years and 18 years old, your legal guardian
            must read these Terms of Use.
          </strong>{' '}
          If you are the parent or legal guardian of a user of the Services who
          is under 18 years old, you consent to such user's use of the Services,
          assume the obligations provided in these Terms of Use, and assume full
          responsibility for such user's use of the Services. If you do not so
          qualify, do not attempt to use the Services. This Terms of Use will be
          void and without effect, and you will not be eligible to use any
          Services, if you do not satisfy these age and jurisdiction
          requirements.
        </p>
        <p className="mt-6">
          <strong>3. Modifications of Terms Of Use</strong>
        </p>
        <p>
          Gigin reserves the right, at its sole discretion, to modify or replace
          the Terms of Use at any time. You shall be responsible for regularly
          reviewing these terms and becoming familiar with any such
          modifications. Your use of the Services following any modification
          constitutes your acceptance of the terms and conditions of the Terms
          of Use, as modified. In addition, when using particular Gigin owned or
          operated services, you and Gigin shall be subject to any posted
          guidelines or rules applicable to such services, which may be posted
          from time to time. All such guidelines or rules are hereby
          incorporated by reference into the Terms of Use.
        </p>
        <p className="mt-6">
          <strong>4. Description of Services</strong>
        </p>
        <p>
          Subject to full compliance with the Terms of Use, Gigin shall provide
          you the Services. "Services" may include, but not be limited to, the
          storage, management, sharing, linking and provision of information,
          media, document files and the like (including, but not limited to
          text, user comments, messages, information, data, graphics,
          photographs, images, illustrations, software, audio, video, Gigin
          product information, Gigin services information and similar materials
          provided by Gigin or third party users, also collectively known as
          "Content"). The Services that Gigin provides are always evolving and
          the form and nature of the Services that Gigin provides may change
          from time to time without prior notice to you. Gigin may change,
          suspend or discontinue the Services or any Content for any reason, at
          any time, including the availability of any feature or part of
          Content. Gigin may also impose limits on the Services or restrict your
          access to parts or all of the Services without notice or liability.
          You understand and agree that the Services are provided "AS-IS" and
          that Gigin assumes no responsibility for the timeliness, deletion,
          mis-delivery or failure to store any user communications,
          personalization settings or Content. You are responsible for obtaining
          access to the Services, and that access may involve third-party fees
          (such as Internet service provider or airtime charges). In addition,
          you must provide and are responsible for all equipment necessary to
          access the Services.
        </p>
        <p>
          The Services may include advertisements, which may be targeted to
          Content or information on the Services, queries made through the
          Services, or other information. The types and extent of advertising by
          Gigin on the Services are subject to change.
        </p>
        <p>
          In consideration for Gigin granting you access to and use of the
          Services, you agree that Gigin and its third party providers and
          partners may place such advertising on the Services or in connection
          with the display of Content or information from the Services whether
          submitted by you or others.
        </p>
        <p className="mt-6">
          <strong>5. Your Registration Obligations</strong>
        </p>
        <p>
          To use some of the Services, you may be required to create an account
          and provide information to Gigin. In consideration of your use of the
          Services, you represent that you have the legal authority to form a
          binding contract and are not a person or company barred from receiving
          services under any laws of India or other applicable jurisdiction. By
          creating an account, you also represent that you are at least 13 years
          old (and if you are between 13 and 18 years old, you must have your
          legal guardian read these Terms of Use in their entirety). You also
          agree to: (a) provide true, accurate, current and complete information
          about yourself as prompted by the Services' registration form as set
          forth below and (b) maintain and promptly update such information to
          keep it true, accurate, current and complete. If you provide any
          information that is untrue, inaccurate, not current or incomplete, or
          Gigin has reasonable grounds to suspect that such information is
          untrue, inaccurate, not current or incomplete, Gigin has the right to
          suspend or terminate your account and refuse any and all current or
          future use of the Services (or any portion thereof). As a condition to
          using portions of the Services, you may be required to register with
          Gigin and provide a valid email address, select a password and
          personalized site name ("Registration Data"). All information you
          provide to Gigin shall be accurate, complete, and updated. You may not
          (i) select or use as a personalized site name a name of another person
          with the intent to impersonate that person; (ii) use as a personalized
          site name a name subject to any rights of a person other than you
          without appropriate authorization; or (iii) use as a personalized site
          name a name that is otherwise offensive, vulgar or obscene as
          determined by Gigin. Gigin reserves the right to refuse registration
          of, or cancel a personalized site name in its sole discretion. You
          will receive a password and account designation upon completing the
          Services' registration process. You are responsible for maintaining
          the confidentiality of the password and account and are fully
          responsible for all activities that occur under your password or
          account. You agree to (a) immediately notify Gigin of any unauthorized
          use of your password or account or any other breach of security, and
          (b) ensure that you exit from your account at the end of each session.
          Gigin cannot and will not be liable for any loss or damage arising
          from your failure to comply with this Section.
        </p>
        <p className="mt-6">
          <strong>6. User Conduct</strong>
        </p>
        <p>
          You understand that all Content, whether publicly posted or privately
          transmitted, is the sole responsibility of the person or company from
          whom such Content originated. This means that you, and not Gigin, are
          entirely responsible for all Content that you upload, post, email,
          transmit or otherwise make available via the Services. Gigin does not
          control Content posted via the Services and, as such, does not
          guarantee the accuracy, integrity or quality of such Content. You
          understand that by using the Services, you may be exposed to Content
          that is offensive, indecent or objectionable. Under no circumstances
          will Gigin be liable in any way for any Content, including, but not
          limited to, any errors or omissions in any Content, or any loss or
          damage of any kind incurred as a result of the use of any Content
          posted, emailed, transmitted or otherwise made available via the
          Services.
        </p>
        <p>
          As a condition of use, you promise not to use the Services for any
          purpose that is unlawful or prohibited by these Terms of Use, or any
          other purpose not reasonably intended by Gigin. Gigin reserves the
          right to remove any Content without notice – Gigin may at any time
          remove posted material. Gigin further reserves the right to terminate
          providing Services to you as determined in its sole and absolute
          discretion.
        </p>
        <p>
          Accordingly, without limitation, you agree not to use the Services:
        </p>
        <ol className="ml-10">
          <li>
            To abuse, harass, threaten, impersonate or intimidate any person;
          </li>
          <li>
            To access, post, email, download, reproduce, display, perform,
            distribute or otherwise make available any Content that infringes
            any patent, trademark, trade secret, copyright or other proprietary
            rights of any party, or that is unlawful, harmful, threatening,
            vulgar, invasive of another's privacy, libelous, defamatory,
            obscene, pornographic, promotes illegal activities, violent, racist,
            sexist, homophobic, discriminatory, abusive, or offensive;
          </li>
          <li>
            To post or transmit, or cause to be posted or transmitted, any
            communication or solicitation designed or intended to obtain
            password, account, or private information from any other user;
          </li>
          <li>
            To create or submit unwanted email ("Spam") to any other user or any
            URL;
          </li>
          <li>To "stalk" another user;</li>
          <li>
            After Gigin has refused registration of, or canceled your account,
            whether by attempting to register using a different email address,
            personalized site name or otherwise, to circumvent the account
            registration process;
          </li>
          <li>
            For the benefit of any third party (which shall include, without
            limitation, allowing more than one person to use a Services account)
            when not expressly authorized by Gigin;
          </li>
          <li>To harm minors in any way;</li>
          <li>To impersonate any other person or entity;</li>
          <li>
            To upload, email, transmit or otherwise make available Content in
            violation of any contractual or other legal obligation, to which you
            are bound;
          </li>
          <li>
            To upload, post, email, transmit or otherwise make available any
            material that contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment;
          </li>
          <li>
            To interfere with or disrupt the Services or servers or networks
            connected to the Services, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Services;
          </li>
          <li>
            To intentionally or unintentionally violate any applicable local,
            state, national or international law;
          </li>
          <li>
            To provide material support or resources (or to conceal or disguise
            the nature, location, source, or ownership of material support or
            resources) to any organization(s) designated by the Indian
            government as a foreign terrorist organization pursuant to all the
            relevant Acts;
          </li>
          <li>
            To reproduce, duplicate, copy, sell, trade, resell or exploit for
            any commercial purposes, any portion of the Services (including your
            Gigin ID), use of the Services, or access to the Services;
          </li>
          <li>To promote or endorse any political issues or candidates;</li>
          <li>
            To collect or store personal data about other users in connection
            with the prohibited conduct and activities set forth in paragraphs a
            through p above;
          </li>
          <li>
            To link to any website with Content that is offensive, indecent or
            objectionable; and/or
          </li>
          <li>
            To make offers or solicitations that are not valid, not honored,
            misrepresentative, illegal or inappropriate.
          </li>
        </ol>
        <p>
          Additionally, you agree that you will not: (i) take any action that
          imposes, or may impose in Gigin's sole discretion an unreasonable or
          disproportionately large load on Gigin's infrastructure; (ii)
          interfere or attempt to interfere with the proper working of the
          Services or any activities conducted on the Services; or (iii) bypass
          any measures Gigin may use to prevent or restrict access to the
          Services.
        </p>
        <p>
          Your use of any information learned through the Services or while in
          the Site is limited to the express purposes set forth in these Terms
          of Use; all other uses, including, without limitation, sending
          unsolicited bulk e-mail, are strictly prohibited.
        </p>
        <p>
          You acknowledge that Gigin does not pre-screen Content, but that Gigin
          and its designees shall have the right (but not the obligation) in
          their sole and absolute discretion to pre-screen, refuse, or remove
          any Content that is available via the Services. Without limiting the
          foregoing, Gigin and its designees shall have the right to remove or
          refuse to distribute any Content that violates the Terms of Use or is
          otherwise objectionable and to terminate users or discontinue Service.
          You agree that you must evaluate, and bear all risks associated with,
          the use of any Content, including any reliance on the accuracy,
          completeness, or usefulness of such Content. In this regard, you
          acknowledge that you may not rely on any Content created by Gigin or
          submitted to Gigin, including without limitation information in all
          other parts of the Services.
        </p>
        <p>
          You acknowledge, consent and agree that Gigin may access, preserve and
          disclose your account information and Content if required to do so by
          law or in a good faith belief that such access preservation or
          disclosure is reasonably necessary to: (a) comply with applicable law,
          regulation, legal process or governmental request; (b) enforce the
          Terms of Use; (c) respond to claims that any Content violates the
          rights of third parties; (d) respond to your requests for customer
          service; or (e) protect the rights, property or personal safety of
          Gigin, its users and the public. You can learn more about how Gigin
          treats information collected through the Services and the Sites by
          reading thePrivacy Policy.
        </p>
        <p>
          You understand that the technical processing and transmission of the
          Services, including your Content, may involve (a) transmissions over
          various networks; and (b) changes to conform and adapt to technical
          requirements of connecting networks or devices.
        </p>
        <p>
          You understand that when using the Service, you will be exposed to
          Content from a variety of sources, and that Gigin is not responsible
          for the accuracy, usefulness, safety, or intellectual property rights
          of or relating to such Content. You further understand and acknowledge
          that you may be exposed to Content that is inaccurate, offensive,
          indecent, or objectionable, and you agree to waive, and hereby do
          waive, any legal or equitable rights or remedies you have or may have
          against Gigin with respect thereto, and, to the extent permitted by
          applicable law, agree to indemnify and hold harmless Gigin, its
          owners, operators, affiliates, licensors, and licensees to the fullest
          extent allowed by law regarding all matters related to your use of the
          Services. Under no circumstances will Gigin be liable in any way for
          any Content, including, but not limited to, any errors or omissions in
          any Content, or any loss or damage of any kind incurred as a result of
          the use of any Content posted, emailed, transmitted or otherwise made
          available via the Services or broadcast elsewhere.
        </p>
        <p>
          Please report any violation of the Terms of Use from following
          webpage: www.giginjobs.com/contact/
        </p>
        <p className="mt-6">
          <strong>7. Proprietary Rights</strong>
        </p>
        <p>
          Gigin respects the intellectual property of others and asks users to
          do the same. If you believe that your work has been copied in a way
          that constitutes copyright infringement, or your intellectual property
          rights have otherwise been violated, please send Gigin a notification,
          under the Digital Millennium Copyright Act 1998 ("DMCA"), from webpage
          www.giginjobs.com/contact/ Please include the following, as required
          by the DMCA:
        </p>
        <ul className="ml-10">
          <li>Identify the copyrighted work(s) you claim is infringed.</li>
          <li>
            Identify the Content you claim is infringing the copyright(s), and
            provide enough information for Gigin to reasonably locate that
            Content.
          </li>
          <li>
            Include a physical or electronic signature of the copyright owner or
            a person authorized to act on the copyright owner's behalf (the
            "Claimant").
          </li>
          <li>
            Include the Claimant's name, address, and telephone number(s).
          </li>
          <li>
            Include a statement that the Claimant has a good faith belief that
            use of the disputed Content is not authorized by the copyright owner
            or his/her agent.
          </li>
          <li>
            Include a statement, under penalty of perjury, that the information
            in the notification of copyright infringement is accurate and that
            the Claimant is authorized to act on behalf of the copyright owner.
          </li>
        </ul>
        <p>
          When Gigin receives an infringement notice with all the required
          information and are able to locate the allegedly infringing Content,
          Gigin will remove or disable access to the subject Content. Gigin will
          also take reasonable steps to promptly notify the person who posted
          the subject Content. Gigin will give them the opportunity to send a
          counter-notification. A counter-notification must include the
          following, to be effective under DMCA:
        </p>
        <ul className="ml-10">
          <li>
            A physical or electronic signature of the person submitting the
            counter-notification;
          </li>
          <li>
            Identification of the Content that was removed or to which access
            was disabled and the location at which the Content appeared before
            it was removed or access to it was disabled;
          </li>
          <li>
            A statement under penalty of perjury that the Content was removed or
            disabled due to mistake or misidentification of the Content removed
            or disabled;
          </li>
          <li>
            The name, address, email address and telephone number of the person
            submitting the counter-notification;
          </li>
          <li>
            A statement that the person submitting the counter-notification
            consents to the jurisdiction of District Court or High Court .
          </li>
        </ul>
        <p>
          Moreover, Gigin may take any action it deems appropriate (in its sole
          and absolute discretion), including, without limitation, removing,
          terminating or suspending any Content provided by you or any third
          party, if Gigin believes for any reason that you or any third party
          possesses insufficient rights in Content to permit full use or access
          through the Sites.
        </p>
        <p>
          All intellectual property rights in and to the Services are and shall
          be owned by Gigin, absolutely. Those rights include, but are not
          limited to, database rights, copyright, design rights (whether
          registered or unregistered), patents, trademarks (whether registered
          or unregistered) and other similar rights, wherever existing in the
          world, together with the right to apply for protection of the same.
          All other trademarks, logos, service marks, company or product names
          set forth in the Service are the property of their respective owners.
          <strong>Gigin's Rights</strong>: As between Gigin and you, Gigin and
          its affiliates and licensors own and retain all rights in the
          Services, which contain proprietary and confidential information that
          is protected by applicable intellectual property and other laws.
          Except as expressly authorized by Gigin and except to the limited
          extent expressly prohibited by applicable law, you may not (nor permit
          others to) reverse engineer, copy, modify, publish, transmit,
          distribute, perform, display or sell any of Gigin's proprietary
          information. Gigin trademarks and service marks and other Gigin logos
          and product and service names are trademarks of Gigin Technologies
          Private Limited. Without Gigin's prior permission, you agree not to
          display or use in any manner the Gigin Trademarks. Moreover any
          attempt to use any "spider", "robot", "bot", "scraper" "data miner" or
          any program, device, algorithm, process or methodology to access,
          acquire, copy or monitor the Sites or pages, data or content found on
          the Sites for the purpose of scraping the Services or Content without
          the prior consent of Gigin is expressly prohibited.
          <strong>Your Rights</strong>: It is your responsibility to ensure that
          you obtain all consents, authorizations and clearances in any Content
          owned or controlled by third parties that you access or communicate to
          others in connection with the Services. Any copying, downloading,
          burning or distribution capabilities with respect to Content shall not
          constitute a grant or waiver of any rights of the copyright owners of
          any Content. The availability of any Content does not transfer to you
          any commercial or promotional use rights in Content.
        </p>
        <p>
          You or a third party licensor, as appropriate, retain all patent,
          trademark and copyright to any Content you submit, post or display on
          or through the Services, and you are responsible for protecting those
          rights, as appropriate. By submitting, posting or displaying Content
          (text, video or content in any form) on or through the Services, you
          grant Gigin a worldwide, perpetual, non-exclusive, irrevocable,
          transferable, assignable, royalty-free license (with the right to
          sublicense) to create derivatives of and to use, store, edit, copy,
          reproduce, display, archive, reproduce , reprint, process, modify,
          such Content on the Services for the purpose of displaying,
          distributing and promoting the Services or any other format or
          channel. You represent that you have all necessary licenses, rights,
          consents and permission to provide the User Submission and to grant
          the foregoing licenses.
        </p>
        <p>
          You agree that this license includes the right for Gigin to make such
          Content available to other companies, organizations or individuals who
          partner with Gigin for the syndication, broadcast, distribution or
          publication of such Content on other media and services, subject to
          these Terms of Use for such Content use.
        </p>
        <p>
          Such additional uses by Gigin, or other companies, organizations or
          individuals who partner with Gigin, may be made with no compensation
          paid to you with respect to Content that you submit post, transmit or
          otherwise make available through the Services.
        </p>
        <p>
          You are responsible for your use of the Services, for any content you
          provide, and for any consequences thereof, including the use of your
          Content by other users and third party partners. You understand that
          your Content may be retransmitted by Gigin's partners and if you do
          not have the right to submit Content for such use, it may subject you
          to liability. Gigin will not be responsible or liable for any use of
          your Content by Gigin in accordance with this Terms of Use. You
          represent and warrant that you have all the rights, power and
          authority necessary to grant the rights granted herein to any Content
          that you submit.
        </p>
        <p>
          Subject to your compliance with these Terms of Use, Gigin grants you a
          personal, worldwide, royalty-free, non-assignable and non-exclusive
          license to use the software that is provided to you by Gigin as part
          of the Services. This license is for the sole purpose of enabling you
          to use and enjoy the benefit of the Services as provided by Gigin, in
          the manner permitted by this Terms of Use.
        </p>
        <p className="mt-6">
          <strong>8. Gigin Privacy Policy</strong>
        </p>
        <p>
          Registration Data and certain other information about you is subject
          to Gigin'sPrivacy Policy. You understand that through your use of the
          Services you consent to the collection and use (as set forth in the
          Privacy Policy) of this information, including the transfer of this
          information to the India and/or other countries for storage,
          processing and use by Gigin and its affiliates. As part of providing
          you the Services, Gigin may need to provide you with certain
          communications, such as service announcements and administrative
          messages. These communications are considered part of the Services,
          which you may not be able to opt-out from receiving.
        </p>
        <p className="mt-6">
          <strong>9. Indemnity</strong>
        </p>
        <p>
          You will indemnify and hold harmless Gigin, its parents, subsidiaries,
          affiliates, customers, vendors, donors, interns, volunteers, trustees,
          directors, officers and employees from any liability, damages,
          expenses, and costs (including reasonable attorneys' fees) arising
          from (i) any claim or demand made by any third party due to or arising
          out of your access to the Sites, use of the Services, your Content or
          violation of the Terms of Use, or (ii) the infringement by you, or any
          third party using your account, of any intellectual property or other
          right of any person or entity.
        </p>
        <p className="mt-6">
          <strong>10. Warranty Disclaimers</strong>
        </p>
        <p>
          <strong>Third Party Content</strong>. Much of the Content displayed in
          connection with the Services is developed by people over whom Gigin
          exercises no control. Accordingly, the Services may include (or direct
          you to sites containing) Content that some people find objectionable,
          inappropriate, or offensive. Gigin cannot guarantee that your use of
          the Services will not expose you to unintended or objectionable
          Content and Gigin assumes no responsibility for what effects the
          Content may have on you, how you may interpret or use the Content, or
          what actions you may take as a result of having been exposed to the
          Content. You hereby release Gigin from all liability for you having
          acquired or not acquired Content through the Services. Gigin makes no
          representations concerning any content contained in or accessed
          through the Services, and Gigin has not independently verified any of
          the information provided from third parties. Furthermore Gigin will
          not be responsible or liable for the accuracy, copyright compliance,
          legality or decency of material contained in or accessed through the
          Services.
        </p>
        <p>
          GIGIN MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, AS TO THE INFORMATION, CONTENT, MATERIALS, QUALITY, PRODUCTS
          OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
          SITES, UNLESS OTHERWISE SPECIFIED IN WRITING. AS SUCH, GIGIN DOES NOT
          GUARANTEE THE ACCURACY, CORRECTNESS, TIMELINESS, RELIABILITY,
          NON-INFRINGEMENT, OR COMPLETENESS OF ANY INFORMATION OR CONTENT ON THE
          SITES. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES AND CONTENT
          IS AT YOUR SOLE RISK.
          <strong>General Disclaimer</strong>. THE SERVICES, CONTENT, AND SITE
          ARE PROVIDED ON AN "AS IS" BASIS. GIGIN AND ITS SUBSIDIARIES,
          AFFILIATES, OFFICERS, EMPLOYEES, DONORS, AGENTS, PARTNERS AND
          LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
          NON-INFRINGEMENT. SOME LAWS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          GIGIN AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
          PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE SERVICES WILL
          MEET YOUR REQUIREMENTS; (ii) THE SERVICES WILL BE UNINTERRUPTED,
          TIMELY, SECURE OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE OBTAINED
          FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; (iv) THE
          QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL
          PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR
          EXPECTATIONS; OR (v) ANY ERRORS IN THE SERVICE WILL BE CORRECTED. ANY
          MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
          DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM GIGIN OR
          THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
          STATED IN THE TERMS OF USE. GIGIN EXPRESSLY DISCLAIMS ANY AND ALL
          VICARIOUS LIABILITY. MOREOVER, THE SERVICES MAY CONTAIN ADVICE,
          OPINIONS, INSTRUCTIONS AND STATEMENTS FROM GIGIN, ITS USERS, AND OTHER
          CONTENT AND INFORMATION PROVIDERS. THIS CONTENT IS INTENDED TO BE USED
          FOR INFORMATIONAL AND ENTERTAINMENT PURPOSES ONLY. YOU USE THE SITE
          AND CONTENT AT YOUR OWN RISK. WE STRONGLY URGE YOU TO CONSULT A
          PROFESSIONAL OR SOME OTHER AUTHORITY IN THE APPROPRIATE FIELD BEFORE
          USING ANY OF THE CONTENT.
        </p>
        <p className="mt-6">
          <strong>11. LINKS</strong>
        </p>
        <p>
          The Services may provide, or third parties may provide, links to other
          websites or other resources. Because Gigin has no control over such
          sites and resources, you acknowledge and agree that Gigin is not
          responsible for the availability of such external sites or resources,
          and does not endorse and is not responsible or liable for any Content,
          advertising, products or other materials on or available from such
          sites or resources. You further acknowledge and agree that Gigin shall
          not be responsible or liable, directly or indirectly, for any damage
          or loss caused or alleged to be caused by or in connection with the
          use of or reliance on any such Content, goods or services available on
          or through any such site or resource. You acknowledge sole
          responsibility for and assume all risk arising from your use of any
          such websites or resources. You agree not to provide links from your
          Content that is not related to your Content or is inappropriate.
        </p>

        <p className="mt-6">
          <strong>
            12. Interstate Nature of Communications On Gigin Network
          </strong>
        </p>

        <p>
          When you register with Gigin, you acknowledge that in using the
          Services to send electronic communications, you will be causing
          communications to be sent through Gigin's computer networks, which are
          located in India and/or outside India. As a result, and also as a
          result of Gigin's network architecture and business practices and the
          nature of electronic communications, regardless of where you are
          physically located at the time of transmission. Accordingly, by
          agreeing to this Terms of Use, you acknowledge that use of the Service
          may result in interstate data transmissions.
        </p>
        <p className="mt-6">
          <strong>14. Ideas and Suggestions Made To Gigin</strong>
        </p>
        <p>
          By submitting ideas or suggestions to Gigin through its suggestion web
          pages, if any, you acknowledge and agree that: (a) your ideas and
          suggestions do not contain confidential or proprietary information;
          (b) Gigin is not under any obligation of confidentiality, express or
          implied, with respect to the ideas or suggestions; (c) Gigin shall be
          entitled to use or disclose (or choose not to use or disclose) such
          ideas and suggestions for any purpose, in any way, in any media
          worldwide; (d) Gigin may have something similar to the ideas or
          suggestions already under consideration or in development; (e) your
          ideas or suggestions automatically become the property of Gigin
          without any obligation of Gigin to you; and (f) you are not entitled
          to any compensation or reimbursement of any kind from Gigin under any
          circumstances.
        </p>
        <p className="mt-6">
          <strong>15. Limitation of Liability</strong>
        </p>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT GIGIN AND ITS SUBSIDIARIES,
          AFFILIATES, MEMBERS, DONORS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS,
          PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
          INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL,
          USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF GIGIN HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR
          THE INABILITY TO USE THE SERVICES; (ii) THE COST OF PROCUREMENT OF
          SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
          INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
          TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES; (iii)
          UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS, CONTENT OR
          DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES;
          OR (v) ANY OTHER MATTER RELATING TO THE SERVICES. SOME JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
          EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. YOU
          AGREE THAT GIGIN HAS NO RESPONSIBILITY OR LIABILITY FOR THE DELETION
          OR FAILURE TO STORE ANY CONTENT MAINTAINED OR POSTED BY OR THROUGH THE
          SITES OR SERVICES.
        </p>
        <p className="mt-6">
          <strong>16. Modifications to Services</strong>
        </p>
        <p>
          Gigin reserves the right at any time and from time to time to modify
          or discontinue, temporarily or permanently, the Services (or any part
          thereof) with or without notice. You agree that Gigin shall not be
          liable to you or to any third party for any modification, suspension
          or discontinuance of the Services.
        </p>
        <p className="mt-6">
          <strong>17. Termination</strong>
        </p>

        <p>
          Gigin will endeavor to notify you when you're right to access the
          Services is about to expire. Upon the expiration of your Services,
          Gigin maintains the right to delete Content for all users created
          within your Services account.
        </p>
        <p>
          You may cancel your access to the Services at any time . To cancel,
          login to your account and go to the account section and select the
          Delete Account option.
        </p>
        <p>
          You agree that Gigin may, under certain circumstances and without
          prior notice, immediately terminate your Gigin account, any associated
          email address, and access to the Services. Cause for such termination
          shall include, but not be limited to, (a) breaches or violations of
          the Terms of Use or other incorporated agreements or guidelines, (b)
          requests by law enforcement or other government agencies, (c) a
          request by you (self-initiated account deletions) per above, (d)
          discontinuance or material modification to the Services (or any part
          thereof), (e) unexpected technical or security issues or problems, (f)
          extended periods of inactivity, and/or (g) engagement by you in
          fraudulent or illegal activities. Termination of your Gigin account
          may include (i) removal of access to all offerings within the
          Services, (ii) deletion of your password, and (iii) barring of further
          use of the Services. Further, you agree that all terminations for
          cause shall be made in Gigin's sole and absolute discretion and that
          Gigin shall not be liable to you or any third party for any
          termination of your account, any associated email address, or access
          to the Services.
        </p>
        <p>
          Gigin also reserves the right to terminate your account for any or no
          reason with or without notice to you.
        </p>
        <p>
          Upon termination of your account, your right to use the Services will
          immediately cease. Gigin's proprietary rights, disclaimer of
          warranties, indemnities, limitations of liability and miscellaneous
          provisions shall survive any such termination.
        </p>
        <p className="mt-6">
          <strong>18. Privacy</strong>
        </p>
        <p>
          The Gigin Privacy Policy sets out our obligations with respect to the
          safeguarding, collection and use of Users' "Personal Identifiable
          information". The Gigin Privacy Policy is subject to modification from
          time to time, and such changes are effective upon posting of the
          modified policy to this URL: www.giginjobs.com/privacy/
        </p>
        <p className="mt-6">
          <strong>19. Force Majeure</strong>
        </p>
        <p>
          Gigin will not be liable for any delay, interruption or failure in the
          provisioning of services if caused by acts of God, declared or
          undeclared war, fire, flood, storm, slide, earthquake, power failure,
          the inability to obtain equipment, supplies or other facilities that
          are not caused by a failure to pay, labor disputes, or other similar
          events beyond our control that may prevent or delay service
          provisioning.
        </p>
        <p className="mt-6">
          <strong>20. Unenforceable Provisions</strong>
        </p>
        <p>
          If any part of this Agreement is found to be invalid or unenforceable
          under applicable law, such part will be ineffective to the extent of
          such invalid or unenforceable part only, without affecting the
          remaining parts of this Agreement in any way.
        </p>
        <p className="mt-6">
          <strong>21. Governing Law</strong>
        </p>
        <p>
          The rights and obligations of the parties pursuant to this Agreement
          are governed by, and shall be construed in accordance with, the laws
          of India.
        </p>
        <p>
          You may be subject to other local, provincial or state and national
          laws. You hereby irrevocably submit to the exclusive jurisdiction of
          the Courts of the India for any dispute arising under or relating to
          this Agreement and waive your right to institute legal proceedings in
          any other jurisdiction. We shall be entitled to institute legal
          proceedings in connection with any matter arising under this Agreement
          in any jurisdiction where you reside, do business or have assets.
        </p>
        <p className="mt-6">
          <strong>22. Disputes</strong>
        </p>
        <p>
          Notwithstanding the foregoing sentence, (but without limiting, and you
          hereby acknowledge and agree to, Gigin's right to seek injunctive or
          other equitable relief in any court of competent jurisdiction), any
          disputes arising from or relating to these Terms and Condition
          (including the enforceability of this Arbitration provision) shall be
          referred to and administered by a single arbitrator in accordance with
          the Arbitration and Conciliation Act 1996. The sole arbitrator shall
          be solely appointed by the Gigin. The written decision of the sole
          arbitrator shall be final and binding on the parties. The Venue and
          seat of the Arbitration will be India and language will be English and
          pursuant to the rules of (and administered by) Arbitration and
          Conciliation Act 1996. Parties will bear their respective cost of
          Arbitration and Litigation. THE ARBITRATION OF DISPUTES PURSUANT TO
          THIS PARAGRAPH SHALL BE IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
          REPRESENTATIVE PROCEEDING. YOU MAY NOT BRING ANY CLAIM AS A PRIVATE
          ATTORNEY GENERAL ON BEHALF OF OTHER SIMILARLY SITUATED PERSONS. THE
          ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR
          PARTIES WHO MAY BE SIMILARLY SITUATED.If any part of this arbitration
          provision is deemed to be invalid, unenforceable or illegal (other
          than that claims will not be arbitrated on a class or representative
          basis), or otherwise conflicts with the rules and procedures
          established by Arbitration and Conciliation Act 1996, then the balance
          of this arbitration provision shall remain in effect and shall be
          construed in accordance with its terms as if the invalid,
          unenforceable, illegal or conflicting provision were not contained
          herein. If, however, the portion that is deemed invalid, unenforceable
          or illegal is that claims will not be arbitrated on a class,
          representative, or collective basis, or as a private attorney general
          on behalf of other persons similarly situated, then the entirety of
          this arbitration provision shall be null and void, and neither you nor
          Gigin shall be entitled to arbitrate the dispute.
        </p>
        <p className="mt-6">
          <strong>23. Miscellaneous</strong>
        </p>
        <p>
          No agency, partnership, joint venture, or employment is created as a
          result of the Terms of Use and you do not have any authority of any
          kind to bind Gigin in any respect whatsoever. The failure of either
          party to exercise in any respect any right provided for herein shall
          not be deemed a waiver of any further rights hereunder. Gigin shall
          not be liable for any failure to perform its obligations hereunder
          where such failure results from any cause beyond Gigin's reasonable
          control, including, without limitation, mechanical, electronic or
          communications failure or degradation (including "line-noise"
          interference). If any provision of the Terms of Use is found to be
          unenforceable or invalid, that provision shall be limited or
          eliminated to the minimum extent necessary so that the Terms of Use
          shall otherwise remain in full force and effect and enforceable. The
          Terms of Use is not assignable, transferable or sub licensable by you
          except with Gigin's prior written consent. You agree that your Gigin
          account is non-transferable and any rights to your Gigin ID or
          contents within your account terminate upon your death or dissolution,
          as applicable. Gigin may transfer, assign or delegate the Terms of Use
          and its rights and obligations without consent. Both parties agree
          that the Terms of Use is the complete and exclusive statement of the
          mutual understanding of the parties and supersedes and cancels all
          previous written and oral agreements, communications and other
          understandings relating to the subject matter of the Terms of Use. You
          also may be subject to additional terms and conditions that may apply
          when you use or purchase certain other Gigin Services, affiliate
          services, third-party content or third-party software. You agree that
          regardless of any statute or law to the contrary, any claim or cause
          of action arising out of or related to use of the Services or the
          Terms of Use must be filed within one (1) year after such claim or
          cause of action arose or be forever barred. The section titles in this
          Terms of Use are for convenience only and have no legal or contractual
          effect. You agree that, except as otherwise expressly provided in this
          Terms of Use, there shall be no third-party beneficiaries to this
          Terms of Use. Gigin may provide you with notices, including those
          regarding changes to the Terms of Use, by email or postings on the
          Services.
          <strong>
            <u>Amendment to GIGIN Terms of Usefor Government Entities</u>
          </strong>
        </p>
        <p>
          Gigin acknowledges that as an India entity, you are required, when
          entering into agreements with other parties, to follow applicable
          Indian laws and regulations, including those related to ethical
          standards, limitations on indemnification, fiscal law constraints,
          advertising and endorsements, freedom of information, governing law,
          and dispute resolution forum and processes. Indian Entity wishes to
          use Gigin's Services pursuant to the Terms of Use as amended hereto,
          and Gigin is willing to allow such use as set forth herein.
        </p>
        <p className="mt-6">
          <strong>1. Terms and Conditions.</strong>
        </p>
        <p>
          In using the Service, Indian entity agrees to abide by the Terms of
          Use (hereby incorporated by this reference) as amended by this
          Amendment. For clarity, this Amendment will govern use of the Service
          worldwide.
        </p>
        <p className="mt-6">
          <strong>2. Conflicts, Use of Terms.</strong>
        </p>
        <p>
          In the event of conflict between the terms and conditions of the Terms
          of Use, and the terms and conditions of this Amendment, the terms and
          conditions of this Amendment will control. Unless otherwise expressly
          defined in this Amendment, all capitalized terms herein will have the
          meanings ascribed to them in the Terms of Use.
        </p>
        <p className="mt-6">
          <strong>3. Amendments.</strong>
        </p>
        <p>
          (a) Governing Law and Jurisdiction. These Terms of Use will be
          governed, interpreted and enforced in accordance with the laws of the
          India.
        </p>
        <p>
          (b) Disputes. The section governing how and where disputes related to
          the Terms of Use are handled is hereby deleted in its entirety and
          replaced with the following:
        </p>
        <p>
          You and Gigin will endeavor to resolve any disputes in an amicable
          fashion. The liability of the Indian Entity and its obligations to
          Company resulting from any breach by Indian Entity of any of the
          provisions of this Terms of Use or any claim arising from this Terms
          of Use will be determined under the Indian Contract Act 1956, Indian
          Legal Services Authority Act 1987, or any other applicable law."
        </p>
        <p>
          (c) Indemnity. The indemnity section in the Terms of Use is hereby
          deleted in its entirety and replaced with the following: "Disclaimer.
          You agree that your account on the Gigin Service will serve as an
          additional distribution channel for information, but in no event will
          serve or be represented as the official site or homepage for any
          Entity."
        </p>
        <p>
          (d) Changes to Terms of Use. The following section is added to the
          Terms of Use:
        </p>
        <p>
          "Changes to the Terms of Use- Gigin agrees that to the extent that any
          change or addition to these Terms of Use conflict with this Amendment,
          the terms of this Amendment shall supersede such change or addition."
        </p>
        <p className="mt-6">
          <strong>4. Effect.</strong>
        </p>
        <p>
          This Amendment constitutes the entire agreement among the parties with
          respect to the amendment of the Terms of Use, and supersedes all prior
          agreements and understandings, both written and oral, among the
          parties with respect to the amendment of the same. This Amendment is
          not intended to confer any rights or remedies hereunder upon any
          person other than the parties. The terms of this amended Terms of Use
          are considered by Gigin to be confidential information, and, to the
          extent permitted by law, Indian Entities will maintain the same in
          strict confidence and not disclose the same to any third party (other
          than its contractors that are bound by similar confidentiality) or use
          the same for any purpose other than its performance under these
          amended Terms of Use.
        </p>
        <p className="mt-6">
          <strong>5. Miscellaneous.</strong>
        </p>

        <p>
          Gigin agrees that it will not use your logos, trademarks, service
          marks, tradenames to imply an endorsement by you or the Indian
          Government of Gigin Services; provided however, that the foregoing
          shall not prohibit Gigin from using your logos, service marks and
          trade names as necessary to run the Gigin Service. The Parties are
          independent entities and nothing in the Terms of Use or this Amendment
          creates an agency, partnership, or joint venture. Nothing in the Terms
          of Use or this Amendment obligates you to expend appropriations or
          incur financial obligations except to the extent that liabilities may
          arise from your breach the Terms of Use as amended by this Amendment.
          The Parties acknowledge and agree that none of the obligations arising
          from the Terms of Use and this Amendment are contingent upon the
          payment of fees by one party to the other.
        </p>
      </div>
    </div>
  );
}

export default TermsConditions;
