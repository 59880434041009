/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  BKStyledTableCell,
  BKStyledTextField,
} from '@gigin-work-space/common-ui';
import { ICandidateRole } from '@gigin-work-space/model';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Drawer,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { axiosInstance, endpoints } from 'src/app/utils';
import PackagesTable from '../bgv/company-settings/components/company-packages/packages-table';
import {
  ListOfPackage,
  ProductListing,
} from './components/interface/ops-platform-interface';

export const CreatePackage = (props: any) => {
  const { _id, tenantId } = props.account;
  const accountId = _id;
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<string[]>(
    []
  );
  const [checkList, setCheckList] = React.useState<ProductListing[]>([]);
  const [details, setDetails] = React.useState<ProductListing[]>([]);
  const [reload, setReload] = React.useState<boolean>(true);
  const [title, setTitle] = React.useState<string>('');
  const [titleError, setTitleError] = React.useState<string>('');

  const [activePackages, setActivePackages] = React.useState<ListOfPackage[]>(
    []
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState([] as string[]);
  const [roles, setRoles] = React.useState([]);

  const [rolesMap, setRolesMap] = useState<Map<string, string>>(new Map());

  const handleSelectSelectedRoles = (
    event: React.SyntheticEvent,
    value: string[]
  ) => {
    setSelectedRoles(value);
  };

  const handleViewPackagesClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const getPackageList = async () => {
    try {
      const response = await axiosInstance.post(
        endpoints.GET_LIST_OF_PACKAGES_OF_ACCOUNT,
        { accountId, tenantId }
      );
      if (response.data.success) {
        setActivePackages(response.data.data);
        return;
      }
      throw new Error('Failed to get package details');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to get package details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const getProductList = async () => {
    try {
      const params = {
        page: 1,
        limit: 100,
      };
      const response = await axiosInstance.get(endpoints.PRODUCT_LISTING, {
        params: params,
      });
      if (response.data.success) {
        setCheckList(response.data.data);
      } else {
        throw new Error('Failed to get package details');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to get package details', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const getRolesList = async () => {
    const response = await axiosInstance.get(`${endpoints.FETCH_ROLE}`);
    if (response.data) {
      const newRolesMap = new Map<string, string>();
      const returnedRole = response.data.data.map((role: ICandidateRole) => {
        if (role._id) {
          newRolesMap.set(role.role_title, role._id);
        }
        return role.role_title;
      });
      setRolesMap(newRolesMap);
      setRoles(returnedRole);
    } else {
      setRoles([]);
    }
  };

  const handleChange = (list: ProductListing) => () => {
    const name = list.product_listing_name;

    setDetails(prevSelected => {
      const updatedDetails = prevSelected.some(item => item._id === list._id)
        ? prevSelected.filter(item => item._id !== list._id)
        : [...prevSelected, list];

      setSelectedCheckboxes(prevSelected =>
        prevSelected.includes(name)
          ? prevSelected.filter(item => item !== name)
          : [...prevSelected, name]
      );
      if (!updatedDetails.length) clearState();
      return updatedDetails;
    });
  };

  const onChangeTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    setTitle(newTitle.trim());

    if (!newTitle.trim()) {
      setTitleError('please enter the title');
    } else {
      setTitleError('');
    }
  };
  const createPackage = async () => {
    try {
      if (title === '') {
        setTitleError('please enter the title');
        return;
      }
      const jobRoleIds = selectedRoles.map(role => {
        return rolesMap.get(role);
      });

      const packageToCreate = {
        package_title: title,
        account_id: _id,
        tenantId: tenantId,
        product_list: [] as {
          _id: string;
          product_listing_name: string;
          product_id: string;
          account_id: string;
          listing_price: number;
          discount: number;
        }[],
        isactive: true,
        job_role_ids: jobRoleIds,
      };

      details.forEach(checks => {
        const list: {
          _id: string;
          product_listing_name: string;
          product_id: string;
          account_id: string;
          listing_price: number;
          discount: number;
        } = {
          _id: checks?._id || '',
          product_id: checks?.product_id?._id || '',
          product_listing_name: checks?.product_listing_name || '',
          account_id: checks?.account_id?._id || '',
          listing_price: checks?.listing_price || 0,
          discount: checks?.discount || 0,
        };

        packageToCreate.product_list.push(list);
      });

      const response = await axiosInstance.post(
        endpoints.GET_PACKAGE_DETAILS,
        packageToCreate
      );

      if (response.data.success) {
        enqueueSnackbar(`Package ${title} created successfully`, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        clearState();
      } else if (!response.data.success) {
        console.error('Bad request:', response);
        enqueueSnackbar(`Package with the same name already exists ${title}`, {
          variant: 'warning',
          autoHideDuration: 2000,
        });
      }
    } catch (error: any) {
      console.error('Unexpected error type:', error);
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(
          'Failed to create package. An unexpected error occurred.',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
      }
    }
  };

  const clearState = () => {
    setCheckList([]);
    setDetails([]);
    setSelectedCheckboxes([]);
    setReload(!reload);
    setTitle('');
    setTitleError('');
    setSelectedRoles([]);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = 'Changes that you made may not be saved.';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getProductList();
    getPackageList();
    getRolesList();
  }, [reload, _id, details]);

  useEffect(() => {
    clearState();
  }, [props.account]);

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          className: 'w-3/4',
        }}
        open={isDrawerOpen}
        onClose={handleViewPackagesClick}>
        {activePackages.length > 0 ? (
          <div className="p-10">
            <Typography className="body1 mb-4">Existing Package</Typography>
            <PackagesTable packageList={activePackages} />
          </div>
        ) : (
          <div className="bk-package-center-container">
            <Typography className="body1">No Package available!</Typography>
          </div>
        )}
      </Drawer>

      <TableContainer>
        <Table>
          <TableHead className="bk-package-table-header">
            <TableRow>
              <BKStyledTableCell>
                <Grid display={'flex'} justifyContent={'space-between'}>
                  <Typography className="body1 pl-4 text-red-500">
                    Package Creation
                  </Typography>
                  <Typography
                    className="bK-package-body1"
                    onClick={handleViewPackagesClick}>
                    View Existing Package
                  </Typography>
                </Grid>
              </BKStyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Grid container columnSpacing={1}>
              <Grid item xs={7.4} className="bk-blur-bottom">
                <FormControl
                  className="bk-package-form-control"
                  component="fieldset"
                  variant="standard">
                  <FormControl component="fieldset">
                    <Grid className="bk-ops-pk-checklist">
                      {checkList.map((list: ProductListing) => (
                        <Card key={list._id} className="bk-ops-pk-card-header">
                          <CardContent>
                            <Grid className="bk-ops-pk-card-content">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedCheckboxes.includes(
                                      list.product_listing_name
                                    )}
                                    onChange={handleChange(list)}
                                    name={list.product_listing_name}
                                  />
                                }
                                label={list.product_listing_name}
                              />

                              <img
                                src={list.product_img}
                                alt="Product Image"
                                className="bk-ops-pk-img"
                              />
                            </Grid>
                            <Grid className="ml-8" rowGap={4}>
                              <Typography
                                className="bK-body2"
                                color="textSecondary">
                                Company: {list?.account_id?.account_name}
                              </Typography>
                              <Typography
                                className="bK-body2"
                                color="textSecondary">
                                Product: {list?.product_id?.product_name}
                              </Typography>
                              {/*TODO: This will needed for future implementation */}
                              {/* <Typography
                                className="bK-body2"
                                color="textSecondary">
                                Price: {list?.product_id?.product_description}
                              </Typography> */}
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                    </Grid>
                  </FormControl>
                </FormControl>
              </Grid>
              <Grid item xs={0.2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid container item xs={4.4} className="bk-blur-bottom">
                <Grid item xs={12} className="h-[90%], overflow-auto">
                  <Box className="flex flex-col justify-between mx-8 pt-2 h-3/4">
                    <div>
                      {selectedCheckboxes.length ? (
                        <BKStyledTextField
                          className="p-0"
                          label="Enter Package Title"
                          variant="outlined"
                          fullWidth
                          onChange={onChangeTitle}
                          error={!!titleError}
                          helperText={titleError}
                        />
                      ) : (
                        ''
                      )}
                      {selectedCheckboxes.length ? (
                        <div className="overflow-auto h-[40vh]">
                          {selectedCheckboxes.map((selectedItem, index) => (
                            <ul key={index} className="bk-ops-pk-ol">
                              <Typography className="bK-body1">
                                {index + 1}. {selectedItem}
                              </Typography>
                            </ul>
                          ))}
                        </div>
                      ) : (
                        <>
                          <Typography className="bK-body1 mb-5">
                            To create a new package please select the checks
                          </Typography>
                        </>
                      )}
                    </div>
                  </Box>
                  <div
                    style={{
                      background: 'white',
                      position: 'absolute',
                      bottom: '12vh',
                      height: 'auto',
                    }}
                    className="flex h-1/4">
                    <Typography>Roles:</Typography>
                    <Autocomplete
                      limitTags={2}
                      className="!w-[320px] ml-2"
                      sx={{
                        '& .MuiAutocomplete-popupIndicator': {
                          display: 'none',
                        },
                      }}
                      multiple
                      value={selectedRoles}
                      onChange={(event, value) =>
                        handleSelectSelectedRoles(event, value)
                      }
                      id="tags-filled"
                      options={roles.map(role => role)}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-input': {
                              fontSize: '11px',
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '13px',
                            },
                          }}
                          {...params}
                          id="packages"
                          size="small"
                          variant="outlined"
                          placeholder="Select Roles assigned to this package"
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  columnGap={6}
                  className="w-full flex justify-end absolute bottom-0 right-0 p-4 h-[10vh] z-50 bg-bk_bg_primary overflow-auto">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={clearState}>
                    Clear
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={createPackage}
                    disabled={selectedCheckboxes.length === 0}>
                    Create Package
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CreatePackage;
